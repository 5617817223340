import API from './API'
import { RequestType, Identifiable } from '../types'

export async function fetchRequestTypes(): Promise<RequestType[]> {
    const res = await API.lambdaGet('/requesttypes/list')
    const types = res.Result as RequestType[]
    return types.filter(u => u.IsActive === 1)
}

export async function fetchOneRequestType(id: string): Promise<RequestType> {
    const res = await API.lambdaGet(`/requesttypes/get?_id=${id}`)
    return res as RequestType
}

export async function createRequestType(reqType: Partial<RequestType>): Promise<RequestType> {
    const id = await API.lambdaPost('/requesttypes/add', reqType)
    const created = await fetchOneRequestType(id)
    return created
}

export async function updateRequestType(reqType: Partial<RequestType> & Identifiable): Promise<RequestType> {
    if (!reqType._id) throw new Error('Updated Request Type object must contain _id field')
    await API.lambdaPost('/requesttypes/update', reqType)
    const updated = await fetchOneRequestType(reqType._id)
    return updated
}

export async function deleteRequestType(id: String): Promise<any> {
    await API.lambdaPost('/requesttypes/update', {
        _id: id,
        IsActive: 0,
    })
}

