import React from 'react';
import { Menu } from 'semantic-ui-react';
import MenuItem from './MenuItem';
import { canManageDailyActivities, isFacilityAdmin, isFacilityTabEnabled, isPowerUser, isSysAdmin } from '../../services/Permissions';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { Link } from 'react-router-dom';
import { SidebarIcons, SysadminIcons } from '../../types/sidebar';
import { prntyrLink } from '../../util/data';
const CustomSidebar = () => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const facilityAdminIcons: { name: string; path: string; iconName: SidebarIcons, tabName?: string }[] = [
        { name: 'Calendar', path: '/admin/dailyactivities', iconName: 'calendar' },
        { name: 'menu', path: '/admin/menu', iconName: 'menu' },
        { name: "Notepad", path: "/admin/notepad", iconName: "residentCalendar", tabName: "notepad" },
        { name: 'Cookbook', path: '/admin/cookbook', iconName: 'cookbook', tabName: "cookbook" },
        { name: 'requests', path: '/admin/requests', iconName: 'requests' , tabName: "orders"},
        { name: 'check-in', path: '/admin/checkin', iconName: 'checkin' },
        { name: 'AI Triggers', path: '', iconName: 'aiTriggers', tabName: "ai" },
        { name: 'Community News', path: '/admin/community-messages', iconName: 'community' },
        { name: 'announcement', path: '/admin/announcements', iconName: 'announcements' },
        { name: 'notifications', path: '/admin/notifications', iconName: 'notifications' },
        { name: 'Signage', path: '/admin/signage', iconName: 'signage' , tabName: "signage"},
        { name: 'Spotlight', path: '', iconName: 'spotlight' },
        { name: 'Pals', path: '/admin/speak2-pals', iconName: 'pals' },
        { name: 'Reports', path: '/admin/reports', iconName: 'reports' },
        { name: "Docs", path: "/admin/docs", iconName: "docs" },
        { name: "Prntyr", path: prntyrLink, iconName: "Prntyr" },
    ];
    const sysadminSidebarIcons: { name: string; path: string; iconName: SysadminIcons }[] = [
        {
            name: "Staff",
            path: "/admin/users/list",
            iconName: "staff"
        },
        {
            name: "Community Liaisons",
            path: "/sysadmin/communityliaisons",
            iconName: "liason"
        },
        {
            name: "Properties' Skills",
            path: "/sysadmin/skills",
            iconName: "propertiesSkills"
        },
        {
            name: "Corporates",
            path: "/sysadmin/corporates",
            iconName: "corporate"
        },
        {
            name: "Facilities",
            path: "/admin/facilities/list",
            iconName: "facilities"
        },
        {
            name: "Clone Demo Data",
            path: "/admin/clone-data",
            iconName: "cloneDemoData"
        },
        {
            name: "Devices",
            path: "/admin/devices",
            iconName: "devices"
        },
        {
            name: "WebRTC ",
            path: "/admin/toggle-webrtc",
            iconName: "webRTC"
        }, 
        {
            name: "TURN billing",
            path: "/admin/turn-billing",
            iconName: "turnBilling"
        }
    ];

    const filteredFacilityAdminIcons = facilityAdminIcons.filter((el) => {
        // Check if there is a tabName and it is enabled
        if (el.tabName) {
            return isFacilityTabEnabled(profile, el.tabName);
        }
        // If there is no tabName, include the item
        return true;
    });

    const roleBasedSideBarIconsArr: { name: string; path: string; iconName: SysadminIcons | SidebarIcons }[] = isSysAdmin(profile)
        ? sysadminSidebarIcons
        : isFacilityAdmin(profile) || isPowerUser(profile)
            ? filteredFacilityAdminIcons
            : [];

    const renderMenu = () => {
        if (roleBasedSideBarIconsArr && roleBasedSideBarIconsArr.length) {
            return (
                <Menu vertical size="tiny">
                    {roleBasedSideBarIconsArr.map((el) => {
                        if (el.name === 'Prntyr') {
                            return (
                                <a href={prntyrLink} target="_blank" rel="noopener noreferrer">
                                    <MenuItem
                                        link={el.path}
                                        name={el.name}
                                        disable={canManageDailyActivities(profile) ? false : true}
                                        iconName={el.iconName}
                                    />
                                </a>
                            );
                        } else if(el.name === 'Spotlight') {
                            return (
                                <div style={{ cursor: 'pointer' }} key={el.name}>
                                    <MenuItem
                                        link={el.path}
                                        name={el.name}
                                        disable={canManageDailyActivities(profile) ? false : true}
                                        iconName={el.iconName}
                                    />
                                </div>
                            );
                        }  else if(el.name === 'AI Triggers') {
                            return (
                                <div style={{ cursor: 'pointer' }} key={el.name}>
                                    <MenuItem
                                        link={el.path}
                                        name={el.name}
                                        disable={canManageDailyActivities(profile) ? false : true}
                                        iconName={el.iconName}
                                    />
                                </div>
                            );
                        }
                        else {
                            return (
                                <Link to={`${el.path}`} key={el.name}>
                                    <MenuItem
                                        link={el.path}
                                        name={el.name}
                                        disable={canManageDailyActivities(profile) ? false : true}
                                        iconName={el.iconName}
                                    />
                                </Link>
                            );
                        }
                    })}
                </Menu>
            );
        } else {
            return <></>;
        }
    };

    return <>{renderMenu()}</>;
};

export default CustomSidebar;
