import React, { FC } from 'react';
import PrintExportIcons from '../PrintExportIcons';
import CustomTable from '../CustomTable';
import { prntyrLink } from '../../util/data';
import moment from 'moment';

interface Props {
    tableItems: {
        dateTimeString: string;
        registrantName: string;
        registrantId: string;
        status: string;
        registrantFirstName: string;
        registrantLastName: string;
        roomName?: string;
    }[];
    tableHeading: string; // To be placed on top of the table
    height?: string;
}

const ReportsCheckInTable: FC<Props> = ({ tableItems, tableHeading, height }) => {
    const onPrintClick = async () => {
        window.open(prntyrLink, '_blank');
    };

    const checkInExportData =
        tableItems.map(({ dateTimeString, registrantName, status, roomName }) => {
            return {
                'Resident Name': registrantName,
                'Day/Time': moment(dateTimeString).format('M/D/YY h:mm A'),
                Status: status,
                Apartment: roomName
            };
        }) || [];
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <h3 style={{ textAlign: 'left', marginTop: '8px' }}>{tableHeading}</h3>
                <PrintExportIcons
                    exportData={checkInExportData}
                    exportFileName="reports_checkin"
                    onPrintClick={onPrintClick}
                />
            </div>
            <CustomTable
                data={tableItems}
                headers={['Resident Name', 'Day/Time', 'Status']}
                loading={false}
                facilityTimezone={''} //not required as date is already formatted correctly
                formatString={''} //not required as date is already formatted correctly
                rowKeys={['registrantName', 'dateTimeString', 'status']}
                setHeight={height}
                overflowX={'hidden'}
            />
        </>
    );
};

export default ReportsCheckInTable;
