import API from './API'

export async function enableSkill(skillId: string, a4hRoomIds: string[]): Promise<any> {
    try {
        const response = await API.lambdaPost('/skills/enable', {
            skillId,
            a4hRoomIds,
        })
        return response
    } catch (error) {
        console.log(error.message || error)
    }
}

export async function disableSkill(skillId: string, a4hRoomId: string): Promise<any> {
    return await API.lambdaPost('/skills/disable', {
        skillId,
        a4hRoomId
    });
}