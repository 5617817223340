import API from './API'

export const listRoomTypes = async () => {
    try {
        const res = await API.lambdaPost(`/room-types/list`, {});
        return res;
    } catch (error) {
        console.error("listRoomTypes error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to list room types");
    }

};