import React from 'react'
import FileUploadPresentation from './FileUploadPresentation'
import match from 'mime-match'
import { toast } from 'react-toastify'

interface State {
    dragging: boolean
}
interface Props {
    multiple?: boolean
    accept?: string[]
    percentage: number
    isUploading: boolean
    finished: boolean
    error: string | null
    onSelected: (files: File[]) => any
}

const defaultAccept = ['*']

class FilePicker extends React.Component<Props, State> {
    dragCounter: number = 0
    state: State = {
        dragging: false,
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            this.onFilesSelected(e.target.files)
        }
    }

    handleDrag = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.props.isUploading) return
        this.dragCounter++
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true })
        }
    }
    handleDragOut = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.props.isUploading) return
        this.dragCounter--
        if (this.dragCounter > 0) return

        this.setState({ dragging: false })
    }
    handleDrop = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.props.isUploading) return
        this.setState({ dragging: false })
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            if (e.dataTransfer.files.length > 1 && !this.props.multiple) {
                e.dataTransfer.clearData()
                this.dragCounter = 0
                return
            }
            this.onFilesSelected(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }

    onFilesSelected = (selected: FileList) => {
        const accept = (this.props.accept || defaultAccept).join(';')
        const filesArr = Array.from(selected).filter(item => match(item.type, accept))
        if (filesArr.length > 0)
            this.props.onSelected(filesArr)
        else //Should we show a toast here?
            toast.warn("Selected file is not supported", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
    }

    render() {
        const accept = (this.props.accept || defaultAccept)
        return (
            <div className="FilePicker">
                <FileUploadPresentation
                    handleDrag={this.handleDrag}
                    handleDragIn={this.handleDragIn}
                    handleDragOut={this.handleDragOut}
                    handleDrop={this.handleDrop}
                    handleFileInputChange={this.handleChange}
                    dragging={this.state.dragging}
                    accept={accept}
                    {...this.props}
                />
            </div>
        )
    }
}

export default FilePicker
