import React from 'react';
import { AuthState, Notification } from '../../../types';
import { Button, Comment } from 'semantic-ui-react';
import { distanceInWordsToNow } from 'date-fns';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

interface Props {
    userName: string | null;
    imageUrl: string | null;
    notification: Notification;
    deleteClickHandler?: (id: string) => void;
    editNotification?: (id: string) => void;
    loading?: boolean;
}

const NotificationItem: React.FC<Props> = (props: Props) => {
    const imageUrl = props.imageUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`;
    const isScheduledNotification =
        props.notification.triggerTimestampISO && props.notification.triggerTimestampISO > new Date().toISOString()
            ? true
            : false;
    const Profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    })
    const facilityTimezone = Profile && Profile.Facility && Profile.FacilityTimeZone || "";
    let notificationSendTime = '';

    if (facilityTimezone) {
        const timeStamp = props.notification.triggerTimestampISO ? props.notification.triggerTimestampISO : props.notification.DateAdded;
        const date = moment.tz(timeStamp, facilityTimezone).format('MM/DD/YYYY');
        const time = moment.tz(timeStamp, facilityTimezone).format('hh:mm A');
        if (props.notification.triggerTimestampISO) {
            if (new Date(props.notification.triggerTimestampISO) < new Date()) {
                notificationSendTime = `Notification sent at ${date} ${time}`;
            } else {
                notificationSendTime = `Notification will be sent at ${date} ${time}`;
            }
        } else {
            notificationSendTime = `Notification sent at ${date} ${time}`;
        }
    }

    const showCreatedNotificationTimeMessage = (dateAdded) => {
        const date = moment.tz(dateAdded, facilityTimezone).format('MM/DD/YYYY');
        const time = moment.tz(dateAdded, facilityTimezone).format('hh:mm A');
        return `Created at ${date} ${time}`;
    }

    return (
        <Comment className="NotificationItem Comment">
            <Comment.Avatar src={imageUrl} />
            <Comment.Content>
                {props.userName && <Comment.Author as="a">{props.userName}</Comment.Author>}
                {!props.userName && <Comment.Author as="a">Unknown User</Comment.Author>}
                <Comment.Metadata>
                    <div>
                        {
                            showCreatedNotificationTimeMessage(props.notification.DateAdded)
                        }
                        {/* {props.notification.EndDate
                            ? ' to ' +
                              new Date(props.notification.EndDate).toLocaleString('en-US', {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                              })
                            : ''} */}
                    </div>
                </Comment.Metadata>
                <br />
                <Comment.Metadata>
                    <div>{notificationSendTime}</div>
                </Comment.Metadata>
                <Comment.Text>
                    <b>
                        {props.notification.Subject && props.notification.Subject + ' - '}
                        {props.notification.Message}
                    </b>
                </Comment.Text>
                <Comment.Metadata>
                    {props.notification.RoomNames && props.notification.RoomNames.length > 0 && (
                        <>{props.notification.RoomNames.map((name) => (name ? `${name}` : ''))}</>
                    )}
                </Comment.Metadata>
                {isScheduledNotification ? (
                    <div className="mt-3">
                        <Button
                            basic
                            size="mini"
                            color="blue"
                            onClick={() => {
                                if (props.editNotification) {
                                    props.editNotification(props.notification._id);
                                }
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            basic
                            size="mini"
                            color="red"
                            loading={props.loading}
                            onClick={() => {
                                if (props.deleteClickHandler) {
                                    props.deleteClickHandler(props.notification._id);
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </Comment.Content>
        </Comment>
    );
};

export default NotificationItem;
