import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Dimmer, Form, Grid, Loader, Pagination, Table } from 'semantic-ui-react'
import { listResidentAnalyzeData } from '../../services/Analyze'
import { fetchOneFacility } from '../../services/Facilities'
import { AuthState } from '../../types'
import { Analyze } from '../../types/Analyze'
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader'
import { DateRange } from '../CustomDatePicker'
import PrintExportIcons from '../PrintExportIcons'
import AnalyzeCharts from './AnalyzeChart'
import AnalyzeTable from './AnalyzeTable'
import ResidentDatePickers from './ResidentDatePickers'
import moment from "moment-timezone"
import { toast } from 'react-toastify'
import { listAssets } from '../../services/Assets';
import { Asset } from '../../types/Asset';

interface Props {
    residentId: string
    residentName: string
}

const ResidentAnalzye: FC<Props> = ({ residentId, residentName }) => {
    const [selectedType, setSelectedType] = useState<"menu" | "activity">("activity")
    const [dateRange, setDateRange] = useState<DateRange>({
        start: undefined,
        end: undefined
    })
    const [isLoading, setIsLoading] = useState(false)
    const [analysisData, setAnalysisData] = useState<Analyze[]>([])
    const [totFacilityEvents, setTotFacilityEvents] = useState(0)
    const [printLoading, setPrintLoading] = useState(false);
    const [facilityTimezone, setFacilityTimezone] = useState("");
    const profile = useSelector((state: { authReducer: AuthState; }) => state.authReducer.profile);
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [assetDict, setAssetDict] = useState<{ [key: string]: Asset }>({});

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let startDateTimeString, endDateTimeString;
            if (profile && profile.FacilityTimeZone) {
                if (dateRange.start) {
                    startDateTimeString = moment.tz(dateRange.start, profile.FacilityTimeZone).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
                }

                if (dateRange.end) {
                    endDateTimeString = moment.tz(dateRange.end, profile.FacilityTimeZone).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
                }
                const attendanceData = await listResidentAnalyzeData(selectedType, residentId, startDateTimeString, endDateTimeString);
                console.log("attendanceData2", attendanceData);
                setFacilityTimezone(profile.FacilityTimeZone || "America/New_York");

                if (isFirstRender) {
                    setIsFirstRender(false); //keep setIsFirstRender above setDateRange to prevent extra calls being made.
                }
                setAnalysisData(attendanceData.data);
                setTotFacilityEvents(attendanceData.totFacilityEvents);
            }
        } catch (error) {
            // handle error
            console.error("fetch data error", error);
            toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);

        }
    };

    useEffect(() => {
        (async () => { // fetch all the assets data
            const allAssetsData = await listAssets();
            const assetDict: { [key: string]: Asset; } = {};
            allAssetsData.forEach((asset: Asset) => {
                assetDict[asset._id] = asset;
            });
            setAssetDict(assetDict);
        })();
    }, [profile]);
      
    useEffect(() => {
        // todo below is fetching data one extra time, fix it if there is a huge performance down side
        fetchData()
    }, [selectedType, dateRange])

    const typeBtnClickHanlder = (type: "menu" | "activity") => {
        setSelectedType(type)
    }

    const handlePrintClick = async () => {
        const printArr = analysisData.map(el => {
            return {
                Subject: el.eventName,
                StartTime: new Date(el.eventTime),
                StartDate: new Date(el.eventTime),
            }
        })
        setPrintLoading(true)
        await formatAndExportDataForPrint(printArr, `${residentName}-analyze`, facilityTimezone, selectedType)
        setPrintLoading(false)
    }

    const formatExportData = () => {
        const exportAnalysisData = analysisData.map((obj) => {
            const date = moment.tz(obj.eventTime, facilityTimezone).format("MM/DD/YYYY");
            const time = moment.tz(obj.eventTime, facilityTimezone).format("hh:mm A");
            return {
                Event: obj.eventName,
                Host: obj.host,
                Date: date,
                Time: time,
                Asset: assetDict && obj.AssetId && assetDict[obj.AssetId] ? assetDict[obj.AssetId].AssetName : "-",
                Registered: obj.registered,
                Attended: obj.attended,
            };
        });
        return exportAnalysisData;
    }
    
    return (
        <div>
            <Grid columns={2} relaxed verticalAlign='top'>
                <Grid.Column width={16}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <ResidentDatePickers dateRange={dateRange} setDateRange={setDateRange} residentName={residentName} />
                        <PrintExportIcons exportData={formatExportData()} exportFileName={`${residentName}-analyse`} onPrintClick={handlePrintClick} printLoading={printLoading} />
                    </div>
                    <AnalyzeTable data={analysisData} loading={isLoading} type={selectedType} facilityTimezone={facilityTimezone} assetDict={assetDict}/>
                </Grid.Column>
                <Grid.Column width={6}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        {/* button are in a seprate div so that they don't take full hieght for the parent div*/}
                        <div>
                            <Button basic color={selectedType === "activity" ? "blue" : "grey"} onClick={() => { typeBtnClickHanlder("activity") }} size={selectedType === "activity" ? "medium" : "small"}>Activity Analysis</Button>
                        </div>
                        <div>
                            <Button basic color={selectedType === "menu" ? "blue" : "grey"} onClick={() => { typeBtnClickHanlder("menu") }} size={selectedType === "menu" ? "medium" : "small"}>Menu Analysis</Button>
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
            <AnalyzeCharts data={analysisData} totFacilityEvents={totFacilityEvents} />
        </div>
    )
}

export default ResidentAnalzye