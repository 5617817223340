import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Checkbox, Dimmer, Dropdown, Form, Loader, Modal, ModalActions, ModalHeader, } from 'semantic-ui-react'
import { fetchResidentRooms } from '../../../../services/Facilities'
import { createAddressBookComProfileContact, enableInboundCalling } from '../../../../services/Registrants'
import { Registrant } from '../../../../types'
import { sortByKey } from '../../../../util/sortData';


interface Props {
    isModalOpen: boolean
    close: () => void
    registrant: Registrant
    reload: () => void
}

const AddCommProfileModal: FC<Props> = ({ isModalOpen, close, registrant, reload }) => {
    const [dropDownOptions, setDropDownOptions] = useState([])
    // const [isEnableInboundCalling, setIsEnableInboundCalling] = useState<boolean | undefined>(false)
    const [response, setResponse] = useState({
        loading: false,
    })

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }
    const wait = async (seconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, seconds)
        })
    }

    const [formData, setFormData] = useState({
        Registrant: registrant && registrant._id,
        contactName: "",
        commProfileId: "",
    })

    const [externalContact, setExternalContact] = useState({
        Registrant: "",
        contactName: registrant && registrant.FirstName && (registrant.FirstName + " " + registrant.LastName) || "",
        commProfileId: registrant.Unit&& registrant.Unit.a4hCommProfileId || ""
    })

    useEffect(() => {
        const getData = async () => {
            setResponse({ loading: true })
            const res = await fetchResidentRooms()
            ///filter to remove current room from the fetched list and check for A4hCommProfileId
            const modifiedData = res
                .filter(room => {return((room.RoomId !== (registrant&&registrant.Unit&&registrant.Unit.a4hRoomId)) && (room.A4hCommProfileId)  && true)})
                .map((room) => {
                    return {
                        key: room.ResidentId,
                        text: room.Resident + "   -   " +"Room #  " + room.RoomName,
                        value: room.A4hCommProfileId,
                        data: room
                    }
                })
            setDropDownOptions(modifiedData)
            setResponse({
                loading: false
            })
        }
        getData()
    }, [])

    const dropdownChangeHandler = (e, data) => {
        const facilityArr = data.options.filter((el) => {
            return data.value === el.data.A4hCommProfileId
        })
        const facility = facilityArr[0]
        setFormData({
            ...formData,
            contactName: facility.text,
            commProfileId: facility.value
        })
        setExternalContact({
            ...externalContact,
            Registrant: facility.data.ResidentId
        })
    }

    const addContactHandler = async (e) => {
        try {
            setResponse({ loading: true })
            e.preventDefault()
            const { contactId, success } = await createAddressBookComProfileContact(formData.Registrant, formData.contactName.split("   -   Room #")[0], formData.commProfileId)

            if (!success) {
                setResponse({ loading: false })
                sendToast("warn", "Something Went Wrong, please try again")
            }
            // if (isEnableInboundCalling) {
            setResponse({ loading: true })
            
            const { contactId : externalContactId, success : externalSuccess } = await createAddressBookComProfileContact(externalContact.Registrant, externalContact.contactName, externalContact.commProfileId)


            if (!externalSuccess) {
                setResponse({ loading: false })
                sendToast("warn", "Something Went Wrong, please try again")
            }
            else {
                sendToast("success", "Added contact and Enabled Inbound calling")
                close()
                await reload()
            }

                // Previous code

                // await wait(30 * 1000)
                // const res = await enableInboundCalling(formData.Registrant, contactId)
                // if (!res.success) {
                //     setResponse({ loading: false })
                //     sendToast("warn", res.data.message)
                // }
                // setResponse({
                //     ...response,
                // })
                // if (res.success) {
                //     sendToast("success", "Contact added and Inbound Calling Enabled")
                // }
            // }
            // else if (success) {
            //     sendToast("success", "Contact added ")
            //     close()
            //     await reload()
            // }
            
        } catch (error) {
            console.error(error)
            if (error instanceof Error) {
                sendToast("warn", error.message)
            }
        }
        setResponse({ loading: false })
    }
    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                close()
            }}
            size={'tiny'}
            className="invite-modal-root"
            closeIcon
        >
            <ModalHeader>
                <h5>Select the room you want to add</h5>
            </ModalHeader>

            <Form error>
                <Modal.Content className="fnf-invite-modal-content">
                    <Dropdown placeholder='Select a room' fluid selection options={sortByKey(dropDownOptions)} onChange={dropdownChangeHandler} loading={response.loading} search={true} />
                    {/* <Checkbox className='mt-4' label="Add myself as a contact in the selected room" onChange={(e, data) =>
                        setIsEnableInboundCalling(data.checked)} checked={isEnableInboundCalling} /> */}
                </Modal.Content>

                <ModalActions className="inviteBtn">
                    <Button
                        className="ui primary small button "
                        // loading={makingCalls}
                        onClick={addContactHandler}
                        disabled={response.loading}
                        loading={response.loading}
                    >
                        Add Contact
                    </Button>
                </ModalActions>
            </Form>
        </Modal>
    )
}

export default AddCommProfileModal