import * as React from 'react';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Grid, Message } from 'semantic-ui-react';

type attendedDataType = {
    active: number,
    observed: number,
    inactive: number
}
type declinedDataType = {
    notInterested: number,
    appointment: number,
    visitors: number,
    outOfCommunity: number,
    inBed: number,
    health: number
}

const ReportsAttendanceDoughnutChart = ({ attendedData, declinedData }: {attendedData: attendedDataType; declinedData: declinedDataType;}) => {
    // Check the data type for incoming data and set the table items accordingly
    const attendedLabels = ['Active', 'Observed', 'Inactive'];
    const declinedLabels = ['Not Interested', 'Appointment', 'Visitors', 'Out of Community', 'In Bed', 'Health'];
    const attendedValues = [attendedData.active || 0, attendedData.observed || 0, attendedData.inactive || 0];
    const declinedValues = [declinedData.notInterested || 0, declinedData.appointment || 0, declinedData.visitors || 0, declinedData.outOfCommunity || 0, declinedData.inBed || 0, declinedData.health || 0];
    const attendedDoughnutData = {
        labels: attendedLabels,
        datasets: [
            {
                data: attendedValues,
                backgroundColor: '#8686D2',
                hoverBackgroundColor: '#8686D2'
            },
        ],
    };

    const declinedDoughnutData = {
        labels: declinedLabels,
        datasets: [
            {
                data: declinedValues,
                backgroundColor: '#8686D2',
                hoverBackgroundColor: '#8686D2'
            },
        ],
    };

    const options = {
        cutoutPercentage: 80,
        legend: false,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
              const currentValue = dataset.data[tooltipItem.index];
              const percentage = Math.round((currentValue / total) * 100);
              return `${data.labels[tooltipItem.index]}: ${percentage}%`;
            },
          },
        },
      };

    const noAttendanceData = ((Math.max(...attendedValues) === 0) && (Math.max(...declinedValues) === 0)) ? true : false;

    return (
        <>
            <Grid style={{ display: 'flex', justifyItems: 'center', height: noAttendanceData ? '20vh' : '60vh'  }}>
                <Grid.Row>
                    <h4 style={{ marginTop: '1vh', marginLeft: '1vw' }}>Activity participation level</h4>
                </Grid.Row>
                {noAttendanceData ?
                    <Grid.Row>
                        <Grid.Column>
                            <Message>No Attendance Info</Message>
                        </Grid.Column>
                    </Grid.Row> :
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Doughnut data={attendedDoughnutData} options={options} />
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ marginTop: '2vh', color: '#8686D2' }}>Attended</h5>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <Doughnut data={declinedDoughnutData} options={options} />
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ marginTop: '2vh', color: '#8686D2' }}>Declined</h5>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </>
    );
};

export default ReportsAttendanceDoughnutChart;
