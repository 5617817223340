import React, { useEffect, useState } from "react";
import { UserProfile } from "../../../types";
import { Table, Icon, Grid, Dimmer, Loader, Pagination, TableHeaderCell, Popup } from 'semantic-ui-react';
import { Restaurant } from "../../../types/Dining";
import "./style.less";
import TableSeatView from "../../../components/TableSeatDetailedView";
import { deleteAsset, listPaginatedRestaurant } from "../../../services/Assets";
import DiningForm from "../../../components/Dining/DiningForm";
import ConfirmationModal from "../../../components/DeleteConfirmationModal";
import { toast } from "react-toastify";
import DiningStaffMembers from "../../../components/Dining/DiningStaffMembers";
import KitchenStaffMembers from "../../../components/Dining/KitchenStaffMembers";
interface Props {
    profile: UserProfile | null;
}

const Dining = ({ profile }: Props) => {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [restaurantTable, setRestaurantTable] = useState<Restaurant | null>(null);
    const [page_no, setPage_no] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [editId, setEditId] = useState<string>('');
    const [openDiningForm, setOpenDiningForm] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');
    const [refreshPage, setRefreshPage] = useState<boolean>(false);
    const [kitchenAndDiningDeletionId, setKitchenAndDiningDeletionId] = useState<string>('');
    const pageSize = 10;

    useEffect(() => {
        fetchRestaurants();
    }, [page_no, refreshPage]);

    const fetchRestaurants = async () => {
        try {
            setLoading(true);
            const pageSort = { page_size: pageSize, page_no: page_no, sort: [{ DateAdded: 'asc' }] };
            if (profile && !profile.Facility) {
                toast.error('Facility not found', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            const filters = {
                Facility: profile && profile.Facility || "", 
            };
            const { Result: response, TotRecords } = await listPaginatedRestaurant({ filters, pageSort });
            setRestaurants(response);
            setTotalPages(Math.ceil(Number(TotRecords) / pageSize)); 
        } catch (error) {
            toast.error(`${error instanceof Error ? error.message : 'Failed to fetch restaurants'}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleTableSelect = async (restaurant: Restaurant) => {
        setRestaurantTable(restaurant);
    };

    const closeDiningForm = () => {
        setOpenDiningForm(false);
    };
    const handleSelectedRestaurant = (id: string) => {
        setEditId(id);
        setOpenDiningForm(true);
    };
    const closeTableSeatView = () => {
        setRestaurantTable(null);
    };
    const handleDeleteRestaurant = (id: string) => {
        setDeleteId(id);
        setDeleteModal(true);
    };
    const cancelDelete = () => {
        setDeleteId("");
        setDeleteModal(false);
    };
    const confirmDelete = async () => {
        try {
            setLoading(true);
            setDeleteModal(false);
            const response = await deleteAsset(deleteId);
            if (response) {
                toast.success('Restaurant deleted successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                await fetchRestaurants();
                setRestaurantTable(null);
            } else {
                toast.error('Failed to delete restaurant', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            toast.error(`${error instanceof Error ? error.message : 'Failed to delete restaurant'}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
        finally {
            setDeleteId("");
            setLoading(false);
        }
    };

    return (
        <div className="restaurant-wrapper" style={{ padding: "10px", height: "100vh" }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={restaurantTable ? 9 : 16}>
                        <Dimmer active={loading} inverted>
                            <Loader size='small'>Loading</Loader>
                        </Dimmer>
                        <div className="restaurant-header">
                            <h3>Restaurants</h3>
                            <span>
                                {
                                    restaurantTable && (
                                        <Popup
                                            trigger={
                                                <Icon name='expand' size="large" onClick={() => closeTableSeatView()} style={{ cursor: "pointer" }} />
                                            }
                                            content='Close Table Seat View'
                                            position="top right"
                                        />
                                    )
                                }
                                <Popup
                                    trigger={
                                        <Icon name='plus' size="large" onClick={() => handleSelectedRestaurant("")} style={{ cursor: "pointer" }} />
                                    }
                                    content='Add Restaurant'
                                    position="top right"
                                />
                            </span>
                        </div>
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Description</Table.HeaderCell>
                                    <Table.HeaderCell>Max Cap</Table.HeaderCell>
                                    <Table.HeaderCell>Tables</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {restaurants && restaurants.length ? (
                                    restaurants.map((restaurant, index) => (
                                        <Table.Row className="table-row" key={index}>
                                            <Table.Cell ><p className="clickable" onClick={() => handleSelectedRestaurant(restaurant && restaurant._id)}>{restaurant.AssetName}</p></Table.Cell>
                                            <Table.Cell>{restaurant.Description}</Table.Cell>
                                            <Table.Cell>{restaurant.MaxCap}</Table.Cell>
                                            <Table.Cell className="table-cell ">
                                                <p className="clickable" onClick={() => handleTableSelect(restaurant)} >{restaurant.TableIds && restaurant.TableIds.length || 0}</p>
                                                <Popup
                                                    trigger={
                                                        <Icon className="trash-icon" name='trash' size="large" onClick={() => handleDeleteRestaurant(restaurant._id)} />
                                                    }
                                                    content='Delete Restaurant'
                                                    position='top right'
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                ) : (
                                    <Table.Row>
                                        <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                                            <p>No restaurant found</p>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            {(totalPages > 1) && <Table.Footer >
                                <Table.Row>
                                    <TableHeaderCell colSpan='4' >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Pagination
                                                boundaryRange={0}
                                                defaultActivePage={1}
                                                activePage={page_no}
                                                onPageChange={(e, { activePage }) => {
                                                    setPage_no(Number(activePage));
                                                }}
                                                ellipsisItem={null}
                                                firstItem={null}
                                                lastItem={null}
                                                siblingRange={1}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                    </TableHeaderCell>
                                </Table.Row>
                            </Table.Footer>}
                        </Table>
                    </Grid.Column>
                    {restaurantTable && (
                        <Grid.Column width={7}>
                            <TableSeatView restaurant={restaurantTable} setRefreshPage={setRefreshPage}/>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <DiningStaffMembers
                            setKitchenAndDiningDeletionId={setKitchenAndDiningDeletionId}
                            kitchenAndDiningDeletionId={kitchenAndDiningDeletionId}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <KitchenStaffMembers
                            setKitchenAndDiningDeletionId={setKitchenAndDiningDeletionId}
                            kitchenAndDiningDeletionId={kitchenAndDiningDeletionId}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <DiningForm
                openDiningForm={openDiningForm}
                closeDiningForm={closeDiningForm}
                editId={editId}
                facilityId={profile && profile.Facility || ''}
                setRefreshPage={setRefreshPage}
            />
            <ConfirmationModal open={deleteModal} onClose={cancelDelete} onConfirm={confirmDelete} messageToDisplay={"Are you sure you want to delete the restaurant along with all table-seat configuration associated with it."} />
        </div >
    );
};

export default Dining;