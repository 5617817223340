import React from 'react'
import { Button, Dimmer, Loader, Modal, Table } from 'semantic-ui-react'
import { RequestInstance } from '../../types/RequestInstance'
import { formatDateWithTZ } from '../../util/timezone'

interface Props {
    open: boolean
    onClose: () => void
    cancelRequest?: (requestId: string) => void
    cancelAllOpenAndAcceptedOrders: () => void
    associatedOrders: RequestInstance[]
    facilityTimezone: string
    loading: boolean
    requestId: string
    parentLoading?: boolean
}

const CloseAssociatedOrdersModal: React.FC<Props> = ({ open, onClose, cancelRequest, cancelAllOpenAndAcceptedOrders, associatedOrders, facilityTimezone, loading, requestId, parentLoading }) => {
    return (
        <Modal open={open} onClose={onClose} style={{ padding: "30px" }} >
            <Dimmer active={loading || parentLoading} inverted>
                <Loader active={loading || parentLoading} />
            </Dimmer>
            <Modal.Header>The OrderId- <span className='statusHeading'>{associatedOrders && associatedOrders[0] && associatedOrders[0].OrderId && associatedOrders[0].OrderId.slice(associatedOrders[0].OrderId.length - 4)}</span> is associated with the below orders, Do you want to close</Modal.Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Order</Table.HeaderCell>
                        <Table.HeaderCell>OrderId</Table.HeaderCell>
                        <Table.HeaderCell>Scheduled Date</Table.HeaderCell>
                        <Table.HeaderCell>Assigned</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {associatedOrders && associatedOrders.length > 0 && associatedOrders.map((order) => (
                        <Table.Row key={order._id}>
                            <Table.Cell>{order.OrderName || "-"}</Table.Cell>
                            <Table.Cell>{order.OrderId || "-"}</Table.Cell>
                            <Table.Cell>{formatDateWithTZ(order.RequestedTime, 'MM/DD/YYYY hh:mm A', facilityTimezone)}</Table.Cell>
                            <Table.Cell>{order.AcceptedByName || "-"}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Modal.Actions>
                <Button onClick={() => cancelRequest && cancelRequest(requestId)}>This Order</Button>
                <Button onClick={cancelAllOpenAndAcceptedOrders}>All Orders</Button>
                <Button onClick={onClose}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default CloseAssociatedOrdersModal