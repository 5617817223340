import React from 'react';
import { Modal, Table, Pagination } from 'semantic-ui-react';
import { UserDetails } from '../../../types/WebRTC';
import PrintExportIcons from '../../../components/PrintExportIcons';

interface UserDetailsModalProps {
    userDetails: UserDetails[];
    open: boolean;
    onClose: () => void;
}

const BillingDetailModal: React.FC<UserDetailsModalProps> = ({ userDetails, open, onClose }) => {
    const [activePage, setActivePage] = React.useState(1);
    const itemsPerPage = 5;

    const sortedUsers = [...userDetails].sort((a, b) => b.user.UserAmount - a.user.UserAmount);

    const transformedDataForExport = sortedUsers.map(user => {
        return user.user
    })

    const paginatedSortedUsers = () => {
        const response = sortedUsers.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
        return response;
    }
    return (
        <Modal open={open} onClose={onClose} closeIcon={true}>
            <Modal.Header>User Details</Modal.Header>
            <Modal.Content>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <PrintExportIcons exportData={transformedDataForExport} exportFileName='Speak2-billingInfo' disablePrintButton={true} onPrintClick={() => { }} />
                </div>
                <Table sortable celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {paginatedSortedUsers().map((detail, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>{detail.user.FirstName}</Table.Cell>
                                <Table.Cell>{detail.user.LastName}</Table.Cell>
                                <Table.Cell>${detail.user.UserAmount.toFixed(2)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Pagination
                    activePage={activePage}
                    totalPages={Math.ceil(sortedUsers.length / itemsPerPage)}
                    onPageChange={(_, { activePage }) => setActivePage(Number(activePage))}
                />
            </Modal.Content>
        </Modal>
    );
};

export default BillingDetailModal;
