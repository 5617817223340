import React from 'react';
import {
    Form,
    InputOnChangeData,
    Dropdown,
    Message,
    Button,
    Icon,
    Modal,
    Header,
    Popup,
    Checkbox,
    Loader,
    Dimmer,
} from 'semantic-ui-react';
import { Notification, UserProfile } from '../../../types';
import DatePicker from 'react-datepicker';
import './style.less'
import moment from "moment-timezone"
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { toast } from 'react-toastify';
import { fetchFacilityTimezoneWithFacilityId } from '../../../util';
import { sortByKey } from '../../../util/sortData';
interface Props {
    isSaving: boolean;
    onSubmit: (
        subject: string,
        message: string,
        roomIds: string[],
        startDateISO: string,
        // endDateISO: string,
        id?: string,
    ) => Promise<any>;
    roomOptions;
    roomGroups;
    deleteRoomGroup: (id: string) => Promise<any>;
    createRoomGroup(name: string, roomIds: string[]);
    residentA4hRoomId?: string;
    selectedNotification?: Notification;
    selectedDate?: any;
    selectedRoomId?: string;
    profile: UserProfile | null;
}

interface State {
    message: string;
    selectedRooms: string[];
    disableIndividualRooms: boolean;
    disableSubmit: boolean;
    errMsg: string;
    warnMsg: string;
    saveAsGroup: boolean;
    roomGroupName: string;
    savingRoomGroup: boolean;
    saveGroupErr: string;
    hideSaveAsGroup: boolean;
    openManageGroups: boolean;
    deleteGroup: string;
    deletingRoomGroup: boolean;
    delGroupErr: string;
    startDateTimeString: string;
    // endDate: string;
    subject: string;
    scheduledNotification: boolean;
    facilityTimeZone: string;
    loading: boolean;
}

class NotificationsForm extends React.Component<Props, State> {
    selectedNotification = this.props.selectedNotification;
    state: State = {
        message: this.selectedNotification ? this.selectedNotification.Message : '',
        selectedRooms: this.props.selectedRoomId ? this.selectedNotification ? this.selectedNotification.RoomIds : [this.props.selectedRoomId] : ["all"],
        disableIndividualRooms: true,
        disableSubmit: true,
        errMsg: '',
        warnMsg: '',
        saveAsGroup: false,
        roomGroupName: '',
        savingRoomGroup: false,
        saveGroupErr: '',
        hideSaveAsGroup: false,
        openManageGroups: false,
        deleteGroup: '',
        deletingRoomGroup: false,
        delGroupErr: '',
        startDateTimeString: '',
        // endDate:
        //     this.selectedNotification && this.selectedNotification.EndDate ? this.selectedNotification.EndDate : '',
        subject:
            this.selectedNotification && this.selectedNotification.Subject ? this.selectedNotification.Subject : '',
        scheduledNotification:
            this.selectedNotification && this.selectedNotification.triggerTimestampISO ? true : false,
        facilityTimeZone: '',
        loading: false,
    };

    handleChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (data.value.length >= 60) {
            this.setState({
                warnMsg: 'Only the first 60 characters shall be shown on pinned and rotating alerts',
            });
        } else {
            this.setState({
                warnMsg: '',
            });
        }
        this.setState({
            message: data.value,
            disableSubmit: this.props.residentA4hRoomId
                ? false
                : this.props.roomOptions.length === 1 || !this.state.message || !!this.state.errMsg,
        });
    }

    // handleSubjectChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
    //     if (data.value.length >= 25) {
    //         this.setState({
    //             warnMsg: 'Only the first 60 characters shall be shown on pinned and rotating alerts',
    //         });
    //     } else {
    //         this.setState({
    //             warnMsg: '',
    //         });
    //     }
    //     this.setState({
    //         subject: data.value,
    //         disableSubmit: this.props.residentA4hRoomId
    //             ? false
    //             : this.props.roomOptions.length === 1 || !this.state.message || !!this.state.errMsg,
    //     });
    // }

    handleDropdownChange(roomIds) {
        const roomsHandlingGroups = roomIds.map((roomId) => {
            if (roomId.indexOf(',') !== -1) {
                return roomId.split(',');
            }
            return roomId;
        });
        const rooms = roomsHandlingGroups.flat();

        if (!rooms.includes('all') && rooms.length > 1 && this.state.hideSaveAsGroup) {
            this.setState({
                hideSaveAsGroup: false,
            });
        }

        if (rooms.includes('all')) {
            this.setState({
                selectedRooms: ['all'],
                disableIndividualRooms: true,
                disableSubmit: this.props.residentA4hRoomId
                    ? false
                    : this.props.roomOptions.length === 1 || !(this.state.message && rooms[0]) || !!this.state.errMsg,
            });
        } else {
            this.setState({
                selectedRooms: rooms,
                disableSubmit: this.props.residentA4hRoomId
                    ? false
                    : this.props.roomOptions.length === 1 || !(this.state.message && rooms[0]) || !!this.state.errMsg,
            });
        }
    }

    handleSubmit() {
        let startDateISO = '';
        if (this.state.scheduledNotification) {
            // if (new Date(this.state.startDate) < new Date(this.state.endDate)) {
                if (this.state.startDateTimeString != '') {
                    startDateISO = this.getDateISO(this.state.startDateTimeString);
                }
            // } else {
            //     this.setState({ errMsg: 'End date has to be greater than start date' });
            //     return;
            // }
        }

        // const endDateISO = this.getDateISO(this.state.endDate);

        if (this.props.residentA4hRoomId) {
            if (this.props.selectedNotification && this.props.selectedNotification._id) {
                this.props.onSubmit(
                    this.state.subject,
                    this.state.message,
                    [this.props.residentA4hRoomId],
                    startDateISO,
                    // endDateISO,
                    this.props.selectedNotification._id,
                );
            } else {
                this.props.onSubmit(
                    this.state.subject,
                    this.state.message,
                    [this.props.residentA4hRoomId],
                    startDateISO,
                    // endDateISO,
                );
            }
        } else {
            if (this.props.selectedNotification && this.props.selectedNotification._id) {
                this.props.onSubmit(
                    this.state.subject,
                    this.state.message,
                    this.state.selectedRooms,
                    startDateISO,
                    // endDateISO,
                    this.props.selectedNotification!._id,
                );
            } else {
                this.props.onSubmit(
                    this.state.subject,
                    this.state.message,
                    this.state.selectedRooms,
                    startDateISO,
                    // endDateISO,
                );
            }
        }

        this.setState({
            subject: '',
            message: '',
            selectedRooms: ['all'],
            disableIndividualRooms: true,
            startDateTimeString: '',
            // endDate: '',
            disableSubmit: true,
            warnMsg: '',
            saveAsGroup: false,
            scheduledNotification: false,
        });
    }

    getDateISO(dateTimeString: string) {
        return moment.tz(dateTimeString, this.state.facilityTimeZone).toISOString();
    }

    async saveRoomGroupName(name: string) {
        this.setState({
            savingRoomGroup: true,
        });
        try {
            //make call to save rooms as group
            await this.props.createRoomGroup(name, this.state.selectedRooms);

            this.setState({
                savingRoomGroup: false,
                saveAsGroup: false,
                hideSaveAsGroup: true,
            });
        } catch (e) {
            this.setState({
                saveGroupErr: e.message ? e.message : 'Save failed! Please retry.',
                savingRoomGroup: false,
            });
        }
    }

    async handleDelGroup() {
        console.log('deleting group', this.state.deleteGroup);
        try {
            this.setState({
                deletingRoomGroup: true,
            });

            await this.props.deleteRoomGroup(this.state.deleteGroup);

            this.setState({
                deletingRoomGroup: false,
                openManageGroups: false,
            });
        } catch (e) {
            console.error(e);
            this.setState({
                deletingRoomGroup: false,
                delGroupErr: 'Delete failed',
            });
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });
        if (this.props.selectedDate && new Date(this.props.selectedDate) > new Date()) {
            this.setState({
                scheduledNotification: true,
            });
        }
        if (this.props.profile && this.props.profile.Facility) {
            const facilityId = this.props.profile && this.props.profile.Facility;
            try {
                const FacilityTimeZone = await fetchFacilityTimezoneWithFacilityId(facilityId);
                if (!FacilityTimeZone) throw new Error('Facility timezone not found. Please have it updated');
                console.log('FacilityTimeZone', FacilityTimeZone);
                // fetch default time - if the current facility hour is less than 9 am, set the default time to 9 am, else set it to 5 minutes from now
                const currentMomentTime = (this.props.selectedDate && (new Date(this.props.selectedDate) > new Date())) ? moment(this.props.selectedDate) : moment.tz(new Date(), FacilityTimeZone);
                const defaultTimeString =
                    currentMomentTime.hour() <= 9
                        ? currentMomentTime.hour(9).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
                        : currentMomentTime.add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
                this.setState({ facilityTimeZone: FacilityTimeZone }, () => {
                    this.setState({
                        startDateTimeString:
                            this.selectedNotification && this.selectedNotification.triggerTimestampISO
                                ? moment
                                      .tz(
                                          this.selectedNotification.triggerTimestampISO,
                                          FacilityTimeZone,
                                      )
                                      .format('YYYY-MM-DDTHH:mm:ss')
                                : defaultTimeString,
                    });
                });
                this.setState({ loading: false });
            } catch (error) {
                toast.error('Facility timezone not found. Please have it updated', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else throw new Error('Profile not found in NotificationsForm');
    }

    render() {
        return this.state.loading ? (
            <div style ={{height:'20vh', width: '10vw'}}>
                <Dimmer active={this.state.loading} inverted>
                    <Loader active={this.state.loading}/>
                </Dimmer>
            </div>
        ) : (
            <Form loading={this.props.isSaving} onSubmit={this.handleSubmit.bind(this)} className="form-custom-margin">
                {/* <Form.Group widths={'equal'}>
                        <Form.Input
                            placeholder="Subject"
                            value={this.state.subject}
                            onChange={this.handleSubjectChange.bind(this)}
                        />
                    </Form.Group> */}
                <Form.Group widths={2}>
                    <Form.Input
                        placeholder="New Notification"
                        value={this.state.message}
                        onChange={this.handleChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group width={2}>
                    {this.state.scheduledNotification ? (
                        <Form.Field width={8}>
                            <div className="customDatePickerWidth">
                                <DatePicker
                                    disabled={this.state.loading}
                                    placeholderText="Start Date"
                                    dateFormat="M.d.Y h:mm aa"
                                    selectsStart
                                    showTimeSelect
                                    timeIntervals={15}
                                    selected={Date.parse(this.state.startDateTimeString)}
                                    onChange={(date) => {
                                        this.setState({
                                            startDateTimeString: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
                                            errMsg: '',
                                            disableSubmit: false,
                                        });
                                    }}
                                    startDate={Date.parse(this.state.startDateTimeString)}
                                    // endDate={Date.parse(this.state.endDate)}
                                    minDate={
                                        new Date(moment.tz(this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'))
                                    }
                                    minTime={moment(this.state.startDateTimeString).format('YYYY-MM-DD') === moment.tz(this.state.facilityTimeZone).format('YYYY-MM-DD')
                                        ? new Date(moment.tz(this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'))
                                        : false}
                                    maxTime={moment(this.state.startDateTimeString).format('YYYY-MM-DD') === moment.tz(this.state.facilityTimeZone).format('YYYY-MM-DD')
                                        ? new Date(moment.tz(this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss')).setHours(23, 59, 0, 0)
                                        : false}
                                    popperPlacement="right"
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                />
                            </div>
                        </Form.Field>
                    ) : (
                        <></>
                    )}
                    {/* <Form.Field width={8}>
                        <div className="customDatePickerWidth">
                            <DatePicker
                                placeholderText="End Date"
                                dateFormat="M.d.Y h:mm aa"
                                selectsEnd
                                showTimeSelect
                                timeIntervals={15}
                                selected={Date.parse(this.state.endDate)}
                                onChange={(date) => {
                                    this.setState({
                                        endDate: date,
                                        errMsg: '',
                                        disableSubmit: false,
                                    })
                                }}
                                startDate={Date.parse(this.state.startDate)}
                                endDate={Date.parse(this.state.endDate)}
                                minDate={this.state.startDate ? this.state.startDate : new Date()}
                                minTime={
                                    new Date(this.state.endDate).toDateString() === new Date().toDateString()
                                        ? new Date().setTime(new Date().getTime() + 1000 * 60 * 5)
                                        : false
                                }
                                maxTime={
                                    new Date(this.state.endDate).toDateString() ===
                                        new Date().toDateString()
                                        ? new Date().setHours(23, 59, 0, 0)
                                        : false
                                }
                                popperPlacement="right"
                                popperModifiers={{ preventOverflow: { enabled: true }}} 
                            />
                        </div>
                        
                    </Form.Field> */}
                </Form.Group>
                <Form.Group widths={2}>
                    <Form.Group widths={2}>
                        {!this.props.residentA4hRoomId && (
                            <Form.Field width={13}>
                                <Dropdown
                                    closeOnEscape
                                    defaultValue={this.props.roomOptions[0].value}
                                    value={this.state.selectedRooms}
                                    multiple
                                    clearable
                                    search
                                    scrolling
                                    selection
                                    options={sortByKey(this.props.roomOptions)}
                                    onChange={(e, d) => this.handleDropdownChange(d.value)}
                                />
                            </Form.Field>
                        )}
                        {!this.props.residentA4hRoomId && (
                            <Form.Field width={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {!this.state.hideSaveAsGroup && (
                                    <Modal
                                        style={{ border: '2px solid #183466' }}
                                        size="mini"
                                        open={this.state.saveAsGroup}
                                        trigger={
                                            <Popup
                                                content="Save as Group"
                                                trigger={
                                                    <Button
                                                        icon="save"
                                                        basic
                                                        color="green"
                                                        disabled={this.state.selectedRooms.length <= 1}
                                                        onClick={(e, d) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                saveAsGroup: !this.state.saveAsGroup,
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                        }
                                    >
                                        <Header>Enter group name</Header>
                                        <Modal.Content>
                                            <input
                                                onChange={(e) =>
                                                    this.setState({
                                                        roomGroupName: e.target.value,
                                                    })
                                                }
                                            />

                                            {this.state.saveGroupErr && (
                                                <Message negative>{this.state.saveGroupErr}</Message>
                                            )}
                                        </Modal.Content>

                                        <Modal.Actions>
                                            <Button
                                                basic
                                                color="red"
                                                onClick={() =>
                                                    this.setState({
                                                        roomGroupName: '',
                                                        saveAsGroup: false,
                                                        saveGroupErr: '',
                                                    })
                                                }
                                            >
                                                <Icon name="remove" /> Cancel
                                            </Button>
                                            <Button
                                                basic
                                                color="green"
                                                loading={this.state.savingRoomGroup}
                                                onClick={() =>
                                                    this.state.roomGroupName &&
                                                    this.saveRoomGroupName(this.state.roomGroupName)
                                                }
                                            >
                                                <Icon name="checkmark" /> Save
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                )}

                                <Modal
                                    style={{ border: '2px solid #183466' }}
                                    size="mini"
                                    open={this.state.openManageGroups}
                                    trigger={
                                        <Popup
                                            content="Manage groups"
                                            trigger={
                                                <Button
                                                    icon="edit"
                                                    basic
                                                    color="blue"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            openManageGroups: true,
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                    }
                                >
                                    <Header>Manage room groups</Header>
                                    <Modal.Content>
                                        <Dropdown
                                            placeholder="Select group to delete"
                                            closeOnEscape
                                            selection
                                            fluid
                                            search
                                            scrolling
                                            onChange={(e, d) => {
                                                const groupObj = this.props.roomGroups.find((g) => g.value === d.value);
                                                groupObj && this.setState({ deleteGroup: groupObj.key });
                                            }}
                                            options={sortByKey(this.props.roomGroups)}
                                        />

                                        {this.state.delGroupErr && <Message negative>{this.state.delGroupErr}</Message>}
                                    </Modal.Content>

                                    <Modal.Actions>
                                        <Button
                                            style={{ backgroundColor: '#2987CD', color: 'white' }}
                                            onClick={() =>
                                                this.setState({
                                                    openManageGroups: false,
                                                    deleteGroup: '',
                                                    delGroupErr: '',
                                                })
                                            }
                                        >
                                            <Icon name="cancel" /> Cancel
                                        </Button>
                                        <Button
                                            color="red"
                                            loading={this.state.deletingRoomGroup}
                                            disabled={!this.state.deleteGroup}
                                            onClick={this.handleDelGroup.bind(this)}
                                        >
                                            <Icon name="trash" /> Delete
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </Form.Field>
                        )}
                    </Form.Group>
                </Form.Group>
                <Form.Group widths={2}>
                    <Form.Button
                        type="submit"
                        primary
                        disabled={
                            this.state.disableSubmit ||
                            !(
                                // this.state.subject &&
                                this.state.message &&
                                (this.props.residentA4hRoomId ? true : this.props.roomOptions.length !== 1) 
                                // && this.state.endDate
                            )
                        }
                    >
                        {this.props.selectedNotification ? 'Update notification' : 'Send notification'}
                    </Form.Button>
                    {this.props.selectedNotification && this.props.selectedNotification._id ? (
                        <></>
                    ) : (
                        <Form.Field>
                            <Checkbox
                                toggle
                                label="Schedule Notification"
                                checked={this.state.scheduledNotification}
                                onChange={(e, d) => {
                                    this.setState({ scheduledNotification: Boolean(d.checked) });
                                }}
                            />
                        </Form.Field>
                    )}
                </Form.Group>
                {this.state.errMsg && <Message negative>{this.state.errMsg}</Message>}
                {this.state.warnMsg && <Message color="grey">{this.state.warnMsg}</Message>}
            </Form>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    };
}

export default connect(mapStateToProps)(NotificationsForm);