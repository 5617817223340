import React from 'react'
import { Form, Button, Dropdown, Divider, Checkbox, Grid, Icon, Dimmer, Select, Input } from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import ReactQuill from 'react-quill'
import AudioRecorder from '../../components/AudioRecorder'
// import S3FileUpload from '../../components/S3FileUpload'
import PhotoGrid from '../../components/PhotoGrid'
import DatePicker from 'react-datepicker'
import './style.less'
import 'react-quill/dist/quill.snow.css'
import { MenuType } from '../../types/menu'
import { capitalizeFirstLetter } from './utils'
import { fetchOneFacility } from '../../services/Facilities';
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { UserProfile } from '../../types';
import moment from 'moment-timezone';
import SignageContentImgCaptureAndDisplay from '../SignageContentImgCaptureAndDisplay'
// import FilePicker from '../S3FileUpload/FilePicker'
import { listRoomTypes } from '../../services/RoomTypes';
import KanbanBoards from '../../pages/AdminPanel/Menu/kanban-boards';
import { kanbanDataType } from '../../types/kanbanDataTypes';
import { updateMenuAttendance } from '../../services/Menus'
import { toast } from 'react-toastify';
import { sortByKey } from '../../util/sortData';
import ExcludeDatesField from '../ExcludeDatesField';

interface Props extends RouteComponentProps {
    isFetching: boolean
    isSaving: boolean
    activeFoodTab: string | undefined
    editableMenu?: Partial<MenuType>
    edit?: boolean
    _id?: string
    copyMenu?: MenuType | null
    handleSubmit: (menu) => any
    handleUpdate: (menu) => any
    cancelEditMenu: (id?) => any
    passAudioBlob?
    audioError?: string | null
    date?: string | undefined
    copyClickHandler?: (image: string | undefined) => void
    kanbanBoard?: kanbanDataType
    selectedAttendees?: string[]
    addNewAttendee?: (name: string) => void
    removeAttendee?: (name: string) => void
    setIsFetching?: (value: boolean) => void
    profile: UserProfile | null
    timezone?: string
    modifyGroup?: boolean
    closeDetailedView?: () => void
    selectedCalendarType?: string[]
    handleLocationUpdate?: (location: string[]) => void
    setKanbanDataAndSelectedAttendees?: (value: null | string[]) => void;
    handleDelete?: (e) => void;
}

interface State {
    menu: Partial<MenuType>
    images: Array<any>
    repeatType: string
    imageFile: File | null
    copyImageUrl: string | null
    signagePreviewImage: string | undefined
    filePickerStatus: {
        percentage: number
        isUploading: boolean
        finished: boolean
        error: string | null
    }
    selectedAttendees?: string[]
    loader: boolean
    timezone: string
    locationOptions: {
        key: string;
        text: string;
        value: string;
    }[];
    selectedLocation: string[];
    isLoading: boolean;
    interval: number;
    excludeDates: string[];
    datePickerMinDate: Date | null;
    datePickerMaxDate: Date | null;
}

class MenuFormEditor extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            images: [],
            menu: {
                text: '',
                alexaReadableCollection: [],
                timestamp: getTimestamp(props),
                dateString: props.date ? moment(props.date, "YYYY-MM-DD").format('ddd MMM DD YYYY') : '',
                category: props.activeFoodTab ? props.activeFoodTab : '',
                recurrence:
                    props.editableMenu && props.editableMenu.recurrence ? props.editableMenu.recurrence : undefined,
                endDate:
                    props.editableMenu && props.editableMenu.recurrence && props.modifyGroup
                        ? moment
                              .tz(
                                  props.editableMenu.recurrence
                                      .split('UNTIL=')[1]
                                      .split('\n')[0]
                                      .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                                  props.timezone,
                              )
                              .unix() * 1000
                        : undefined,
            },
            imageFile: null,
            copyImageUrl: null,
            signagePreviewImage: undefined,
            filePickerStatus: {
                error: null,
                finished: false,
                isUploading: false,
                percentage: 0,
            },
            selectedAttendees: [],
            loader: false,

            repeatType:
                this.props.editableMenu && this.props.editableMenu.recurrence && this.props.modifyGroup
                    ? this.props.editableMenu.recurrence.split('FREQ=')[1].split(';')[0]
                    : 'OFF',
            timezone: 'America/New_York',
            locationOptions: [],
            selectedLocation: (
                this.props.editableMenu && this.props.editableMenu.calendarType ?
                    this.props.editableMenu.calendarType :
                    this.props.selectedCalendarType
            ) || [],
            isLoading: false,
            interval:
                (this.props.editableMenu &&
                    this.props.modifyGroup &&
                    this.props.editableMenu.recurrence &&
                    this.props.editableMenu.recurrence.split('FREQ=')[1] &&
                    !isNaN(
                        Number(
                            this.props.editableMenu.recurrence
                                .split('FREQ=')[1]
                                .split(';')[0]
                        )
                    ) &&
                    Number(
                        this.props.editableMenu.recurrence
                            .split('FREQ=')[1]
                            .split(';')[0]
                    )) ||
                1,
            excludeDates: this.getExcludeDatesFromRecurrence(this.props),
            datePickerMinDate: this.getDatePickerMinDate(this.props),
            datePickerMaxDate: this.getDatePickerMaxDate(this.props),
        };
    }

    getExcludeDatesFromRecurrence(props) {
        if (props.editableMenu && props.modifyGroup && props.editableMenu.recurrence) {
            const recurrence = props.editableMenu.recurrence;
            const exDatesStringFromRecurrence = recurrence.split('EXDATE:')[1];
            const exDatesArrayFromRecurrence = exDatesStringFromRecurrence ? exDatesStringFromRecurrence.split(',').filter(x => x) : [];
            const formattedDatesArray = exDatesArrayFromRecurrence.map((dateString) => {
                return `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
            });
            return formattedDatesArray;
        }
        return [];
    }

    getDatePickerMinDate(props) {
        const timestamp = props.editableMenu && props.editableMenu.timestamp;
        const endDate = (props.editableMenu && props.editableMenu.recurrence && props.modifyGroup)
            ? moment
                .tz(
                    props.editableMenu.recurrence
                        .split('UNTIL=')[1]
                        .split('\n')[0]
                        .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                    props.timezone,
                )
                .unix() * 1000
            : undefined;
        console.log("endDate === ", endDate)
        if (timestamp && endDate) {
            return moment(timestamp).add(1, 'day').toDate();
        }
        return null;
    }

    getDatePickerMaxDate(props) {
        const timestamp = props.editableMenu && props.editableMenu.timestamp;
        const endDate = (props.editableMenu && props.editableMenu.recurrence && props.modifyGroup)
            ? moment
                .tz(
                    props.editableMenu.recurrence
                        .split('UNTIL=')[1]
                        .split('\n')[0]
                        .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                    props.timezone,
                )
                .unix() * 1000
            : undefined;
        console.log("endDate === ", endDate)
        if (timestamp && endDate) {
            return moment(endDate).subtract(1, 'day').toDate();
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.menu.timestamp !== prevState.menu.timestamp ||
            this.state.menu.endDate !== prevState.menu.endDate
        ) {
            this.updateDatePickerRange();
        }
    }

    updateDatePickerRange() {
        const { timestamp, endDate } = this.state.menu;

        if (timestamp && endDate) {
            const datePickerMinDate = moment(timestamp).add(1, 'day').toDate();
            const datePickerMaxDate = moment(endDate).subtract(1, 'day').toDate();

            this.setState({ datePickerMinDate, datePickerMaxDate }, () => {
                this.updateExcludeDates(datePickerMinDate, datePickerMaxDate);
            });
        }
    }

    updateExcludeDates(datePickerMinDate: Date, datePickerMaxDate: Date) {
        const updatedExDates = this.state.excludeDates.filter((date) => {
            return moment(date).isBetween(datePickerMinDate, datePickerMaxDate, 'day', '[]');
        });

        this.setState({ excludeDates: updatedExDates });
    }

    setMenuText(content, delta, source, editor) {
        const htmlText = content;
        const alexaReadableCollection = editor.getContents().ops;
        this.setState({
            menu: {
                ...this.state.menu,
                text: htmlText,
                alexaReadableCollection,
            },
        });
    }

    setMenuTime(time) {
        const localDate = new Intl.DateTimeFormat('sv').format(time); // get localdate in yyyy-mm-dd
        if (this.state.menu.endDate) {
            if (
                moment
                    .tz(localDate, this.state.timezone)
                    .isAfter(moment.tz(this.state.menu.endDate, this.state.timezone))
            ) {
                this.setState({
                    menu: {
                        ...this.state.menu,
                        endDate: undefined,
                    },
                });
            }
        }
        const dateString = moment.tz(localDate, this.state.timezone).format('YYYY-MM-DD');
        this.setState({
            menu: {
                ...this.state.menu,
                timestamp: moment.tz(dateString, this.state.timezone).unix() * 1000,
                dateString: moment(localDate, "YYYY-MM-DD").format('ddd MMM DD YYYY'),
            },
        }, () => this.generateRecurrence());
    }

    validForm() {
        if (this.state.repeatType !== 'OFF') {
            return this.state.menu.text && this.state.menu.timestamp && this.state.menu.endDate;
        } else {
            return this.state.menu.text && this.state.menu.timestamp;
        }
    }

    handleFilePick = async (files: File[]) => {
        const file = files[0];
        console.log({ handleFilePick: file });
        this.setState({
            imageFile: file,
            filePickerStatus: {
                ...this.state.filePickerStatus,
                finished: true,
                isUploading: false,
            },
        });
    };

    async handleSubmit() {
        if (this.props.edit) {
            // const menu = this.state.menu
            // menu._id = this.props._id
            this.props.handleUpdate({
                ...this.state.menu,
                _id: this.props._id,
                imageFile: this.state.imageFile,
                copyImageUrl: this.state.copyImageUrl,
                calendarType: this.state.selectedLocation
            });
        } else {
            this.props.handleSubmit({
                ...this.state.menu,
                category: this.state.menu.category
                    ? this.state.menu.category
                    : this.props.copyMenu
                    ? this.props.copyMenu.category
                    : undefined,
                audioId: this.props.copyMenu ? this.props.copyMenu.audioId : undefined,
                playableUrl: this.props.copyMenu ? this.props.copyMenu.playableUrl : undefined,
                imageFile: this.state.imageFile,
                copyImageUrl: this.state.copyImageUrl,
                calendarType: this.state.selectedLocation
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.copyMenu &&
            nextProps.copyMenu !== prevState.copyMenu &&
            nextProps.copyMenu.text &&
            nextProps.copyMenu.timestamp
        ) {
            return {
                menu: {
                    text: nextProps.copyMenu.text,
                    timestamp: nextProps.copyMenu.timestamp,
                    alexaReadableCollection: nextProps.copyMenu.alexaReadableCollection,
                },
                copyMenu: nextProps.copyMenu,
            };
        }
        return prevState;
    }

    async componentDidMount() {
        this.props.setIsFetching && this.props.setIsFetching(true);
        const facilityId = this.props.profile && this.props.profile.Facility;
        const facilityData: any = (facilityId && await fetchOneFacility(facilityId)) || {};
        const FacilityTimeZone = facilityData.FacilityTimeZone || 'America/New_York';
        this.setState({
            timezone: FacilityTimeZone,
        });
        if (this.props.editableMenu) {
            const extractedInterval =
                this.props.editableMenu &&
                this.props.modifyGroup &&
                this.props.editableMenu.recurrence &&
                this.props.editableMenu.recurrence.split('INTERVAL=')[1] &&
                this.props.editableMenu.recurrence.split('INTERVAL=')[1].split(';')[0];
            const interval = (extractedInterval && !isNaN(Number(extractedInterval))) ? Number(extractedInterval) : 1;
            if (this.props.editableMenu.externalImages){
                this.setState({
                    images: this.props.editableMenu.externalImages,
                });
            }
            this.setState(
                {
                    menu: {
                        ...this.props.editableMenu,
                        timestamp: this.props.modifyGroup
                            ? this.props.editableMenu && this.props.editableMenu.recurrence
                                ? moment
                                      .tz(
                                          this.props.editableMenu.recurrence
                                              .split('\n')[0]
                                              .split('DTSTART=')[1]
                                              .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                                          this.props.timezone,
                                      )
                                      .unix() * 1000
                                : undefined
                            : this.props.editableMenu && this.props.editableMenu.timestamp
                            ? moment.tz(this.props.editableMenu.timestamp, this.props.timezone).unix() * 1000
                            : this.props.date
                            ? moment.tz(this.props.date, this.props.timezone).unix() * 1000
                            : '',
                        endDate:
                            this.props.editableMenu && this.props.editableMenu.recurrence && this.props.modifyGroup
                                ? moment
                                      .tz(
                                          this.props.editableMenu.recurrence
                                              .split('UNTIL=')[1]
                                              .split('\n')[0]
                                              .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                                          this.props.timezone,
                                      )
                                      .unix() * 1000
                                : undefined,
                    },
                    interval
                },
                () => this.generateRecurrence(),
            );
        }
        try {
            this.setState({ isLoading: true });
            listRoomTypes().then((res) => {
                const locationOptions = res.Result && res.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                this.setState({
                    locationOptions,
                    isLoading: false
                });
            });
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to list room types", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            this.setState({ isLoading: false });
        }
        this.props.setIsFetching && this.props.setIsFetching(false);
    }

    handleFileChange(file: File) {
        this.setState({
            imageFile: file,
        });
    }

    handleCopyImage(url: string) {
        // supported file extensions
        const fileExtensions = ['.jpg', '.png'];
        const regex = new RegExp(`(${fileExtensions.join('|')})\\b(?!.*\\1)`);
        const match = url.match(regex);

        if (match && match.index) {
            // If a supported extension is found, truncate the URL at that point
            const truncatedUrl = url.substring(0, match.index + match[0].length);
            this.setState({
                copyImageUrl: truncatedUrl,
            });
        } else {
            // If no supported extension is found, use the original URL
            this.setState({
                copyImageUrl: url,
            });
        }
    }

    clearSignageContentState() {
        this.setState({
            copyImageUrl: null, 
            imageFile: null
        })
    }

    getAndStorePreviewSignageImg(img: string| undefined) {
        this.setState({
            signagePreviewImage: img
        })
    }

    generateRecurrence() {
        const formatDate = (dateString: string) => {
            const [year, month, day] = dateString.split('-');
            return `${year}${month}${day}`;
        };
        const dtstart =
            this.state.menu.timestamp && moment.tz(this.state.menu.timestamp, this.state.timezone).format('YYYYMMDD');
        const until =
            this.state.menu.endDate && moment.tz(this.state.menu.endDate, this.state.timezone).format('YYYYMMDD');
        const frequency = this.state.repeatType !== 'OFF' ? this.state.repeatType : undefined;
        const interval = this.state.interval;
        const excludeDatesString = this.state.excludeDates.length && this.state.excludeDates.map(formatDate).join(',');
        const recurrence =
            dtstart && until && frequency ? `DTSTART=${dtstart}\nRRULE:FREQ=${frequency};INTERVAL=${interval};UNTIL=${until}${excludeDatesString ? `\nEXDATE:${excludeDatesString}` : ''}` 
            : undefined;
        this.setState({
            menu: {
                ...this.state.menu,
                recurrence: recurrence,
            },
        });
    }

    get intervalType() : string {
        const interval = this.state.interval;
        const correctGrammar = (value: string) => interval > 1 ? value + 's' : value;
        switch (this.state.repeatType) {
            case 'DAILY':
                return correctGrammar('Day')
            case 'WEEKLY':
                return correctGrammar('Week')
            case 'MONTHLY':
                return correctGrammar('Month')
            default:
                return '';
        }
    }

    renderSaveBtn(timestamp: number | string) {
        return <Button
            type="submit"
            primary
            loading={this.props.isSaving}
            disabled={this.props.isSaving || !this.validForm()}
        >
            {`Save ${capitalizeFirstLetter(
                this.state.menu.category
                    ? this.state.menu.category
                    : this.props.copyMenu
                    ? this.props.copyMenu.category
                    : '',
            )}`}
        </Button>
    }

    render() {
        return (
            <>
                <Button
                    basic
                    size="large"
                    type={'button'}
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.closeDetailedView && this.props.closeDetailedView();
                    }}
                >
                    <Icon name="chevron left" /> Back to all menu
                </Button>
                <Grid centered>
                    <Grid.Column mobile={16} computer={8} tablet={8}>
                        <div className="edit-item-form">
                            <Form
                                loading={this.props.isFetching}
                                autoComplete="false"
                                onSubmit={this.handleSubmit.bind(this)}
                            >
                                <div className="audio-or-text">
                                    <h3 className="text-center">
                                        {this.props.editableMenu ? 'Update' : 'Create'} your Menu
                                    </h3>
                                    <div className="audio-or-text-header">
                                        <span>Record your voice</span>
                                    </div>

                                    <div className="audio-recorder">
                                        {this.props.passAudioBlob ? (
                                            <AudioRecorder passAudioBlob={this.props.passAudioBlob} />
                                        ) : (
                                            <></>
                                        )}

                                        {this.props.editableMenu && this.props.editableMenu.playableUrl ? (
                                            <div className="current-audio">
                                                <p>To change your audio, press the Mic</p>
                                                <p>
                                                    <br />
                                                    Current Audio
                                                </p>
                                                <audio controls src={this.props.editableMenu.playableUrl}>
                                                    Your browser does not support the
                                                    <code>audio</code> element.
                                                </audio>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        {this.props.copyMenu && this.props.copyMenu.playableUrl ? (
                                            <div className="current-audio">
                                                <p>To change your audio, press the Mic</p>
                                                <p>
                                                    <br />
                                                    Current Audio
                                                </p>
                                                <audio controls src={this.props.copyMenu.playableUrl}>
                                                    Your browser does not support the
                                                    <code>audio</code> element.
                                                </audio>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <Form.Field required>
                                            <ReactQuill
                                                style={{ marginTop: '12px', border: '1px solid #183466' }}
                                                value={this.state.menu.text}
                                                placeholder={`Enter and save your text here`}
                                                onChange={this.setMenuText.bind(this)}
                                                modules={{
                                                    toolbar: [
                                                        [{ size: ['small', false, 'large', 'huge'] }],
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                                        [{ color: [] }, { background: [] }],
                                                        [{ align: [] }],
                                                    ],
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                }}
                                            />
                                        </Form.Field>
                                    </div>
                                    <Form.Field style={{ marginTop: '15px', width: '300px' }}>
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                style={{ border: '1px solid #183466' }}
                                                placeholderText="Select Date"
                                                dateFormat="MMMM d, Y"
                                                selectsStart
                                                selected={getSelectedDate(this.state)}
                                                startDate={
                                                    this.state.menu.timestamp &&
                                                    moment.tz(this.state.menu.timestamp, this.state.timezone).toDate()
                                                }
                                                endDate={
                                                    this.state.menu.endDate &&
                                                    moment.tz(this.state.menu.endDate, this.state.timezone).toDate()
                                                }
                                                onChange={(date) => this.setMenuTime(date)}
                                            />
                                        </div>
                                    </Form.Field>
                                    <Form.Field style={{ marginTop: '15px', width: '300px' }}>
                                        <Dropdown
                                            fluid
                                            selection
                                            options={['breakfast', 'lunch', 'dinner', 'snack', 'alternative'].map(
                                                (item) => {
                                                    return {
                                                        key: item,
                                                        value: item,
                                                        text: item,

                                                        label: {
                                                            className: `menu-${item}`,
                                                            empty: true,
                                                            circular: true,
                                                        },
                                                    };
                                                },
                                            )}
                                            value={this.state.menu.category}
                                            onChange={(event, data) => {
                                                this.setState({
                                                    menu: {
                                                        ...this.state.menu,
                                                        category: `${data.value}`,
                                                    },
                                                });
                                            }}
                                            className="menu-dropdown"
                                        />
                                    </Form.Field>
                                    <Form.Field style={{ width: '300px' }}>
                                        <Dropdown
                                            selection
                                            multiple
                                            control={Select}
                                            placeholder="Select Calendar"
                                            options={sortByKey(this.state.locationOptions)}
                                            onChange={(e, { value }) => {
                                                if (value) {
                                                    this.setState({
                                                        selectedLocation: value as string[]
                                                    });
                                                    this.props.handleLocationUpdate && this.props.handleLocationUpdate(value as [])
                                                }
                                            }}
                                            value={this.state.selectedLocation}
                                            loading={this.state.isLoading}
                                        />
                                    </Form.Field>
                                    <Form.Field style={{ width: '300px' }}>
                                        <Dropdown
                                            placeholder="Repeats"
                                            value={this.state.repeatType}
                                            fluid
                                            selection
                                            options={[
                                                {
                                                    key: 'Off',
                                                    text: 'Repeat off',
                                                    value: 'OFF',
                                                },
                                                {
                                                    key: 'Daily',
                                                    text: 'Repeat daily',
                                                    value: 'DAILY',
                                                },
                                                {
                                                    key: 'Weekly',
                                                    text: 'Repeat weekly',
                                                    value: 'WEEKLY',
                                                },
                                                {
                                                    key: 'Monthly',
                                                    text: 'Repeat monthly',
                                                    value: 'MONTHLY',
                                                },
                                            ]}
                                            onChange={(e, d) => {
                                                this.setState({
                                                    repeatType: String(d.value),
                                                });
                                                if (d.value === 'OFF') {
                                                    this.setState({
                                                        menu: {
                                                            ...this.state.menu,
                                                            endDate: undefined,
                                                            recurrence: undefined,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Field>
                                    {
                                        this.state.repeatType && 
                                        this.state.repeatType !== 'OFF' && 
                                        (
                                            <Form.Field inline>
                                                <label>Once every</label>
                                                <Input
                                                    type='number'
                                                    min={1}
                                                    value={this.state.interval}
                                                    onChange={(e, d) => {
                                                        const interval = d.value;
                                                        if (interval && Number(interval) > 0) {
                                                            this.setState({
                                                                interval: Number(interval),
                                                            }, () => this.generateRecurrence());
                                                        }
                                                    }}
                                                >
                                                </Input>
                                                <label style={{marginLeft: '10px'}}>{this.intervalType}</label>
                                            </Form.Field>
                                        )  
                                    }
                                    {this.state.repeatType != 'OFF' && (
                                        <Form.Field style={{ marginTop: '15px', width: '300px' }}>
                                            <div className="customDatePickerWidth">
                                                <DatePicker
                                                    style={{ border: '1px solid #183466' }}
                                                    placeholderText="Select End Date"
                                                    dateFormat="MMMM d, Y"
                                                    selectsEnd
                                                    selected={
                                                        this.state.menu.endDate &&
                                                        moment.tz(this.state.menu.endDate, this.state.timezone).toDate()
                                                    }
                                                    startDate={
                                                        this.state.menu.timestamp &&
                                                        moment
                                                            .tz(this.state.menu.timestamp, this.state.timezone)
                                                            .toDate()
                                                    }
                                                    endDate={
                                                        this.state.menu.endDate &&
                                                        moment.tz(this.state.menu.endDate, this.state.timezone).toDate()
                                                    }
                                                    minDate={moment
                                                        .tz(this.state.menu.timestamp, this.state.timezone)
                                                        .toDate()}
                                                    onChange={async (date) => {
                                                        const localDate = new Intl.DateTimeFormat('sv').format(
                                                            date || new Date(),
                                                        ); // get localdate in yyyy-mm-dd
                                                        this.setState(
                                                            {
                                                                menu: {
                                                                    ...this.state.menu,
                                                                    endDate:
                                                                        moment
                                                                            .tz(localDate, this.state.timezone)
                                                                            .startOf('day')
                                                                            .unix() * 1000,
                                                                },
                                                            },
                                                            () => this.generateRecurrence(),
                                                        );
                                                    }}
                                                    // style={{ border: '1px solid #183466' }}
                                                />
                                            </div>
                                        </Form.Field>
                                    )}
                                    {this.state.repeatType && this.state.repeatType !== 'OFF' && this.state.menu.timestamp && this.state.menu.endDate && (
                                        <ExcludeDatesField
                                            excludeDates={this.state.excludeDates}
                                            setExcludeDates={(excludeDates) => this.setState({ excludeDates })}
                                            datePickerMinDate={this.state.datePickerMinDate}
                                            datePickerMaxDate={this.state.datePickerMaxDate}
                                            generateRecurrence={() => { this.generateRecurrence(); }}
                                        />
                                    )
                                    }
                                    {!this.props.edit ? (
                                        <Checkbox
                                            toggle
                                            onChange={async (e, data) => {
                                                this.setState({
                                                    menu: {
                                                        ...this.state.menu,
                                                        showAttendance: data.checked,
                                                    },
                                                });
                                            }}
                                            checked={this.state.menu && this.state.menu.showAttendance}
                                            label={`Attendance turned ${
                                                this.state.menu && this.state.menu.showAttendance ? 'on' : 'off'
                                            }`}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {/* <S3FileUpload 
                                        folderName={`activities/${props.activity._id}/images`}
                                        onUploaded={(url) => this.handleFilePick(url)}
                                        accept={['image/*']}
                                        // onSelected={async (file) => {
                                        //     const filenames = [...imageFilenames]
                                        //     filenames.push(file.name)
                                        //     setImageFilenames(filenames)
                                        // }}
                                    /> */}
                                    
                                    <div style={{ position: 'relative' }}>
                                        <Dimmer active={(!this.props.modifyGroup && this.props.editableMenu && this.props.editableMenu.srcId && (this.props.editableMenu._id === this.props.editableMenu.srcId)) || false} inverted></Dimmer>
                                        <Form.Field>
                                            <SignageContentImgCaptureAndDisplay
                                                handleFileChange={this.handleFileChange.bind(this)}
                                                contentId={
                                                    this.props.editableMenu && this.props.editableMenu.signageImageId
                                                        ? this.props.editableMenu.signageImageId
                                                        : this.props._id
                                                }
                                                contentType={'menus'}
                                                galleryImageClickHandler={this.handleCopyImage.bind(this)}
                                                clearSignageState={this.clearSignageContentState.bind(this)}
                                                getFetchedPreviewImage={this.getAndStorePreviewSignageImg.bind(this)}
                                                title={this.state.menu.category ? capitalizeFirstLetter(this.state.menu.category) : ""}
                                                subTitle={this.state.menu.text ? this.state.menu.text.replace(/(<([^>]+)>)/ig, "") : ""}
                                                defaultImage={this.props.editableMenu && this.props.editableMenu.category ? `https://speak2-signage.s3.amazonaws.com/menus/default-images/${this.props.editableMenu.category}.png` : undefined}
                                            />
                                        </Form.Field>
                                        <PhotoGrid imageUrls={this.state.images} />
                                    </div>
                                    {this.renderSaveBtn(this.state.menu.timestamp)}
                                    <Button
                                        onClick={() => {
                                            if (this.props.edit) {
                                                this.props.cancelEditMenu(this.props._id);
                                            } else {
                                                this.setState({
                                                    menu: {
                                                        text: '',
                                                        timestamp: null,
                                                    },
                                                });
                                                this.props.cancelEditMenu();
                                            }
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {this.props.copyClickHandler && (
                                        <Button
                                            className="copyBtn"
                                            type="button"
                                            style={{ padding: '11px 21px' }}
                                            onClick={() => {
                                                if (this.props.copyClickHandler) {
                                                    this.props.copyClickHandler(this.state.signagePreviewImage);
                                                }
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    )}
                                    {this.props.handleDelete && this.props.edit && (
                                        <Button
                                            loading={this.state.isLoading}
                                            disabled={this.state.isLoading}
                                            color="red"
                                            onClick={(e)=>{this.props.handleDelete && this.props.handleDelete(e)}}  
                                        >
                                            Delete
                                        </Button>
                                        )
                                    }
                                    <Divider />
                                    {this.props.edit ? (
                                        <Checkbox
                                            toggle
                                            onChange={async (e, data) => {
                                                if (this.props._id) {
                                                    this.props.setIsFetching && this.props.setIsFetching(true);
                                                    this.setState(
                                                        {
                                                            menu: {
                                                                ...this.state.menu,
                                                                showAttendance: data.checked,
                                                            },
                                                        },
                                                        async () => {
                                                            try {
                                                                await updateMenuAttendance({
                                                                    _id: this.state.menu._id,   
                                                                    showAttendance: this.state.menu.showAttendance,
                                                                });
                                                            } catch (error) {
                                                                toast.warn(error instanceof Error ? error.message : "Failed to update attendance", {
                                                                    position: 'bottom-center',
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                });
                                                            } finally {
                                                                if (data.checked)
                                                                    this.props.setKanbanDataAndSelectedAttendees && this.props.setKanbanDataAndSelectedAttendees(null);
                                                                else
                                                                    this.props.setIsFetching && this.props.setIsFetching(false);
                                                            }
                                                        },
                                                    );
                                                }
                                            }}
                                            checked={this.state.menu && this.state.menu.showAttendance}
                                            label={`Attendance turned ${
                                                this.state.menu && this.state.menu.showAttendance ? 'on' : 'off'
                                            }`}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Form>
                        </div>
                    </Grid.Column>

                    <Grid.Row centered>
                        <Grid.Column width={16}>
                            {this.props.edit && this.props.kanbanBoard ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <h3 style={{ textAlign: 'center' }}>Manage Attendance</h3>
                                        <KanbanBoards
                                            board={this.props.kanbanBoard}
                                            setLoading={this.props.setIsFetching}
                                            loading={this.props.isFetching}
                                            facilityId={this.props.editableMenu && this.props.editableMenu.Facility}
                                            menuId={this.state.menu && this.state.menu._id}
                                            addNewAttendee={(name) => {
                                                this.props.addNewAttendee && this.props.addNewAttendee(name);
                                            }}
                                            removeAttendee={(name) => {
                                                this.props.removeAttendee && this.props.removeAttendee(name);
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

function getTimestamp(props) {
    if (props.modifyGroup) {
        if (props.editableMenu && props.editableMenu.recurrence) {
            return moment
                .tz(
                    props.editableMenu.recurrence
                        .split('\n')[0]
                        .split('DTSTART=')[1]
                        .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                    props.timezone,
                )
                .unix() * 1000;
        }
        return undefined;
    } else {
        if (props.editableMenu && props.editableMenu.timestamp) {
            return moment.tz(props.editableMenu.timestamp, props.timezone).unix() * 1000;
        } else if (props.date) {
            const dateString = moment(props.date).format('YYYY-MM-DD');
            const timeStamp = moment.tz(dateString, props.timezone).unix() * 1000;
            return timeStamp;
        }
        return '';
    }
}

function getSelectedDate(state) {
    if (state.menu.timestamp) {
        const dateString = moment.tz(state.menu.timestamp, state.timezone).format('YYYY-MM-DD');
        return moment(dateString).toDate();
    }
    return new Date();
}


export default withRouter(connect(mapStateToProps)(MenuFormEditor));