import {
    fetchRegistrants,
    fetchResidentStaffCalendarEvents,
} from '../../services/Registrants';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import UnscheduledEventList from '../UnscheduledEventList';
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader';
import { Registrant, ResidentCalendar, User } from '../../types';
import React from 'react';
import { Button, Dimmer, Grid, Icon, Loader, Message, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ResidentCalendarForm from './ResidentCalendarForm';
import PrintExportIcons from '../PrintExportIcons';
import { fetchResidentGroups } from '../../services/RoomGroups';
import { fetchAllStaffUsers } from '../../services/Users';
import { fetchStaffCalendarEvents } from '../../services/StaffCalendar';
import { StaffCalendar } from '../../types/staffCalendar';
import './style.less'
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface State {
    isLoading: boolean;
    showError: boolean;
    errorMessage: string;
    showUserProfileCalendarCreateModal: boolean;
    isLoadingUserProfileCalendar: boolean;
    userProfileCalendarDate: any;
    userProfileCalendarEntries: any;
    userProfileCalendarData: Partial<ResidentCalendar | StaffCalendar>;
    residentOptions: { key: string; value: string; text: string }[] | [];
    staffOptions: { key: string, value: string, text: string }[] | [];
    duration: number;
    isEdit: boolean;
    deleteUserProfileCalendarConfirmation: boolean;
    exportBtnLoader: boolean;
    printBtnLoader: boolean;
    facilityTZ: string;
    disableEditAndDelete: boolean;
    pageSource: string;
}
interface Props extends RouteComponentProps{
    userProfile: Registrant | User | null;
    isStaff?: boolean;
    isEdit?: boolean;
    handleIsEdit?: () => void;
    renderUpsideDown?: boolean;
}

class ResidentStaffCalendarComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            showError: false,
            errorMessage: '',
            showUserProfileCalendarCreateModal: false,
            isLoadingUserProfileCalendar: false,
            userProfileCalendarEntries: [],
            userProfileCalendarDate: moment().hour(9).minute(0).second(0).toDate(),
            userProfileCalendarData: {},
            residentOptions: [],
            staffOptions: [],
            duration: 0,
            isEdit: false,
            deleteUserProfileCalendarConfirmation: false,
            exportBtnLoader: false,
            printBtnLoader: false,
            facilityTZ: 'America/New_York',
            disableEditAndDelete: false,
            pageSource: "ResidentCalendar"
        };
    }

    get allowAddResidentCalendarModal() {
        if (!this.props.isStaff) {
            return (
                this.state.userProfileCalendarData.registrantIds &&
                Array.isArray(this.state.userProfileCalendarData.registrantIds) &&
                this.state.userProfileCalendarData.registrantIds.length > 0
            );
        } else {
            return true
        }
    }

    setResdientCalendarDataField(key: keyof ResidentCalendar, value: any) {
        if (key === 'registrantIds') {
            if (Array.isArray(value)) {
                const handleGroups = value.map((residentId) => {
                    if (residentId.indexOf(',') !== -1) {
                        return residentId.split(',');
                    }
                    return residentId;
                });
                const rooms = handleGroups.flat();
                this.setState(
                    {
                        userProfileCalendarData: {
                            ...this.state.userProfileCalendarData,
                            [key]: rooms,
                        },
                    },
                );
            }
            return;
        }
        if (key === 'staffId') {
            this.setState({
                userProfileCalendarData: {
                    ...this.state.userProfileCalendarData,
                    [key]: value,
                },
            });
            return;
        }

        this.setState(
            {
                userProfileCalendarData: {
                    ...this.state.userProfileCalendarData,
                    [key]: value,
                },
            },
            () => {
                if (key === 'dateTimeString') { // we need to update endDateTimeString whenever dateTimeString changes
                    const duration = this.state.duration;
                    if (!this.state.duration) {
                        const endDateTimeString = moment(this.state.userProfileCalendarData.dateTimeString)
                            .add(1, 'm')
                            .format('YYYY-MM-DDTHH:mm:ss');
                        this.setResdientCalendarDataField('endDateTimeString', endDateTimeString);
                    } else {
                        const endDateTimeString = moment(this.state.userProfileCalendarData.dateTimeString)
                            .add(duration, 'm')
                            .format('YYYY-MM-DDTHH:mm:ss');
                        this.setResdientCalendarDataField('endDateTimeString', endDateTimeString);

                    }
                }
            },
        );
    }

    handleUpdateResidentCalendar = (payload) => {
        // for request instance event in calendar when it is clicked we need to redirect to request instance detailed page
        // for other events we need to open the modal
        if (payload && payload.instanceId && payload.instanceType && payload.instanceType == "requestInstance") {
            // redirect to request instance detailed page
            return this.props.history.push(`/admin/request/${String(payload.instanceId)}`);
        }
        this.setState({ isLoadingUserProfileCalendar: false });
        const data = Object.assign({}, payload);
        if (data && data.syncStatus === 'merged') {
            this.setState({
                disableEditAndDelete: true
            });
        } else {
            this.setState({
                disableEditAndDelete: false
            });
        }
        const duration = data.endDateTimeString
            ? Math.floor((Date.parse(data.endDateTimeString) - Date.parse(data.dateTimeString)) / 1000 / 60)
            : 0;
        this.setState({
            isEdit: true,
            userProfileCalendarData: data,
            showUserProfileCalendarCreateModal: true,
            duration,
        });
    };

    displayError = (errorMessage: string) => {
        if (errorMessage.length > 0) {  // this is just to turn off the error after a successful caled
            this.setState({
                showError: true,
                errorMessage,
            });

        } else {
            this.setState({
                showError: false,
                errorMessage,
            });
        }
    };

    refreshResidentCalendarItems = async () => {
        await this.fetchResidentStaffCalendar(
            this.props.userProfile && this.props.userProfile._id,
            new Date(this.state.userProfileCalendarDate),
        );
    };

    handleSelectSlotForResidentCalendar = async (payload) => {
        const data = Object.assign({}, payload);
        const userProfile = this.props && this.props.userProfile && this.props.userProfile && this.props.userProfile._id;
        if (userProfile && !this.props.isStaff) {
            data.registrantIds = [userProfile];
        }
        const duration = data.endDateTimeString
            ? Math.floor((Date.parse(data.endDateTimeString) - Date.parse(data.dateTimeString)) / 1000 / 60)
            : 0;
        this.setState({
            isEdit: false,
            userProfileCalendarData: data,
            showUserProfileCalendarCreateModal: true,
            duration,
        });
    };

    formatAndSortResidents(residents) {
        const formattedResidents = residents.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.LastName}` || "",
                value: `${obj._id}`,
            };
        });

        const sortedResidents = formattedResidents.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedResidents;
    }

    formatAndSortStaffs(staff) {
        const formattedStaffs = staff.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.LastName}` || "",
                value: `${obj._id}`,
            };
        });

        const sortedStaffs = formattedStaffs.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedStaffs;
    };

    fetchResidentStaffCalendar = async (registrantId: string | null, date: Date) => {
        this.setState({ isLoadingUserProfileCalendar: true });
        // fetch data for the seleted resident and date
        this.setState({
            userProfileCalendarDate: date,
        });
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const startDate = formattedDate + 'T00:00:00'; // YYYY-MM-DDTHH:mm:ss
        const endDate = formattedDate + 'T23:59:59'; // YYYY-MM-DDTHH:mm:ss
        try {
            const data = this.props.isStaff ? await fetchStaffCalendarEvents(registrantId, startDate, endDate) : await fetchResidentStaffCalendarEvents(registrantId, startDate, endDate);
            this.setState({
                userProfileCalendarEntries: data,
            });
        } catch (error) {
            // do not throw error
            this.displayError('Unabled to fetch notepad events');
            console.error(error);
        }
        this.setState({ isLoadingUserProfileCalendar: false });
    };

    refreshResidentRooms = async () => {
        this.setState({ isLoadingUserProfileCalendar: true });
        // fetch residents of the facility and store in state
        try {
            const staffDetails = await fetchAllStaffUsers();
            const formatedStaffOptions = this.formatAndSortStaffs(staffDetails)
            const residentsWithRooms = await fetchRegistrants();
            const formattedResidentOptions = this.formatAndSortResidents(residentsWithRooms);
            const roomGroups = await fetchResidentGroups();
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);
            const roomOptions = [].concat(sortedRoomGroups).concat(formattedResidentOptions);
            this.setState({
                staffOptions: formatedStaffOptions,
                residentOptions: roomOptions
            })
        } catch (error) {
            // do not throw error
            console.error(error);
            this.displayError('Unable to fetch resident list');
        }
        this.setState({ isLoadingUserProfileCalendar: false });
    };

    formatAndSortRoomGroups = (groups: any) => {
        const formattedRoomGroups = groups.map((obj) => {
            if (obj.ResidentIds && obj.ResidentIds.length <= 1) return; // don't show groups which does not have atleast 2 ResidentIds (To take care of wierd issues with residentGroups)
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.ResidentIds]}`,
            };
        }).filter(obj => obj);;

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRoomGroups;
    }

    resetResidentCalendarDate = () => {
        if (!this.props.isStaff) {
            if (this.props.userProfile && this.props.userProfile._id) {
                this.setState({
                    userProfileCalendarData: {
                        registrantIds: [this.props.userProfile._id],
                    },
                    isEdit: false
                });
            }
        } else {
            this.setState({
                userProfileCalendarData: {
                },
                isEdit: false
            });
        }
    };

    async componentDidMount() {
        this.setState({ isLoading: true })
        try {
            if (this.props.userProfile && this.props.userProfile._id) this.setResdientCalendarDataField('staffId', this.props.userProfile._id)
            await Promise.all([
                this.refreshResidentRooms(),
                this.fetchResidentStaffCalendar(this.props.userProfile && this.props.userProfile._id, new Date()),
            ]);
        } catch (error) {
            console.error(error);
            this.displayError('Unable to refresh notepad');
        }
        if (this.props && this.props.userProfile) {
            this.setState({ isLoadingUserProfileCalendar: true });
            const currentResident = this.state.residentOptions.find(
                (resident) => resident.key === (this.props && this.props.userProfile && this.props.userProfile._id),
            );
            if (currentResident && typeof currentResident.value === 'string') {
                this.setState({
                    userProfileCalendarData: {
                        ...this.state.userProfileCalendarData,
                        registrantIds: [currentResident.value],
                    },
                });
            }
            this.setState({ isLoadingUserProfileCalendar: false });
        }
        this.setState({ isLoading: false })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showUserProfileCalendarCreateModal !== this.state.showUserProfileCalendarCreateModal) {
            if (this.props.isStaff) {
                if (this.props.userProfile && this.props.userProfile._id) this.setResdientCalendarDataField('staffId', this.props.userProfile._id)
            }
        }
    }

    handlePrintClick = async () => {
        this.setState({
            printBtnLoader: true,
        });
        const printCalendarPayload = this.state.userProfileCalendarEntries;
        try {
            if (!printCalendarPayload || !printCalendarPayload.length) {
                toast.error('There are no events to print', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                const formattedData = printCalendarPayload.map((residentEvent) => {
                    return {
                        Subject: residentEvent.text,
                        StartDate: residentEvent.dateTimeString,
                        StartTime: residentEvent.dateTimeString,
                    };
                });
                await formatAndExportDataForPrint(
                    formattedData,
                    'Unscheduled-events',
                    this.state.facilityTZ,
                    'activity',
                ); // activity formats date in  "YYYY-MM-DD hh:mm A"
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to print the calendar', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
        this.setState({
            printBtnLoader: false,
        });
    };

    formatExportData = () => {
        return this.state.userProfileCalendarEntries.map((entry: ResidentCalendar) => {
            return {
                Event: entry.text || '',
                Location: entry.location || '',
                StartDate: moment(entry.dateTimeString).format('MM-DD-YYYY'),
                StartTime: moment(entry.dateTimeString).format('hh:mm A'),
                EndDate: moment(entry.endDateTimeString).format('MM-DD-YYYY'),
                EndTime: moment(entry.endDateTimeString).format('hh:mm A'),
            };
        });
    };

    render() {
        return (
            <>
                <Dimmer active={this.state.isLoadingUserProfileCalendar || this.state.isLoading} inverted>
                    <Loader active={this.state.isLoadingUserProfileCalendar || this.state.isLoading} />
                </Dimmer>
                {this.state.showError && <Message negative>{this.state.errorMessage}</Message>}
                <ResidentCalendarForm
                    setLoader={(value: boolean) => this.setState({ isLoadingUserProfileCalendar: value })}
                    showModal={this.state.showUserProfileCalendarCreateModal}
                    closeForm={() => this.setState({ showUserProfileCalendarCreateModal: false })}
                    isEdit={this.state.isEdit}
                    residentCalendarData={this.state.userProfileCalendarData}
                    setResdientCalendarDataField={(...values) => this.setResdientCalendarDataField(...values)}
                    displayError={this.displayError}
                    refresh={this.refreshResidentCalendarItems}
                    resetResidentCalendarData={() => this.resetResidentCalendarDate()}
                    residentOptions={this.state.residentOptions}
                    staffOptions={this.state.staffOptions}
                    duration={this.state.duration}
                    setDuration={(value: number) => this.setState({ duration: value })}
                    isStaff={this.props.isStaff}
                    disableEditAndDelete={this.state.disableEditAndDelete}
                    pageSource={this.state.pageSource}
                />
                {
                    this.props.isStaff ?
                        <div style={{ marginTop: "1rem" }}>
                            {!this.props.isEdit ? <Grid >
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <div className='customDatePickerWidth' >
                                            <DatePicker
                                                open={true}
                                                onChange={async (date: Date) => {
                                                    await this.fetchResidentStaffCalendar(
                                                        this.props &&
                                                        this.props.userProfile &&
                                                        this.props.userProfile._id &&
                                                        this.props.userProfile._id,
                                                        date,
                                                    );
                                                }}
                                                style={{ border: '1px solid #183466' }}
                                                inline
                                                selected={this.state.userProfileCalendarDate}
                                                onMonthChange={async (date) => {
                                                    this.setState({
                                                        userProfileCalendarDate: date,
                                                    });
                                                    await this.fetchResidentStaffCalendar(
                                                        this.props &&
                                                        this.props.userProfile &&
                                                        this.props.userProfile._id &&
                                                        this.props.userProfile._id,
                                                        date,
                                                    );
                                                }}
                                            />

                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <div className="button-container">
                                            <Grid.Column style={{ height: '75vh' }} floated='right' >
                                                <div className='container-date' style={{ paddingTop: "0px" }}>
                                                    <Popup
                                                        trigger={<Button className='searchBtnPlus' icon='plus' size='large' onClick={(e) => {
                                                            e.preventDefault()
                                                            this.setState(prev => ({
                                                                ...prev, showUserProfileCalendarCreateModal: !this.state.showUserProfileCalendarCreateModal
                                                            }))
                                                        }} style={{ background: "none", padding: 0, marginRight: "12px" }} loading={this.state.isLoading || false} />}
                                                        content='Create Calendar Item'
                                                        size='tiny'
                                                        type="button"
                                                    />
                                                    <PrintExportIcons
                                                        printLoading={this.state.printBtnLoader}
                                                        onPrintClick={this.handlePrintClick}
                                                        exportData={this.formatExportData()}
                                                        exportFileName="Unscheduled-events"
                                                    />

                                                </div>

                                                <UnscheduledEventList
                                                    events={this.state.userProfileCalendarEntries}
                                                    editResidentCalendarEntry={this.handleUpdateResidentCalendar}
                                                    allowEdit={this.allowAddResidentCalendarModal}
                                                    onSelection={this.handleSelectSlotForResidentCalendar}
                                                    selectedDay={this.state.userProfileCalendarDate}
                                                />
                                            </Grid.Column>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid> :
                                <>
                                    <div className='customDatePickerWidth' >
                                        <DatePicker
                                            open={true}
                                            onChange={async (date: Date) => {
                                                await this.fetchResidentStaffCalendar(
                                                    this.props &&
                                                    this.props.userProfile &&
                                                    this.props.userProfile._id &&
                                                    this.props.userProfile._id,
                                                    date,
                                                );
                                            }}
                                            style={{ border: '1px solid #183466' }}
                                            inline
                                            selected={this.state.userProfileCalendarDate}
                                            onMonthChange={async (date) => {
                                                this.setState({
                                                    userProfileCalendarDate: date,
                                                });
                                                await this.fetchResidentStaffCalendar(
                                                    this.props &&
                                                    this.props.userProfile &&
                                                    this.props.userProfile._id &&
                                                    this.props.userProfile._id,
                                                    date,
                                                );
                                            }}
                                        />

                                    </div>
                                    <div className="button-container">
                                        <Grid.Column style={{ height: '75vh' }} floated='right' >
                                            <div className='container-date'>
                                                <Popup
                                                    trigger={<Button className='searchBtnPlus' icon='plus' size='large' onClick={(e) => {
                                                        e.preventDefault()
                                                        this.setState(prev => ({
                                                            ...prev, showUserProfileCalendarCreateModal: !this.state.showUserProfileCalendarCreateModal
                                                        }))
                                                    }} style={{ background: "none", padding: 0, marginRight: "12px" }} loading={this.state.isLoading || false} />}
                                                    content='Create Calendar Item'
                                                    size='tiny'
                                                    type="button"
                                                />
                                                <PrintExportIcons
                                                    printLoading={this.state.printBtnLoader}
                                                    onPrintClick={this.handlePrintClick}
                                                    exportData={this.formatExportData()}
                                                    exportFileName="Unscheduled-events"
                                                />

                                            </div>

                                            <UnscheduledEventList
                                                events={this.state.userProfileCalendarEntries}
                                                editResidentCalendarEntry={this.handleUpdateResidentCalendar}
                                                allowEdit={this.allowAddResidentCalendarModal}
                                                onSelection={this.handleSelectSlotForResidentCalendar}
                                                selectedDay={this.state.userProfileCalendarDate}
                                            />
                                        </Grid.Column>
                                    </div>
                                </>}

                        </div>
                        :
                        <Grid columns="equal" divided relaxed padded>
                            <Grid.Column mobile={16} tablet={16} computer={this.props.renderUpsideDown ? 16 : 4}>
                                <div style={{ display: 'flex', marginBottom: "5px", gap: "5px" }}>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        disabled={!this.allowAddResidentCalendarModal}
                                        loading={!this.allowAddResidentCalendarModal}
                                        primary
                                        size={'tiny'}
                                        onClick={(e) => {
                                            const nineAmDateTimeString = moment().hour(9).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
                                            e.preventDefault();
                                            this.setResdientCalendarDataField(
                                                'dateTimeString',
                                                nineAmDateTimeString,
                                            );
                                            this.setState({
                                                showUserProfileCalendarCreateModal: !this.state.showUserProfileCalendarCreateModal,
                                                isEdit: false,
                                            });
                                        }}
                                    >
                                        Add <Icon name="add" />
                                    </Button>
                                    <PrintExportIcons
                                        printLoading={this.state.printBtnLoader}
                                        onPrintClick={this.handlePrintClick}
                                        exportData={this.formatExportData()}
                                        exportFileName="Unscheduled-events"
                                    />
                                </div>
                                <DatePicker
                                    open={true}
                                    onChange={async (date: Date) => {
                                        await this.fetchResidentStaffCalendar(
                                            this.props &&
                                            this.props.userProfile &&
                                            this.props.userProfile._id &&
                                            this.props.userProfile._id,
                                            date,
                                        );
                                    }}
                                    style={{ border: '1px solid #183466' }}
                                    inline
                                    selected={this.state.userProfileCalendarDate}
                                    onMonthChange={async (date) => {
                                        this.setState({
                                            userProfileCalendarDate: date,
                                        });
                                        await this.fetchResidentStaffCalendar(
                                            this.props &&
                                            this.props.userProfile &&
                                            this.props.userProfile._id &&
                                            this.props.userProfile._id,
                                            date,
                                        );
                                    }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ height: '75vh' }} floated='right'>
                                <UnscheduledEventList
                                    events={this.state.userProfileCalendarEntries}
                                    editResidentCalendarEntry={this.handleUpdateResidentCalendar}
                                    allowEdit={this.allowAddResidentCalendarModal}
                                    onSelection={this.handleSelectSlotForResidentCalendar}
                                    selectedDay={this.state.userProfileCalendarDate}
                                />
                            </Grid.Column>
                        </Grid>
                }
            </>
        );
    }
}

export default  withRouter(ResidentStaffCalendarComponent) ;
