import React from 'react'
import ProtectedRoute from '../../../components/ProtectedRoute'

import { AppState } from '../../../reducers'
import { connect } from 'react-redux'
import { UserProfile } from '../../../types'
import ListUnits from '../../../pages/AdminPanel/Units/List'
import EditUnit from '../../../pages/AdminPanel/Units/Edit'
import CreateUnit from '../../../pages/AdminPanel/Units/Create'
import ListDepartments from '../../../pages/AdminPanel/Departments/List'
import EditDepartment from '../../../pages/AdminPanel/Departments/Edit'
import CreateDepartment from '../../../pages/AdminPanel/Departments/Create'
import ListContacts from '../../../pages/AdminPanel/Contacts/List'
import EditContact from '../../../pages/AdminPanel/Contacts/Edit'
import CreateContact from '../../../pages/AdminPanel/Contacts/Create'
import ListRequestTypes from '../../../pages/AdminPanel/RequestTypes/List'
import EditRequestTypes from '../../../pages/AdminPanel/RequestTypes/Edit'
import CreateRequestType from '../../../pages/AdminPanel/RequestTypes/Create'
import Speak2FamilyBroadcastIncomingList from '../../../pages/AdminPanel/Speak2FamilyBroadcast/List'
import ManageSkills from '../../../pages/AdminPanel/ManageSkills'
import {
    canManageUnits,
    canManageDepartments,
    canManageContacts,
    canManageRequestTypes,
    canManageUsers,
    isAdmin,
    isSysAdmin
} from '../../../services/Permissions'
import Tabs from './Tabs'
import { Grid, Header, Modal, Segment, TabPane } from 'semantic-ui-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import LocationTable from '../../../components/ManageFacility/LocationTable';

interface Props extends RouteComponentProps {
    isAuthenticated: boolean
    profile: UserProfile | null
}
interface State { 
    refreshKey: number
}
interface TabType {
    name: string;
    url: string;
    icon: string;
    disabled?: boolean;
}

const tabItems = (profile: UserProfile | null) => [
    {
        name: 'Service Types',
        Component: ListRequestTypes,
        icon: 'wrench',
        disabled: !canManageRequestTypes(profile),
    },
    {
        name: 'Apartments',
        Component: ListUnits,
        icon: 'bed',
        disabled: !canManageUnits(profile),
    },
    {
        name: 'Departments',
        Component: ListDepartments,
        icon: 'hospital',
        disabled: !canManageDepartments(profile),
    },
    {
        name: 'Special Contacts',
        Component: ListContacts,
        icon: 'address book',
        disabled: !canManageContacts(profile),
    },
    // todo take care of the below item
    isSysAdmin(profile) ? {
        name: 'Manage Skills',
        url: '/admin/forms/properties-skills',
        icon: 'sliders',
    } : {
        name: 'Manage Skills',
        Component: ManageSkills,
        icon: 'sliders',
        disabled: !isAdmin(profile),
    },
    {
        name: "Calendar Locations",
        Component: LocationTable,
        disabled: !isAdmin(profile)
    }
]

class FormIndex extends React.Component<Props, State> {
    tabItemsArr = tabItems(this.props.profile)
    constructor(props: Props) {
        super(props);
        this.state = {
            refreshKey: 0
        }
    }

    refreshKeyFunHandler = () => {
        this.setState({
            refreshKey: this.state.refreshKey + 1
        })
    }
    renderProtectedRoutes() {
        return <>
            <ProtectedRoute
                path="/admin/forms/units/create"
                exact
                component={CreateUnit}
                condition={canManageUnits(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/unit/:id"
                exact
                component={() => <EditUnit refreshListFun={this.refreshKeyFunHandler.bind(this)}/>}
                condition={canManageUnits(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/departments/create"
                exact
                component={CreateDepartment}
                condition={canManageDepartments(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/department/:id"
                exact
                component={() => <EditDepartment refreshListFun={this.refreshKeyFunHandler.bind(this)} />} 
                condition={canManageDepartments(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/contacts/create"
                exact
                component={CreateContact}
                condition={canManageContacts(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/contact/:id"
                exact
                component={ () => <EditContact refreshListFun={this.refreshKeyFunHandler.bind(this)} /> }
                condition={canManageContacts(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/requesttypes/create"
                exact
                component={CreateRequestType}
                condition={canManageRequestTypes(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/requesttype/:id"
                exact
                component={() => <EditRequestTypes refreshListFun={this.refreshKeyFunHandler.bind(this)}/>}
                condition={canManageRequestTypes(this.props.profile)}
                redirect="/"
            />
            <ProtectedRoute
                path="/admin/forms/skills"
                exact
                component={ManageSkills}
                condition={isAdmin(this.props.profile)}
                redirect="/"
            />
        </>
    }
    render() {
        const { pathname } = this.props.location;
        const isAdminFormsPage = pathname === '/admin/forms';
        return (
            <>
                {
                    !isAdminFormsPage ?
                        <Segment padded style={{marginBottom: "30px"}}>
                            {this.renderProtectedRoutes()}
                        </Segment>
                        : this.renderProtectedRoutes()
                }
                <Grid columns={3} relaxed verticalAlign='top'>
                    {/* todo write a else case for this  */}
                    {Array.isArray(this.tabItemsArr) && this.tabItemsArr.map(({ name, Component }) => {
                        return <Grid.Column width={5} style={{ height: "500px", overflowY: "scroll", marginBottom: "10px" }}>
                            <Segment padded>
                                <Header as={"h3"}>{name}</Header>
                                {Component ? <Component key={this.state.refreshKey} /> : null}
                            </Segment>
                        </Grid.Column>
                    })}
                </Grid>
            </>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        isAuthenticated: authReducer.isAuthenticated,
        profile: authReducer.profile,
    }
}

function mapDispatchToProps() {
    return {}
}

const ConnectedFormIndex = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormIndex);

export default withRouter(ConnectedFormIndex);
