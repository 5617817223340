import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import moment from 'moment-timezone';

import Board, { moveCard } from "@asseinfo/react-kanban"
import "@asseinfo/react-kanban/dist/styles.css";
import "./style.less"
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react';
import { sortKanbanList } from '../../../util';
function CheckinKanbanBoard ({ board, updateCard, colNamesList, preventMovementToCol, exceptedSourceColName, exceptionMoveCardFunc, updateSourceBoard, selectedDate, facilityTimeZone }) {
    const [controlledBoard, setControlledBoard] = useState(board)
    const [shouldSort, setShouldSort] = useState(true)
    const [selectedSortingOption, setSelectedSortingOption] = useState('');
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
        if (shouldSort && board && board.columns && board.columns.length > 0 && board.columns[0].cards && board.columns[0].cards.length > 0) {
            board.columns.forEach((item) => {
                item.cards.sort((a, b) => {
                    const aLastName = a.registrant && a.registrant.LastName ? a.registrant.LastName : '';
                    const bLastName = b.registrant && b.registrant.LastName ? b.registrant.LastName : '';
                    return aLastName < bLastName ? -1 : aLastName > bLastName ? 1 : 0;
                });
            });
            setShouldSort(false);
        }
        setControlledBoard(board);
    }, [board])
    const sortingOptions = [
        { key: 'FirstName', text: 'First Name', value: 'FirstName' },
        { key: 'LastName', text: 'Last Name', value: 'LastName' },
        { key: 'RoomName', text: 'Apartment Name', value: 'RoomName' },
    ];

    const addCardBackToSource = (localBoard, colIndex, positionIndex, card) => {
        const cardCopy = JSON.parse(JSON.stringify(card))
        delete cardCopy.description
        delete cardCopy.timestamp
        cardCopy.id = Date.now() + 'all-registrants' + cardCopy.registrantId
        localBoard.columns[colIndex].cards.splice(positionIndex, 0, cardCopy)
        return localBoard
    }
    async function handleCardMove(_card, source, destination) {
        setSelectedSortingOption('');
        if (
                source.fromColumnId !== destination.toColumnId && 
                destination.toColumnId !== colNamesList.indexOf(preventMovementToCol)
            ) {
            // If the source column is an excepted column, call the exception function, else update the card as
            if (source.fromColumnId === colNamesList.indexOf(exceptedSourceColName)) {
                const currentTime = moment.tz(Date.now(), facilityTimeZone).format("HH:mm:ss");
                const generatedDateTimeString = `${selectedDate}T${currentTime}`;
                _card.timestamp = moment.tz(generatedDateTimeString, facilityTimeZone).valueOf();
                _card.description = moment
                    .tz(generatedDateTimeString, facilityTimeZone)
                    .format('MM/DD/YYYY hh:mm:ss A');
                _card.id = Date.now() + _card.id
                let updatedBoard = moveCard(controlledBoard, source, destination)
                updatedBoard = addCardBackToSource(updatedBoard, source.fromColumnId, source.fromPosition, _card)
                setControlledBoard(updatedBoard)
                updateSourceBoard(updatedBoard)
                const { newItemId } = await exceptionMoveCardFunc(destination.toColumnId, _card)
                _card._id = newItemId
                _card.id = newItemId
                _card.key = newItemId
                console.log('newItemId: ', newItemId)
                console.log('Moved card: ', updatedBoard.columns[destination.toColumnId].cards[destination.toPosition])
                console.log('Newly added back card: ', updatedBoard.columns[source.fromColumnId].cards[source.fromPosition])

            } else {
                const column = colNamesList[destination.toColumnId]
                const prev_col = colNamesList[source.fromColumnId]
                const currentTime = moment.tz(Date.now(), facilityTimeZone).format("HH:mm:ss");
                const generatedDateTimeString = `${selectedDate}T${currentTime}`;
                const timestamp = moment.tz(generatedDateTimeString, facilityTimeZone).valueOf();
                _card.description = moment
                    .tz(generatedDateTimeString, facilityTimeZone)
                    .format('MM/DD/YYYY hh:mm:ss A');

                const newCard = {
                    ..._card,
                    column,
                    prev_col,
                    timestamp
                }

                if (newCard._id) {
                    const updatedBoard = moveCard(controlledBoard, source, destination)
                    setControlledBoard(updatedBoard)
                    updateSourceBoard(updatedBoard)
                    await updateCard(newCard)
                } else {
                    _card.timestamp = timestamp;
                    _card.id = Date.now() + _card.id
                    let updatedBoard = moveCard(controlledBoard, source, destination)
                    // updatedBoard = addCardBackToSource(updatedBoard, source.fromColumnId, source.fromPosition, _card)
                    setControlledBoard(updatedBoard)
                    updateSourceBoard(updatedBoard)
                    const { newItemId } = await exceptionMoveCardFunc(destination.toColumnId, _card)
                    _card._id = newItemId
                    _card.id = newItemId
                    _card.key = newItemId
                }
            }
        }
    }
    const RenderCard = (card) => {
        return (
            <div className="react-kanban-card">
                <div className="react-kanban-column-header">
                    <div className="react-kanban-card__title">{card.title}</div>
                </div>
                <div className="react-kanban-card__description">
                    {card.roomName && (
                        <p>
                            <span style={{ color: 'grey' }}>Apartment Name: </span>
                            {card.roomName}
                        </p>
                    )}
                    {card.description && card.roomName && <hr style={{ border: '1px solid #F0F0F0' }} />}
                    {card.description && <p>{card.description}</p>}
                </div>
            </div>
        );
    };
    const handleSortingDropdownChange = (value) => {
        setLoading(true);
        setSelectedSortingOption(value);
        sortKanbanList(value, controlledBoard);
        setLoading(false);
    };
    return (
        <>
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            <Dropdown
                style={{ marginLeft: '10px', marginTop: '10px' }}
                placeholder={'Sort By'}
                closeOnEscape
                value={selectedSortingOption}
                selection
                scrolling
                options={sortingOptions}
                onChange={(e, { value = "" }) => handleSortingDropdownChange(value)}
            />
            <Board
                onCardDragEnd={handleCardMove}
                disableColumnDrag
                renderCard={RenderCard}
            >
                {controlledBoard}
            </Board>
        </>
    )
}

export default CheckinKanbanBoard
