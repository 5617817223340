import { authReducer } from './auth'
import { requestsReducer } from './requests'
import { combineReducers } from 'redux'
import { remoteVideoReducer } from './remoteVideo'

export const rootReducer = combineReducers({
    authReducer,
    requestsReducer,
    remoteVideoReducer,
})

export type AppState = ReturnType<typeof rootReducer>
