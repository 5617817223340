import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import RegistrantEditor from '../RegistrantEditor'
import { Unit, Registrant, User } from '../../../../types'
import { fetchAllUnits } from '../../../../services/Units'
import { createAndAssignRegistrant } from '../../../../services/Registrants'
import { fetchStaffRoleUsers } from '../../../../services/Users'

interface State {
    units: Unit[]
    staffUsers: (User | { _id: string; FirstName: string })[]
    isFetching: boolean
    hasError: boolean
    error: string | null
    isSaving: boolean
}
class CreateRegistrant extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            units: [],
            staffUsers: [],
            isFetching: false,
            hasError: false,
            error: null,
            isSaving: false,
        }
    }

    async componentDidMount() {
        try {
            this.setState({
                isFetching: true,
                hasError: false,
                error: null,
            })

            const [units, staffUsers] = await Promise.all([fetchAllUnits(), fetchStaffRoleUsers()])

            this.setState({
                isFetching: false,
                hasError: false,
                units,
                staffUsers,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message || 'Could not fetch units or staff.',
            })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(registrant: Partial<Registrant>, unit: Unit | null, unitUpdated: boolean = true) {
        this.setState({
            isSaving: true,
            hasError: false,
            error: null,
        })
        try {
            await createAndAssignRegistrant(registrant, unit && unit._id)
            this.setState({
                isSaving: false,
                hasError: false,
                error: null,
            })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                hasError: true,
                error: e.message || 'Could not save Resident.',
            })
        }
    }

    render() {
        return (
            <div className="EditRegistrant">
                <RegistrantEditor
                    {...this.state}
                    registrant={null}
                    cancel={this.goBack.bind(this)}
                    onSave={this.handleSave.bind(this)}
                />
            </div>
        )
    }
}

export default withRouter(CreateRegistrant)
