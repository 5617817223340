import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm, Checkbox } from 'semantic-ui-react'
import * as Yup from 'yup'
import ReactSlider from 'react-slider'
import {
    GenericTextField,
    GenericImageField,
    GenericDataSourceDropdown,
} from '../../../../components/GenericFormFields'

import './style.less'
import { Department, RequestType } from '../../../../types'

export interface FormValues {
    Name: string
    Category: string
    Department: Department | null
    EscalationDepartment: Department | null
    EscalationTimeMinutes: number
    NotificationMethodA: any
    NotificationMethodB: any
    Icon: string
    NotificationATimeMinutes: number
    NotificationBTimeMinutes: number
    NotifyFamily: boolean
    EscalationEnabled: boolean
}

const ValidationSchema = Yup.object().shape({
    Name: Yup.string().required('Service name is required'),
    Category: Yup.string().required('Category is required'),
    Department: Yup.object()
        .nullable()
        .required('Department is required'),
    EscalationDepartment: Yup.object()
        .nullable()
        .required('Escalation Department is required'),
    EscalationTimeMinutes: Yup.number()
        .required()
        .default(20),
    NotificationMethodA: Yup.string().required('At least 1 notification method is required'),
    // NotificationMethodB: Yup.string(),
    Icon: Yup.string(),
    NotificationATimeMinutes: Yup.number(),
    NotificationBTimeMinutes: Yup.number(),
    NotifyFamily: Yup.bool(),
})

const imagePlaceholder = `${process.env.PUBLIC_URL}/request_type_placeholder.png`

interface Props {
    requestType: RequestType | null
    departments: Department[]
    error: string | null
    title: string
    onSave: (data: FormValues) => Promise<any>
    cancel: () => any
    onDelete?: (id: string) => Promise<any>
    isSaving?: boolean
}

const getDepartmentById = (id: string, departments: Department[]) => {
    return departments.find(d => d._id === id)
}

const getNotificationMethod = NotificationMethod => {
    const _id = NotificationMethod && NotificationMethod.toLowerCase()
    return {
        _id,
        Name: _id && (_id == 'email' ? 'Email' : 'SMS'),
    }
}

const RequestTypesForm: React.SFC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Name: (props.requestType && props.requestType.Name) || '',
        Category: (props.requestType && props.requestType.Category) || '',
        Department: (props.requestType && getDepartmentById(props.requestType.Department, props.departments)) || null,
        EscalationDepartment:
            (props.requestType && getDepartmentById(props.requestType.EscalationDepartment, props.departments)) || null,
        EscalationTimeMinutes: (props.requestType && props.requestType.EscalationTimeMinutes) || 20,
        NotificationMethodA:
            (props.requestType &&
                props.requestType.NotificationMethodA &&
                getNotificationMethod(props.requestType.NotificationMethodA)) ||
            getNotificationMethod('email'),
        NotificationMethodB:
            props.requestType &&
            props.requestType.NotificationMethodB &&
            getNotificationMethod(props.requestType.NotificationMethodB),
        Icon: (props.requestType && props.requestType.Icon) || '',
        NotificationATimeMinutes: (props.requestType && props.requestType.NotificationATimeMinutes) || 20,
        NotificationBTimeMinutes: (props.requestType && props.requestType.NotificationBTimeMinutes) || 30,
        NotifyFamily: (props.requestType && props.requestType.NotifyFamily) || false,
        EscalationEnabled: (props.requestType && props.requestType.EscalationEnabled) || false,
    })

    const canDelete = () => {
        return props.onDelete && props.requestType && props.requestType._id
    }

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.requestType && props.requestType._id) {
            props.onDelete(props.requestType._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }

    return (
        <div className="RequestTypesForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                    console.log('values', values)
                    await props.onSave(values)
                    actions.setSubmitting(false)
                }}
                validationSchema={ValidationSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField name="Icon" component={GenericImageField} placeholder={imagePlaceholder} />
                        <FormikField required name="Name" component={GenericTextField} placeholder="Service Name" />
                        <FormikField required name="Category" component={GenericTextField} placeholder="Category" />
                        <FormikField
                            data={props.departments}
                            required
                            name="Department"
                            component={GenericDataSourceDropdown}
                            placeholder="Department"
                            labelFields={['Name']}
                            imageField="Icon"
                        />
                        <FormikField
                            data={props.departments}
                            required
                            name="EscalationDepartment"
                            component={GenericDataSourceDropdown}
                            placeholder="Escalation Department"
                            labelFields={['Name']}
                            imageField="Icon"
                        />

                        <div className="field enable-escalation">
                            <Checkbox
                                name="NotifyFamily"
                                label="Notify Family for this request?"
                                checked={values.NotifyFamily}
                                onChange={() => {
                                    setFieldValue('NotifyFamily', !values.NotifyFamily)
                                }}
                                toggle
                            />
                        </div>

                        <div className="field enable-escalation">
                            <Checkbox
                                name="EscalationEnabled"
                                label="Enable Escalations"
                                checked={values.EscalationEnabled}
                                onChange={() => {
                                    setFieldValue('EscalationEnabled', !values.EscalationEnabled)
                                }}
                                toggle
                            />
                        </div>

                        {values.EscalationEnabled && (
                            <div>
                                <FormikField
                                    data={[
                                        { _id: 'email', Name: 'Email' },
                                        { _id: 'sms', Name: 'SMS' },
                                    ]}
                                    required
                                    name="NotificationMethodA"
                                    component={GenericDataSourceDropdown}
                                    placeholder="Notification Method A"
                                    labelFields={['Name']}
                                />
                                <div>
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="example-thumb"
                                        trackClassName="example-track"
                                        name="NotificationATimeMinutes"
                                        defaultValue={values.NotificationATimeMinutes}
                                        max={100}
                                        min={1}
                                        onChange={value => setFieldValue('NotificationATimeMinutes', value)}
                                    />
                                    {values.NotificationATimeMinutes}
                                </div>

                                {/* <FormikField
                                    data={[ { _id:"", Name:"disabled"}, { _id:"email", Name:"Email"},{ _id:"sms", Name:"SMS"}]}
                                    
                                    component={GenericDataSourceDropdown}
                                    labelFields={['Name']}
                                    name="NotificationMethodB"
                                    placeholder="Notification Method B"
                                />
                                <div>
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="example-thumb"
                                        trackClassName="example-track"
                                        name="NotificationBTimeMinutes"
                                        defaultValue={values.NotificationBTimeMinutes} 
                                        max={100}
                                        min={1}
                                        onChange={(value)=>setFieldValue("NotificationBTimeMinutes",value)}
                                    />
                                    {values.NotificationBTimeMinutes}
                                </div> */}
                            </div>
                        )}
                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving Service Type</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove Service Type
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default RequestTypesForm
