import { DocWidgetData } from '../types/Service';
import { fetchAllPaginatedData } from '../util';
import API from './API';

export async function addDocWidgetData(data: DocWidgetData): Promise<string> {
    try {
        const res = await API.lambdaPost(`/docs/add`, data);
        return res;
    } catch (error) {
        throw new Error("Error while adding doc widget data");
    }
}

export async function updateDocWidgetData(data: DocWidgetData): Promise<string> {
    try {
        if (!data._id) throw new Error("No Id detected");
        const res = await API.lambdaPut(`/docs/update`, data);
        return res;
    } catch (error) {
        throw new Error("Error while updating doc widget data");
    }
}

export async function getDocWidgetData(_id: string): Promise<any> {
    try {
        const res = await API.lambdaGet(`/docs/get`, { _id });
        return res;
    } catch (error) {
        throw new Error("Error while getting doc widget data");
    }
}

export async function ListDocWidgetData(Filter = {}): Promise<any> {
    try {
        const docsListApiCall = async (params: any) => { return await API.lambdaPost('/docs/list', params); };
        const response = await fetchAllPaginatedData(docsListApiCall, { Filter });
        return response;   
    } catch (error) {
        throw new Error("Error while getting doc widget data");
    }
}

export async function ListPaginatedDocWidgetData(page_no, page_size, Filter?): Promise<any> {
    try {
        const QueryOptions = {
            page_no,
            page_size,
            sort: [{ Name: 'desc' }],
        };
        const Data = { QueryOptions };
        if (Filter) Data['Filter'] = Filter;
        const res = await API.lambdaPost(`/docs/list`, Data);
        return res;   
    } catch (error) {
        throw new Error("Error while fetching doc widget data");
    }
}

export async function deleteDocWidgetData(id: string): Promise<any> {
    try {
        const params = { _id: id };
        const res = await API.lambdaDeleteById(`/docs/delete`, params);
        return res;   
    } catch (error) {
        throw new Error("Error while deleting doc widget data");
    }
}

export async function deleteAttachedFileData(data) {
    try {
        const res = await API.lambdaPost(`/docs/files/delete`, data);
        return res;   
    } catch (error) {
        throw new Error("Error while deleting doc widget data");
    }
}