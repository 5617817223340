import { FetchOrderFromOrderIdParams, RequestInstance, UpdateRequestInstanceResult } from '../types/RequestInstance';
import { fetchAllPaginatedData } from '../util';
import API from './API';

export async function createRequestInstance(requestInstanceObj: Partial<RequestInstance>): Promise<string> {
    try {
        const response = await API.lambdaPost('/requests/instances/add', requestInstanceObj);
        return response;
    } catch (error) {
        console.error('Something went wrong in adding request instance', error);
        throw new Error(`Something went wrong in adding request instance ${error}`);
    }
}

export async function updateRequestInstance(requestInstanceObj: Partial<RequestInstance>): Promise<UpdateRequestInstanceResult> {
    try {
        const response = await API.lambdaPost('/requests/instances/update', requestInstanceObj);
        return response;
    } catch (error) {
        console.error('Something went wrong in updating request instance', error);
        throw new Error(`Something went wrong in updating request instance ${error}`);
    }
}

export async function fetchRequestInstance(filters: any): Promise<{ Result: RequestInstance[], TotRecords: number }> {
    try {
        const response = await API.lambdaPost('/requests/instances/list', filters);
        return response;
    } catch (error) {
        console.error('Something went wrong in fetching request instance', error);
        throw new Error(`Something went wrong in fetching request instance ${error}`);
    }
}

export async function fetchAllRequestInstances(filters: any): Promise<RequestInstance[]> {
    const params = {
        Filter: filters
    }
    try {
        return await fetchAllPaginatedData(
            fetchRequestInstance,
            params
        )
    } catch (error) {
        console.error('Something went wrong in fetching request instance', error);
        throw new Error(`Something went wrong in fetching request instance ${error}`);
    }
}

export async function getTotCountRequestInstances(filters: any): Promise<number> {
    try {
        const params = {
            Filter: filters
        }
        const pagingSorting: any = {
            page_size: 1,
            page_no: 1,
        };
        const response = await fetchRequestInstance({ ...params, pagingSorting });
        return response.TotRecords;
    } catch (error) {
        console.error('Something went wrong in fetching request instance', error);
        throw new Error(`Something went wrong in fetching request instance ${error}`);
    }
}

export async function fetchOneResidentCalendar(ResidentCalendarId: string) { 
    try {
        const Filter = {
            ResidentCalendarId
        };
        const response = await API.lambdaPost('/requests/instances/list', { Filter });
        return response;
    } catch (error) {
        console.error('Something went wrong in fetching ResidentCalendarId', error);
        throw new Error(`Something went wrong in fetching ResidentCalendarId ${error}`);
    }
}
export async function fetchRequestInstanceData(requestInstanceId: string) { 
    try {
        const Filter = {
            _id : requestInstanceId
        };
        const response = await API.lambdaPost('/requests/instances/list', { Filter });
        return response ;
    } catch (error) {
        console.error('Something went wrong in fetching ResidentCalendarId', error);
        throw new Error(`Something went wrong in fetching ResidentCalendarId ${error}`);
    }
}

export async function unacceptServiceRequest(requestId: string) {
    try {
        const response = await API.lambdaPost('/requests/unaccept', { requestId });
        return response;
    } catch (error) {
        console.error('Something went wrong in unaccepting service request', error);
        throw new Error(`Something went wrong in unaccepting service request ${error}`);
    }
}

export async function reopenServiceRequest(requestId: string) {
    try {
        const response = await API.lambdaPost('/requests/reopen', { requestId });
        return response;
    } catch (error) {
        console.error('Something went wrong in reopening service request', error);
        throw new Error(`Something went wrong in reopening service request ${error}`);
    }
}

export async function convertStandaloneRequestToServiceRequest({standaloneRequestId, serviceId}:{standaloneRequestId: string, serviceId: string}) {
    try {
        const response = await API.lambdaPost('/requests/convert', { standaloneRequestId, serviceId });
        return response;
    } catch (error) {
        console.error('Something went wrong in converting standalone request to service request', error);
        throw new Error(`Something went wrong in converting standalone request to service request ${error}`);
    }
}

export const fetchOrdersFromOrderId = async ({ OrderId, statusArr, residentId }:FetchOrderFromOrderIdParams) => {
    try {
        const params =
        {
            Filter: {
                Status: statusArr, 
                OrderId: OrderId,
                Registrant: residentId 
            },
            QueryOptions: {
                page_no: 1,
                page_size: 100,
                sort: [
                    {
                        RequestedTime: "asc"
                    }
                ]
            }
        };
        const response = await API.lambdaPost("/requests/instances/list", params);
        return response.Result as RequestInstance[];
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Error fetching orders.');
    }
};