import React, { FC } from 'react'
import CustomTable, { RowData } from '../CustomTable';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { sortByKey } from '../../util/sortData';


interface AttendanceDetailViewTableProps {
  data: RowData[];
}

const AttendanceDetailViewTable: FC<AttendanceDetailViewTableProps> = ({ data }) => {
  const [filteredData, setFilteredData] = React.useState<RowData[]>([])
  const [selectedResidents, setSelectedResidents] = React.useState<string[]>([])
  const dataToUse = filteredData.length ? filteredData : data

  const formattedData = data.map((row) => {
    return {
      key: row._id,
      text: row.name,
      value: row._id
    }
  })

  const dropdownChangeHandler = (e: any, { value = [] }: DropdownProps) => {
    setSelectedResidents(value as string[])

    const filteredData = data.filter((row) => {
      if (Array.isArray(value) && value.length) {
        return value.includes(row._id)
      }
    })

    setFilteredData(filteredData)
  }

  const headers = ["Name", "Status"];
  const rowKeys = ["name", "status"];
  return ( 
    <>
      <Dropdown placeholder='Residents' options={sortByKey(formattedData)} onChange={dropdownChangeHandler} value={selectedResidents} multiple style={{ border: '1px solid #ddd' }}  />
      <CustomTable
        data={dataToUse}
        loading={false} // Set to false as data is not being fetch currently
        headers={headers}
        rowKeys={rowKeys}
        facilityTimezone="" // not required currently
        formatString=""  // not required currently
      />
    </>
  )
}

export default AttendanceDetailViewTable
