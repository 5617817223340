import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import SegmentedInputBox from '../SegmentedInputBox';
import { FeedItem } from '../../types/feedItems';

interface Props {
    feedItem: Partial<FeedItem>;
}

const RelativeFeedDetailView: FC<Props> = ({ feedItem }) => {
    return (
        <>
            <Grid columns={6}>
                <Grid.Column width={6}>
                    <SegmentedInputBox
                        value={feedItem.Relative_FullName || ''}
                        required={true}
                        label="Relative full name"
                        onChange={(e) => {}}
                        placeholder="Relative"
                        readonly={true}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default RelativeFeedDetailView;
