import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { listQueueStatus } from '../../services/AlexaHospitalityQueueStatus';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import CustomTable from '../CustomTable';
import momentTz from 'moment-timezone';
import { sendToast } from '../../util';
import { AssertAddressAndTimezoneHospitalityStatusItem, AssertAddressAndTimezoneInfo } from '../../types/AlexaHospitalityQueueStatus';

const AssertAddressAndTimezoneList = () => {
    const [assertedStatusList, setAssertedStatusList] = useState<AssertAddressAndTimezoneHospitalityStatusItem[]>([]);
    const [assertedAddressInfo, setAssertedAddressInfo] = useState<AssertAddressAndTimezoneInfo[]>([]);
    const [assertedTimezoneInfo, setAssertedTimezoneInfo] = useState<AssertAddressAndTimezoneInfo[]>([]);
    const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);
    const [openTimezoneModal, setOpenTimezoneModal] = useState<boolean>(false);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const pageSize = 20;

    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    const statusTableHeaders = ['Created At', 'Address Info', 'Timezone Info'];
    const statusTableKeys = ['createdAt', 'addressInfo', 'timeZoneInfo'];

    const addressAssertionInfoHeaders = ['Room', 'Device', 'DSN', 'Status', 'Status Message'];
    const addressAssertionInfoKeys = ['roomName', 'deviceName', 'dsn', 'status', 'statusMessage'];

    const timezoneAssertionInfoHeaders = ['Room', 'Device', 'DSN', 'Status', 'Status Message'];
    const timezoneAssertionInfoKeys = ['roomName', 'deviceName', 'dsn', 'status', 'statusMessage'];

    useEffect(() => {
        fetchAssertAddressAndTimezoneStatus();
    }, [pageNo]);

    const fetchAssertAddressAndTimezoneStatus = async () => {
        try {
            setLoading(true);
            const filters = {
                EventType: 'AssertAddress', // Includes both assert address and timezone responses in same object
                Facility: profile && profile.Facility,
            };
            const pageSort = {
                page_size: pageSize,
                page_no: pageNo,
                sort: [{ DateAdded: 'asc' }],
            };
            const response = await listQueueStatus({ filters, pageSort });
            if (response && response.Result && response.Result.length > 0) {
                const formattedAssertionData = response.Result.map((assertedStatus: AssertAddressAndTimezoneHospitalityStatusItem) => {                  
                    const addressInfoButton = (
                        <Button
                            onClick={() => {
                                handleAssertAddressInfo(assertedStatus);
                            }}
                            color='blue'
                            size='mini'
                        >
                            View Device Details
                        </Button>
                    );

                    const timeZoneInfoButton = (
                        <Button
                            onClick={() => {
                                handleAssertTimezoneInfo(assertedStatus);
                            }}
                            color='blue'
                            size='mini'
                        >
                            View Device Details
                        </Button>
                    );

                    return {
                        createdAt: momentTz.tz(assertedStatus.DateAdded, profile && profile.FacilityTimeZone).format('MM/DD/YYYY hh:mm A'),
                        addressInfo: addressInfoButton,
                        timeZoneInfo: timeZoneInfoButton
                    };
                });
                setAssertedStatusList(formattedAssertionData);
            } else {
                setAssertedStatusList([]);
            }
            setTotalDataLength(response.TotRecords);
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error in fetching Address and Timezone status");
        } finally {
            setLoading(false);
        }
    };

    const handleAssertAddressInfo = (assertedStatus: AssertAddressAndTimezoneHospitalityStatusItem) => {
        if (assertedStatus && assertedStatus.AddressAPIResponse && assertedStatus.AddressAPIResponse.length > 0) {
            const formattedAssertionInfo = assertedStatus.AddressAPIResponse.map((addressInfo) => {
                return {
                    roomName: addressInfo.room && addressInfo.room.RoomName || '-',
                    deviceName: addressInfo.getEndpointRes && addressInfo.getEndpointRes.deviceFriendlyName || '-',
                    dsn: addressInfo.getEndpointRes && addressInfo.getEndpointRes.dsn || '-',
                    status: addressInfo.status === 201 ? 'Success' : 'Failed',
                    statusMessage: addressInfo.statusText || '-'
                };
            });
            setAssertedAddressInfo(formattedAssertionInfo);
            setOpenAddressModal(true);
        } else {
            setAssertedAddressInfo([]);
            if (assertedStatus.Status === 'inProgress') {
                sendToast("warn", "Address assertion is in progress, Please refresh after few minutes");
            } else if (assertedStatus.Status === 'failed') {
                sendToast("warn", "Address assertion failed");
            } else {
                sendToast("warn", "No data found for the assertion");
            }
        }
    };

    const handleAssertTimezoneInfo = (assertedStatus: AssertAddressAndTimezoneHospitalityStatusItem) => {
        if (assertedStatus && assertedStatus.CallbackQueuePayload && assertedStatus.CallbackQueuePayload.TimezoneAPIResponse && assertedStatus.CallbackQueuePayload.TimezoneAPIResponse.length > 0) {
            const formattedAssertionInfo = assertedStatus.CallbackQueuePayload.TimezoneAPIResponse.map((timezoneInfo) => {
                return {
                    roomName: timezoneInfo.room && timezoneInfo.room.RoomName || '-',
                    deviceName: timezoneInfo.getEndpointRes && timezoneInfo.getEndpointRes.deviceFriendlyName || '-',
                    dsn: timezoneInfo.getEndpointRes && timezoneInfo.getEndpointRes.dsn || '-',
                    status: timezoneInfo.status === 204 ? 'Success' : 'Failed',
                    statusMessage: timezoneInfo.statusText === "No Content" ? "Created" : timezoneInfo.statusText || '-'
                };
            });
            setAssertedTimezoneInfo(formattedAssertionInfo);
            setOpenTimezoneModal(true);
        } else {
            setAssertedTimezoneInfo([]);
            if (assertedStatus.Status === 'inProgress') {
                sendToast("warn", "Timezone assertion is in progress, Please refresh after few minutes");
            } else if (assertedStatus.Status === 'failed') {
                sendToast("warn", "Timezone assertion failed");
            } else {
                sendToast("warn", "No data found for the assertion");
            }
        }
    };

    return (
        <>
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            <CustomTable
                headers={statusTableHeaders}
                data={assertedStatusList}
                loading={loading}
                rowKeys={statusTableKeys}
                facilityTimezone=""
                formatString=""
                overflowX="hidden"
                isServerPagination={true}
                pageNo={pageNo}
                setPageNo={setPageNo}
                itemsPerPage={pageSize}
                totalDataLength={totalDataLength}
            />
            <Modal
                open={openAddressModal}
                size='large'
                onClose={() => {
                    setAssertedAddressInfo([]);
                    setOpenAddressModal(false);
                }}
            >
                <div>
                    <CustomTable
                        headers={addressAssertionInfoHeaders}
                        data={assertedAddressInfo}
                        loading={loading}
                        rowKeys={addressAssertionInfoKeys}
                        facilityTimezone=""
                        formatString=""
                        overflowX='hidden'
                    />
                </div>
            </Modal>
            <Modal
                open={openTimezoneModal}
                size='large'
                onClose={() => {
                    setAssertedTimezoneInfo([]);
                    setOpenTimezoneModal(false);
                }}
            >
                <div>
                    <CustomTable
                        headers={timezoneAssertionInfoHeaders}
                        data={assertedTimezoneInfo}
                        loading={loading}
                        rowKeys={timezoneAssertionInfoKeys}
                        facilityTimezone=""
                        formatString=""
                        overflowX='hidden'
                    />
                </div>
            </Modal>
        </>
    );
};

export default AssertAddressAndTimezoneList;