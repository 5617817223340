import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Dimmer, Dropdown, Form, Grid, Icon, Loader, Modal, Popup, Segment } from 'semantic-ui-react';
import FilePicker from '../S3FileUpload/FilePicker';
import { toast } from 'react-toastify';
import ListDocsModal from './ListDocsModal';
import { uploadGroupImageToS3 } from '../../services/ImageUpload';
import { Group, Member } from '../../types/Group';
import { copyS3GroupImage, createGroup, getOneGroup, updateGroup } from '../../services/Groups';
import { formatFileName } from '../../util';
import { fetchResidentIdToCarePlanIdMap } from '../../services/Registrants';
import { sortByKey } from '../../util/sortData';
import ScrollableDropDown from '../ScrollableDropDown/ScrollableDropDown';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    editId: string;
    setEditId: React.Dispatch<React.SetStateAction<string>>;
    facilityId: string;
    membersList: Member[];
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    updateGroupList: any;
    groupLists: Group[];
}

const GroupsModal: FC<Props> = ({
    open,
    setOpen,
    editId,
    setEditId,
    membersList,
    facilityId,
    setLoading,
    updateGroupList,
    groupLists,
}) => {
    const [groupName, setGroupName] = useState<string>('');
    const [groupModalLoading, setGroupModalLoading] = useState<boolean>(false);
    const [selectedGroupCategory, setSelectedGroupCategory] = useState<string>('');
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [description, setDescription] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [minCap, setMinCap] = useState<string>('');
    const [maxCap, setMaxCap] = useState<string>('');
    const [residentView, setResidentView] = useState<boolean>(false);
    const [docIds, setDocIds] = useState<string[]>([]);
    const [docsModal, setDocsModal] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>('');
    const [copyGroupId, setCopyGroupId] = useState<string>('');
    const [currrentGroupFacility, setCurrentGroupFacility] = useState<string>('');
    const [previewImageSrc, setPreviewImageSrc] = useState<string>(''); // this is the image preview that is shown when a file is selected in the file picker
    const [file, setFile] = useState<File>(); // this is the image that is uploaded to s3
    const [isCommunityGroup, setIsCommunityGroup] = useState<boolean>(false);
    const [deleteImage, setDeleteImage] = useState<boolean>(false);
    const [imageExists, setImageExists] = useState<boolean>(false);
    const [groupMemberOptions, setGroupMemberOptions] = useState<any[]>([{
        key: 'all residents',
        text: 'All Residents',
        value: 'all residents'
    }, ...sortByKey(membersList.map((item) => {
        return {
            key: item._id,
            value: item._id,
            text: `${item.firstName} ${item.lastName} ~ ${item.staff ? 'Staff' : 'Resident'}`,
        };
    }))]);

    useEffect(() => {
        if (editId) getEditGroupData();
    }, []);

    const filePickerStatus = {
        error: null,
        finished: false,
        isUploading: false,
        percentage: 0,
    };
    const groupCategories = [
        'Committee', 'Maintenance', 'Dining', 'Wellness', 'Social',
        'Facility', 'Geography', 'Residents', 'Other'
    ];
    const groupCategoryOptions = groupCategories.map(category => ({
        key: category,
        text: category,
        value: category
    }));

    const handleDropdownChangeGroupCategory = (value) => {
        setSelectedGroupCategory(value);
    };
    const getEditGroupData = async () => {
        try {
            setGroupModalLoading(true);
            const { response = {} as Group, signedUrl = "" } = await getOneGroup(editId);
            if (signedUrl) {
                setPreviewImageSrc(signedUrl);
            }
            if(response.CommunityGroup) {
                setIsCommunityGroup(true);
            }
            setGroupName(response.Name);
            setSelectedGroupCategory(response.GroupCategory);
            let residentArr: string[] = [];
            let staffArr: string[] = [];
            if (response.ResidentIds && response.ResidentIds.length > 0) {
                residentArr = response.ResidentIds;
            }
            if (response.StaffIds && response.StaffIds.length > 0) {
                staffArr = response.StaffIds;
            }
            const membersArr: string[] = [...residentArr, ...staffArr];
            setSelectedMembers(membersArr);
            if (response.Description)
                setDescription(response.Description);
            if (response.URL)
                setUrl(response.URL);
            if (response.MinCap) setMinCap(response.MinCap.toString());
            if (response.MaxCap) setMaxCap(response.MaxCap.toString());
            setResidentView(response.ResidentView);
            if (response.DocIds)
                setDocIds(response.DocIds);
            if (response.Facility)
                setCurrentGroupFacility(response.Facility);
            if (response.ImageName) {
                setImageName(response.ImageName);
                setImageExists(true);
            }

            try {
                if (response.RoomTypeId) {
                    const residentIds = membersList
                        .filter(member => member.resident)
                        .map(member => String(member._id));

                    const residentIdsToCareIdMap = await fetchResidentIdToCarePlanIdMap(residentIds);

                    const filteredMembers = membersList.filter(member => {
                        if (member.staff) {
                            return true;
                        }
                        return residentIdsToCareIdMap[member._id] !== undefined &&
                            String(residentIdsToCareIdMap[member._id]) === String(response.RoomTypeId);
                    });

                    setGroupMemberOptions([...sortByKey(filteredMembers.map((member) => {
                        return {
                            key: member._id,
                            value: member._id,
                            text: `${member.firstName || ''} ${member.lastName || ''} ~ ${member.staff ? 'Staff' : 'Resident'}`,
                        };
                    }))]);
                }
            } catch (error) {
                toast.warn(`Could not filter care plan residents`, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }

        } catch (error) {
            toast.error(`Cannot fetch Group ${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        } finally {
            setGroupModalLoading(false);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Number(maxCap) < Number(minCap)) {
            toast.warn('Max Cap should be greater than or equal to Min Cap', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else {
                if (selectedGroupCategory) {
                    setGroupModalLoading(true);
                    let StaffIds: string[] = [];
                    let ResidentIds: string[] = [];
                    try {
                        if (selectedMembers.length > 0)
                            selectedMembers.map((item) => {
                                const memberObj = membersList.find((member) => member._id === item);
                                if (memberObj) {
                                    if (memberObj.resident) {
                                        ResidentIds.push(memberObj._id);
                                    }
                                    if (memberObj.staff) {
                                        StaffIds.push(memberObj._id);
                                    }
                                }
                            });
                        const allResidentMembers = membersList.filter((member) => member.resident).map((member) => member._id);
                        if (selectedMembers.includes('all residents')) {
                            ResidentIds = allResidentMembers;
                        }
                        const dataObj = {
                            Name: groupName,
                            GroupCategory: selectedGroupCategory,
                            Description: description,
                            URL: url,
                            MinCap: minCap ? Number(minCap) : null,
                            MaxCap: maxCap ? Number(maxCap) : null,
                            ResidentView: residentView,
                            Facility: facilityId,
                            DocIds: docIds,
                            ImageName: imageName,
                            ResidentIds : ResidentIds
                        };

                        if(!isCommunityGroup) {
                            dataObj['StaffIds'] = StaffIds;
                        }
                        if(isCommunityGroup) {
                            dataObj['CommunityGroup'] = true;
                        }
                        if (deleteImage) {
                            dataObj['deleteImage'] = true
                        }
                        if (editId) {
                            if (file) {
                                await uploadImageFile(editId);
                                await updateGroup(
                                    {
                                        _id: editId,
                                        ...dataObj,
                                        ImageName: file.name,
                                    },
                                    imageName,
                                );
                            } else {
                                await updateGroup({
                                    _id: editId,
                                    ...dataObj,
                                });
                            }
                            const { response = {} as Group } = await getOneGroup(editId);
                            if (response) {
                                // Update the specific asset in the array based on _id
                                const updatedAssetsList = groupLists.map((group) =>
                                    group._id === response._id ? response : group
                                );
                                // Set the state with the updated array
                                updateGroupList(updatedAssetsList);
                                toast.success('Group Updated successfully', {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            } else {
                                toast.error('Cannot update Group', {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            }
                        } else {
                            const createdGroupId = await createGroup(dataObj);
                            if (file) {
                                await uploadImageFile(createdGroupId);
                                await updateGroup({
                                    _id: createdGroupId,
                                    ...dataObj,
                                    ImageName: file.name,
                                });
                            }
                            if (copyGroupId && imageName) {
                                await copyS3GroupImage(currrentGroupFacility, copyGroupId, createdGroupId, imageName);
                                await updateGroup({
                                    _id: createdGroupId,
                                    ...dataObj,
                                    ImageName: imageName,
                                });
                            }
                            const { response = {} as Group } = await getOneGroup(createdGroupId);
                            if (response) {
                                updateGroupList([...groupLists, response]);
                                toast.success('Group created successfully', {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            } else {
                                toast.error('Cannot create Group', {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                });
                            }
                        }
                        setOpen(false);
                    } catch (error) {
                        toast.error(`Cannot create Group ${error}`, {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                        });
                    } finally {
                        setGroupModalLoading(false);
                    }
                } else {
                    toast.warn('Please select a group category', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    });
                }
        }
    };
    const uploadImageFile = async (groupId: string) => {
        if (file) {
            await uploadGroupImageToS3(groupId, file.name, file);
        } else {
            throw new Error('Image file is not provided');
        }
    };
    const handleFilePick = async (files: File[]) => {
        setPreviewImageSrc('');
        const file = files[0];
        const fileExtension = file.name.split('.').pop();
        if (fileExtension !== 'jpg' && fileExtension !== 'png') {
            toast.warn('Only JPG and PNG files are supported', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        const formattedFileName = formatFileName(file.name);
        const fileBlob = new Blob([file], { type: file.type });
        const modifiedFile = new File([fileBlob], formattedFileName, { type: file.type });
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const result = reader.result as string;
                setPreviewImageSrc(result);
                setFile(modifiedFile);
            }
        };
        reader.readAsDataURL(file);
    };
    const handleMemberChange = (value) => {
        // Check if "all residents" is included in the new value
        if (value.includes('all residents')) {
            // Filter out any resident members if "all residents" is selected
            const nonResidentMembers = value.filter(val => {
                const member = membersList.find(member => String(member._id) === String(val));
                return !(member && member.resident) && val !== 'all residents';
            });
            // Add "all residents" to the filtered list
            setSelectedMembers(['all residents', ...nonResidentMembers]);
        } else {
            // Update selectedMembers with the new value
            setSelectedMembers((value as string[]) || []);
        }
    };
    const handleCopy = () => {
        if (editId) {
            setCopyGroupId(editId);
            setEditId('');
        }
    };
    // isCommunityGroup ? [{ // this was removed for a feature where the user can removed from the group
    //     key: 'all',
    //     text: 'All',
    //     value: 'all'
    // }]

    return (
        <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} size={'mini'}>
            <Dimmer inverted active={groupModalLoading}>
                <Loader active={groupModalLoading} />
            </Dimmer>
            <Modal.Content>
                <Modal.Header>
                    <Grid>
                        <Grid.Column width={11} style={{ textAlign: 'right' }}>
                            <h3>{editId ? 'Edit a Group' : 'Create Group'}</h3>
                        </Grid.Column>
                        <Grid.Column width={5} style={{ textAlign: 'right' }}>
                            {editId ? (
                                <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleCopy}
                                    src="../assets-groups-management/copy.png"
                                    alt="Copy icon"
                                />
                            ) : (
                                ''
                            )}
                        </Grid.Column>
                        <FilePicker
                            {...filePickerStatus}
                            onSelected={handleFilePick}
                            multiple={false}
                            accept={['image/*']}
                        />
                        {previewImageSrc && (
                            <>
                                <div style={{ display: 'flex',width:'100%', justifyContent: 'space-between', marginTop: '10px', marginBottom: '0px' }}>
                                    <p style={{ margin: 0 }}>Preview</p>
                                    <Popup
                                        content="Remove Image"
                                        trigger={
                                            <Icon
                                                style={{ cursor: 'pointer', color: "maroon" }}
                                                name="trash"
                                                onClick={() => {
                                                    setPreviewImageSrc('');
                                                    setImageName("");
                                                    setFile(undefined);
                                                    imageExists && setDeleteImage(true);
                                                }}
                                            />
                                        }
                                        position="top center"
                                        inverted
                                    />
                                </div>
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <img style={{ width: '100%' }} src={previewImageSrc} alt="Preview" />
                                </div>
                            </>
                        )}

                        {deleteImage && imageExists && (
                            <div style={{ color: 'orange', marginTop: '5px', fontSize: 'small' }}>
                                Your image has been removed. Click Update to save your changes.
                            </div>
                        )}

                    </Grid>
                </Modal.Header>
                <Modal.Description>
                    <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input
                                required={true}
                                value={groupName || ''}
                                placeholder="Group Name"
                                onChange={(e) => setGroupName(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                placeholder={'Group Category'}
                                closeOnEscape
                                value={selectedGroupCategory}
                                clearable
                                search
                                scrolling
                                selection
                                options={sortByKey(groupCategoryOptions)}
                                onChange={(e, { value }) => handleDropdownChangeGroupCategory(value)}
                            />                  
                        </Form.Field>
                        <Form.Field>
                            <ScrollableDropDown
                                placeholder={'Members'}
                                selectedValues={selectedMembers}
                                dropdownOptions={groupMemberOptions}
                                handleValuesChange={handleMemberChange}
                                setSelectedValues={setSelectedMembers}
                                name={"Members"}
                                maxHeight={"400px"}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.TextArea
                                value={description}
                                onChange={(e) => setDescription(e.currentTarget.value)}
                                placeholder=""
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input value={url || ''} placeholder="URL" onChange={(e) => setUrl(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <Grid centered>
                                <Grid.Column style={{ width: '50%' }}>
                                    <Segment>
                                        <span>Min Cap</span>
                                        <Form.Input
                                            type="number"
                                            min="0"
                                            value={minCap || ''}
                                            onChange={(e) => {
                                                setMinCap(e.target.value);
                                            }}
                                        />
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column style={{ width: '50%' }}>
                                    <Segment>
                                        <span>Max Cap</span>
                                        <Form.Input
                                            type="number"
                                            min="0"
                                            value={maxCap || ''}
                                            onChange={(e) => setMaxCap(e.target.value)}
                                        />
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                        <Form.Field>
                            <Grid centered style={{ margin: '30px 0' }}>
                                <Form.Field
                                    control={Checkbox}
                                    label="Resident View"
                                    onChange={(e, data) => setResidentView(data.checked ? data.checked : false)}
                                    checked={residentView}
                                />
                            </Grid>
                        </Form.Field>
                        <Form.Field style={{ justifyContent: 'center' }}>
                            <Grid>
                                <Grid.Row centered>
                                    <Segment
                                        style={{
                                            backgroundColor: '#183466',
                                            width: '30%',
                                            height: '90%',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            color: 'white',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setDocsModal(true)}
                                    >
                                        <img src="../assets-groups-management/copy-white.png" alt="Image Input" />
                                        <span style={{ fontSize: '16px' }}>Docs</span>
                                    </Segment>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                        <Modal.Actions style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Button type="submit" size={'big'} color='blue'>
                                {editId ? 'Update' : 'Create'}
                            </Button>
                            <Button size={'big'} onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            {docsModal && <ListDocsModal open={docsModal} setOpen={setDocsModal} multipleDocs={docIds} setMultipleDocs={setDocIds} facilityId={facilityId} source={groupName} />}
        </Modal>
    );
};

export default GroupsModal;
