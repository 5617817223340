import React, { FC, useEffect, useState } from 'react';
import { fetchAllActivities, listActivityAttendeesForMultipleActivityIds } from '../../services/DailyActivities';
import { DailyActivity, Unit } from '../../types';
import CustomTable from '../CustomTable';
import AttendanceDetailViewTable from './AttendanceDetailViewTable';
import { Dimmer, Grid, Loader, Modal, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import ReportsAttendanceOverviewTable from '../Reports/AttendanceTable';
import ReportsAttendancePieChart from '../Reports/ReportsAttendancePieChart';
import { fetchAllServiceInstance, fetchAllServiceInstanceAttendees } from '../../services/ServiceInstances';
import { ServicesType } from '../../types/ServicesTypes';
import { listAssets } from '../../services/Assets';
import { fetchUnitsUsingParams } from '../../services/Units';
import { getUnitIdsFromResidentIds } from '../../services/UnitRegistrant';
import { Asset } from '../../types/Asset';
import ReportContentRequests from '../ReportContentRequest';
import {
    declineOptions,
    participationOptions,
    ParticipationOptionsStatsType,
    DeclineOptionsStatsType,
} from '../../util/data';
interface Props {
    startDate: any; // any is used else it breaks for a reusable component BasicReportsSummary
    endDate: any;
    facilityTimeZone: string;
}

const ReportsAttendance: FC<Props> = ({ startDate, endDate, facilityTimeZone }) => {
    const [attendanceData, setAttendanceData] = useState<any[]>([]);
    const [activities, setActivities] = useState<DailyActivity[]>([]);
    const [activityDict, setActivityDict] = useState<{ [key: string]: DailyActivity }>({});
    const [graphSearchTerm, setGraphSearchTerm] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedTableData, setSelectedTableData] = useState<{ name: string; status: string }[]>([]);
    const [overviewTableData, setOverviewTableData] = useState<{
        activitiesCount: number;
        registeredCount: number;
        attendedCount: number;
        declinedCount: number;
    }>({ activitiesCount: 0, registeredCount: 0, attendedCount: 0, declinedCount: 0 });
    const [overviewTableDetailViewData, setOverviewTableDetailViewData] = useState<{
        registeredActivities: any[];
        attendedActivities: any[];
        declinedActivities: any[];
    }>({ registeredActivities: [], attendedActivities: [], declinedActivities: [] });
    const [attendedData, setAttendedData] = useState<ParticipationOptionsStatsType>({});
    const [declinedData, setDeclinedData] = useState<DeclineOptionsStatsType>({});
    const [prevStartDate, setPrevStartDate] = useState<Date>(new Date());
    const [prevEndDate, setPrevEndDate] = useState<Date>(new Date());
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [assetDict, setAssetDict] = useState<{ [key: string]: Asset }>({});
    const [registrantUnitDict, setRegistrantUnitDict] = useState<{ [key: string]: Unit }>({});

    const fetchData = async () => {
        try {
            setLoading(true);
            // extract string dates from the date objects (since user selects the date we and it is locally converted to date object we need not consider facility time zone)
            const startDateString = moment(startDate).format('YYYY/MM/DD');
            const endDateString = moment(endDate).format('YYYY/MM/DD');
            const startDateTimestamp = moment
                .tz(startDateString, 'YYYY/MM/DD', facilityTimeZone)
                .startOf('day')
                .valueOf();
            const endDateTimestamp = moment.tz(endDateString, 'YYYY/MM/DD', facilityTimeZone).endOf('day').valueOf();
            const filter = {
                timestamp: {
                    $gte: startDateTimestamp,
                    $lte: endDateTimestamp,
                },
            };
            const activities = await fetchAllActivities(filter);

            // fetch all the service instances using - fetchAllServiceInstance for current
            const currentServiceInstanceFilter = {
                startDate: moment.tz(startDateTimestamp, facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
                endDate: moment.tz(endDateTimestamp, facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
            };

            // fetch all the service instances using - fetchAllServiceInstance for previous
            const allAssets = await listAssets();
            const tempAssetDict = {};
            allAssets.forEach((asset) => {
                tempAssetDict[asset._id] = asset;
            });
            setAssetDict(tempAssetDict); // set the asset dictionary

            const currentServiceInstances = await fetchAllServiceInstance(currentServiceInstanceFilter);
            const formattedServiceInstances = formatServiceInstancesToDailyActivities(currentServiceInstances);

            activities.push(...formattedServiceInstances);

            // format all the service instances activities to look like activities and let the below code run for all of them
            const sortedActivities = activities.sort((a, b) => b.timestamp - a.timestamp);
            setActivities(sortedActivities);
            const newActivityDict: { [key: string]: DailyActivity } = {};
            const activityIds = activities.map((activity) => {
                newActivityDict[String(activity._id)] = activity;
                return String(activity._id);
            });
            const attendanceData = await listActivityAttendeesForMultipleActivityIds(activityIds);

            const currentServiceInstanceIds = currentServiceInstances.map((serviceInstance) => {
                return String(serviceInstance._id);
            });
            setActivityDict(newActivityDict);
            const serviceInstancesAttendeesData = await fetchAllServiceInstanceAttendees(currentServiceInstanceIds);
            const formattedAttendanceData = formatServiceInstancesAttendeesData(serviceInstancesAttendeesData);
            attendanceData.push(...formattedAttendanceData);

            // fetch all the attendees for service instances and add it to the attendanceData
            setAttendanceData(attendanceData);
            // Loop over attendance daat and give me a array set of uniqure registrantId
            const uniqueRegistrantIds = Array.from(
                new Set(attendanceData.map((data: { registrantId: string }) => data.registrantId)),
            );
            // fetch all units for this registrantId
            const allRegistrantUnits = await getUnitIdsFromResidentIds(uniqueRegistrantIds as string[]);
            const allUnitIds = Object.values(allRegistrantUnits).flat();
            const params = {
                _id: { $in: allUnitIds },
                IsActive: 1,
            };
            const allUnits = await fetchUnitsUsingParams(params, 1, allUnitIds.length); // Sketchy way to paginate - fix this later down the line
            // Create a map from unitId to unitObj
            const unitIdToUnitObj = Object.fromEntries(allUnits.map((unitObj: any) => [unitObj._id, unitObj]));
            // Create a dictionary mapping registrantId to unitObj
            const registrantIdToUnitObj = {};
            for (const [registrantId, unitId] of Object.entries(allRegistrantUnits)) {
                registrantIdToUnitObj[registrantId] = unitIdToUnitObj[unitId as string];
            }
            setRegistrantUnitDict(registrantIdToUnitObj);
            refreshAttendanceStatsForGraph(attendanceData);
            refreshAttendanceStatsForOverViewTable(attendanceData, newActivityDict);
        } catch (error) {
            console.log({ error });
            toast.warn('Error fetching Attendance stats for the facility', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const refreshAttendanceStatsForGraph = (attendanceData: any[]) => {
        const attendedStats = {};
        const declinedStats = {};

        attendanceData.forEach((attendee) => {
            const { status } = attendee;
            if (status === 'attended') {
                const participationReason = (attendee.participation && attendee.participation) || 'active';
                if (participationOptions.map((reason) => reason.value).includes(participationReason)) {
                    if (attendedStats[participationReason]) {
                        attendedStats[participationReason] += 1;
                    } else {
                        attendedStats[participationReason] = 1;
                    }
                }
            }
            if (status === 'removed') {
                const declineReason = (attendee.declineReason && attendee.declineReason) || 'Not Interested';
                if (declineOptions.map((reason) => reason.value).includes(declineReason)) {
                    if (declinedStats[declineReason]) {
                        declinedStats[declineReason] += 1;
                    } else {
                        declinedStats[declineReason] = 1;
                    }
                }
            }
        });
        setAttendedData(attendedStats);
        setDeclinedData(declinedStats);
    };

    const refreshAttendanceStatsForOverViewTable = (
        attendanceData: any[],
        activityDictionary: { [key: string]: DailyActivity } = activityDict,
    ) => {
        let registeredCount = 0;
        let attendedCount = 0;
        let declinedCount = 0;
        const registeredActivities: any[] = [];
        const attendedActivities: any[] = [];
        const declinedActivities: any[] = [];

        attendanceData.forEach((attendee) => {
            const { status } = attendee;
            if (status === 'intend_attend') {
                registeredCount += 1;
                const activity = activityDictionary[attendee.activityId];
                registeredActivities.push({ ...attendee, activity });
            }
            if (status === 'attended') {
                attendedCount += 1;
                const activity = activityDictionary[attendee.activityId];
                attendedActivities.push({ participationReason: 'active', ...attendee, activity });
            }
            if (status === 'removed') {
                declinedCount += 1;
                const activity = activityDictionary[attendee.activityId];
                declinedActivities.push({ declineReason: 'Not Interested', ...attendee, activity });
            }
        });

        setOverviewTableData({
            activitiesCount: (activities && activities.length) || 0,
            registeredCount,
            attendedCount,
            declinedCount,
        });
        setOverviewTableDetailViewData({ registeredActivities, attendedActivities, declinedActivities });
    };

    const formatServiceInstancesToDailyActivities = (serviceInstances: ServicesType[]) => {
        const formattedData = serviceInstances.map((serviceInstance) => {
            return {
                ...serviceInstance,
                text: serviceInstance.name,
                dateTimeString: serviceInstance.startDate,
                assetId: serviceInstance.Asset || '',
            };
        });

        return formattedData;
    };

    const formatServiceInstancesAttendeesData = (serviceInstancesAttendeesData: any[]) => {
        if (!serviceInstancesAttendeesData || serviceInstancesAttendeesData.length === 0) {
            return [];
        }
        const formattedData = serviceInstancesAttendeesData.map((attendee) => {
            return {
                ...attendee,
                activityId: attendee.svcInstanceId,
            };
        });

        return formattedData;
    };

    const handleSearchForGraph = (searchTerm: string) => {
        setGraphSearchTerm(searchTerm);
        if (searchTerm && searchTerm.length > 0) {
            const filteredAttendanceData = attendanceData.filter((attendee) => {
                const activity: DailyActivity = activityDict[attendee.activityId];
                if (!activity) {
                    return false;
                } else {
                    return activity.text && activity.text.toLowerCase().includes(searchTerm.toLowerCase());
                }
            });
            refreshAttendanceStatsForGraph(filteredAttendanceData);
        } else {
            refreshAttendanceStatsForGraph(attendanceData);
        }
    };

    useEffect(() => {
        setPrevStartDate(startDate);
        setPrevEndDate(endDate);
        if (isFirstLoad) {
            (async () => {
                await fetchData();
            })();
            setIsFirstLoad(false);
            return;
        }
        if (startDate !== prevStartDate && endDate !== prevEndDate) {
            return;
        }
        (async () => {
            await fetchData();
        })();
    }, [startDate, endDate]);

    const getFormattedAttendeesData = (id: string) => {
        return attendanceData
            .filter((data) => data.activityId === id && data.status === 'attended')
            .map((data) => {
                return {
                    name: `${data.registrant.FirstName} ${data.registrant.LastName}`,
                    status: data.status,
                    _id: data.registrantId,
                };
            });
    };

    const handleAtteendeesClick = (id: string) => {
        const formattedAttendeesData = getFormattedAttendeesData(id);
        console.log({ formattedAttendeesData });
        if (formattedAttendeesData.length > 0) {
            setSelectedTableData(formattedAttendeesData);
        } else {
            toast.warn('No data to display', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const formatDataForTableView = () => {
        return activities.map((activity: any) => {
            const activityAttendees = attendanceData.filter(
                (attendee) => attendee.activityId === activity._id && attendee.status === 'attended',
            );
            const date = activity.timestamp
                ? moment.tz(activity.timestamp, facilityTimeZone).format('M/D/YY')
                : activity.dateTimeString
                ? moment.tz(activity.dateTimeString, facilityTimeZone).format('M/D/YY')
                : '-';
            const time = activity.timestamp
                ? moment.tz(activity.timestamp, facilityTimeZone).format('h:mm A')
                : activity.dateTimeString
                ? moment.tz(activity.dateTimeString, facilityTimeZone).format('h:mm A')
                : '-';
            const asset = (activity.assetId && assetDict[activity.assetId]) || null;
            return {
                _id: activity._id || '',
                event: activity.text || '-',
                host: '-',
                Date: date,
                Time: time,
                Asset: (asset && asset.AssetName) || '-',
                attendeesCount: activityAttendees.length || 0,
            };
        });
    };

    const formatExportData = () => {
        const exportData: any = [];
        activities.forEach((activity: any) => {
            const date = activity.timestamp
                ? moment.tz(activity.timestamp, facilityTimeZone).format('M/D/YY')
                : activity.dateTimeString
                ? moment.tz(activity.dateTimeString, facilityTimeZone).format('M/D/YY')
                : '-';
            const time = activity.timestamp
                ? moment.tz(activity.timestamp, facilityTimeZone).format('h:mm A')
                : activity.dateTimeString
                ? moment.tz(activity.dateTimeString, facilityTimeZone).format('h:mm A')
                : '-';
            const asset = (activity.assetId && assetDict[activity.assetId]) || null;
            const allAttendeesMatchActivityId = attendanceData.filter(
                (attendee) => attendee.activityId === activity._id,
            );
            if (allAttendeesMatchActivityId.length === 0) {
                return [];
            }
            allAttendeesMatchActivityId.forEach((attendee) => {
                exportData.push({
                    _id: activity._id || '',
                    event: activity.text || '-',
                    FirstName: attendee.registrant.FirstName || '-',
                    LastName: attendee.registrant.LastName || '-',
                    Apartment:
                        (registrantUnitDict[attendee.registrantId] && registrantUnitDict[attendee.registrantId].Name) ||
                        '-',
                    host: '-',
                    Date: date,
                    Time: time,
                    Location: (asset && asset.AssetName) || '-',
                });
            });
        });
        // Sort exportData by Date and then by Time
        exportData.sort((a, b) => {
            const dateA = moment(a.Date + ' ' + a.Time, 'M/D/YY h:mm A');
            const dateB = moment(b.Date + ' ' + b.Time, 'M/D/YY h:mm A');
            return dateA - dateB;
        });
        // return the sorted exportData
        return exportData;
    };

    const tableData = formatDataForTableView() || [];
    const exportedData = formatExportData();

    const tableClickHandler = (header: string, row: object) => {
        if (header === 'attendeesCount') {
            handleAtteendeesClick(row['_id']);
        }
    };

    return (
        <div>
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            <Grid>
                <Grid.Row style={{ marginBottom: '5px' }}>
                    <Grid.Column width={9} rows={2}>
                        <Grid.Row style={{ margin: '1vh 0 2vh 0' }}>
                            {/* Overview table */}
                            <Segment style={{ marginBottom: '30px' }}>
                                <ReportsAttendanceOverviewTable
                                    overviewTableData={overviewTableData}
                                    overviewTableDetailViewData={overviewTableDetailViewData}
                                    activities={activities}
                                    facilityTimezone={facilityTimeZone}
                                />
                            </Segment>
                        </Grid.Row>
                        <Grid.Row>
                            {/* Table section */}
                            <Segment>
                                <div style={{ margin: '25px' }}>
                                    <CustomTable
                                        allowSearch={true}
                                        searchkey={['event']}
                                        searchBoxPlaceholder="Search by activity name..."
                                        data={tableData}
                                        headers={['Event', 'Host', 'Date', 'Time', 'Asset', 'Attendees']}
                                        facilityTimezone=""
                                        formatString="D/M/Y H:M A"
                                        loading={loading}
                                        rowKeys={['event', 'host', 'Date', 'Time', 'Asset', 'attendeesCount', '_id']}
                                        clickableKeys={['attendeesCount']}
                                        clickHandler={tableClickHandler}
                                        allowExport={true}
                                        allowPrint={false}
                                        exportFileName="reports_attendance"
                                        formattedExportData={exportedData}
                                        exportEmptyData={exportedData.length > 0 ? false : true}
                                        overflowX="hidden"
                                    />
                                    <Modal
                                        open={selectedTableData.length ? true : false}
                                        onClose={() => setSelectedTableData([])}
                                        closeIcon={true}
                                    >
                                        <Modal.Content>
                                            <AttendanceDetailViewTable data={selectedTableData} />
                                        </Modal.Content>
                                    </Modal>
                                </div>
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Grid.Row style={{ marginTop: '1vh' }}>
                            {/* Pie charts */}
                            <Segment>
                                <ReportsAttendancePieChart
                                    searchTerm={graphSearchTerm}
                                    setSearchTerm={handleSearchForGraph}
                                    attendedData={attendedData}
                                    declinedData={declinedData}
                                />
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: 0, paddingTop: 0 }}>
                    {/* Attendance content table */}
                    <Segment style={{ margin: '0 0 0 0.7vw', width: '90%', height: '100%' }}>
                        <ReportContentRequests
                                startDate={startDate}
                                endDate={endDate}
                                facilityTimeZone={facilityTimeZone}
                                attendancePage={true}
                        />
                    </Segment>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default ReportsAttendance;
