import React, { useState, useEffect } from 'react'
import { Button, Message, Dimmer, Loader } from 'semantic-ui-react'
import { assertEndpointAddress } from '../../../../services/A4hEndpoints'
import { fetchAllUnits } from '../../../../services/Units'
import { fetchOneFacility } from '../../../../services/Facilities'
import { Facility } from '../../../../types/facilities'
import { AuthState } from '../../../../types'
import { useSelector } from 'react-redux'
import { sendToast } from '../../../../util';

const AssertAddress = props => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile
    })
    const [facility, setFacility] = useState<Facility | undefined>(undefined)
    const [fetchingFacility, setFetchingFacility] = useState<boolean>(true)
    const [missingParams, setMissingParams] = useState<boolean | undefined>(undefined)
    const [errMsg, setErrMsg] = useState<string>('')
    const [tz, setTz] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const processFacility = (fac) => {
        const {
            Address = '',
            City = '',
            State = '',
            ZIP = '',
            Name = '',
            FacilityTimeZone: Timezone = ''
        } = fac
        setFetchingFacility(false)
        if (!fac) {
            setMissingParams(true)
            return
        }
        setFacility(fac)
        if (!Address || !City || !State || !ZIP || !Name)
            setMissingParams(true)

        if (Timezone)
            setTz(Timezone)
    }

    const handleFacility = async (id = '') => {
        if (profile && profile.Facility) {
            setFetchingFacility(true)
            setMissingParams(false)
            const fac = await fetchOneFacility(profile.Facility)
            processFacility(fac)
        }
    }

    useEffect(() => {
        handleFacility(profile ? profile.Facility : "")
    }, [profile && profile.Facility])

    const handleAddrAssertion = async () => {
        try {
            setLoading(true);
            if (!(profile && profile.Facility)) {
                setErrMsg('Missing facility id');
                return;
            }

            const dbRooms = await fetchAllUnits(profile && profile.Facility);
            const rooms = dbRooms.map(room => {
                if (room.Name && room.a4hRoomId) {
                    return {
                        RoomName: room.Name,
                        RoomId: room.a4hRoomId
                    };
                }
            }).filter(Boolean);
            const address = facility && facility.Address;
            const city = facility && facility.City;
            const state = facility && facility.State;
            const postalCode = facility && facility.ZIP;

            if (!address || !city || !state || !postalCode) {
                setErrMsg('Missing assertion params');
                return;
            }
            await assertEndpointAddress({
                rooms,
                address,
                city,
                state,
                postalCode,
                timezoneName: tz
            });
            sendToast('success', 'Address and timezone assertion initiated successfully. Please refresh the page after a couple of minutes to view the assertion status.');
            setLoading(false);
        } catch (e) {
            setErrMsg('Something went wrong');
            sendToast('error', e instanceof Error ? e.message : 'Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {profile && profile.Facility && !fetchingFacility &&
                <div>
                    <Dimmer inverted active={loading}>
                        <Loader active={loading}/>
                    </Dimmer>
                    {facility && facility.Name ?
                        <div>
                            <h5>{facility.Name}</h5>
                            <ul>
                                <li>Address - {facility.Address || 'N/A'}</li>
                                <li>City - {facility.City || 'N/A'}</li>
                                <li>State - {facility.State || 'N/A'}</li>
                                <li>ZIP - {facility.ZIP || 'N/A'}</li>
                                <li>Country - US</li>
                                <li>Timezone - {tz || 'N/A'}</li>
                            </ul>
                            {missingParams && <p>
                                Please update your address to enable assertions
                            </p>}
                        </div>
                        :
                        <h4>Facility not found!</h4>
                    }

                    <div style={{ display: "flex", gap: "1px" }}>
                        <Button
                            type='button'
                            basic
                            content='Assert address'
                            onClick={handleAddrAssertion}
                            disabled={missingParams || !facility || !facility.Name}
                            color='blue'
                        />
                    </div>
                    {errMsg &&
                        <Message negative>{errMsg}</Message>
                    }
                </div>
            }
        </>
    )
}

export default AssertAddress
