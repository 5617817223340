import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon, Button, Modal } from 'semantic-ui-react';
import "./style.less"
import DateRangePicker from '../DateRangePicker';

export interface DateRange {
    start?: Date | undefined,
    end?: Date | undefined
}
interface Props {
    dateRange?: DateRange;
    selectedDate?: Date;
    setDateRange?: (dateRange: DateRange) => void;
    setDate?: (date: Date) => void;
    maxDate?: Date;
    minDate?: Date;
    onChange?: (start: Date, end: Date) => void;
    useNewStyles?: boolean;
    residentInteration?: boolean;
    skipMaxDate?: boolean;
    selectsRange?: boolean;
    showDatePickerWithTime?: boolean;
}

const formatDateRange = (dateRange: DateRange): string => {
    const startDate = dateRange.start ? moment(dateRange.start).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY");
    const endDate = dateRange.end ? moment(dateRange.end).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY");
    return `${startDate} - ${endDate}`;
}
const formatSingleDate = (date: Date): string => {
    return moment(date).format("MM-DD-YYYY");
}

const CustomDatePicker: React.FC<Props> = ({ dateRange, selectedDate, setDateRange, setDate, maxDate, minDate, onChange, useNewStyles = false, residentInteration = false, selectsRange = true, skipMaxDate, showDatePickerWithTime = false}) => {
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const calendarRef = useRef<HTMLHeadingElement>(null);
    const [showDateAndTimePickerModal, setShowDateAndTimePickerModal] = useState<boolean>(false);
    const closeShowDateAndTimePickerModal = () => {
        setShowDateAndTimePickerModal(false);
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);
    if (showDatePickerWithTime) {
        return (
            showDatePickerWithTime && (
                <div ref={calendarRef} style={{ position: 'relative' }}>
                    <Button
                        onClick={() => {
                            setShowDateAndTimePickerModal(true);
                        }}
                        className="date-range-selector inner-datepicker"
                        style={{ alignItems: 'center' }}
                    >
                        <Icon name="calendar alternate outline" size="large" />
                        <span style={{ fontSize: '10px' }}>
                            {selectsRange
                                ? formatDateRange(dateRange || { start: new Date(), end: new Date() })
                                : formatSingleDate(selectedDate || new Date())
                            }
                        </span>
                    </Button>
                    <Modal open={showDateAndTimePickerModal} onClose={closeShowDateAndTimePickerModal} size='small'>
                        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} closeShowdateAndTimePickerModal={closeShowDateAndTimePickerModal} />
                    </Modal>
                </div>
            )
        );
    } else if (useNewStyles) {
        return (
            <div ref={calendarRef} style={{ position: 'relative' }}>
                <Button
                    onClick={() => setShowDatePicker((prev) => !prev)}
                    className="date-range-selector inner-datepicker"
                    style={{ alignItems: 'center' }}
                >
                    <Icon name="calendar alternate outline" size="large" />
                    <span style={{ fontSize: '10px' }}>{selectsRange ? formatDateRange(dateRange || {start: new Date(), end: new Date()}) : formatSingleDate(selectedDate || new Date())}</span>
                </Button>
                {showDatePicker && (
                    <div style={{ position: 'absolute', zIndex: 3 }} className={residentInteration ? "rightside" : ""}>
                        {selectsRange ? (
                            <DatePicker
                                selected={(dateRange && dateRange.start) || new Date()}
                                startDate={(dateRange && dateRange.start) || new Date()}
                                endDate={(dateRange && dateRange.end)}
                                maxDate={skipMaxDate ? null : new Date()}
                                onChange={(payload) => {
                                        const [start, end] = payload;
                                        if (setDateRange)
                                            setDateRange({ ...dateRange, start, end });
                                        if (onChange) 
                                            onChange(start, end);
                                        if (start && end) {
                                            setShowDatePicker(false);
                                        }
                                }}
                                selectsRange
                                inline
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                        ) : (
                            <DatePicker
                                selected={selectedDate || new Date()}
                                maxDate={skipMaxDate ? null : new Date()}
                                onChange={(payload: Date) => {
                                        const selectedDate = payload;
                                        if(setDate)
                                            setDate(selectedDate);
                                        setShowDatePicker(false);
                                }}
                                inline
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                        )}
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div ref={calendarRef} style={{ display: "flex", gap: "2px", justifyContent: "left", alignItems: "flex-start", cursor: "pointer", position: "relative" }}>
                <Icon name='calendar' size='small' style={{ marginTop: '4px' }} onClick={() => {
                    setShowDatePicker(prev => !prev);
                }} />
                <div>
                    <span onClick={() => setShowDatePicker(prev => !prev)}>{selectsRange ? formatDateRange(dateRange || {start: new Date(), end: new Date()}) : formatSingleDate(selectedDate || new Date())}</span>
                    {showDatePicker &&
                        <div style={{ position: "absolute", zIndex: 2 }}>
                            {selectsRange ? (
                                <DatePicker
                                    selected={(dateRange && dateRange.start) || new Date()}
                                    startDate={(dateRange && dateRange.start) || new Date()}
                                    endDate={(dateRange && dateRange.end)}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onChange={(payload) => {
                                            const [start, end] = payload;
                                            if (setDateRange)
                                                setDateRange({ ...dateRange, start, end });
                                            if (onChange) 
                                                onChange(start, end);
                                            if (start && end) {
                                                setShowDatePicker(false);
                                            }
                                    }}
                                    selectsRange
                                    inline
                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                />
                            ) : (
                                <DatePicker
                                    selected={selectedDate || new Date()}
                                    maxDate={maxDate || new Date()}
                                    onChange={(payload: Date) => {
                                            console.log({ payload })
                                            const selectedDate = payload;
                                            if(setDate)
                                                setDate(selectedDate);
                                            setShowDatePicker(false);
                                    }}
                                    inline
                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default CustomDatePicker;