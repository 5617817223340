import React, { useState, useEffect } from 'react'
import { fetchInviteAnalytics } from '../../services/Analytics';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import moment from 'moment-timezone';

export const useAdminInvites = (
    filters: any,
    sortByStr: string | undefined,
    setErrorInfo: Function
) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });

    const facilityTZ = profile && profile.FacilityTimeZone || "";
    const [invites, setInvites] = useState<any>([]);
    const [itemCount, setItemCount] = useState(0);
    const [pendingItemCount, setPendingItemCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchInvites = async () => {
        setIsLoading(true);
        try {
            const [acceptedInviteResponse, pendingInviteResponse] = await Promise.all([
                fetchInviteAnalytics({ ...filters, inviteType: "accepted", sortBy: sortByStr && sortByStr.length ? sortByStr : "DateAdded:desc" }),
                fetchInviteAnalytics({ ...filters, inviteType: "pending", sortBy: sortByStr && sortByStr.length ? sortByStr : "DateAdded:desc" })
            ]);
            if (acceptedInviteResponse && pendingInviteResponse) {
                const pendingInvites = pendingInviteResponse.invites.map((invite) => {
                    return { ...invite, pending: true };
                });
                let inviteResponse = {
                    invites: [...acceptedInviteResponse.invites, ...pendingInvites],
                    totalItemCount: acceptedInviteResponse.totalItemCount + pendingInviteResponse.totalItemCount
                };
                if (sortByStr && sortByStr.includes("DateAdded:asc")) {
                    inviteResponse.invites.sort((a, b) => b.DateAdded - a.DateAdded);
                } else {
                    inviteResponse.invites.sort((a, b) => b.DateAdded - a.DateAdded);
                }
                if (inviteResponse) {
                    const { totalItemCount } = inviteResponse;
                    const formattedInvites = inviteResponse.invites.map((invite) => {
                        const DateAdded = invite.DateAdded ? moment.tz(invite.DateAdded, facilityTZ).format('M/D/YY h:mm A') : "-";
                        const LastInviteSent = invite.LastInviteSent && moment.tz(invite.LastInviteSent, facilityTZ).format('M/D/YY h:mm A') || "-";
                        const ResendCount = invite.ResendCount ? invite.ResendCount : "-";
                        const pending = invite.pending ? "Pending" : "Accepted";
                        const messageCount = invite.messageCount ? invite.messageCount : "-";
                        return { ...invite, DateAdded, LastInviteSent, ResendCount, pending, messageCount };
                    });
                    setInvites(formattedInvites);
                    setItemCount(totalItemCount);
                    setPendingItemCount(pendingInviteResponse.totalItemCount)
                    setErrorInfo('');
                } else {
                    setErrorInfo('Something went wrong. Please try again');
                }
            } else {
                setErrorInfo('Something went wrong. Please try again');
            }
        } catch (error) {
            setErrorInfo('An error occurred: ' + (error instanceof Error ? error.message : 'Something went wrong'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (filters.fromDate && filters.toDate)
            fetchInvites();
    }, [filters, sortByStr]);

    return {
        invites,
        itemCount,
        isLoading,
        pendingItemCount
    }
}