import momentTZLib from 'moment-timezone';
import moment from 'moment';
/**
 * Formats a date using the specified timezone and format string.
 * If no timezone is provided, it formats the date in the user's local timezone.
 * 
 * @param {Date|string|number} date - The date to format. Can be a Date object, a string, or a timestamp.
 * @param {string} formatString - The format string to use for formatting the date.
 * @param {string} [timezone] - Coverts the date to the specified timezone before formatting.
 * @returns {string} The formatted date. eg 2023-12-08T15:59:59
 */

export const formatDateWithTZ = (date: string | number, formatString: string, timezone: string): string => {
    const parsedDate = momentTZLib.tz(date, timezone) 
    return parsedDate.format(formatString);
}

/**
 * Converts a date to a UTC timestamp.
 * If a timezone is provided, the date is first converted to that timezone.
 * If no timezone is provided, the date is treated as local time.
 * 
 * @param {Date|string|number} date - The date to convert. Can be a Date object, a string, or a timestamp.
 * @param {string} [timezone] - The timezone to use for conversion.
 * @returns {number} The UTC timestamp. eg 1701993600000
 */
export const convertToUTCTimestampUsingTZ = (date: string, timezone: string): number => {
    const parsedDate = momentTZLib.tz(date, timezone)
    return parsedDate.valueOf();
}

/**
 * Converts a date range to the start and end of day timesstamps based on the specified timezone.
 * Accepts fromDate and toDate as Date object, string, or timestamp.
 * If no timezone is provided, it uses the user's local timezone.
 * 
 * @param {Date|string|number} fromDate - The start date. Can be a Date object, a string, or a timestamp.
 * @param {Date|string|number} toDate - The end date. Can be a Date object, a string, or a timestamp.
 * @param {string} [timezone] - Optional. The timezone of the facility. If not provided, local timezone is used.
 * @returns {{startTimestamp: number, endTimestamp: number}} An object containing start and end of day timestamps. eg {
    "startTimestamp": 1701993600000,
    "endTimestamp": 1702079999999
    }
 */
export const getStartEndOfDayTimestampsForRangeInTZ = (
    fromDate: string , 
    toDate: string,
    timezone: string
): { startTimestamp: number; endTimestamp: number } => { // if the timezone is not valid (eg America), moment-timezone does not throw error but creates corrupted values

    // Parse the dates in the facility timezone, or in local timezone if not provided
    const fromMoment = momentTZLib.tz(fromDate, timezone)
    const toMoment = momentTZLib.tz(toDate, timezone)

    return {
        // Start of the day in the specified timezone
        startTimestamp: fromMoment.startOf('day').valueOf(),
        // End of the day in the specified timezone
        endTimestamp: toMoment.endOf('day').valueOf(),
    };
}

/**
 * This function calculates the human-readable time difference between a given date and the current time, considering a specific timezone.
 * 
 * @param {Date|string|number} date - The date you want to compare with the current time. Can be a Date object, a string representing a date, or a timestamp.
 * @param {string} timezone - The timezone in which you want to compare the time. This should be a valid timezone string (e.g., 'America/New_York', 'Europe/Berlin').
 * 
 * @returns {string} A human-readable string representing the time difference (e.g., '2 hours ago', 'in 3 days').
 * 
 */

export const distanceInWordsToNowWithTimezone = (date: Date, timezone: string) => {
    const now = momentTZLib(new Date(), timezone);
    const targetTime = momentTZLib.tz(date, timezone);
    const diff = targetTime.diff(now);
    return momentTZLib.duration(diff).humanize(true);
}

export const getDurationInMs = (start: string, end: string): number => {
    const duration = moment(end).diff(moment(start));
    return duration;
};

export const getFormattedEndDateString = (start: string, durationMs: number, format: string): string => {
    const endDate = moment(start).add(durationMs, 'ms').format(format);
    return endDate;
};