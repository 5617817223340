import API from './API'

export const fetchFeedItems = async (Filter: Partial<{ fromDate: number, toDate: number, facilityId: Object[] | undefined}> = {}, Pagination: Partial<{ page_no: number, page_size: number, sorting: Object[] | undefined}> = {}, requestsFormat: boolean = false ) => {
    try {
        const res = await API.lambdaPost('/feed/list', {
            Filter,
            Pagination, 
            requestsFormat,
        });
        return res;
    } catch (error) {
        throw error;
    }
}