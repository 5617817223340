import React, { Key, useEffect, useRef, useState } from 'react';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';
import OpenRequests from '../OpenRequests';
import Unassigned from '../Unassigned';
import './style.less';
import CreateRequestModal from '../../../../components/Requests/CreateRequestModal';
import { AuthState, Facility, OrderIdColorDict, Request } from '../../../../types';
import { fetchOneFacility } from '../../../../services/Facilities';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Requests from '../../../../components/Requests';
import { useSelector } from 'react-redux';
import { fetchUnassignedRequests } from '../../../../services/Requests';
import { toast } from 'react-toastify';
import { AppState } from '../../../../reducers';
import { formatRequestsDictForCalendarEvents, getOrderIdColor } from '../../../../util/requests';

function StaffDashboard(props) {
    const upcomingRequestPageSize = 20;
    const [upcomingRequestPageNo, setUpcomingRequestPageNo] = useState<number>(1);
    const [open, setOpen] = useState<boolean>(false);
    const [refreshOpen, setRefreshOpen] = useState<Key>("false");
    const [refreshClose, setRefreshClose] = useState<Key>("false");
    const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
    const [showClosedAccReqModal, setShowClosedAccReqModal] = useState<boolean>(false);
    const [showUpcomingReqDueModal, setShowUpcomingReqDueModal] = useState<boolean>(false);
    const [orderIdColorDict, setOrderIdColorDict] = useState<OrderIdColorDict>({});
    const [alreadyGeneratedColors, setAlreadyGeneratedColors] = useState<string[]>([]);
    const [dates, setDates] = useState({
        fromDate: (() => {
            const fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the current date
            return fromDate;
        })(),
        toDate: (() => {
            const toDate = new Date();
            toDate.setDate(toDate.getDate() + 7); // Add 7 days to the current date
            return toDate;
        })(),
    });
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const calendarRef = useRef<HTMLHeadingElement>(null);
    const [isOpenReqListView, setIsOpenReqListView] = useState<boolean>(true);
    const [isAcceptedAndClosedReqListView, setIsAcceptedAndClosedReqListView] = useState<boolean>(true);
    // const [totalUpcomingReqDueCount, setTotalUpcomingReqDueCount] = useState<number>(0);
    // const [upcomingDueRequests, setUpcomingDueRequests] = useState<Request[]>([]);
    const [fetchingUpcomingDueRequests, setFetchingUpcomingDueRequests] = useState<boolean>(false);
    // const [isUpcomingRequestListView, setIsUpcomingRequestListView] = useState<boolean>(true);
    const requestSortOptions = [{ key: "asc", value: "desc", text: "Oldest" }, { key: "desc", value: "asc", text: "Newest" }]; // The backend is faulty. If we send descending we get ascending values and if we send ascending we get descending value so we have shifted it
    const [selectedOpenSortingValue, setSelectedOpenSortingValue] = useState<string>("asc");  
    const [selectedAcceptedAndClosedSortingValue, setSelectedAcceptedAndClosedSortingValue] = useState<string>("asc");
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const { unassignedRequests, requests } = useSelector((state: AppState) => state.requestsReducer);
    const allRequests = [...(unassignedRequests && unassignedRequests.length > 0 ? unassignedRequests : []), ...(requests && requests.length > 0 ? requests : [])];
    allRequests && allRequests.length > 0 && allRequests.forEach((request) => {
        if (request.OrderId) {
            getOrderIdColor(orderIdColorDict, setAlreadyGeneratedColors, setOrderIdColorDict, alreadyGeneratedColors, request.OrderId);
        }
    }
    );    
    
    const unassignedIsFetching = useSelector((state: AppState) => {
        return state.requestsReducer.unassignedRequestFetching;
    })
    const acceptedIsFetching = useSelector((state: AppState) => {
        return state.requestsReducer.fetching;
    });
    const facilityTimezone = profile && profile.FacilityTimeZone || ""; 


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);

    useEffect(() => {
        const getQueryParams = () => {
            const queryParams = new URLSearchParams(props.location.search);
            const expandOpen = queryParams.get('expandOpen');
            const expandAccClosed = queryParams.get('expandAccClosed');
            const expandUpcomingReqDue = queryParams.get('expandUpcomingReqDue');

            setOpenRequestModal(expandOpen === 'true');
            setShowClosedAccReqModal(expandAccClosed === 'true');
            setShowUpcomingReqDueModal(expandUpcomingReqDue === 'true');
        };

        getQueryParams();
    }, [props.location]);

    // const fetchUpcomingRequests = async () => {
    //     try {
    //         let data: Request[] = [];
    //         const { count: resTotalCount = 0, requests: fetchedResponse = [] } = await fetchUnassignedRequests(upcomingRequestPageNo, upcomingRequestPageSize, undefined, facilityTimezone, true, !isUpcomingRequestListView);
    //         if(!isUpcomingRequestListView) {
    //             data = formatRequestsDictForCalendarEvents(fetchedResponse);
    //         } else {
    //             data = fetchedResponse;
    //         }
    //         setTotalUpcomingReqDueCount(resTotalCount);
    //         setUpcomingDueRequests(data);
    //     } catch (error) {
    //         console.error("fetchUpcomingRequests", error);
    //         toast.warn(error instanceof Error ? error.message : "Failed to fetch upcoming requests due.", {
    //             position: 'bottom-center',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //         });
    //     }
    // }

    const formatDateRange = (dateRange: { fromDate: Date; toDate: Date; }) => {
        const startDate = dateRange.fromDate ? moment(dateRange.fromDate).format('MM/DD/YYYY') : 'none';
        const endDate = dateRange.toDate ? moment(dateRange.toDate).format('MM/DD/YYYY') : 'none';
        if (!dateRange.toDate || startDate === endDate) return startDate;
        return `${startDate} - ${endDate}`;
    };

    const handleRefreshOpen = () => {
        const booleanValue = JSON.parse(`${refreshOpen}`);
        setRefreshOpen(`${!booleanValue}`);
    };

    const handleRefreshClose = () => {
        const booleanValue = JSON.parse(`${refreshClose}`);
        setRefreshClose(`${!booleanValue}`);
    };

    const combinedRefresh = () => {
        handleRefreshOpen();
        handleRefreshClose();
    };

    const onOpenReqViewChange = (isListView: boolean) => {
        setIsOpenReqListView(isListView);
    };

    const onAcceptedAndClosedReqViewChange = (isListView: boolean) => {
        setIsAcceptedAndClosedReqListView(isListView);
    };

    // const onFetchRequests = async (userId: string, pageNo?, filter?, facilityTZ?: string, fetchAllData?: boolean) => {
    //     setFetchingUpcomingDueRequests(true);
    //     await fetchUpcomingRequests();
    //     setFetchingUpcomingDueRequests(false);
    // }

    // const onBgFetchRequests = async (userId: string, pageNo?, filter?, facilityTZ?: string, fetchAllData?: boolean) => {
    //     await fetchUpcomingRequests();
    // }

    // const onUpcomingReqViewChange = (isListView: boolean) => {
    //     setIsUpcomingRequestListView(isListView);
    // }

    // const renderUpcomingRequestsDue = () => {
    //     return <Requests
    //         requestTitle='Upcoming Requests Due'
    //         emptyMessage='No Upcoming Requests Due'
    //         unassigned={true}
    //         facilityTZ={facilityTimezone}
    //         userId={profile && profile._id}
    //         unassignedRequestCount={totalUpcomingReqDueCount}
    //         unassignedRequestLimit={upcomingRequestPageSize}
    //         unassignedRequests={upcomingDueRequests}
    //         onFetchRequests={onFetchRequests}
    //         onBgFetchRequests={onBgFetchRequests}
    //         requests={[]}
    //         count={0}
    //         isFetching={false}
    //         unassignedRequestIsFetching={fetchingUpcomingDueRequests}
    //         hasMorePages={totalUpcomingReqDueCount >= upcomingRequestPageSize * upcomingRequestPageNo}
    //         errorCode={null}
    //         hasError={false}
    //         history={props.history}
    //         limit={20}
    //         page={upcomingRequestPageNo}
    //         isListView={isUpcomingRequestListView}
    //         onViewUpdate={onUpcomingReqViewChange}
    //         modalView={openRequestModal || showClosedAccReqModal || showUpcomingReqDueModal || false} 
    //         isUpcomingRequestsDue={true}
    //         location={props.location}
    //         match={props.match}
    //         refresh={combinedRefresh}
    //         setPageNo={setUpcomingRequestPageNo}
    //     />
    // }

    const isLoading = unassignedIsFetching || acceptedIsFetching || fetchingUpcomingDueRequests;
    return (
        <div className="staff-dashboard">
            <div className='staff-header'>
                <Button onClick={() => setOpen(true)}>Create request</Button>
                <div ref={calendarRef}>
                    <Button
                        onClick={() => setShowDatePicker((prev) => !prev)}
                        className="date-range-selector"
                    >
                        <Icon name="calendar alternate outline" size="large" />
                        <span style={{ fontSize: '12px' }}>{formatDateRange(dates)}</span>
                    </Button>
                    {showDatePicker && !isLoading && (
                        <div style={{ position: 'absolute', zIndex: 2 }}>
                            <DatePicker
                                selected={dates.fromDate}
                                startDate={dates.fromDate}
                                endDate={dates.toDate}
                                onChange={(startEndDates) => {
                                    const [start, end] = startEndDates;
                                    setDates({ ...dates, fromDate: start, toDate: end });
                                    if (start && end) {
                                        setShowDatePicker(false);
                                    }
                                }}
                                selectsRange
                                inline
                                style={{ position: 'absolute', top: 0, left: 0 }}
                                readOnly={true}
                            />
                        </div>
                    )}
                </div>
            </div>
            {open && (
                <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} style={{ width: "450px" }}>
                    <Modal.Content>
                        <CreateRequestModal
                            facilityId={props.profile.Facility}
                            setOpen={setOpen}
                            refresh={handleRefreshOpen}
                        />
                    </Modal.Content>
                </Modal>
            )}
            {openRequestModal && (
                <Modal open={openRequestModal} onClose={() => {
                    props.history.push("/");
                }} size='fullscreen' dimmer="blurring">
                    <Modal.Content>
                        {/* Open Requests */}
                        <Unassigned
                            key={refreshOpen}
                            refresh={combinedRefresh}
                            staffDateRange={dates} 
                            modalView={true}
                            isListView={isOpenReqListView}
                            onViewUpdate={onOpenReqViewChange}
                            showSort={true}
                            requestSortOptions={requestSortOptions}
                            sortDropdownValue={selectedOpenSortingValue}
                            setSortDropdownValue={setSelectedOpenSortingValue}    
                            orderIdColorDict={orderIdColorDict}                
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            props.history.push("/");
                        }}>Close</Button>
                    </Modal.Actions>
                </Modal>
            )}
            {showClosedAccReqModal && (
                <Modal open={showClosedAccReqModal} onClose={() => {
                    props.history.push("/");
                }} size='fullscreen' dimmer="blurring">
                    <Modal.Content>
                        {/* My Requests (accepted) */}
                        <OpenRequests
                            key={refreshClose}
                            refresh={combinedRefresh} 
                            staffDateRange={dates}
                            modalView={true}
                            isListView={isAcceptedAndClosedReqListView}
                            onViewUpdate={onAcceptedAndClosedReqViewChange}
                            showSort={true}
                            requestSortOptions={requestSortOptions}
                            sortDropdownValue={selectedAcceptedAndClosedSortingValue}
                            setSortDropdownValue={setSelectedAcceptedAndClosedSortingValue}    
                            orderIdColorDict={orderIdColorDict}               
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            props.history.push("/");
                        }}>Close</Button>
                    </Modal.Actions>
                </Modal>
            )}

            {showUpcomingReqDueModal && (
                <Modal open={showUpcomingReqDueModal} onClose={() => {
                    props.history.push("/");
                }} size='fullscreen' dimmer="blurring">
                    <Modal.Content>
                        {/* Upcoming Requests Due */}
                        {/* {renderUpcomingRequestsDue()} */}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            props.history.push("/");
                        }}>Close</Button>
                    </Modal.Actions>
                </Modal>
            )}

            {(!openRequestModal && !showClosedAccReqModal && !showUpcomingReqDueModal && !open) ? <Grid columns={2} style={{ marginTop: '2rem' }} >
                <Grid.Row>
                    <Grid.Column>
                        {/* Upcoming Requests Due */}
                        {/* {renderUpcomingRequestsDue()} */}
                        {/* {renderUpcomingRequestsDue()} */}
                        {/* Open requests */}
                        <Unassigned key={refreshOpen} refresh={combinedRefresh} staffDateRange={dates} onViewUpdate={onOpenReqViewChange} isListView={isOpenReqListView}  showSort={true} requestSortOptions={requestSortOptions} sortDropdownValue={selectedOpenSortingValue} setSortDropdownValue={setSelectedOpenSortingValue} orderIdColorDict={orderIdColorDict}/>
                    </Grid.Column>
                    <Grid.Column>
                        {/* My Requests (accepted) */}
                        <OpenRequests key={refreshClose} refresh={combinedRefresh} staffDateRange={dates} onViewUpdate={onAcceptedAndClosedReqViewChange} isListView={isAcceptedAndClosedReqListView} showSort={true} requestSortOptions={requestSortOptions} sortDropdownValue={selectedAcceptedAndClosedSortingValue} setSortDropdownValue={setSelectedAcceptedAndClosedSortingValue} orderIdColorDict={orderIdColorDict}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid> : <></>}
        </div>
    );
}

export default withRouter(StaffDashboard);
