import React, { useState } from 'react';

import './style.less';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    Dimmer,
    Loader,
    Tab,
    TabProps,
    Pagination,
    PaginationProps,
    Button,
    Modal,
    Form,
    Dropdown
} from 'semantic-ui-react';
import { User, UserProfile } from '../../../types';
import 'flatpickr/dist/themes/airbnb.css';
import 'rc-time-picker/assets/index.css';
import { newMenu, fetchMenuItems, deleteMenu, deleteRecurringMenuGroup, updateMenu, updateMenuAttendance, listMenuAttendee } from '../../../services/Menus';
import MenuFormEditor from '../../../components/MenuFormEditor';
import ListItemsPane from '../../../components/ListItemsPane';

import { MenuType } from '../../../types/menu';
import { capitalizeFirstLetter } from './utils';
import { getUploadUrl, uploadToSignedUrl, checkDestPlayableWithTimeDelay } from '../../../services/PplOfTheMonth';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarEventContent from '../../../components/CalendarEventContent';
import { jsonTocsvDownloader, jsonTocsvDownloaderV2 } from '../../../util/jsonTocsvDownloader';
import { uploadSignageImageToS3, copySignageImageFromS3, uploadFile } from '../../../services/ImageUpload';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { fetchOneFacility, fetchResidentRooms } from '../../../services/Facilities';
import { AppState } from '../../../reducers';
import { connect } from 'react-redux';
import { fetchAllActiveFacilityRegistrants } from '../../../services/Registrants';
import { kanbanDataType } from '../../../types/kanbanDataTypes';
import kanbanInitData from './kanban-init-data';
import { listRoomTypes } from '../../../services/RoomTypes';
import ServiceInstances from '../../../components/ServiceInstances';
import { fetchOneUser, updateUser } from '../../../services/Users';
import ServiceInstanceFormCreate from '../../../components/ServiceInstances/ServiceInstanceFormCreate';
import { ServicesType } from '../../../types/ServicesTypes';
import { deleteServiceInstance, fetchAllMenuAndServiceInstance, fetchPaginatedMenuandSvcInstance } from '../../../services/ServiceInstances';
import { sortByKey } from '../../../util/sortData';
import { prntyrLink } from '../../../util/data';
import { sendToast } from '../../../util';

interface State {
    isFetching: boolean 
    error: string | null
    isSaving: boolean
    menus: MenuType[]
    menuLength: number
    openCreateForm: boolean
    menuFilter: string | number
    activeFoodCategoryTab: string
    activeHistoryTab: string
    activePageNumber: any
    copyMenu: MenuType | null
    activeHistoryTabIndex: number
    activeFoodCategoryTabIndex: number
    warning: string
    audioBlob
    isCalendarView
    monthlyMenus: MenuType[]
    selectedDateInCalendar: string | undefined
    calendarTabIndex: number
    selectedEventId?: string | undefined
    selectedMenuItem?: MenuType | undefined
    showDetailModal: boolean
    showRecurringEditConfirmModal: boolean
    showRecurringDeleteConfirmModal: boolean
    modifyGroup: boolean
    deleteGroup: boolean
    allButtonLoader: boolean
    buttonLoader: boolean
    printBtnSpinner: boolean
    exportBtnSpinner: boolean
    copyImageUrl: string| undefined
    kanbanBoard: kanbanDataType
    selectedAttendees: string[]
    timezone?: string
    locationOptions: {
        key: string;
        text: string;
        value: string;
    }[];
    filteredMenu: MenuType[];
    selectedCalendarType: string[];
    fetchedMenus: MenuType[];
    loadDropdown: boolean;
    componentMounting: boolean;
    showSvcInstanceModal: boolean;
    svcInstanceData: Partial<ServicesType>;
    reloadDailySvcMenu: boolean;
    reloadServiceAccordianFlag: boolean;
    selectedStartDate: string;
    selectedEndDate: string;
    calendarDateOfDailytab: Date;
    initalCalendarDate: Date;
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class Menu extends React.Component<Props, State> {
    private isServiceEnabled: boolean = this.props.profile && this.props.profile.FacilityConfigurations && this.props.profile.FacilityConfigurations.dining ? true : false;
    private isDiningEnabled: boolean = this.props.profile && this.props.profile.FacilityConfigurations && this.props.profile.FacilityConfigurations.dining ? true : false;

    constructor(props: Props) {
        super(props)
        this.state = {
            isFetching: false,
            isSaving: false,
            error: null,
            menus: [],
            menuLength: 0,
            openCreateForm: false,
            menuFilter: '',
            activeHistoryTab: 'active',
            activeFoodCategoryTab: 'breakfast',
            activePageNumber: 1,
            copyMenu: null,
            activeHistoryTabIndex: 0,
            activeFoodCategoryTabIndex: 0,
            warning: '',
            audioBlob: undefined,
            isCalendarView: false,
            monthlyMenus: [],
            selectedDateInCalendar: undefined,
            calendarTabIndex: 0,
            selectedEventId: this.props.match.params['id'] || undefined,
            selectedMenuItem: undefined,
            exportBtnSpinner: false,
            printBtnSpinner: false,
            copyImageUrl: undefined,
            kanbanBoard: kanbanInitData,
            selectedAttendees: [],
            showDetailModal: false,
            showRecurringEditConfirmModal: false,
            showRecurringDeleteConfirmModal: false,
            modifyGroup: false,
            deleteGroup: false,
            buttonLoader: false,
            allButtonLoader: false,
            timezone: 'America/New_York',
            locationOptions: [],
            filteredMenu: [],
            selectedCalendarType: (this.props.profile && this.props.profile.selectedCalendarType && this.props.profile.selectedCalendarType.length && this.props.profile.selectedCalendarType) ||  [],
            fetchedMenus: [],
            loadDropdown: false,
            componentMounting: true,
            showSvcInstanceModal: false,
            svcInstanceData: {
                name: '',
                longDescription: '',
                shortDescription: '',
                Asset: '',
                calendarType: [],
                menuCategory: ''
              },
            reloadDailySvcMenu: false,
            reloadServiceAccordianFlag: false,
            selectedStartDate: '',
            selectedEndDate: '',
            calendarDateOfDailytab: new Date(),
            initalCalendarDate: new Date(),
        }
    }
    handlecalendarDateOfDailytab = (date: Date) => {
        this.setState({
            calendarDateOfDailytab: date
        })
    }

    handleChangeActiveTab = (listTab: string, tabNumber:number) => {
        this.setState({
            activeHistoryTab: listTab,
            activeHistoryTabIndex: tabNumber
        })
    }

    handleSvcInstanceModal = (startDate?: string, endDate?: string, menuCategory?:string) => {
        if (startDate && endDate) {
            this.setState(prevState => ({
                svcInstanceData: {
                    ...prevState.svcInstanceData,
                    startDate: startDate,
                    endDate: endDate,
                    calendarType: this.state.selectedCalendarType,
                    menuCategory: menuCategory && menuCategory.toLowerCase()
                },
                showSvcInstanceModal: !this.state.showSvcInstanceModal
            }));
        } else {
            this.setState({
                showSvcInstanceModal: !this.state.showSvcInstanceModal,
                svcInstanceData: {
                    calendarType: this.state.selectedCalendarType,
                }
            });
        }
    }

    setSvcInstanceData = (data) => {
        this.setState({
            svcInstanceData: data
        });
    }

    openCloseCreateMenuForm = (): void => {
        if (!this.state.openCreateForm && this.state.selectedMenuItem) {
            this.handleRedirectedEvent(this.state.selectedMenuItem);
        } else {
            if (this.state.selectedEventId) {
                this.setState({
                    openCreateForm: !this.state.openCreateForm,
                    warning: '',
                }, () => { this.handleRedirect(); });
            } else {
                this.setState({
                    openCreateForm: !this.state.openCreateForm,
                    warning: '',
                }, () => { this.handleRedirect(); });
            }
        }
    }

    opemMenuModal = () =>{
        this.setState({
            openCreateForm:true
        })
    }

    getLocationId(location: string, locationOptions: any[]) {
        const foundObject = locationOptions.find((option) => option.text === location);
        return foundObject ? foundObject.value : '';
    }

    filterMenuItems(menus) {
        menus.forEach((menu: MenuType) => {
            if (!menu.calendarType || !Array.isArray(menu.calendarType) || menu.calendarType.length === 0) {
                const defaultLocationId = this.getLocationId('Other', this.state.locationOptions);
                menu.calendarType = [defaultLocationId];
            }
        });

        const filteredMenus = menus.filter((menu) => {
            return menu && menu.calendarType && Array.isArray(menu.calendarType) && menu.calendarType.some((type) => this.state.selectedCalendarType.includes(type));
        });

        return filteredMenus;
    }

    editMenu(id: string): void {
        const menus = this.state.menus.map((menu: MenuType) => {
            if (menu._id === id) menu.edit = true
            return menu
        })
        const selectedMenuItems = this.state.menus.filter((menu: MenuType) => menu._id === id)
        this.setState({
            ...this.state,
            menus,
            warning: '',
            activeFoodCategoryTab: selectedMenuItems[0].category,
        })
    }

    cancelEditMenu(id?: string): void {
        if (id) {
            const menus = this.state.menus.map((menu: MenuType) => {
                if (menu._id === id) delete menu.edit
                return menu
            })
            this.setState({
                ...this.state,
                menus,
                copyMenu: null,
                warning: '',
                selectedEventId: '',
                selectedMenuItem: undefined,
                selectedDateInCalendar: undefined,
                openCreateForm: false,
                error: '',
                audioBlob: undefined,
                modifyGroup: false
            }, () => { this.handleRedirect(); })
        } else {
            this.setState({
                openCreateForm: false,
                copyMenu: null,
                warning: '',
                selectedEventId: '',
                selectedMenuItem: undefined,
                selectedDateInCalendar: undefined,
                error: '',
                audioBlob: undefined,
                modifyGroup: false
            });
        }
    }

    copyMenu(id: string): void {
        let menu = this.state.menus.find((menu: MenuType) => menu._id === id);
        document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
        if (menu) {
            if (menu.svcMenuInstance) {
                this.setSvcInstanceData(menu);
                this.setState({
                    showSvcInstanceModal: true,
                });
            } else {
                this.setState({
                    openCreateForm: true,
                    copyMenu: menu,
                    warning: '',
                });
            }
        }
    }

    handleLocationUpdate = (value: string[] = []) => {
        this.handleEventMenuEdit(value)
    }

    async getMenus(currentPage, pageLimit, menuType, filters: any = {}): Promise<{ items: MenuType[]; totalItems: number; }> {
        try {
            this.setState({ isFetching: true, error: null });
            if (!filters.startDateTimeString && !filters.endDateTimeString) {
                if (this.state.selectedStartDate && this.state.selectedEndDate) {
                    filters.startDateTimeString = this.state.initalCalendarDate ? moment(this.state.initalCalendarDate).startOf("day").format('YYYY-MM-DDTHH:mm:ss') : this.state.selectedStartDate;
                    filters.endDateTimeString = this.state.initalCalendarDate ? moment(this.state.initalCalendarDate).endOf("day").format('YYYY-MM-DDTHH:mm:ss') : this.state.selectedEndDate;
                } else {
                    const selectedDate = menuType === 'active' ? new Date() : new Date().setDate(new Date().getDate() - 1);
                    filters.startDateTimeString = this.state.initalCalendarDate ? moment(this.state.initalCalendarDate).startOf("day").format('YYYY-MM-DDTHH:mm:ss') : moment.tz(selectedDate, this.state.timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    filters.endDateTimeString = this.state.initalCalendarDate ? moment(this.state.initalCalendarDate).endOf("day").format('YYYY-MM-DDTHH:mm:ss') : moment.tz(selectedDate, this.state.timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                }
            }
            const menus = await fetchPaginatedMenuandSvcInstance({ page_no: currentPage, page_size: pageLimit, type: menuType, filters });
            return {
                items: menus.Result,
                totalItems: menus.TotRecords,
            };
        } catch (error) {
            console.log(error);
            toast.error(error instanceof Error ? error.message : "Failed to get menu items", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return {
                items: [],
                totalItems: 0,
            };
        } finally {
            this.setState({ isFetching: false });
        }

    }

    async getMenusCurrentMonth(
        currentPage,
        pageLimit,
        menuType,
        startTime,
        endTime,
        filters = {},
    ): Promise<{ items: MenuType[]; totalItems: number }> {
        this.setState({ isFetching: true, error: null })
        const calendarType = this.state.selectedCalendarType.length == 0 ? undefined : this.state.selectedCalendarType;
        const menuAndServiceInstance = await fetchAllMenuAndServiceInstance({ startDateTimeString: startTime, endDateTimeString: endTime, Facility: this.props.profile && this.props.profile.Facility, calendarType});
        this.setState({ isFetching: false })
        return {
            items: menuAndServiceInstance,
            totalItems: menuAndServiceInstance.length,
        }
    }

    async passAudioBlob(audioBlob) {
        this.setState({
            audioBlob,
        })
    }

    async uploadAudiofile() {
        const { audioId = undefined, signedUrl = undefined } = await getUploadUrl('menu')

        const file = new File([this.state.audioBlob], audioId, {
            type: 'audio/wav',
        })

        await uploadToSignedUrl(file, signedUrl)

        return audioId
    }

    async uploadImageFile(menuId: string, imageFile: File): Promise<void> {
        if (imageFile) {
            await uploadSignageImageToS3("menus", menuId, imageFile.name, imageFile)
        } else {
            throw new Error("Image file is not provided")
        }
    }

    async copyUploadImageFile(menuId: string, copyUrl: string): Promise<void> {
        if (copyUrl) {
            await copySignageImageFromS3('menus', copyUrl, menuId)
        } else {
            throw new Error("url is not provided")
        }
    }

    reloadChildComponents(){
       this.setState({reloadServiceAccordianFlag: !this.state.reloadServiceAccordianFlag});
      };

    async handleAudio(menu) {
        try {
            const audioId = await this.uploadAudiofile()

            if (audioId) {
                const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                let destPlayableExists
                for (let i = 0; i < 6; i++) {
                    destPlayableExists = await checkDestPlayableWithTimeDelay(
                        destAudioId,
                        'menu',
                        Math.pow(2, i) * 1000,
                    )

                    if (destPlayableExists) {
                        break
                    }
                }

                if (destPlayableExists) {
                    try {
                        const insertNewMenuId = await newMenu({
                            text: menu.text,
                            alexaReadableCollection: menu.alexaReadableCollection,
                            alexaReadableString: menu.alexaReadableString,
                            timestamp: moment.tz(menu.timestamp, this.state.timezone).unix() * 1000,
                            category: menu.category ? menu.category : this.state.activeFoodCategoryTab,
                            AddedBy: this.props.profile ? this.props.profile._id : '',
                            dateString: menu.dateString,
                            audioId: destAudioId ? destAudioId : null,
                            recurrence: menu.recurrence,
                            showAttendance: menu.showAttendance,
                            calendarType: menu.calendarType
                        })

                        if (!insertNewMenuId) {
                            this.setState({
                                warning: 'Menu category already exists for the selected day',
                            })
                        }

                        if (insertNewMenuId) {
                            const signageMenuId = Array.isArray(insertNewMenuId) 
                            ? insertNewMenuId.sort((a,b) => a.localeCompare(b))[0]
                            : insertNewMenuId;
                            if (menu.imageFile) {
                                await this.uploadImageFile(signageMenuId, menu.imageFile)
                            } else if (menu.copyImageUrl) {
                                await this.copyUploadImageFile(signageMenuId, menu.copyImageUrl)
                            } else if (this.state.copyImageUrl) {
                                await this.copyUploadImageFile(signageMenuId, this.state.copyImageUrl);
                            }
                        }

                        if (this.state.calendarTabIndex) {
                            await this.populateStateWithMenus('save')
                        } else {
                            const menuDate = new Date(menu.timestamp)
                            const firstDay = moment.tz(menuDate, this.state.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                            const lastDay = moment.tz(menuDate, this.state.timezone).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                            this.handleMonthChange({ start: firstDay, end: lastDay })
                        }
                    } catch (e) {
                        this.setState({
                            isSaving: false,
                            isFetching: false,
                            audioBlob: undefined,
                            error: e.message,
                            modifyGroup: false
                        })
                    }

                    await this.populateStateWithMenus('save')
                } else {
                    this.setState({
                        isSaving: false,
                        isFetching: false,
                        audioBlob: undefined,
                        error: 'Failed to process audio. Please start over',
                    })
                }
            } else {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    error: 'Error uploading audio. Please start over',
                })
            }
        } catch (e) {
            this.setState({
                isSaving: false,
                isFetching: false,
                audioBlob: undefined,
                error: e.message,
            })
        }
        return false
    }

    async handleSubmit(menu: MenuType): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        menu.alexaReadableString = menu.alexaReadableCollection
            .map((element) => {
                return element.insert
            })
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, '')

        //handle audio here
        if (this.state.audioBlob) {
            try {
                await this.handleAudio.bind(this)(menu)
            } catch (e) {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    error: e.message,
                    modifyGroup: false
                })
            }
        } else {
            try {
                const insertNewMenuId = await newMenu({
                    text: menu.text,
                    alexaReadableCollection: menu.alexaReadableCollection,
                    alexaReadableString: menu.alexaReadableString,
                    timestamp: moment.tz(menu.timestamp, this.state.timezone).unix() * 1000,
                    category: menu.category ? menu.category : this.state.activeFoodCategoryTab,
                    AddedBy: this.props.profile ? this.props.profile._id : '',
                    dateString: menu.dateString,
                    audioId: menu.audioId,
                    playableUrl: menu.playableUrl,
                    showAttendance: menu.showAttendance,
                    recurrence: menu.recurrence,
                    calendarType: menu.calendarType
                })

                if (!insertNewMenuId) {
                    this.setState({
                        warning: 'Menu category already exists for the selected day',
                    })
                }

                if (insertNewMenuId) {
                    const signageMenuId = Array.isArray(insertNewMenuId) 
                    ? insertNewMenuId.sort((a,b) => a.localeCompare(b))[0]
                    : insertNewMenuId;
                    if (menu.imageFile) {
                        await this.uploadImageFile(signageMenuId, menu.imageFile)
                    } else if (menu.copyImageUrl) {
                        await this.copyUploadImageFile(signageMenuId, menu.copyImageUrl)
                    } else if (this.state.copyImageUrl) {
                        await this.copyUploadImageFile(signageMenuId, this.state.copyImageUrl);
                    }
                }

                if (this.state.calendarTabIndex) {
                    await this.populateStateWithMenus('save')
                } else {
                    const menuDate = new Date(menu.timestamp)
                    const firstDay = moment.tz(menuDate, this.state.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    const lastDay = moment.tz(menuDate, this.state.timezone).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    this.handleMonthChange({ start: firstDay, end: lastDay })
                }
                this.setState({
                    modifyGroup: false
                })
                if (this.state.copyMenu) this.handleRedirect();
            } catch (error) {
                toast.warn(error instanceof Error ? error.message : "Somthing went wrong, please try again", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }) 
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    error: error.message,
                    modifyGroup: false
                })
            }
        }
    }

    async handleAttendanceUpdate(menu): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
        });
        const res = await updateMenuAttendance(menu);
        this.setState({
            isSaving: false,
            isFetching: false,
        });
    }
    async handleUpdate(menu): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        menu.alexaReadableString = menu.alexaReadableCollection
            .map((element) => {
                return element.insert
            })
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, '')

        try {

            if (this.state.audioBlob) {
                const audioId = await this.uploadAudiofile()

                if (audioId) {
                    const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                    let destPlayableExists
                    for (let i = 0; i < 6; i++) {
                        destPlayableExists = await checkDestPlayableWithTimeDelay(
                            destAudioId,
                            'menu',
                            Math.pow(2, i) * 1000,
                        )

                        if (destPlayableExists) {
                            break
                        }
                    }

                    if (destPlayableExists) {
                        const updateMenuResponse = await updateMenu({
                            _id: menu._id,
                            text: menu.text,
                            alexaReadableCollection: menu.alexaReadableCollection,
                            alexaReadableString: menu.alexaReadableString,
                            timestamp: moment.tz(menu.timestamp, this.state.timezone).unix() * 1000,
                            category: menu.category,
                            UpdatedBy: this.props.profile ? this.props.profile._id : '',
                            dateString: menu.dateString,
                            audioId: destAudioId ? destAudioId : null,
                            recurrence: menu.recurrence,
                            modifyGroup: this.state.modifyGroup,
                            showAttendance: menu.showAttendance,
                            calendarType: menu.calendarType,
                            ...(menu.imageFile || menu.copyImageUrl ?{updateSignage: true}: {})
                        })
                        if (!updateMenuResponse) {
                            this.setState({
                                warning: 'Menu category already exists for the selected day',
                            })
                        }

                        if (updateMenuResponse) {
                            const signageMenuId = this.state.modifyGroup ? menu.srcId : menu._id;
                            if (menu.imageFile || menu.copyImageUrl) {
                                if (menu.copyImageUrl) {
                                    await this.copyUploadImageFile(signageMenuId, menu.copyImageUrl)
                                } else {
                                    await this.uploadImageFile(signageMenuId, menu.imageFile)
                                }
                            }
                        }

                        if (this.state.calendarTabIndex) {
                            await this.populateStateWithMenus('update')
                        } else {
                            const menuDate = new Date(menu.timestamp)
                            const firstDay = moment.tz(menuDate, this.state.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                            const lastDay = moment.tz(menuDate, this.state.timezone).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                            this.handleMonthChange({ start: firstDay, end: lastDay })
                        }
                    } else {
                        this.setState({
                            isSaving: false,
                            isFetching: false,
                            audioBlob: undefined,
                            error: 'Failed to process audio. Please start over',
                        })
                    }
                }
            } else {
                const updateMenuResponse = await updateMenu({
                    _id: menu._id,
                    text: menu.text,
                    alexaReadableCollection: menu.alexaReadableCollection,
                    alexaReadableString: menu.alexaReadableString,
                    timestamp: moment.tz(menu.timestamp, this.state.timezone).unix() * 1000,
                    category: menu.category,
                    UpdatedBy: this.props.profile ? this.props.profile._id : '',
                    dateString: menu.dateString,
                    showAttendance: menu.showAttendance,
                    recurrence: menu.recurrence,
                    modifyGroup: this.state.modifyGroup,
                    calendarType: menu.calendarType,
                    ...(menu.imageFile || menu.copyImageUrl ?{updateSignage: true}: {}),
                })
                if (!updateMenuResponse) {
                    this.setState({
                        warning: 'Menu category already exists for the selected day',
                        modifyGroup: false
                    })
                }

                if (updateMenuResponse) {
                    const signageMenuId = this.state.modifyGroup ? menu.srcId : menu._id;
                    if (menu.imageFile || menu.copyImageUrl) {
                        if (menu.copyImageUrl) {
                            await this.copyUploadImageFile(signageMenuId, menu.copyImageUrl)
                        } else {
                            await this.uploadImageFile(signageMenuId, menu.imageFile)
                        }
                    }
                }

                if (this.state.calendarTabIndex) {
                    await this.populateStateWithMenus('update')
                } else {
                    const menuDate = new Date(menu.timestamp)
                    const firstDay = moment.tz(menuDate, this.state.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    const lastDay = moment.tz(menuDate, this.state.timezone).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    this.handleMonthChange({ start: firstDay, end: lastDay })
                }
            }
            this.setState({
                modifyGroup: false
            })
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Somthing went wrong, please try again", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
        this.handleRedirect();
    }

    async deleteMenu(id): Promise<void> {
        
        this.setState({
            ...this.state,
            isSaving: true,
            isFetching: true,
            warning: '',
        })
        try {
            let menus = this.state.menus;
            if (!menus || menus.length === 0) {
                if (this.state.selectedMenuItem) {
                    menus = [this.state.selectedMenuItem];
                }
            }
            let menu = menus.find((menu: MenuType) => menu._id === id);
            if (menu) {
                if (menu.svcMenuInstance) {
                    const _id = menu._id;
                    if (_id) {
                        const response = await deleteServiceInstance({ _id, deleteGroup: false });
                        if (!response) {
                            throw new Error('Instance could not be deleted');
                        }
                    } else {
                        throw new Error('Instance Id not found');
                    }
                }else{
                    await deleteMenu(id)
                }
                //remove the item from the array
                menus = menus.filter((menu: MenuType) => menu._id !== id)
                this.setState({
                    ...this.state,
                    menus,
                })
            } else {
                throw new Error('Menu entry could not be found');
            }
        } catch (error) {
            console.error(error.message || "Could not delete menu")
            this.setState({
                ...this.state,
                error: error,
            })
        } finally {
            this.setState({
                ...this.state,
                isSaving: false,
                isFetching: false,
            })
        }
    }

    async setMenuDateFilter(time) {
        const date = moment(time).format('YYYY-MM-DD');
        const startDateTimeString = moment.tz(date, this.state.timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment.tz(date, this.state.timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, null, {
            startDateTimeString,
            endDateTimeString,
            menuCategory: this.state.activeFoodCategoryTab,
        })

        this.setState({
            menuFilter: date,
            menus: filteredMenus.items,
            menuLength: filteredMenus.totalItems,
            activePageNumber: 1,
            warning: '',
        })
    }

    async clearDateFilter() {
        const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, this.state.activeHistoryTab, {
            menuCategory: this.state.activeFoodCategoryTab,
        })
        this.setState({
            menuFilter: '',
            menus: filteredMenus.items,
            menuLength: filteredMenus.totalItems,
            activePageNumber: 1,
        })
    }

    async handleCategoriesTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        const foodCategories = ['breakfast', 'lunch', 'dinner', 'snack', 'alternative']

        const selectedDate = this.state.menuFilter
            ? this.state.menuFilter
            : ''

        if (
            data.activeIndex === 0 ||
            data.activeIndex === 1 ||
            data.activeIndex === 2 ||
            data.activeIndex === 3 ||
            data.activeIndex === 4
        ) { 
            const defaultDateString = moment.tz(this.state.timezone).format('YYYY-MM-DD');
            const defaultDate = this.state.activeHistoryTab === 'active' ? new Date(defaultDateString) : new Date(defaultDateString).setDate(new Date().getDate() - 1);
            const startDateTimeString = moment(selectedDate || defaultDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            const endDateTimeString = moment(selectedDate || defaultDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

            const menus = await this.getMenus(
                1,
                this.paginationPerPageNumber,
                selectedDate ? '' : this.state.activeHistoryTab,
                {
                    startDateTimeString,
                    endDateTimeString,
                    menuCategory: foodCategories[data.activeIndex],
                },
            )

            const filteredMenus = this.filterMenuItems(menus.items)

            this.setState({
                activeFoodCategoryTab: foodCategories[data.activeIndex],
                activeHistoryTab: this.state.activeHistoryTab,
                activeHistoryTabIndex: this.state.activeHistoryTabIndex,
                menuFilter: this.state.menuFilter,
                menus: filteredMenus,
                menuLength: menus.totalItems,
                activePageNumber: 1,
                warning: '',
            })
        }
        this.props.history.push('/admin/menu?tab=list');
    }

    async handleHistoryTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        const selectedDate = this.state.menuFilter
        if (data.activeIndex === 0 || data.activeIndex === 1) {
            const menus = await this.getMenus(
                1,
                this.paginationPerPageNumber,
                data.activeIndex === 0 ? 'active' : 'history',
                {
                    menuCategory: this.state.activeFoodCategoryTab,
                },
            )
            const filteredMenus = this.filterMenuItems(menus.items)

            this.setState({
                activeHistoryTab: data.activeIndex === 0 ? 'active' : 'history',
                menuFilter: '',
                menus: filteredMenus,
                menuLength: filteredMenus.length,
                activePageNumber: 1,
                activeHistoryTabIndex: data.activeIndex,
            })
            this.props.history.push('/admin/menu?tab=list');
        }
    }
    async handleMainTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        const selectedDate = this.state.selectedDateInCalendar
        const startDateTimeString = moment(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment(selectedDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        if (data.activeIndex === 2) {
            const menus = await this.getMenus(1, this.paginationPerPageNumber, selectedDate ? '' : 'active', {
                startDateTimeString,
                endDateTimeString,
                menuCategory: this.state.activeFoodCategoryTab,
            })

            const filteredMenus = this.filterMenuItems(menus.items)

            this.setState({
                ...this.state,
                calendarTabIndex: data.activeIndex,
                activeHistoryTab: selectedDate && new Date(selectedDate).getTime() < Date.now() ? 'history' : 'active',
                activeHistoryTabIndex: selectedDate && new Date(selectedDate).getTime() < Date.now() ? 1 : 0,
                menus: filteredMenus,
                menuLength: menus.totalItems,
                activeFoodCategoryTab: 'breakfast',
                activeFoodCategoryTabIndex: 0,
                error: '',
            });
            this.props.history.push('/admin/menu?tab=list');
        }

        if (data.activeIndex === 1) {
            this.setState({
                ...this.state,
                calendarTabIndex: data.activeIndex,
                activeFoodCategoryTab: 'breakfast',
                activeFoodCategoryTabIndex: 0,
            });
            this.props.history.push('/admin/menu?tab=calendar');
        }
        if (data.activeIndex === 0) {
            this.setState({
                ...this.state,
                calendarTabIndex: 0,
            });
            this.props.history.push('/admin/menu?tab=daily');
        }
    }

    async changePage(e, data: PaginationProps) {
        const response = await this.getMenus(
            data.activePage,
            this.paginationPerPageNumber,
            this.state.activeHistoryTab,
            {
                menuCategory: this.state.activeFoodCategoryTab,
            },
        )

        this.setState({
            activePageNumber: data.activePage,
            menus: response.items,
            menuLength: response.totalItems,
        })
    }

    getPaginatedMenu(): MenuType[] {
        return this.state.menus
    }

    async reloadAllMenus(tab = 0) {
        if(this.state.calendarTabIndex === 0) {
           this.setState({reloadDailySvcMenu: true});
        } else {
            const firstDay = this.state.selectedStartDate ? this.state.selectedStartDate : moment.tz(new Date(), this.state.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
            const lastDay = this.state.selectedEndDate ? this.state.selectedEndDate : moment.tz(new Date(), this.state.timezone).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
            this.handleMonthChange({ start: firstDay, end: lastDay });
        }
    }  

    closeSvcInstanceModal = () => {
        this.setState({
            svcInstanceData: {
                name: '',
                longDescription: '',
                shortDescription: '',
                Asset: '',
                calendarType: [],
                menuCategory:''
            },
            showSvcInstanceModal: false
        });
    }

    async handleMonthChange(payload) {
        const firstDay = moment(new Date(payload.start)).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
        const lastDay = moment(new Date(payload.start)).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
        this.setState({
            selectedStartDate: firstDay,
            selectedEndDate: lastDay,
        });
        if (this.props.match.params['id']) return; // avoid fetch calendar data when in detailed view
        const menus = await this.getMenusCurrentMonth(
            1,
            200,
            'history',
            firstDay,
            lastDay,
            {},
        )

        // const filteredMenus = this.filterMenuItems(menus.items)

        this.setState({
            ...this.state,
            monthlyMenus: menus.items,
            fetchedMenus: menus.items,
            isSaving: false,
            isFetching: false,
            openCreateForm: false,
            selectedEventId: '',
            selectedMenuItem: undefined,
            error: '',
        })
    }

    async handleDateClick(payload) {
        const date = new Intl.DateTimeFormat('sv').format(payload.date); // get localdate in yyyy-mm-dd
        const selectedDateObj = moment(date);
        if (this.isDiningEnabled) {
            this.setState({
                showSvcInstanceModal: !this.state.showSvcInstanceModal,
                svcInstanceData: {
                    calendarType: this.state.selectedCalendarType,
                }
            });
        } else {
            this.setState({
                ...this.state,
                selectedDateInCalendar: selectedDateObj,
                menuFilter: selectedDateObj,
                openCreateForm: true,
                selectedEventId: '',
                selectedMenuItem: undefined,
                warning: '',
                error: '',
                initalCalendarDate: selectedDateObj,
                calendarDateOfDailytab: selectedDateObj,
            });
        }
    }

    setShowRecurringEditConfirmModal(selectedEventId){
        this.setState({ showRecurringEditConfirmModal: true, selectedEventId });
    }

    handleEventClick(payload) {
        this.handlecalendarDateOfDailytab(payload.event._instance.range.start || new Date());
        const id = payload.event._def.publicId;
        const selectedMenuItem = this.state.monthlyMenus.find(
            (event) => event._id === id,
        );
        if (selectedMenuItem && selectedMenuItem.svcMenuInstance) {
            if (!this.isDiningEnabled) {
                sendToast("error", "Dining is not enabled for the facility, please turn it on.")
                return;
             } 
            const selectedTab = this.props.history.location.search;
            const selectedDate = this.state.selectedStartDate && moment(this.state.selectedStartDate).format('YYYY-MM-DD')
            this.props.history.push(`/admin/menu/serviceinstance/${selectedMenuItem._id}`, { selectedTab, selectedDate });
        } else {
            if (selectedMenuItem && selectedMenuItem.recurrence) {
                const date = new Intl.DateTimeFormat('sv').format(selectedMenuItem.timestamp); // get localdate in yyyy-mm-dd
                const selectedDateObj = moment.tz(date, this.state.timezone).startOf('day');
                this.setState({
                    ...this.state,
                    selectedDateInCalendar: selectedDateObj,
                    menuFilter: selectedDateObj,
                    showRecurringEditConfirmModal: true,
                    selectedEventId: payload.event._def.publicId,
                    selectedMenuItem: selectedMenuItem,
                    error: '',
                });
            } else {
                this.handleRedirect(id);
            }
        }
    }

    handleRedirect(id?: string, calendarDate?: Date) {
        const selectedDate = calendarDate && moment(calendarDate).format('YYYY-MM-DD') || this.state.calendarDateOfDailytab && moment(this.state.calendarDateOfDailytab).format('YYYY-MM-DD');
        if (id) {
            const selectedTab = this.props.history.location.search;
            this.props.history.push(`/admin/menu/${id}/${this.state.modifyGroup ? true : false}`, { selectedTab, selectedDate });
        } else {
            const previousUrl = this.props.history.location.state ? this.props.history.location.state : '/';
            const previousDate = previousUrl.selectedDate || this.state.initalCalendarDate && moment(this.state.initalCalendarDate).format('YYYY-MM-DD');
            const queryParams = new URLSearchParams(previousUrl.selectedTab);
            const previousTab = queryParams.get('tab') || "daily";
            this.props.history.push(`/admin/menu?tab=${previousTab}&date=${previousDate}`);
        }
    }

    handleRedirectedEvent (selectedMenuItem: MenuType) {
        const date = new Intl.DateTimeFormat('sv').format(selectedMenuItem.timestamp); // get localdate in yyyy-mm-dd
        const selectedDateObj = moment.tz(date, this.state.timezone).startOf('day');
        const modifyGroup : boolean = JSON.parse(this.props.match.params['recurringActivities'] || "false");
        this.setState({
            modifyGroup,
            showDetailModal: true,
            selectedDateInCalendar: selectedDateObj,
            menuFilter: selectedDateObj,
            selectedEventId: String(selectedMenuItem._id),
            selectedMenuItem: selectedMenuItem,
            error: '',
        }, () => this.handleEventMenuEdit(null) );
    }

    handleEventMenuEdit = async (value: string[] | null = null) => {
        if (this.state.selectedMenuItem) {
            this.setState({
                isFetching: true
            });
            await this.setKanbanDataAndSelectedAttendees(value);
            this.setState({
                isFetching: false,
                componentMounting: false,
                openCreateForm: true,
            });
        }
    }

    async fetchMenuAttendees(menu): Promise<any> {
        const attendees = await listMenuAttendee(menu._id);
        const facilityRegistrants = await fetchAllActiveFacilityRegistrants(menu.Facility, true /* excludeAlisImage */, true, /* includeRoomInfo */ );
        const residentRooms = await fetchResidentRooms();
        return [attendees, facilityRegistrants, residentRooms];
    }

    async setKanbanDataAndSelectedAttendees(value: null | string[]){
        this.setState({
            isFetching: true
        });
        const [selectedMenuAttendees, allFacilityRegistrants, residentRooms] =  await this.fetchMenuAttendees(this.state.selectedMenuItem);

        const facilityRegistrants = allFacilityRegistrants.filter((registrant) => {
            if (value) {
                if (value && Array.isArray(value) && value.length > 0) {
                    return registrant.Unit ? (value.includes(registrant.Unit.RoomCategory) || value.includes(this.getLocationId('Other', this.state.locationOptions))) : true;
                }
            } else {
                if (this.state.selectedMenuItem && this.state.selectedMenuItem.calendarType && this.state.selectedMenuItem.calendarType.length > 0) {
                    return registrant.Unit ? (this.state.selectedMenuItem.calendarType.includes(registrant.Unit.RoomCategory)) : true;
                } else {
                    return true;
                }
            }
        });

        let kanbanDataTemp: kanbanDataType = JSON.parse(JSON.stringify(kanbanInitData))
        let menuAttendeesNames: string[] = []

        const intendedRegistrantIds: String[] = []

        selectedMenuAttendees.Result.forEach((attendeeObj) => {
            const room = residentRooms.find(roomObj => roomObj.ResidentId === attendeeObj.registrantId);
            attendeeObj.key = attendeeObj._id
            attendeeObj.id = attendeeObj._id
            attendeeObj.title = attendeeObj.registrant.FirstName + ' ' + attendeeObj.registrant.LastName
            attendeeObj.roomName = room ? room.RoomName : undefined;
            if (attendeeObj.status === 'attended') {
                kanbanDataTemp.columns[1].cards.push(attendeeObj)
            }
            if (attendeeObj.status === 'delivery') {
                delete attendeeObj.declineReason;
                kanbanDataTemp.columns[2].cards.push(attendeeObj)
                menuAttendeesNames.push(attendeeObj.title)
            }
            if (attendeeObj.status === 'removed') {
                delete attendeeObj.deliveryReason;
                attendeeObj.declineReason = attendeeObj.declineReason || 'Refused';
                kanbanDataTemp.columns[3].cards.push(attendeeObj)
            }
            intendedRegistrantIds.push(attendeeObj.registrantId)
        })

        facilityRegistrants.forEach((registrant) => {
            let attendeeObj = {
                key: 'temp' + registrant._id,
                id: 'temp' + registrant._id,
                registrantId: registrant._id,
                title: registrant.FirstName + ' ' + registrant.LastName,
                registrant: {
                    FirstName: registrant.FirstName,
                    LastName: registrant.LastName
                },
                roomName: registrant.Unit ? registrant.Unit.Name : undefined,
            }
            if (!intendedRegistrantIds.includes(registrant._id)) {
                kanbanDataTemp.columns[0].cards.push(attendeeObj)
            }
        })

        this.setState({
            kanbanBoard: kanbanDataTemp,
            selectedAttendees: selectedMenuAttendees.Result,
            isFetching: false,
        })
    }

    addNewAttendee = (name: string) => {
        let announcees: string[] = []
        if (this.state.selectedAttendees) {
            announcees = [...this.state.selectedAttendees]
        } else {
            announcees = []
        }
        if (!announcees.includes(name)) {
            announcees.push(name)
        }
        this.setState({ selectedAttendees: announcees })
    }

    removeAttendee = (name: string) => {
        if (this.state.selectedAttendees) {
            let announcees = [...this.state.selectedAttendees]
            const indexToRemove = announcees.indexOf(name)
            if (indexToRemove >= 0) {
                announcees.splice(indexToRemove, 1)
                this.setState({ selectedAttendees: announcees }) 
            }
        }
    }

    handleFetchLoader = (value: boolean) => {
        this.setState({
            isFetching: value
        })
    }

    async populateStateWithMenus(readMenuIntent): Promise<void> {
        try {
            let activeFoodCategoryTab = this.state.activeFoodCategoryTab
            if (readMenuIntent === 'mount') activeFoodCategoryTab = 'breakfast'
            if (
                (readMenuIntent === 'update' || readMenuIntent === 'delete') &&
                this.state.activeHistoryTab === 'history'
            ) {
                const menus = await this.getMenus(1, this.paginationPerPageNumber, 'history', {
                    menuCategory: this.state.activeFoodCategoryTab,
                })

                const filteredMenus = this.filterMenuItems(menus.items)

                this.setState({
                    ...this.state,
                    isFetching: false,
                    isSaving: false,
                    openCreateForm: false,
                    activeFoodCategoryTab: activeFoodCategoryTab,
                    menus: filteredMenus,
                    menuLength: menus.totalItems,
                    activePageNumber: 1,
                    activeHistoryTabIndex: 1,
                    activeFoodCategoryTabIndex: 0,
                    audioBlob: undefined,
                    modifyGroup: false
                })
            } else {
                const menus = await this.getMenus(1, this.paginationPerPageNumber, 'active', {
                    menuCategory: this.state.activeFoodCategoryTab,
                })

                const filteredMenus = this.filterMenuItems(menus.items)

                this.setState({
                    ...this.state,
                    isFetching: false,
                    isSaving: false,
                    openCreateForm: false,
                    activeFoodCategoryTab: activeFoodCategoryTab,
                    menus: filteredMenus,
                    menuLength: menus.totalItems,
                    activePageNumber: 1,
                    activeHistoryTabIndex: 0,
                    activeFoodCategoryTabIndex: 0,
                    audioBlob: undefined,
                    modifyGroup: false
                })
            }
        } catch (error) {
            this.setState({
                ...this.state,
                isFetching: false,
                isSaving: false,
                audioBlob: undefined,
                error: error.message,
                modifyGroup: false
            })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedCalendarType !== prevState.selectedCalendarType) {
            if (this.state.calendarTabIndex === 1) {
                const firstDay = this.state.selectedStartDate ? this.state.selectedStartDate : moment(new Date()).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                const lastDay = this.state.selectedEndDate ? this.state.selectedEndDate : moment(new Date()).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                this.handleMonthChange({ start: firstDay, end: lastDay });
            } else if(this.state.calendarTabIndex === 2){
               await this.populateStateWithMenus('update')
            }
        }
        const queryParams = new URLSearchParams(window.location.search); // chooseing the tab
        const currentTab = queryParams.get('tab'); 
        let menuTabIndex =  this.isServiceEnabled ? 0 : 1; //  by default setting the menu tab, if service is enabled view the Daily view or else the calendar view 
        if (currentTab === 'calendar') {
            menuTabIndex = 1;
        } else if (currentTab === 'list') {
            menuTabIndex = 2;
        }
        if (menuTabIndex !== prevState.calendarTabIndex) {
            this.setState({
                ...this.state,
                calendarTabIndex: menuTabIndex
            });
        }
    }


    async componentDidMount(): Promise<void> {
        this.setState({ componentMounting: true });
        try {
            if (this.props.match.params['id']) {
                const eventId = this.props.match.params['id'];
                const selectedMenuItem = await this.fetchOneMenuItem(eventId);
                this.handleRedirectedEvent(selectedMenuItem);
                this.setState({ componentMounting: false });
                return;
            }

            const queryParams = new URLSearchParams(window.location.search); // chooseing the tab
            const currentTab = queryParams.get('tab');
            const queryString = this.props.history.location.search || ""; // Get the query string, e.g., "?tab=daily&date=2024-06-04"
            const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
            const dateString = urlParams.get('date'); // Get the value of the 'date' parameter
            const initialDate = dateString ? moment(dateString).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
            let menuTabIndex = this.isServiceEnabled ? 0 : 1;  // by default setting the menu tab, if service is enabled view the Daily view or else the calendar view 
            if (currentTab === 'calendar') {
                menuTabIndex = 1;
            } else if (currentTab === 'list') {
                menuTabIndex = 2;
            }
            this.setState({
                ...this.state,
                calendarTabIndex: menuTabIndex,
                initalCalendarDate: initialDate,
                selectedDateInCalendar:initialDate,
                selectedStartDate: moment(initialDate).startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                selectedEndDate: moment(initialDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
            });
            const facilityId = this.props.profile && this.props.profile.Facility;
            const [facilityData, userData, roomTypes]: any[] = await Promise.all([
                facilityId ? fetchOneFacility(facilityId) : {},
                this.props.profile ? fetchOneUser(this.props.profile._id) : {},
                listRoomTypes()
              ]);
            const FacilityTimeZone = facilityData.FacilityTimeZone || 'America/New_York';
            this.setState({
                timezone: FacilityTimeZone,
            });
            if (roomTypes && roomTypes.Result && roomTypes.Result.length > 0) {
                const locationOptions = roomTypes.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                const defaultLocationId = this.getLocationId('Other', locationOptions);
                const defaultCalendarType = facilityData.defaultCalendarType ? [facilityData.defaultCalendarType] : [defaultLocationId];
                this.setState({
                    locationOptions,
                    selectedCalendarType: (userData && userData.selectedCalendarType && userData.selectedCalendarType.length && userData.selectedCalendarType) || defaultCalendarType
                });
            }
            await this.populateStateWithMenus('mount');
        } catch (error) {
            console.log(error)
            toast.error("There was an issue while fetching this menu item", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
            this.handleRedirect();
        }
        this.setState({ componentMounting: false });
    }

    componentWillUnmount() {
        // Clean up the event listener when the component is unmounted
        window.onpopstate = null;
    }

    fetchMenuForMonthRange(menu) {
        const menuDate = moment.tz(menu.timestamp || new Date(), this.state.timezone)
        var firstDay = menuDate.startOf('month').unix() * 1000;
        var lastDay = menuDate.endOf('month').unix() * 1000;
        this.handleMonthChange({ start: new Date(firstDay), end: new Date(lastDay) });
    }

    async handleDeleteRecurringMenu(){
        if (this.state.selectedMenuItem && this.state.selectedMenuItem.recurrence) {
            const srcId = this.state.selectedMenuItem && this.state.selectedMenuItem.srcId;
            try {
                await deleteRecurringMenuGroup({
                    _id: this.state.selectedMenuItem._id,
                    srcId: srcId,
                    deleteGroup: this.state.deleteGroup
                });
                this.setState({
                    showRecurringDeleteConfirmModal: false,
                    showDetailModal: false,
                    buttonLoader: false,
                    deleteGroup: false,
                    allButtonLoader: false
                }, async () => {
                    this.fetchMenuForMonthRange(this.state.selectedMenuItem);
                });
            } catch (error) {
                this.setState({
                    error: error.message,
                    showRecurringDeleteConfirmModal: false,
                    showDetailModal: false,
                    buttonLoader: false,
                    allButtonLoader: false
                }, async () => {
                    this.fetchMenuForMonthRange(this.state.selectedMenuItem);
                })
            }
        }
    }

    paginationPerPageNumber = 5

    panes2 = [
        {
            menuItem: 'Active',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.menuFilter}
                    setListItemDateFilter={this.setMenuDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.menus}
                    getPaginatedListItem={this.getPaginatedMenu.bind(this)}
                    deleteListItem={this.deleteMenu.bind(this)}
                    editListItem={this.editMenu.bind(this)}
                    copyListItem={this.copyMenu.bind(this)}
                    cancelEditListItem={this.cancelEditMenu.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="Menu"
                    aciveFoodTab={this.state.activeFoodCategoryTab}
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    activeHistoryTab='active'
                    facilityTimezone={this.state.timezone}
                    initalCalendarDate={this.state.initalCalendarDate}
                    handleChangeActiveTab={this.handleChangeActiveTab.bind(this)}
                />
            ),
        },
        {
            menuItem: 'History',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.menuFilter}
                    setListItemDateFilter={this.setMenuDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.menus}
                    getPaginatedListItem={this.getPaginatedMenu.bind(this)}
                    deleteListItem={this.deleteMenu.bind(this)}
                    editListItem={this.editMenu.bind(this)}
                    copyListItem={this.copyMenu.bind(this)}
                    cancelEditListItem={this.cancelEditMenu.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="Menu"
                    aciveFoodTab={this.state.activeFoodCategoryTab}
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    activeHistoryTab='history'
                    facilityTimezone={this.state.timezone}
                    initalCalendarDate={this.state.initalCalendarDate}
                    handleChangeActiveTab={this.handleChangeActiveTab.bind(this)}
                />
            ),
        },
    ]

    panes = [
        {
            menuItem: 'Breakfast',
            render: () => {
                if (this.state.error) return this.state.error
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Lunch',
            render: () => {
                if (this.state.error) return this.state.error
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Dinner',
            render: () => {
                if (this.state.error) return this.state.error
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Snack',
            render: () => {
                if (this.state.error) {
                    return this.state.error
                }
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Alternative',
            render: () => {
                if (this.state.error) {
                    return this.state.error
                }
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
    ]

    panes0 = [
        {
            ...(this.isServiceEnabled ? {
                menuItem: 'Daily',
                render: () => (
                    this.DailyView()
                ),
            } : {})
        }, 
        {
            menuItem: 'Calendar',
            render: () => (
                this.CalendarView()
            ),
        },
        {
            menuItem: 'List',
            render: () => {
                return (
                    <>
                        <Tab
                            className="menu-tab"
                            menu={{ secondary: true }}
                            panes={this.panes}
                            onTabChange={this.handleCategoriesTabChange.bind(this)}
                        />
                        {this.state.menuLength > this.paginationPerPageNumber ? (
                            <div className="pagination-holder">
                                <Pagination
                                    activePage={this.state.activePageNumber}
                                    totalPages={Math.ceil(this.state.menuLength / this.paginationPerPageNumber)}
                                    onPageChange={this.changePage.bind(this)}
                                    siblingRange={1}
                                />
                            </div>
                        ) : null}
                    </>
                )
            },
        },
    ]

    panes1 = [
        {
            menuItem: 'Daily',
            render: () => (
                this.DailyView()
            ),
        },
        {
            menuItem: 'Calendar',
            render: () => (
                this.CalendarView()
            ),
        },
    ]

    CalendarView = () => {
        const getColor = (category) => {
            switch (category) {
                case 'breakfast':
                    return '#2987CD'
                case 'lunch':
                    return '#69B546'
                case 'dinner':
                    return '#EAC31F'
                case 'snack':
                    return '#E98530'
                case 'alternative':
                    return '#E98530'
                default:
                    return '#2987CD'
            }
        }
        let exportCalendarData = async () => {
            if (this.state.exportBtnSpinner) {
                toast.warn("Task in progress, please wait until it's completed.", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
                return
            }
            this.setState({ exportBtnSpinner: true })
            const exportData = this.state.monthlyMenus.map((instance) => {
                return {
                    Name: instance.name && instance.name.replace(/\n/g, '') || '-',
                    Description: instance.shortDescription || '-',
                    Category: instance.menuCategory || instance.category || '-',
                    Tags: instance.defaults && instance.defaults.tags || '-',
                    StartDate: instance.startDate ? moment(instance.startDate).format('MM-DD-YYYY') : '-',
                    StartTime: instance.startDate ? moment(instance.startDate).format('hh:mm A') : '-',
                    EndDate: instance.endDate ? moment(instance.endDate).format('MM-DD-YYYY') : '-',
                    EndTime: instance.endDate ? moment(instance.endDate).format('hh:mm A') : '-',
                };
            });
            await jsonTocsvDownloaderV2(exportData, "Menu")
            this.setState({ exportBtnSpinner: false })
        }
        return <div className="menu-calendar-view">
            <FullCalendar
                customButtons={{
                    addSvcMenu: {
                        text: `+`,
                        click: () => {
                            this.handleSvcInstanceModal();
                        },
                        hint: 'Create a service instance'
                    },
                    addMenu: {
                        text: `+`,
                        hint: `Create a simple menu`,
                        click: () => {
                            this.setState({
                                openCreateForm: true,
                                selectedEventId: '',
                                selectedMenuItem: undefined,
                                warning: '',
                                error: '',
                            });
                        },
                    },
                    export: {
                        text: this.state.exportBtnSpinner ? 'Loading...' : 'Export data',
                        click: function () {
                            exportCalendarData();
                        }
                    },
                    print: {
                        text: this.state.printBtnSpinner ? 'Loading...' : 'Print calendar',
                        click: async () => {
                            window.open(prntyrLink, '_blank');
                            // Commented out the print functionality as it is now redirecting to https://www.prntyr.com?speak2-user=true
                            // if (this.state.printBtnSpinner) {
                            //     toast.warn("Task in progress, please wait until it's completed.", {
                            //         position: 'bottom-center',
                            //         autoClose: 5000,
                            //         hideProgressBar: false,
                            //         closeOnClick: true,
                            //         pauseOnHover: true,
                            //     })
                            //     return
                            // }
                            // this.setState({ printBtnSpinner: true });
                            // const menuWithSlotPrefix = [...this.state.monthlyMenus.map((event) => {
                            //     return {
                            //         ...event,
                            //         text: event.name
                            //             ? `${capitalizeFirstLetter(event.menuCategory || event.category)}: ${event.name.replace(
                            //                 /(<([^>]+)>)/gi,
                            //                 ''
                            //             )}`
                            //             : '',
                            //     };
                            // })];
                            // await jsonTocsvDownloader(menuWithSlotPrefix, "Menu", true);
                            // this.setState({ printBtnSpinner: false });
                        }
                    }
                }}
                headerToolbar={{
                    right: this.isServiceEnabled ? 'addSvcMenu print export today prev,next' : 'addMenu print export today prev,next'
                }}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                editable={false}
                events={this.state.monthlyMenus.map((event) => {
                    const timeSlots = {
                        breakfast: 8,
                        lunch: 12,
                        dinner: 20,
                        snack: 18,
                        alternative: 22,
                    };
                    const startTime = event.externalSource === 'groveApi'
                        ? moment(event.startDate).startOf('day').unix() * 1000 + timeSlots[event.menuCategory ? event.menuCategory : event.category] * 60
                        : moment(event.startDate).startOf('day').unix() * 1000 + timeSlots[event.menuCategory ? event.menuCategory : event.category] * 60;

                    const title = event.name
                        ? `${capitalizeFirstLetter(event.menuCategory ? event.menuCategory : event.category)}: ${event.name.replace(/(<([^>]+)>)/gi, '').replace(/,([^ ])/g, ', $1')}`
                        : '';

                    return {
                        title,
                        start: startTime,
                        id: event._id,
                        backgroundColor: getColor(event.menuCategory ? event.menuCategory : event.category),
                        textColor: "black",
                        classNames: [`category-${event.menuCategory ? event.menuCategory : event.category}`, event.audioId ? 'audio' : '']
                    };
                })}
                initialDate={moment(this.state.initalCalendarDate).format('YYYY-MM-DD')}
                datesSet={this.handleMonthChange.bind(this)}
                dateClick={this.handleDateClick.bind(this)}
                eventClick={this.handleEventClick.bind(this)}
                eventContent={(info) => (
                    <CalendarEventContent info={info} />
                    // <div className={`fc-event-title ${info.event.classNames.join(' ')}`} style={{ whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                    //     {info.event.title}
                    // </div>
                )}
                displayEventTime={false}
                showNonCurrentDates={false}
            />
        </div>;
    }

    DailyView = () => {
        return (
            <div>
                <ServiceInstances
                    selectedCalendarType={this.state.selectedCalendarType}
                    reloadSvcInstanceData={this.state.reloadDailySvcMenu}
                    source={"Menu"}
                    handleRedirect={this.handleRedirect.bind(this)}
                    showSvcInstanceModal={this.handleSvcInstanceModal}
                    showCreateForm={this.opemMenuModal.bind(this)}
                    reloadServiceAccordianFlag={this.state.reloadServiceAccordianFlag}
                    setShowRecurringEditConfirmModal={this.setShowRecurringEditConfirmModal.bind(this)}
                    handlecalendarDateOfDailytab={this.handlecalendarDateOfDailytab.bind(this)}
                />
            </div>
        );
    }

    fetchOneMenuItem = async (eventId) => {
        const menuFetchResp = await fetchMenuItems(1, 10, null, {_id: eventId});
        if (!menuFetchResp || !menuFetchResp.Result || !menuFetchResp.Result.length) {
            this.setState({
                componentMounting: false,
            });
            throw new Error("Menu not found");
        }
        return menuFetchResp.Result[0];
    }

    delay = ms => new Promise(res => setTimeout(res, ms))

    async copyClickHandler(copyImageUrl: string | undefined) {
        if(this.state.selectedEventId){
            try {
                this.setState({
                    isFetching: true
                })
                await this.delay(1000);
                const menu = this.state.selectedMenuItem ? JSON.parse(JSON.stringify(this.state.selectedMenuItem)) : await this.fetchOneMenuItem(this.state.selectedEventId);
                document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
                if (menu) {
                    this.setState({
                        openCreateForm: true,
                        copyMenu: menu,
                        selectedMenuItem: undefined, 
                        warning: "", 
                        selectedEventId: "", 
                        copyImageUrl,
                        isFetching: false
                    })
                }
            } catch (error) {
                toast.error("There was an issue while copying this menu item", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
                this.setState({
                    isFetching: false
                })
            }
        }
    }

    MenuMainComponent = () => {
        return (
            <>
                <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Form.Field>
                        <Dropdown
                            selection={true}
                            loading={this.state.loadDropdown}
                            value={this.state.selectedCalendarType} 
                            options={sortByKey(this.state.locationOptions)}
                            placeholder="Select Calendar"
                            style={{ width: "100%" }}
                            onChange={(e, { value }) => {
                                this.handleChange(value as []);
                            }}
                            multiple
                        /> 
                    </Form.Field>
                </div>
                {this.state.warning ? <h3 className="warning-existing-menu">{this.state.warning}</h3> : null}
                {this.isDiningEnabled ? 
                <Tab
                    className="menu-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes1}
                    onTabChange={this.handleMainTabChange.bind(this)}
                    activeIndex={this.state.calendarTabIndex}
                />
                : <Tab
                    className="menu-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes0}
                    onTabChange={this.handleMainTabChange.bind(this)}
                    activeIndex={this.state.calendarTabIndex}
                />}
            </>
        );

    }

    MenuDetailComponent = () => {
        return (
            this.state.selectedEventId ? (
                <MenuFormEditor
                    isFetching={this.state.isFetching}
                    isSaving={this.state.isSaving}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    cancelEditMenu={this.cancelEditMenu.bind(this)}
                    copyMenu={this.state.copyMenu}
                    activeFoodTab={this.state.activeFoodCategoryTab}
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    date={this.state.selectedDateInCalendar}
                    edit={this.state.selectedEventId ? true : false}
                    _id={this.state.selectedEventId}
                    editableMenu={this.state.selectedMenuItem}
                    copyClickHandler={this.copyClickHandler.bind(this)}
                    kanbanBoard={this.state.kanbanBoard}
                    selectedAttendees={this.state.selectedAttendees}
                    addNewAttendee={this.addNewAttendee}
                    removeAttendee={this.removeAttendee}
                    setIsFetching={this.handleFetchLoader}
                    closeDetailedView={this.openCloseCreateMenuForm}
                    timezone={this.state.timezone}
                    modifyGroup={this.state.modifyGroup}
                    selectedCalendarType={this.state.selectedCalendarType}
                    handleLocationUpdate={this.handleLocationUpdate}
                    setKanbanDataAndSelectedAttendees={this.setKanbanDataAndSelectedAttendees.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                />
            ) : (
                <MenuFormEditor
                    isFetching={this.state.isFetching}
                    isSaving={this.state.isSaving}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    cancelEditMenu={this.cancelEditMenu.bind(this)}
                    copyMenu={this.state.copyMenu}
                    activeFoodTab={this.state.activeFoodCategoryTab}
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    date={this.state.selectedDateInCalendar}
                    closeDetailedView={this.openCloseCreateMenuForm}
                    timezone={this.state.timezone}
                    selectedCalendarType={this.state.selectedCalendarType}
                    handleLocationUpdate={this.handleLocationUpdate}
                    setKanbanDataAndSelectedAttendees={this.setKanbanDataAndSelectedAttendees.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                />
            )
        );
    }

    async handleChange(value: string[]) {
        try {
            this.setState({
                loadDropdown: true,
                selectedCalendarType: value || [],
            });
            
            const staffUser = this.props.profile as unknown as User;
            const updatedStaff = {
                ...staffUser,
                selectedCalendarType: value || [],
                updateSelectedCalendarType: true
            };

            await updateUser(updatedStaff, staffUser);

            this.setState({
                // menus: filteredMenus,
                // monthlyMenus: filteredMenus,
                selectedCalendarType: value,
            });
        } catch (err) {
            console.log('error in handlechange', err);
            toast.error(err.message);
        } finally {
            this.setState({ loadDropdown: false });
        }
    }

    async handleDelete(e){
        e.preventDefault();
        if (this.state.selectedMenuItem && this.state.selectedMenuItem.recurrence) {
            this.setState({
                showRecurringDeleteConfirmModal: true,
                showDetailModal: false,
            });
        } else {
            this.setState({ isFetching: true, }, async () => await this.deleteMenu(this.state.selectedEventId));
            this.setState({
                showDetailModal: false,
                isFetching: false,
            }, () => this.handleRedirect());
        }
    }

    render(): JSX.Element {
        return (
            <div className="DailyMenus">
                <Dimmer active={!this.state.openCreateForm && (this.state.isFetching || this.state.componentMounting)} inverted>
                    <Loader active={!this.state.openCreateForm && (this.state.isFetching || this.state.componentMounting)} />
                </Dimmer>
                <Modal open={this.state.showRecurringEditConfirmModal}>
                    <Modal.Header>Edit recurring event?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.buttonLoader}
                            basic
                            color="blue"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringEditConfirmModal: false,
                                    modifyGroup: false
                                }, () => {
                                    this.state.selectedEventId && this.handleRedirect(this.state.selectedEventId);
                                });
                            }}
                        >
                            This event
                        </Button>
                        <Button
                            loading={this.state.buttonLoader}
                            basic
                            color="red"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringEditConfirmModal: false,
                                    modifyGroup: true
                                }, () => { this.state.selectedEventId && this.handleRedirect(this.state.selectedEventId); });
                            }
                            }
                        >
                            All events
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringEditConfirmModal: false,
                                    showDetailModal: true,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.showRecurringDeleteConfirmModal}>
                    <Modal.Header>Delete recurring event?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.buttonLoader}
                            basic
                            color="blue"
                            onClick={async (e) => {
                                e.preventDefault();
                                this.setState({
                                    buttonLoader: true,
                                }, async () => {
                                    await this.deleteMenu(this.state.selectedEventId);
                                    this.handleRedirect();
                                });
                            }}
                        >
                            This event
                        </Button>
                        <Button
                            loading={this.state.allButtonLoader}
                            basic
                            color="red"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    allButtonLoader: true,
                                    deleteGroup: true
                                }, async () => {
                                    await this.handleDeleteRecurringMenu();
                                    this.handleRedirect();
                                });
                            }
                            }
                        >
                            All events
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringDeleteConfirmModal: false,
                                    showDetailModal: true,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    style={{ width: "450px" }}
                    dimmer={true}
                    open={this.state.showSvcInstanceModal}
                    onClose={() => this.closeSvcInstanceModal()}
                >
                    <Modal.Content>
                        <ServiceInstanceFormCreate instanceData={this.state.svcInstanceData} setInstanceData={this.setSvcInstanceData.bind(this)}
                            closeModal={this.closeSvcInstanceModal.bind(this)} reloadServices={this.reloadChildComponents.bind(this)} handleCalendarReload={this.reloadAllMenus.bind(this)} source={"Menu"} />
                    </Modal.Content>
                </Modal>
                {this.state.openCreateForm
                    ? this.MenuDetailComponent()
                    : this.MenuMainComponent() 
                }
            </div>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    };
} 

export default withRouter(connect(mapStateToProps)(Menu));
