import React from 'react';
import { useTable } from 'react-table';
import { Menu, Icon, Loader, Dimmer, Table } from 'semantic-ui-react';

const SortableTable = ({ columns, data, pageNumber, previousPage, nextPage, loading, setManualSortBy, columnSortData, height = ''}) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });
    const toggleSorting = (column) => {
        setManualSortBy(column.id);
    }
    return (
        <div className="messages-table" style={{ maxHeight: height ? height : '', overflow: 'auto' }}>
            <table {...getTableProps()} className="ui celled table">
                <Table.Header>
                    {headerGroups.map((headerGroup, index) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${index}`}>
                            {headerGroup.headers.map((column, hgIndex) => (
                                <Table.HeaderCell
                                    {...column.getHeaderProps()}
                                    key={`hgIndex-${hgIndex}`}
                                    style={{ width: `${100 / columns.length}%` }}
                                    onClick={() => { toggleSorting(column) }}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {
                                            columnSortData[column.id] && columnSortData[column.id].isSorted ? (
                                                columnSortData[column.id].isSortedDesc ? (
                                                    <Icon name="angle down" />
                                                ) : (
                                                    <Icon name="angle up" />
                                                )
                                            ) : (
                                                ''
                                            )}
                                    </span>
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td 
                                    {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={columns.length} style={{ textAlign: 'center' }}>
                            <Menu floated={false} pagination >
                                <Menu.Item
                                    as="a"
                                    icon
                                    disabled={previousPage.disablePrev}
                                    onClick={() => previousPage.previousPage()}
                                >
                                    <Icon name="chevron left" />
                                </Menu.Item>
                                <Menu.Item>{pageNumber}</Menu.Item>

                                <Menu.Item
                                    as="a"
                                    icon
                                    disabled={nextPage.disableNext}
                                    onClick={() => {
                                        nextPage.nextPage();
                                    }}
                                >
                                    <Icon name="chevron right" />
                                </Menu.Item>
                            </Menu>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default SortableTable;
