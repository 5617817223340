import React from 'react'

const ServiceInstanceCalendarEventContent = ({ info }) => {
    return (
        <div className='calendarEventSVC'>
            <div>{info && info.timeText}</div>
            <div>{info && info.event && info.event._def && info.event._def.title}</div>
        </div>
    );
}

export default ServiceInstanceCalendarEventContent