import React, { MouseEvent, useEffect, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm, Dimmer, Loader, Grid, Segment } from 'semantic-ui-react'
import * as Yup from 'yup'
import moment from 'moment-timezone'

import { GenericTextField, GenericDropdown, GenericCheckbox, GenericImageField } from '../../../../components/GenericFormFields'
import countries from './countries'

import './style.less'
import { Facility, FacilityConfigurationsTypes } from '../../../../types';
import CustomAppInfoForm from '../../../../components/CustomAppInfoForm';
import FacilityConfigurations from '../../../../components/FacilityConfigurations';
import { listRoomTypes } from '../../../../services/RoomTypes';
import ExternalIntegrationConfiguration from '../../../../components/ExternalIntegrationConfiguration';
import { fetchCorporates } from '../../../../services/Corporates';

const countryOptions = countries.map((c, i) => ({
    key: i,
    text: c,
    value: c,
}))

const timezoneOptions = moment.tz.names()
    .filter(e=>!e.startsWith('US/')) //remove deprecated US/ timezone strings 
    .map(c => ({ 
        key: c,
        text: c,
        value: c,
    })
)

export interface FacilityFormValues {
    Name: string
    Phone1: string
    Phone2: string
    Phone3: string
    Email: string
    URL: string
    // TODO: map
    // Lat: 21
    // Lon: 41
    Country: string
    State: string
    City: string
    Address: string
    ZIP: string
    Code: string
    Notes: string
    AlexaProfile: string
    isA4HProfile: boolean
    FacilityTimeZone?: string
    Icon: string
    a4hPropertyId?: string
    configurations?: FacilityConfigurationsTypes
    CorporateId?: string
} 

const FacilitySchema = Yup.object().shape({
    Name: Yup.string().required('Facility Name is required'),
    Phone1: Yup.string(),
    Phone2: Yup.string(),
    Phone3: Yup.string(),
    Email: Yup.string()
        .email('Invalid Email address')
        .required(),
    URL: Yup.string()
        .url('Invalid URL')
        .required(),
    Country: Yup.string(),
    State: Yup.string(),
    City: Yup.string(),
    Address: Yup.string(),
    ZIP: Yup.string(),
    Code: Yup.string(),
    Notes: Yup.string(),
    AlexaProfile: Yup.string(),
    isA4HProfile: Yup.boolean(),
    FacilityTimeZone: Yup.string().required('You must specify an timezone'),
    Icon: Yup.string(),
    CorporateId: Yup.string(),
})

interface Props {
    title: string
    facility: Facility | null
    error: string | null
    onSave: (data: FacilityFormValues, facilityConfiguraion?:FacilityConfigurationsTypes) => Promise<any>
    cancel: () => any
    onDelete?: (facilityId: string) => Promise<any>
    isSaving?: boolean
    facilityOptions?: any[]
    refresh?: () => void 
}

const FacilitiesForm: React.FC<Props> = (props: Props) => {
    const [facilityConfig, setFacilityConfig] = useState<FacilityConfigurationsTypes>(
        {
            notepad: false,
            orders: false,
            ai: false,
            signage: false,
            kiosk: false,
            services: false,
            assets: false,
            dining: false,
        });
    const [roomProfileOptions, setRoomProfileOptions] = useState<any>([]);
    const [corporateOptions, setCorporateOptions] = useState<any>([]);

    useEffect(() => {
        (async () => {
            const res = await listRoomTypes();
            if (res && res.Result && res.Result.length > 0) {
                const locationOptions = res.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                setRoomProfileOptions(locationOptions);
            }
            const corporates = await fetchCorporates();
            if (corporates && corporates.length > 0) {
                const corporateOptions = [
                    { key: '', text: 'Select a corporate', value: '' },
                    ...corporates.map((corporate) => ({
                        key: corporate._id,
                        text: corporate.Name,
                        value: corporate._id,
                    })),
                ];
                setCorporateOptions(corporateOptions);
            }

        })();
    }, [])

    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Name: (props.facility && props.facility.Name) || '',
        Phone1: (props.facility && props.facility.Phone1) || '',
        Phone2: (props.facility && props.facility.Phone2) || '',
        Phone3: (props.facility && props.facility.Phone3) || '',
        Email: (props.facility && props.facility.Email) || '',
        URL: (props.facility && props.facility.URL) || '',
        Country: (props.facility && props.facility.Country) || '',
        State: (props.facility && props.facility.State) || '',
        City: (props.facility && props.facility.City) || '',
        Address: (props.facility && props.facility.Address) || '',
        ZIP: (props.facility && props.facility.ZIP) || '',
        Code: (props.facility && props.facility.Code) || '',
        Notes: (props.facility && props.facility.Notes) || '',
        AlexaProfile: (props.facility && props.facility.AlexaProfile) || '',
        isA4HProfile: !props.facility ? true : !!props.facility.isA4HProfile,
        FacilityTimeZone: (props.facility && props.facility.FacilityTimeZone) || 'America/New_York',
        Icon: (props.facility && props.facility.Icon) || '',
        FriendlyName: (props.facility && props.facility.FriendlyName) || "",
        a4hPropertyId: (props.facility && props.facility.a4hPropertyId) || '',
        configurations: facilityConfig,
        RoomProfile: (props.facility && props.facility.RoomProfile) || '',
        CorporateId: (props.facility && props.facility.CorporateId) || '',
    })

    const imagePlaceholder = `${process.env.PUBLIC_URL}/JustThe2.svg`;

    const canDelete = () => {
        return props.onDelete && props.facility && props.facility._id
    }

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.facility && props.facility._id) {
            props.onDelete(props.facility._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }
    const getFacilityConfig = async (config: FacilityConfigurationsTypes) => {
        setFacilityConfig(config);
    }

    return (
        <div className="FacilitiesForm">
            <Dimmer active={!!props.facilityOptions && !props.facilityOptions.length} inverted>
                <Loader active={!!props.facilityOptions && !props.facilityOptions.length} />
            </Dimmer>
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FacilityFormValues, actions: FormikActions<FacilityFormValues>) => {
                    //code will remain unchanged once created
                    if (!values.Code) {
                        const code = values.Name.split(' ').join("_");
                        values.Code = code;
                    }

                    values.AlexaProfile = values.Name;

                    if (props.facility) {
                        if (values.Name === props.facility.Name) {
                            values.AlexaProfile = props.facility.AlexaProfile;
                        }
                    }
                    
                    //add a4h prop id
                    const a4hProp = props.facilityOptions && props.facilityOptions.filter((obj) => obj.text === values.Name);
                    const a4hPropertyId = a4hProp && a4hProp.length === 1 ? a4hProp[0].key : undefined;
                    values.a4hPropertyId = a4hPropertyId;
                    if (props.facility) {
                        delete values.configurations;
                    } else {
                        values.configurations = facilityConfig;
                    }
                    await props.onSave(values);
                    actions.setSubmitting(false)
                }}
                validationSchema={FacilitySchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField
                            name="Icon"
                            component={GenericImageField}
                            placeholder={imagePlaceholder}
                            folderName="facility-logos"
                        />
                        <Grid>
                            <Grid.Column width={props.facility ? 12 : 16}>
                                <FormikField
                                    required
                                    name="Name"
                                    component={GenericDropdown}
                                    options={props.facilityOptions}
                                    placeholder="Facility Name"
                                />
                                <FormikField
                                    name="FriendlyName"
                                    component={GenericTextField}
                                    placeholder="Friendly Name"
                                />
                                <FormikField
                                    name="CorporateId"
                                    component={GenericDropdown}
                                    placeholder="Select Corporate"
                                    options={corporateOptions}
                                    search
                                />
                                <FormikField name="Phone1" component={GenericTextField} placeholder="Phone 1" />
                                <FormikField name="Phone2" component={GenericTextField} placeholder="Phone 2" />
                                <FormikField name="Phone3" component={GenericTextField} placeholder="Phone 3" />
                                <FormikField
                                    required
                                    name="Email"
                                    component={GenericTextField}
                                    placeholder="Email"
                                    type="email"
                                />
                                <FormikField
                                    required
                                    name="URL"
                                    component={GenericTextField}
                                    placeholder="Facility URL"
                                />
                                <FormikField
                                    name="Country"
                                    component={GenericDropdown}
                                    placeholder="Country"
                                    options={countryOptions}
                                    search
                                />
                                <FormikField
                                    required
                                    name="FacilityTimeZone"
                                    component={GenericDropdown}
                                    placeholder="timezone"
                                    options={timezoneOptions}
                                    search
                                />
                                <FormikField name="State" component={GenericTextField} placeholder="State" />
                                <FormikField name="City" component={GenericTextField} placeholder="City" />
                                <FormikField name="Address" component={GenericTextField} placeholder="Address" />
                                <FormikField name="ZIP" component={GenericTextField} placeholder="ZIP" />
                                <FormikField name="Notes" component={GenericTextField} placeholder="Notes" />
                                <FormikField
                                    name="RoomProfile"
                                    component={GenericDropdown}
                                    placeholder="Room Profile"
                                    options={roomProfileOptions}
                                    search
                                />
                                <FormikField
                                    name="isA4HProfile"
                                    component={GenericCheckbox}
                                    placeholder="A4H Facility?"
                                />
                                <Grid centered>
                                    {!props.facility && (
                                        <Grid.Column width={10}>
                                            <FacilityConfigurations
                                                getValues={getFacilityConfig}
                                            />
                                        </Grid.Column>
                                    )}
                                </Grid>
                                {hasSaveError && (
                                    <Message error>
                                        <Message.Header>Error saving Facility</Message.Header>
                                        <p>{props.error}</p>
                                    </Message>
                                )}
                                <div style={{ marginTop : "30px"}}>
                                    <Button type="submit" disabled={isSubmitting} primary>
                                        Submit
                                    </Button>
                                    <Button basic onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                    {canDelete() && (
                                        <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                            Remove Facility
                                        </Button>
                                    )}
                                </div>
                            </Grid.Column>
                            {props.facility ? (
                                <Grid.Column width={4}>
                                    <FacilityConfigurations facilityId={(props.facility && props.facility._id) || ''}
                                        featureConfigs={
                                            (props.facility &&
                                                props.facility.featureConfigs &&
                                                props.facility.featureConfigs) ||
                                            undefined
                                        }
                                        refresh={props.refresh && props.refresh}
                                    />
                                    <ExternalIntegrationConfiguration facilityId={(props.facility && props.facility._id) || ''} />
                                    <Segment>
                                        <CustomAppInfoForm
                                            facilityId={(props.facility && props.facility._id) || ''}
                                            customAppInfo={
                                                (props.facility &&
                                                    props.facility.customAppInfo &&
                                                    props.facility.customAppInfo) ||
                                                undefined
                                            }
                                        />
                                    </Segment>
                                </Grid.Column>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Form>
                )}
            />
        </div>
    );
}

export default FacilitiesForm
