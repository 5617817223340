import React, { useState } from 'react';
import { Gadget } from '../../types';
import './style.less';
import { Button } from 'semantic-ui-react';
import { getSmartGadget } from '../../services/SmartGadgets';

interface Props {
    gadgets: Gadget[] | undefined
}

const SmartGadgets: React.FC<Props> = props => {
    const [gadgets, setGadgets] = useState<Gadget[] | undefined>(props.gadgets);
    const [loading, setLoading] = useState<boolean>(false);

    const handleRetry = async () => {
        setLoading(true);
        if (gadgets && gadgets[0].a4hRoomId) {
            const a4hRoomId = gadgets[0].a4hRoomId;
            const fetchedGadgets = await getSmartGadget(a4hRoomId);
            setGadgets(fetchedGadgets);
        } 
        setLoading(false);
    }

    return (
        <></>
        //             {/* HAVE COMMENTED THE BELOW PART AS IT IS BEING SHOWED IN THE FEED TAB , LATER WILL ADD IT IN THE ALEXA TAB FOR RESIDENT
        // <div className="smart-gadgets">

        //     {/* <p>Smart Gadgets</p>
        //     {gadgets && gadgets.length && !gadgets[0].error ? 
        //         <ul>
        //             {gadgets.map(gadget => 
        //                 <li key={gadget.id}>
        //                     {gadget.friendlyName ? gadget.friendlyName + ' ' : 'N/A '}
        //                     - {gadget.manufacturer ? gadget.manufacturer + ' ' : 'N/A '}
        //                     - {gadget.model ? gadget.model + ' ' : 'N/A '}
        //                     - {gadget.serialNumber ? gadget.serialNumber + ' '  : 'N/A '}
        //                     - {gadget.status ? <span className={gadget.status}>{gadget.status}</span> : 'N/A '}
        //                 </li>
        //             )}
        //         </ul>
        //     :
        //     gadgets && gadgets[0].error ? 
        //         <span>
        //             {gadgets[0].error + ". Please retry..." + '  '}
        //             <Button basic loading={loading} icon="refresh" onClick={handleRetry} />
        //         </span>
        //     :
        //         <span>No devices found</span>
        //     } 
        // </div>
        // */}
    );
};

export default SmartGadgets;