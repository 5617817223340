import React, { FC, useEffect, useState } from 'react';
import { Button, Dimmer, Dropdown, Form, Grid, Loader, Modal, Segment } from 'semantic-ui-react';
import FilePicker from '../S3FileUpload/FilePicker';
import { copyS3AssetImage, createAsset, getOneAsset, updateAsset } from '../../services/Assets';
import { toast } from 'react-toastify';
import ListDocsModal from './ListDocsModal';
import { uploadAssetImageToS3 } from '../../services/ImageUpload';
import { Asset } from '../../types/Asset';
import { sortByKey } from '../../util/sortData';
import AvailabilityModal from '../Availability/AvailabilityModal';
import { removeUnsafeElementsFromFileName } from '../../util/image';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    editId: string;
    setEditId: React.Dispatch<React.SetStateAction<string>>;
    facilityId?: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    updateAssetsLists: any;
    assetsLists: Asset[];
}

const AssetsModal: FC<Props> = ({ open, setOpen, editId, setEditId, facilityId, setLoading, updateAssetsLists, assetsLists }) => {
    const [roomName, setRoomName] = useState<string>('');
    const [assetModalLoading, setAssetModalLoading] = useState<boolean>(false); // this is the loading state of the modal, not the loading state of the page
    const [selectedAssetType, setSelectedAssetType] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [minCap, setMinCap] = useState<string>('');
    const [maxCap, setMaxCap] = useState<string>('');
    const [docId, setDocId] = useState<string>('');
    const [availabilityModal, setAvailabilityModal] = useState<boolean>(false);
    const [docsModal, setDocsModal] = useState<boolean>(false);
    const [copyAssetId, setCopyAssetId] = useState<string>('');
    const [imageName, setImageName] = useState<string>('');
    const [currAssetFacilityId, setCurrAssetFacilityId] = useState<string>('');
    const [previewImageSrc, setPreviewImageSrc] = useState<string>(''); // this is the image preview that is shown when a file is selected in the file picker
    const [file, setFile] = useState<File>(); // this is the image that is uploaded to s3
    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const initialTimings = days.reduce((acc, day) => {
        acc[day] = { startTime: '7 : 00 AM', endTime: '7 : 00 PM' };
        return acc;
    }, {});
    const [timings, setTimings] = useState(initialTimings);
    const [newTimings, setNewTimings] = useState(initialTimings);

    useEffect(() => {
        getRoomTypes();
    }, []);

    const filePickerStatus = {
        error: null,
        finished: false,
        isUploading: false,
        percentage: 0,
    };
    const assetCategories = [
        'Resident Accommodations',
        'Healthcare',
        'Dining',
        'Kitchen',
        'Recreational',
        'Entertainment',
        'Safety',
        'Security',
        'Medical Supplies',
        'Consumables',
        'Cleaning',
        'Maintenance',
        'Office and Administrative',
        'Transportation',
        'Fitness',
        'Therapy',
        'Landscaping',
        'Outdoor',
        'Utilities',
        'Infrastructure',
        'Decorative',
        'Educational',
        'Cognitive',
        'Specialized Care Equipment',
        'Wellness',
        'Creative arts',
        'Employee Resource',
        'Concierge',
        'Storage',
        'Furniture',
        'Event set up',
        'Room',
        'General',
        'Other',
        'Meal Cart'
    ];
    const assetTypeOptions = assetCategories.map(category => ({
        key: category,
        text: category,
        value: category
    })).sort((a, b) => a.text.localeCompare(b.text));

    const handleDropdownChangeAssetType = (value) => {
        setSelectedAssetType(value);
    };
    const getRoomTypes = async () => {
        try {
            setAssetModalLoading(true);
            if (editId) {
                const { response, signedUrl } = await getOneAsset(editId);
                if (signedUrl) {
                    setPreviewImageSrc(signedUrl);
                }
                setImageName(response.ImageName);
                setCurrAssetFacilityId(response.Facility);
                setRoomName(response.AssetName);
                const assetType = assetTypeOptions.find((item) => item.value === response.AssetType);
                setSelectedAssetType(assetType ? assetType.value : '');
                setDescription(response.Description ? response.Description : '');
                setUrl(response.URL ? response.URL : '');
                setMinCap(response.MinCap.toString());
                setMaxCap(response.MaxCap.toString());
                setDocId(response.DocId ? response.DocId : '');
                const formattedTimeRange = days.reduce((acc, day) => {
                    const dayAvailability = response.Availability.find((item) => item.DayOfWeek === day);
                    if (dayAvailability) {
                        acc[day] = { startTime: dayAvailability.StartTime, endTime: dayAvailability.EndTime };
                    } else {
                        acc[day] = { startTime: '', endTime: '' };
                    }
                    return acc;
                }, {});
                setTimings(formattedTimeRange);
                setNewTimings(formattedTimeRange);
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error Fetching data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setAssetModalLoading(false);
        }
    };
    const handleSubmit = async () => {
        if (selectedAssetType) {
            if (Number(maxCap) === 0 || Number(minCap) === 0) {
                toast.warn('Max cap and Min cap should not be less than or equal to zero.', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            } else if (Number(maxCap) >= Number(minCap)) {
                const Availability = days
                    .filter((day) => timings[day].startTime && timings[day].endTime)
                    .map((day) => ({
                        DayOfWeek: day,
                        StartTime: timings[day].startTime,
                        EndTime: timings[day].endTime,
                    }));
                const dataObj = {
                    AssetName: roomName,
                    AssetType: selectedAssetType,
                    Description: description,
                    URL: url,
                    MinCap: Number(minCap),
                    MaxCap: Number(maxCap),
                    Availability,
                    Facility: facilityId,
                    DocId: docId,
                    ImageName: imageName,
                };
                try {
                    setAssetModalLoading(true);
                    if (editId) {
                        if (file) {
                            await uploadImageFile(editId);
                            await updateAsset(
                                {
                                    _id: editId,
                                    ...dataObj,
                                    ImageName: file.name,
                                },
                                imageName,
                            );
                        } else {
                            await updateAsset({
                                _id: editId,
                                ...dataObj,
                            });
                        }
                        const { response = {} as Asset } = await getOneAsset(editId);
                        if (response) {
                            // Update the specific asset in the array based on _id
                            const updatedAssetsList = assetsLists.map((asset) =>
                                asset._id === response._id ? response : asset
                            );
                            // Set the state with the updated array
                            updateAssetsLists(updatedAssetsList);
                            toast.success('Successfully Updated Asset', {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        } else {
                            toast.error('Failed to Update Asset', {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    } else {
                        const createdAssetId = await createAsset(dataObj);
                        if (file) {
                            await uploadImageFile(createdAssetId);
                            await updateAsset({
                                _id: createdAssetId,
                                ...dataObj,
                                ImageName: file.name,
                            });
                        }
                        if (copyAssetId && imageName) {
                            await copyS3AssetImage(currAssetFacilityId, copyAssetId, createdAssetId, imageName);
                            await updateAsset({
                                _id: createdAssetId,
                                ...dataObj,
                                ImageName: imageName,
                            });
                        }
                        const {response = {} as Asset} = await getOneAsset(createdAssetId);
                        if(response) {
                            updateAssetsLists([...assetsLists, response]);
                            toast.success('Successfully Created Asset', {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        } else {
                            toast.error('Cannot create Asset', {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    }
                    setOpen(false);
                } catch (error) {
                    console.error('Cannot add Asset', error);
                    toast.warn(error instanceof Error ? error.message : 'Failed to Add Asset', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } finally {
                    setAssetModalLoading(false);
                }
            } else {
                toast.warn('Max Cap should be greater than or equal to Min Cap', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.warn(' Asset type are required', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };
    const uploadImageFile = async (assetId: string): Promise<void> => {
        if (file) {
            await uploadAssetImageToS3(assetId, file.name, file);
        } else {
            throw new Error('Image file is not provided');
        }
    };
    const handleFilePick = async (files: File[]) => {
        setPreviewImageSrc('');
        const file = files[0];
        const fileExtension = file.name.split('.').pop();
        if (fileExtension !== 'jpg' && fileExtension !== 'png') {
            toast.warn('Only JPG and PNG files are supported', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        const formattedFileName = removeUnsafeElementsFromFileName(file.name);
        const fileBlob = new Blob([file], { type: file.type });
        const modifiedFile = new File([fileBlob], formattedFileName, { type: file.type });
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const result = reader.result as string;
                setPreviewImageSrc(result);
                setFile(modifiedFile);
            }
        };
        reader.readAsDataURL(file);
    };
   
    const handleCopy = () => {
        if (editId) {
            setCopyAssetId(editId);
            setEditId('');
        }
    };
  
    return (
        <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} size={'mini'}>
            <Dimmer inverted active={assetModalLoading}>
                <Loader active={assetModalLoading} />
            </Dimmer>
            <Modal.Content>
                <Modal.Header>
                    <Grid>
                        <Grid.Column width={11} style={{ textAlign: 'right' }}>
                            <h3>{editId ? 'Edit an Asset' : 'Create Asset'}</h3>
                        </Grid.Column>
                        <Grid.Column width={5} style={{ textAlign: 'right' }}>
                            {editId ? (
                                <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleCopy}
                                    src="../assets-groups-management/copy.png"
                                    alt="Copy icon"
                                />
                            ) : (
                                ''
                            )}
                        </Grid.Column>
                        <FilePicker
                            {...filePickerStatus}
                            onSelected={handleFilePick}
                            multiple={false}
                            accept={['image/*']}
                        />
                        {previewImageSrc && (
                            <>
                                <p style={{ marginTop: '10px', marginBottom: '0px' }}>Preview</p>
                                <img style={{ width: '100%' }} src={previewImageSrc} />
                            </>
                        )}
                    </Grid>
                </Modal.Header>
                <Modal.Description>
                    <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input
                                required={true}
                                value={roomName || ''}
                                placeholder="Room/Asset Name"
                                onChange={(e) => setRoomName(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                placeholder={'Asset Type'}
                                closeOnEscape
                                value={selectedAssetType}
                                clearable
                                search
                                scrolling
                                selection
                                options={sortByKey(assetTypeOptions)}
                                onChange={(e, { value }) => handleDropdownChangeAssetType(value)} // e is not used, but is required for onchange event
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.TextArea
                                value={description}
                                onChange={(e) => setDescription(e.currentTarget.value)}
                                placeholder="Asset Description"
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input value={url || ''} placeholder="URL" onChange={(e) => setUrl(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <Grid centered>
                                <Grid.Column style={{ width: '50%' }}>
                                    <Segment>
                                        <span>Min Cap</span>
                                        <Form.Input
                                            type="number"
                                            min="0"
                                            required={true}
                                            value={minCap || ''}
                                            placeholder="0"
                                            onChange={(e) => setMinCap(e.target.value)}
                                        />
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column style={{ width: '50%' }}>
                                    <Segment>
                                        <span>Max Cap</span>
                                        <Form.Input
                                            type="number"
                                            min="0"
                                            required={true}
                                            value={maxCap || ''}
                                            placeholder="0"
                                            onChange={(e) => setMaxCap(e.target.value)}
                                        />
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                        <Form.Field style={{ justifyContent: 'center' }}>
                            <Grid>
                                <Grid.Row centered>
                                    <Segment
                                        style={{
                                            width: '50%',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setAvailabilityModal(true)}
                                    >
                                        <img src="../assets-groups-management/calendar.png" alt="Image Input" />
                                        <span style={{ fontSize: '18px' }}>Availability</span>
                                    </Segment>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                        <Form.Field style={{ justifyContent: 'center' }}>
                            <Grid>
                                <Grid.Row centered>
                                    <Segment
                                        style={{
                                            backgroundColor: '#183466',
                                            width: '30%',
                                            height: '90%',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            color: 'white',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setDocsModal(true)}
                                    >
                                        <img src="../assets-groups-management/copy-white.png" alt="Image Input" />
                                        <span style={{ fontSize: '16px' }}>Docs</span>
                                    </Segment>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                        <Modal.Actions style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Button type="submit" size={'big'} color='blue'>
                                {editId ? 'Update' : 'Create'}
                            </Button>
                            <Button size={'big'} onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <AvailabilityModal
                openAvailabilityModal={availabilityModal}
                days={days}
                newTimings={newTimings}
                setNewTimings={setNewTimings}
                timings={timings}
                setTimings={setTimings}
                setOpenAvailabilityModal={setAvailabilityModal}
            />
            {docsModal && <ListDocsModal open={docsModal} setOpen={setDocsModal} docId={docId} setDocId={setDocId} />}
        </Modal>
    );
};

export default AssetsModal;
