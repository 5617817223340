import React, { useEffect, useState } from "react";
import UsersForm, { FormValues } from "../../pages/AdminPanel/Staff/UsersForm";
import { Department, Facility, User, UserRole } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { fetchOneRoleForFederatedUsers, fetchFacilityIdFromFederatedUserOfficeLocation, fetchOneFacilityForFederatedUsers, createFederatedUserWithRoles } from "../../services/FederatedIDPUserAPI";
import { Dimmer, Loader } from "semantic-ui-react";
import { setFederatedUserDetails } from "../../actions/auth";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

const FederatedUserDetailsForm = (props) => {
    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [staffRole, setStaffRole] = useState<UserRole>({} as UserRole);
    const [user, setUser] = useState<User>({} as User);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const federatedUserName = useSelector((state: AppState) => state.authReducer.federatedUsername);
    const federatedUserEmail = useSelector((state: AppState) => state.authReducer.federatedUserEmail);
    const federatedUserExternalId = useSelector((state: AppState) => state.authReducer.federatedUserExternalId);
    const federatedUserOfficeLocation = useSelector((state: AppState) => state.authReducer.federatedUserOfficeLocation);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const [staffRoleRes, FacilityId = ""] = await Promise.all([fetchOneRoleForFederatedUsers("Staff", 1), fetchFacilityIdFromFederatedUserOfficeLocation(federatedUserOfficeLocation || "")]);
                const facilityDetails = await fetchOneFacilityForFederatedUsers(FacilityId);
                setFacilities([facilityDetails]);
                setStaffRole(staffRoleRes);
                setUser(
                    {
                        FirstName: "",
                        LastName: "",
                        Cell: "",
                        _id: "",
                        AddedBy: "",
                        Email: federatedUserEmail || "",
                        DateAdded: "",
                        ExternalId: federatedUserExternalId || "",
                        IsActive: 1,
                        Username: federatedUserName || "",
                        Facility: FacilityId || "",
                        Roles: [staffRoleRes],
                        Departments: [],
                        Icon: "",
                        LastUpdated: "",
                        UpdatedBy: "",
                        Phone: "",
                        Stars: "",
                    }
                );
            } catch (error) {
                console.log("error", error);
                toast.error(error instanceof Error ? error.message : "Something went wrong in fetching facility or role", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const submitButtonClickHandler = async (data: FormValues) => {
        // on form submission, call createUserWithDepartments with the role fetch from fetchOneRole, and departments as an empty array
        try {
            setLoading(true);
            if(!federatedUserExternalId) throw new Error("ExternalId is missing");
            const dataWithExternalId = { ...data, ExternalId: federatedUserExternalId };
            const id = await createFederatedUserWithRoles(dataWithExternalId, dataWithExternalId.Roles);
            if (id && federatedUserEmail && federatedUserName && federatedUserOfficeLocation && federatedUserExternalId) {
                dispatch(setFederatedUserDetails(true, true, federatedUserEmail, federatedUserExternalId, federatedUserName, federatedUserOfficeLocation));
                const from = props.location.state && props.location.state.from || '/';
                props.history.push(from);
            }
        } catch (e) {
            console.error("error", e);
            toast.error(e instanceof Error ? e.message : "Something went wrong in creating user", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    return (
        <>
            {loading ? (
                <Dimmer active={loading} inverted>
                    <Loader active={loading} />
                </Dimmer>
            ) : (
                <>
                    <h3 style={{ textAlign: 'center' }}>Please fill below details to continue</h3>
                    <UsersForm
                        departments={[] as Department[]}
                        facilities={facilities}
                        roles={[staffRole]}
                        title="Fill the below details to complete your registration"
                        onSave={submitButtonClickHandler}
                        cancel={() => {}} // cancel button is not required in this form as we display back lo login instead of cancel
                        user={user}
                        error={null}
                        isFederatedUserForm={true}
                    />
                </>
            )}
        </>
    );
};

export default withRouter(FederatedUserDetailsForm);