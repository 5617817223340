import { UserProfile, User } from '../types'

type GenericProfile = UserProfile | User | null

export function isFacilityAdmin(profile: GenericProfile) {
    if (!profile) return false
    return profile.Roles.some((role) => role.Name === 'Facility Admin' && role.IsActive === 1)
}

export function isSysAdmin(profile: GenericProfile) {
    if (!profile) return false
    return profile.Roles.some((role) => role.Name === 'System Admin' && role.IsActive === 1)
}

export function isAdmin(profile: GenericProfile) {
    if (!profile) return false
    return isSysAdmin(profile) || isFacilityAdmin(profile)
}

export function isStaff(profile: GenericProfile) {
    if (!profile) return false
    return profile.Roles.some((role) => role.Name === 'Staff')
}

export function isJustStaff(profile: GenericProfile) {
    if (!profile) return false
    return !isAdmin(profile) && !isPowerUser(profile) && isStaff(profile)
}

export function isPowerUser(profile: GenericProfile) {
    if (!profile) return false
    return profile.Roles.some((role) => role.Name === 'Power User')
}

export function isJustPowerUser(profile: GenericProfile) {
    if (!profile) return false
    return isPowerUser(profile) && !isFacilityAdmin(profile)
}

export function hasPermission(profile: UserProfile | null, permission: string) {
    if (!profile) return false
    return profile.Permissions.includes(permission)
}

export function hasPermissions(profile: UserProfile | null, permissions: string[]) {
    return permissions.map((p) => hasPermission(profile, p)).every((p) => p === true)
}

export function canManageRequests(profile: UserProfile | null) {
    return hasPermission(profile, '/requests/manage*')
}

export function canSeeDashboard(profile: UserProfile | null) {
    return hasPermission(profile, '/reports/dashboard')
}

export function canManageCheckin(profile: UserProfile | null) {
    return isAdmin(profile) || isStaff(profile) || isPowerUser(profile);
}

export function canManageUsers(profile: UserProfile | null) {
    return hasPermissions(profile, [
        '/roles/get',
        '/roles/list',
        // '/users/department/add',
        // '/users/department/remove',
        '/users/disable',
        '/users/enable',
        '/users/get',
        '/users/list',
        '/users/profile/get',
        '/users/profile/update',
        '/users/roles/add',
        '/users/update',
    ])
}

export function canManageRegistrants(profile: UserProfile | null) {
    return hasPermissions(profile, ['/registrants/add', '/registrants/get', '/registrants/list', '/registrants/update'])
}

export function canManageFacilities(profile: UserProfile | null) {
    return hasPermissions(profile, ['/facilities/get', '/facilities/update', '/facilities/add', '/facilities/list'])
}

export function canManageUnits(profile: UserProfile | null) {
    return hasPermissions(profile, ['/units/add', '/units/get', '/units/list', '/units/update'])
}

export function canManageDepartments(profile: UserProfile | null) {
    return hasPermissions(profile, ['/departments/add', '/departments/get', '/departments/list', '/departments/update'])
}

export function canManageContacts(profile: UserProfile | null) {
    return hasPermissions(profile, ['/contacts/add', '/contacts/get', '/contacts/list', '/contacts/update'])
}

export function canManageRequestTypes(profile: UserProfile | null) {
    return hasPermissions(profile, [
        '/requesttypes/add',
        '/requesttypes/get',
        '/requesttypes/list',
        '/requesttypes/update',
    ])
}

export function canAccessFacilityForms(profile: UserProfile | null) {
    return (
        canManageUnits(profile) ||
        canManageContacts(profile) ||
        canManageDepartments(profile) ||
        canManageRequestTypes(profile)
    )
}

export function canManageNotifications(profile: UserProfile | null) {
    return hasPermissions(profile, ['/facilities/notifications/send', '/facilities/notifications/list'])
}

export function canManageDailyActivities(profile: UserProfile | null) {
    return hasPermissions(profile, [
        '/dailyactivities/add',
        '/dailyactivities/list',
        '/dailyactivities/update',
        '/dailyactivities/delete',
    ])
}

export function canManageUniversityTrainings(profile: UserProfile | null) {
    return hasPermissions(profile, ['/university/trainings/manage*'])
}

export function canManageStandardUniversityTrainings(profile: UserProfile | null) {
    return hasPermissions(profile, [
        '/university/standard-trainings/add',
        '/university/standard-trainings/list',
        '/university/standard-trainings/update',
        '/university/standard-trainings/delete',
    ])
}

export function canManageMenus(profile: UserProfile | null) {
    return hasPermissions(profile, ['/menu/add', '/menu/list', '/menu/update', '/menu/delete'])
}

export function isFacilityTabEnabled(profile: UserProfile | null, tab: string) {
    if (!profile && !tab) return false;
    // Special case: if the tab name is "AI Triggers," treat it as "ai" 
    // Special case: if the tab name is "requests," treat it as "orders"
    const lowercaseTab = tab.toLowerCase();
    let adjustedTab = lowercaseTab;

    if (lowercaseTab === "ai triggers") {
        adjustedTab = "ai";
    } else if (lowercaseTab === "requests") {
        adjustedTab = "orders";
    } else if (lowercaseTab === "cookbook") {
        adjustedTab = "dining";
    }
    // Check if the tab exists in FacilityConfigurations and its value is true
    return profile && profile.FacilityConfigurations && profile.FacilityConfigurations[adjustedTab] === true ? true : false;
}