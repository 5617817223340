import API from './API'
import config from '../config'
import { ContentType } from '../types/signage'
import { removeUnsafeElementsFromFileName } from '../util/image'

declare var process: {
    env: {
        SERVER_ENV: string
    }
}
console.log(process.env.SERVER_ENV)
const envConfig = config[process.env.SERVER_ENV] || config.development

export async function getSignedActivityImageUrls(activityId:string, fileNames :string[]) {
    // const urls = 
    return await API.lambdaPost('/dailyactivities/getSignedImageUrls', {
        activityId,
        fileNames
    })

}

export async function getSignedBirthdayImageUrls(birthdayId:string, fileName:string) {
    return await API.lambdaPost("/birthdays/getSignedImageUrls", {
        birthdayId, 
        fileName
    })
}
export async function getPreSignedS3Url(
    folderName: string,
    file: File,
    randomizeFilename = true,
    removeUnsupportedCharactersFromFileName = false,
): Promise<{ putUrl: string; fileName: string }> {
    let name = file.name
    if (randomizeFilename) {
        const nameParts = file.name.split('.')
        nameParts[nameParts.length - 2] = nameParts[nameParts.length - 2] + `_${Date.now()}`
        name = nameParts.join('.')
    }

    if (removeUnsupportedCharactersFromFileName) {
        name = removeUnsafeElementsFromFileName(name);
    }
    const url = await API.lambdaPost('/image/upload', {
        Folder: folderName,
        FileName: name,
    })
    return {
        putUrl: url as string,
        fileName: name,
    }
}

function generateS3ImageUrl(folderName: string, fileName: string) {
    return `${envConfig.s3BucketUrl}/${folderName}/${fileName}`
}

export async function uploadFile(folderName: string, file: File, onProgress?: (percent: number) => any, returnFileInfo?: boolean, removeUnsupportedCharactersFromFileName?:boolean): Promise<any> {
    const { putUrl, fileName } = await getPreSignedS3Url(folderName, file, true, removeUnsupportedCharactersFromFileName);

    await API.putFile(putUrl, file, onProgress)
    const url = generateS3ImageUrl(folderName, fileName)

    if (returnFileInfo) {
        return { url, fileName };
    }

    return url
}

export const generateS3ImageUrlForSignage = async (imageName: string) => {
    const obj = await API.lambdaGet(`/signage/getSignedImageUrls?imageName=${imageName}`)
    return obj.signedUrl
}
export const getPreSignedUrlForSignage = async (currentFileName: string, randomizeFilename: boolean): Promise<{ putUrl: string; fileName: string }> => {
    let name = currentFileName
    if (randomizeFilename) {
        const nameParts = currentFileName.split('.')
        nameParts[nameParts.length - 2] = nameParts[nameParts.length - 2] + `_${Date.now()}`
        name = nameParts.join('.')
    }
    const url = await API.lambdaPost('/signage/images/add', {
        FileName: name
    })
    return {
        putUrl: url as string,
        fileName: name,
    }
}
export const getPreSignedUrlForSignageContent = async (contentType: ContentType, contentId: string, fileName: string): Promise<{ putUrl: string }> => {
    const url = await API.lambdaPost('/signage/images/add', {
        contentType, 
        contentId,
        uploadContent: true,
        fileName
    })
    return {
        putUrl: url as string,
    }
}

export const getPreSignedUrlForAssetImage = async (assetId: string, imageName: string): Promise<{ putUrl: string; }> => {
    const url = await API.lambdaPost('/assets/images/upload', {
        assetId,
        imageName
    });
    return {
        putUrl: url as string,
    };
}

export const getPreSignedUrlForRequestsInstances = async (requestInstanceId: string, imageName: string): Promise<{ putUrl: string; }> => {
    const url = await API.lambdaPost('/requests/instances/images/upload', {
        requestInstanceId,
        imageName
    });
    return {
        putUrl: url as string,
    };
}

export const getPreSignedUrlForGroupImage = async (groupId: string, imageName: string): Promise<{ putUrl: string; }> => {
    const url = await API.lambdaPost('/groups/images/upload', {
        groupId,
        imageName
    });
    return {
        putUrl: url as string,
    };
}

// export const getSignageBucketUrl =async () => {
//     // todo currently s3 is blocking this with Access denied make this work
//     const res = await API.lambdaGet("/signage/getS3BucketUrl")
//     console.log(res)
//     return res
// }

export const uploadSignageFile = async (imageFile: string, file: File, Facility: string, onProgress?: (percent: number) => any) => {
    const { putUrl, fileName } = await getPreSignedUrlForSignage(imageFile, true)
    await API.putFile(putUrl, file, onProgress)
    // const url = await generateS3ImageUrlForSignage(fileName)
    const url = `https://speak2-signage.s3.amazonaws.com/${Facility}/${fileName}`
    return url
}

export const uploadSignageImageToS3 = async (contentType: ContentType, contentId: string, imageFileName: string, file: File, onProgress?: (percent: number) => any) => {
    const { putUrl } = await getPreSignedUrlForSignageContent(contentType, contentId, imageFileName)
    await API.putFile(putUrl, file, onProgress)
    return
}

export const copySignageImageFromS3 = async (contentType: ContentType, copyUrl: string, contentDestinationId: string) => {
    const res = await API.lambdaPost("/signage/images/add", {
        contentType,
        copyUrl,
        contentDestinationId, 
        galleryImage: true
    })
    return res
}

export const listSignageImageFromS3 = async (contentType: ContentType, contentId: string) => {
    const res = await API.lambdaPost('/signage/images/list', {
        contentType,
        contentId
    })
    return res
}

export const getSignageImageFromS3 = async (contentType: ContentType, contentId: string) => {
    const res = await API.lambdaPost('/signage/images/get', {
        contentType,
        contentId
    })
    return res;
}

export const listSignageImagesForContentTypeFromS3 = async (contentType: ContentType, perPage: number = 10, nextContinuationToken: string | null, eTags?: string[]) => {
    const res = await API.lambdaPost('/signage/images/list', {
        contentType,
        listGalleryImages: true, 
        perPage, 
        nextContinuationToken, 
        eTags
    })
    return res
}

export const listDefaultSignageImages = async (contentType: ContentType, perPage: number = 10, nextContinuationToken: string | null, eTags?: string[]) => {
    const res = await API.lambdaPost('/signage/images/listDefaultImages', {
        contentType,
        perPage,
        nextContinuationToken,
        eTags
    });
    return res;
};

export const uploadAssetImageToS3 = async (assetId: string, imageName: string, file: File, onProgress?: (percent: number) => any) => {
    const { putUrl } = await getPreSignedUrlForAssetImage(assetId, imageName);
    await API.putFile(putUrl, file, onProgress);
    return;
};

export const uploadRequestsInstancesImageToS3 = async (requestInstanceId: string, imageName: string, file: File, onProgress?: (percent: number) => any) => {
    const { putUrl } = await getPreSignedUrlForRequestsInstances(requestInstanceId, imageName);
    await API.putFile(putUrl, file, onProgress);
    return;
};

export const uploadGroupImageToS3 = async (groupId: string, imageName: string, file: File, onProgress?: (percent: number) => any) => {
    const { putUrl } = await getPreSignedUrlForGroupImage(groupId, imageName);
    await API.putFile(putUrl, file, onProgress);
    return;
};