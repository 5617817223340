import React, { useEffect, useState } from 'react';
import '../DailyAffirmations/style.less';
import { Dimmer, Loader, Tab, Form, Dropdown, Button, List, Icon, Grid } from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarEventContent from '../../../components/CalendarEventContent';
import {  fetchRegistrants } from '../../../services/Registrants';
import { fetchAllFacilityNotepadEvents, fetchAllResidentCalendarEventsFromResidentIds } from "../../../services/Notepad";
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { jsonTocsvDownloaderV2, formatAndExportDataForPrint } from '../../../util/jsonTocsvDownloader';
import { toast } from 'react-toastify';
import { ResidentCalendar } from '../../../types/residentCalendar.d';
import ResidentCalendarForm from '../../../components/ResidentCalendar/ResidentCalendarForm';
import moment from 'moment-timezone';
import CustomDatePicker, { DateRange } from '../../../components/CustomDatePicker';
import { useSelector } from 'react-redux';
import { AuthState, User } from '../../../types';
import { fetchOneFacility } from '../../../services/Facilities';
import './style.less';
import { fetchResidentGroups } from '../../../services/RoomGroups';
import { fetchAllStaffUsers } from '../../../services/Users';
import { KioskAnswersType, Question } from '../../../types/KioskAnswers';
import { sortByKey } from '../../../util/sortData';

const ResidentCalendarPage = () => {
    const [residentOptions, setResidentOptions] = useState<{ key: string; text: string; value: string }[]>([
        { key: 'all', text: 'All residents', value: 'all' },
    ]);
    const [staffOptions, setStaffOptions] = useState<{ key: string; text: string; value: string }[]>([]);
    const [selectedResdients, setSelectedResidents] = useState<string[]>(['all']);
    const [startDateFilter, setStartDateFilter] = useState<string>(format(startOfMonth(new Date()), 'YYYY-MM-DD'));
    const [endDateFilter, setEndDateFilter] = useState<string>(format(endOfMonth(new Date()), 'YYYY-MM-DD'));
    const [dropdownLoader, setDropdownLoader] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [residentCalendarEvents, setResidentCalendarEvents] = useState<ResidentCalendar[]>([]);
    const [exportLoader, setExportLoader] = useState<boolean>(false);
    const [printLoader, setPrintLoader] = useState<boolean>(false);
    const [disableApplyFilters, setDisableApplyFilters] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [residentCalendarData, setResidentCalendarData] = useState<Partial<ResidentCalendar>>({})
    const [duration, setDuration] = useState<number>(0)
    const [showForm, setShowForm] = useState<boolean>(false)
    const [dateRange, setDateRange] = useState<DateRange>({
        end: new Date(format(endOfMonth(new Date()))),
        start: new Date(format(startOfMonth(new Date())))
    });
    const [facilityTZ, setFacilityTZ] = useState("")
    const [formattedResidents, setformattedResidents] = useState([]);
    const [staff, setStaff] = useState<User[]>([]);
    const [activePaneIndex, setActivePaneIndex] = useState(0);
    const [previousPaneIndex, setPreviousPaneIndex] = useState(0);
    const [disableEditAndDelete, setDisableEditAndDelete] = useState<boolean>(false);
    const pageSource = "Notepad";

    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });

    const formatAndSortRoomGroups = (groups: any) => {
        const formattedRoomGroups = groups.map((obj) => {
            if (obj.ResidentIds && obj.ResidentIds.length <= 1) return // don't show groups which does not have atleast 2 ResidentIds (To take care of wierd issues with residentGroups)
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.ResidentIds]}`,
            };
        }).filter(obj => obj);

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRoomGroups;
    }

    const addRegistrantNames = (calendarEvents: any, formattedResidentOptions: any) => {
        return calendarEvents.map((event: any) => {
            const registrantNames = event.registrantIds.map(registrantId => {
                const residentOption = formattedResidentOptions.find(option => option.value === registrantId);
                return residentOption ? residentOption.text : null;
            }).filter((name: any) => name !== null);

            return {
                ...event,
                registrantNames,
            };
        });
    }

    const getDisplayNames = (registrantNames: any) => {
        if (registrantNames && registrantNames.length) {
            const maxDisplayNames = 2;
            const totalNames = registrantNames.length;
            let displayNames = "";

            if (totalNames <= maxDisplayNames) {
                displayNames = registrantNames.join(", ") + " : ";
            } else {
                displayNames = registrantNames.slice(0, maxDisplayNames).join(", ");
                const remainingNames = totalNames - maxDisplayNames;
                displayNames += ` and ${remainingNames} other${remainingNames > 1 ? "s : " : " : "}`;
            }
            return displayNames
        } else {
            return " "
        }
    }

    const createUpdateString = (kioskAnswer: KioskAnswersType): string => {
        let updateString = `Signing In ${kioskAnswer.visitorName}, `;
            for (const key in kioskAnswer) {
            if (key.startsWith("question") || key.startsWith("yesNoQuestion")) {
                const questionObj: Question = kioskAnswer[key as keyof KioskAnswersType] as Question;
    
                // Check if the question is enabled and has an answerText
                if (questionObj.enabled && questionObj.answerText) {
                    updateString += `${questionObj.questionText}: ${questionObj.answerText}, `;
                }
            }
        }
    
        // Remove trailing comma and space
        updateString = updateString.replace(/, $/, "");
    
        return updateString;
    };
    

    useEffect(() => {
        (async () => {
            try {
                setDropdownLoader(true);
                setLoader(true);
                const staffDetails = await fetchAllStaffUsers();
                const formatedStaffOptions = formatAndSortStaffs(staffDetails)
                const residentsWithRooms = await fetchRegistrants();
                const formattedResidentOptions = formatAndSortResidents(residentsWithRooms);
                setformattedResidents(formattedResidentOptions);
                const calendarEvents = await fetchAllFacilityNotepadEvents(
                    startDateFilter,
                    endDateFilter,
                );
                const calendarEventsWithRegistrantName = addRegistrantNames(calendarEvents, formattedResidentOptions);
                const roomGroups = await fetchResidentGroups();
                const sortedRoomGroups = formatAndSortRoomGroups(roomGroups);
                setStaff(staffDetails);
                if (profile && profile.Facility) {
                    const { FacilityTimeZone = "America/New_York" } = await fetchOneFacility(profile.Facility);
                    setFacilityTZ(FacilityTimeZone)
                }
                const residentOptions = [{ key: 'all', text: 'All residents', value: 'all' }]
                    .concat(sortedRoomGroups)
                    .concat(formattedResidentOptions);
                setResidentOptions(residentOptions);
                setStaffOptions(formatedStaffOptions)
                setResidentCalendarEvents(calendarEventsWithRegistrantName);
                setDropdownLoader(false);
                setLoader(false);
            } catch (error) {
                showWarningToast(error instanceof Error ? error.message : "Failed to fetch calendar events")
                setDropdownLoader(false);
                setLoader(false);
            }
        })();
    }, []);

    const formatAndSortResidents = (residents) => {
        const formattedResidents = residents.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.FirstName}`,
                value: `${obj._id}`,
            };
        });

        const sortedResidents = formattedResidents.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedResidents;
    };

    const formatAndSortStaffs = (staff) => {
        const formattedStaffs = staff.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.FirstName}`,
                value: `${obj._id}`,
            };
        });

        const sortedStaffs = formattedStaffs.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedStaffs;
    };

    const dateClickHandler = async ({ date }) => {
        setIsEdit(false);
        // Set the time to 9 AM
        const dateWithTime = moment(date).hour(9).minute(0).second(0);
        // Format the date and calculate the endDateTimeString
        const dateTimeString = dateWithTime.format("YYYY-MM-DDTHH:mm:ss");
        const endDateTimeString = dateWithTime.clone().add(30, "minutes").format("YYYY-MM-DDTHH:mm:ss");
        setResidentCalendarData({
            dateTimeString,
            endDateTimeString,
        });
        setDuration(30);
        setShowForm(true);
    };

    const eventClickHandler = async (payload) => {
        const eventId = (payload.event && payload.event._def && payload.event._def.publicId) || payload._id;
        const event = residentCalendarEvents.find(calendarEvent => String(calendarEvent._id) === String(eventId))
        if(event && event.syncStatus === "merged"){
            setDisableEditAndDelete(true);
        } else {
            setDisableEditAndDelete(false);
        }
        if (!event) showWarningToast('There is an issue with this entry !')
        const data = Object.assign({}, event);
        const durationInMinutes = data.endDateTimeString
            ? Math.floor((Date.parse(data.endDateTimeString) - Date.parse(data.dateTimeString)) / 1000 / 60)
            : 0;
        setResidentCalendarData(data);
        setIsEdit(true);
        setDuration(durationInMinutes);
        setShowForm(true);
    };

    const showWarningToast = (message: string) => {
        toast.warn(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }

    const dateChangeHandler = async ({ startStr, endStr }) => {
        // avoid fetch before component mount
        if (residentOptions.length > 1) {
            // startStr and endStr look like this "2023-01-29T00:00:00+05:30"
            if (previousPaneIndex === 1) {
                setPreviousPaneIndex(0);
                return;
            }
            setDateRange({
                end: new Date(endStr),
                start: new Date(startStr)
            })
            const start = format(startStr, 'YYYY-MM-DD'); // resolves to 'YYYY-MM-DD'
            const end = format(endStr, 'YYYY-MM-DD'); // resolves to 'YYYY-MM-DD'
            setStartDateFilter(start);
            setEndDateFilter(end);
            await refreshCalendarEvents(selectedResdients, start, end);
        }
    };

    const handleDropdownChange = (residentIds) => {
        const handleGroups = residentIds.map((residentId) => {
            if (residentId.indexOf(',') !== -1) {
                return residentId.split(',');
            }
            return residentId;
        });
        const rooms = handleGroups.flat();
        if (rooms.length === 0) {
            setDisableApplyFilters(true);
            setSelectedResidents(rooms);
        }
        else if (rooms.includes('all')) {
            setDisableApplyFilters(false);
            setSelectedResidents(['all']);
        } else {
            setDisableApplyFilters(false);
            setSelectedResidents(rooms);
        }
    };

    const refreshCalendarEvents = async (selection: string[], start: string, end: string) => {
        setLoader(true);
        try {
            const residentsToQuery: string[] = [];
            if (!(selection.length === 1 && selection[0] === 'all')) {
                residentsToQuery.push(...selection);
            }
            const calendarEvents = await fetchAllResidentCalendarEventsFromResidentIds(residentsToQuery, start, end);
            const calendarEventsWithRegistrantName = addRegistrantNames(calendarEvents, formattedResidents);
            setResidentCalendarEvents(calendarEventsWithRegistrantName);
            setLoader(false);
        } catch (error) {
            showWarningToast(error instanceof Error ? error.message : "Failed to fetch calendar events")
            setLoader(false);
        }
    };

    const handleSubmit = async () => {
        await refreshCalendarEvents(selectedResdients, startDateFilter, endDateFilter);
    };

    const exportCalendarData = async () => {
        if (exportLoader) {
            showWarningToast("Task in progress, please wait until it's completed.");
            return;
        }
        setExportLoader(true);
        const residentCalendarWithNames = addRegistrantNames(residentCalendarEvents, formattedResidents);
        const staffLookup = staff.reduce((lookup, staffDetail) => {
            lookup[staffDetail._id] = staffDetail;
            return lookup;
        }, {});

        const orderedCalendarEvents = residentCalendarWithNames
            .sort((a, b) => moment.tz(b.dateTimeString, facilityTZ).valueOf() - moment.tz(a.dateTimeString, facilityTZ).valueOf())
            .map((event) => {
                const { dateTimeString, registrantNames = [], text, staffId, location } = event;
                const staffDetail = staffLookup[staffId];
                const staffName = staffDetail && `${staffDetail.FirstName} ${staffDetail.LastName}`;
                const formattedDate = moment.tz(dateTimeString, facilityTZ).format('MM/DD/YYYY');
                const formattedTime = moment.tz(dateTimeString, facilityTZ).format('hh:mm A');

                return {
                    Date: formattedDate,
                    Time: formattedTime,
                    Residents: registrantNames.join(', '),
                    Event: text,
                    staffName,
                    Location: location,
                };
            });

        jsonTocsvDownloaderV2(orderedCalendarEvents, 'Resident-Calendar');
        setExportLoader(false);
    };

    const printCalendarData = async () => {
        if (printLoader) {
            showWarningToast("Task in progress, please wait until it's completed.");
            return;
        }
        if (!residentCalendarEvents || !residentCalendarEvents.length) {
            showWarningToast("No events to print");
            return;
        }
        setPrintLoader(true);
        const formattedData = residentCalendarEvents.map(residentEvent => {
            return {
                Subject: (getDisplayNames(residentEvent.registrantNames) + residentEvent.text),
                StartDate: residentEvent.dateTimeString,
                StartTime: residentEvent.dateTimeString
            }
        })
        await formatAndExportDataForPrint(formattedData, 'Resident-Calendar', facilityTZ, "activity"); // activity formats date in  "YYYY-MM-DD hh:mm A"
        setPrintLoader(false);
    };

    const setResdientCalendarDataField = (key: keyof ResidentCalendar, value: any) => {
        if (key === 'registrantIds') {
            if (Array.isArray(value)) {
                const handleGroups = value.map((residentId) => {
                    if (residentId.indexOf(',') !== -1) {
                        return residentId.split(',');
                    }
                    return residentId;
                });
                const rooms = handleGroups.flat();
                console.log({ rooms });
                setResidentCalendarData({
                    ...residentCalendarData,
                    [key]: rooms,
                },
                );
            }
        } else if (key === 'dateTimeString') {
            const endDateTimeString = moment(value).add(duration, 'm').format('YYYY-MM-DDTHH:mm:ss');
            console.log({ value, endDateTimeString })
            setResidentCalendarData({
                ...residentCalendarData,
                [key]: value,
                endDateTimeString,
            });
        } else if (key === 'staffId') {
            setResidentCalendarData({
                ...residentCalendarData,
                [key]: value,
            });
        } else {
            setResidentCalendarData({
                ...residentCalendarData,
                [key]: value,
            });
        }
    };
    const formattedCalendarEvents = () => {
        return residentCalendarEvents.map(event => {
            const dateTimeInfo = event.dateTimeString.replace('T', ' ');
            return {
                text: event.text,
                timestamp: dateTimeInfo,
                _id: event._id
            }
        })
    }

    const onDateRangeChange = (start: Date, end: Date | undefined) => {
        setStartDateFilter(format(start, 'YYYY-MM-DD'));
        if (start.getTime() > Date.parse(endDateFilter)) {
            setEndDateFilter(format(start, 'YYYY-MM-DD'));
        }
        if (end) {
            setEndDateFilter(format(end, 'YYYY-MM-DD'))
        }
    }

    const handleTabChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: any) => {
        setPreviousPaneIndex(activePaneIndex);
        setActivePaneIndex(data.activeIndex);
    };

    const panes = [
        {
            menuItem: 'Calendar',
            render: () => {
                return (
                    <div className="menu-calendar-view">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                right: 'addItem export today prev,next',
                            }}
                            customButtons={{
                                export: {
                                    text: exportLoader ? 'Loading...' : 'Export data',
                                    click: () => {
                                        exportCalendarData();
                                    },
                                },
                                // commented out print button as it is not required but we might need it later
                                // print: {
                                //     text: printLoader ? 'Loading...' : 'Print Calendar',
                                //     click: () => {
                                //         printCalendarData();
                                //     },
                                // },
                                addItem: {
                                    text: `+`,
                                    click: () => {
                                        setIsEdit(false);
                                        setShowForm(!showForm)
                                    },
                                }
                            }}
                            dateClick={dateClickHandler}
                            datesSet={dateChangeHandler}
                            eventClick={eventClickHandler}
                            editable={false}
                            timeZone={'local'}
                            defaultTimedEventDuration={0} // without this, events with time after 11pm get extended to the next day as default value is 1hr
                            events={residentCalendarEvents.map((event) => {
                                const start = new Date(event.dateTimeString).getTime();
                                return {
                                    id: event._id,
                                    title: getDisplayNames(event.registrantNames) + event.text,
                                    start,
                                };
                            })}
                            eventContent={(info) => <CalendarEventContent info={info} />}
                            initialDate={dateRange.start}
                        />
                    </div>
                );
            },
        },
        {
            menuItem: 'List',
            render: () => {
                return (
                    <>
                        <div style={{ display: 'flex' }}>
                            {/**commented the print button as it no required now but we might need it later. */}
                            {/* <Button style={{ backgroundColor: "#2C3E50", color: "#FFFFFF", fontWeight: 300 }} onClick={printCalendarData}>{printLoader ? 'Loading...' : 'Print Calendar'}</Button> */}
                            <Button style={{ backgroundColor: "#2C3E50", color: "#FFFFFF", fontWeight: 300 }} onClick={exportCalendarData}>{exportLoader ? 'Loading...' : 'Export data'}</Button>
                        </div>
                        <List divided relaxed>
                            {/* @ts-ignore */}
                            {residentCalendarEvents.sort((a, b) => (parseInt(format(b.dateTimeString, 'x'))) - parseInt(format(a.dateTimeString, 'x'))).map(event =>
                                <List.Item key={event._id} onClick={() => eventClickHandler({ _id: event._id })}>
                                    <List.Content>
                                        <List.Header as='a'>{getDisplayNames(event.registrantNames) + event.text}</List.Header>
                                        <List.Description as='a'>{format(event.dateTimeString, 'MM/DD/YYYY hh:mm a')}</List.Description>
                                    </List.Content>
                                </List.Item>
                            )}
                        </List>
                        {/* todo use the below later if required */}
                        {/* <div className="pagination-holder">
                            <Pagination activePage={activeListPageNO} totalPages={Math.ceil(residentCalendarEvents.length) / 20} onPageChange={() => { }} />
                        </div> */}
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <Dimmer active={loader} inverted>
                <Loader active={loader} />
            </Dimmer>
            <ResidentCalendarForm
                setLoader={(value: boolean) => setLoader(value)}
                showModal={showForm}
                closeForm={() => setShowForm(false)}
                isEdit={isEdit}
                residentCalendarData={residentCalendarData}
                setResdientCalendarDataField={(...values) => setResdientCalendarDataField(...values)}
                displayError={(message) => message.length > 0 ? showWarningToast(message) : ""}
                refresh={handleSubmit} // handle submit refreshes the page based on filters
                resetResidentCalendarData={() => setResidentCalendarData({})}
                residentOptions={residentOptions.filter(option => option.value !== 'all')}
                staffOptions={staffOptions}
                duration={duration}
                setDuration={(value: number) => setDuration(value)}
                disableEditAndDelete={disableEditAndDelete}
                pageSource={pageSource}
            />

            <Form size="tiny" onSubmit={handleSubmit}>
                <Form.Group >
                    <Form.Field width={'16'}>
                        <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} onChange={onDateRangeChange} />
                        {/* <label>Residents</label> */}
                        <div>
                            <Dropdown
                                style={{ border: '1px solid #183466', margin: "10px 0px" }}
                                loading={dropdownLoader}
                                closeOnEscape
                                value={selectedResdients}
                                multiple
                                clearable
                                search
                                scrolling
                                selection 
                                options={sortByKey(residentOptions)}
                                disabled={dropdownLoader}
                                onChange={(e, d) => handleDropdownChange(d.value)}
                            />
                            <Button className='button-class' primary type="submit" disabled={disableApplyFilters}>
                                Apply Filters
                            </Button>
                        </div>
                    </Form.Field>
                </Form.Group>
            </Form>

            <Tab className="views-tabs" menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={activePaneIndex} onTabChange={handleTabChange} />
        </div>
    );
};

export default ResidentCalendarPage;