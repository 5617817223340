import React from 'react'
import { useState } from 'react';
import { Icon } from 'semantic-ui-react'

const InboundSync = ({ onSync, reload, listType, relative }) => {
    const [loadingConnect, setLoadingConnect] = useState<boolean>(false)
    const handleclick = async () => {
        setLoadingConnect(true)
        await onSync(relative.contacts && relative.contacts.contactId)
        setLoadingConnect(false)
        await reload(listType)
    }
    return (
        <a>
            <Icon
                name="sync"
                loading={loadingConnect}
                onClick={handleclick}
            ></Icon>
        </a>
    )
}

export default InboundSync
