import { Form, Modal, Dropdown, Button, Dimmer, Loader } from 'semantic-ui-react';
import * as React from 'react';
import { useState } from 'react';
import { createResidentCalendarEvent, deleteResidentCalendarEvent, fetchResidentCalendarEvents, updateResidentCalendarEvent } from '../../services/Registrants';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { ResidentCalendar } from '../../types';
import { StaffCalendar } from '../../types/staffCalendar';
import { createStaffCalendarEvent, deleteStaffCalendarEvent, updateStaffCalendarEvent } from '../../services/StaffCalendar';
import { toast } from 'react-toastify';
import { fetchOneResidentCalendar } from '../../services/RequestInstances';
import { sortByKey } from '../../util/sortData';
import { sendToast } from '../../util';
import { createNotepadEvent, deleteNotepadEvent, fetchNotepadEvent, updateNotepadEvent } from '../../services/Notepad';

interface Props {
    setLoader: (value: boolean) => void
    showModal: boolean
    closeForm: () => void
    isEdit: boolean
    residentCalendarData: Partial<ResidentCalendar | StaffCalendar>
    setResdientCalendarDataField: (key: keyof ResidentCalendar, value: any) => void
    displayError: (message: string) => void
    refresh: () => Promise<void>
    resetResidentCalendarData: () => void
    residentOptions: { key: string, value: string, text: string }[]
    staffOptions: { key: string, value: string, text: string }[]
    duration: number
    setDuration: (value: number) => void
    isStaff?: boolean
    dropdownLoading?: boolean
    disableEditAndDelete?: boolean
    pageSource: string
}

const ResidentCalendarForm: React.FC<Props> = ({
    setLoader,
    showModal,
    closeForm,
    isEdit,
    residentCalendarData,
    setResdientCalendarDataField,
    displayError,
    refresh,
    resetResidentCalendarData,
    residentOptions,
    staffOptions,
    duration,
    setDuration,
    isStaff,
    dropdownLoading = false,
    disableEditAndDelete = false,
    pageSource
}) => {
    const [deleteResidentCalendarConfirmation, setDeleteResidentCalendarConfirmation] = useState<boolean>(false)
    const [instanceEventEdit, setInstanceEventEdit] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const validResdientCalendarForm = () => {
        if (isStaff) {
            return (
                residentCalendarData &&
                residentCalendarData.text &&
                residentCalendarData.text.length > 0 &&
                residentCalendarData.dateTimeString &&
                residentCalendarData.dateTimeString.length > 0 &&
                ((residentCalendarData.registrantIds &&
                    residentCalendarData.registrantIds.length > 0 &&
                    Array.isArray(residentCalendarData.registrantIds)) ||
                    (residentCalendarData.staffId &&
                        residentCalendarData.staffId.length > 0)) &&
                residentCalendarData.endDateTimeString &&
                residentCalendarData.endDateTimeString.length > 0
            )
        } else {
            return (
                residentCalendarData &&
                residentCalendarData.text &&
                residentCalendarData.text.length > 0 &&
                residentCalendarData.dateTimeString &&
                residentCalendarData.dateTimeString.length > 0 &&
                ((residentCalendarData.registrantIds && residentCalendarData.registrantIds.length > 0 && Array.isArray(residentCalendarData.registrantIds)) ||
                    (residentCalendarData.staffId && residentCalendarData.staffId.length > 0)) &&
                residentCalendarData.location &&
                residentCalendarData.location.length > 0 &&
                residentCalendarData.endDateTimeString &&
                residentCalendarData.endDateTimeString.length > 0 &&
                duration > 0
            )
        }
    };

    React.useEffect(() => {
        if (!residentCalendarData.dateTimeString) {
            setResdientCalendarDataField("dateTimeString", moment().format('YYYY-MM-DDTHH:mm:ss'))
        }
    }, [showModal])

    React.useEffect(() => {
        fetchResidentCalendarId();
    }, [isEdit]);

    console.log("PageSource",pageSource)
    const fetchResidentCalendarId = async () => {
        try {
            if (isEdit && residentCalendarData && residentCalendarData._id && residentCalendarData.endDateTimeString && residentCalendarData.dateTimeString && residentCalendarData.registrantIds && residentCalendarData.registrantIds.length > 0) {
                setModalLoading(true);
                const data = pageSource === "Notepad" ? await fetchNotepadEvent(residentCalendarData.registrantIds[0], residentCalendarData.dateTimeString, residentCalendarData.endDateTimeString, residentCalendarData._id) : await fetchResidentCalendarEvents(residentCalendarData.registrantIds[0], residentCalendarData.dateTimeString, residentCalendarData.endDateTimeString, residentCalendarData._id);
                if (data && data.length > 0 && data[0].instanceId) {
                    setInstanceEventEdit(true);
                } else {
                    setInstanceEventEdit(false);
                }
                setModalLoading(false);
            }
        } catch (error) {
            setModalLoading(false);
            console.error(error);
        }
    }

    const handleCreateForStaffAndResident = async () => {
        try {
            if (!isStaff) {
                const data = residentCalendarData as Partial<ResidentCalendar>;
                !isEdit
                    ? (pageSource && pageSource === "Notepad" ? await createNotepadEvent(data) : await createResidentCalendarEvent(data))
                    : (pageSource && pageSource === "Notepad" ? await updateNotepadEvent(data) : await updateResidentCalendarEvent(data));
            } else {
                const data = residentCalendarData as Partial<StaffCalendar>;
                !isEdit
                    ? await createStaffCalendarEvent(data)
                    : await updateStaffCalendarEvent(data);
            }
            !isEdit ?
                toast.success('Event has been registered!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                }) :
                toast.success('Event has been updated!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : isEdit ? "Failed to update notepad event" : "Failed to create notepad event";
            sendToast('error', errorMessage)
        }
    }

    const handleSaveResidentCalendar = async () => {
        setLoader(true);
        closeForm();
        try {
            await handleCreateForStaffAndResident()
            displayError("");
        } catch (error) {
            displayError(error instanceof Error ? error.message : 'Failed to create notepad event');
            console.error(error);
            setLoader(false);
        }
        // refresh notepad events
        await refresh();
        clearResidentCalendarFormFields()
        setLoader(false);
    };

    const handleDeleteResidentCalendar = async (id) => {
        closeForm();
        setLoader(true);
        try {
            pageSource === "Notepad" ? await deleteNotepadEvent(id) : isStaff ? await deleteStaffCalendarEvent(id) : await deleteResidentCalendarEvent(id);
        } catch (error) {
            displayError('Could not delete the event');
            setLoader(false);
        }
        await refresh();
        setLoader(false);
    };

    const clearResidentCalendarFormFields = async () => {
        await resetResidentCalendarData();
        setDuration(0);
    };

    return (
        <>
            <Modal
                as={Form}
                onClose={() => {
                    clearResidentCalendarFormFields()
                    closeForm();
                }}
                open={showModal}
                onSubmit={(e) => {
                    e.preventDefault(); // we prevent default to avoid refresh of page when form submits
                }}
            >
                <Modal.Header>{isEdit ? 'Edit calendar entry' : 'Create a calendar entry'}</Modal.Header>
                <Dimmer active={modalLoading} inverted>
                    <Loader active={modalLoading} />
                </Dimmer>
                <Modal.Content>
                    <Modal.Description>
                        <Form.Field>
                            <label>Event Name</label>
                            <Form.Input
                                required={true}
                                value={residentCalendarData.text || ''}
                                placeholder="Event Name"
                                onChange={(e) => setResdientCalendarDataField('text', e.currentTarget.value)}
                                readOnly={instanceEventEdit || disableEditAndDelete}
                           />
                        </Form.Field>
                        <Form.Field>
                            <label>Residents</label>
                            <Dropdown
                                loading={!residentOptions.length || dropdownLoading ? true : false}
                                closeOnEscape
                                value={residentCalendarData.registrantIds || []}
                                multiple
                                clearable
                                search
                                scrolling
                                selection
                                options={sortByKey(residentOptions)}
                                onChange={(e, d) => setResdientCalendarDataField('registrantIds', d.value)}
                                disabled={instanceEventEdit || disableEditAndDelete}
                           />
                        </Form.Field>
                        <Form.Field>
                            <label>Staff</label>
                            <Dropdown
                                loading={!staffOptions.length || dropdownLoading ? true :  false}
                                closeOnEscape
                                value={residentCalendarData.staffId || ''}
                                clearable
                                search
                                scrolling
                                selection
                                options={sortByKey(staffOptions)}
                                onChange={(e, d) => { setResdientCalendarDataField('staffId', d.value) }}
                                disabled={instanceEventEdit || disableEditAndDelete}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Location</label>
                            <Form.Input
                                required={true}
                                value={residentCalendarData.location || ''}
                                placeholder="Location"
                                onChange={(e) => setResdientCalendarDataField('location', e.currentTarget.value)}
                                readOnly={instanceEventEdit || disableEditAndDelete}
                                />
                        </Form.Field>
                        <Form.Field>
                            <label>Date and Time</label>
                            <DatePicker
                                style={{ border: '1px solid #183466' }}
                                placeholderText="Time and Date"
                                dateFormat="M.d.Y h:mm aa"
                                showTimeSelect
                                timeIntervals={60}
                                selected={Date.parse(residentCalendarData.dateTimeString || new Date().toISOString())}
                                onChange={(date: Date) => {
                                    const dateTimeString = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                    setResdientCalendarDataField(
                                        'dateTimeString',
                                        dateTimeString
                                    );
                                }}
                                onCalendarOpen={() => {
                                    const dateTimeString = moment().hour(9).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
                                    if (!residentCalendarData.dateTimeString) {
                                        setResdientCalendarDataField(
                                            'dateTimeString',
                                            dateTimeString
                                        );
                                    }
                                }}
                                readOnly={instanceEventEdit || disableEditAndDelete}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Duration in Minutes</label>
                            <Form.Input
                                required={true}
                                type="number"
                                min="0"
                                value={duration || ''}
                                placeholder="Duration in Minutes"
                                onChange={(e) => {
                                    const mins = Number(e.currentTarget.value);
                                    const endDateTimeString = moment(residentCalendarData.dateTimeString)
                                        .add(mins, 'm')
                                        .format('YYYY-MM-DDTHH:mm:ss');
                                    setDuration(mins);
                                    setResdientCalendarDataField('endDateTimeString', endDateTimeString);
                                }}
                                readOnly={instanceEventEdit || disableEditAndDelete}
                            />
                        </Form.Field>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {isEdit ? (
                        <Button
                            color={'red'}
                            onClick={(e) => {
                                e.preventDefault();
                                setDeleteResidentCalendarConfirmation(true)
                            }}
                            disabled={disableEditAndDelete || (isEdit && residentCalendarData.isKioskEvent) || false}
                        >
                            Delete
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button
                        disabled={disableEditAndDelete || instanceEventEdit  || validResdientCalendarForm() ? false : true}
                        primary
                        type="submit"
                        onClick={() => handleSaveResidentCalendar()}
                    >
                        Submit
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
                onClose={() => {
                    setDeleteResidentCalendarConfirmation(false);
                }}
                size={'tiny'}
                open={deleteResidentCalendarConfirmation}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Modal.Content>
                    <Modal.Description>
                        <b>
                            Are you sure you want to delete this event? This will delete the calendar entry for ALL the selected residents.
                        </b>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setDeleteResidentCalendarConfirmation(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={async (e) => {
                            e.preventDefault();
                            setDeleteResidentCalendarConfirmation(false);
                            residentCalendarData._id && (await handleDeleteResidentCalendar(residentCalendarData._id));
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ResidentCalendarForm;