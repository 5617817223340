import React from 'react'
import FacilitiesForm, { FacilityFormValues } from '../FacilitiesForm'
import { createFacility } from '../../../../services/Facilities'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchA4hFacilities, fetchAllActiveFacilities } from '../../../../services/Facilities'


interface State {
    isSaving: boolean
    error: string | null
    facilityOptions: object[]
}

class FacilitiesCreate extends React.Component<RouteComponentProps, State> {
    state = {
        isSaving: false,
        error: null,
        facilityOptions: []
    }

    async componentDidMount() {
        this.setState({
            isSaving: true,
            error: null,
        });
    
        try {
            const a4hFacilities = await fetchA4hFacilities();

            const facilities = await fetchAllActiveFacilities();
            /*
                Foreach a4h facility, if the ProfileName doesn't match with any of the facilities' AlexaProfile, push a4h facility to a new unmappedFacilities array
            */
            let unmappedFacilities :any[]= [];

            a4hFacilities.forEach((a4hFacility) => {
                if (!facilities.some(facility => facility.AlexaProfile === a4hFacility.Name)) {
                    unmappedFacilities.push(a4hFacility);                 
                }
            });

            console.log('unmappedFacilities', unmappedFacilities);

            const facilityOptions = unmappedFacilities.map((f) =>
                ({
                    key: f.id,
                    text: f.Name,
                    value: f.Name
                })
            );

            this.setState({
                isSaving: false,
                facilityOptions,
                error: null,
            });
        } catch (e) {
            console.error(e);
            const msg = e.message || 'Could not fetch Facilities.'
            this.setState({
                isSaving: false,
                error: msg,
            });
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FacilityFormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            await createFacility(data);

            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <FacilitiesForm
                title="Create Facility"
                cancel={this.goBack.bind(this)}
                facility={null}
                facilityOptions={this.state.facilityOptions}
                onSave={this.handleSave.bind(this)}
                {...this.state}
            />
        )
    }
}

export default withRouter(FacilitiesCreate)
