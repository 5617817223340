export const participationOptions = [
    {
        key: '0',
        text: 'Active',
        value: 'active',
    },
    {
        key: '1',
        text: 'Observed',
        value: 'passive',
    },
    {
        key: '2',
        text: 'Inactive',
        value: 'inactive',
    },
];

export const declineOptions = [
    {
        key: '0',
        text: 'Not Interested',
        value: 'Not Interested',
    },
    {
        key: '1',
        text: 'Appointment',
        value: 'Appointment',
    },
    {
        key: '2',
        text: 'Visitors',
        value: 'Visitors',
    },
    {
        key: '3',
        text: 'Out of Community',
        value: 'Out of Community',
    },
    {
        key: '4',
        text: 'In Bed',
        value: 'In Bed',
    },
    {
        key: '5',
        text: 'Health',
        value: 'Health',
    },
    {
        key: '5',
        text: 'Out Of Building',
        value: 'Out Of Building',
    },
    {
        key: '6',
        text: 'Refused',
        value: 'Refused',
    },
    {
        key: '7',
        text: 'Hospice',
        value: 'Hospice',
    }
];

export type ParticipationOptionsStatsType = {
    [Key in typeof participationOptions[number]['value']]: number;
};

export type DeclineOptionsStatsType = {
    [Key in typeof declineOptions[number]['value']]: number;
};

export const prntyrLink = "https://www.prntyr.com?speak2-user=true"