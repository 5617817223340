import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Dimmer, Form, Header, Input, Loader } from 'semantic-ui-react';
import { createKioskQuestion, getKioskQuestions, updateKioskQuestion } from '../../services/KioskQuestions';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { KioskQuestionsType } from '../../types/KioskQuestions';
import { toast } from 'react-toastify';

const KioskQuestions = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [formData, setFormData] = useState<KioskQuestionsType>({
        _id: '',
        question1: { questionText: '', enabled: false },
        question2: { questionText: '', enabled: false },
        question3: { questionText: '', enabled: false },
        yesNoQuestion1: { questionText: '', enabled: false },
        yesNoQuestion2: { questionText: '', enabled: false },
    });
    const [initialData, setInitialData] = useState<KioskQuestionsType>(formData);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    useEffect(() => {
        fetchKioskQuestions();
    }, []);

    const handleChange = (event, { name, value, checked }: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                enabled: checked !== undefined ? checked : prevState[name].enabled,
                questionText: value !== undefined ? value : prevState[name].questionText,
            },
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const { _id = '' } = formData;
            if (_id) {
                await updateKioskQuestion(formData);
                toast.success("Successfully udpated kiosk question", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                const { _id, ...dataWithoutId } = formData;
                await createKioskQuestion(dataWithoutId);
                toast.success("Successfully created kiosk question", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            fetchKioskQuestions();
        } catch (error) {
            console.error("error in submitting", error);
            toast.error(`Something went wrong`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setFormData(initialData); // Reset the form data to initial state
    };

    const fetchKioskQuestions = async () => {
        try {
            if (profile && profile.Facility) {
                setLoading(true);
                const questionResponse = await getKioskQuestions(profile.Facility);
                if (questionResponse && questionResponse.Result && questionResponse.Result.length > 0) {
                    // Map the fetched data to the formData format and set it
                    const mappedData = questionResponse.Result[0];
                    const formatedData = {
                        _id: mappedData._id,
                        question1: { questionText: mappedData.question1.questionText, enabled: mappedData.question1.enabled },
                        question2: { questionText: mappedData.question2.questionText, enabled: mappedData.question2.enabled },
                        question3: { questionText: mappedData.question3.questionText, enabled: mappedData.question3.enabled },
                        yesNoQuestion1: { questionText: mappedData.yesNoQuestion1.questionText, enabled: mappedData.yesNoQuestion1.enabled },
                        yesNoQuestion2: { questionText: mappedData.yesNoQuestion2.questionText, enabled: mappedData.yesNoQuestion2.enabled },
                    };
                    setFormData(formatedData);
                    setInitialData(formatedData);
                }
            }
        } catch (error) {
            console.error("Error in fetching data", error);
            toast.error('Error fetching Question', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const isFormDataValid = () => {
        for (const key in formData) {
            const questionText = formData[key].questionText;
            if (questionText && questionText.trim()) {
                return true; // Enable the button if at least one non-empty and non-space questionText is found
            }
        }
        return false; // Disable the button if all questionText values are null
    };

    return (
        <div>
            <Header as="h2">Kiosk Questions</Header>
            <Form>
                <Dimmer active={loading} inverted>
                    <Loader active={loading} />
                </Dimmer>
                <Form.Group>
                    <Form.Field width={1}>
                        <Header as="h4"> Turn Off/On</Header>
                    </Form.Field>
                    <Form.Field width={12}>
                        <Header as="h4"> Questions</Header>
                    </Form.Field>
                    <Form.Field width={2}>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={1}>
                        <Checkbox toggle checked={formData.question1.enabled} name='question1' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={12}>
                        <Input placeholder="Question 1.." name="question1" value={formData.question1.questionText} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={2}>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={1}>
                        <Checkbox toggle checked={formData.question2.enabled} name='question2' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={12}>
                        <Input placeholder="Question 2.." name="question2" value={formData.question2.questionText} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={2}>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={1}>
                        <Checkbox toggle checked={formData.question3.enabled} name='question3' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={12}>
                        <Input placeholder="Question 3.." name="question3" value={formData.question3.questionText} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={2}>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={1}>
                        <Checkbox toggle checked={formData.yesNoQuestion1.enabled} name='yesNoQuestion1' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={12}>
                        <Input placeholder="Yes/No 1..." name="yesNoQuestion1" value={formData.yesNoQuestion1.questionText} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={2}>
                        <p>Yes/No only</p>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={1}>
                        <Checkbox toggle checked={formData.yesNoQuestion2.enabled} name='yesNoQuestion2' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={12}>
                        <Input placeholder="Yes/No 2..." name="yesNoQuestion2" value={formData.yesNoQuestion2.questionText} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field width={2}>
                        <p>Yes/No only</p>
                    </Form.Field>
                </Form.Group>
                <Button type="submit" disabled={!isFormDataValid()} onClick={handleSubmit}>Submit</Button>
                <Button type="button" onClick={handleCancel}>Cancel</Button>
            </Form>
        </div>
    );
};

export default KioskQuestions;
