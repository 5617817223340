import { StaffCalendar } from "../types/staffCalendar";
import API from "./API";

export async function createStaffCalendarEvent(staffCalendarEntry: Partial<StaffCalendar>) {
    const response = await API.lambdaPost('/staffCalendar/add', staffCalendarEntry);
    if (!response) throw new Error("Could not create a notepad entry");
    return response;
}

export async function updateStaffCalendarEvent(staffCalendarEntry: Partial<StaffCalendar>) {
    const response = await API.lambdaPost('/staffCalendar/update', {
        _id: staffCalendarEntry._id,
        text: staffCalendarEntry.text,
        dateTimeString: staffCalendarEntry.dateTimeString,
        registrantIds: staffCalendarEntry.registrantIds,
        location: staffCalendarEntry.location,
        endDateTimeString: staffCalendarEntry.endDateTimeString,
        staffId: staffCalendarEntry.staffId,
        residentName: staffCalendarEntry.residentName
    });
    if (!response) throw new Error("Could not update the notepad entry");
    return response;
}

export async function deleteStaffCalendarEvent(_id: string) {
    const response = await API.lambdaPost('/staffCalendar/delete', {
        _id
    })
    if (!response) throw new Error("Could not delete the notepad entry");
    return response;
}

export async function StaffCalendarRead(_id: string) {
    const response = await API.lambdaPost('/staffCalendar/read', {
        _id
    })
    if (!response) throw new Error("Staff Calendar Id is not Matched");
    return response;
}

export async function fetchStaffCalendarEvents(staffId: string | null, startDate: string, endDate: string) {
    const Filter = {
        staffId,
        startDate,
        endDate,
    }
    const response = await API.lambdaPost('/staffCalendar/list', { Filter });
    if (!response || !response.Result) throw new Error("Cannot fetch notepad entries");
    return response.Result;
}

export async function fetchMultipleStaffCalendarEvents(
    staffIds: string[],
    startDate: string,
    endDate: string,
) {
    const Filter = {
        staffIds,
        startDate,
        endDate,
    };
    const response = await API.lambdaPost('/staffCalendar/list', { Filter });
    if (!response || !response.Result) throw new Error('Cannot fetch notepad entries');
    return response.Result;
}