import { Analyze, PotentialSummaryData } from '../types/Analyze'
import API from './API'

export async function listResidentAnalyzeData(type: "menu" | "activity" | "summary",residentId?: string, startDate?: number | string, endDate?: number | string): Promise<{ data: Analyze[], totFacilityEvents: number }> {
    const res = await API.lambdaPost('/registrants/analyze/list', { residentId, startDate, endDate, type })
    return res
}

export async function fetchPotentialSummaryData(startDate: string, endDate: string): Promise<PotentialSummaryData> {
    const res = await API.lambdaPost('/graphs/potentialCalendarAttendees', { startDate, endDate })
    return res
}