import { fetchAllPaginatedData } from '../util'
import API from './API'

export async function listCards (facilityId:string|null=null, filters) {
    const params:any = {}
    if (facilityId) params.Facility = facilityId
    if (filters) {
        if (filters.resident !== 'all') params.registrantId = filters.resident
        if (filters.startTimestamp) params.startTimestamp = filters.startTimestamp
        if (filters.endTimestamp) params.endTimestamp = filters.endTimestamp
    }

    if (!params.Facility && !params.registrantId) {
        throw new Error('Insufficient params to fetch facility registrants')
    }

    if ((params.startTimestamp && !params.endTimestamp) || (!params.startTimestamp && params.endTimestamp)) {
        throw new Error('Insufficient params to fetch facility registrants - only one of start and end timestamps were provided')
    }

    console.log('Params for checkin list: ', params)

    // fetch paginated checkin cards
    const checkinApiCall = async (params: any) => { return await API.lambdaPost('/checkin/list', params); };
    const checkinCards = await fetchAllPaginatedData(checkinApiCall, params);
    return checkinCards;
    
}

export async function updateCard (newCard) {
    if (newCard._id && newCard.column && newCard.prev_col && newCard.timestamp) {
        const response = await API.lambdaPut('/checkin/update', newCard)
        return response
    } else {
        throw new Error('Missing checkin card update params!')
    }
}

export async function createCard (newCard) {
    if (newCard.column && newCard.timestamp && newCard.registrant && newCard.Facility && newCard.registrantId) {
        const response = await API.lambdaPost('/checkin/create', newCard)
        return response
    } else {
        throw new Error('Missing create checkin card params!')
    }
}
