import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Select, Checkbox, Header, Segment, Button, Popup, Message } from 'semantic-ui-react';
import { ReqAction } from './reducersData';
import { User } from '../../../types';
import { CustomFieldsFormula, ReqState } from '../../../types/Service';
import CustomFields from '../../../components/CustomFields';
import { toast } from 'react-toastify';
import { sortByKey } from '../../../util/sortData';
import { isFacilityAdmin } from '../../../services/Permissions';
import { cookbook } from '../../../util';

interface Props {
    dispatch: React.Dispatch<ReqAction>;
    formValues: ReqState;
    staffList?: User[];
    source?: string;
}

const Requirements: FC<Props> = ({ dispatch, formValues, staffList = [], source}) => {
    const [customFieldsDropdownValues, setCustomFieldsDropdownValues] = React.useState<any[]>([
        { key: 'internalCost', text: 'Internal cost', value: "internalCost" },
        { key: 'externalCost', text: 'External cost', value: "externalCost"},
        { key: 'pointsAttainment', text: 'Points attainment', value: "pointsAttainment" }
    ]);
    const [customFields, setCustomFields] = React.useState<CustomFieldsFormula[]>(formValues.customFields || []);
    const [inProgressCustomField, setInProgressCustomField] = React.useState<CustomFieldsFormula | null>(null);
    const [clearState, setClearState] = React.useState(false)

    React.useEffect(() => {
        setCustomFields(formValues.customFields || []);
        setCustomFieldsDropdownValues([
            { key: 'internalCost', text: 'Internal cost', value: "internalCost" },
            { key: 'externalCost', text: 'External cost', value: "externalCost" },
            { key: 'pointsAttainment', text: 'Points attainment', value: "pointsAttainment" }
        ]);
      }, [formValues.customFields, formValues.internalCost, formValues.externalCost, formValues.pointsAttainment]);
      

    const categoryOptions = [
        { key: 'platinum', text: 'Platinum', value: 'platinum' },
        { key: 'gold', text: 'Gold', value: 'gold' },
        { key: 'silver', text: 'Silver', value: 'silver' },
        { key: 'bronze', text: 'Bronze', value: 'bronze' },
    ];

    const handleInputChange = (e: React.FormEvent<HTMLInputElement> | null, { name, value, checked }: any) => {
        if (name === "waitingList") {
            dispatch({
                type: "HANDLE-INPUT-CHANGE",
                payload: {
                    key: name,
                    value: checked || false
                }
            });
        } else {
            dispatch({
                type: "HANDLE-INPUT-CHANGE",
                payload: {
                    key: name as string,
                    value: value as any
                }
            });
        }
        const customFieldsValuedChanged = customFieldsDropdownValues.find(item => item.key === name);
        if (customFieldsValuedChanged) {
            customFieldsValuedChanged.value = value;
            const rest = customFieldsDropdownValues.filter(item => item.key !== name);
            setCustomFieldsDropdownValues([...rest, customFieldsValuedChanged]);
        }
    };

    const onFormulaChange = (formula: CustomFieldsFormula) => {
        setInProgressCustomField(formula);
    };

    const sendWarningMessage = (message: string) => {
        toast.warn(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    };

    const createButtonClickHandler = () => {
        if (inProgressCustomField && inProgressCustomField.formulaName && inProgressCustomField.leftOperandName && inProgressCustomField.rightOperandName && inProgressCustomField.operator) {
            const leftHandOperandValue = formValues[inProgressCustomField.leftOperandName];
            const rightHandOperandValue = formValues[inProgressCustomField.rightOperandName];
            if (!leftHandOperandValue || !rightHandOperandValue) {
                sendWarningMessage(`Please fill the value for ${!leftHandOperandValue ?  camelCaseToHumanReadable(inProgressCustomField.leftOperandName) : camelCaseToHumanReadable(inProgressCustomField.rightOperandName)}`);
                return;
            }
            const updatedCustomFields = [...customFields, inProgressCustomField];
            setCustomFields(updatedCustomFields);
            setInProgressCustomField(null);
            setClearState(true);
            handleInputChange(null, { name: "customFields", value: updatedCustomFields, checked: false })
        } else {
            sendWarningMessage("Please fill in all the details.")
        }
    }

    function camelCaseToHumanReadable(str) {
        // Add a space before each uppercase letter, then capitalize the first letter of the result
        return str
          // Insert a space before all caps (assumes the first letter is lowercase)
          .replace(/([A-Z])/g, ' $1')
          // Capitalize the first letter
          .replace(/^./, function(match) {
            return match.toUpperCase();
          });
      }
      

    const renderErrorMessage = (leftOperandValue, leftOperandName, rightOperandValue, rightOperandName) => {
        if (!leftOperandValue && !rightOperandValue) {
            return `Please fill the value for ${camelCaseToHumanReadable(leftOperandName)} and ${camelCaseToHumanReadable(rightOperandName)}`
        } else if(!leftOperandValue) {
            return `Please fill the value for ${camelCaseToHumanReadable(leftOperandName)}`
        } else if(!rightOperandValue) {
            return `Please fill the value for ${camelCaseToHumanReadable(rightOperandName)}`
        }
    }

    const renderFormula = (customField: CustomFieldsFormula) => {
        const processOperand = (operand: string | number) => {
            let value: number;

            if (typeof operand === "string") {
                value = parseFloat(operand.trim()); // Remove spaces if it's a string
            } else {
                value = operand;
            }

            return isNaN(value) ? null : value; // Check for NaN
        };

        const leftOperandValue = processOperand(formValues[customField.leftOperandName]);
        const rightOperandValue = processOperand(formValues[customField.rightOperandName]);
        const operator = customField.operator;
        const formulaName = customField.formulaName;

        let result: number | null = null;
        let operationString: string | null = null;
    
        if (leftOperandValue !== null && rightOperandValue !== null && operator) {
            switch (operator) {
                case 'add':
                    result = leftOperandValue + rightOperandValue;
                    operationString = '+';
                    break;
                case 'subtract':
                    result = leftOperandValue - rightOperandValue;
                    operationString = '-';
                    break;
                case 'multiply':
                    result = leftOperandValue * rightOperandValue;
                    operationString = '*';
                    break;
                case 'divide':
                    if (rightOperandValue !== 0) {
                        result = leftOperandValue / rightOperandValue;
                        operationString = '/';
                    } else {
                        result = null;
                        operationString = null;
                    }
                    break;
                default:
                    break;
            }
        }
    
        const getPopoverContent = (operandName: string) => {
            return `${operandName}`;
        };
    
        if (result !== null && operationString !== null) {
            return (
                <div style={{ display: "flex", gap: "10px", marginTop: "10px", alignItems: "center" }}>
                    <div>
                        <Header as={"h5"}>{formulaName.trim()}:</Header>
                    </div>
                    <div>
                        <Popup
                            content={getPopoverContent(customField.leftOperandName)}
                            trigger={<span>{leftOperandValue}</span>}
                            size='tiny'
                        />
                        <span style={{ fontWeight: "bold" }}>{operationString}</span>
                        <Popup
                            content={getPopoverContent(customField.rightOperandName)}
                            trigger={<span>{rightOperandValue}</span>}
                            size='tiny'
                        />
                       <span style={{ fontWeight: "bold" }}>=</span>
                        <span style={{ fontWeight: "bold" }}>{result}</span>
                    </div>
                </div>
            );
        } else {
            return <Message negative style={{ marginTop: '5px' }}>
                {renderErrorMessage(leftOperandValue, customField.leftOperandName, rightOperandValue, customField.rightOperandName)}
            </Message>;
        }
    }
    

    return (
        <div style={{ paddingTop: "15px" }}>
            <Form.Group widths="equal">
                <Form.Field
                    control={Input}
                    name="internalCost"
                    label={source === cookbook ? "Our Cost" : "Internal Cost"}
                    placeholder={source === cookbook ? "Our Cost" : "Internal Cost"}
                    onChange={handleInputChange}
                    value={formValues.internalCost}
                    type='number'
                    min={0}
                    style={{ width: source === cookbook ? '50%' : '100%' }}
                />
                {source !== cookbook && <Form.Field
                    control={Input}
                    name="internalCostRequirement"
                    label="Minimum Requirement"
                    placeholder="Minimum Requirement"
                    onChange={handleInputChange}
                    readOnly
                    value={formValues.internalCostRequirement}
                />}
            </Form.Group>
            <Form.Group widths="equal">
               <Form.Field
                    control={Input}
                    name="externalCost"
                    label={source === cookbook ? "Pricing" : "External Cost"}
                    placeholder={source === cookbook ? "Pricing" : "External Cost"}
                    onChange={handleInputChange}
                    value={formValues.externalCost}
                    type='number'
                    min={0}
                    style={{ width: source === cookbook ? '50%' : '100%' }}
                />
                {source !== cookbook && <Form.Field
                    control={Input}
                    name="externalCostRequirement"
                    label="Maximum Requirement"
                    placeholder="Maximum Requirement"
                    onChange={handleInputChange}
                    readOnly
                    value={formValues.externalCostRequirement}
                />} 
            </Form.Group>
            {source === cookbook ? (
                <>
                    <Form.Field
                        control={Input}
                        name="pointsAttainment"
                        label="Points"
                        placeholder="Points"
                        onChange={handleInputChange}
                        value={formValues.pointsAttainment}
                        min={0}
                        type='number'
                        style={{ width: "50%" }}
                    />
                    <Form.Field
                        control={Select}
                        name="premiumCategory"
                        label="Premium Category"
                        placeholder="Premium Category"
                        options={sortByKey(categoryOptions)}
                        onChange={handleInputChange}
                        value={formValues.premiumCategory}
                        style={{ width: "50%" }}
                    />
                </>
            ) : (
            <Form.Group widths="equal">
                <Form.Field
                    control={Input}
                    name="pointsAttainment"
                    label="Points Attainment"
                    placeholder="Points Attainment"
                    onChange={handleInputChange}
                    value={formValues.pointsAttainment}
                    min={0}
                    type='number'
                />
                <Form.Field 
                    control={Select}
                    name="premiumCategory"
                    label="Premium Category"
                    placeholder="Premium Category"
                    options={sortByKey(categoryOptions)}
                    onChange={handleInputChange}
                    value={formValues.premiumCategory}
                />
            </Form.Group>)}
            {source !== cookbook && 
            <Form.Group widths="equal">
                <Form.Field
                    control={Select}
                    name="expert"
                    label="Expert"
                    placeholder="Expert"
                    options={sortByKey(staffList.filter((staff)=>{
                        if(!isFacilityAdmin(staff)){
                            return staff;
                        }
                    }).map((staff)=>{
                        return {
                            key: staff._id,
                            text: `${staff.FirstName} ${staff.LastName}`,
                            value: staff._id,
                        }
                    }))}
                    onChange={handleInputChange}
                    value={formValues.expert}
                />
                <Form.Field
                    control={Select}
                    name="assistant"
                    label="Assistant"
                    options={sortByKey(staffList.filter((staff)=>{
                        if(!isFacilityAdmin(staff)){
                            return staff;
                        }
                    }).map((staff)=>{
                        return {
                            key: staff._id,
                            text: `${staff.FirstName} ${staff.LastName}`,
                            value: staff._id,
                        }
                    }))}
                    placeholder="Assistant"
                    onChange={handleInputChange}
                    value={formValues.assistant}
                />
            </Form.Group>}
            {source !== cookbook &&
            <Form.Group widths="equal" style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Field
                    control={Input}
                    name="tags"
                    label="Tags"
                    placeholder="Tags"
                    onChange={handleInputChange}
                    value={formValues.tags}
                    style={{ width: "50%" }}
                />
            </Form.Group>}
            {source !== cookbook &&
                <div style={{ marginTop: "25px", marginBottom:"15px" }}>
                    <Header as="h3">Custom Fields</Header>
                    <Segment>
                        <CustomFields dropdownValues={customFieldsDropdownValues} onFormulaChange={onFormulaChange} clearState={clearState} setClearStateToFalse={() => {
                            setClearState(false);
                        }} />
                        <Button onClick={createButtonClickHandler} style={{margin: "10px 0px"}} type="button" size='tiny' color="blue">Create Custom Field</Button>
                        <div style={{ marginTop: "10px" }}>
                            {customFields && customFields.length ? customFields.map((customField, index) => {
                                return (
                                    <div key={index}>
                                        {renderFormula(customField)}
                                    </div>
                                )
                            }) : null}
                        </div>
                    </Segment>
                </div>}
        </div>
    );
};

export default Requirements;
