import React, { FC } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { DetailsState } from '../../../types/Service';

interface Props {
    details: DetailsState;
    setDetails: React.Dispatch<React.SetStateAction<DetailsState>>;
}

const Details: FC<Props> = ({ details, setDetails }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleStartDateChange = (date) => {
        setDetails((prevState) => ({
            ...prevState,
            activeStartDate: date
        }));
    };

    const handleEndDateChange = (date) => {
        if (date >= details.activeStartDate || !details.activeStartDate) {
            setDetails((prevState) => ({
                ...prevState,
                activeEndDate: date
            }));
        }
    };
    return (
        <Form>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <label htmlFor="startDate" style={{fontSize: '0.92857143em', fontWeight:'bold'}}>Start Date</label>
                        <DatePicker
                            style={{ border: "1px solid #183466", marginTop:'4px' }}
                            placeholderText="Start Date"
                            dateFormat="M.d.Y"
                            selectsStart
                            label="date"
                            selected={details.activeStartDate ? new Date(details.activeStartDate) : null}
                            onChange={handleStartDateChange} // Use the separate handler function
                            minDate={new Date()}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <label style={{ fontSize: '0.92857143em', fontWeight: 'bold' }} htmlFor='Created By'>Created By</label>
                        <input placeholder="Created By"
                            value={details.createdBy} readOnly={true} disabled />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    <label htmlFor="endDate"  style={{fontSize: '0.92857143em', fontWeight:'bold'}}>End Date</label>
                        <DatePicker
                            style={{ border: "1px solid #183466", marginTop:'4px' }}
                            placeholderText="End Date"
                            dateFormat="M.d.Y"
                            selectsStart
                            selected={details.activeEndDate ? new Date(details.activeEndDate) : null}
                            onChange={handleEndDateChange} // Use the separate handler function
                            minDate={new Date()}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <label style={{ fontSize: '0.92857143em', fontWeight: 'bold' }} htmlFor='Updated By'>Updated By</label>
                        <input placeholder="Updated By"
                            value={details.updatedBy} readOnly={true} disabled={details.updatedBy ? false : true} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            fluid
                            label="Billing Code "
                            placeholder="Enter Billing Code"
                            name="billingCode"
                            value={details.billingCode}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            fluid
                            label="Integration Key"
                            placeholder="Enter Integration Key"
                            name="integrationKey"
                            value={details.integrationKey}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            fluid
                            label="Unique Id"
                            placeholder="Enter Unique Id"
                            name="uniqueId"
                            value={details.uniqueId}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Details;
