import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Loader, Modal } from 'semantic-ui-react'
import * as Promise from 'bluebird'
import { CSVLink } from 'react-csv'
import { Registrant,  RegistrantRelative,  UserProfile } from '../../../../types'
import { fetchAllPaginatedResidents, fetchPaginatedRegistrantA4HContacts } from '../../../../services/Registrants'
import { connect } from 'react-redux'
import { getSmartGadget } from '../../../../services/SmartGadgets'

interface Props {
    getAllSmartGadgets: () => Promise
    profile:UserProfile;
}

const ExportRegistrantButton: FC<Props> = (props) => {
    const [exportData, setExportData] = useState([])
    const [exportDataLoading, setExportDataLoading] = useState(false)
    const [exportOptionsModal, setExportOptionsModal] = useState<boolean>(false);
    const [exportOptions, setExportOptions] = useState<{
        includeContacts: boolean;
        includeNonResidentialRoomOccupants: boolean;
    }>({
        includeContacts: false,
        includeNonResidentialRoomOccupants: false,
    })

    const excelRef = useRef()

    useEffect(() => {
        if (exportData.length > 0) {
            // WIP see how you can avoid this issue instead of using ts-ignore
            // @ts-ignore
           excelRef && excelRef.current && excelRef.current.link && excelRef.current.link.click()
        }
    }, [exportData])
    
   const  getAllSmartGadgets = async (registrants:Registrant[]) => {
        const residentsWithGadgetInfo = await Promise.map(registrants, async (e) => {
            const gadgetNames = await getSmartGadget(e.Unit && e.Unit.a4hRoomId)
            e.gadgetNames = gadgetNames
            return e
        }, {
            concurrency: 5
        })
        return residentsWithGadgetInfo
    }

    const handleExport = async (registrants) => {
        setExportDataLoading(true)
        // const gadgetsData = await props.getAllSmartGadgets()
        let arrObj = await Promise.map(registrants, async (e) => {
            let data: RegistrantRelative[] = []
            if (exportOptions.includeContacts) {
                data = await fetchPaginatedRegistrantA4HContacts(e._id);
            }
            
            return {
                'Resident Name': `${e.FirstName} ${e.LastName}`,
                'Resident Room': (e.Unit && e.Unit.Name) || 'NA',
                'Alexa DSNs': e.gadgetNames
                    ? e.gadgetNames.map((e) => {
                        const number = e.serialNumber;
                        return number.toString();
                    })
                    : 'NA',
                ...(exportOptions.includeContacts && {
                    Contacts: (data.length && data[0]) ? data
                        .map((e) => {
                            const str = `${e.RelativeName}: ${e.Contact}`;
                            if (e.Contact) {
                                return str;
                            } else {
                                return 'NA';
                            }
                        })
                        .join('\n') : "NA"
                })
            }
        }, {
            concurrency: 5
        })
        setExportDataLoading(false)
        setExportData(arrObj)
    }
    const exportButtonClickHandler = async () => {
        setExportDataLoading(true)
        const res = await fetchAllPaginatedResidents({ IncludeNonResidentialRoomOccupants: exportOptions.includeNonResidentialRoomOccupants })
        await getAllSmartGadgets(res)
        handleExport(res)
    }

    return (
        <>
            <Dimmer active={exportDataLoading} inverted>
                <Loader active={exportDataLoading} />
            </Dimmer>
            <Button content={exportDataLoading ? 'Loading CSV...' : 'Export'} disabled={exportDataLoading} basic onClick={() => setExportOptionsModal(true)} />
            <CSVLink
                data={exportData}
                asyncOnClick={true}
                filename="Residents.csv"
                ref={excelRef}
                style={{
                    display: 'none',
                }}
            />
            <Modal
                open={exportOptionsModal}
                size='tiny'
                onClose={() => {
                    setExportOptionsModal(false);
                }}
            >
                <Modal.Header>Export Residents</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Please select the options you want to include in the export</p>
                    </Modal.Description>
                    <div className="spacing">
                        <Checkbox
                            label='Include Contacts'
                            checked={exportOptions.includeContacts}
                            onChange={(e, { checked }) => {
                                setExportOptions({
                                    ...exportOptions,
                                    includeContacts: checked || false,
                                });
                            }}
                        />
                    </div>
                    <div className="lessSpacing">
                        <Checkbox
                            label='Include Hidden Residents'
                            checked={exportOptions.includeNonResidentialRoomOccupants}
                            onChange={(e, { checked }) => {
                                setExportOptions({
                                    ...exportOptions,
                                    includeNonResidentialRoomOccupants: checked || false,
                                });
                            }}
                        />
                    </div>
                    {/* <div className="lessSpacing">
                        <Checkbox
                            label='Include Smart Device Information'
                            checked={exportOptions.includeSmartGadgets}
                            onChange={(e, { checked }) => {
                                setExportOptions({
                                    ...exportOptions,
                                    includeSmartGadgets: checked || false,
                                });
                            }}
                        />
                    </div> This should be uncommented and used when the queue for the get gadgets API is built*/}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Submit'
                        onClick={async () => {
                            setExportOptionsModal(false);
                            await exportButtonClickHandler();
                        }}
                    />
                    <Button
                        content='Cancel'
                        onClick={() => {
                            setExportOptionsModal(false);
                        }}
                    />
                </Modal.Actions>
            </Modal>
        </>
    )
}

function mapStateToProps({ authReducer }) {
    return {
        currentUser: authReducer.currentUser,
        profile: authReducer.profile,
    }
}

export default connect(mapStateToProps)(ExportRegistrantButton)
