import API from './API'

import { ScheduledNotificationType } from '../types/scheduledNotification'

export async function getScheduledNotifications({ facilityId }) {
    const response = await API.lambdaPost('/scheduled-notifications/get', { facilityId })
    return response
}

export async function updateScheduledNotifications(scheduledNotifications: ScheduledNotificationType): Promise<void> {
    const response = await API.lambdaPut('/scheduled-notifications/update', scheduledNotifications)
    return response
}
