import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';
import NotepadServices from '../../../components/NotepadServices';
import ResidentCalendarPage from '../ResidentCalendar';
import './style.less';

const Notepad = () => {
    const [view, setView] = useState<string>('list');

    return (
        <div style={{ padding: '0px 20px' }}>
            <div className="view-icons">
                <Image
                    style={{ cursor: 'pointer' }}
                    onClick={() => setView('list')}
                    src={`${process.env.PUBLIC_URL}/notepadFiltericons/list.svg`}
                />
                <Image
                    style={{ cursor: 'pointer' }}
                    onClick={() => setView('calendar')}
                    src={`${process.env.PUBLIC_URL}/notepadFiltericons/calendar.svg`}
                />
            </div>
            {view === 'list' && <NotepadServices />}
            {view === 'calendar' && <ResidentCalendarPage />}
        </div>
    );
};

export default Notepad;
