const kanbanInitData = {
    columns: [
        {
            key: 0,
            id: 0,
            title: 'Residents',
            cards: []
        },
        {
            key: 1,
            id: 1,
            title: 'Attended',
            cards: []
        },
        {
            key: 2,
            id: 2,
            title: 'Delivery',
            cards: []
        },
        {
            key: 3,
            id: 3,
            title: 'Declined',
            cards: []
        }
    ]
}

export default kanbanInitData