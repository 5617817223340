import React, { FC, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { Modal, Form, FormInput, Header, Button, FormTextArea } from 'semantic-ui-react';

import ReportSummaryAll from '../../components/Dashboard/ReportSummaryAll';
import { useAdminPanelRequests as useAssignableRequests } from '../../hooks/adminPanel';
import CustomTable from '../CustomTable';
import PrintExportIcons from '../PrintExportIcons';

import { fetchRequests } from '../../services/Requests';
import { updateRequestInstance } from '../../services/RequestInstances';
import { sendToast } from '../../util';
import { prntyrLink } from '../../util/data';

import { ReportSummaryFilters, Request, UserProfile } from '../../types';

import './style.less';

interface Props {
    profile: UserProfile | null;
    assignableRequestFilters: ReportSummaryFilters;
    setAssignableRequestFilters: React.Dispatch<React.SetStateAction<ReportSummaryFilters>>;
    facilityTimeZone: string;
    pageNo: number;
    setPageNo: React.Dispatch<React.SetStateAction<number>>;
    history: any;
}
// This page contains only assignable requests and no basic requests
const ReportsLiveRequests: FC<Props> = ({profile, assignableRequestFilters, setAssignableRequestFilters, facilityTimeZone, history, pageNo, setPageNo}) => {
    const [showRequestDetailModal, setShowRequestDetailModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<Request | null>(null);
    const [editedFields, setEditedFields] = useState<Partial<Request>>({});
    const [isLoading, setIsLoading] = useState(false);
    
    const sortBy = [{ RequestedTime: 'asc' }];
    const { requests: assignableRequests, isFetching: assignableIsFetching, totalDataLength: assignableTotalDataLength, isPaginating: assignableIsPaginating } = useAssignableRequests(
        assignableRequestFilters,
        sortBy,
        false,
        true,
        pageNo,
        20,
        history,
    );
    const allRequests: any[] = [...assignableRequests]; // using any because its causing problems with RequestsInstances merged data
 
    // sort by descending order based on date created
    if (facilityTimeZone) {
        allRequests.sort(function (a, b) {
            const dateA = moment.tz(a.RequestedTime, facilityTimeZone);
            const dateB = moment.tz(b.RequestedTime, facilityTimeZone);
            if (dateB.isBefore(dateA)) {
                return -1; // if b is in the past, put a first
            } else if (dateA.isBefore(dateB)) {
                return 1; // if a is in the past, put b first
            } else {
                return 0;
            }
        });
    }


    const handleEditIconClick = (request: Request) => {
        setShowRequestDetailModal(true);
        setSelectedRequest(request);
        // first check if the request has internal cost, external cost, billing code because else use the default values from the service
        setEditedFields({
            InternalCost: request.InternalCost || request.Service && request.Service.defaults && request.Service.defaults.internalCost,
            ExternalCost: request.ExternalCost || request.Service && request.Service.defaults && request.Service.defaults.externalCost,
            BillingCode: request.BillingCode || request.Service && request.Service.details && request.Service.details.billingCode,
            ServiceAddOns: request.ServiceAddOns,
            Description: request.Description,
        });
        };

    const closedModal = () => {
        setShowRequestDetailModal(false);
    }

    const calculateTotalCost = (request: Request) => {
        // Total cost = external cost + total addons cost

        const serviceRequestAddons = request.ServiceAddOns ? request.ServiceAddOns : [];

        const totalAddOnsCost = serviceRequestAddons.reduce((acc, addon) => {
            return acc + addon.itemCost;
        }, 0);

        // CONTEXT: If External cost is update for a request it will add a new key in the request object with the update value. If its not updated then we take the value from the service object as this request is a service request. 

        // External Cost can be 0 that is the reason why below condition is used
        const requestHasExternalCostAndIsAValidNumber = request.ExternalCost !== undefined && !isNaN(parseFloat(request.ExternalCost));

        const parsedRequestExternalCost = request.ExternalCost ? parseFloat(request.ExternalCost) : 0; // parseFloat will change the string value for External cost to float.

        // Service external cost can be 0 that is the reason why below condition is used
        const serviceHasExternalCostAndIsValidNumber = request.Service && request.Service.defaults && !isNaN(parseFloat(request.Service.defaults.externalCost));

        const parsedServiceExternalCost = request.Service && request.Service.defaults.externalCost ? parseFloat(request.Service.defaults.externalCost) : 0;

        const externalCost = requestHasExternalCostAndIsAValidNumber
            ? parsedRequestExternalCost
            : serviceHasExternalCostAndIsValidNumber
                ? parsedServiceExternalCost : 0;


        const totalExternalCostAndTotalAddonsCost = externalCost + totalAddOnsCost;

        return totalExternalCostAndTotalAddonsCost;
    }    

    const tableData = allRequests.map((requestItem) => {
        return {
            EditIcon: <Button icon="edit" size="small" color={calculateTotalCost(requestItem) ? 'green' : undefined} onClick={() => handleEditIconClick(requestItem)} />, // show green color if total cost is available
            Registrant_FirstName: requestItem.Registrant_FirstName || '-',
            Registrant_LastName: requestItem.Registrant_LastName || '-',
            CarePlan: requestItem.CarePlan || '-',
            Name: requestItem.Name || '-',
            RequestDetails : requestItem.Description || '-',
            Apartment: requestItem.Unit_Name || '-',
            RequestType_Name: requestItem.RequestType_Name || '-',
            Department_Name: requestItem.Department_Name || requestItem.DepartmentName || '-',
            AcceptedByName: requestItem.AcceptedByName || '-',
            ClosedByName: requestItem.ClosedByName || '-',
            RequestNumber: requestItem._id || '-',
            EnteredDate: moment.tz(requestItem.DateAdded, facilityTimeZone).format('M/D/YY') || '-',
            EnteredTime: moment.tz(requestItem.DateAdded, facilityTimeZone).format('h:mm A') || '-',
            Category: requestItem.Category || '-',
            CreatedBy: requestItem.AddedByName || '-',
            AcceptedDate: requestItem.AcceptedTime ? moment.tz(requestItem.AcceptedTime, facilityTimeZone).format('M/D/YY') : '-',
            AcceptedTime: requestItem.AcceptedTime ? moment.tz(requestItem.AcceptedTime, facilityTimeZone).format('h:mm A') : '-',
            ClosedDate: requestItem.ClosedTime ? moment.tz(requestItem.ClosedTime, facilityTimeZone).format('M/D/YY') : '-',
            ClosedTime: requestItem.ClosedTime ? moment.tz(requestItem.ClosedTime, facilityTimeZone).format('h:mm A') : '-',
            UpdatedBy: requestItem.UpdatedByName || '-',
            UpdatedDate: requestItem.LastUpdated ? moment.tz(requestItem.LastUpdated, facilityTimeZone).format('M/D/YY') : '-',
            UpdateTime: requestItem.LastUpdated ? moment.tz(requestItem.LastUpdated, facilityTimeZone).format('h:mm A') : '-',
            ServiceName: requestItem.Service && requestItem.Service.name || '-',
        };
    });
    const exportData = allRequests.map((requestItem) => {
        return {
            RegistrantFirstName: requestItem.Registrant_FirstName || '-',
            RegistrantLastName: requestItem.Registrant_LastName || '-',
            CarePlan: requestItem.CarePlan || '-',
            Name: requestItem.Name || '-',
            Apartment: requestItem.Unit_Name || '-',
            RequestTypeName: requestItem.RequestType_Name || '-',
            ServiceName: requestItem.Service && requestItem.Service.name || '-',
            DepartmentName: requestItem.Department_Name || requestItem.DepartmentName || '-',
            RequestedDate: requestItem.RequestedTime ? (typeof requestItem.RequestedTime === "string" ? moment(requestItem.RequestedTime).format('M/D/YY') : moment.tz(requestItem.RequestedTime, facilityTimeZone).format('M/D/YY')) : '-',
            RequestedTime: requestItem.RequestedTime ? (typeof requestItem.RequestedTime === "string" ? moment(requestItem.RequestedTime).format('h:mm A') : moment.tz(requestItem.RequestedTime, facilityTimeZone).format('h:mm A')) : '-',
            AcceptedByName: requestItem.AcceptedByName || '-',
            ClosedByName: requestItem.ClosedByName || '-',
            RequestNumber: requestItem._id || '-',
            EnteredDate: moment.tz(requestItem.DateAdded, facilityTimeZone).format('M/D/YY') || '-',
            EnteredTime: moment.tz(requestItem.DateAdded, facilityTimeZone).format('h:mm A') || '-',
            Category: requestItem.Category || '-',
            CreatedBy: requestItem.AddedByName || '-',
            AcceptedDate: requestItem.AcceptedTime ? moment.tz(requestItem.AcceptedTime, facilityTimeZone).format('M/D/YY') : '-',
            AcceptedTime: requestItem.AcceptedTime ? moment.tz(requestItem.AcceptedTime, facilityTimeZone).format('h:mm A') : '-',
            ClosedDate: requestItem.ClosedTime ? moment.tz(requestItem.ClosedTime, facilityTimeZone).format('M/D/YY') : '-',
            ClosedTime: requestItem.ClosedTime ? moment.tz(requestItem.ClosedTime, facilityTimeZone).format('h:mm A') : '-',
            UpdatedBy: requestItem.UpdatedByName || '-',
            UpdatedDate: requestItem.LastUpdated ? moment.tz(requestItem.LastUpdated, facilityTimeZone).format('M/D/YY') : '-',
            UpdateTime: requestItem.LastUpdated ? moment.tz(requestItem.LastUpdated, facilityTimeZone).format('h:mm A') : '-',
        };
    })

    const onPrintClick = async () => {
        window.open(prntyrLink, '_blank');
    };

    const exportAllData = async () => {
        try {
            const alldata = await fetchAllData(assignableRequestFilters);
            const formatedData = alldata.map((requestItem) => {
                const serviceRequestAddons = requestItem.ServiceAddOns ? requestItem.ServiceAddOns : [];
                const requestServiceAddOnsString = requestItem.ServiceAddOns && requestItem.ServiceAddOns.map((addon) => {
                    return `${addon.itemName} - $${addon.itemCost}`;
                }).join(', ');
                const totalAddOnsCost = serviceRequestAddons.reduce((acc, addon) => {
                    return acc + addon.itemCost;
                }, 0);
                const totalExternalCostAndTotalAddonsCost = calculateTotalCost(requestItem); // this function returns the total cost of the request
                return {
                    RegistrantFirstName: requestItem.Registrant_FirstName || '-',
                    RegistrantLastName: requestItem.Registrant_LastName || '-',
                    CarePlan: requestItem.CarePlan || '-',
                    Name: requestItem.Name || '-',
                    RequestDetails: requestItem.Description || '-',
                    Apartment: requestItem.Unit_Name || '-',
                    RequestTypeName: requestItem.RequestType_Name || '-',
                    ServiceName: requestItem.Service && requestItem.Service.name || '-',
                    DepartmentName: requestItem.Department_Name || requestItem.DepartmentName || '-',
                    RequestedDate: requestItem.RequestedTime ? (typeof requestItem.RequestedTime === "string" ? moment(requestItem.RequestedTime).format('M/D/YY') : moment.tz(requestItem.RequestedTime, facilityTimeZone).format('M/D/YY')) : '-',
                    RequestedTime: requestItem.RequestedTime ? (typeof requestItem.RequestedTime === "string" ? moment(requestItem.RequestedTime).format('h:mm A') : moment.tz(requestItem.RequestedTime, facilityTimeZone).format('h:mm A')) : '-',
                    AcceptedByName: requestItem.AcceptedByName || '-',
                    ClosedByName: requestItem.ClosedByName || '-',
                    RequestNumber: requestItem._id || '-',
                    EnteredDate: moment.tz(requestItem.DateAdded, facilityTimeZone).format('M/D/YY') || '-',
                    EnteredTime: moment.tz(requestItem.DateAdded, facilityTimeZone).format('h:mm A') || '-',
                    Category: requestItem.Category || '-',
                    CreatedBy: requestItem.AddedByName || '-',
                    AcceptedDate: requestItem.AcceptedTime ? moment.tz(requestItem.AcceptedTime, facilityTimeZone).format('M/D/YY') : '-',
                    AcceptedTime: requestItem.AcceptedTime ? moment.tz(requestItem.AcceptedTime, facilityTimeZone).format('h:mm A') : '-',
                    ClosedDate: requestItem.ClosedTime ? moment.tz(requestItem.ClosedTime, facilityTimeZone).format('M/D/YY') : '-',
                    ClosedTime: requestItem.ClosedTime ? moment.tz(requestItem.ClosedTime, facilityTimeZone).format('h:mm A') : '-',
                    UpdatedByName: requestItem.UpdatedByName || '-',
                    UpdatedDate: requestItem.LastUpdated ? moment.tz(requestItem.LastUpdated, facilityTimeZone).format('M/D/YY') : '-',
                    UpdateTime: requestItem.LastUpdated ? moment.tz(requestItem.LastUpdated, facilityTimeZone).format('h:mm A') : '-',
                    InternalCost: requestItem.InternalCost ? requestItem.InternalCost : requestItem.Service && requestItem.Service.defaults && requestItem.Service.defaults.internalCost ? requestItem.Service.defaults.internalCost : '-',
                    ExternalCost: requestItem.ExternalCost ? requestItem.ExternalCost : requestItem.Service && requestItem.Service.defaults && requestItem.Service.defaults.externalCost ? requestItem.Service.defaults.externalCost : '-',
                    Premium: requestItem.Service && requestItem.Service.defaults && requestItem.Service.defaults.premiumCategory ? requestItem.Service.defaults.premiumCategory : '-',
                    Points: requestItem.Service && requestItem.Service.defaults && requestItem.Service.defaults.pointsAttainment ? requestItem.Service.defaults.pointsAttainment : '-',
                    BillingCode: requestItem.BillingCode ?  requestItem.BillingCode : requestItem.Service && requestItem.Service.details && requestItem.Service.details.billingCode ? requestItem.Service.details.billingCode : '-',
                    IntegrationKey: requestItem.Service && requestItem.Service.details && requestItem.Service.details.integrationKey ? requestItem.Service.details.integrationKey : '-',
                    UniqueId: requestItem.Service && requestItem.Service.details && requestItem.Service.details.uniqueId ? requestItem.Service.details.uniqueId : '-',
                    FederalTax: requestItem.Service && requestItem.Service.taxRates && requestItem.Service.taxRates.federal ? requestItem.Service.taxRates.federal : '-',
                    StateTax: requestItem.Service && requestItem.Service.taxRates && requestItem.Service.taxRates.state ? requestItem.Service.taxRates.state : '-',
                    LocalTax: requestItem.Service && requestItem.Service.taxRates && requestItem.Service.taxRates.local ? requestItem.Service.taxRates.local : '-',
                    OtherTax: requestItem.Service && requestItem.Service.taxRates && requestItem.Service.taxRates.other ? requestItem.Service.taxRates.other : '-',
                    AddOns: requestServiceAddOnsString || '-',
                    TotalAddonsCost: totalAddOnsCost,
                    TotalCost: totalExternalCostAndTotalAddonsCost,
                };
            }).sort(function (a, b) {
                const dateA = facilityTimeZone ? moment.tz(a.RequestedTime, facilityTimeZone) : moment(a.RequestedTime);
                const dateB = facilityTimeZone ? moment.tz(b.RequestedTime, facilityTimeZone) : moment(b.RequestedTime);

                if (dateB.isBefore(dateA)) {
                    return -1; // if b is in the past, put a first
                } else if (dateA.isBefore(dateB)) {
                    return 1; // if a is in the past, put b first
                } else {
                    return 0;
                }
            });
            return formatedData;
        } catch (error) {
            toast.error('Error fetching data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.log("error", error);
        }
    }

    async function fetchAllData(requestFilters: ReportSummaryFilters) {
        const allRequests: any = [];
        let page = 1;
        let requests; // Declare requests outside the loop
        do {
            const response = await fetchRequests(
                requestFilters.requestType,
                requestFilters.fromDate,
                requestFilters.toDate,
                null,
                requestFilters.staff === 'all' ? null : requestFilters.staff,
                requestFilters.resident === 'all' ? null : requestFilters.resident,
                requestFilters.department === 'all' ? null : requestFilters.department,
                requestFilters.disable === true ? true : null,
                page,
                100,
                undefined, 
                undefined, 
                facilityTimeZone
            );
            requests = response.requests; 
            allRequests.push(...requests);
            page++;
        } while (requests.length > 0);

        return allRequests;
    }

    const handleRequestUpdate = async () => {
        try {
            setIsLoading(true);
            if (selectedRequest) {
                await updateRequestInstance({ _id: selectedRequest._id, ...editedFields });
            }
        } catch (error) {
            console.log("error", error);
            sendToast('error', error instanceof Error ? error.message : "Error updating request");
        } finally {
            setIsLoading(false);
            setShowRequestDetailModal(false);
            setAssignableRequestFilters({ ...assignableRequestFilters, reload: assignableRequestFilters.reload + 1 });
        }
    };

    return (
        <div className="DashboardHome">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <ReportSummaryAll
                    filters={[assignableRequestFilters, '']} // Here '' this is the basicRequestFilters prop which we dont require in this assignableRequests page so its an empty string
                    handleFilterChangeAssignable={(updatedFilters) => {
                        setAssignableRequestFilters(updatedFilters);
                        setPageNo(1);
                    }}
                    handleFilterChangeBasic={(updatedFilters) => {
                        // This page contains only assignable requests but handleFilterChangeBasic is a prop which is required hence it is just empty
                    }}
                    shouldCreateRequestBeShown={false}
                    requests={allRequests}
                    history={history}
                    profile={profile}
                    isFetching={assignableIsFetching}
                    importedBy="ReportsLiveRequests"
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <PrintExportIcons
                        exportData={exportData}
                        exportFileName="reports_live_requests"
                        onPrintClick={onPrintClick}
                        exportAll={true}
                        fetchAll={exportAllData}
                        disablePrintButton={true}
                    />
                </div>
            </div>

            <CustomTable
                data={tableData}
                headers={[
                    '', // This is for the edit icon so no header is required
                    'FirstName',
                    'LastName',
                    'Care Plan',
                    'Request Name',
                    "Request Details",
                    'Apartment',
                    'Request Type',
                    "Service Name",
                    'Department',
                    'Accepted By',
                    'Closed By',
                    "Request Number",
                    "Entered Date", 
                    "Entered Time",
                    "Category", 
                    "Created By", 
                    "Accepted Date",  
                    "Accepted Time", 
                    "Closed Date", 
                    "Closed Time", 
                    "Updated By", 
                    "Updated Date", 
                    "Updated Time" ,
                ]}
                facilityTimezone={facilityTimeZone}
                totalDataLength={assignableTotalDataLength}
                pageNo={pageNo}
                setPageNo={setPageNo}
                isServerPagination={true}
                formatString=""
                formatKeys={[]}
                loading={assignableIsPaginating}
                rowKeys={[
                    'EditIcon',
                    'Registrant_FirstName', 
                    'Registrant_LastName', 
                    'CarePlan',
                    'Name',
                    "RequestDetails", 
                    'Apartment', 
                    'RequestType_Name', 
                    "ServiceName",
                    "Department_Name", 
                    'AcceptedByName', 
                    'ClosedByName', 
                    'RequestNumber',
                    "EnteredDate",  
                    "EnteredTime",
                    "Category",
                    "CreatedBy", 
                    "AcceptedDate", 
                    "AcceptedTime", 
                    "ClosedDate", 
                    "ClosedTime", 
                    "UpdatedBy", 
                    "UpdatedDate", 
                    "UpdateTime", 
                ]}
                setHeight='622px' // set height to match the height of the page
            />

            <Modal
                open={showRequestDetailModal}
                onClose={closedModal}
                size="large"
                closeIcon
                closeOnDimmerClick={true}>
                <Modal.Header>Edit Request</Modal.Header>
                <Modal.Content>
                    {selectedRequest && selectedRequest.Service ? <Form>
                        <FormInput label="Request name" value={selectedRequest.Name}/>
                        <FormInput label="Internal cost" value={editedFields.InternalCost} onChange={(e, { value = "" }) => {
                            setEditedFields({ ...editedFields, InternalCost: value });
                        }} />
                        <FormInput label="External cost" value={editedFields.ExternalCost} onChange={(e, { value = "" }) => {
                            setEditedFields({ ...editedFields, ExternalCost: value });
                        }} />
                        <FormInput label="Billing code" value={editedFields.BillingCode} onChange={(e, { value = "" }) => {
                            setEditedFields({ ...editedFields, BillingCode: value });
                        }} />
                        <FormTextArea label="Description" value={editedFields.Description} onChange={(e, { value = "" }) => {
                            setEditedFields({ ...editedFields, Description: value.toString() });
                        }} />
                        {editedFields.ServiceAddOns && editedFields.ServiceAddOns.length > 0 && (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: '5px 0px',
                                        padding: '0px 20px',
                                    }}
                                >
                                    <div>
                                        <label style={{ fontWeight: 'bold' }}>Item name</label>
                                    </div>
                                    <div>
                                        <label style={{ fontWeight: 'bold', marginRight: '120px' }}>Item cost</label>
                                    </div>
                                </div>
                                {editedFields.ServiceAddOns.map((item) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            margin: '5px 0px',
                                            padding: '0px 20px',
                                        }}
                                    >
                                        <div>
                                            <FormInput value={item.itemName} />
                                        </div>
                                        <div>
                                            <FormInput
                                                value={item.itemCost}
                                                name="itemCost"
                                                onChange={(e) => {
                                                    const newServiceAddOns = editedFields.ServiceAddOns && editedFields.ServiceAddOns.map((addon) => {
                                                        if (addon.id === item.id) {
                                                            return { ...addon, itemCost: parseFloat(e.target.value) };
                                                        }
                                                        return addon;
                                                    });
                                                    setEditedFields(prevState => ({ ...prevState, ServiceAddOns: newServiceAddOns }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Form> :
                        <Header size='medium'>
                            Only service requests are editable.
                        </Header>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closedModal} color='red'>Close</Button>
                    <Button onClick={handleRequestUpdate} color='blue' loading={isLoading}>Update</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default ReportsLiveRequests;
