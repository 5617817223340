import React, { useEffect } from 'react';
import { Tab, Comment, Button } from 'semantic-ui-react';
import 'flatpickr/dist/themes/airbnb.css';
import ItemsList from '../ItemsList';
import { DailyActivity, MenuType, PersonOfTheMonth } from '../../types';
import { Birthday } from '../../types/Birthday';
import DatePicker from 'react-datepicker';
import { ResidentCalendar } from '../../types/residentCalendar';
import moment from 'moment-timezone';

interface Props {
    loading: boolean;
    listItemFilter: string | number;
    isSaving: boolean;
    isFetching: boolean;
    listItems: DailyActivity[] | MenuType[] | PersonOfTheMonth[] | Birthday[] | ResidentCalendar[];
    sourcePage: string;
    aciveFoodTab?: string;
    setListItemDateFilter: (time) => any;
    clearDateFilter: () => any;
    getPaginatedListItem: () => any;
    deleteListItem: (id) => any;
    editListItem: (id) => any;
    copyListItem: (id) => any;
    cancelEditListItem: (id) => any;
    handleSubmit: (activity) => any;
    handleUpdate: (activity) => any;
    passAudioBlob?;
    hideActionButtons?: boolean;
    hideDateFitler?: boolean;
    ItemClickHandler?: (id: string) => void;
    activeHistoryTab?: string;
    facilityTimezone?: string;
    initalCalendarDate?: Date;
    handleChangeActiveTab?: (tab: string, tabNumber: number) => void;
    calendarType?: string[]
    allCalendarType?: {
        key: string;
        text: string;
        value: string;
    }[]
}

const ListItemsPane: React.FC<Props> = (props: Props) => {
    const [selectedDate, setSelectedDate] = React.useState<Date>();
    const calendarTab = 0;
    const historyTab = 1;
    useEffect(() => {
        if (props.initalCalendarDate && moment(props.initalCalendarDate).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")) {
            props.handleChangeActiveTab && props.handleChangeActiveTab('history', historyTab);
        } else {
            props.handleChangeActiveTab && props.handleChangeActiveTab('active', calendarTab);
        }
        // We are doing this so that the date filter is set to the current date when the user navigates to the page
        if (props.initalCalendarDate) {
            setSelectedDate(props.initalCalendarDate);
            props.setListItemDateFilter(props.initalCalendarDate);
        }
    }, [])
    let datePicker = <></>;
    if (props.facilityTimezone) {
        const today = moment.tz(props.facilityTimezone).format('YYYY-MM-DD');
        const yesterday = moment().subtract(1, 'days').tz(props.facilityTimezone).format('YYYY-MM-DD');
        const todayDate = new Date(today);
        const yesterdayDate = new Date(yesterday);
        const selectedDay = props.activeHistoryTab && props.activeHistoryTab === 'active' ? todayDate : yesterdayDate;
        datePicker = <DatePicker
            className="date-filter"
            placeholderText="Select Date"
            dateFormat="dd.MM.Y"
            selected={props.listItemFilter ? moment(props.listItemFilter).valueOf() : selectedDay}
            onChange={(date) => {
                setSelectedDate(date);
                props.setListItemDateFilter(date);
            }}
            minDate={props.activeHistoryTab && props.activeHistoryTab === 'active' ? todayDate : null}
            maxDate={props.activeHistoryTab && props.activeHistoryTab === 'history' ? yesterdayDate : null}
        />;
    } else {
        datePicker = <DatePicker
            className="date-filter"
            placeholderText="Select Date"
            dateFormat="dd.MM.Y"
            selected={props.listItemFilter ? new Date(props.listItemFilter).getTime() : null}
            onChange={(date) => {
                setSelectedDate(date);
                props.setListItemDateFilter(date);
            }}
        />;
    }
    return (
        <Tab.Pane loading={false} className="items-list-container">
            <Comment.Group>
                {!props.hideDateFitler ? <>
                    <h4>Filter Date: </h4>
                    {datePicker}
                </> : <></>}
                {props.listItems.length ? (
                    <ItemsList
                        listItems={props.getPaginatedListItem()}
                        isFetching={props.isFetching}
                        isSaving={props.isSaving}
                        handleSubmit={props.handleSubmit}
                        handleUpdate={props.handleUpdate}
                        deleteListItem={props.deleteListItem}
                        editListItem={props.editListItem}
                        cancelEditListItem={props.cancelEditListItem}
                        copyListItem={props.copyListItem}
                        sourcePage={props.sourcePage}
                        aciveFoodTab={props.aciveFoodTab}
                        passAudioBlob={props.passAudioBlob}
                        hideActionButtons={props.hideActionButtons}
                        ItemClickHandler={props.ItemClickHandler}
                        selectedDate={selectedDate}
                        calendarType={props.calendarType}
                        allCalendarType={props.allCalendarType}
                    />
                ) : (
                    <h3>There are no listed items</h3>
                )}
            </Comment.Group>
        </Tab.Pane>
    );
};

export default ListItemsPane;
