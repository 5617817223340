import React, { useState } from 'react';
import { Button, Dimmer, Loader, Modal, Table } from 'semantic-ui-react';
import { getDocWidgetData } from '../../../services/DocWidgets';
import { Registrant, User } from '../../../types';
import { Group } from '../../../types/Group';
import { DocWidgetData } from '../../../types/Service';
import { toast } from 'react-toastify';
import './style.less';

interface DocsProps {
    allDocs: DocWidgetData[];
    staffList: User[];
    residentsList: Registrant[];
    groupsList: Group[];
    isDocLoading: boolean;
    docId: string;
    setDocId: (newDocId: string)=>void;
}

const Docs: React.FC<DocsProps> = ({ allDocs, staffList, residentsList, groupsList, isDocLoading, docId, setDocId }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<DocWidgetData | null>(null);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);

    const staffDictionary = {};
    staffList.forEach((staff: User) => {
        staffDictionary[staff._id] = staff;
    });

    const handleDocsView = async (id: string, name: string) => {
        try {
            const data = await getDocWidgetData(id);
            setModalContent(data);
            setSelectedFile(name);
            setOpenModal(true);
        } catch (error) {
            toast.error('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    };
    const closeModal = () => {
        setOpenModal(false);
        setModalContent(null);
        setSelectedFile(null);
    };

    const getAccessNames = (accessIds: string[]) => {
        if (!Array.isArray(accessIds)) {
            return '-';
        }
        if (accessIds.includes('All')) {
            return 'All';
        }
        const names: string[] = accessIds.map(id => {
            const resident: Registrant | undefined = residentsList.find((resident) => resident._id === id);
            if (resident) {
                return `${resident.FirstName} ${resident.LastName}`;
            }

            const group: Group | undefined = groupsList.find(group => group._id === id);
            if (group) {
                return group.Name;
            }

            const staff: User | undefined = staffList.find(staff => staff._id === id);
            if (staff) {
                return `${staff.FirstName} ${staff.LastName}`;
            }
            return '';
        });
        return names.join(', ');
    };

    return (
        <div style={{ marginTop: "10px" }}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Access Rooms</Table.HeaderCell>
                        <Table.HeaderCell>CreatedBy</Table.HeaderCell>
                        <Table.HeaderCell>UpdatedBy</Table.HeaderCell>
                        <Table.HeaderCell>Files</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Dimmer active={isDocLoading} inverted>
                        <Loader size='mini'>Loading</Loader>
                    </Dimmer> 
                    {allDocs && allDocs.length > 0 && allDocs.map((docs: DocWidgetData) => (
                        <Table.Row key={docs._id} style={{ cursor: 'pointer', backgroundColor: docId === docs._id ? "#1e2a64" : "white", color: docId === docs._id ? "white" : "black" }} onClick={() => { docs._id && setDocId(docs._id); }}>
                            <Table.Cell>
                                {docs.Name}
                            </Table.Cell>
                            <Table.Cell>
                                {getAccessNames(docs.Access)}
                            </Table.Cell>
                            <Table.Cell>
                                {docs.AddedBy && staffDictionary[docs.AddedBy]
                                    ? `${staffDictionary[docs.AddedBy].FirstName} ${staffDictionary[docs.AddedBy].LastName}`
                                    : "-"}
                            </Table.Cell>
                            <Table.Cell>
                                {docs.UpdatedBy && staffDictionary[docs.UpdatedBy]
                                    ? `${staffDictionary[docs.UpdatedBy].FirstName} ${staffDictionary[docs.UpdatedBy].LastName}`
                                    : "-"}
                            </Table.Cell>
                            <Table.Cell style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                {docs.files.map((file, index) => (
                                    <div style={{ cursor: "pointer", color: docId === docs._id ? "white" : "blue" }} onClick={() => docs._id && handleDocsView(docs._id, file)} key={index}>{file}</div>
                                ))}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {/* Modal for displaying file content */}
            <Modal open={openModal} onClose={closeModal}>
                <Modal.Header>{modalContent ? modalContent.Name : ''}</Modal.Header>
                <Modal.Content>
                    {modalContent && modalContent.attachedFiles && modalContent.attachedFiles.map((attachedFile, index) => (
                        <div key={index}>
                            {attachedFile.name === selectedFile && (
                                attachedFile.name.endsWith('.jpg') || attachedFile.name.endsWith('.png') ? (
                                    <img src={attachedFile.attachedLink} alt={attachedFile.name} style={{ maxWidth: '100%' }} />
                                ) : (
                                    <a href={attachedFile.attachedLink} target="_blank">
                                        {attachedFile.name}
                                    </a>
                                )
                            )}
                        </div>
                    ))}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal}>Close</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default Docs;
