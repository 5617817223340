import React, { FC } from "react";
import { Form, Input } from "semantic-ui-react";
import { TaxRateType } from "../../types/Service";

interface Props {
    taxRates: TaxRateType;
    setTaxRates: React.Dispatch<React.SetStateAction<TaxRateType>>;
}
const TaxTab: FC<Props> = ({ taxRates, setTaxRates }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTaxRates((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div style={{ padding: "10px", marginBottom: "20px" }}>
            <div style={{ fontSize: "20px", fontWeight: "bold", marginTop: '20px' }}>
                Tax Rates
            </div>
            <div style={{marginTop: "15px"}}>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        name="federal"
                        label="Federal"
                        placeholder="Federal"
                        onChange={handleChange}
                        value={taxRates.federal}
                    />
                    <Form.Field
                        control={Input}
                        name="state"
                        label="State"
                        placeholder="State"
                        onChange={handleChange}
                        value={taxRates.state}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        name="local"
                        label="Local"
                        placeholder="Local"
                        onChange={handleChange}
                        value={taxRates.local}
                    />
                    <Form.Field
                        control={Input}
                        name="other"
                        label="Other"
                        placeholder="Other"
                        onChange={handleChange}
                        value={taxRates.other}
                    />
                </Form.Group>
            </div>
        </div>
    );
};

export default TaxTab;