import React from 'react'
import ContactsForm, { FormValues } from '../ContactsForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { createContact } from '../../../../services/Contacts'
import { Registrant } from '../../../../types'
import { fetchRegistrants } from '../../../../services/Registrants'
import { Dimmer, Loader } from 'semantic-ui-react'

interface State {
    isSaving: boolean
    isFetching: boolean
    error: string | null
    registrants: Registrant[]
}

interface Props extends RouteComponentProps {
    onSubmitClick?: () => void
    onCancelClick?: () => void
}

class ContactsCreate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            isFetching: false,
            error: null,
            registrants: [],
        }
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const registrants = await fetchRegistrants()
            this.setState({ registrants, isFetching: false })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        if (this.props.onCancelClick) {
            this.props.onCancelClick()
        } else {
            this.props.history.goBack()
        }
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            await createContact(data)
            this.setState({ isSaving: false })
            this.props.onSubmitClick && this.props.onSubmitClick()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <div className="CreateContact">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.registrants.length && (
                    <ContactsForm
                        title="Create Contact"
                        contact={null}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(ContactsCreate)
