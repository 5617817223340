import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchOneFacility } from '../../services/Facilities';
import { fetchMultipleStaffCalendarEvents } from '../../services/StaffCalendar';
import { fetchAllStaffUsers } from '../../services/Users';
import { AuthState, Registrant, ResidentCalendar, User } from '../../types';
import { StaffCalendar } from '../../types/staffCalendar';
import CustomDatePicker, { DateRange } from '../CustomDatePicker';
import CustomTable from '../CustomTable'
import { Button, Dimmer, Dropdown, DropdownProps, Grid, Icon, Loader, Popup } from 'semantic-ui-react';
import { fetchAllActiveFacilityRegistrants, fetchRegistrants } from '../../services/Registrants';
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader';
import FilterPrintExportIcons from '../FilterPrintExportIcons';
import ResidentCalendarForm from '../ResidentCalendar/ResidentCalendarForm';
import { fetchResidentGroups } from '../../services/RoomGroups';
import { toast } from 'react-toastify';
import './style.less'
import { sortByKey } from '../../util/sortData';

const StaffTable = () => {
    const [dateRange, setDateRange] = useState<DateRange>({ start: undefined, end: undefined });
    const [staffData, setStaffData] = useState<StaffCalendar[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const profile = useSelector((state: { authReducer: AuthState; }) => state.authReducer.profile);
    const [staffUsers, setStaffUsers] = useState<User[]>([])
    const [facilityTimeZone, setFacilityTimeZone] = useState("")
    const [filteredStaffData, setFilteredStaffData] = useState<StaffCalendar[]>([])
    const [selectedStaff, setSelectedStaff] = useState<string[]>([])
    const [residents, setResidents] = useState<Registrant[]>([])
    const [selectedResidents, setSelectedResidents] = useState<string[]>([])
    const [printLoading, setPrintLoading] = useState(false);
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [isLoadingResidentCalendar, setIsLoadingResidentCalendar] = useState(false)
    const [showResidentCalendarCreateModal, setShowResidentCalendarCreateModal] = useState(false)
    const [residentCalendarData, setResidentCalendarData] = useState({})
    const [residentOptions, setResidentOptions] = useState([])
    const [staffOptions, setStaffOptions] = useState([])
    const [duration, setDuration] = useState<number>(0)
    const [refresh, setRefresh] = useState(false)
    const [error, setError] = useState({})
    const pageSource = "StaffCalendar"

    const fetchData = async (start, end) => {
        setLoading(true);
        const staffUsers = await fetchAllStaffUsers();
        const staffIds = staffUsers.map(staffUser => staffUser._id);
        const startDate = start ? moment(start).format('YYYY-MM-DD') : moment().subtract(1, 'months').format('YYYY-MM-DD');
        const endDate = end ? moment(end).format('YYYY-MM-DD') : moment(startDate).add(1, 'months').format('YYYY-MM-DD');
        const data = await fetchMultipleStaffCalendarEvents(staffIds, startDate, endDate);
        setLoading(false);
        return data;
    }

    useEffect(() => {
        (async () => {
            const [{ FacilityTimeZone = "" }, staffUsers, residents] = await Promise.all([fetchOneFacility(profile && profile.Facility || ""), fetchAllStaffUsers(), fetchAllActiveFacilityRegistrants(profile && profile.Facility || "", true /* excludeAlisImage */)]);
            setResidents(residents);
            setStaffUsers(staffUsers);
            setFacilityTimeZone(FacilityTimeZone);
        })()
        fetchResidentAndRoomOptions()
    }, []);

    useEffect(() => {
        (async () => {
            if (!dateRange.start && !dateRange.end) {
                const start = moment().toDate();
                const end = moment().toDate();
                setDateRange({ start, end })
            }
            if (dateRange.start && dateRange.end) {
                const data = await fetchData(dateRange.start, dateRange.end);
                setStaffData(data);
            }
        })()
    }, [dateRange, refresh]);

    useEffect(() => {
        if (residentCalendarData["dateTimeString"]) {          // Update endDateTimeString whenever dateTimeString changes
            const endDateTimeString = moment(residentCalendarData["dateTimeString"])
                .add(duration ? duration : 1, 'm')
                .format('YYYY-MM-DDTHH:mm:ss');
            setResdientCalendarDataField('endDateTimeString', endDateTimeString);
        }
    }, [residentCalendarData["dateTimeString"]]);

    useEffect(() => {
        if (error["showError"]) {
            toast.error(error["errorMessage"], {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }, [error])

    const staffDataToDisplay = ((selectedStaff.length > 0) || (selectedResidents.length > 0) || (isFilterSelected)) ? filteredStaffData : staffData;

    const tableData = staffDataToDisplay.map(data => {
        return {
            name: data.text,
            timestamp: data.dateTimeString,
            staff: data.staffName
        }
    }).sort((a, b) => {
        // show the latest first
        return moment(b.timestamp).diff(moment(a.timestamp))
    });

    const handleDeleteSelectedStaff = (index: number) => {
        const value = selectedStaff
        value.splice(index, 1)
        setSelectedStaff(value as string[]);
        if (Array.isArray(value) && value.length) {
            const filteredData = staffData.filter(data => value.includes(data.staffId));
            setFilteredStaffData(filteredData)
        } else {
            setFilteredStaffData([])
        }
    }

    const handleDeleteResident = (index: number) => {
        const value = selectedResidents
        value.splice(index, 1)
        setSelectedResidents(value as string[]);
        if (Array.isArray(value) && value.length) {
            const filteredData = staffData.filter(data => value.includes(data.staffId));
            setFilteredStaffData(filteredData)
        }
    }

    const handleStaffDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const { value = [] } = data;
        setSelectedStaff(value as string[]);
        if (Array.isArray(value) && value.length) {
            const filteredData = staffData.filter(data => value.includes(data.staffId));
            setFilteredStaffData(filteredData)
        } else {
            setFilteredStaffData([])
        }
    }

    const handleResidentDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const { value = [] } = data;
        setSelectedResidents(value as string[]);
        if (Array.isArray(value) && value.length) {
            const filteredData = staffData.filter(data => value.includes(data.staffId));
            setFilteredStaffData(filteredData)
        }
    }

    const staffDropdownValues = staffUsers.map(staffUser => {
        return {
            key: staffUser._id,
            text: `${staffUser.FirstName} ${staffUser.LastName}`,
            value: staffUser._id
        }
    })

    const residentDropdownValues = residents.map(resident => {
        return {
            key: resident._id,
            text: `${resident.FirstName} ${resident.LastName}`,
            value: resident._id
        }
    })

    const tableExportData = staffDataToDisplay.map(data => {
        return {
            "Activity Name": data.text,
            "Day/Time": data.dateTimeString,
            "Staff": data.staffName
        }
    });

    const handlePrintClick = async () => {
        const printArr = staffData.map(el => {
            return {
                Subject: el.text,
                StartTime: new Date(el.dateTimeString),
                StartDate: new Date(el.dateTimeString),
            }
        })
        setPrintLoading(true)
        await formatAndExportDataForPrint(printArr, `staff_calendar`, facilityTimeZone, "menu" /* menu will prevent using timezone */)
        setPrintLoading(false)
    }


    const filterChangeHandler = (filterItems: string[]) => {
        setIsFilterSelected(filterItems.length ? true : false)
        const formattedFilterNames: string[] = []
        filterItems.forEach(filter => {
            switch (filter) {
                case ("menu"):
                    formattedFilterNames.push("Menu")
                    break
                case ("calendar"):
                    formattedFilterNames.push("Calendar", "6360ca9c7b46ce0008e0d5e0")
                    break
                case ("mail"):
                    formattedFilterNames.push("Affirmations")
                    break
                case ("checkin"):
                    formattedFilterNames.push("Check in", "Transportation", "Walk", "Stretch", "Yoga", "Exercise", "Game", "TV", "Movie", "Ate", "Awake", "Meds", "Water", "Sleep")
                    break
                case ("birthdays"):
                    formattedFilterNames.push("Birthdays")
                    break
                case ("rotm"):
                    formattedFilterNames.push("ResidentOfTheMonth")
                    break
                case ("sotm"):
                    formattedFilterNames.push("StaffOfTheMonth")
                    break
                case ("car"):
                    formattedFilterNames.push("Transportation")
                    break
                case ("kitchen"):
                    formattedFilterNames.push("Kitchen", "Dinning")
                    break
                case ("sms"):
                    formattedFilterNames.push("Message", "Sent Message")
                    break
                case ("maintenance"):
                    formattedFilterNames.push("Maintenance")
                    break
                case ("mail_is_in"):
                    formattedFilterNames.push("Mail")
                    break
                case ("housekeeping"):
                    formattedFilterNames.push("Housekeeping")
                    break
                case ("event"):
                    formattedFilterNames.push("Unscheduled Event", "Resident Calendar")
                    break
                default:
                    return filter
            }
        })
        const filteredStaffIds = staffUsers.filter(staff => {
            if (staff.Departments && staff.Departments.length) {
                return staff.Departments.some(department => formattedFilterNames.includes(department.Name));
            }
            return false;
        }).map(event => event._id);
        const eventsStaffIsLinkedWith = staffData.filter(event => {
            return event.staffId && filteredStaffIds.includes(event.staffId);
        })
        setFilteredStaffData(eventsStaffIsLinkedWith)
    }

    function setResdientCalendarDataField(key: keyof ResidentCalendar, value: any) {
        if (key === 'registrantIds') {
            if (Array.isArray(value)) {
                const handleGroups = value.map((residentId) => {
                    if (residentId.indexOf(',') !== -1) {
                        return residentId.split(',');
                    }
                    return residentId;
                });
                const rooms = handleGroups.flat();
                setResidentCalendarData({ ...residentCalendarData, [key]: rooms })
            }
            return;
        }
        if (key === 'staffId') {
            setResidentCalendarData({ ...residentCalendarData, [key]: value })
            return;
        }
        setResidentCalendarData({ ...residentCalendarData, [key]: value })
    }
    const displayError = (errorMessage: string) => {
        if (errorMessage.length > 0) {
            setError({
                showError: true,
                errorMessage,
            })
        } else {
            setError({
                showError: false,
                errorMessage: "",
            })
        }
    };

    const refreshResidentCalendarItems = async () => {
        setRefresh(!refresh)
    };
    const resetResidentCalendarDate = () => {
        setResidentCalendarData({
            dateTimeString: moment().format('YYYY-MM-DDTHH:mm:ss')
        })
        setDuration(0)
    }

    const formatAndSortStaffs = (staff) => {
        const formattedStaffs = staff.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.LastName}` || "",
                value: `${obj._id}`,
            };
        });

        const sortedStaffs = formattedStaffs.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });
        return sortedStaffs;
    };

    const formatAndSortResidents = (residents) => {
        const formattedResidents = residents.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.LastName}` || "",
                value: `${obj._id}`,
            };
        });

        const sortedResidents = formattedResidents.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });
        return sortedResidents;
    }

    const formatAndSortRoomGroups = (groups: any) => {
        const formattedRoomGroups = groups.map((obj) => {
            if (obj.ResidentIds && obj.ResidentIds.length <= 1) return;// don't show groups which does not have atleast 2 ResidentIds (To take care of wierd issues with residentGroups)
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.ResidentIds]}`,
            };
        }).filter(obj => obj);;

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });
        return sortedRoomGroups;
    }

    const fetchResidentAndRoomOptions = async () => {
        setIsLoadingResidentCalendar(true)
        try {
            const staffDetails = await fetchAllStaffUsers();
            const formatedStaffOptions = formatAndSortStaffs(staffDetails)
            const residentsWithRooms = await fetchRegistrants();
            const formattedResidentOptions = formatAndSortResidents(residentsWithRooms);
            const roomGroups = await fetchResidentGroups();
            const sortedRoomGroups = formatAndSortRoomGroups(roomGroups);
            const roomOptions = [].concat(sortedRoomGroups).concat(formattedResidentOptions);
            setStaffOptions(formatedStaffOptions)
            setResidentOptions(roomOptions)
        } catch (error) {
            console.error(error);
            displayError('Unable to fetch resident list');
        }
        setIsLoadingResidentCalendar(false)
    };

    return (
        <div>
            <Dimmer active={isLoadingResidentCalendar} inverted>
                <Loader />
            </Dimmer>
            <ResidentCalendarForm
                setLoader={(value: boolean) => setIsLoadingResidentCalendar(value)}
                showModal={showResidentCalendarCreateModal}
                closeForm={() => setShowResidentCalendarCreateModal(false)}
                isEdit={false}
                residentCalendarData={residentCalendarData}
                setResdientCalendarDataField={(...values) => setResdientCalendarDataField(...values)}
                displayError={displayError}
                refresh={refreshResidentCalendarItems}
                resetResidentCalendarData={resetResidentCalendarDate}
                residentOptions={residentOptions}
                staffOptions={staffOptions}
                duration={duration}
                setDuration={(value: number) => setDuration(value)}
                isStaff={true}
                pageSource={pageSource}
            />
            <Grid columns={2} centered padded verticalAlign='top'>
                <Grid.Column width={4} style={{ paddingTop: "25px" }} >
                    <CustomDatePicker
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        onChange={(start, end) => {
                            setDateRange({ start, end });
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Grid columns={3} relaxed centered verticalAlign='top'>
                        <Grid.Column width={6}  >
                            <div style={{ position: "relative" }}>
                                <Dropdown className={selectedStaff.length > 1 ? 'scrollable' : ""} placeholder='Select Staff' fluid selection multiple options={sortByKey(staffDropdownValues)} onChange={handleStaffDropdownChange} search />
                                {selectedStaff.length > 1 ?
                                    <>
                                        <p className='display-text'>No of Selected Staffs:{selectedStaff.length}</p>
                                        <div className='selectedList'>
                                            <h5>  Selected Staffs   </h5>
                                            <ul className='scroll-list'>
                                                {selectedStaff.map((staff, index) => {
                                                    const selectedUser = staffDropdownValues.find((user) => user.value === staff);
                                                    if (selectedUser) {
                                                        return <li className='scroll-option' key={index}>{selectedUser.text}
                                                            <Icon onClick={() => handleDeleteSelectedStaff(index)} name="times" className="deleteIcon" /></li>;
                                                    }
                                                })}
                                            </ul>
                                        </div> </> : ""}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={6} >
                            <div style={{ position: "relative" }}>
                                <Dropdown className={selectedResidents.length > 1 ? 'scrollable' : ""} placeholder='Select Resident' fluid selection multiple options={sortByKey(residentDropdownValues)} onChange={handleResidentDropdownChange} search />
                                {selectedResidents.length > 1 ? 
                                    <>
                                        <p className='display-text'>No of Selected Residents:{selectedResidents.length}</p>
                                        <div className='selectedList'>
                                            <h5>  Selected Residents </h5>
                                            <ul className='scroll-list'>
                                                {selectedResidents.map((resident, index) => {
                                                    const selectedUser = residentDropdownValues.find((user) => user.value === resident);
                                                    if (selectedUser) {
                                                        return <li className='scroll-option' key={index}>{selectedUser.text}
                                                            <Icon onClick={() => handleDeleteResident(index)} name="times" className="deleteIcon" /></li>;
                                                    }
                                                })}
                                            </ul>
                                        </div> </> : ""}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <div style={{ display: "flex", paddingTop: "12px" }}  >
                                <Popup
                                    trigger={<Button className='searchBtnPlus' icon='plus' size='large' onClick={(e) => {
                                        e.preventDefault()
                                        setShowResidentCalendarCreateModal(!showResidentCalendarCreateModal)
                                    }} style={{ background: "none", padding: 0, marginRight: "12px" }} loading={isLoadingResidentCalendar || false} />}
                                    content='Create Calendar Item'
                                    size='tiny'
                                    type="button"
                                />
                                <FilterPrintExportIcons exportData={tableExportData} onPrintClick={handlePrintClick} printButtonLoading={printLoading} exportFileName='staff_calendar' onAnnouncementFilterClick={() => {
                                }}
                                    onBirthdaysFilterClick={(filteredRequests) => {
                                        filterChangeHandler(filteredRequests)
                                    }}
                                    onCalendarFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onCheckinFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onMailFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onMenuFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onPalsFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onRequestsFilterClick={() => {
                                        console.log("print click")
                                    }}
                                    onCarFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onKitchenFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onMailIsInFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onMaintenanceFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onROTMFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onSMSFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onSOTMFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onHouseKeeingFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onUnschduledEventFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                    onDeviceStatusFilterClick={(filterItems) => {
                                        filterChangeHandler(filterItems)
                                    }}
                                />
                            </div>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            <CustomTable
                data={tableData}
                loading={loading}
                headers={["Activity Name", "Day/Time", "Staff"]}
                rowKeys={["name", "timestamp", "staff"]}
                facilityTimezone={facilityTimeZone}
                formatString={"MM/DD/YYYY hh:mm A"}
                formatKeys={["timestamp"]}
                setHeight={"550px"}
            />
        </div >
    )
}


export default StaffTable
