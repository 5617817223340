import React from 'react';
import {
    Form,
    InputOnChangeData,
    Dropdown,
    Message,
    Checkbox,
    Grid,
} from 'semantic-ui-react';
import { deleteAnnouncement } from '../../../services/Notifications';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { sortByKey } from '../../../util/sortData';
import { Slider } from "react-semantic-ui-range";
export interface AnnouncementType {
    Message: string;
    RoomIds: string[];
    _id: string;
    triggerTimestampISO?: string;
    notificationId?: string;
    Recurrence?: string;
    dismissalTime?: string;
    DateAdded: number;
    roomNames: string[];
}

interface Props {
    isSaving: boolean;
    onSubmit: (data: object) => Promise<any>;
    roomOptions;
    roomGroups;
    deleteRoomGroup: (id: string) => Promise<any>;
    createRoomGroup(name: string, roomIds: string[]);
    disallowRoomControls?: boolean;
    selectedAnnouncees?: string[];
    selectedTriggerTimestampISO?: string;
    hideAnnounceeSelection?: boolean;
    selectedAnnouncement?: Partial<AnnouncementType>;
    handleModalClose?: any;
    refreshNotifications?: any;
    facilityTimeZone: string;
}

interface State {
    message: string;
    selectedRooms: string[];
    disableIndividualRooms: boolean;
    disableSubmit: boolean;
    dismissalTimeOffsetMinutes: number;
    endDateTimeString: string;
    repeatType?: string;
    errMsg: string;
    warnMsg: string;
    sendDeviceNotification: boolean;
    saveAsGroup: boolean;
    roomGroupName: string;
    savingRoomGroup: boolean;
    saveGroupErr: string;
    hideSaveAsGroup: boolean;
    openManageGroups: boolean;
    deleteGroup: string;
    deletingRoomGroup: boolean;
    delGroupErr: string;
    deleteAnnouncementLoader: boolean;
    showDeleteConfirmModal: boolean;
    triggerDateTimeString: string;
}

class UpdateNotificationsForm extends React.Component<Props, State> {
    // convert YYYYMMDD date to be in YYYY-MM-DD  https://stackoverflow.com/a/36638153
    dateYYYMMDD = (inputStr) => inputStr.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    dtstart =
        this.props.selectedAnnouncement &&
        this.dateYYYMMDD(this.props.selectedAnnouncement.Recurrence!.split('\n')[0].split('DTSTART:')[1]);
    until =
        this.props.selectedAnnouncement &&
        this.dateYYYMMDD(this.props.selectedAnnouncement.Recurrence!.split('UNTIL=')[1]);
    state: State = {
        message: this.props.selectedAnnouncement ? this.props.selectedAnnouncement.Message! : '',
        selectedRooms: this.props.disallowRoomControls
            ? []
            : this.props.selectedAnnouncement
            ? this.props.selectedAnnouncement.RoomIds!
            : ['all'],
        disableIndividualRooms: true,
        disableSubmit: true,
        dismissalTimeOffsetMinutes: this.getDismissalTime(),
        endDateTimeString:
            (this.props.selectedAnnouncement &&
                this.props.selectedAnnouncement.Recurrence &&
               moment(
                    this.dateYYYMMDD(this.props.selectedAnnouncement.Recurrence.split('UNTIL=')[1]),
                ).format('YYYY-MM-DDTHH:mm:ss')) || '',
        repeatType:
            this.props.selectedAnnouncement && this.props.selectedAnnouncement.Recurrence && this.dtstart != this.until
                ? this.props.selectedAnnouncement &&
                  this.props.selectedAnnouncement.Recurrence &&
                  this.props.selectedAnnouncement.Recurrence.split('FREQ=')[1].split(';')[0]
                : 'OFF',
        errMsg: '',
        warnMsg: '',
        sendDeviceNotification:
            this.props.selectedAnnouncement && this.props.selectedAnnouncement.notificationId ? true : false,
        saveAsGroup: false,
        roomGroupName: '',
        savingRoomGroup: false,
        saveGroupErr: '',
        hideSaveAsGroup: false,
        openManageGroups: false,
        deleteGroup: '',
        deletingRoomGroup: false,
        delGroupErr: '',
        deleteAnnouncementLoader: false,
        showDeleteConfirmModal: false,
        triggerDateTimeString:
            this.props.selectedAnnouncement && this.props.selectedAnnouncement.triggerTimestampISO
                ? moment
                      .tz(this.props.selectedAnnouncement.triggerTimestampISO, this.props.facilityTimeZone)
                      .format('YYYY-MM-DDTHH:mm:ss')
                : '',
    };

    handleChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (data.value.length >= 60) {
            this.setState({
                warnMsg: 'Only the first 60 characters shall be shown on pinned and rotating alerts',
            });
        } else {
            this.setState({
                warnMsg: '',
            });
        }
        this.setState({
            message: data.value,
            disableSubmit:
                this.props.roomOptions.length === 1 ||
                !(data.value && this.state.selectedRooms[0]) ||
                !!this.state.errMsg,
        });
    }

    handleTriggerDateTimeString(data: Date) {
        const date = moment.tz(data, this.props.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss');
        if (date != this.state.triggerDateTimeString) {
            this.setState({
                triggerDateTimeString: date,
                disableSubmit:
                    this.props.roomOptions.length === 1 ||
                    !(date && this.state.selectedRooms[0]) ||
                    !!this.state.errMsg,
            });
        }
    }

    handleDropdownChange(roomIds) {
        const roomsHandlingGroups = roomIds.map((roomId) => {
            if (roomId.indexOf(',') !== -1) {
                return roomId.split(',');
            }
            return roomId;
        });
        const rooms = roomsHandlingGroups.flat();

        if (!rooms.includes('all') && rooms.length > 1 && this.state.hideSaveAsGroup) {
            this.setState({
                hideSaveAsGroup: false,
            });
        }

        if (rooms.includes('all')) {
            this.setState({
                selectedRooms: ['all'],
                disableIndividualRooms: true,
                disableSubmit:
                    this.props.roomOptions.length === 1 || !(this.state.message && rooms[0]) || !!this.state.errMsg,
                sendDeviceNotification: true,
            });
        } else {
            this.setState({
                selectedRooms: rooms,
                disableSubmit:
                    this.props.roomOptions.length === 1 || !(this.state.message && rooms[0]) || !!this.state.errMsg,
                sendDeviceNotification: false,
            });
        }
    }

    async handleSubmit() {
        if (this.props.selectedAnnouncement!.Recurrence) {
            const formattedDate = (date: string) => moment(date).format('YYYYMMDD');
            let recurrence = '';
            const dtstart = formattedDate(this.state.triggerDateTimeString);
            const until =
                this.state.repeatType == 'OFF'
                    ? formattedDate(this.state.triggerDateTimeString)
                    : formattedDate(this.state.endDateTimeString);
            const freq = this.state.repeatType === 'OFF' ? 'DAILY' : this.state.repeatType;

            const pattern = /exdate/i;
            const isExdate = pattern.test(this.props.selectedAnnouncement!.Recurrence.split('\n')[1]);
            if (isExdate) {
                const [_, ...exdates] = this.props.selectedAnnouncement!.Recurrence.split('\n')[1].split(':');
                recurrence = `DTSTART:${dtstart}\nEXDATE:${exdates}\nRRULE:FREQ=${freq};UNTIL=${until}`;
            } else {
                recurrence = `DTSTART:${dtstart}\nRRULE:FREQ=${freq};UNTIL=${until}`;
            }

            this.props.onSubmit({
                message: this.state.message,
                roomIds: this.state.selectedRooms,
                dismissalTimeOffsetMinutes: this.state.dismissalTimeOffsetMinutes,
                sendDeviceNotification: this.state.sendDeviceNotification,
                _id: this.props.selectedAnnouncement!._id!,
                triggerDateTimeString: this.state.triggerDateTimeString,
                recurrence,
            });
        } else {
            this.props.onSubmit({
                message: this.state.message,
                roomIds: this.state.selectedRooms,
                dismissalTimeOffsetMinutes: this.state.dismissalTimeOffsetMinutes,
                sendDeviceNotification: this.state.sendDeviceNotification,
                _id: this.props.selectedAnnouncement!._id!,
                triggerDateTimeString: this.state.triggerDateTimeString,
            });
        }

        if (this.props.selectedAnnouncees) {
            const selectedRooms = this.getSelectedRoomsWhenRoomSelectionDisabled();
            this.setState({
                message: '',
                selectedRooms: selectedRooms,
                disableIndividualRooms: true,
                dismissalTimeOffsetMinutes: this.getDismissalTime(),
                triggerDateTimeString: this.state.triggerDateTimeString,
                disableSubmit: true,
                sendDeviceNotification: true,
                warnMsg: '',
                saveAsGroup: false,
            });
        } else {
            this.setState({
                message: '',
                selectedRooms: ['all'],
                disableIndividualRooms: true,
                dismissalTimeOffsetMinutes: 5,
                triggerDateTimeString: this.state.triggerDateTimeString,
                disableSubmit: true,
                sendDeviceNotification: true,
                warnMsg: '',
                saveAsGroup: false,
            });
        }
        this.props.handleModalClose();
        await this.props.refreshNotifications();
    }

    getDismissalTime() {
        const triggerTimestampISO = this.props.selectedAnnouncement && this.props.selectedAnnouncement.triggerTimestampISO
        const dismissalTime = this.props.selectedAnnouncement && this.props.selectedAnnouncement.dismissalTime
        if (triggerTimestampISO && dismissalTime && (triggerTimestampISO < dismissalTime)) {
            return Number((Date.parse(dismissalTime) - Date.parse(triggerTimestampISO))/ 1000 / 60)
        } else {
            return 5;
        }
    }

    async saveRoomGroupName(name: string) {
        this.setState({
            savingRoomGroup: true,
        });
        try {
            //make call to save rooms as group
            await this.props.createRoomGroup(name, this.state.selectedRooms);

            this.setState({
                savingRoomGroup: false,
                saveAsGroup: false,
                hideSaveAsGroup: true, //hack
            });
        } catch (e) {
            this.setState({
                saveGroupErr: e.message ? e.message : 'Save failed! Please retry.',
                savingRoomGroup: false,
            });
        }
    }

    async handleDelGroup() {
        console.log('deleting group', this.state.deleteGroup);
        try {
            this.setState({
                deletingRoomGroup: true,
            });

            await this.props.deleteRoomGroup(this.state.deleteGroup);

            this.setState({
                deletingRoomGroup: false,
                openManageGroups: false,
            });
        } catch (e) {
            console.error(e);
            this.setState({
                deletingRoomGroup: false,
                delGroupErr: 'Delete failed',
            });
        }
    }

    async handleDeleteAnnouncement(e) {
        e.preventDefault();
        this.setState({
            deleteAnnouncementLoader: true,
            showDeleteConfirmModal: true,
        });
    }

    getA4HRoomIdsAndSyncState(announceeNames) {
        if (announceeNames) {
            const announceeRooms = this.props.roomOptions.filter((room) => {
                return announceeNames.includes(room.text);
            });
            const A4HRoomIds = announceeRooms.map((room) => {
                return room.value;
            });
            this.setState({ selectedRooms: A4HRoomIds });
            return A4HRoomIds;
        }
        return [];
    }

    getSelectedRoomsWhenRoomSelectionDisabled = () => {
        if (this.props.selectedAnnouncees) {
            const announceeA4HIds = this.getA4HRoomIdsAndSyncState(this.props.selectedAnnouncees);
            return announceeA4HIds;
        }
    };

    componentDidMount() {
        if (this.props.selectedAnnouncees) {
            const selectedRooms = this.getSelectedRoomsWhenRoomSelectionDisabled();
            this.setState({ selectedRooms: selectedRooms });
        }
        if (this.props.selectedTriggerTimestampISO) {
            this.setState({
                triggerDateTimeString: moment.tz(this.props.selectedTriggerTimestampISO, this.props.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedAnnouncees) {
            if (
                this.props.selectedAnnouncees !== prevProps.selectedAnnouncees ||
                this.props.roomOptions.length !== prevProps.roomOptions.length
            ) {
                const announceeA4HIds = this.getA4HRoomIdsAndSyncState(this.props.selectedAnnouncees);
                this.setState({ selectedRooms: announceeA4HIds });
            }
        }
    }

    deleteAssoicatedNotificationHandler() {
        console.log('delete notification assciated to annoucement');
    }

    async deleteAnnoucementHandler() {
        if (this.props.selectedAnnouncement && this.props.selectedAnnouncement._id) {
            const _id = this.props.selectedAnnouncement._id;
            try {
                await deleteAnnouncement(_id);
                this.setState({
                    deleteAnnouncementLoader: false,
                });
                this.props.handleModalClose();
                await this.props.refreshNotifications();
                toast.success('Deleted announcement', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } catch (err) {
                this.setState({
                    errMsg: err.message,
                });
                toast.error(err.message, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }
    }

    cancelClickHandler() {
        this.setState({
            showDeleteConfirmModal: false,
        });
    }
    renderStartDateTimePicker() {
       return this.props.selectedAnnouncement && this.props.selectedAnnouncement.triggerTimestampISO && (
            <Form.Field style={{ marginTop: '17px', width: "150px" }}>
                <div className="customDatePickerWidth">
                    <DatePicker
                        placeholderText="Start Date"
                        dateFormat="M.d.Y h:mm aa"
                        selectsStart
                        selected={
                            this.state.triggerDateTimeString
                                ? new Date(this.state.triggerDateTimeString)
                                : ''
                        }
                        showTimeSelect
                        timeIntervals={15}
                        onChange={(date: Date) => this.handleTriggerDateTimeString(date)}
                        startDate={new Date(this.state.triggerDateTimeString)}
                        endDate={new Date(this.state.endDateTimeString)}
                        minDate={new Date()}
                        minTime={ //// can this be demoed tommorow - also look into the date picker bug rishi mentioned
                            new Date(this.state.triggerDateTimeString).toDateString() ===
                                new Date().toDateString()
                                ? Date.now()
                                : false
                        }
                        // for using minTime, maxTime should be provided (for time selector) cc @rishi
                        maxTime={
                            new Date(this.state.triggerDateTimeString).toDateString() ===
                                new Date().toDateString()
                                ? new Date().setHours(23, 59, 0, 0)
                                : false
                        }
                    />
                </div>
            </Form.Field>
        )
    }
    renderEndDateTimePicker() {
       return this.state.repeatType != 'OFF' && (
            <Form.Field style={{ width: "150px" }}>
                <div className="customDatePickerWidth">
                    <DatePicker
                        placeholderText="Start Date"
                        dateFormat="M.d.Y h:mm aa"
                        selectsEnd
                        selected={this.state.endDateTimeString ? new Date(this.state.endDateTimeString) : ''}
                        showTimeSelect
                        timeIntervals={15}
                        onChange={(date: Date) => {
                            this.setState({
                                disableSubmit: false,
                            });
                            this.setState({ endDateTimeString: moment(date).format('YYYY-MM-DDTHH:mm:ss') });
                        }}
                        startDate={new Date(this.state.triggerDateTimeString)}
                        endDate={new Date(this.state.endDateTimeString)}
                        minDate={
                            this.state.triggerDateTimeString
                                ? new Date(
                                    24 * 60 * 60 * 1000 +
                                    (new Date(this.state.triggerDateTimeString).getTime() || Date.now()),
                                )
                                : new Date()
                        }
                    />
                </div>
            </Form.Field>
        )
    }
    render() {
        return (
            <Form loading={this.props.isSaving} onSubmit={this.handleSubmit.bind(this)} className="form-custom-margin" style={{minWidth:"100%"}}>
                <div className='align-center' style={{ padding: "0px 5px" }} >
                    <div style={{ padding: "0px 25px", maxWidth: "50rem", minWidth:"100%" }} >
                        <Form.Field className='announcement-input'>
                            <input type="text"
                                placeholder="Type your announcement here"
                                className="centered-placeholder"
                                style={{
                                    border: "2px solid #1BB934",
                                    borderRadius: "7px"

                                }}
                                value={this.state.message}
                                onChange={(e) => {
                                    this.handleChange(e, { value: e.target.value });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            {!this.props.hideAnnounceeSelection && (
                                <Form.Field>
                                    <Dropdown
                                        closeOnEscape
                                        defaultValue={this.props.disallowRoomControls ? '' : this.props.roomOptions[0].value}
                                        value={this.state.selectedRooms}
                                        multiple
                                        clearable
                                        search
                                        scrolling
                                        selection
                                        options={sortByKey(this.props.roomOptions)}
                                        disabled={this.props.disallowRoomControls}
                                        onChange={(e, d) => this.handleDropdownChange(d.value)}
                                    />
                                </Form.Field>
                            )}
                        </Form.Field>
                            <Form.Group>
                                <Form.Field>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: "100px" }}>
                                        <Slider value={this.state.dismissalTimeOffsetMinutes} color="blue" settings={{
                                            start: 0,
                                            min: 5,
                                            max: 60,
                                            step: 1,
                                            onChange: value => {
                                                this.setState({
                                                    dismissalTimeOffsetMinutes: Number(value),
                                                    disableSubmit:
                                                        this.props.roomOptions.length === 1 || !(this.state.message && this.state.selectedRooms[0]) || !!this.state.errMsg,
                                                });
                                            }
                                        }} />
                                    </div>
                                    &nbsp;
                                    <p className='light-font'>{this.state.dismissalTimeOffsetMinutes} minutes on screen</p>
                                </div> 
                                </Form.Field>
                            </Form.Group>
                            <div style={{ width: "100%" }}>
                                <hr style={{ border: "0.05rem solid #979797", margin: "10px" }} />
                            </div>
                            <div className='align-center'>
                                {this.props.selectedAnnouncement && this.props.selectedAnnouncement.Recurrence ? (
                                    <Form.Field style={{ width: "150px" }}>
                                        <Dropdown
                                            style={{ marginTop: '17px' }}
                                            placeholder="Repeats"
                                            defaultValue={this.state.repeatType}
                                            fluid
                                            selection
                                            options={[
                                                {
                                                    key: 'Off',
                                                    text: 'Repeat off',
                                                    value: 'OFF',
                                                },
                                                {
                                                    key: 'Daily',
                                                    text: 'Repeat daily',
                                                    value: 'DAILY',
                                                },
                                                {
                                                    key: 'Weekly',
                                                    text: 'Repeat weekly',
                                                    value: 'WEEKLY',
                                                },
                                                {
                                                    key: 'Monthly',
                                                    text: 'Repeat monthly',
                                                    value: 'MONTHLY',
                                                },
                                            ]}
                                            onChange={(e, d) => {
                                                this.setState({
                                                    repeatType: String(d.value),
                                                    sendDeviceNotification: false,
                                                    disableSubmit: false,
                                                });
                                            }}
                                        />
                                    </Form.Field>
                                ) : (
                                    <Form.Field>
                                        <Checkbox
                                            label="Add to notifications"
                                            checked={this.state.sendDeviceNotification}
                                            onChange={(e, d) =>
                                                this.setState({
                                                    sendDeviceNotification: Boolean(d.checked),
                                                    disableSubmit: false,
                                                })
                                            }
                                        />
                                    </Form.Field>
                                )}
                                <div className='align-center' style={{ width: "100%" }}>
                                    <Grid columns={this.state.repeatType != 'OFF' ? 2 : 1} stackable centered >
                                        <Grid.Column style={{ display: 'flex', justifyContent: "center" }}>
                                            {this.renderStartDateTimePicker()}
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: "15px" }}>
                                            {this.renderEndDateTimePicker()}
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        {this.state.errMsg && <Message negative>{this.state.errMsg}</Message>}
                        {this.state.warnMsg && <Message color="grey">{this.state.warnMsg}</Message>}
                        <Form.Group>
                            <Form.Button type="submit" primary disabled={this.state.disableSubmit}>
                                {this.props.selectedAnnouncement ? 'Update' : 'Create'}
                            </Form.Button>
                            <Form.Button
                                type="button"
                                onClick={() => {
                                    this.props.handleModalClose();
                                }}
                            >
                                Cancel
                            </Form.Button>
                        </Form.Group>
                    </div>
            </Form>
        );
    }
}

export default UpdateNotificationsForm;