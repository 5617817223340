import React, { useEffect, useState } from 'react'
import { Icon, Label, Button } from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import { approveBroadcastMessage, rejectBroadcastMessage } from '../../../../services/Speak2Broadcaster'

type props = {
    type: string
    text?: string
    playableUrl?: string
    isDistributed: boolean
    isApproved: boolean
    isRejected: boolean
}
const Message = ({ type, text, playableUrl, isDistributed, isApproved, isRejected, _id, updateMessage }) => {
    const [isUpdating, setIsUpdating] = useState(false)

    const approve = async () => {
        setIsUpdating(true)
        await approveBroadcastMessage(_id)
        updateMessage(_id, true)
        setIsUpdating(false)
    }

    const reject = async () => {
        setIsUpdating(true)
        await rejectBroadcastMessage(_id)
        updateMessage(_id, false)
        setIsUpdating(false)
    }

    const TextMessage = ({ text }) => <div className="messageItem">{text}</div>

    const AudioMessage = ({ src }) => (
        <div className="messageItem">
            <audio controls src={src}></audio>
        </div>
    )

    return (
        <div className="messageItem">
            {type === 'text' ? <TextMessage text={text} /> : <AudioMessage src={playableUrl} />}
            {isDistributed ? (
                <div>
                    {isApproved && (
                        <Label>
                            <Icon color="green" name="checkmark" /> Approved
                        </Label>
                    )}
                    {isRejected && (
                        <Label>
                            <Icon color="red" name="close" /> Rejected
                        </Label>
                    )}
                </div>
            ) : (
                <div className="messageItem">
                    <Button onClick={() => approve()} loading={isUpdating}>
                        <Icon color="green" name="checkmark" /> Approve
                    </Button>
                    <Button onClick={() => reject()} loading={isUpdating}>
                        <Icon color="red" name="close" /> Reject
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Message
