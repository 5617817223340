import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Form, Grid, Icon, Image, Input, Loader, Modal, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createAsset, getOneAsset, updateAsset } from '../../services/Assets';
import ListDocsModal from '../AssetsGroupsManagement/ListDocsModal';
import { uploadAssetImageToS3 } from '../../services/ImageUpload';
import AvailabilityModal from '../Availability/AvailabilityModal';
import { removeUnsafeElementsFromFileName } from '../../util/image';
import { Asset } from '../../types/Asset';
interface DiningFormProps {
    openDiningForm: boolean;
    closeDiningForm: () => void;
    editId: string;
    facilityId: string;
    setRefreshPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const DiningForm: React.FC<DiningFormProps> = ({
    openDiningForm,
    closeDiningForm,
    editId,
    facilityId,
    setRefreshPage,
}) => {
    const [hoursModal, setHoursModal] = useState<boolean>(false);
    const [diningModalLoading, setDiningModalLoading] = useState<boolean>(false); // this is the loading state of the modal, not the loading state of the page
    const [file, setFile] = useState<File | undefined>();
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [imageName, setImageName] = useState<string>('');
    const [restaurantName, setRestaurantName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [maxCap, setMaxCap] = useState<string>('');
    const [docId, setDocId] = useState<string>('');
    const [docsModal, setDocsModal] = useState<boolean>(false);
    const [tableIds, setTableIds] = useState<string[]>([]);

    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const initialTimings = days.reduce((acc, day) => {
        acc[day] = { startTime: '7 : 00 AM', endTime: '7 : 00 PM' };
        return acc;
    }, {});
    const [newTimings, setNewTimings] = useState(initialTimings);
    const [timings, setTimings] = useState(initialTimings);

    useEffect(() => {
        getRoomTypes();
    }, [editId]);
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);
    const formatTimeRange = (response: Asset) => {
        const formattedTimeRange = days.reduce((acc, day) => {
            const dayAvailability = response.Availability.find((item) => item.DayOfWeek === day);
            if (dayAvailability) {
                acc[day] = { startTime: dayAvailability.StartTime, endTime: dayAvailability.EndTime };
            } else {
                acc[day] = { startTime: '', endTime: '' };
            }
            return acc;
        }, {});
        return formattedTimeRange;
    };
    const resetState = () => {
        setPreviewUrl('');
        setImageName('');
        setRestaurantName('');
        setDescription('');
        setMaxCap('');
        setUrl('');
        setTimings(initialTimings);
        setNewTimings(initialTimings);
        setDocId('');
        setFile(undefined);
        setTableIds([]);
    };
    const getRoomTypes = async () => {
        try {
            setDiningModalLoading(true);
            if (editId) {
                setPreviewUrl('');
                const { response, signedUrl } = await getOneAsset(editId);
                if (signedUrl) {
                    setPreviewUrl(signedUrl);
                }
                setImageName(response.ImageName || '');
                setRestaurantName(response.AssetName);
                setDescription(response.Description || '');
                setUrl(response.URL || '');
                setMaxCap(response.MaxCap.toString());
                setDocId(response.DocId || '');
                setTableIds(response && response.TableIds && response.TableIds.length > 0 ? response.TableIds : []);
                const formattedTimeRange = formatTimeRange(response);
                setTimings(formattedTimeRange);
                setNewTimings(formattedTimeRange);
            } else {
                resetState();
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error Fetching data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setDiningModalLoading(false);
        }
    };

    const handleChangeImage = async (files: FileList | null) => {
        if (files && files.length > 0) {
            setPreviewUrl('');
            const file = files[0];
            const formattedFileName = removeUnsafeElementsFromFileName(file.name);
            const fileBlob = new Blob([file], { type: file.type });
            const modifiedFile = new File([fileBlob], formattedFileName, { type: file.type });
            const previewUrl = URL.createObjectURL(file);
            setPreviewUrl(previewUrl);
            setFile(modifiedFile);
        }
    };

    const handleOnChange = (field: string, value: string) => {
        switch (field) {
            case 'restaurantName':
                setRestaurantName(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'url':
                setUrl(value);
                break;
            case 'maxCap':
                setMaxCap(value);
                break;
            default:
                break;
        }
    };

    const uploadImageFile = async (assetId: string): Promise<void> => {
        if (file) {
            await uploadAssetImageToS3(assetId, file.name, file);
        } else {
            throw new Error('Image file is not provided');
        }
    };

    const handleSubmit = async () => {
        const nameOfRestaurant = restaurantName.trim();
        const descriptionOfRestaurant = description.trim();
        if (Number(maxCap) <= 0) {
            toast.warn('Max cap and Min cap should not be less than or equal to zero.', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        } else if (!nameOfRestaurant || !descriptionOfRestaurant || !maxCap) {
            toast.warn('Please fill in the required details', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        try {
            setDiningModalLoading(true);
            const Availability = days
                .filter((day) => timings[day].startTime && timings[day].endTime)
                .map((day) => ({
                    DayOfWeek: day,
                    StartTime: timings[day].startTime,
                    EndTime: timings[day].endTime,
                }));

            const dataObj = {
                AssetName: restaurantName.trim(),
                AssetType: 'Restaurant',
                Description: description.trim(),
                URL: url.trim(),
                MinCap: 1, // Changed to a fixed value
                MaxCap: Number(maxCap),
                Availability,
                Facility: facilityId,
                DocId: docId,
                ImageName: imageName,
                TableIds: tableIds,
            };
            if (editId) {
                // Update existing asset
                if (file) {
                    await uploadImageFile(editId);
                    const response = await updateAsset({
                        _id: editId,
                        ...dataObj,
                        ImageName: file.name,
                    });
                    if (response) {
                        setRefreshPage((prev) => !prev);
                        toast.success('Successfully Updated Restaurant', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    } else {
                        throw new Error('Failed to update Restaurant');
                    }
                } else {
                    const response = await updateAsset({
                        _id: editId,
                        ...dataObj,
                    });
                    if (response) {
                        setRefreshPage((prev) => !prev);
                        toast.success('Successfully Updated Restaurant', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    } else {
                        throw new Error('Failed to update Restaurant');
                    }
                }
            } else {
                // Create new asset
                const createdAssetId = await createAsset(dataObj);
                if (!createdAssetId) {
                    throw new Error('Failed to create Restaurant');
                }
                if (createdAssetId && !file) {
                    resetState();
                    setRefreshPage((prev) => !prev);
                    toast.success('Successfully Created Restaurant', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                if (file) {
                    await uploadImageFile(createdAssetId);
                    const response = await updateAsset({
                        _id: createdAssetId,
                        ...dataObj,
                        ImageName: file.name,
                    });
                    if (response) {
                        resetState();
                        setRefreshPage((prev) => !prev);
                        toast.success('Successfully Created Restaurant', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    } else {
                        throw new Error('Failed to create Restaurant');
                    }
                }
            }
            closeDiningForm();
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Failed to Add Restaurant', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setDiningModalLoading(false);
        }
    };

    return (
        <Modal open={openDiningForm} onClose={closeDiningForm} size="mini">
            <Dimmer inverted active={diningModalLoading}>
                <Loader active={diningModalLoading} />
            </Dimmer>
            <Modal.Content>
                <Modal.Header>
                    <Grid>
                        <Grid.Column width={16} style={{ textAlign: 'left' }}>
                            <Input
                                id="imageInput"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => handleChangeImage(e.target.files)}
                            />
                            <div style={{ cursor: 'pointer' }}>
                                {!previewUrl && (
                                    <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                        <Icon name="images" size="huge" />
                                    </label>
                                )}
                                {previewUrl && (
                                    <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                        <Image src={previewUrl} size="medium" />
                                    </label>
                                )}
                            </div>
                        </Grid.Column>
                    </Grid>
                </Modal.Header>
                <Modal.Description>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <Form.Field required={true} style={{ marginTop: '20px' }}>
                            <label>Restaurant Name</label>
                            <Form.Input
                                value={restaurantName}
                                required={true}
                                placeholder="Restaurant Name"
                                onChange={(e) => handleOnChange('restaurantName', e.currentTarget.value)}
                            />
                        </Form.Field>
                        <Form.Field required={true} style={{ marginTop: '20px' }}>
                            <label>Description</label>
                            <Form.TextArea
                                value={description}
                                placeholder="Description"
                                required={true}
                                onChange={(e) => handleOnChange('description', e.currentTarget.value)}
                            />
                        </Form.Field>
                        <Form.Field style={{ marginTop: '20px' }}>
                            <label>URL</label>
                            <Form.Input
                                placeholder="URL"
                                value={url}
                                onChange={(e) => handleOnChange('url', e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field width={6} required={true} style={{ marginTop: '20px' }}>
                            <label>Max Cap</label>
                            <Form.Input
                                type="number"
                                min="1"
                                required={true}
                                placeholder="Max Cap"
                                value={maxCap}
                                onChange={(e) => handleOnChange('maxCap', e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field style={{ justifyContent: 'center', marginTop: '20px' }}>
                            <Segment
                                style={{
                                    width: '45%',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    gap: '10px',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setHoursModal(true)}
                            >
                                <img src="/assets-groups-management/calendar.png" alt="Image Input" />
                                <span style={{ fontSize: '18px' }}>Hours</span>
                            </Segment>
                        </Form.Field>
                        <Form.Field style={{ marginTop: '20px' }}>
                            <Segment
                                style={{
                                    backgroundColor: '#183466',
                                    width: '30%',
                                    height: '90%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setDocsModal(true)}
                            >
                                <img src="/assets-groups-management/copy-white.png" alt="Image Input" />
                                <span style={{ fontSize: '16px' }}>Docs</span>
                            </Segment>
                        </Form.Field>
                        <Modal.Actions style={{ marginTop: '20px' }}>
                            <div>
                                <Button type="submit" size={'small'}>
                                    {editId ? 'Update' : 'Save'}
                                </Button>
                                <Button size={'small'} style={{ marginLeft: '10px' }} onClick={closeDiningForm}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Actions>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <AvailabilityModal
                openAvailabilityModal={hoursModal}
                days={days}
                newTimings={newTimings}
                setNewTimings={setNewTimings}
                timings={timings}
                setTimings={setTimings}
                setOpenAvailabilityModal={setHoursModal}
            />
            {docsModal && <ListDocsModal open={docsModal} setOpen={setDocsModal} docId={docId} setDocId={setDocId} />}
        </Modal>
    );
};

export default DiningForm;
