import { NotepadSyncDataType } from "../types";
import { FilterServiceInstance } from "../types/ServiceInstance";
import { ServicesType } from "../types/ServicesTypes";
import { fetchAllPaginatedData } from "../util";
import API from './API';
import { getBaseUrlFromPresignedUrl } from "./service";

export const addServiceInstance = async (serviceInstance: Partial<ServicesType>, createAService: boolean = false): Promise<any> => {
    try {
        const res = await API.lambdaPost('/service-instance/add', { data: serviceInstance, createAService });
        return res;
    } catch (error) {
        console.error("Error while adding service instance", error);
        throw error;
    }
};

export const updateServiceInstance = async (serviceInstance: Partial<ServicesType>): Promise<any> => {
    try {
        if (serviceInstance._id && serviceInstance._id.length > 0) {
            const res = await API.lambdaPost('/service-instance/update', serviceInstance);
            return res;
        } else
            throw new Error("Id not found");
    } catch (error) {
        console.error("Error while updating service instance", error);
        throw error;
    }
};

export const deleteServiceInstance = async (filter): Promise<any> => {
    try {
        const res = await API.lambdaPost('/service-instance/delete', filter);
        return res;
    } catch (error) {
        console.error("Error while deleting service instance", error);
        throw error;
    }
};

export const fetchAllServiceInstance = async (Filter: Partial<FilterServiceInstance>): Promise<ServicesType[]> => {
    try {
        const serviceInstanceListApiCall = async (params: any) => { return await API.lambdaPost('/service-instance/list', params); };
        const response = await fetchAllPaginatedData(serviceInstanceListApiCall, { Filter });
        return response;
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }
};

export const getServiceInstancesTotCount = async (Filter: Partial<FilterServiceInstance>): Promise<number> => {
    try {
        const pagingSorting: any = {
            page_size: 1,
            page_no: 1,
        };
        const serviceInstanceTotCountObj = await API.lambdaPost('/service-instance/list', { Filter, pagingSorting });
        return serviceInstanceTotCountObj && serviceInstanceTotCountObj.TotRecords;
    } catch (error) {
        console.error("Error getting total count of service instances", error);
        throw error;
    }
};

export const fetchAllServiceInstanceAttendees = async (svcInstanceIds: string[]) => {
    try {
        // fetch all service instance attendees for the given service instance ids
        const allServiceInstanceListApiCall = async (params: any) => { return await API.lambdaPost('/service-instance/attendees/list', params); };
        const response = await fetchAllPaginatedData(allServiceInstanceListApiCall, { svcInstanceIds });
        return response;
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }
}

export const fetchAllMenuAndServiceInstance = async (Filter): Promise<any> => {
    try { 
        const serviceInstanceListApiCall = async (params: any) => { return await API.lambdaPost('/menu/listAll', params) };
        const response = await fetchAllPaginatedData(serviceInstanceListApiCall, { Filter });
        return response;
     
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }
};


export const fetchPaginatedMenuandSvcInstance = async ({page_no = 1, page_size = 10, type, filters = {}}): Promise<any> => {
    try {
        const Filter: any = filters;
        const pagingSorting: any = { page_no,
            page_size,
            sort: [{ startDateTimeString: type === 'active' ? 'desc' : 'asc' }]}
        const response = await API.lambdaPost('/menu/listAll', {Filter, pagingSorting});
        return response;
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }

}

export const fetchAllActivityAndServiceInstance = async (Filter): Promise<any> => {
    try { 
        const serviceInstanceListApiCall = async (params: any) => { return await API.lambdaPost('/dailyactivities/listAll', params) };
        const response = await fetchAllPaginatedData(serviceInstanceListApiCall, { Filter });
        return response;
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }
};


export const fetchPaginatedActivityandSvcInstance = async ({page_no = 1, page_size = 10, type, filters = {}}): Promise<any> => {
    try {
        const Filter: any = filters;
        const pagingSorting: any = { page_no,
            page_size,
            sort: [{ startDateTimeString: type === 'active' ? 'desc' : 'asc' }]}
        const response = await API.lambdaPost('/dailyactivities/listAll', {Filter, pagingSorting});
        return response;
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }

}

export const fetchServiceInstance = async (Filter): Promise<any> => {
    try {
        const { Result: response } = await API.lambdaPost('/service-instance/list', Filter);
        if (response && response.length > 0)
            return response[0];
        else
            throw new Error("No service instance found");
    } catch (error) {
        console.error("Error while fetching service instance", error);
        throw error;
    }
};

export const syncNotepadItemsToServiceInstances = async (notepadItemsArr: NotepadSyncDataType[]) => {
    try {
        const res = await API.lambdaPost('/notepad/sync', { notepadItems: notepadItemsArr });
        return res;
    } catch (error) {
        console.error('Error Fetching service instance', error);
        throw error;
    }
};

export const createSvcAttendee = async (serviceMenuAttendeeObj) => {
    try {
        const response = await API.lambdaPost('/service-instance/attendees/add', serviceMenuAttendeeObj);
        return response;
    } catch (error) {
        console.error('Error creating service instance attendee', error);
        throw error;
    }
};

export const updateSvcAttendee = async ({ attendeeObjId, status, svcInstanceId, svcType, declineReason = "", deliveryReason = "", participation = "", thisAndFollowingDate }) => {
    try {
        const params = {
            attendeeObjId,
            status,
            svcInstanceId,
            svcType,
            ...(declineReason && { declineReason }),
            ...(deliveryReason && { deliveryReason }),
            ...(participation && { participation }),
            ...(thisAndFollowingDate && { thisAndFollowingDate })
        };
        const response = await API.lambdaPut('/service-instance/attendees/update', params);
        return response;
    } catch (error) {
        console.error('Error updating service instance attendee', error);
        throw error;
    }
};

export const deleteSvcAttendee = async ({ id, svcType, svcInstanceId, thisAndFollowingDate }) => {
    try {
        const payload = {
            _id: id,
            svcType,
            svcInstanceId,
            ...(thisAndFollowingDate && { thisAndFollowingDate })
        }
        const response = await API.lambdaPut('/service-instance/attendees/delete', payload);
        return response;
    } catch (error) {
        console.error('Error creating service instance attendee', error);
        throw error;
    }
};

export const uploadServiceInstanceImage = async (file: File, svcInstanceId: string, Facility: string): Promise<any> => {
    try {
        const preSignedUrl = await API.lambdaPost('/service-instance/images/upload', { imageName: file.name, svcInstanceId, Facility });
        await API.putFile(preSignedUrl, file);
        const SignedUrl = getBaseUrlFromPresignedUrl(preSignedUrl);
        return SignedUrl;
    } catch (error) {
        console.error('uploadServiceImage error', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to upload service image');
    }
};

export const fetchServiceInstanceImage = async (svcInstanceId: string, fileName: string, Facility: string): Promise<any> => {
    try {
        const res = await API.lambdaGet('/service-instance/images/get', { svcInstanceId, fileName, Facility });
        return res;
    } catch (error) {
        console.error('Failed to fetch image for service type', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch image');
    }
};


export const upsertSignage = async (serviceId: string, signageUrl: string, update: boolean = false, imageKey: string = ''): Promise<any> => {
    try {
        const res = await API.lambdaPost('/service-instance/signage/upsert', { _id: serviceId, signageUrl, isUpdate: update, imageKey }); 
        return res;
    } catch (error) {
        console.error('upsert error', error);
        throw new Error(error instanceof Error ? error.message : 'Signage upsert error');
    }
};

export const reorderWaitingListAttendee = async ({ attendeeObjId, fromPosition, toPosition, svcInstanceId,  thisAndFollowingDate  }) => {
    try {
        const payload = {
            attendeeObjId,
            fromPosition,
            toPosition,
            svcInstanceId,
            ...(thisAndFollowingDate && { thisAndFollowingDate })
        }
        const response = await API.lambdaPost('/service-instance/attendees/reorder', payload);
        return response;
    } catch (error) {
        console.error('Error reordering service attendee', error);
        throw error;
    }
};