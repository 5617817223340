import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dimmer, Dropdown, Form, Grid, List, Loader, Popup, Segment } from 'semantic-ui-react'
import { listResidentAnalyzeData } from '../../services/Analyze'
import { fetchResidentCalendarEvents } from '../../services/Registrants'
import { fetchOneFacility } from '../../services/Facilities'
import { AuthState, ResidentCalendar } from '../../types'
import { Analyze } from '../../types/Analyze'
import moment from "moment-timezone"
import AnalyzeTable from '../ResidentAnalyze/AnalyzeTable'
import ResidentDatePickers from '../ResidentAnalyze/ResidentDatePickers'
import { DateRange } from '../CustomDatePicker'
import PrintExportIcons from '../PrintExportIcons'
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader'
import { toast } from 'react-toastify';
import AttendanceChart from './AttendanceChart'
import { sortByKey } from '../../util/sortData';
import { Asset } from '../../types/Asset';
import { listAssets } from '../../services/Assets';

interface PersonalEventData extends Analyze {
    isPersonalEvent: true;
}

interface FacilityEventData extends Analyze {
    isPersonalEvent: false;
}

type AttendanceDataGrouped = {
    personal?: PersonalEventData[];
    facility?: FacilityEventData[];
};

interface EventGroup {
    date: string;
    events: Analyze[];
}
interface Props {
    residentId: string
    residentName: string
}
const ResidentAttendance: FC<Props> = ({ residentId, residentName }) => {
    const [attendanceData, setAttendanceData] = useState<Analyze[]>([])
    const profile = useSelector((state: { authReducer: AuthState }) => state.authReducer.profile);
    const [isLoading, setIsLoading] = useState(false)
    const [dateRange, setDateRange] = useState<DateRange>({
        start: undefined,
        end: undefined
    })
    const [analysisData, setAnalysisData] = useState<Analyze[]>([])
    const [selectedType, setSelectedType] = useState<"menu" | "activity">("activity")
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [printLoading, setPrintLoading] = useState(false)
    const [assetDict, setAssetDict] = useState<{ [key: string]: Asset }>({});
    const facilityTimezone = profile && profile.FacilityTimeZone || "";

    useEffect(() => {
        (async () => {
            // todo below is fetching data one extra time, fix it if there is a huge performance down side
            setIsLoading(true)
            try {
                const [upNextEvents, attendanceData, allAssetsData] = await Promise.all([fetchUpNextData(), fetchDateFilterData(dateRange), listAssets()]);
                setAttendanceData(upNextEvents);
                const assetDict: { [key: string]: Asset } = {};
                allAssetsData.forEach((asset: Asset) => {
                    assetDict[asset._id] = asset;
                });
                setAssetDict(assetDict);
                setIsLoading(false)            
                setAnalysisData(attendanceData.data); 
                setIsFirstRender(false)
            } catch (error) {
                console.error(error)
                showWarningToast("Failed to get Ateendance information")
                setIsLoading(false)
            }
        })();
    }, []);

    useEffect(() => {
        if (isFirstRender) {
            return
        }
        (async () => {
            setIsLoading(true)
            try {
                const { data, totFacilityEvents } = await fetchDateFilterData(dateRange)
                const upNextEvents = await fetchUpNextData();
                setAttendanceData(upNextEvents);
                setAnalysisData(data)
            } catch (error) {
                console.error(error);
                showWarningToast("Failed to get Attendance information")
                setIsLoading(false);
            }
            setIsLoading(false)
        })()
    }, [dateRange, selectedType])

    async function fetchUpNextData() {
        try {
            const startDateTimeString = moment.tz(new Date(), facilityTimezone).format("YYYY-MM-DDTHH:mm:ss");
            const endDateTimeString = moment.tz(new Date(), facilityTimezone).add(24, 'hours').format("YYYY-MM-DDTHH:mm:ss");
            // Fetch data
            const analysisData = await listResidentAnalyzeData(selectedType, residentId, startDateTimeString, endDateTimeString);
            const residentCalendarData = await fetchResidentCalendarEvents(residentId, startDateTimeString, endDateTimeString);
            const formattedResidentCalendarData = residentCalendarData.map((residentCalendar) => {
                return {
                    eventName: residentCalendar.text,
                    eventTime: residentCalendar.dateTimeString,
                    host: "-", //## this has been asked to matt and needs to be updated based on what matt replies 
                    registered: '0',
                    attended: '0',
                    isPersonalEvent: true
                };
            });
            // Merge the data and return
            return [...analysisData.data, ...formattedResidentCalendarData];
        } catch (error) {
            console.error("Error fetching up next data", error);
            throw new Error(error instanceof Error ? error.message : "Failed to fetch up next data");
        }
    }
    

    async function fetchDateFilterData({ start, end }: DateRange) {
        // change the startDatetimpestamp to dateTimeString 
        const startDateTimeString = start ? moment.tz(start, facilityTimezone).startOf('day').format("YYYY-MM-DDTHH:mm:ss") : moment.tz(new Date(), facilityTimezone).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        // end date time should be end of the day with the time
        const endDateTimeString  = end ? moment.tz(end, facilityTimezone).endOf('day').format("YYYY-MM-DDTHH:mm:ss") : moment.tz(new Date(), facilityTimezone).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        return await listResidentAnalyzeData(selectedType, residentId, startDateTimeString, endDateTimeString)
    }

    function formatTimestamp(eventTime: string) {
        return moment.tz(eventTime, facilityTimezone).format("hh:mm A")
    }

    const DDOptions = [
        { key: 'activities', text: 'Activities', value: 'activity' },
        { key: 'menu', text: 'Dining', value: 'menu'},
    ]

    const showWarningToast = (message: string) => {
        toast.warn(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }
    function isPersonalEvent(data: Analyze): data is PersonalEventData {
        return data.isPersonalEvent || false;
    }

    const attendanceDataWithGrouping: AttendanceDataGrouped = attendanceData.reduce((groups, el) => {
        const key = isPersonalEvent(el) ? 'personal' : 'facility';
        if (!groups[key]) groups[key] = [];
        groups[key].push(el);
        return groups;
    }, {});

    const handlePrintClick = async () => {
        const printArr = analysisData.map(el => {
            return {
                Subject: el.eventName,
                StartTime: new Date(el.eventTime),
                StartDate: new Date(el.eventTime),
            }
        })
        setPrintLoading(true)
        try {
            await formatAndExportDataForPrint(printArr, `${residentName}-attendance`, facilityTimezone, selectedType)
        } catch (error) {
            console.error(error);
            showWarningToast("Failed to print, please try again later");
            setPrintLoading(false);
        }
        setPrintLoading(false)
    }


    const renderEvents = (attendanceData: Analyze[]): JSX.Element[] => {
        const eventGroups: EventGroup[] = [];
        attendanceData.forEach((event) => {
            const date = moment(event.eventTime).format('ddd, M.DD.YY');
            let eventGroup = eventGroups.find((group) => group.date === date);
            if (!eventGroup) {
                eventGroup = { date, events: [] };
                eventGroups.push(eventGroup);
            }
            eventGroup.events.push(event);
        });
        // sort data before rendering
        eventGroups.sort((a, b) => moment(a.date, 'MM/DD/YYYY').diff(moment(b.date, 'MM/DD/YYYY')));

        eventGroups.forEach((group) => {
            group.events.sort((a, b) => new Date(a.eventTime).getTime() - new Date(b.eventTime).getTime());
          });
        
        const renderedEventGroups: JSX.Element[] = [];
        eventGroups.forEach((group) => {
            const eventListItems = group.events.map((event, i) => (
                <List.Item key={i} style={{ borderBottom: "1px solid #ddd", paddingBottom: "5px" }}>
                    {/* //// The host value needs to be updated when the feature is built */}
                    {formatTimestamp(event.eventTime)} {event.eventName} {(event.host && event.host !== '-') ? `with ${event.host}`: ""} </List.Item>
            ));
            const header = <List.Header style={{color: "#adb5bd", fontSize: "14px", margin: "2px 0px", fontWeight: "600"}}>{group.date}</List.Header>;
            const list = <List>{eventListItems}</List>;
            renderedEventGroups.push(
                <div key={group.date}> 
                    {header}
                    {list}
                </div>
            );
        });

        return renderedEventGroups;
    };

    return (
        <div style={{ position: "relative" }}>
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading} />
            </Dimmer>
            <Grid columns={2} relaxed padded>
                <Grid.Column width={10}>
                    <AttendanceChart residentId={residentId} selectedType={selectedType} />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field style={{ marginTop: "5px" }}>
                        <Dropdown
                            selection
                            options={sortByKey(DDOptions)}
                            value={selectedType}
                            onChange={(e, { value }) => {
                                if (value) {
                                    setSelectedType(value as "menu" | "activity")
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={12}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <ResidentDatePickers dateRange={dateRange} setDateRange={setDateRange} residentName={residentName} />
                        <PrintExportIcons
                            exportData={(() => {
                                const exportAnalysisData = analysisData.map((obj) => {
                                    const date = moment.tz(obj.eventTime, facilityTimezone).format("MM/DD/YYYY");
                                    const time = moment.tz(obj.eventTime, facilityTimezone).format("hh:mm A");
                                    return {
                                        Event: obj.eventName,
                                        Host: obj.host,
                                        Date: date,
                                        Time: time,
                                        Location: assetDict && obj.AssetId && assetDict[obj.AssetId] ? assetDict[obj.AssetId].AssetName : "-",
                                        Registered: obj.registered,
                                        Attended: obj.attended,
                                    };
                                });
                                // Sort exportData by Date and then by Time
                                exportAnalysisData.sort((a, b) => {
                                    const dateA = moment(a.Date + ' ' + a.Time, 'MM/DD/YYYY hh:mm A');
                                    const dateB = moment(b.Date + ' ' + b.Time, 'MM/DD/YYYY hh:mm A');
                                    return dateA - dateB;
                                });                                
                                return exportAnalysisData;
                            })()}
                            exportFileName={`${residentName}-attendance`}
                            onPrintClick={handlePrintClick}
                            printLoading={printLoading}
                            disablePrintButton={true}                            
                        />
                    </div>
                    <AnalyzeTable data={analysisData} loading={false} type={selectedType} facilityTimezone={facilityTimezone} assetDict={assetDict}/>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Segment padded style={{ overflowY: "scroll", height: "50vh" }}>
                        <List celled relaxed>
                            <Popup
                                trigger={
                                    <List.Header style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "uppercase", marginBottom: "10px" }}>Up Next</List.Header>
                                }
                                content="Shows events in 24 hours"
                                size='tiny'
                                position='top center'
                                pinned
                            />
                            {attendanceData && attendanceData.length > 0 &&
                                <>
                                    {attendanceDataWithGrouping.personal &&
                                        <>
                                            <List.Header style={{ fontSize: "14px", fontWeight: "bold", textDecoration: "uppercase", margin: "10px 0px" }}>Personal Events</List.Header>
                                            {renderEvents(attendanceDataWithGrouping.personal)}
                                        </>
                                    }
                                    {attendanceDataWithGrouping.facility &&
                                        <>
                                            <List.Header style={{ fontSize: "14px", fontWeight: "bold", textDecoration: "uppercase", margin: "10px 0px" }}>Facility Events</List.Header>
                                            {renderEvents(attendanceDataWithGrouping.facility)}
                                        </>
                                    }
                                </>
                            }
                        </List>
                    </Segment>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default ResidentAttendance