import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Checkbox, Dropdown, Form, Grid } from 'semantic-ui-react'
import { Gadget } from '../../types';
import { distanceUnitData, localesData, speechConfirmationData, temperatureData, timezonesData, wakeWordsData } from './alexaDropdownData';
import DeviceStatus from './DeviceStatus';
import { updateSmartGadget } from "../../services/SmartGadgets"
import { sortByKey } from '../../util/sortData';
interface DeviceFormProps {
    device: Gadget
}

const ResidentAlexaForm: FC<DeviceFormProps> = ({ device }) => {
    const [formValues, setFormValues] = useState(device)
    const [initialState, setInitialState] = useState({ ...device })
    const [disableBtn, setDisableBtn] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const findChanges = (formValuesObj: Gadget, initialStateObj: Gadget) => {
        let changes: Partial<Gadget> = {}
        for (const [key, value] of Object.entries(formValuesObj)) {
            const isArray = Array.isArray(value);
            if (isArray) {
                if (initialStateObj[key] && initialStateObj.hasOwnProperty(key)) {
                    const joinedFormValue = value.join(".");
                    const joinedInitialValue = initialStateObj[key].join(".");
                    if (joinedFormValue !== joinedInitialValue) {
                        changes[key] = joinedFormValue.split(".");
                    }
                } else {
                    changes[key] = value;
                }
            } else {
                if (value !== initialStateObj[key]) {
                    changes[key] = value;
                }
            }
        }
        return changes
    }

    const handleFormSubmit = async () => {
        try {
            const changes = findChanges(formValues, initialState)
            if (!changes) return

            if (formValues.a4hRoomId) {
                setIsLoading(true)
                const updateRes = await updateSmartGadget(formValues.a4hRoomId, changes)
                Object.entries(updateRes).map(([key, value]) => {
                    const hasErrorMessage = typeof value === "string";
                    if (hasErrorMessage) {
                        toast.error(`Failed to update ${key}: ${value}`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        const initialValue = initialState[key]
                        setFormValues((prev) => ({ ...prev, [key]: initialValue }))
                    } else { // update the initial value if there is no error from BE
                        const formValue = formValues[key]
                        setInitialState((prev) => ({ ...prev, [key]: formValue }))
                    }
                })
            } else {
                throw new Error("a4h room is is not provided")
            }
        } catch (error) {
            console.error("handleFormSubmit", error)
            toast.error(error instanceof Error ? error.message : "Failed to update form", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false)
        }
    }
    const handleCheckboxChange = (field: string, checked: boolean) => {
        setFormValues(prev => ({
            ...prev,
            [field]: checked,
        }));
    };

    const handleDropDownChange = (field: string, value: string | string[]) => {
        const languagesField = field === "languages";
        const isArrayAndNoValueSelected = Array.isArray(value) && value.length === 0;
        const moreThan2ValuesSelectedForLang = languagesField && Array.isArray(value) && value.length > 2;

        let errorMessage = "Please select at least one option";
        if (languagesField && moreThan2ValuesSelectedForLang) {
            errorMessage = "Please select up to 2 languages. Alexa does not support more than 2 languages at a time.";
        } else if (languagesField && isArrayAndNoValueSelected) {
            errorMessage = "At least one language should be selected.";
        }
        if (isArrayAndNoValueSelected || moreThan2ValuesSelectedForLang) {
            toast.error(errorMessage, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return
        }
        setFormValues(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleVolumeChange = (e) => {
        const value = parseInt(e.target.value);
        if (isNaN(value) || value < 1) {
            setFormValues((prev) => ({
                ...prev,
                maximumVolumeLimit: "",
            }));
        } else if (value > 100) {
            setFormValues((prev) => ({
                ...prev,
                maximumVolumeLimit: 100,
            }));
        } else {
            setFormValues((prev) => ({
                ...prev,
                maximumVolumeLimit: value,
            }));
        }
    };
    useEffect(() => {
        const changes = findChanges(formValues, initialState)
        if (changes && Object.keys(changes).length) {
            setDisableBtn(false)
        } else {
            setDisableBtn(true)
        }
    }, [formValues, initialState])

    return (
        <Form loading={isLoading}>
            {/* \u00A0\u00A0\u00A0 is symobl for space in html */}
            <p>Device Serial Number: {'\u00A0\u00A0\u00A0' + formValues.serialNumber}</p>
            <p>MAC Address: {'\u00A0\u00A0\u00A0' + `${formValues.macAddress}`}</p>
            <p>Connection Status: {<span style={{ color: `${formValues.status === "OFFLINE" ? "red" : "green"}`, fontWeight: "bold" }}>{'\u00A0\u00A0\u00A0' + formValues.status}</span>}</p>
            <Grid columns={4} relaxed padded>
                <Form.Field>
                    <label>Languages</label>
                    <Dropdown
                        selection
                        options={sortByKey(localesData)}
                        value={formValues.languages || 'none'}
                        multiple
                        onChange={(e, { value }) => {
                            if (value && Array.isArray(value)) {
                                handleDropDownChange('languages', value.map(v => String(v)));
                            }
                        }}
                        search
                    />
                </Form.Field>
                <Form.Field>
                    <label>Wake Words</label>
                    <Dropdown
                        selection
                        options={sortByKey(wakeWordsData)}
                        value={formValues.wakeWords}
                        multiple
                        onChange={(e, { value }) => {
                            if (value && Array.isArray(value)) {
                                handleDropDownChange('wakeWords', value.map(v => String(v)));
                            }
                        }}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Maximum Volume</label>
                    <input
                        type={"number"}
                        value={formValues.maximumVolumeLimit === "" ? 0 : formValues.maximumVolumeLimit}
                        onChange={handleVolumeChange}
                        min={1}
                        max={100}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Speech Confirmation</label>
                    <Dropdown
                        selection
                        options={sortByKey(speechConfirmationData)}
                        value={formValues.speechConfirmation}
                        onChange={(e, { value }) => {
                            if (value) {
                                handleDropDownChange('speechConfirmation', String(value));
                            }
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Temperature</label>
                    <Dropdown
                        selection
                        options={sortByKey(temperatureData)}
                        value={formValues.temperatureUnit}
                        onChange={(e, { value }) => {
                            if (value) {
                                handleDropDownChange('temperatureUnit', String(value));
                            }
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Distance Unit</label>
                    <Dropdown
                        selection
                        options={sortByKey(distanceUnitData)}
                        value={formValues.distanceUnits}
                        onChange={(e, { value }) => {
                            if (value) {
                                handleDropDownChange('distanceUnits', String(value));
                            }
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Time Zone</label>
                    <Dropdown
                        selection
                        options={sortByKey(timezonesData)}
                        value={formValues.timeZone}
                        onChange={(e, { value }) => {
                            if (value) {
                                handleDropDownChange('timeZone', String(value));
                            }
                        }}
                        search
                    />
                </Form.Field>
                <Form.Field>
                    <label>Do Not Disturb</label>
                    <Checkbox toggle checked={formValues.doNotDisturb} onChange={(e, { checked }) => {
                        if (checked !== undefined) {
                            handleCheckboxChange("doNotDisturb", checked)
                        }
                    }} />
                </Form.Field>
                <Grid.Column width={16}>
                    <Button type="button" onClick={handleFormSubmit} color='blue' disabled={disableBtn}>Save</Button>
                </Grid.Column>
            </Grid>
            {formValues.a4hRoomId ? <DeviceStatus gadget={formValues} /> : <></>}
        </Form>
    )
}

export default ResidentAlexaForm