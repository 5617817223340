import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { Registrant, UserProfile } from '../../../types'
import { kanbanDataType } from '../../../types/kanbanDataTypes'

import { listCards, updateCard, createCard } from '../../../services/CheckinCards'
import { fetchAllActiveFacilityRegistrants } from '../../../services/Registrants'
import { fetchResidentRooms } from '../../../services/Facilities'
import { fetchOneFacility } from '../../../services/Facilities'
import { sendAnnouncement } from '../../../services/Notifications'
import { fetchAllActiveUsers } from '../../../services/Users'

import {
    Divider,
    Dimmer,
    Loader,
    Tab
} from 'semantic-ui-react'

import CheckinKanbanBoard from './checkinKanbanBoard'
import checkinKanbanInitData from './checkinKanbanInitData'
import CheckinFiltersAndAnnouncementButton from './checkinFilters'
import Content from '../../../components/Content'
import { getScheduledNotifications, updateScheduledNotifications } from '../../../services/ScheduledNotifications'
import { ScheduledNotificationType } from '../../../types/scheduledNotification'
import { jsonTocsvDownloaderV2 } from '../../../util/jsonTocsvDownloader';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import { sendToast } from '../../../util';

interface Props {
    profile: UserProfile | null
}

interface CheckInPageProps extends Props, RouteComponentProps {}
interface State {
    kanbanBoard?: kanbanDataType
    allFacilityResidents?: any
    isLoading: boolean
    facilityCards: any[]
    readOnlyColNamesList: any
    filters: any
    exportData: any
    isExporting: boolean
    facilityTimeZone: string | undefined
    staffDict?: any
    residentRoomDict?: any
}

class Checkin extends React.Component<Props, State> {
    constructor (props) {
        super(props)
        this.state = {
            kanbanBoard: checkinKanbanInitData,
            isLoading: false,
            facilityCards: [],
            readOnlyColNamesList: checkinKanbanInitData.columns.map(column => {
                return column.name_string
            }),
            filters: {
                selectedDate: moment().format('YYYY-MM-DD'),
                resident: 'all'
            },
            exportData: [],
            isExporting: false,
            facilityTimeZone: undefined
        }
    }

    getProcessedCard(rawCard, residentRooms, allFacilityResidents) {
        const room = residentRooms.find(roomObj => roomObj.ResidentId === rawCard.registrantId);
        // fetch latest resident info from allFacilityResidents
        const residentObj = allFacilityResidents.find(resident => String(resident._id) === String(rawCard.registrantId));
        const title = residentObj ? residentObj.FirstName + ' ' + residentObj.LastName : "-";
        return {
            ...rawCard,
            key: rawCard._id,
            id: rawCard._id,
            title: title,
            description: moment.tz(rawCard.timestamp, this.state.facilityTimeZone).format('MM/DD/YYYY hh:mm:ss A'),
            roomId: room ? room.RoomId : undefined,
            roomName: room ? room.RoomName : undefined
        };
    }

    getCardFromResidentObj (residentObj, residentRooms) {
        const room = residentRooms.find(roomObj => roomObj.ResidentId === residentObj._id)
        return {
            column: 'not_checked_in',
            registrant: {
                _id: residentObj._id,
                FirstName: residentObj.FirstName,
                LastName: residentObj.LastName
            },
            Facility: residentObj.Facility,
            registrantId: residentObj._id,
            key: 'all-residents-' + residentObj._id,
            id: 'all-residents-' + residentObj._id,
            title: residentObj.FirstName + ' ' + residentObj.LastName,
            roomId: room ? room.RoomId : undefined,
            roomName: room ? room.RoomName : undefined
        }
    }

    getBoardFromCards (allFacilityResidents, rawCards, residentRooms) {
        
        // Make a copy of kanban board to set state
        const localKanbanBoard = JSON.parse(JSON.stringify(checkinKanbanInitData))

        const residentCards = Array<any>();
        // Format cards and add to relevant columns in kanban board copy
        rawCards.forEach(rawCard => {
            const processedCard = this.getProcessedCard(rawCard, residentRooms, allFacilityResidents)
            residentCards.push(processedCard);
            let correctColIndex = this.state.readOnlyColNamesList.indexOf(processedCard.column)
            localKanbanBoard.columns[correctColIndex].cards.push(processedCard)
        })
        
        let filteredResidents = allFacilityResidents.filter(resident => {
            return !rawCards.some(card => {
                return String(resident._id) === card.registrantId
            })
        })

        filteredResidents.forEach(resident => {
            const residentCard = this.getCardFromResidentObj(resident, residentRooms)
            const correctColIndex = this.state.readOnlyColNamesList.indexOf(residentCard.column)
            residentCards.push(residentCard);
            localKanbanBoard.columns[correctColIndex].cards.push(residentCard)
        })
        this.setState({exportData: residentCards})
        
        // Return kanban board with processed cards
        return localKanbanBoard
    }

    async fetchCards(FacilityTimeZone: string) {
        try {
            const filters = {
                startTimestamp: moment.tz(this.state.filters.selectedDate, FacilityTimeZone).startOf('day').valueOf(),
                endTimestamp: moment.tz(this.state.filters.selectedDate, FacilityTimeZone).endOf('day').valueOf(),
                resident: 'all'
            };
            const localFacilityCards = await listCards(this.props.profile && this.props.profile.Facility, filters);
            const localAllFacilityResidents = await fetchAllActiveFacilityRegistrants(this.props.profile && this.props.profile.Facility || "", true /* excludeAlisImage */, true /* includeRoomInfo */); // || "" is not an issue as if the user has no profile (it means the user has not logged in) the user cannot access this page
            const residentRooms = localAllFacilityResidents.map(resident => {
                if (resident.Unit) {
                    return {
                        RoomName: resident.Unit.Name,
                        RoomId: resident.Unit.a4hRoomId,
                        Resident: resident.FirstName + " " + resident.LastName,
                        ResidentNotes: resident.Notes,
                        ResidentId: String(resident._id),
                        A4hCommProfileId: resident.a4hCommProfileId
                    };
                }
            }).filter(Boolean);
        
            //store resident rooms in a dictionary for easy access
            const residentRoomDict = {};
            residentRooms.forEach(room => {
                residentRoomDict[room.ResidentId] = room;
            });

            this.setState({ residentRoomDict });
            localAllFacilityResidents.sort((a, b) => {
                const aLastName = a && a.LastName ? a.LastName : '';
                const bLastName = b && b.LastName ? b.LastName : '';
                return aLastName < bLastName ? -1 : aLastName > bLastName ? 1 : 0;
            });
            this.setState({
                allFacilityResidents: localAllFacilityResidents,
                kanbanBoard: this.getBoardFromCards(localAllFacilityResidents, localFacilityCards, residentRooms)
            });
        } catch (error) {
            console.error(error);
        }
    }

    async updateCheckinCard (newCard) {
        try {
            delete newCard.id
            delete newCard.key
            delete newCard.title
            delete newCard.description
            
            const updateCardRes = await updateCard(newCard)
            
        } catch (error) {
            console.error(error)
        }
    }

    async createNewCheckinCard (destinationColIndex, card) {
        try {
            const destinationColName = this.state.readOnlyColNamesList[destinationColIndex]
            const cardCopy = JSON.parse(JSON.stringify(card))
            cardCopy.column = destinationColName

            delete cardCopy.id
            delete cardCopy.key
            delete cardCopy.title
            delete cardCopy.description

            const createCardResponse = await createCard(cardCopy)

            return createCardResponse
        } catch (error) {
            console.error(error)
        }
    }

    handleFilterChange (filterObj) {
        const localFilters = JSON.parse(JSON.stringify(this.state.filters))
        this.setState({
            filters: {
                ...localFilters,
                ...filterObj
            }
        })
    }

    async handleFiltersSubmit () {
        this.setState({ isLoading: true })
        const filters = {
            startTimestamp: moment.tz(this.state.filters.selectedDate, this.state.facilityTimeZone).startOf('day').valueOf(),
            endTimestamp: moment.tz(this.state.filters.selectedDate, this.state.facilityTimeZone).endOf('day').valueOf(),
            resident: this.state.filters.resident
        }        
        const localAllFacilityResidents = JSON.parse(JSON.stringify(this.state.allFacilityResidents))
        const filteredRawCards = await listCards(this.props.profile && this.props.profile.Facility, filters)
        const residentRooms = await fetchResidentRooms()
        const filteredFacilityResidents: Partial<Registrant>[] = (filters.resident !== 'all') ? localAllFacilityResidents.filter((resident: Partial<Registrant>) => (String(resident._id) === filters.resident)) : localAllFacilityResidents
        this.setState({ 
            isLoading: false,
            kanbanBoard: this.getBoardFromCards(filteredFacilityResidents, filteredRawCards, residentRooms)
        })
    }
    
    async handleFiltersReset () {
        this.setState({
            filters: {
                selectedDate: moment.tz(Date.now(), this.state.facilityTimeZone).format('YYYY-MM-DD'),
                resident: 'all'
            }
        })
        this.setState({ isLoading: true });
        if (this.state.facilityTimeZone)
            await this.fetchCards(this.state.facilityTimeZone);
        this.setState({ isLoading: false });
    }

    async handleSendAnnouncement () {
        this.setState({isLoading: true})

        if (this.props.profile) {
            const facilityFetchResponse = await fetchOneFacility(this.props.profile.Facility);
            const facilityName = facilityFetchResponse.FriendlyName || facilityFetchResponse.Name;
            let roomIds:any[] = []
            if (this.state.kanbanBoard) {
                roomIds = this.state.kanbanBoard.columns[0].cards.map(card => card.roomId)
                roomIds = roomIds.filter(roomId => roomId !== undefined)
                const message = `Hi, I hope you're having a great day. This is an announcement from ${facilityName}. We haven't heard from you today. Can you please check in with your Alexa?`;
                if(roomIds.length) {
                    await sendAnnouncement(message, roomIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
                }
            }
        }
        this.setState({isLoading: false})
    }

    formatExportData(exportData) {
        const formattedExportData = exportData
            .sort((a, b) => moment.tz(b.dateTimeString, this.state.facilityTimeZone).valueOf() - moment.tz(a.dateTimeString, this.state.facilityTimeZone).valueOf())
            .map(exportDataItem => {
                const staffId = exportDataItem.AddedBy || exportDataItem.UpdatedBy;
                const StaffName = this.state.staffDict[staffId] || "self";
                const status = exportDataItem.column === 'checked_in' ? 'Yes' : 'No';
                // fetch the latest resident details from allFacilityResidents
                const residentData = this.state.allFacilityResidents && this.state.allFacilityResidents.find(resident => String(resident._id) === String(exportDataItem.registrantId)) || {};
                return {
                    "Registrant FirstName": residentData.FirstName && residentData.FirstName || "-",
                    "Registrant LastName": residentData.LastName &&  residentData.LastName || "-",
                    "Apartment": exportDataItem.roomName,
                    "Staff Name": StaffName,
                    "Status": status,
                    "Date": exportDataItem.timestamp ? moment.tz(exportDataItem.timestamp, this.state.facilityTimeZone).format("MM-DD-YYYY") : "-",
                    "Time": exportDataItem.timestamp ? moment.tz(exportDataItem.timestamp, this.state.facilityTimeZone).format("hh:mm A") : "-",
                };
            });
        return formattedExportData;
    }

    async fetchStaff() {
        try {
            const allStaffUsersData = await fetchAllActiveUsers();
            const tempStaffDict = {};
            allStaffUsersData.forEach((user) => {
                tempStaffDict[user._id] = `${user.FirstName} ${user.LastName}`;
            });
            this.setState({ staffDict: tempStaffDict });
        } catch (error) {
            console.log("Error fetching staff data", error);
            sendToast("error", "Something went wrong while fetching staff data. Please try again later.")
        }
    }


    async handleExport() {
        try {
            this.setState({ isExporting: true });
            if (!this.state.facilityTimeZone) {
                throw new Error("Facility time zone is not set. Please contact support.");
            }
            await this.fetchCards(this.state.facilityTimeZone);
            await this.fetchStaff();           
            const formattedExportData = this.formatExportData(this.state.exportData);
            jsonTocsvDownloaderV2(formattedExportData, "Check-in", true);
        } catch (error) {
            console.log(error);
            toast.error(error.message || 'Could not export data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            this.setState({ isExporting: false });
        }

    }

    async updateBoard (updatedBoard) {
        this.setState({
            kanbanBoard: updatedBoard
        })
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        const facilityId = this.props.profile && this.props.profile.Facility;
        const facilityData: any = (facilityId && await fetchOneFacility(facilityId)) || {};
        const FacilityTimeZone = facilityData.FacilityTimeZone;
        this.setState({ facilityTimeZone: FacilityTimeZone });
        const selectedDate = moment.tz(Date.now(), FacilityTimeZone).format('YYYY-MM-DD');
        this.setState({
            filters: {
                selectedDate,
                resident: 'all'
            }
        })
        await this.fetchCards(FacilityTimeZone)
        this.setState({ isLoading: false })
    }

    render () {
        return (
            <>
                <Dimmer active={this.state.isLoading} inverted>
                    <Loader active={this.state.isLoading} />
                </Dimmer>
                <Divider />
                <CheckinFiltersAndAnnouncementButton 
                    filters={this.state.filters}
                    handleFilterChange={this.handleFilterChange.bind(this)}
                    handleFiltersSubmit={this.handleFiltersSubmit.bind(this)}
                    handleFiltersReset={this.handleFiltersReset.bind(this)}
                    residentsOptionsList={this.state.allFacilityResidents}
                    handleSendAnnouncement={this.handleSendAnnouncement.bind(this)}
                    handleExport={this.handleExport.bind(this)}
                    isExporting={this.state.isExporting}
                    currentDate={moment.tz(Date.now(), this.state.facilityTimeZone).format('YYYY-MM-DD')}
                />
                <CheckinKanbanBoard 
                    board={this.state.kanbanBoard}
                    updateCard={this.updateCheckinCard.bind(this)}
                    colNamesList={this.state.readOnlyColNamesList}
                    preventMovementToCol={'all_residents'}
                    exceptedSourceColName={'all_residents'}
                    exceptionMoveCardFunc={this.createNewCheckinCard.bind(this)}
                    updateSourceBoard={this.updateBoard.bind(this)}
                    selectedDate={this.state.filters.selectedDate}
                    facilityTimeZone={this.state.facilityTimeZone}
                />
            </>
        )
    }
}

const CheckInPage: FC<CheckInPageProps> = ({ profile }) => {
    const [scheduledNotification, setScheduledNotification] = useState<ScheduledNotificationType>({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async function () {
            await refreshNotifications()
        })()
    }, [])
    const handleNotificationsContentSubmit = async (scheduledNotifications: string[]) => {
        setLoading(true)
        try {
            await updateScheduledNotifications({
                alerts: scheduledNotifications,
                facilityId: profile && profile.Facility, ///don't allow submit if facilityId doesn't exist 
                ...(scheduledNotification._id ? { _id: scheduledNotification._id } : {})
            })
            await refreshNotifications()
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    async function refreshNotifications() {
        setLoading(true)
        const scheduledNotifications = await getScheduledNotifications({facilityId: profile && profile.Facility}) || {alerts: []}
        setScheduledNotification(scheduledNotifications)
        setLoading(false)
        return scheduledNotifications
    }

    const contentExistsInScheduleNotification = (type: string): boolean => {
        return scheduledNotification && scheduledNotification.alerts && scheduledNotification.alerts.includes(type)
    }

    const panes = [{
        menuItem: "Checkin",
        render: () => {
            return profile ? <Checkin profile={profile} /> : <></>
        }
    }, {
            menuItem: "Content",
            render: () => {
                return <Content
                    view='list'
                    showSubmitButton
                    handleNotificationsContentSubmit={handleNotificationsContentSubmit}
                    Menu={contentExistsInScheduleNotification("menu")}
                    Activities={contentExistsInScheduleNotification("activities")}
                    CommunityMessages={contentExistsInScheduleNotification("affirmations")}
                    Birthdays={contentExistsInScheduleNotification("birthdays")}
                    Rotm={contentExistsInScheduleNotification("rotm")}
                    Sotm={contentExistsInScheduleNotification("sotm")}
                    QuestionOfTheDay={contentExistsInScheduleNotification('questionOfTheDay')}
                />
        }
    }]

    return <>
        <Dimmer active={loading} inverted>
            <Loader active={loading} />
        </Dimmer>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </>
}

export default withRouter(CheckInPage)
