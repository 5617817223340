import API from './API'
import { FetchRequestsResponse } from '../types'

import { Request } from '../types'
import moment from 'moment-timezone';
import { fetchAllPaginatedData } from '../util'

interface getTimestampLimitsResponse {
    startTimestamp: Number
    endTimestamp: Number
}

interface requestFilters {
    RequestedTime?: object
    Registrant?: string
    Type?: string | null | object
    Details?: object
    Disable? : boolean | null
}

interface requestOptions {
    Filter: requestFilters
    QueryOptions: {
        page_no: number
        page_size: number
        sort: object[]
    }
}

function parseFetchResponse(response: any, page: number = 1, limit: number = 100): FetchRequestsResponse {
    const res: FetchRequestsResponse = {
        count: response.TotRecords || 0,
        page,
        limit,
        requests: response.Result,
    }

    return res
}

/**
 * Identifies the start and end timestamps of the local date
 *
 *
 */
function getTimestampLimits(fromDate: Date, toDate: Date, facilityTimezone) {
    if (facilityTimezone) {
        // extract raw date string from the date object
        const fromDateString = moment(fromDate).format('YYYY-MM-DD')
        const toDateString = moment(toDate).format('YYYY-MM-DD')
        return {
            startTimestamp: moment.tz(fromDateString, facilityTimezone).startOf('day').valueOf(),
            endTimestamp: moment.tz(toDateString, facilityTimezone).endOf('day').valueOf(),
        }
    } else {
        return {
            startTimestamp: moment(fromDate).startOf('day').valueOf(),
            endTimestamp: moment(toDate).endOf('day').valueOf(),
        }
    }
}

export const TypeDictionary = { // grouping the types for the reports page
    'Wellness Check': ['Ate', 'Awake', 'checkin_exercise', 'checkin_film', 'checkin_game', 'checkin_meal', 'checkin_meds', 'checkin_sleep', 'checkin_stretch', 'checkin_tv', 'checkin_wake', 'checkin_walked', 'checkin_water', 'checkin_yoga', 'Game', 'Meds', 'Movie', 'Sleep', 'Walk', 'Water', 'Yoga', 'Stretch', 'TV'],
    'Check in': ['Check in', 'CustomYesIntent', 'Daily Checkin', 'Feeling bad', 'ImOk', 'wellness_bad'],
    'Attendance': ['Close Activity', 'Daily Activities Attendance', 'DailyActivities', 'DailyActivitiesAttendance', 'Exercise', 'List Activity Attendees', 'Start Activity'],
    'Messages': ['Message', 'Heard Messages', 'Sent Message'],
    'Calendar': ['Calendar', 'Read Activities', 'Resident Calendar'],
    'Notepad': ['Staff Visit', 'Staff_Visit', 'Unscheduled Event', 'Unscheduled Interaction'],
    'Speak2 Support': ['Video', 'Speak2 support']
};

export async function fetchRequests(
    fromDate: Date | null,
    toDate: Date | null,
    resident: string | null,
    food: string | null,
    type: string | null | object,
    disable : boolean | null,
    page_no = 1,
    page_size = 100,
    sortBy: object[] = [{ RequestedTime: 'asc' }],
    isReport: boolean = false,
    facilityTimezone: string | null = null,
): Promise<FetchRequestsResponse> {
    const requestOptions: requestOptions = {
        Filter: {},
        QueryOptions: {
            page_no,
            page_size,
            sort: sortBy,
        },
    }

    if (type) {
        if (isReport) {
            if (TypeDictionary[type as string]) { // check if the type is in the dictionary and filter by the values
                requestOptions.Filter.Type = { $in: TypeDictionary[type as string] };
            } else {
                requestOptions.Filter.Type = type;
            }
        } else {
            requestOptions.Filter.Type = type;
        }
    } else {
        if (isReport) // Only for reports page
            requestOptions.Filter.Type = { $ne: 'Question of the day' }; // Since we are already fetching the question of the day separately we skip it from basic requests
    }

    if (fromDate && toDate) {
        const { startTimestamp, endTimestamp } = getTimestampLimits(fromDate, toDate, facilityTimezone)
        requestOptions.Filter.RequestedTime = { $gte: startTimestamp, $lte: endTimestamp }
    }

    if (resident) {
        requestOptions.Filter.Registrant = resident
    }

    if (food) {
        requestOptions.Filter.Details = { food: food }
    }

    if (disable) {
        requestOptions.Filter.Disable = disable 
    }

    const res = await API.lambdaPost(`/basic-requests/list`, requestOptions)
    const parsed = parseFetchResponse(res)
    return parsed
}

export async function fetchAllRequests({
    fromDate = null,
    toDate = null,
    resident = null,
    food = null,
    type = null,
    disable = null,
    sortBy = [{ RequestedTime: 'asc' }],
}: {
    fromDate: number | null; // timestamp
    toDate: number | null; //timestamp
    resident?: string | object | null;
    food?: string | null;
    type?: string | null;
    disable?: boolean | null;
    sortBy?: object[];
}): Promise<Request[]> {
    if (!fromDate || !toDate) throw new Error('Missing date filter for fetching content');
    const params: any = {
        Filter : {
            RequestedTime: {
                $gte: fromDate,
                $lte: toDate,
            },
        },
    } 
    if (resident) {
        params.Filter.Registrant = resident
    }
    if (food) {
        params.Filter.Details = { food }
    }
    if (type) {
        params.Filter.Type = type
    }
    if (disable) {
        params.Filter.Disable = disable
    }
    const requests = await fetchAllPaginatedData(
        async (params: any) => {
            return await API.lambdaPost('/basic-requests/list', params)
        },
        params,
        sortBy,
    )
    return requests as Request[];
}
