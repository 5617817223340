import React, { FC, useEffect, useState } from 'react'
import { Menu, Popup } from 'semantic-ui-react'
import { SidebarIcons, SysadminIcons } from '../../types/sidebar';
import SidebarIcon from '../SidebarIcon';
import "./style.less"
import { Link } from 'react-router-dom';
import SubMenuItem from './SubMenuItem';
interface MenuItemProps {
  iconName: SidebarIcons | SysadminIcons
  link: string;
  name: string;
  disable: boolean;
}
const MenuItem: FC<MenuItemProps> = ({ iconName, name, disable }) => {
  const [isHovering, setIsHovering] = useState(false)
  const spotlightIcons: { name: string; path: string; iconName: SidebarIcons; }[] = [
    {
      name: 'Birthdays',
      iconName: 'birthdays',
      path: '/admin/birthdays'
    },
    {
      name: 'Resident Spotlight',
      iconName: 'ROTM',
      path: '/admin/resident-of-the-month'
    },
    {
      name: 'Staff Spotlight',
      iconName: 'SOTM',
      path: '/admin/staff-of-the-month'
    }
  ]
  const onMouseEnter = () => setIsHovering(true)

  const onMouseLeave = () => setIsHovering(false)

  return (
    <div onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
      <Menu.Item
        disabled={disable}
        className='menu-item'
        style={{ display: "flex", justifyContent: "center", backgroundColor: !isHovering ? "white" : '#2987CD', border: "1px solid #2987CDCC" }}
      >
        <Popup
          content={
            name === 'Spotlight' ?
              spotlightIcons.map((subIcon) => {
                return (
                  <Link to={subIcon.path}>
                    <SubMenuItem
                      name={subIcon.name}
                      disable={disable}
                      iconName={subIcon.iconName}
                    />
                  </Link>
                );
              })
              :
              name === 'AI Triggers' ?
                name.replace(name[0], name[0].toUpperCase()) + ' - Coming Soon' :
                name.replace(name[0], name[0].toUpperCase())
          } // Capitalise the frist letter
          trigger={
            <>
              <SidebarIcon white={isHovering} name={iconName} />
            </>
          }
          open={isHovering}
          size={"tiny"}
          position="right center"
        />
      </Menu.Item>
    </div>
  )
}

export default MenuItem