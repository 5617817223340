import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import moment from 'moment-timezone';

import { Dimmer, DropdownProps, Grid, Loader, Segment } from 'semantic-ui-react';
import CustomTable from '../CustomTable';

import { DailyActivity, Registrant, Request, User } from '../../types';
import { ServicesType } from '../../types/ServicesTypes';
import { RequestInstance } from '../../types/RequestInstance';
import { Asset } from '../../types/Asset';

import { fetchAllActivities, listActivityAttendeesForCustomFilter } from '../../services/DailyActivities';
import { fetchAllActiveUsers } from '../../services/Users';
import { fetchAllActiveFacilityRegistrants } from '../../services/Registrants';
import { fetchOneFacility } from '../../services/Facilities';
import { fetchAllServiceInstance } from '../../services/ServiceInstances';
import { listAssets } from '../../services/Assets';
import { fetchRequestsAndReqInstances } from '../../services/Requests';
import { listCards } from '../../services/CheckinCards';
import { fetchAllPaginatedData, findKeyByValue, sendToast } from '../../util';
import './style.less';
import { CheckinCard } from '../../types/CheckinCard';
import { fetchAllRequests } from '../../services/Basics';
import { fetchAllAnnouncementAnalytics, fetchAllMessageAnalytics } from '../../services/Analytics';
import { Message } from '../../types/Analytics';
import { fetchRequestInstance } from '../../services/RequestInstances';
import { prntyrLink } from '../../util/data';

interface Props {
    startDate: Date | undefined;
    endDate: Date | undefined;
}

interface TableItem {
    Resident: string;
    'Day/Time': string;
    Interaction: string;
    Status: string;
    Staff: string;
    Length: string;
    Location: string;
}

const ReportsResidentInteractions: FC<Props> = ({ startDate, endDate }) => {
    // requests made by the selected residents
    const [requests, setRequests] = useState<TableItem[]>([]);
    
    // attendance data for the selected residents
    const [activityDict, setActivityDict] = useState<{ [key: string]: (DailyActivity | ServicesType) }>({});
    const [attendanceData, setAttendanceData] = useState<TableItem[]>([]);

    // check in data for the selected residents
    const [checkinItems, setCheckinItems] = useState<TableItem[]>([]);

    // content data for the selected residents
    const [content, setContent] = useState<TableItem[]>([]);

    // message transactions for the selected residents
    const [messages, setMessages] = useState<TableItem[]>([]);
    
    // announcement data for the selected residents
    const [announcement, setAnnouncement] = useState<any[]>([]);
    const [requestAnnouncements, setRequestAnnouncements] = useState<any[]>([]);
    const [svcInstanceAnnouncements, setSvcInstanceAnnouncements] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [residents, setResidents] = useState<Registrant[]>([]);
    const [residentDict, setResidentDict] = useState<{ [key: string]: Registrant }>({});
    const [staffDict, setStaffDict] = useState<{ [key: string]: User }>({});
    const [assetDict, setAssetDict] = useState<{ [key: string]: Asset }>({});
    const [facilityTimeZone, setFacilityTimeZone] = useState<string>('');
    const profile = useSelector((state: AppState) => state.authReducer.profile);
    const [selectedResidents, setSelectedResidents] = useState<string[]>([]);
    const [printLoading, setPrintLoading] = useState<boolean>(false);

    const getStaffNameById = (staffId: string) => {
        const resultingStaff = staffDict[String(staffId)];
        return resultingStaff ? `${resultingStaff.FirstName || ''} ${resultingStaff.LastName || ''}` : '-';
    };

    const getResidentNameById = (residentId: string) => {
        const resident = residentDict[String(residentId)];
        return resident ? (`${resident.FirstName || ''} ${resident.LastName || ''}`).trim() : '-';
    };

    const formatRequestsDataForTableView = (requests: (RequestInstance | Request)[]) => { // This function is used to fetch and format the requests data for the table view
        // return requests
        // Type guard to check if 'StaffId' property exists
        const hasStaffId = (request: any): request is RequestInstance => {
            return (request as RequestInstance).StaffId !== undefined;
        }
        return requests.map((request) => {
            const AssetId = request.Service && request.Service.Asset;
            const asset = AssetId && assetDict[AssetId] as Asset;
            const resident = residentDict[request.Registrant];
            const location = asset && asset.AssetName || (resident && resident.Unit && resident.Unit.Name) || '-';
            const residentName = getResidentNameById(request.Registrant);
            const time = moment.tz(request.RequestedTime, facilityTimeZone).format('M/D/YY h:mm A');
            const eventName = request.Name || (`${request.Department_Name || ""} ${request.RequestType_Name || ""}`).trim() || '-';
            const status = request.Status || '-';
            const staffId = request.ClosedBy || (hasStaffId(request) ? request.StaffId : undefined) || request.AcceptedBy || "";
            const staffName = getStaffNameById(staffId);
            const duration = '-';
            return {
                Resident: residentName,
                'Day/Time': time,
                Interaction: eventName,
                Status: status,
                Staff: staffName,
                Length: duration,
                Location: location
            };
        })
    }

    const formatAttendanceDataForTableView = (attendance) => {
        return attendance.map((item) => {
            const activity = activityDict[item.activityId] as DailyActivity;
            const serviceActivity = activityDict[item.svcInstanceId] as ServicesType;
            if (!activity || !serviceActivity) {
                return null;
            }
            const activityTime = activity && (activity.timestamp ? moment.tz(activity.timestamp, facilityTimeZone).format('M/D/YY h:mm A') : activity.dateTimeString ? moment(activity.dateTimeString).format('M/D/YY h:mm A') : null) || moment(serviceActivity.startDate).format('M/D/YY h:mm A');
            if (!activityTime) {
                return null;
            }
            const duration = serviceActivity && `${moment(activity.endDate).diff(moment(activity.startDate), 'minutes')}  min` || '-';
            const asset = (serviceActivity.Asset && assetDict[serviceActivity.Asset]) || null;
            const location = (asset && asset.AssetName) || '-';

            return {
                Resident: (`${item.registrant.FirstName || ''} ${item.registrant.LastName || ''}`).trim(),
                'Day/Time': activityTime,
                Interaction: (activity && activity.text || activity.name) || 'Unrecognized Activity',
                Status: item.participation ? item.participation : item.declineReason || '-',
                Staff: '-',
                Length: duration,
                Location: location
            };
        }
        ).filter((item) => item); // This is filter out falsy values from the array
    }

    const formatCheckinDataForTableView = (checkinItems: CheckinCard[]) => {
        return checkinItems.map((item) => {
            const residentName = getResidentNameById(item.registrantId || "");
            const staffName = getStaffNameById(item.AddedBy || "");
            const status = item.column === 'checked_in' ? 'Checked In for the day' : 'Not Checked In/Other for the day';
            const resident = residentDict[item.registrantId || ""];
            const location = resident && resident.Unit && resident.Unit.Name || '-';
            return {
                Resident: residentName,
                'Day/Time': moment.tz(item.timestamp, facilityTimeZone).format('M/D/YY h:mm A'),
                Interaction: 'Check In',
                Status: status,
                Staff: staffName,
                Length: '-',
                Location: location
            };
        });
    }

    const formatContentDataForTableView = (content: Request[]) => {
        return content.map((item) => {
            const staffName = getStaffNameById(item.AddedBy || '');
            const resident = residentDict[item.Registrant || ''];
            const residentName = resident ? (`${resident.FirstName || ''} ${resident.LastName || ''}`).trim() : '-';
            const location = resident && resident.Unit && resident.Unit.Name || '-';
            return {
                Resident: residentName,
                'Day/Time': moment.tz(item.RequestedTime, facilityTimeZone).format('M/D/YY h:mm A'),
                Interaction: `Content - ${findKeyByValue(item.Type || '') || item.Type}` || '-',
                Status: '-',
                Staff: staffName,
                Length: '-',
                Location: location
            };
        });
    }

    const formatMessageDataForTableView = (messages: Message[]) => {

        return messages.map((item) => {
            const status = item.To === 'Resident' ? `Recieved a ${item.type} message` : `Sent a text message`;
            const resident = residentDict[item.residentId || ''];
            const residentName = resident && (`${resident.FirstName || ''} ${resident.LastName || ''}`).trim() || item.residentName || '-';
            const location = resident && resident.Unit && resident.Unit.Name || '-';
            return {
                Resident: residentName,
                'Day/Time': moment.tz(item.sentAt, facilityTimeZone).format('M/D/YY h:mm A'),
                Interaction: 'Message',
                Status: status,
                Staff: '-',
                Length: '-',
                Location: location
            };
        });
    }

    const formatAnnouncementDataForTableView = (announcements: any[]) => {
        return announcements.flatMap((item) => {
            if (!item.RoomIds || item.RoomIds.length === 0) return [];
    
            return item.RoomIds.map((roomId: string) => {
                const resident = Object.values(residentDict).find((resident) => resident && resident.Unit && resident.Unit.a4hRoomId === roomId);
                if (resident && selectedResidents.includes(String(resident._id))) {
                    const residentName = `${resident.FirstName || ''} ${resident.LastName || ''}`.trim() || '-';
                    const location = resident && resident.Unit && resident.Unit.Name || '-';
                    return {
                        Resident: residentName,
                        'Day/Time': moment.tz(item.DateAdded, facilityTimeZone).format('M/D/YY h:mm A'),
                        Interaction: 'Announcement',
                        Status: '-',
                        Staff: '-',
                        Length: '-',
                        Location: location
                    };
                }
            }).filter(Boolean); // Removes any undefined values
        });
    };

    const formatRequestsAnnouncementsDataForTableView = (requests: any[]) => {
        return requests.map((item) => {
            const resident = residentDict[item.Registrant];
            const residentName = resident ? (`${resident.FirstName || ''} ${resident.LastName || ''}`).trim() : '-';
            const location = resident && resident.Unit && resident.Unit.Name || '-';

            return {
                Resident: residentName,
                'Day/Time': moment.tz(item.RequestedTime, facilityTimeZone).format('M/D/YY h:mm A'),
                Interaction: 'Announcement',
                Status: '-',
                Staff: '-',
                Length: '-',
                Location: location
            };
        });
    };

    const formatSvcAnnouncements = (attendance, activityDict) => {
        return attendance.map((item) => {
            if(item.status === 'removed') {
                return;
            }
            const resident = residentDict[item.registrantId];
            const activity = activityDict[item.svcInstanceId] as ServicesType;
            const minutesBeforeAnnouncement = activity && activity.defaults && Number(activity.defaults.minutesBeforeAnnouncement);
            let activityTime;
            if (minutesBeforeAnnouncement) {
                activityTime = moment(activity.startDate).subtract(minutesBeforeAnnouncement, 'minutes').format('M/D/YY h:mm A');
            } else {
                activityTime = moment(activity.startDate).format('M/D/YY h:mm A');
            }
            const location = resident && resident.Unit && resident.Unit.Name || '-';
            return {
                Resident: (`${item.registrant.FirstName || ''} ${item.registrant.LastName || ''}`).trim(),
                'Day/Time': activityTime,
                Interaction: 'Announcement',
                Status: '-',
                Staff: '-',
                Length: '-',
                Location: location
            };
        }).filter((item) => item);
    }

    const fetchRequestsData = async () => {
        // start and end date strings of the selected date in frontend
        const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        if (selectedResidents.length > 0) {
            const filter = {
                Filter: {
                    Registrant: {
                        $in: selectedResidents
                    },
                    StartDateTime: startDateTimeString,
                    EndDateTime: endDateTimeString
                }
            };
            const requests: (RequestInstance | Request)[] = await fetchAllPaginatedData(
                fetchRequestsAndReqInstances,
                filter,
            )
            const formattedRequests = formatRequestsDataForTableView(requests)
            setRequests(formattedRequests);
        }
    }

    const fetchAttendanceData = async () => {
        if (startDate && endDate && facilityTimeZone) {
            setLoading(true);

            // start and end date strings of the selected date in frontend
            const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
            const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
            // start and end date timestamps in facility timezone for selected date
            const startDateTimestamp = moment.tz(startDateTimeString, facilityTimeZone).valueOf();
            const endDateTimestamp = moment.tz(endDateTimeString, facilityTimeZone).valueOf();
            const filter = {
                timestamp: {
                    $gte: startDateTimestamp,
                    $lte: endDateTimestamp,
                },
            };

            // Fetch all activities for the date range - we fetch all activities since we need to fetch all attendance entries for only these activities
            const activities: DailyActivity[] = await fetchAllActivities(filter);
            const allActivityDict = {};
            const activityIds = activities.map((activity) => {
                allActivityDict[String(activity._id)] = activity;
                return String(activity._id || ''); // _id will never be undefined, this is added to satisfy the type checker
            }).filter((id) => id);

            // Fetch all service instances for the date range - we fetch all service instances since we need to fetch all attendance entries for only these service instances
            const currentServiceInstanceFilter = {
                startDate: startDateTimeString,
                endDate: endDateTimeString,
            }
            const serviceInstances = await fetchAllServiceInstance(currentServiceInstanceFilter);
            const serviceInstanceIds = serviceInstances.map((serviceInstance) => {
                allActivityDict[String(serviceInstance._id)] = serviceInstance;
                return String(serviceInstance._id || ''); // _id will never be undefined, this is added to satisfy the type checker
            }).filter((id) => id);
            setActivityDict(allActivityDict);
            
            // Fetch attendance data for the activities and the selected residents - the API doesnt allow $or filter, so we make a two api calls to fetch attendance data for activities and service instances
            const attendanceFilterForActivities = {
                activityId: {
                    $in: activityIds,
                },
                registrantId: {
                    $in: selectedResidents,
                },
            };
            const attendanceFilterForServiceInstances = {
                svcInstanceId: {
                    $in: serviceInstanceIds,
                },
                registrantId: {
                    $in: selectedResidents,
                },
            };
            
            const [attendanceDataForActivities = [], attendanceDataForServiceInstances = []] = await Promise.all([
                fetchAllPaginatedData(listActivityAttendeesForCustomFilter, attendanceFilterForActivities),
                fetchAllPaginatedData(listActivityAttendeesForCustomFilter, attendanceFilterForServiceInstances),
            ]);
            const mergedAttendanceData = [...attendanceDataForActivities, ...attendanceDataForServiceInstances].sort((a, b) => {
                return a.DateAdded - b.DateAdded;
            });
            const formattedAttendanceData = formatAttendanceDataForTableView(mergedAttendanceData);
            setAttendanceData(formattedAttendanceData);
            setLoading(false);
        }
    };

    const fetchCheckInData = async () => {
        // start and end date strings of the selected date in frontend
        const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        // generate timestamps for the start and end date
        const startTimestamp = moment.tz(startDateTimeString, facilityTimeZone).valueOf();
        const endTimestamp = moment.tz(endDateTimeString, facilityTimeZone).valueOf();
        const facilityId = profile && profile.Facility;
        const checkinCards = await listCards(facilityId, { 
            startTimestamp,
            endTimestamp,
            resident: { $in: selectedResidents }
        });
        const formattedCheckinItems = formatCheckinDataForTableView(checkinCards as CheckinCard[]);
        setCheckinItems(formattedCheckinItems);
    }

    const fetchContentData = async () => {
        // start and end date strings of the selected date in frontend
        const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        // generate timestamps for the start and end date
        const startTimestamp = moment.tz(startDateTimeString, facilityTimeZone).valueOf();
        const endTimestamp = moment.tz(endDateTimeString, facilityTimeZone).valueOf();
        // fetch content data for the selected residents
        
        const contentData = await fetchAllRequests({
            fromDate: startTimestamp,
            toDate: endTimestamp,
            resident: { $in: selectedResidents },
        });
        const formattedContentData = formatContentDataForTableView(contentData);
        setContent(formattedContentData);
    }

    const fetchMessagesData = async () => {
        // start and end date strings of the selected date in frontend
        const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        // generate timestamps for the start and end date
        const startTimestamp = moment.tz(startDateTimeString, facilityTimeZone).valueOf();
        const endTimestamp = moment.tz(endDateTimeString, facilityTimeZone).valueOf();
        // fetch messages data for the selected residents
        const messages = await fetchAllMessageAnalytics(
            {
                fromDate: startTimestamp,
                toDate: endTimestamp,
                residentIds: selectedResidents,
            }
        );
        const formattedMessages = formatMessageDataForTableView(messages);
        setMessages(formattedMessages);
    }

    const fetchAnnouncementData = async () => {
        // start and end date strings of the selected date in frontend
        const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        // generate timestamps for the start and end date
        const startTimestamp = moment.tz(startDateTimeString, facilityTimeZone).valueOf();
        const endTimestamp = moment.tz(endDateTimeString, facilityTimeZone).valueOf();
        // fetch announcement data for the selected residents
        const selectedA4hRoomIds = selectedResidents.map((residentId) => {
            const resident = residentDict[residentId];
            if (resident && resident.Unit && resident.Unit.a4hRoomId) {
                return resident.Unit.a4hRoomId;
            }
        }).filter((a4hRoomId) => a4hRoomId);
        if(selectedA4hRoomIds.length === 0) {
            return;
        }
        const announcementReqBody = {
            option: "announcement",
            fromDate: startTimestamp,
            toDate: endTimestamp,
            fromDateString: startDateTimeString,
            toDateString: endDateTimeString,
            a4hRoomIds: selectedA4hRoomIds,
        };
        const sorting = "DateAdded:desc"
        const announcements = await fetchAllAnnouncementAnalytics(announcementReqBody, sorting);
        const formattedAnnouncements = formatAnnouncementDataForTableView(announcements);
        setAnnouncement(formattedAnnouncements);
    }

    const fetchServiceRequestsAnnouncements = async () => {
        if (selectedResidents.length > 0) {
            const filter = {
                Filter: {
                    Registrant: {
                        $in: selectedResidents
                    },
                    StartDateTime: moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss"),
                    EndDateTime: moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss"),
                    triggerArns: { $exists: true }
                }
            };
            const requests = await fetchAllPaginatedData(
                fetchRequestInstance,
                filter,
            );
            const formattedRequestAnnouncements = formatRequestsAnnouncementsDataForTableView(requests);
            setRequestAnnouncements(formattedRequestAnnouncements);
        }
    };

    const fetchServiceInstanceAnnouncements = async () => {
        if (startDate && endDate && facilityTimeZone) {
            // start and end date strings of the selected date in frontend
            const startDateTimeString = moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
            const endDateTimeString = moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss");

            // Fetch all service instances for the date range - we fetch all service instances since we need to fetch all attendance entries for only these service instances
            const currentServiceInstanceFilter = {
                startDate: startDateTimeString,
                endDate: endDateTimeString,
                "triggerArns": { $exists: true },
                "defaults.autoAnnouncement": true
            };
            const allActivityDict = {};
            const serviceInstances = await fetchAllServiceInstance(currentServiceInstanceFilter);
            const serviceInstanceIds = serviceInstances.map((serviceInstance) => {
                allActivityDict[String(serviceInstance._id)] = serviceInstance;
                return String(serviceInstance._id || ''); // _id will never be undefined, this is added to satisfy the type checker
            }).filter((id) => id);

            // Fetch attendance data for the activities and the selected residents - the API doesnt allow $or filter, so we make a two api calls to fetch attendance data for activities and service instances
            const attendanceFilterForServiceInstances = {
                svcInstanceId: {
                    $in: serviceInstanceIds,
                },
                registrantId: {
                    $in: selectedResidents,
                },
            };

            const attendanceDataForServiceInstances = await fetchAllPaginatedData(listActivityAttendeesForCustomFilter, attendanceFilterForServiceInstances);
            const mergedAttendanceData = attendanceDataForServiceInstances.sort((a, b) => {
                return a.DateAdded - b.DateAdded;
            });
            const formattedSvcInstanceAnnouncements = formatSvcAnnouncements(mergedAttendanceData, allActivityDict);
            setSvcInstanceAnnouncements(formattedSvcInstanceAnnouncements);
        }
    }
    useEffect(() => {
        try {
            (async () => {
                const excludeAlisImage = true;
                const includeRoomInfo = true;
                const [residents, staff, { FacilityTimeZone = '' }, allAssets] = await Promise.all([
                    fetchAllActiveFacilityRegistrants('', excludeAlisImage, includeRoomInfo),
                    fetchAllActiveUsers(),
                    fetchOneFacility((profile && profile.Facility) || ''),
                    listAssets()
                ]);
                // Generate dictionaries
                const assetDictionary = {};
                allAssets.forEach((asset: Asset) => {
                    assetDictionary[String(asset._id)] = asset;
                });
                const residentDict = {};
                residents.forEach((resident) => {
                    residentDict[String(resident._id)] = resident;
                });
                const staffDict = {};
                staff.forEach((staff) => {
                    staffDict[String(staff._id)] = staff;
                });
                setResidents(residents);
                setResidentDict(residentDict);
                setStaffDict(staffDict);
                setAssetDict(assetDictionary);
                setFacilityTimeZone(FacilityTimeZone);
            })();
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching resident or staff data');
        }
    }, []);

    useEffect(() => {
        try {
            if (startDate && endDate && selectedResidents.length > 0) {
                Promise.all([fetchRequestsData(), fetchAttendanceData(), fetchCheckInData(), fetchContentData(), fetchMessagesData(), fetchAnnouncementData(), fetchServiceRequestsAnnouncements(), fetchServiceInstanceAnnouncements()]);
            } else {
                setRequests([]);
                setAttendanceData([]);
                setCheckinItems([]);
                setContent([]);
                setMessages([]);
                setAnnouncement([]);
                setRequestAnnouncements([]);
                setSvcInstanceAnnouncements([]);
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching data');
        }
    }, [startDate, endDate, selectedResidents]);

    const residentsDropdownChangeHandler = (_, data: DropdownProps) => {
        const { value } = data;
        setSelectedResidents((value as string[]) || []);
    };

    const tableHeaders = ['Resident', ' Day/Time', 'Interaction', ' Status', 'Staff', 'Length', 'Location'];
    const tableKeys = ['Resident', 'Day/Time', 'Interaction', 'Status', 'Staff', 'Length', 'Location'];

    const tableData = [...attendanceData, ...requests, ...checkinItems, ...content, ...messages, ...announcement, ...requestAnnouncements, ...svcInstanceAnnouncements].sort((a, b) => b['Day/Time'] < a['Day/Time'] ? -1 : b['Day/Time'] > a['Day/Time'] ? 1 : 0); // Latest item first
    const exportData = tableData.map((item) => {
        return {
            Resident: item.Resident,
            Date: moment(item['Day/Time']).format('M/D/YY'),
            Time: moment(item['Day/Time']).format('h:mm A'),
            Interaction: item.Interaction,
            Status: item.Status,
            Staff: item.Staff,
            Length: item.Length,
            Location: item.Location
        };
    }
    );
    const handlePrintClick = async () => {
        window.open(prntyrLink, '_blank');
    };

    const hideTableMessage = {
        header: 'No residents selected',
        content: 'Please select one or more residents to view their interactions.',
    }

    return (
        <div className="mt-4">
            <Dimmer active={loading} inverted>
                <Loader inverted content="Loading" />
            </Dimmer>
            <Grid>
                <Grid.Column>
                    <Segment className="table-container">
                        <CustomTable
                            facilityTimezone={facilityTimeZone}
                            formatString=""
                            headers={tableHeaders}
                            rowKeys={tableKeys}
                            data={tableData}
                            allowSearch={true}
                            searchBoxPlaceholder="Search"
                            searchkey={['Resident', 'Staff', 'Interaction', 'Length', 'Day/Time']}
                            allowExport={true}
                            allowPrint={true}
                            formattedExportData={exportData}
                            exportFileName="reports_interactions"
                            pageNo={1}
                            printHandler={handlePrintClick}
                            printLoader={printLoading}
                            selectResident={true}
                            selectedResident={selectedResidents}
                            handleSelectDropdown={residentsDropdownChangeHandler}
                            residents={residents}
                            overflowX='hidden'
                            hideTableMessage={selectedResidents.length === 0 ? hideTableMessage : undefined}
                            refreshCurrPage={true}
                            setHeight={'70vh'}
                            filter={[]}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default ReportsResidentInteractions;
