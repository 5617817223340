import React, { useState, useEffect } from 'react'

import { Form, Modal, ModalHeader, Dimmer, Loader, Input, ModalActions, Progress, Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { updateA4HEmail } from '../../../../services/Registrants';

const UpdateA4HContactEmail = ({ isModalOpen, residentId, contactId, type, reload, inputEmail, close }) => {
    const [makingCalls, setMakingCalls] = useState(false)
    const [makingCallsPercent, setMakingCallsPercent] = useState(0)

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setEmail(inputEmail)
    }, [residentId, contactId, type, inputEmail]);

    useEffect(() => {
        validator();
    },[email]);

    const validator = () => {
        const mailTest =  !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)); // email validator regex
        if(email === ''){
            setIsValidEmail(false);
        }else{
            setIsValidEmail(mailTest);
        }
    }

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    const handleSubmit = async () => {
        try {
            setMakingCalls(true)
            setMakingCallsPercent(0)
            await updateA4HEmail(residentId, contactId, email, type)
            sendToast('success', 'Contact email updated')
            await reload()
            setMakingCallsPercent(100)
            setMakingCalls(false)
            close()
        } catch (er) {
            console.log('error', er)
            sendToast('warn', 'Something went wrong')
            close()
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                if (!makingCalls) {
                    close()
                }
            }}
            size={'tiny'}
            className="invite-modal-root"
            closeIcon
        >
            <ModalHeader>
                <h5>Enter new email to be reflected in Resident's AddressBook</h5>
            </ModalHeader>

            <Form error>
                <Modal.Content className="fnf-invite-modal-content">
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>

                    <div className="name-section">
                        <Input
                            type='email'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            className="firstName"
                            required
                            name="email"
                            placeholder="Email"
                        ></Input>
                    </div>

                    {/* {makingCalls && (
                        <div>
                            <Progress
                                percent={makingCallsPercent}
                                size="tiny"
                                autoSuccess
                                color="blue"
                                className="inbound-progress"
                                style={{ width: '30%', marginBottom: '0px' }}
                            />
                            <div>We are saving this contact. This may take some time.</div>
                        </div>
                    )} */}
                </Modal.Content>
                <ModalActions className="inviteBtn">
                    <Button
                        className="ui primary small button"
                        loading={makingCalls}
                        disabled={isValidEmail}
                        onClick={async (e) => {
                            e.preventDefault()
                            await handleSubmit()
                        }}
                    >
                        Save
                    </Button>
                </ModalActions>
            </Form>
        </Modal>
    )
}

export default UpdateA4HContactEmail
