export const throwCorrespondingExternalIntegrationError = ({
    isExternalResident,
    externalIntegrationSource,
    isAlisResident,
    action,
}: {
    isExternalResident?: boolean;
    externalIntegrationSource?: string;
    isAlisResident?: boolean;
    action: string;
}) => {
    if (isAlisResident) {
        return generateExternalIntegrationErrorMessage('Alis', action);
    } else if (isExternalResident) {
        return generateExternalIntegrationErrorMessage(externalIntegrationSource || '', action);
    } else {
        return generateExternalIntegrationErrorMessage('', action);
    }
};

export const generateExternalIntegrationErrorMessage = (source: string, action: string) => {
    if (source) {
        return `Cannot ${action} residents imported from ${source}, Please use the ${source} system to ${action} the resident`;
    } else {
        return `Cannot ${action} residents imported from external systems, Please use the external system to ${action} the resident`;
    }
};
