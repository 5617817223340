import React, { useEffect, useState } from 'react';
import { Icon, Menu, Dimmer, Loader } from 'semantic-ui-react';
import { listAllRestaurant } from '../../services/Assets';
import { Restaurant } from '../../types/Dining';
import { sendToast } from '../../util';
import "./style.less";
interface Props {
    selectedRestaurant: string | null;
    setSelectedRestaurant: React.Dispatch<React.SetStateAction<Restaurant | null>>;
}

const initialItemsToShow = 5;

const CarouselRestaurant = ({ selectedRestaurant, setSelectedRestaurant }: Props) => {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [startIndex, setStartIndex] = useState<number>(0);

    useEffect(() => {
        fetchFacilityRestaurant();
    }, []);

    const fetchFacilityRestaurant = async () => {
        try {
            setLoading(true);
            const isImageRequired = true;
            const response = await listAllRestaurant(undefined, isImageRequired);
            setRestaurants(response || []);
        } catch (error) {
            sendToast('error', 'Failed to fetch Restaurants');
        } finally {
            setLoading(false);
        }
    };

    const handleNext = () => {
        if (startIndex + initialItemsToShow < restaurants.length) {
            setStartIndex(prevIndex => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(prevIndex => prevIndex - 1);
        }
    };

    const visibleRestaurants = restaurants.slice(startIndex, startIndex + initialItemsToShow);

    const handleRestaurantSelection = (restaurant: Restaurant) => {
        setSelectedRestaurant(prevSelected => prevSelected && prevSelected._id === restaurant._id ? null : restaurant);
    };

    return (
        <div className="restaurant-filter">
            <Dimmer inverted active={loading}>
                <Loader active={loading} />
            </Dimmer>
            <div className="carousel-arrows-container">
                <Icon name="angle left" className="angle left" onClick={handlePrev} />
            </div>
            {visibleRestaurants && visibleRestaurants.length > 0 ?
                <Menu style={{ display: 'grid', gridTemplateColumns: `repeat(${initialItemsToShow}, 1fr)`, width: "100%" }}>
                    {visibleRestaurants.map((restaurant) => (
                        <Menu.Item
                            className={`menu-item ${selectedRestaurant === restaurant._id ? 'active' : ''}`}
                            key={restaurant._id as string}
                            onClick={() => handleRestaurantSelection(restaurant)}
                        >
                            <div className='group-name'>
                                {!restaurant.SignedUrl ?
                                    <Icon name="images" size="huge" />
                                    :
                                    <img src={restaurant.SignedUrl} style={{ maxWidth: '5rem', maxHeight: '5rem', cursor: 'pointer' }} onClick={() => handleRestaurantSelection(restaurant)} />
                                }
                                <h3 className='single-line-paragraph'>{restaurant.AssetName}</h3>
                            </div>
                        </Menu.Item>
                    ))}
                </Menu>
                : <div style={{ width: "100%", margin: "50px" }}><p style={{ textAlign: "center" }}>No restaurants in the facility to show. Please add restaurants from Dining page</p>
                </div>}
            <div className="carousel-arrows-container">
                <Icon name="angle right" className="angle right" onClick={handleNext} />
            </div>
        </div>
    );
};

export default CarouselRestaurant;
