import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../../../actions/requests'
import './style.less'
import { AppState } from '../../../../reducers'
import Requests from '../../../../components/Requests'
import React from 'react'
import { Tab } from 'semantic-ui-react'

function mapStateToProps({ requestsReducer, authReducer }: AppState) {
    return {
        requests: requestsReducer.requests,
        hasError: requestsReducer.hasError,
        errorCode: requestsReducer.errorCode,
        isFetching: requestsReducer.fetching,
        page: requestsReducer.page,
        count: requestsReducer.count,
        limit: requestsReducer.limit,
        userId: authReducer.profile && authReducer.profile._id,
        unassigned: false,
    }
}

function mapDispatchToOpenedProps(dispatch: ThunkDispatch<any, any, actions.RequestsAction>) {
    return {
        onFetchRequests: (userId: string) => {
            console.log(userId, 'UserId')
            return dispatch(actions.fetchAndFilter(userId, 'OPEN'))
        },
    }
}
const OpenRequests = connect(
    mapStateToProps,
    mapDispatchToOpenedProps,
)(withRouter(Requests))

function mapDispatchToClosedProps(dispatch: ThunkDispatch<any, any, actions.RequestsAction>) {
    return {
        onFetchRequests: (userId: string) => dispatch(actions.fetchAndFilter(userId, 'CLOSED')),
    }
}
const ClosedRequests = connect(
    mapStateToProps,
    mapDispatchToClosedProps,
)(withRouter(Requests))

class MyRequests extends React.Component {
    panes = [
        {
            menuItem: 'Open',
            render: () => (
                <Tab.Pane>
                    <OpenRequests />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Closed',
            render: () => (
                <Tab.Pane>
                    <ClosedRequests />
                </Tab.Pane>
            ),
        },
    ]

    render() {
        return (
            <div className="MyRequests">
                <Tab menu={{ secondary: true, pointing: true }} panes={this.panes} />
            </div>
        )
    }
}

export default MyRequests
