import React from "react";
import { OrderIdColorDict, RequestsDict } from "../types";

export const formatRequestsDictForCalendarEvents = (requestsDict: RequestsDict) => {
    // its getting to complicated to make finalResult a type that is not any, we have to change many files , so for now we will leave it as any
    const finalResult: any[]= [];
    if (requestsDict && typeof requestsDict === "object" && !Array.isArray(requestsDict)) {
        for (const [date, categories = {}] of Object.entries(requestsDict)) {
            if (categories && typeof categories === "object" && Object.keys(categories).length && !Array.isArray(categories)) {
                for (const [category, count] of Object.entries(categories)) {
                    if (typeof count === "number") {
                        finalResult.push({
                            Name: `${category} - ${count}`,
                            ScheduledTime: {
                                startDate: date,
                                endDate: date,
                            },
                            _id: `${date}-${category}`,
                        });
                    } else {
                        console.error(`Invalid count for category ${category} on date ${date}`);
                    }
                }
            } else {
                console.error(`Invalid categories for date ${date}`);
            }
        }
    } else {
        console.error("Invalid input object");
    }
    return finalResult;
};

export function getOrderIdColor(orderIdColorDict: OrderIdColorDict, setAlreadyGeneratedColors: React.Dispatch<React.SetStateAction<string[]>>, setOrderIdColorDict: React.Dispatch<React.SetStateAction<OrderIdColorDict>>, alreadyGeneratedColors: string[], orderId: string | undefined) {
    if (!orderId) {
        return;
    }
    // If the color already exists for the orderId, return it
    if (orderIdColorDict[orderId]) {
        return orderIdColorDict[orderId];
    }
    // Function to generate a valid color
    const generateColor = (): string => {
        // Generate a random color
        let randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
        // Check if the color has already been generated
        if (alreadyGeneratedColors.includes(randomColor)) {
            return generateColor();
        }
        // Convert hex to RGB
        const r = parseInt(randomColor.substring(0, 2), 16);
        const g = parseInt(randomColor.substring(2, 4), 16);
        const b = parseInt(randomColor.substring(4, 6), 16);
        // Calculate brightness (perceived luminance)
        const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        // Define the brightness thresholds
        const minBrightness = 50; // Avoid very dark colors (close to black)
        const maxBrightness = 200; // Avoid very bright colors (close to white)
        // Check if the color is within acceptable brightness range
        if (brightness > minBrightness && brightness < maxBrightness) {
            // Add the color to the already generated colors
            setAlreadyGeneratedColors(prevColors => [...prevColors, randomColor]);
            return `#${randomColor}`;
        }
        // Generate a new color if it doesn't meet the criteria
        return generateColor();
    };
    const newColor = generateColor();
    setOrderIdColorDict(prevMap => ({ ...prevMap, [orderId]: newColor }));
    return newColor;
};