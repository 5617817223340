import { Facility, UserRole, User } from "../types";
import API from "./API";

export const fetchOneRoleForFederatedUsers = async (name: string, isActive: number): Promise<UserRole> => {
    const filter = { Name: name, IsActive: isActive };
    const res = await API.lambdaPost(`/federated-users/index`, { Filter: filter, action: 'getRole' });
    return res as UserRole;
};

export const fetchFacilityIdFromFederatedUserOfficeLocation = async (officeLocation: string): Promise<string> => {
    try {
        const res = await API.lambdaPost(`/federated-users/index`, { officeLocation, action: "getFacilityIdFromFederatedUserOfficeLocation" });
        return res;
    } catch (error) {
        console.error("fetchFacilityIdFromFederatedUserOfficeLocation error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to fetch facility id");
    }
};

export const fetchOneFacilityForFederatedUsers = async (facilityId: string): Promise<Facility> => {
    try {
        const res = await API.lambdaPost(`/federated-users/index`, { _id: facilityId, action: "getFacilityDetailsFromId" });
        return res;
    } catch (error) {
        console.error("fetchOneFacilityForFederatedUsers error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to fetch facility");
    }
};

export async function createFederatedUserWithRoles(
    userData: Partial<User>,
    roles: UserRole[],
): Promise<any> {
    try {
        const dataClone = { ...userData };
        if (dataClone.Roles) delete dataClone.Roles;
        if (dataClone.Departments) delete dataClone.Departments;
        // "Phone number formatting for 'Phone' and 'Cell' is already done, no need for below lines of code."
        // if (dataClone.Phone) dataClone.Phone = formatPhone(dataClone.Phone)
        // if (dataClone.Cell) dataClone.Cell = formatPhone(dataClone.Cell)

        const createdId = await API.lambdaPost('/federated-users/index', {
            ...dataClone,
            IsActive: 1,
            action: "createFederatedUser",
            federatedAuthUser: true,
        });

        const roleAddPromises = roles.map(role => assignRoleToFederatedUser(createdId, role._id));
        await Promise.all([...roleAddPromises]);
        return createdId;
    } catch (error) {
        console.error("createFederatedUserWithRoles error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to create user");
    }
}

export async function assignRoleToFederatedUser(userId: string, roleId: string): Promise<any> {
    const res = await API.lambdaPost('/federated-users/index', {
        User: userId,
        Role: roleId,
        action: "assignRoleToFederatedUser",
        federatedAuthUser: true,
    });
    return res;
}