import React, { useEffect, useState } from 'react';
import { getAlisResident } from '../../services/Alis';
import './style.less';
import { Grid, GridColumn } from 'semantic-ui-react';

interface Props {
    residentId: string
}

const AlisIntegration = (props :Props) => {
    const [resident, setResident] = useState(undefined);
    // const [loading, setLoading] = useState<boolean>(false);

    const fetchAndSetResident = async (RegistrantId) => {
        try {
            const res = await getAlisResident(RegistrantId)
            console.log('res', res)
            if (res)
                setResident(res)
        } catch(e) {
            console.error(e)
        }
    }

    useEffect(()=>{
        if (props.residentId) fetchAndSetResident(props.residentId)
        else throw new Error('No registrant id')
    }, [])

    const alisResidentGrid = (r) => {
        return (
            <Grid columns='equal'>
            <Grid.Column>
                <p>Name: {r.firstName + ' ' + r.lastName}</p>
                {r.dateOfBirth &&
                    <p>DOB: {r.dateOfBirth.split('T')[0]}</p>
                }
                {r.basicInfo && r.basicInfo.gender &&
                    <p>Gender: {r.basicInfo.gender}</p>
                }
                {r.basicInfo && r.basicInfo.mobilePhoneNumber &&
                    <p>Mobile: {r.basicInfo.mobilePhoneNumber}</p>
                }
                {r.basicInfo && r.basicInfo.email &&
                    <p>Email: {r.basicInfo.email}</p>
                }
            </Grid.Column>

            {r.rooms && r.rooms.length && r.rooms.map(room => {
                const roomData :string[] = []
                if (room.hall)
                    roomData.push(`Hall: ${room.hall}`)
                if (room.floor)
                    roomData.push(`Floor: ${room.floor}`)
                if (room.room)
                    roomData.push(`Room: ${room.room}`)
                if (room.roomNumber)
                    roomData.push(`Room number: ${room.roomNumber}`)
                if (room.bed)
                    roomData.push(`Bed: ${room.bed}`)

                return roomData.length && 
                    <Grid.Column>
                        {roomData.map((d,i)=> <p key={i}>{d}</p>)}
                    </Grid.Column>
            }
            )}
            <Grid.Column>
            {r.physicalMoveInDate && 
                    <p>Moved in: {r.physicalMoveInDate.split('T')[0]}</p>
                }
            {r.physicalMoveOutDate &&
                <p>Moved out: {r.physicalMoveOutDate.split('T')[0]}</p>
            }
            </Grid.Column>
            </Grid>
        )
    }
    return (
        resident ? 
        <div className="alis-integration">
            <h3 style={{textAlign:"center"}}>Alis Data</h3>
            <div style={{"paddingTop": "4rem"}}>
                {alisResidentGrid(resident)}
            </div>
        </div>
        :
        <></>
    );
};

export default AlisIntegration