import React, { useEffect, useState } from 'react'
import { Dimmer, Grid, Icon, Input, Loader, Modal, Form, Image, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createAsset, getOneAsset, updateAsset } from '../../services/Assets';
import { uploadAssetImageToS3 } from '../../services/ImageUpload';
import {  TableSeat } from '../../types/Dining';
import { sendToast } from '../../util';
interface CreateTableProps {
    openCreateTableModal: boolean;
    closeCreateTableModal: () => void;
    restaurantId: string;
    editId: string;
    facilityId: string;
    tableMaxCap: number;
    restaurantMaxCap: number;
    setRefreshPage: React.Dispatch<React.SetStateAction<boolean>>;
    maxCapOfSelectedTable: number;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateTableModal: React.FC<CreateTableProps> = ({ openCreateTableModal, closeCreateTableModal, restaurantId, editId, facilityId, tableMaxCap, restaurantMaxCap, setRefreshPage, maxCapOfSelectedTable, setRefreshTable }) => {
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [file, setFile] = useState<File | undefined>();
    const [tableSeat, setTableSeat] = useState<string>('')
    const [description, setDescription] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [maxCap, setMaxCap] = useState<string>('');
    const [tableDetailLoading, setTableDetailLoading] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>('');

    useEffect(() => {
        getTableDetails();
    }, [editId]);
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);
    const getTableDetails = async () => {
        try {
            setTableDetailLoading(true);
            if (editId) {
                setPreviewUrl("")
                const { response, signedUrl } = await getOneAsset(editId);
                if (signedUrl) {
                    setPreviewUrl(signedUrl);
                }
                setImageName(response.ImageName);
                setTableSeat(response.AssetName);
                setDescription(response.Description ? response.Description : '');
                setUrl(response.URL ? response.URL : '');
                setMaxCap(response.MaxCap.toString());

            } else {
                setPreviewUrl("")
                setImageName("")
                setTableSeat("")
                setDescription("")
                setMaxCap('')
                setUrl("")
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error Fetching data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setTableDetailLoading(false);
        }
    };

    const formatFileName = (fileName: string) => {
        const fileNameArray = fileName.split('.');
        const ext = fileNameArray.pop();
        const rawFileName = fileNameArray.join('');
        const URLSafeFileName = rawFileName.replace(/[^a-zA-Z0-9]/g, '');
        return URLSafeFileName + '.' + ext;
    };
    const handleChangeImage = async (files: FileList | null) => {
        if (files && files.length > 0) {
            setPreviewUrl('');
            const file = files[0];
            const formattedFileName = formatFileName(file.name);
            const fileBlob = new Blob([file], { type: file.type });
            const modifiedFile = new File([fileBlob], formattedFileName, { type: file.type });
            const previewUrl = URL.createObjectURL(file);
            setPreviewUrl(previewUrl);
            setFile(modifiedFile);
        }
    };

    const handleOnChange = (field: string, value: string) => {
        switch (field) {
            case 'tableSeat':
                setTableSeat(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'url':
                setUrl(value);
                break;
            case 'maxCap':
                setMaxCap(value);
                break;
            default:
                break;
        }
    };
    const uploadImageFile = async (assetId: string): Promise<void> => {
        if (file) {
            await uploadAssetImageToS3(assetId, file.name, file);
        } else {
            throw new Error('Image file is not provided');
        }
    };
    const CanAddTable = (restaurantMaxCap: number, tableMaxCap: number, maxCapOfSelectedTable: number, maxCap: number) => {
        const remainingMaxCapOfRestaurant = restaurantMaxCap - tableMaxCap + maxCapOfSelectedTable;
        return remainingMaxCapOfRestaurant >= maxCap;
    }
    const handleSubmit = async () => {
        const nameOfTable = tableSeat.trim();
        const descriptionOfTable = description.trim();
        if (Number(maxCap) <= 0) {
            toast.warn('Max cap and Min cap should not be less than or equal to zero.', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        } else if (!nameOfTable || !descriptionOfTable || !maxCap) {
            toast.warn('Please fill in the required details', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
            return
        };
        if (!CanAddTable(restaurantMaxCap, tableMaxCap, maxCapOfSelectedTable, Number(maxCap))) {
            toast.warn('Cannot add table. The total capacity of tables exceeds the restaurant capacity.', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        try {
            setTableDetailLoading(true);
            const dataObj: Partial<TableSeat> = {
                AssetName: tableSeat.trim(),
                AssetType: "Table-Seat",
                Description: description.trim(),
                URL: url.trim(),
                MinCap: 1, // Changed to a fixed value
                MaxCap: Number(maxCap),
                Facility: facilityId,
                ImageName: imageName,
                RestaurantId: restaurantId,
            };
            if (editId) {
                // Update existing asset
                if (file) {
                    await uploadImageFile(editId);
                    const response = await updateAsset({
                        _id: editId,
                        ...dataObj,
                        ImageName: file.name,
                    });
                    if (response) {
                        sendToast("success", "Successfully Updated Table")
                    } else {
                        throw new Error('Failed to update Table');
                    }
                } else {
                    const response = await updateAsset({
                        _id: editId,
                        ...dataObj,
                    });
                    if (response) {
                        sendToast("success", "Successfully Updated Table")
                    } else {
                        throw new Error('Failed to update Table');
                    }
                }
            } else {
                // Create new asset
                const createdAssetId = await createAsset(dataObj);
                if (!createdAssetId) {
                    throw new Error('Failed to create Table');
                }
                if (file) {
                    await uploadImageFile(createdAssetId);
                    const response = await updateAsset({
                        _id: createdAssetId,
                        ...dataObj,
                        ImageName: file.name,
                    });
                    if (!response) {
                        throw new Error('Failed to create Table');
                    }
                }
                setRefreshPage((prev) => !prev);
                sendToast("success", "Successfully Created Table")
            }
            setRefreshTable((prev) => !prev);
            closeCreateTableModal();
        } catch (error) {
            toast.error(error instanceof Error ? error.message : 'Failed to Add Table', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setTableDetailLoading(false);
        }
    };
    return (
        <div>
            <Modal open={openCreateTableModal} onClose={closeCreateTableModal} size="mini">
                <Dimmer inverted active={tableDetailLoading}>
                    <Loader active={tableDetailLoading} />
                </Dimmer>
                <Modal.Content>
                    <Modal.Description>
                        <Form autoComplete="off" onSubmit={handleSubmit}>
                            <Form.Field required={true} style={{ marginTop: '20px' }}>
                                <label>Table-Seat</label>
                                <Form.Input
                                    value={tableSeat}
                                    required={true}
                                    placeholder="Table-Seat"
                                    onChange={(e) => handleOnChange('tableSeat', e.currentTarget.value)}
                                />
                            </Form.Field>
                            <Form.Field required={true} style={{ marginTop: '20px' }}>
                                <label>Description</label>
                                <Form.TextArea
                                    value={description}
                                    placeholder="Description"
                                    onChange={(e) => handleOnChange('description', e.currentTarget.value)}
                                />
                            </Form.Field>
                            <Form.Field style={{ marginTop: '20px' }}>
                                <label>URL</label>
                                <Form.Input
                                    placeholder="URL"
                                    value={url}
                                    onChange={(e) => handleOnChange('url', e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field width={6} required={true} style={{ marginTop: '20px' }}>
                                <label>Max Cap</label>
                                <Form.Input
                                    type="number"
                                    min="1"
                                    required={true}
                                    placeholder="Max Cap"
                                    value={maxCap}
                                    onChange={(e) => handleOnChange('maxCap', e.target.value)}
                                />
                            </Form.Field>
                            <Grid>
                                <Grid.Column width={16} style={{ textAlign: 'left', marginTop: "20px" }}>
                                    <Input
                                        id="imageInput"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleChangeImage(e.target.files)}
                                    />
                                    <div style={{ cursor: 'pointer' }}>
                                        {!previewUrl && (
                                            <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                                <Icon name="images" size="huge" />
                                            </label>
                                        )}
                                        {previewUrl && (
                                            <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                                <Image src={previewUrl} size="medium" />
                                            </label>
                                        )}
                                    </div>
                                </Grid.Column>
                            </Grid>
                            <Modal.Actions style={{ marginTop: '20px' }}>
                                <div>
                                    <Button type="submit" size={'small'}>
                                        {editId ? 'Update' : 'Save'}
                                    </Button>
                                    <Button size={'small'} style={{ marginLeft: '10px' }} onClick={closeCreateTableModal}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Actions>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>
    );
}

export default CreateTableModal