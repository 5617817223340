import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Item, Segment, Checkbox } from 'semantic-ui-react';
import { fetchDepartments } from '../../services/Departments';
import { updateUser } from '../../services/Users';
import { Department, User } from '../../types';

interface Props {
  staff: User
}

const StaffDetailView: FC<Props> = ({ staff }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [staffUser, setStaffUser] = useState({ ...staff })

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        const departments = await fetchDepartments();
        setDepartments(departments)
      } catch (error) {
        console.error("useEffect error", error)
        toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  const handleToggleChange = async (department: Department, value: boolean) => {
    console.log({ department, value })
    try {
      setIsLoading(true);
      let updatedDepartments: Department[] = [];
      if (value) { // if value is true, add the department
        updatedDepartments = [...staffUser.Departments];
        updatedDepartments.push(department);
      } else { // if value is false, remove the department
        updatedDepartments = staffUser.Departments.filter(departmentObj => departmentObj._id !== department._id);
      }
      const updatedStaff: User = { ...staffUser, Departments: updatedDepartments };
      await updateUser(updatedStaff, staffUser);
      setStaffUser({ ...staffUser, Departments: updatedDepartments })
    } catch (error) {
      console.error("handleToggleChange error", error)
      toast.warn(error instanceof Error ? error.message : "Failed to update data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Segment loading={isLoading}>
      <Item.Group>
        <Item>
          <Item.Content>
            <Item.Header as='h3'>{staffUser.FirstName} {staffUser.LastName}</Item.Header>
            <Item.Description>
              <p>{staffUser.Email}</p>
              <p>{staffUser.Cell}</p>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {departments.map((department) => {
                  const checked = staffUser.Departments ? staffUser.Departments.some((el) => el.Name === department.Name) : false;
                  return <Checkbox label={department.Name} toggle checked={checked} style={{ display: "block" }} onChange={(e, { checked }) => {
                    if (typeof checked === "boolean") {
                      handleToggleChange(department, checked)
                    }
                  }} />
                })}
              </div>
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  );
};

export default StaffDetailView;
