import API from './API';

export async function toggleContentStatusEmail(facilityId: string, contentStatusEmail: boolean): Promise<boolean> {
    const res = await API.lambdaPost('/content-status-email/update', { facilityId, contentStatusEmail });
    return res;
}

export async function updateSelectedCalendarTypes(facilityId: string, selectedCalendarTypes: string[]): Promise<boolean> {
    const res = await API.lambdaPost('/content-status-email/updateSelectedCalendarTypes', { facilityId, selectedCalendarTypes });
    return res;
}