import React, { useState } from "react";
import { Button, Checkbox, Header, Icon, Input, Modal, Table } from "semantic-ui-react";
import { v4 as uuidv4 } from 'uuid';
import { ServiceAddOn } from "../../types/Service";

const ServiceAddOns = ({ addOns, setAddOns, source }) => {
    const [itemName, setItemName] = useState<string>("");
    const [itemCost, setItemCost] = useState<number>(0);
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleAddOnClick = () => {
        if (itemName) {
            const newAddOn: ServiceAddOn = {
                id: uuidv4(), // added it since we need to edit and delete addons
                itemName: itemName,
                itemCost: itemCost,
                required: isRequired,
            };
            const updatedAddOns = addOns ? [newAddOn, ...addOns] : [newAddOn]; // Make sure to check if addOns is not null or undefined
            setAddOns(updatedAddOns);
            setItemName("");
            setItemCost(0);
            setIsRequired(false);
            setShowModal(false);
        } else {
            setShowModal(true); // Show modal if itemName is empty
        }
    };

    const handleEditProperty = (id: string, propertyName: string, newValue: string | boolean | number) => {
        setAddOns((prevAddOns: ServiceAddOn[]) => {
            const updatedAddOns: ServiceAddOn[] = prevAddOns.map(addOn =>
                addOn.id === id ? { ...addOn, [propertyName]: newValue } : addOn
            );
            return updatedAddOns;
        });
    };

    const deleteAddOn = (id: string) => {
        setAddOns((prevAddOns: ServiceAddOn[]) => prevAddOns.filter((addOn: ServiceAddOn) => addOn.id !== id));
    };

    return (
        <div style={{marginTop:"25px"}}>
            <div>
                <Header as="h3">{source === 'cookbook' ? "Sides" : "Service Add ons"}</Header>
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '10px 0' }}>
                <Icon
                    name="plus"
                    size="large"
                    onClick={handleAddOnClick}
                    style={{ cursor: "pointer" }}
                />
                <Input
                    placeholder="Item"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                />
                <Input
                    placeholder="Item Cost"
                    value={itemCost === null ? "" : itemCost}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setItemCost(newValue === "" ? 0 : parseFloat(newValue));
                    }}
                />
                <Checkbox
                    label="Required"
                    checked={isRequired}
                    onChange={() => setIsRequired((prev) => !prev)}
                />
            </div>
            <div>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Item</Table.HeaderCell>
                            <Table.HeaderCell>Item Cost</Table.HeaderCell>
                            <Table.HeaderCell>Required</Table.HeaderCell>
                            <Table.HeaderCell>Delete</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {addOns && addOns.map((addOn: ServiceAddOn) => (
                            <Table.Row key={addOn.id}>
                                <Table.Cell>
                                    <Input
                                        value={addOn.itemName}
                                        onChange={(e) => handleEditProperty(addOn.id, "itemName", e.target.value)}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        value={addOn.itemCost === null ? "" : addOn.itemCost}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            handleEditProperty(addOn.id, "itemCost", newValue === "" ? 0 : parseFloat(newValue));
                                        }}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Checkbox 
                                        checked={addOn.required}
                                        onChange={() => handleEditProperty(addOn.id, "required", !addOn.required)}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Button type="button" onClick={() => deleteAddOn(addOn.id)}>Delete</Button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                {/* Modal for showing message when itemName is empty */}
                <Modal open={showModal} onClose={() => setShowModal(false)}>
                    <Modal.Header>Enter Item Name</Modal.Header>
                    <Modal.Content style={{ color: "red" }}>
                        Please enter a valid item name before adding.
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowModal(false)}>OK</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    );
};

export default ServiceAddOns;
