import React, { useEffect, useState } from 'react';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import IconList from './IconList';
import './style.less';
import { getServiceCategories } from '../../../../services/service';
import { toast } from 'react-toastify';

interface CategoryIconlistProps {
    selectedCategory: string;
    handleCategorySelection: (category: string) => void;
    smallSize?: boolean;
    disableCategorySelection?: boolean;
}
function CategoryIconlist({ handleCategorySelection, selectedCategory, smallSize, disableCategorySelection }: CategoryIconlistProps) {
    const itemsPerRow = 8;
    const numRows = 2;
    const totalItems = itemsPerRow * numRows;
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [categoryList, setCategoryList] = useState<string[]>([]);

    const startItemIndex = currentPage * totalItems;
    const endItemIndex = startItemIndex + totalItems;

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const categories = await getServiceCategories({ sortAlphabetically: false });
            setCategoryList(categories);
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to get categories", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const visibleCategories = categoryList && categoryList.length > 0 && categoryList.slice(startItemIndex, endItemIndex);

    useEffect(() => { // Set the current page based on the selected category so that it is visible on the screen
        if (selectedCategory) {
            // Find the index of the initialCategory
            const categoryIndex = categoryList.findIndex(category => category === selectedCategory);

            // Calculate the page number based on the index
            const page = Math.floor(categoryIndex / totalItems);

            // Set the current page
            setCurrentPage(page);
        }
    }, [selectedCategory]);

    const handlePageChange = (direction: 'prev' | 'next') => {
        if (direction === 'prev' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && currentPage < Math.ceil(categoryList.length / totalItems) - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="menu-wrapper">
            <div className="carousel-arrows-container">
                <Icon name="angle left" className="angle left" onClick={() => handlePageChange('prev')} />
            </div>
            <Menu.Menu style={{ display: 'grid', gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)` }}>
                {visibleCategories && visibleCategories.length > 0 && visibleCategories.map((category) => (
                    <Menu.Item
                        disabled={disableCategorySelection}
                        className={`menu-item ${selectedCategory === category ? 'active' : ''}`}
                        key={category}
                        onClick={() => handleCategorySelection(category)}
                        style={{ width: smallSize ? '38px' : '', padding: smallSize ? '5px' : '10px' }}
                    >
                        <Popup
                            content={category}
                            trigger={
                                <div>
                                    <IconList name={category} size={smallSize ? 'small' : 'large'} />
                                </div>
                            }
                            size="tiny"
                            on="hover"
                            position="top center"
                        />
                    </Menu.Item>
                ))}
            </Menu.Menu>
            <div className="carousel-arrows-container">
                <Icon name="angle right" className="angle right" onClick={() => handlePageChange('next')} />
            </div>
        </div>
    );
}

export default CategoryIconlist;
