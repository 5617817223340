import React from 'react';
import { Button, Grid, Modal, Segment } from 'semantic-ui-react';
interface AvailabilityModalProps {
    openAvailabilityModal: boolean;
    days: string[];
    newTimings: any;
    setNewTimings: any;
    timings: any;
    setTimings: any;
    setOpenAvailabilityModal: React.Dispatch<React.SetStateAction<boolean>>;
}
interface TimeRange {
    startTime: string;
    endTime: string;
}
const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
    openAvailabilityModal,
    days,
    newTimings,
    setNewTimings,
    timings,
    setTimings,
    setOpenAvailabilityModal,
}) => {
    const availabilityOptions = [
        '12 : 00 AM',
        '1 : 00 AM',
        '2 : 00 AM',
        '3 : 00 AM',
        '4 : 00 AM',
        '5 : 00 AM',
        '6 : 00 AM',
        '7 : 00 AM',
        '8 : 00 AM',
        '9 : 00 AM',
        '10 : 00 AM',
        '11 : 00 AM',
        '12 : 00 PM',
        '1 : 00 PM',
        '2 : 00 PM',
        '3 : 00 PM',
        '4 : 00 PM',
        '5 : 00 PM',
        '6 : 00 PM',
        '7 : 00 PM',
        '8 : 00 PM',
        '9 : 00 PM',
        '10 : 00 PM',
        '11 : 00 PM',
        '11 : 59 PM',
    ];
    const getTimeRange = (time: string, timeRange: TimeRange) => {
        const tempTimeRange = { ...timeRange }; // new object to avoid mutating the state
        if (tempTimeRange.startTime) {
            if (time === tempTimeRange.startTime) {
                tempTimeRange.startTime = '';
                tempTimeRange.endTime = '';
            } else {
                if (availabilityOptions.indexOf(time) > availabilityOptions.indexOf(tempTimeRange.startTime))
                    tempTimeRange.endTime = time;
                else {
                    tempTimeRange.endTime = '';
                    tempTimeRange.startTime = time;
                }
            }
        } else {
            tempTimeRange.endTime = '';
            tempTimeRange.startTime = time;
        }
        return tempTimeRange;
    };
    const handleAvailabilityActions = (action: boolean) => {
        if (action) {
            setTimings(newTimings);
        } else setNewTimings(timings);
        setOpenAvailabilityModal(false);
    };
    const selectTimings = (day: string, time: string) => {
        const newTimeRange = getTimeRange(time, newTimings[day]);
        setNewTimings({ ...newTimings, [day]: { startTime: newTimeRange.startTime, endTime: newTimeRange.endTime } });
    };
    return (
        <div>
            <Modal open={openAvailabilityModal} size={'fullscreen'}>
                <Modal.Content scrolling style={{ maxHeight: '80vh' }}>
                    <Grid columns={7} divided>
                        <Grid.Row stretched>
                            {days.map((day) => (
                                <Grid.Column key={day}>
                                    <Segment style={{ color: '#B6B6B6' }}>{day}</Segment>
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                        {availabilityOptions.map((item) => (
                            <Grid.Row key={item} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                {days.map((day) => (
                                    <Grid.Column key={day}>
                                        <Segment
                                            style={{
                                                backgroundColor:
                                                    newTimings[day].startTime === item ||
                                                    newTimings[day].endTime === item ||
                                                    (availabilityOptions.indexOf(newTimings[day].startTime) <
                                                        availabilityOptions.indexOf(item) &&
                                                        availabilityOptions.indexOf(item) <
                                                            availabilityOptions.indexOf(newTimings[day].endTime))
                                                        ? '#183466'
                                                        : '',
                                                color:
                                                    newTimings[day].startTime === item ||
                                                    newTimings[day].endTime === item ||
                                                    (availabilityOptions.indexOf(newTimings[day].startTime) <
                                                        availabilityOptions.indexOf(item) &&
                                                        availabilityOptions.indexOf(item) <
                                                            availabilityOptions.indexOf(newTimings[day].endTime))
                                                        ? 'white'
                                                        : '',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => selectTimings(day, item)}
                                        >
                                            {item}
                                        </Segment>
                                    </Grid.Column>
                                ))}
                            </Grid.Row>
                        ))}
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => handleAvailabilityActions(true)}>Submit</Button>
                    <Button onClick={() => handleAvailabilityActions(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default AvailabilityModal;
