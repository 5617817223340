import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { Segment } from 'semantic-ui-react';

interface Props {
    data: { name: string; count: number }[]; // Current Data
    previousData: { name: string; count: number }[]; // Previous Data
    heading: string; // To be placed on top of the graph describing about it e.g. CheckIn per per ime period
}
const DoubleLineGraph : React.FC<Props> = ({ data, previousData, heading }) => {
    const lineData = {
        labels: data.map((item) => item.name), // labels to be added according to current data
        datasets: [
            {
                label: 'current',
                data: data.map((item) => item.count),
                fill: false,
                borderColor: '#56f07f', //light green
                borderWidth: 1.5,
            },
            {
                label: 'Matching previous timeframe',
                data: previousData.map((item) => item.count),
                fill: false,
                borderWidth: 1.5,
                borderColor: 'rgba(128,0,128,1)', //dark purple
            },
        ],
    };

    return (
        <Segment>
            <h3 style={{ textAlign: 'left', marginBottom: '0px' }}>{heading}</h3>
            <Line data={lineData} />
        </Segment>
    );
};

export default DoubleLineGraph;
