import React, { useState, useEffect } from 'react'
import {
    fetchPaginatedRegistrantRelatives,
    fetchPaginatedRegistrantA4HContacts,
    enableInboundCalling,
    deleteRelativeContact,
    updateAnnouncementAlert
} from '../../../../services/Registrants'
import { RegistrantRelative } from '../../../../types'
import { Dimmer, Loader, Button, List, Dropdown, Icon, Grid, Segment, Card, Item, Modal } from 'semantic-ui-react'
import './style.less'
import * as jsonexport from 'jsonexport/dist'
import { saveAs } from 'file-saver'
import LoadMore from '../../../../components/LoadMore'
import AddContactModal from './addContactModal'
import UpdateContactName from './UpdateContactName'
import UpdateContactNumber from './UpdateContactNumber'
import InboundSync from './InboundSync'
import AnnouncementAlertToggle from './AnnouncementAlert'

import { toast } from 'react-toastify'

import { deleteA4HContact } from '../../../../services/Registrants'
import AddCommProfileModal from './AddCommProfileModal'
import UpdateA4HContactEmail from './UpdateContactEmail'
import AddressBookItem from '../../../../components/AddressBookItem'
import ContactDetailView from './ContactDetailView'
import FilterPrintExportIcons from '../../../../components/FilterPrintExportIcons'
import PrintExportIcons from '../../../../components/PrintExportIcons'

const RegistrantRelatives = ({ registrantId, activeIndex, registrant, reloadList, removeOriginalManagerFromFamilyInvite, children, handleRPBox = (registrantRelative) => {} }) => {
    const [registrantRelativesAppEntered, setRegistrantRelativesAppEntered] = useState<RegistrantRelative[]>([])
    const [registrantRelativesStaffEntered, setRegistrantRelativesStaffEntered] = useState<RegistrantRelative[]>([])
    const [registrantRelativesRoomToRoom, setRegistrantRelativesRoomToRoom] = useState<RegistrantRelative[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [hasMoreAppEntered, setHasMoreAppEntered] = useState(false)
    const [hasMoreStaffEntered, setHasMoreStaffEntered] = useState(false)
    const [hasMoreRoomToRoom, setHasMoreRoomToRoom] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [listType, setListType] = useState('')
    const [isEditNameOpen, setIsEditNameOpen] = useState(false)
    const [isEditNumberOpen, setIsEditNumberOpen] = useState(false)
    const [isEditEmailOpen, setIsEditEmailOpen] = useState(false)
    const [editContactId, setEditContactId] = useState<string | undefined>('')
    const [editContactName, setEditContactName] = useState<string | undefined>('')
    const [editContactNumber, setEditContactNumber] = useState<string | undefined>('')
    const [editEmail, setEditEmail] = useState<string | undefined>('')
    const [openDetailViewModal, setOpenDetailViewModal] = useState(false)
    const [modalHeading, setModalHeading] = useState("")
    const [registrantRelativesDetails, setRegistrantRelativesDetails] = useState<RegistrantRelative | null>(null)
    const limit = 6
    const fetchNext = async () => {
        try {
            setIsLoading(true)
            let response
            console.log(listType, 'lll')
            if (listType === 'relatives') {
                response = await fetchPaginatedRegistrantRelatives(registrantId, pageNo, limit)
                setRegistrantRelativesAppEntered([...registrantRelativesAppEntered, ...response])
                setHasMoreAppEntered(!(response.length < limit))
                setIsLoading(false)
            } else if (listType === "staff") {
                response = await fetchPaginatedRegistrantA4HContacts(registrantId, pageNo, limit, listType);
                setRegistrantRelativesStaffEntered([...registrantRelativesStaffEntered, ...response])
                setHasMoreStaffEntered(!(response.length < limit))
                setIsLoading(false)
            } else if (listType === "room") {
                response = await fetchPaginatedRegistrantA4HContacts(registrantId, pageNo, limit, listType);
                setRegistrantRelativesRoomToRoom([...registrantRelativesRoomToRoom, ...response])
                setHasMoreRoomToRoom(!(response.length < limit))
                setIsLoading(false)
            }
            setListType('');
        } catch (err) {
            console.log(err)
            setListType('');
        }
    }

    // todo remove the below code once code review is done
    // const reload = async (listT?: string) => {
    //     try {
    //         const limit = 6
    //         setIsLoading(true)
    //         let response
    //         setRegistrantRelativesAppEntered([])
    //         setRegistrantRelativesStaffEntered([])
    //         setRegistrantRelativesRoomToRoom([])
    //         if (listT === 'relatives' || listType === 'relatives') {
    //             response = await fetchPaginatedRegistrantRelatives(registrantId, 1, limit)
    //             setRegistrantRelativesAppEntered([...response])
    //             setHasMoreAppEntered(!(response.length < limit))
    //         } else if (listT === 'staff' || listType === "staff") {
    //             response = await fetchPaginatedRegistrantA4HContacts(registrantId, 1, limit, listType);
    //             setRegistrantRelativesStaffEntered([...response])
    //             setHasMoreStaffEntered(!(response.length < limit))
    //         } else if (listT === 'room' || listType === "room"){
    //             response = await fetchPaginatedRegistrantA4HContacts(registrantId, 1, limit, listType);
    //             setRegistrantRelativesRoomToRoom([...response])
    //             setHasMoreRoomToRoom(!(response.length < limit))
    //         }
    //         setIsLoading(false)
    //         setPageNo(1 + 1)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    const reload = async () => {
        try {
            setListType('')
            const limit = 6
            setIsLoading(true)
            const [relatives, staff, room] = await Promise.all([
                fetchPaginatedRegistrantRelatives(registrantId, 1, limit),
                fetchPaginatedRegistrantA4HContacts(registrantId, 1, limit, "staff"),
                fetchPaginatedRegistrantA4HContacts(registrantId, 1, limit, "room")
            ])
            // app entered tab
            setRegistrantRelativesAppEntered(relatives)
            setHasMoreAppEntered(!(relatives.length < limit))
            // Cell-Landline tab
            setRegistrantRelativesStaffEntered([...staff])
            setHasMoreStaffEntered(!(staff.length < limit))
            // room to room calling tab
            setRegistrantRelativesRoomToRoom([...room])
            setHasMoreRoomToRoom(!(room.length < limit))
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false);
            console.log(err)
        }
    }

    useEffect(() => {
        console.log('loaded', registrantId)
        // Call the api here, and then set the state
        // Iterate and show the status of each relative
        // FirstName, LastName,
        // isOriginalManager, isManager
        // Contact
        // Status of the reciprocal call -
        // If is in address book
        reload()
    }, [registrantId, reloadList])

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    const connectBtnHandler = async (contactId) => {
        const result = await enableInboundCalling(registrantId, contactId)
        console.log(result, 'result data')
        if (!result.success) {
            sendToast('warn', result.data.message)
        } else {
            sendToast('success', result.data.message)
        }
    }

    const toggleModalHandler = () => {
        setOpenDetailViewModal((prev) => !prev)
        setRegistrantRelativesDetails(null)
        setListType('')
    }

    useEffect(() => {
        (async () => {
            await fetchNext()
        })()
    }, [pageNo])

    const options = [
        { key: 'staff', text: 'Cell-Landline', value: 'staff' },
        { key: 'room', text: 'Room To Room', value: 'room' },
    ];

    const getAddressBookExportData = () => {
        const exportData: {}[] = []
        const joinedArr: RegistrantRelative[] = [...registrantRelativesAppEntered, ...registrantRelativesRoomToRoom, ...registrantRelativesStaffEntered]
        joinedArr.forEach((registrantRelative) => {
            const { RelativeName = "", manager: IsManager = false, isOriginalManager, contacts: Contacts = undefined, Contact = "" } = registrantRelative;

            const Category = registrantRelativesAppEntered.includes(registrantRelative) ? 'App Entered' :
                registrantRelativesRoomToRoom.includes(registrantRelative) ? 'Room To Room' :
                    registrantRelativesStaffEntered.includes(registrantRelative) ? 'Cell-Landline' :
                        'Unknown';
            const isContactAmazonId = Contact.includes("amzn1")
            const roomToRoomContact = Contacts && Contacts.contact && Contacts.contact.phoneNumbers && Contacts.contact.phoneNumbers.length && Contacts.contact.phoneNumbers.map(el => {
                return `${el.number}\n`
            })
            const data = { // keep the keys capital so that column headings are capital
                RelativeName,
                Contact: Category === "Room To Room" ? roomToRoomContact : isContactAmazonId ? "" : Contact,
                Category,
                IsManager,
                isOriginalManager
            }
            exportData.push(data)
        })

        return exportData
    }

    const onStaffDeleteSuccess = (relativeId: string) => {
        const filteredRelatives = registrantRelativesStaffEntered.filter(el => el._id !== relativeId)
        setRegistrantRelativesStaffEntered(filteredRelatives)
        //// load more button doesnt get updated when this happens
    }

    const onRoomDeleteSuccess = (relativeId: string) => {
        const filteredRooms = registrantRelativesRoomToRoom.filter(el => el._id !== relativeId)
        setRegistrantRelativesRoomToRoom(filteredRooms)
        //// load more button doesnt get updated when this happens
    }

    const onAppEnteredDeleteSuccess = (relativeId: string) => {
        const filteredRelatives = registrantRelativesAppEntered.filter(el => el._id !== relativeId)
        setRegistrantRelativesAppEntered(filteredRelatives)
        //// load more button doesnt get updated when this happens
    }

    const onUpdateSuccess= async () => {
        await reload()
    } 

    return registrantId ? (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                <Button className='primary' compact style={{padding: '0'}}>
                <Dropdown
                    text='Create Contact'
                    multiple
                    closeOnChange
                    value = {listType}
                    onChange={(e, data) => {
                        if (data.value) {
                            setListType(String(data.value))
                            setIsModalOpen(true)
                        }
                    }}
                    options={options}
                    style={{color: 'white', margin: '0', padding: '4px 16px 4px 6px'}}
                >
                </Dropdown>
                </Button>
                <PrintExportIcons
                    exportData={getAddressBookExportData()}
                    onPrintClick={() => { }}
                    disablePrintButton={true}
                    disableExportButton={isLoading}
                    exportFileName={`${registrant.FirstName} ${registrant.LastName}-addressbook.csv`}
                />
            </div>
            <Grid columns={3} style={{ height: '100%', overflowY: "scroll" }}>
                <Dimmer active={isLoading} inverted>
                    <Loader />
                </Dimmer>
                <Grid.Column width={5} style={{ height: '100%' }}>
                    <Segment style={{ height: '100%' }}>
                        <Card>
                            <Card.Content>
                                <Card.Header style={{ margin: '10px 0px' }}>Cell-Landline</Card.Header>
                                <Item.Group relaxed>
                                    {registrantRelativesStaffEntered && registrantRelativesStaffEntered.length ? registrantRelativesStaffEntered.map((el) => {
                                        // todo figure out how i have to pass image url for below
                                        return <AddressBookItem
                                            cell={el.Contact.includes('amzn1.alexa.communications.profile.did')
                                                ? `${el.contacts && el.contacts.contact.name}`
                                                : `${el.Contact}`}
                                            name={el.RelativeName}
                                            onEditClick={() => {
                                                toggleModalHandler()
                                                setModalHeading("Cell-Landline")
                                                setRegistrantRelativesDetails(el)
                                                setListType("staff")
                                            }}
                                            listType={"staff"}
                                            onSync={connectBtnHandler}
                                            relaitve={el}
                                            reload={reload}
                                            residentId={registrantId}
                                            onDeleteSuccess={onStaffDeleteSuccess}
                                        />
                                    })
                                        :
                                        <></>
                                    }
                                    <Item.Extra>
                                        <LoadMore hasMore={hasMoreStaffEntered} isLoading={isLoading} next={() => {
                                            setListType("staff")
                                            setPageNo((prev) => prev + 1)
                                        }} />
                                    </Item.Extra>
                                </Item.Group>
                            </Card.Content>
                        </Card>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={5} style={{ height: '100%' }}>
                    <Segment style={{ height: '100%' }}>
                        <Card>
                            <Card.Content>
                                <Card.Header style={{ margin: '10px 0px' }}>Apartment To Apartment</Card.Header>
                                <Item.Group relaxed>
                                    {registrantRelativesRoomToRoom && registrantRelativesRoomToRoom.length ? registrantRelativesRoomToRoom.map((el) => {
                                        // todo figure out extra keys
                                        return <AddressBookItem
                                            cell={el.Contact.includes('amzn1.alexa.communications.profile.did')
                                                ? `${el.contacts && el.contacts.contact.name}`
                                                : `${el.Contact}`}
                                            name={el.RelativeName}
                                            onEditClick={() => {
                                                toggleModalHandler()
                                                setModalHeading("Room To Room")
                                                setListType("room")
                                                setRegistrantRelativesDetails(el)
                                            }}
                                            listType={"room"}
                                            onSync={connectBtnHandler}
                                            relaitve={el}
                                            reload={reload}
                                            residentId={registrantId}
                                            onDeleteSuccess={onRoomDeleteSuccess}
                                        />
                                    })
                                        :
                                        <></>
                                    }

                                    <Item.Extra>
                                        <LoadMore hasMore={hasMoreRoomToRoom} isLoading={isLoading} next={() => {
                                            setListType("room")
                                            setPageNo((prev) => prev + 1)
                                        }} />
                                    </Item.Extra>
                                </Item.Group>

                            </Card.Content>
                        </Card>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6} style={{ height: '100%' }}>
                    <Segment style={{ height: '100%' }}>
                        <Card>
                            <Card.Content>
                                <Card.Header style={{ margin: '10px 0px' }}>App Entered</Card.Header>
                                {children}
                                <Item.Group relaxed>
                                    {registrantRelativesAppEntered && registrantRelativesAppEntered.length ? registrantRelativesAppEntered.map((el) => {
                                        return <AddressBookItem
                                            cell={el.Contact}
                                            name={el.RelativeName}
                                            onEditClick={() => {
                                                toggleModalHandler()
                                                setModalHeading("App Entered")
                                                setListType("relatives")
                                                setRegistrantRelativesDetails(el)
                                            }}
                                            listType={"relatives"}
                                            onSync={connectBtnHandler}
                                            relaitve={el}
                                            reload={reload}
                                            residentId={registrantId}
                                            onDeleteSuccess={onAppEnteredDeleteSuccess}
                                            handleRPBox={handleRPBox}
                                        />
                                    })
                                        :
                                        <></>
                                    }
                                    <Item.Extra>
                                        <LoadMore hasMore={hasMoreAppEntered} isLoading={isLoading} next={() => {
                                            setListType("relatives")
                                            setPageNo((prev) => prev + 1)
                                        }} />
                                    </Item.Extra>
                                </Item.Group>
                            </Card.Content>
                        </Card>
                    </Segment>
                </Grid.Column>
            </Grid>


            {/* <div className="UnitsList">
                <Dimmer active={isLoading} inverted>
                    <Loader active={isLoading} />
                </Dimmer>

                <h2>Add Contacts:</h2>
                <div className="staff-contacts-box">
                    <div className="select">
                        <Dropdown
                            options={[
                                {
                                    key: 1,
                                    text: 'App Entered',
                                    value: 'relatives',
                                },
                                {
                                    key: 2,
                                    text: 'Staff Entered',
                                    value: 'staff',
                                },
                                {
                                    key: 3,
                                    text: "Room to room",
                                    value: "room"
                                }
                            ]}
                            onChange={(e, { value }) => {
                                console.log('value', value)
                                //@ts-ignore
                                setListType(value)
                            }}
                            selection
                            value={listType}
                        ></Dropdown>
                    </div>

                </div>
                <List divided relaxed className="staff-detail">
                    {(listType==='staff' ? registrantRelativesStaffEntered : listType === 'room' ? registrantRelativesRoomToRoom : registrantRelativesAppEntered).map((relative) => {
                        return (
                            <List.Item key={relative.RelativeId} size="large" disabled={!relative.isActive}>
                                <List.Content>
                                    <List.Header as="a">{relative.RelativeName}</List.Header>
                                </List.Content>
                                <List.Description>
                                    Username:{' '}
                                    <a>
                                        <b>
                                            {relative.Contact.includes('amzn1.alexa.communications.profile.did')
                                                ? `${relative.contacts && relative.contacts.contact.name}`
                                                : `${relative.Contact}`}
                                        </b>
                                    </a>
                                </List.Description>
                                <List.Description>
                                    Role:{' '}
                                    <a>
                                        <b>
                                            {relative.isOriginalManager
                                                ? 'Original Manager'
                                                : relative.manager
                                                ? 'Manager'
                                                : 'Member'}
                                        </b>
                                    </a>
                                </List.Description>
                                <List.Description>
                                    <AnnouncementAlertToggle
                                        reload={reload}
                                        listType={listType}
                                        relative={relative}
                                        updateAnnouncementAlert={updateAnnouncementAlert}
                                    />
                                </List.Description>

                                <List.Description>
                                Email:{' '}
                                <a>
                                    {relative.email ? <b>{relative.email}</b> : <b>-</b>}
                                </a>
                                <a>
                                    <Icon
                                        className="addr-name-edit"
                                        name="edit"
                                        onClick={() => {
                                            setEditContactId(relative.RelativeId)
                                            setIsEditEmailOpen(true)
                                            setEditEmail(
                                                relative.email,
                                            )
                                        }}></Icon>
                                </a>
                                </List.Description>

                                {relative.contacts && relative.contacts.contact && relative.contacts.contact.name ? (
                                    <List.Description>
                                        AddressBook Name:{' '}
                                        <a>
                                            <b>{relative.contacts.contact.name}</b>
                                        </a>
                                        <a>
                                            <Icon
                                                className="addr-name-edit"
                                                name="edit"
                                                onClick={() => {
                                                    console.log('clicked')

                                                    setEditContactId(relative.RelativeId)
                                                    setIsEditNameOpen(true)
                                                    setEditContactName(
                                                        relative.contacts && relative.contacts.contact.name,
                                                    )
                                                }}
                                            ></Icon>
                                        </a>
                                    </List.Description>
                                ) : (
                                    <></>
                                )}

                                {relative.contacts && relative.contacts.contact && relative.contacts.contact.phoneNumbers ? (relative.contacts.contact.phoneNumbers.map((e, index) => (
                                        <List.Description key={e.number}>
                                            Contact {index === 0 ? '' : index + 1}:{' '}
                                            <a>
                                                <b>{e.number}</b>
                                            </a>
                                            <a>
                                                <Icon
                                                    className="addr-name-edit"
                                                    name="edit"
                                                    onClick={() => {
                                                        console.log('clicked')

                                                        setEditContactId(relative.RelativeId)
                                                        setIsEditNumberOpen(true)
                                                        setEditContactNumber(e.number)
                                                    }}
                                                ></Icon>
                                            </a>
                                        </List.Description>
                                    ))
                                ) : (
                                    <></>
                                )}
                                {relative.contacts && relative.contacts.reciprocals && listType !== 'room' ? (
                                    <List.Description>
                                        Inbound Calling:{' '}
                                        <a>
                                            <b>{relative.contacts.reciprocals} </b>
                                        </a>
                                        {relative.contacts.reciprocals !== 'ENABLED' && (
                                            <InboundSync
                                                onSync={connectBtnHandler}
                                                reload={reload}
                                                listType={listType}
                                                relative={relative}
                                            />
                                        )}
                                    </List.Description>
                                ) : (
                                    <></>
                                )}
                                {(listType === 'staff' || listType === 'room' || listType === 'relatives')? (
                                    <Button
                                        className="ui red basic button delete-staff"
                                        onClick={async (e) => {
                                            e.preventDefault()
                                            console.log('console')
                                            setIsLoading(true)
                                            try {
                                                if (listType === "staff" || listType === "room") {
                                                    await deleteA4HContact(registrantId, relative.RelativeId)
                                                    await reload("room")
                                                } else if (listType === 'relatives') {
                                                    if(relative.isOriginalManager) {
                                                        removeOriginalManagerFromFamilyInvite() }
                                                    await deleteRelativeContact({
                                                        RegistrantId: registrantId,
                                                        RelativeId: relative.RelativeId
                                                    })
                                                    await reload("relative")
                                                }
                                                setIsLoading(false)
                                                sendToast('success', 'Contact deleted')
                                                await reload('room')
                                            } catch (er) {
                                                setIsLoading(false)
                                                console.log('er', er)
                                                sendToast('warn', 'Error deleting contact')
                                            }
                                        }}
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </List.Item>
                        )
                    })}
                </List>
                <LoadMore
                    next={(e) => {
                        e.preventDefault()
                        fetchNext()
                    }}
                    isLoading={isLoading}
                    hasMore={listType === 'staff' ? hasMoreStaffEntered : listType === 'room' ? hasMoreRoomToRoom : hasMoreAppEntered}
                />
            </div> */}
            {isModalOpen && listType === "staff" && (
                <AddContactModal
                    residentId={registrantId}
                    isModalOpen={isModalOpen}
                    close={() => {
                        setIsModalOpen(false)
                        setListType("")
                    }}
                    reload={async () => {
                        await reload()
                    }}
                />
            )}
            {isModalOpen && listType === "room" && <AddCommProfileModal isModalOpen={isModalOpen} close={() => {
                setIsModalOpen(false)
                setListType("")
            }} registrant={registrant} reload={async () => {
                await reload()
            }
            } />}
            {isEditNameOpen && (
                <UpdateContactName
                    isModalOpen={isEditNameOpen}
                    residentId={registrantId}
                    contactId={editContactId}
                    close={() => {
                        console.log('Close')
                        setIsEditNameOpen(false)
                        setListType('')
                    }}
                    inputName={editContactName}
                    type={listType}
                    reload={reload}
                />
            )}
            {isEditEmailOpen && (
                <UpdateA4HContactEmail
                    isModalOpen={isEditEmailOpen}
                    residentId={registrantId}
                    contactId={editContactId}
                    close={() => {
                        console.log('Close')
                        setIsEditEmailOpen(false)
                        setListType('')
                    }}
                    inputEmail={editEmail}
                    type={listType}
                    reload={reload}
                />
            )}
            {isEditNumberOpen && (
                <UpdateContactNumber
                    isModalOpen={isEditNumberOpen}
                    residentId={registrantId}
                    contactId={editContactId}
                    close={() => {
                        console.log('Close')
                        setIsEditNumberOpen(false)
                        setListType('')
                    }}
                    inputNumber={editContactNumber}
                    type={listType}
                    reload={reload}
                />
            )}

            {/* <div className="buy-container">
                <Button
                    className="primary large bottom-invite-button"
                    onClick={(e) => {
                        e.preventDefault()
                        console.log('open modal')
                        setIsModalOpen(true)
                    }}
                >
                    <i className="plus circle icon"></i> Add Contact
                </Button>
            </div> */}
            <Modal open={openDetailViewModal} onClose={toggleModalHandler} closeIcon={true} closeOnDocumentClick={true}>
                <Modal.Header>{modalHeading}</Modal.Header>
                <Modal.Content>
                    {registrantRelativesDetails ?
                        <ContactDetailView
                            announcementsEnabled={registrantRelativesDetails.announcementAlerts ? true : false}
                            contact={registrantRelativesDetails.Contact} 
                            name={registrantRelativesDetails.RelativeName}
                            role='adfsafsd'
                            manager={registrantRelativesDetails.manager}
                            relationship={"figure out"}
                            contacts={registrantRelativesDetails.contacts}
                            listType={listType}
                            onSync={connectBtnHandler}
                            relative={registrantRelativesDetails}
                            reload={reload}
                            registrantId={registrantId}
                            onUpdateSuccess={onUpdateSuccess}
                            setModalOpenState={setOpenDetailViewModal}
                        /> : <></>
                    }
                </Modal.Content>
            </Modal>
        </div>
    ) : (
        <></>
    )
}

export default RegistrantRelatives
