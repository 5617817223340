import { Asset } from '../types/Asset';
import { TableSeat } from '../types/Dining';
import { RestaurantListFilter } from '../types/Dining';
import { fetchAllPaginatedData } from '../util';
import API from './API';

export interface PageSort {
    page_size: number;
    page_no: number;
    sort: { DateAdded: string; }[];
}

export async function listAssets(filters = {}): Promise<any> {
    try {
        const assetListApiCall = async (params: any) => { return await API.lambdaPost('/assets/list', params); };
        const response = await fetchAllPaginatedData(assetListApiCall, filters);
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing Asset', error);
        throw new Error(`Something went wrong in Listing Asset ${error}`);
    }
}

export async function listPaginatedAssets({ filters = {}, pageSort = { page_size: 100, page_no: 1, sort: [{ DateAdded: 'asc' }] } }): Promise<any> {
    try {
        const params = {
            pagingSorting: pageSort,
            Filter: filters,
            sort: [{ DateAdded: "asc" }],
        };
        const response = await API.lambdaPost('/assets/list', params);
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing Asset', error);
        throw new Error(`Something went wrong in Listing Asset ${error}`);
    }
}

export async function createAsset(asset: Partial<Asset>): Promise<string> {
    try {
        const response = await API.lambdaPost('/assets/add', asset);
        return response;
    } catch (error) {
        console.error('Something went wrong in adding Asset', error);
        throw new Error(`Something went wrong in adding Asset ${error}`);
    }
}

export async function updateAsset(asset: Partial<Asset | TableSeat>, imageName: string = ''): Promise<string> {
    try {
        const params = {
            asset,
            imageName,
        };
        const response = await API.lambdaPut('/assets/update', params);
        return response;
    } catch (error) {
        console.error('Something went wrong in updating Asset', error);
        throw new Error(`Something went wrong in updating Asset ${error}`);
    }
}

export async function getOneAsset(id: string): Promise<any> {
    // response type is any because it returns an asset object and a signedimage url from backend
    try {
        const response = await API.lambdaGet(`/assets/get?_id=${id}`);
        return response;
    } catch (error) {
        console.error('Something went wrong in fetching one Asset', error);
        throw new Error(`Something went wrong in fetching one Asset ${error}`);
    }
}

export async function deleteAsset(assetId: string, imageName?: string): Promise<Asset> {
    try {
        const response = await API.lambdaDeleteById(`/assets/delete`, { assetId: assetId, imageName: imageName });
        return response;
    } catch (error) {
        console.error('Something went wrong in deleting one Asset', error);
        throw new Error(`Something went wrong in deleting one Asset ${error}`);
    }
}

export async function copyS3AssetImage(
    copiedFromFacilityId: string,
    copiedFromAssetId: string,
    copiedToAssetId: string,
    imageName: string,
): Promise<any> {
    try {
        const response = await API.lambdaPost('/assets/images/copyassetimage', {
            copiedFromFacilityId,
            copiedFromAssetId,
            copiedToAssetId,
            imageName,
        });
        return response;
    } catch (error) {
        console.error('error copyS3AssetImage', error);
        throw new Error(`Something went wrong in copying asset image ${error}`);
    }
}

export async function listPaginatedRestaurant({ filters = {} as RestaurantListFilter, pageSort = { page_size: 100, page_no: 1, sort: [{ DateAdded: 'asc' }] } as PageSort }): Promise<any> {
    try {
        const restaurantFilter = {
            ...filters,
            AssetType: "Restaurant"
        };
        const params = {
            pagingSorting: pageSort,
            Filter: restaurantFilter,
        };
        const response = await API.lambdaPost('/assets/list', params);
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing Asset', error instanceof Error ? error.message : error);
        throw new Error(`Something went wrong in Listing Asset ${error instanceof Error ? error.message : error}`);
    }
}

export async function listAllRestaurant(filters = {} as RestaurantListFilter, isImageRequired = false as boolean): Promise<any> {
    try {
        const restaurantFilter = {
            ...filters,
            AssetType: "Restaurant"
        };
        const assetListApiCall = async (params: any) => { return await API.lambdaPost('/assets/list', params); };
        const response = await fetchAllPaginatedData(assetListApiCall, { Filter: restaurantFilter, isImageRequired });
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing Asset', error instanceof Error ? error.message : error);
        throw new Error(`Something went wrong in Listing Asset ${error instanceof Error ? error.message : error}`);
    }
}

export async function listTableSeatConfiguration(filters = {} as RestaurantListFilter): Promise<any> {
    try {
        const tableFilter = { ...filters, AssetType: "Table-Seat" };
        const assetListApiCall = async (params: any) => { return await API.lambdaPost('/assets/list', params); };
        const response = await fetchAllPaginatedData(assetListApiCall, { Filter: tableFilter });
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing Table-Seat', error instanceof Error ? error.message : error);
        throw new Error(`Something went wrong in Listing Table-Seat ${error instanceof Error ? error.message : error}`);
    }
}
