import React, { Component, ChangeEvent } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'

import './style.less'
import { User } from '../../types'

interface Props {
    users: User[]
    loading?: boolean
    disabled?: boolean
    onSelectionChange?: (u: User | null) => void
    locationState?: boolean
    customButtonText?: string | null
}

interface State {
    searchInput: string
}
class AssignButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            searchInput: '',
        }
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchInput: e.currentTarget.value,
        })
    }

    getFilteredUsersOptions() {
        return this.props.users
            .filter(u => {
                const fullName = (u.FirstName + ' ' + u.LastName).toLowerCase()
                return fullName.includes(this.state.searchInput.toLowerCase())
            })
            .map(u => ({
                key: u._id,
                text: u.FirstName + ' ' + u.LastName,
                value: u._id,
                image: { avatar: true, src: u.Icon || `${process.env.PUBLIC_URL}/avatar_placeholder.svg` },
                disabled : u.onBreak 
            })).sort((a, b) => a.text.localeCompare(b.text))
    }

    handleSelect(_: any, data: any) {
        if (!this.props.onSelectionChange) return
        const user = this.props.users.find(u => u._id === data.value) || null
        this.props.onSelectionChange(user)
    }

    render() {
        return (
            <Dropdown
                scrolling
                text={this.props.customButtonText ? this.props.customButtonText : this.props.locationState ? 'Assign All' : 'Assign'}
                icon="user"
                fluid
                labeled
                button
                basic
                className="icon button primary AssignButton"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.disabled}
            >
                <Dropdown.Menu>
                    <Input
                        icon="search"
                        iconPosition="left"
                        className="search"
                        onClick={(e: Event) => e.stopPropagation()}
                        value={this.state.searchInput}
                        onChange={this.handleChange.bind(this)}
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header content="Assign To" />
                    {this.getFilteredUsersOptions().map(option => (
                        <Dropdown.Item {...option} onClick={this.handleSelect.bind(this)} />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default AssignButton
