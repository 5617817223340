import React from 'react'
import { Button, Grid, Dropdown, Loader, Popup } from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import './style.less'
import CustomDatePicker from '../../../components/CustomDatePicker'
import moment from 'moment-timezone'
import { sortByKey } from '../../../util/sortData';

const CheckinFiltersAndAnnouncementButton = ({
    handleFiltersSubmit,
    filters,
    handleFilterChange,
    handleFiltersReset,
    residentsOptionsList,
    handleSendAnnouncement,
    handleExport,
    isExporting,
    currentDate
}) => {
    const handleChange = (selectedDate: Date) => {
        handleFilterChange({
            selectedDate: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null
        })
    }
    console.log({ residentsOptionsList, resident: filters.resident })
    return (
        <div className="Reports" style={{ marginTop: '20px', marginBottom: '20px' }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: '10px' }}>
                <CustomDatePicker 
                    selectedDate={moment(filters.selectedDate).toDate()} 
                    setDate={handleChange} 
                    maxDate={moment(currentDate).toDate()} 
                    selectsRange = {false} 
                />
                <div className="">
                    {residentsOptionsList ? <Dropdown
                        options={[
                            { value: 'all', text: 'All', key: "all" },
                            ...sortByKey(residentsOptionsList.map(item => {
                                return { value: item._id, text: `${item.FirstName} ${item.LastName}`, key: item._id }
                            })),
                        ]}
                        onChange={(e, { value }) => {
                            console.log({ value })
                            handleFilterChange({
                                resident: String(value)
                            })

                        }}
                        search
                        value={filters.resident}
                        placeholder="select a resident"
                        selection
                    /> : <Loader active={true} />}
                </div>

                <Button
                    primary
                    onClick={handleFiltersSubmit}
                    disabled={!filters.selectedDate || !filters.resident}
                >
                    Set
                </Button>
                <Button
                    primary
                    onClick={handleFiltersReset}
                    disabled={(filters.resident === 'all') && (filters.selectedDate === currentDate)}
                >
                    Reset filters
                </Button>

                <Button
                    primary
                    onClick={handleSendAnnouncement}
                >
                    Reminder
                </Button>               
                <Popup
                    trigger={
                        <Button
                            icon="download"
                            size='huge'
                            onClick={handleExport}
                            loading={isExporting}
                            style={{ background: 'none', padding: 0, margin: 0 }}
                        />
                    }
                    content="Export"
                    size="tiny"
                    type="button"
                />
            </div>
        </div>
    )
}

export default CheckinFiltersAndAnnouncementButton
