import { AlexaStatus, AlexaStatusBody } from "../types/AlexaStatus";
import API from "./API";

export const upsertAlexaStatus = async (data: Partial<AlexaStatusBody>) => {
    try {
        const res = await API.lambdaPost("/alexa-status/upsert", data)
        return res
    } catch (error) {
        console.error("upsertAlexaStatus error", error)
        throw new Error(error instanceof Error ? error.message : "Failed to upsert alexa status")
    }
}

export const listAlexaStatus = async (a4hRoomId: string): Promise<AlexaStatus> => {
    try {
        const res = await API.lambdaPost("/alexa-status/list", { a4hRoomId })
        return res
    } catch (error) {
        console.error("listAlexaStatus error", error)
        throw new Error(error instanceof Error ? error.message : "Failed to list alexa status")
    }
}