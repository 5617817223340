import moment from "moment-timezone"
import React, { FC, useState } from "react";
import { Dimmer, Loader, Pagination, Table } from "semantic-ui-react";
import { Analyze } from "../../types/Analyze";
import { Asset } from "../../types/Asset";
interface TableProps {
    data: Analyze[]
    loading: boolean
    type: "menu" | "activity"
    facilityTimezone: string
    assetDict?: { [key: string]: Asset }
}

const AnalyzeTable: FC<TableProps> = ({ data, loading, type, facilityTimezone, assetDict }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = data && data.slice(indexOfFirstItem, indexOfLastItem);
   
    const formatTimeAndDate = (time: string, key: string) => {
        if (key === "Time") {
            return moment.tz(time, facilityTimezone).format("hh:mm A");
        } else {
            return moment.tz(time, facilityTimezone).format("MM/DD/YYYY");
        }
    }

    const currentDataHasLength = currentData && currentData.length
    return (
        <div style={{ height: '400px', overflow: 'auto', position: "relative" }}>
            <Dimmer inverted active={loading}>
                <Loader active={loading} />
            </Dimmer>
            <Table size='large'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center'>{type === "activity" ? "Activity" : "Menu"} Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Host</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Date</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Asset</Table.HeaderCell>
                        {type === "activity" ? <Table.HeaderCell textAlign='center'>Registered</Table.HeaderCell> : <></>}
                        <Table.HeaderCell textAlign='center'>Attendance status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentDataHasLength ? currentData.map((row, i) => (                        
                        <Table.Row key={i}>
                            <Table.Cell textAlign='center'>{row.eventName}</Table.Cell>
                            <Table.Cell textAlign='center'>{row.host}</Table.Cell>
                            <Table.Cell textAlign='center'>{formatTimeAndDate(row.eventTime, "Date")}</Table.Cell>
                            <Table.Cell textAlign='center'>{formatTimeAndDate(row.eventTime, "Time")}</Table.Cell>
                            <Table.Cell textAlign='center'>{assetDict && row.AssetId && assetDict[row.AssetId] ? assetDict[row.AssetId].AssetName : "-"}</Table.Cell>                          
                            {type === "activity" ? <Table.Cell textAlign='center'>{row.registered}</Table.Cell> : <></>}
                            <Table.Cell textAlign='center'>{row.attended}</Table.Cell>
                        </Table.Row>
                    )) : <></>}
                </Table.Body>
            </Table>
            {
                currentDataHasLength ? <div style={{ marginTop: '1rem', display: "flex", justifyContent: "center" }}>
                    <Pagination
                        totalPages={totalPages}
                        activePage={currentPage}
                        onPageChange={(e, { activePage }) => {
                            setCurrentPage(Number(activePage))
                        }}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        boundaryRange={0}
                        size='mini'
                    />
                </div> : <></>
            }

        </div>
    );
};

export default AnalyzeTable