import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react'
import { AuthState } from '../../types';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import CustomDatePicker, { DateRange } from '../CustomDatePicker';
import ReportsAttendanceDoughnutChart from '../Reports/ReportsAttendanceDoughnutCharts';
import DoubleLineGraph from '../Reports/DoubleLineGraph';
import { RRule } from 'rrule';
import { fetchAllActivities, listActivityAttendeesForMultipleActivityIds } from '../../services/DailyActivities';
import { listCards } from '../../services/CheckinCards';
import { fetchOneFacility } from '../../services/Facilities';

const ResidentsSummaryDataCharts = () => {
  const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
    return authReducer.profile;
  });

  const [dateRange, setDateRange] = useState<DateRange>({
    start: undefined,
    end: undefined
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [attendedData, setAttendedData] = useState<{ active: number, observed: number, inactive: number; }>({ active: 0, observed: 0, inactive: 0 });
  const [declinedData, setDeclinedData] = useState<{ notInterested: number, appointment: number, visitors: number, outOfCommunity: number, inBed: number, health: number; }>({ notInterested: 0, appointment: 0, visitors: 0, outOfCommunity: 0, inBed: 0, health: 0 });
  const [calendarCountEvents, setCalendarCountEvents] = useState<{ date: string, title: string; }[]>([]);
  const [calendarDetailedEvents, setCalendarDetailedEvents] = useState({});
  const [calendarCountEventsPrevious, setCalendarCountEventsPrevious] = useState<{ date: string, title: string; }[]>([]);
  const [calendarDetailedEventsPrevious, setCalendarDetailedEventsPrevious] = useState({});

  useEffect(() => {
    (async () => {
      try {
        if (profile && profile.Facility) {
          setIsLoading(true);
          const facilityId = profile.Facility;
          const facility = await fetchOneFacility(facilityId);
          if (!facility) throw new Error("No facility found");
          if (!facility.FacilityTimeZone) throw new Error("Missing facility timezone. Please contact support");
          const startDate = dateRange.start ? moment(dateRange.start || new Date()).format("YYYY-MM-DD") : moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
          const endDate = dateRange.end ? moment(dateRange.end).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
          if (isFirstRender) { // todo this will cause an extra re-render check if its causing huge performence issues
            setDateRange({
              start: new Date(startDate),
              end: new Date(endDate)
            })
            setIsFirstRender(false)
          }
          if (dateRange.start && dateRange.end) {
            await fetchData(facility.FacilityTimeZone);
            await getCheckinGraphData(facility.FacilityTimeZone);
          }
        }
      } catch (error) {
        toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [dateRange])

  const fetchData = async (FacilityTimezone: string) => {
    try {
      // Calculate the length of the original date range - number of days + 1
      const startDateString = moment(dateRange.start).format("YYYY-MM-DD");
      const endDateString = moment(dateRange.end).format("YYYY-MM-DD");
      const startDateTimestamp = moment.tz(startDateString, FacilityTimezone).startOf('day').valueOf();
      const endDateTimestamp = moment.tz(endDateString, FacilityTimezone).endOf('day').valueOf();
      const filter = {
        timestamp: {
          $gte: startDateTimestamp,
          $lte: endDateTimestamp
        }
      };

      const activities = await fetchAllActivities(filter);

      const activityDict = {};
      const activityIds = activities.map(activity => {
        activityDict[activity._id] = activity;
        return activity._id;
      });

      const attendanceData = await listActivityAttendeesForMultipleActivityIds(activityIds);
      let registeredCount = 0;
      let attendedCount = 0;
      let declinedCount = 0;
      let activeCount = 0;
      let observedCount = 0;
      let inactiveCount = 0;
      let notInterestedCount = 0;
      let appointmentCount = 0;
      let visitorsCount = 0;
      let outOfCommunityCount = 0;
      let inBedCount = 0;
      let healthCount = 0;
      const registeredActivities: any[] = [];
      const attendedActivities: any[] = [];
      const declinedActivities: any[] = [];

      attendanceData.forEach(attendee => {
        const { status } = attendee;
        if (status === "intend_attend") {
          registeredCount += 1;
          const activity = activityDict[attendee.activityId];
          registeredActivities.push({ ...attendee, activity });
        }
        if (status === "attended") {
          attendedCount += 1;
          const activity = activityDict[attendee.activityId];
          attendedActivities.push({ participationReason: 'active', ...attendee, activity });
          const participationReason = attendee.participation && attendee.participation || "active";
          if (participationReason === "active") {
            activeCount += 1;
          }
          if (participationReason === "passive") {
            observedCount += 1;
          }
          if (participationReason === "inactive") {
            inactiveCount += 1;
          }
        }
        if (status === "removed") {
          declinedCount += 1;
          const activity = activityDict[attendee.activityId];
          declinedActivities.push({ declineReason: "Not Interested", ...attendee, activity });
          const declineReason = attendee.declineReason && attendee.declineReason || "Not Interested";
          if (declineReason === "Not Interested") {
            notInterestedCount += 1;
          }
          if (declineReason === "Appointment") {
            appointmentCount += 1;
          }
          if (declineReason === "Visitors") {
            visitorsCount += 1;
          }
          if (declineReason === "Out of Community") {
            outOfCommunityCount += 1;
          }
          if (declineReason === "In Bed") {
            inBedCount += 1;
          }
          if (declineReason === "Health") {
            healthCount += 1;
          }
        }
      });
      setAttendedData({ active: activeCount, observed: observedCount, inactive: inactiveCount });
      setDeclinedData({ notInterested: notInterestedCount, appointment: appointmentCount, visitors: visitorsCount, outOfCommunity: outOfCommunityCount, inBed: inBedCount, health: healthCount });
    } catch (error) {
      console.log({ error });
      toast.warn("Error fetching data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

  }

  const getCheckinList = async (filters) => {
    const facilityId = profile && profile.Facility;
    const checkinList = await listCards(facilityId, filters);
    return {
      items: checkinList
    };
  }

  const getCheckinGraphData = async (FacilityTimezone: string) => {
    const startDateString = moment(dateRange.start && dateRange.start || moment(new Date()).subtract(7, 'days').toDate()).format('YYYY-MM-DD');
    const endDateString = moment(dateRange.end && dateRange.end || moment(startDateString).add(7, 'days').toDate()).format('YYYY-MM-DD');
    const currentStartTimestamp = moment.tz(startDateString, FacilityTimezone).startOf('day').valueOf();
    const currentEndTimestamp = moment.tz(endDateString, FacilityTimezone).endOf('day').valueOf();
    const bothPreviousAndCurrentEvents = async (dataFilter: string) => {
      // In this function we are getting data fr both current and previous matching timeframe using a dataFilter as current and previous
      const dateRangeDifference = currentEndTimestamp - currentStartTimestamp;
      const { items: checkinList } = await getCheckinList({
        startTimestamp: dataFilter === 'current' ? currentStartTimestamp : currentStartTimestamp - dateRangeDifference,
        endTimestamp: dataFilter === 'current' ? currentEndTimestamp : currentStartTimestamp - 1,
        resident: 'all',
      });

      const formattedCalendarInfo = {};
      const formmatedCheckinInfo = {};

      // Define the desired frequency (e.g., daily)
      const frequency = RRule.DAILY;
      // Create an RRule instance
      const rule = new RRule({
        freq: frequency,
        dtstart:
          dataFilter === 'current'
            ? new Date(startDateString)
            : new Date(new Date(startDateString).getTime() - (new Date(endDateString).getTime() - new Date(startDateString).getTime()) - 86400000),
        until: dataFilter === 'current' ? new Date(endDateString) : new Date(new Date(startDateString).getTime() - 86400000),
      });
      const generatedDates = rule.all();
      generatedDates.forEach((generatedDate) => {
        // since start end date range is set in the browser, we need not consider facility timezone here
        const generatedDateString =
          moment(new Date()).startOf('day').valueOf() >= moment(generatedDate).startOf('day').valueOf()
            ? moment(generatedDate).format('YYYY-MM-DD')
            : undefined;
        if (generatedDateString) formattedCalendarInfo[generatedDateString] = 0;
      });
      checkinList.forEach((checkInRecord) => {
        if (checkInRecord.column === 'checked_in') {
          const date = moment.tz(checkInRecord.timestamp, FacilityTimezone).format('YYYY-MM-DD');
          formattedCalendarInfo[date] = formattedCalendarInfo[date] ? formattedCalendarInfo[date] + 1 : 1;
          formmatedCheckinInfo[date]
            ? formmatedCheckinInfo[date].push(checkInRecord)
            : (formmatedCheckinInfo[date] = [checkInRecord]);
        }
      });
      const calendarEvents = Object.keys(formattedCalendarInfo).map((key) => {
        return {
          date: key,
          title: ''
        };
      });
      if (dataFilter === 'current') {
        setCalendarDetailedEvents(formmatedCheckinInfo);
        setCalendarCountEvents(calendarEvents);
      } else {
        setCalendarDetailedEventsPrevious(formmatedCheckinInfo);
        setCalendarCountEventsPrevious(calendarEvents);
      }
    };
    await bothPreviousAndCurrentEvents('current');
    await bothPreviousAndCurrentEvents('previous');
  };

  const formatDataForChart = () => {
    const chartData = calendarCountEvents.map((item) => {
      return {
        name: item.date,
        count: calendarDetailedEvents[item.date] ? calendarDetailedEvents[item.date].length : 0,
      };
    });
    return chartData;
  };

  const formatDataForChartPrevious = () => {
    const chartDataPrevious = calendarCountEventsPrevious.map((item) => {
      return {
        name: item.date,
        count: calendarDetailedEventsPrevious[item.date] ? calendarDetailedEventsPrevious[item.date].length : 0,
      };
    });
    return chartDataPrevious;
  };

  return (
    <div style={{ position: 'relative', marginTop: '20px' }}>
      <Dimmer active={isLoading} inverted>
        <Loader active={isLoading} />
      </Dimmer>
      <Grid columns={2} verticalAlign="top" >
        <Grid.Column width={12}>
          <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} />
          <Segment>
            <ReportsAttendanceDoughnutChart attendedData={attendedData} declinedData={declinedData} />
          </Segment>
        </Grid.Column>
        <Grid.Column width={12}>
          <DoubleLineGraph
            data={formatDataForChart()}
            previousData={formatDataForChartPrevious()}
            heading={'Check in trend period'}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default ResidentsSummaryDataCharts