import React, { useState, useMemo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input, Button, Dimmer, Loader, Dropdown, Modal } from 'semantic-ui-react';
import { useAdminInvites } from './useAdminInvites';
import { deleteRelative, deletePendingRelative } from '../../services/Analytics';
import SortableTable from "./SortedTable";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import moment from 'moment-timezone';

interface filters {
    toDate?: number;
    fromDate?: number;
    pageLimit: number;
    pageNo: number;
    contactQuery?: string;
    residentName?: string;
    inviteType?: string;
    facility?: string
}

const Invites = ({ startDate, endDate } : { startDate: Date, endDate: Date }) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    const facilityTZ = profile && profile.FacilityTimeZone;
    const defaultFilters = {
        pageLimit: 40,
        pageNo: 1
    };

    const [filters, setFilters] = useState<filters>(defaultFilters);
    const [nameQuery, setNameQuery] = useState('');
    const [residentNameQuery, setResidentNameQuery] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [dateFilters, setDateFilters] = useState<any>({});
    const [sortByStr, setSortByStr] = useState<any>([]);
    const [columnSortData, setColumnSortData] = useState({
        facilityName: {
            isSorted: false,
            isSortedDesc: false,
        },
        residentName: {
            isSorted: false,
            isSortedDesc: false,
        },
        relativeName: {
            isSorted: false,
            isSortedDesc: false,
        },
        messageCount: {
            isSorted: false,
            isSortedDesc: false,
        },
        RelativeId: {
            isSorted: false,
            isSortedDesc: false,
        },
        Contact: {
            isSorted: false,
            isSortedDesc: false,
        },
        Relationship: {
            isSorted: false,
            isSortedDesc: false,
        },
        pending: {
            isSorted: false,
            isSortedDesc: false,
        },
        DateAdded: {
            isSorted: true,
            isSortedDesc: true,
        },
        ResendCount: {
            isSorted: false,
            isSortedDesc: false,
        },
        LastInviteSent: {
            isSorted: false,
            isSortedDesc: false,
        },
    });

    const [deleteRelativeId, setDeleteRelativeId] = useState('');
    const [deletingRelative, setDeletingRelative] = useState<boolean>(false);
    const [deletionCandidateRelative, setDeletionCandidateRelative] = useState<any>('');
    const [deleteRelativeContact, setDeleteRelativeContact] = useState<string>('');

    useEffect(() => {
        if (startDate && endDate) {
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const startTimestamp = moment.tz(formattedStartDate, 'YYYY-MM-DD', facilityTZ).startOf('day').valueOf();
            const endTimestamp = moment.tz(formattedEndDate, 'YYYY-MM-DD', facilityTZ).endOf('day').valueOf();
            setFilters({ ...filters, fromDate: startTimestamp, toDate: endTimestamp });
        }
    }, [startDate, endDate]);

    const handleDeleteRelative = async () => {
        try {
            setDeletingRelative(true);
            if (deleteRelativeId) {
                await deleteRelative(deleteRelativeId);
            }
            else {
                await deletePendingRelative(deleteRelativeContact);
            }
            setDeleteRelativeId('');
            setDeletingRelative(false);
            setDeletionCandidateRelative('');
            setDeleteRelativeContact('');
            handleResidentNameQueryChange(); //to refresh
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to delete data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    };

    const { invites, itemCount, isLoading, pendingItemCount } = useAdminInvites(filters, sortByStr, setErrorInfo);

    const nextPage = () => {
        filters.pageNo * filters.pageLimit < itemCount && setFilters({ ...filters, pageNo: filters.pageNo + 1 });
    };
    const previousPage = () => {
        filters.pageNo > 1 && setFilters({ ...filters, pageNo: filters.pageNo - 1 });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Facility',
                accessor: 'facilityName',
            },
            {
                Header: 'Resident Name',
                accessor: 'residentName',
            },
            {
                Header: 'Username',
                accessor: 'relativeName',
            },
            {
                Header: 'Message Count',
                accessor: 'messageCount',
            },
            {
                Header: 'Contact',
                accessor: 'Contact',
            },
            {
                Header: 'Relationship',
                accessor: 'managerType',
            },
            {
                Header: 'Accepted',
                accessor: 'pending',
            },
            {
                Header: 'Invite Sent',
                accessor: 'DateAdded',
            },

            {
                Header: 'Last Invite Sent',
                accessor: 'LastInviteSent',
            },
            {
                Header: 'Resend Count',
                accessor: 'ResendCount',
            },
            {
                Header: 'Delete relative',
                accessor: 'RelativeId',
                Cell: ({ row, value }) => {
                    return (
                        <>
                            {row.values.Contact || (row.values.RelativeId && row.values.relativeName && row.values.relativeName !== 'null null') ? (
                                <Button
                                    onClick={() => {
                                        console.log("the delete is getting called")
                                        if (filters.inviteType === 'pending') {
                                            setDeleteRelativeContact(row.values.Contact);
                                        } else {
                                            if (row.values.relativeName && row.values.RelativeId) {
                                                setDeletionCandidateRelative(row.values.relativeName)
                                                setDeleteRelativeId(row.values.RelativeId);
                                            } else {
                                                toast.warn("Can't delete this invite.", {
                                                    position: 'bottom-center',
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                })
                                            }
                                        }
                                    }}
                                    size="mini"
                                    color="red"
                                >
                                    Delete
                                </Button>
                            ) : (
                                ''
                            )}
                        </>
                    );
                },
            },
        ],
        [filters],
    );

    const data = useMemo(() => invites, [invites]);

    const handleFilterChange = (filters) => {
        setFilters(filters);
    };

    const handleNameQueryChange = () => {
        if (!nameQuery) {
            const { contactQuery, ...otherOptionsOfFilter } = filters;
            handleFilterChange(otherOptionsOfFilter);
        } else {
            handleFilterChange({ ...filters, contactQuery: nameQuery });
        }
    };

    const handleResidentNameQueryChange = () => {
        if (!residentNameQuery) {
            const { residentName, ...otherOptionsOfFilter } = filters;
            handleFilterChange(otherOptionsOfFilter);
        } else {
            handleFilterChange({ ...filters, residentName: residentNameQuery });
        }
    };

    const processSortBy = (columnSortData) => {
        const sortStringArray: Array<string> = [];

        for (const property in columnSortData) {
            if (columnSortData[property].isSorted) {
                sortStringArray.push(`${property}:${columnSortData[property].isSortedDesc ? 'desc' : 'asc'}`);
            }
        }

        const sortString = sortStringArray.join(',');
        setSortByStr(sortString);
    };

    const setManualSortBy = (columnId) => {
        if (!(columnId === 'pending' || columnId === 'facilityName' || columnId === 'relativeName' || columnId === 'ResendCount')) {
            const newColumnSortData = { ...columnSortData };
            if (columnSortData[columnId].isSorted) {
                newColumnSortData[columnId] = {
                    ...columnSortData[columnId],
                    isSorted: !columnSortData[columnId].isSortedDesc,
                    isSortedDesc: !columnSortData[columnId].isSortedDesc,
                };
            } else {
                newColumnSortData[columnId].isSorted = true;
            }
            setColumnSortData(newColumnSortData);
            processSortBy(newColumnSortData);
            setFilters({ ...filters, pageNo: 1 });
        }
    };

    return (
        <div className="messages-page">
            <Modal open={!!(deleteRelativeId || deleteRelativeContact)} onClose={() => { setDeleteRelativeId(''); setDeleteRelativeContact('') }} size="tiny" closeIcon>
                <Modal.Content>
                    <p>
                        Are you sure you want to delete relative?
                        <br />
                    </p>
                    <b>
                        <h3>
                            {deletionCandidateRelative
                                ? deletionCandidateRelative
                                : deleteRelativeContact
                                    ? deleteRelativeContact
                                    : ''
                            }
                        </h3>
                    </b>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleDeleteRelative} color="red" loading={deletingRelative}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
            <div className="filters">
                <div>
                    <div className="caption">Invite Count</div>
                    <div className="message-count">{itemCount}</div>
                </div>
                <div>
                    <div className="caption">Pending Invites</div>
                    <div className="message-count">{pendingItemCount}</div>
                </div>
                <div>
                    <div className="caption">Invites Accepted</div>
                    <div className="message-count">{
                        `${(isNaN(((itemCount - pendingItemCount) / itemCount) * 100) ?
                            0 :
                            (((itemCount - pendingItemCount) / itemCount) * 100).toFixed(2))
                        }%`
                    }</div>
                </div>
                <div>
                    <div className="caption" style={{ display: "flex" }}>Resident Name</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                            value={residentNameQuery}
                            onChange={(event, data) => {
                                setResidentNameQuery(data.value);
                            }}
                        />
                        <Button className="tiny primary ml-4" style={{ height: '35px' }} onClick={handleResidentNameQueryChange}>
                            Search
                        </Button>
                    </div>
                </div>
            </div>

            {errorInfo && <div className="error-info">{errorInfo}</div>}

            <div className="table-container">
                <Dimmer active={isLoading} inverted>
                    <Loader active={isLoading}></Loader>
                </Dimmer>

                <SortableTable
                    columns={columns}
                    data={data}
                    nextPage={{ nextPage, disableNext: !(filters.pageNo * filters.pageLimit < itemCount) }}
                    previousPage={{ previousPage, disablePrev: filters.pageNo === 1 }}
                    pageNumber={filters.pageNo}
                    loading={isLoading}
                    setManualSortBy={setManualSortBy}
                    columnSortData={columnSortData}
                    height={"40vh"}
                />
            </div>
        </div>
    );
};

export default Invites;
