import { ReqState } from "../../../types/Service";

export type ReqAction = {
    type: 'HANDLE-INPUT-CHANGE';
    payload: {
        key: string,
        value: string | boolean | number,
    }
} | {
    type: 'RESET_STATE';
} | {
    type: 'SET_DEFAULTS';
    payload: ReqState;
  };

export const reqInitialState: ReqState = {
    internalCost: '',
    internalCostRequirement: "",
    externalCost: '',
    pointsAttainment: '',
    premiumCategory: '',
    expertRequirement: '',
    assistantRequirement: '',
    displayInSignage: false,
    waitingList: false,
    assistance: "",
    tags: "",
    assistant: "",
    expert: "",
    externalCostRequirement: "",
    shareToCalendars: false,
    autoAnnouncement: false,
    autoNotification: false,
    minutesBeforeAnnouncement: 30,
    alertTarget:"registered",
};

export const reqReducer = (state: ReqState, action: ReqAction) => {
    switch (action.type) {
        case 'HANDLE-INPUT-CHANGE':
            return { ...state, [action.payload.key]: action.payload.value };
        case "RESET_STATE" : 
            return reqInitialState;
        case 'SET_DEFAULTS':
                return { ...reqInitialState, ...action.payload };    
        default:
            return state;
    }
};
