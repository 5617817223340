import API from './API';

export async function fetchCorporates(): Promise<any[]> {
	const res = await API.lambdaGet('/corporates/list');
	return res as any[];
}

export async function createCorporate(data: any): Promise<string> {
	const res = await API.lambdaPost('/corporates/add', data);
	return res;
}

export async function updateCorporate(data: any): Promise<string> {
	const res = await API.lambdaPost('/corporates/update', data);
	return res;
}

export async function deleteCorporate(id: string): Promise<string> {
	const res = await API.lambdaPost('/corporates/delete', { _id: id });
	return res;
}