import { RouteComponentProps, withRouter } from "react-router-dom";
import ServiceTypesForm from "../../../../components/ServiceTypesForm";
import React, { useEffect, useState } from "react";
import { Service } from "../../../../types/Service";
import { fetchOneServiceType } from "../../../../services/service";
import { useSelector } from "react-redux";
import { AuthState } from "../../../../types";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { cookbook } from "../../../../util";

interface ServicesProps extends RouteComponentProps {
    source?: string;
}

const UpdateServiceForm: React.FC<ServicesProps> = ({ history, source }) => {
    const [selectedService, setSelectedService] = useState<Service>();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    useEffect(() => {
        // When the URL changes, check if it contains an ID
        const id = history.location.pathname.split('/').pop(); // Get the ID from the URL
        if (id) {
            fetchOneServiceTypeDetails(id);
        }
    }, [history.location.pathname]);

    const fetchOneServiceTypeDetails = async (id: string) => {
        try {
            setIsFetching(true);
            const responseData = await fetchOneServiceType(id, (profile && profile.Facility) || '');
            // Combine data from different service types into a single object
            const combinedData = {
                ...responseData.Service,
                Dietary: responseData.Dietary,
                Wellness: responseData.Wellness,
            };
            // Update the state with the combined object
            setSelectedService(combinedData);
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            console.error('error in fetching serviceTypes details', error);
        }
    };

    return (
        <div>
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching} size="small">
                    Loading
                </Loader>
            </Dimmer>
            <Button
                size="tiny"
                basic
                onClick={() => history.push(source === cookbook ? '/admin/cookbook' : '/admin/services/list')}>
                {source === cookbook ? 'Back to Cook Book' : 'Back to Services'}
            </Button>
            {selectedService ? <ServiceTypesForm service={selectedService} source={source} /> : null}
        </div>
    );
};

export default withRouter(UpdateServiceForm);