import API from "./API";

export const getUnitIdsFromResidentIds = async (residentIds: string[]) => {
    try {
        const filter = {
            residentIds
        };
        const res = await API.lambdaPost('/registrants/unit-registrant/list', filter); // this API has a pagination wrapper in the backend
        return res;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
