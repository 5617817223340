import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import ReportsLiveRequests from '../../../components/ReportsLiveRequests';
import CustomDatePicker, { DateRange } from '../../../components/CustomDatePicker';
import CommunityMetrics from '../../../components/CommunityMetrics';
import { ReportSummaryFilters } from '../../../types';
import './style.less';
import { withRouter } from 'react-router-dom';
import ReportsResidentInteractions from '../../../components/ReportsResidentInteractions';
import ReportsMessage from '../../../components/ReportsMessage';
import ReportsCheckIn from '../../../components/ReportsCheckIn';
import moment from 'moment-timezone';
import ReportsAttendance from '../../../components/ReportsAttendance';
import ReportContentRequests from '../../../components/ReportContentRequest';
import ReportsDeviceStatusMonitor from '../../../components/ReportsDeviceStatusMonitor';
import { isFacilityTabEnabled } from '../../../services/Permissions';

const Reports = (props) => {
    const facilityTimeZone = props.profile && props.profile.FacilityTimeZone;
    // control date range for all reports
    const [reportsDateRange, setReportsDateRange] = useState<DateRange>({
        start: moment().subtract(6, 'days').startOf('day').toDate(), // default to last 7 days
        end: moment().endOf('day').toDate(),
    });
    const [pageNo, setPageNo] = useState(1);
    const [selectedService, setSelectedService] = useState('live-requests');
    const [requestTabEnabled, setRequestTabEnabled] = useState<boolean>(false);
    const [assignableRequestFilters, setAssignableRequestFilters] = useState<ReportSummaryFilters>({
        requestType: 'all',
        fromDate: new Date(),
        toDate: new Date(),
        disable: false,
        staff: 'all',
        resident: 'all',
        department: 'all',
        reload: 0,
        FetchCancelRequests: true,
    });

    useEffect(() => {
        const isRequestTabEnabled = isFacilityTabEnabled(props.profile, 'requests');
        if (!isRequestTabEnabled) {
            setSelectedService('attendance');
        }
        setRequestTabEnabled(isRequestTabEnabled);
    }, []);

    const handleFilterChangeAssignable = (updatedFilters) => {
        setAssignableRequestFilters(updatedFilters);
        setPageNo(1);
    };
    const handleFilterChange = (type, payload) => {
        switch (type) {
            case 'assignable':
                handleFilterChangeAssignable({ ...payload, disable: false });
                break;

            default:
                handleFilterChangeAssignable({
                    ...assignableRequestFilters,
                    disable: false,
                    staff: 'all',
                    department: 'all',
                    resident: 'all',
                });
        }
    };
    const liveRequestsDateChangeHandler = (dateRange: DateRange) => {
        if (dateRange.start && !dateRange.end) { // if only start date is selected then set the end date to undefined 
            setReportsDateRange({ start: dateRange.start, end: undefined });
        }
        if (dateRange.start && dateRange.end) {
            setReportsDateRange(dateRange);
            handleFilterChange(
                'assignable',
                { ...assignableRequestFilters, fromDate: dateRange.start, toDate: dateRange.end }
            );
        }
    };

    const handleDynamicClassName = (tabName: string) => {
        return selectedService === tabName ? 'boldClickableArea' : 'clickableArea';
    };

    const handleClearFilters = () => {
        setReportsDateRange({
            start: moment().subtract(6, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate(),
        });
        handleFilterChange('assignable',
            { ...assignableRequestFilters, fromDate: moment().subtract(6, 'days').startOf('day').toDate(), toDate: moment().endOf('day').toDate() },
        );
    }

    return (
        <>
            <div className="parentWrapper">
                {requestTabEnabled && (
                    <div
                        className={handleDynamicClassName('live-requests')}
                        onClick={() => setSelectedService('live-requests')}
                    >
                        <p>Requests</p>
                    </div>
                )}
                <div className={handleDynamicClassName('attendance')} onClick={() => setSelectedService('attendance')}>
                    <p>Attendance</p>
                </div>
                <div className={handleDynamicClassName('check-in')} onClick={() => setSelectedService('check-in')}>
                    <p>Checkin</p>
                </div>
                <div
                    className={handleDynamicClassName('content-requests')}
                    onClick={() => setSelectedService('content-requests')}
                >
                    <p>Content</p>
                </div>
                <div className={handleDynamicClassName('messaging')} onClick={() => setSelectedService('messaging')}>
                    <p>Messaging</p>
                </div>
                <div
                    className={handleDynamicClassName('community-metrics')}
                    onClick={() => setSelectedService('community-metrics')}
                >
                    <p>Communication</p>
                </div>
                <div
                    className={handleDynamicClassName('device-monitor')}
                    onClick={() => setSelectedService('device-monitor')}
                >
                    <p>Devices</p>
                </div>
                <div
                    className={handleDynamicClassName('resident-interactions')}
                    onClick={() => setSelectedService('resident-interactions')}
                >
                    <p>Resident Interactions</p>
                </div>
                <div className='filtersWrapper'>
                    <CustomDatePicker
                        dateRange={reportsDateRange}
                        setDateRange={liveRequestsDateChangeHandler}
                        useNewStyles={true}
                        skipMaxDate={true}
                        showDatePickerWithTime={selectedService === 'check-in' ? true : false} // show time picker along with date for checkin page
                    />
                    <Button onClick={handleClearFilters} size='mini'>
                        Clear Filters
                    </Button>
                </div>
            </div>
            <hr style={{ color: '#DEDEDF', margin: '15px 0', marginRight: '410px' }} />
            <main>
                {selectedService === 'live-requests' && requestTabEnabled ? (
                    <ReportsLiveRequests
                        profile={props.profile}
                        assignableRequestFilters={assignableRequestFilters}
                        setAssignableRequestFilters={setAssignableRequestFilters}
                        facilityTimeZone={facilityTimeZone}
                        history={props.history}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                    />
                ) : (
                    <></>
                )}
                {selectedService === 'attendance' ? (
                    // todo check || new Date is really a good idea
                    <ReportsAttendance
                        startDate={reportsDateRange.start}
                        endDate={reportsDateRange.end}
                        facilityTimeZone={facilityTimeZone}
                    />
                ) : (
                    <></>
                )}
                {selectedService === 'check-in' ? (
                    <>
                        <ReportsCheckIn
                            startDate={reportsDateRange.start || moment.tz().startOf('day').toDate()} // if we don't specify timezone it takes default timezone
                            endDate={reportsDateRange.end}
                            facilityTimeZone={facilityTimeZone}
                        />
                    </>
                ) : (
                    <></>
                )}
                {selectedService === 'content-requests' ? (
                    <div style={{ width: '99%', textAlign: 'center', marginLeft: '13px' }}>
                        <ReportContentRequests
                            startDate={reportsDateRange.start}
                            endDate={reportsDateRange.end}
                            facilityTimeZone={facilityTimeZone}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {selectedService === 'messaging' ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <ReportsMessage startDate={reportsDateRange.start} endDate={reportsDateRange.end} />
                    </div>
                ) : (
                    <></>
                )}
                {selectedService === 'community-metrics' ? (
                    <CommunityMetrics
                        startDate={reportsDateRange.start || new Date()}
                        endDate={reportsDateRange.end || new Date()}
                        facilityTimeZone={facilityTimeZone}
                    />
                ) : (
                    <></>
                )}
                {selectedService === 'resident-interactions' ? (
                    <ReportsResidentInteractions startDate={reportsDateRange.start} endDate={reportsDateRange.end} />
                ) : (
                    <></>
                )}

                {selectedService === 'device-monitor' ? (
                    <ReportsDeviceStatusMonitor
                        startDate={reportsDateRange.start}
                        endDate={reportsDateRange.end}
                        facilityTimeZone={facilityTimeZone}
                    />
                ) : (
                    <></>
                )}
            </main>
        </>
    );
};

export default withRouter(Reports);
