import React, { FC, useEffect, useState } from 'react';
import { Card, Container, Header, Loader } from 'semantic-ui-react'; // Import the Loader
import { getBillingInfo } from '../../../services/WebRTC';
import { TURNBillingFacilityInfo, UserDetails } from '../../../types/WebRTC';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BillingDetailModal from './BillingDetailModal';
interface TURNBillingProps extends RouteComponentProps { }

const TURNBilling: FC<TURNBillingProps> = () => {
    const [billingInfo, setBillingInfo] = useState<TURNBillingFacilityInfo[] | null>(null);
    const [loading, setLoading] = useState(true); // State to track loading
    const [selectedFacilityUsers, setSelectedFacilityUsers] = useState<UserDetails[] | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const res = await getBillingInfo();
                setBillingInfo(res);
            } catch (error) {
                console.error("Failed to fetch billing info", error);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        })();
    }, []);

    const handleCardClick = (users: UserDetails[]) => {
        setSelectedFacilityUsers(users);
    };

    const handleCloseModal = () => {
        setSelectedFacilityUsers(null);
    };

    return (
        <Container>
            <Header as="h2">Facility Billing Information</Header>
            {loading ? ( // If loading, show the loader
                <Loader active inline="centered" content="Loading billing information..." />
            ) : (
                <Card.Group itemsPerRow={4}>
                    {billingInfo ? billingInfo.map(info => (
                        <Card key={info.facilityInfo._id} onClick={() => handleCardClick(info.userDetails)} style={{cursor: "pointer"}}>
                            <Card.Content>
                                <Card.Header>{info.facilityInfo.Name}</Card.Header>
                                <Card.Description>
                                    Total Facility Amount: ${info.totalFacilityAmount}
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    )) : <></>}
                </Card.Group>
            )}
            {selectedFacilityUsers ? (
                <BillingDetailModal userDetails={selectedFacilityUsers} open={Boolean(selectedFacilityUsers)} onClose={handleCloseModal} />
            ) : null}
        </Container>
    );
}

export default withRouter(TURNBilling);
