import React, { FC } from 'react';

interface Props {
    name: string;
    size?: string;
}

const AnnouncementIcons: FC<Props> = ({ name, size = "small" }) => {
    if (name === "Alexa") {
        return ( 
            <svg width="auto" height="45" viewBox="0 0 43 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.2692 2.42507C42.289 2.0595 42.1334 1.62814 41.7341 1.39264L39.8024 0.467143C39.7957 0.463929 39.7882 0.464143 39.7811 0.461357C39.556 0.337071 39.2883 0.262286 38.9962 0.2595L8.95334 0C8.0745 0 7.21005 0.566786 6.98534 1.29236L0.0544976 24.4234C-0.0597884 24.7916 0.00702166 25.1638 0.242253 25.4709C0.4777 25.7786 0.851277 25.9802 1.29274 26.0387L30.9446 29.9835C31.0301 29.9949 31.1152 30 31.1996 30C31.2176 30 31.2344 29.9964 31.2524 29.9961C31.2576 29.9964 31.2619 29.9989 31.267 29.9989L34.0082 30C34.0237 30 34.0391 29.9994 34.0544 29.9972C34.281 29.9702 34.5014 29.8753 34.6862 29.7431C34.6909 29.7399 34.6971 29.7384 34.7021 29.7351L34.7328 29.7118C34.7354 29.7096 34.7386 29.7073 34.7405 29.7058L37.3227 27.7159C38.4873 26.7403 39.3665 24.8912 39.5435 23.6638L42.2731 2.45571C42.2739 2.44479 42.2694 2.43514 42.2694 2.42507H42.2692ZM0.794779 25.1619C0.663522 24.9906 0.627432 24.7796 0.693812 24.5657L7.62423 1.43486C7.77074 0.963214 8.37933 0.564643 8.96408 0.564643L38.9899 0.823929C39.2402 0.8265 39.4507 0.905786 39.5813 1.0485C39.7122 1.19014 39.7536 1.38257 39.6978 1.58936L32.2789 28.7126C32.156 29.1669 31.5955 29.4999 31.0456 29.4253L1.39392 25.4805C1.13914 25.4469 0.926681 25.3339 0.794779 25.1619ZM33.9824 29.4356L32.574 29.4345C32.7375 29.2637 32.8621 29.0638 32.9227 28.8407L40.3412 1.71686C40.3719 1.60521 40.3798 1.49636 40.3777 1.38879L41.3844 1.87029C41.5779 1.98514 41.6514 2.29779 41.5968 2.505L34.4872 29.0267C34.4643 29.1152 34.3953 29.2054 34.3064 29.2789L34.2857 29.2952C34.1951 29.3646 34.087 29.4182 33.9826 29.4351L33.9824 29.4356ZM38.8873 23.5976C38.7279 24.7007 37.9074 26.4347 36.8703 27.3041L35.3029 28.5114L40.9807 7.33093L38.8875 23.5978L38.8873 23.5976Z"  />
                <path d="M37.0718 4.34722C37.0118 4.27908 36.9193 4.23772 36.8196 4.23472L8.64958 3.38636C8.49963 3.38122 8.35613 3.46994 8.3181 3.59851L2.35912 23.5476C2.33549 23.625 2.35224 23.7077 2.40488 23.7748C2.45751 23.8419 2.54043 23.8858 2.63345 23.8969L30.1882 27.174C30.2037 27.1759 30.2189 27.177 30.234 27.177C30.3833 27.177 30.5164 27.0902 30.5538 26.9631L37.1285 4.58572C37.1523 4.50301 37.1317 4.41536 37.0718 4.34722ZM29.9856 26.5798L3.09038 23.3814L8.89168 3.95872L36.388 4.78672L29.9856 26.58V26.5798Z"  />
                <path d="M22.4643 2.28364C22.4259 2.51507 22.2121 2.68114 21.9864 2.65393C21.7606 2.62693 21.6083 2.41714 21.6465 2.18571C21.6845 1.95429 21.8987 1.78821 22.1245 1.81564C22.3503 1.84264 22.5026 2.05243 22.4643 2.28386V2.28364Z"  />
                <path d="M23.8869 2.29436C23.8617 2.44908 23.7126 2.55879 23.5548 2.53972C23.3966 2.52065 23.2892 2.38008 23.3148 2.22558C23.3404 2.07129 23.489 1.96136 23.6469 1.98022C23.805 1.99929 23.9127 2.14008 23.8869 2.29436Z"  />
                <path d="M20.7322 2.24056C20.7067 2.39485 20.5585 2.50456 20.4003 2.48549C20.2427 2.46642 20.1353 2.32628 20.1604 2.17178C20.186 2.01706 20.3346 1.90735 20.4923 1.92642C20.6504 1.94549 20.7578 2.08564 20.7322 2.24035V2.24056Z"  />
                <path d="M17.8769 2.18807C17.8518 2.34236 17.7032 2.45207 17.5455 2.433C17.3874 2.41436 17.28 2.27379 17.3055 2.11929C17.3307 1.965 17.4793 1.85486 17.6374 1.87393C17.7951 1.893 17.9025 2.03357 17.8772 2.18786L17.8769 2.18807Z"  />
                <path d="M26.8561 2.35306C26.831 2.50735 26.6824 2.61706 26.524 2.59842C26.3659 2.57935 26.2583 2.43878 26.2834 2.28449C26.3086 2.1302 26.4577 2.02049 26.6155 2.03935C26.7739 2.05799 26.8817 2.19856 26.8561 2.35306Z"  />
            </svg>
        );
    } else if (name === "Text") {
        return (
            <i style={{ fontSize: size === "small" ? '18px' : '45px' }} className="fa-thin fa-comment-sms"></i>
        );
    } else if (name === "Email") {
        return (
            <i style={{ fontSize: size === "small" ? '18px' : '45px' }} className="fa-thin fa-envelope"></i>
        );
    } else if (name === "Staff") {
        return (
            <i style={{ fontSize: size === "small" ? '18px' : '45px' }} className="fa-thin fa-users-medical"></i>
        );
    } else
        return null; // or some other fallback JSX if needed
};

export default AnnouncementIcons;
