import React, { useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { Registrant, BasicReportSummaryFilters, UserProfile } from '../../types';
import { sortByKey } from '../../util/sortData';

interface Props {
    filters: any[];
    handleFilterChangeBasic: (newFilters: BasicReportSummaryFilters) => void;
    registrants: Registrant[];
}

const ContentRequestFilters: React.FC<Props> = React.memo(({ filters, handleFilterChangeBasic, registrants }) => {
    const [basicRequestFilters] = filters;
    const [resident, setResident] = useState('all');
    const requestTypeOptions = [
        { value: 'all', text: 'All' },
        { value: 'Affirmations', text: 'Community News' },
        { value: 'Wellness Check', text: 'Wellness Check' },
        { value: 'Birthdays', text: 'Birthday' },
        { value: 'Calendar', text: 'Calendar' },
        { value: 'Check in', text: 'Check in' },
        { value: 'Attendance', text: 'Attendance' },
        { value: 'Messages', text: 'Messages' },   
        { value: 'Mail', text: 'Mail is in' },
        { value: 'Menu', text: 'Menu' },
        { value: 'Question of the day', text: 'Question of the day' },
        { value: 'ResidentOfTheMonth', text: 'Resident Spotlight' },
        { value: 'Speak2 support', text: 'Speak2 support' },
        { value: 'Notepad', text: 'Notepad' },
        { value: 'StaffOfTheMonth', text: 'Staff Spotlight' },
    ];

    const handleFilterChange = (type, payload) => {
        switch (type) {
            case 'basic':
                handleFilterChangeBasic({ ...payload, disable: false });
                break;
            case 'resident':
                if (basicRequestFilters.disable === false) {
                    handleFilterChangeBasic({ ...basicRequestFilters, ...payload });
                }
                break;
            default:
                setResident('all');
                handleFilterChangeBasic({
                    ...basicRequestFilters,
                    disable: false,
                    type: 'all',
                    resident: 'all',
                    food: 'all',
                });
        }
    };

    return (
        <div>
            <div style={{ marginLeft: 'auto' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginLeft: '0px',
                    }}
                >
                    <div>
                        <p style={{ fontSize: '12px', marginBottom: '0px', textAlign: 'start' }}>Request Type</p>
                        <Dropdown
                            style={{ width: '300px', minWidth: '100px', marginRight: '5px' }}
                            placeholder="Request Type"
                            value={basicRequestFilters.type}
                            selection
                            scrolling
                            search
                            closeOnEscape
                            options={requestTypeOptions}
                            onChange={(event, data) => {
                                if (data.value === 'Menu') {
                                    handleFilterChange('basic', { ...basicRequestFilters, type: data.value });
                                } else {
                                    handleFilterChange('basic', {
                                        ...basicRequestFilters,
                                        food: 'all',
                                        type: data.value,
                                    });
                                }
                            }}
                        />
                    </div>
                    <div>
                        <p style={{ fontSize: '12px', marginBottom: '0px', textAlign: 'start' }}>Residents</p>
                        <Dropdown
                            style={{ width: '300px', minWidth: '100px', marginRight: '5px' }}
                            placeholder="Residents"
                            value={resident}
                            selection
                            scrolling
                            search
                            options={[
                                { value: 'all', text: 'All' },
                                ...sortByKey(registrants.map((item) => {
                                    return { value: item._id, text: `${item.FirstName} ${item.LastName}` };
                                })),
                            ]}
                            onChange={(event, data) => {
                                if (typeof data.value === 'string') {
                                    setResident(data.value);
                                    handleFilterChange('resident', { resident: data.value });
                                }
                            }}
                        />
                    </div>
                    <Button
                        style={{ width: '120px', marginTop: '15px' }}
                        onClick={() => {
                            handleFilterChange('default', {});
                        }}
                    >
                        Clear Filters
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default ContentRequestFilters;
