import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, List, Image, Modal } from 'semantic-ui-react'
import { RequestType } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchRequestTypes } from '../../../../services/RequestTypes'
import CreateRequestType from "../Create"

const imagePlaceholder = `${process.env.PUBLIC_URL}/request_type_placeholder.png`

interface State {
    requestTypes: RequestType[]
    error: string | null
    isFetching: boolean
    page: number
    count: number
    limit: number
    showModal: boolean
}

class RequestTypesList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            requestTypes: [],
            error: null,
            isFetching: false,
            page: 0,
            count: 0,
            limit: 10000,
            showModal: false
        }
    }
    async loadData() {
        this.setState({
            isFetching: true,
            error: null,
        })

        try {
            const requestTypes = await fetchRequestTypes()
            requestTypes.sort((a, b) => a.Name.localeCompare(b.Name));
            this.setState({
                isFetching: false,
                requestTypes,
                error: null,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Departments.'
            this.setState({
                isFetching: false,
                error: msg,
            })
        }
    }
    async componentDidMount() {
        await this.loadData()
    }
    toggleModalHandler = () => {
        this.setState(prev => ({
            showModal: !prev.showModal
        }))
    }

    navigateToCreate() {
        return this.props.history.push('/admin/forms/requesttypes/create')
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/forms/requesttype/${id}`)
    }

    get data() {
        return this.state.requestTypes.map(r => (
            <List.Item key={r._id} onClick={() => this.goToEdit(r._id)}>
                <Image src={r.Icon || imagePlaceholder} avatar />
                <List.Content>
                    <List.Header as="a">{r.Name}</List.Header>
                    <List.Description as="a">Category: {r.Category}</List.Description>
                </List.Content>
            </List.Item>
        ))
    }

    render() {
        return (
            <div className="RequestTypesList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.toggleModalHandler.bind(this)}
                />
                <List divided relaxed>
                    {this.data}
                </List>
                <Modal open={this.state.showModal} closeIcon closeOnEscape onClose={this.toggleModalHandler}>
                    <CreateRequestType onSubmitClick={() => {
                        this.loadData()
                        this.toggleModalHandler()
                    }} onCancelClick={() => {
                        this.toggleModalHandler()
                    }} />
                </Modal>
            </div>
        )
    }
}

export default withRouter(RequestTypesList)
