import API from "./API"
import { QuestionOfTheDay } from '../types/questionOfTheDay.d';
import { fetchAllPaginatedData } from "../util";

export async function listAllResponsesForQuestionsOfTheDayForOneResident(registrantId: string): Promise<any> {
    const params = {
        registrantId
    }
    const response = await API.lambdaPost('/question-of-the-day/resident-responses', params)
    return response
}

export async function listFacilityQuestionsOfTheDay(searchTerm: string = ''): Promise<any> {
    const params = {
        searchTerm
    }
    const response = await API.lambdaPost('/question-of-the-day/facility-questions/list', params)
    return response
}

export async function createFacilityQuestionOfTheDay(question: Partial<QuestionOfTheDay>): Promise<any> {
    const response = await API.lambdaPost('/question-of-the-day/facility-questions/add', question)
    return response
}

export async function updateFacilityQuestionOfTheDay(existingQuestion: Partial<QuestionOfTheDay>, question: string): Promise<any> {
    if (question && question !== ""){
        const payload = {
            question,
            facilityId: existingQuestion.facilityId,
            _id: existingQuestion._id
        }
        const response = await API.lambdaPost('/question-of-the-day/facility-questions/update', payload);
        return response
    } else {
        return null
    }
}

export async function deleteFacilityQuestionOfTheDay(_id: string): Promise<any> {
    const response = await API.lambdaPost('/question-of-the-day/facility-questions/delete', {_id})
    return response
}

export async function listAllPaginatedResponsesForQuestionsOfTheDayForMultipleResidents(residentIds: string[], startTimeStamp: number, endTimeStamp: number): Promise<any> {
    const params = {
        residentIds,
        startTimeStamp,
        endTimeStamp
    };
    const apiCall = async (params: any) => { return await API.lambdaPost('/question-of-the-day/resident-responses', params); };
    const response = await fetchAllPaginatedData(apiCall, params);
    return response;
}