import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Segment, Button, Message, Popup, Loader } from 'semantic-ui-react';
import { FacilityConfigurationsTypes, FacilityFeatureType } from '../../types';
import { addFacilityConfigurations, fetchOneFeaturedFacility, updateFacilityConfigurations } from '../../services/Facilities';
import { toast } from 'react-toastify';
import { addWebRTCFeaturedFacility, removeWebRTCFeaturedFacility } from '../../services/WebRTCFeaturedFacilities';
import { sendToast } from '../../util';
interface Props {
    featureConfigs?: FacilityFeatureType;
    facilityId?: string;
    refresh?: () => void;
    getValues?: (config: FacilityConfigurationsTypes) => void; // this is to get the values from the parent component for Facility Creation
}

interface enabledFacility {
    FacilityId: string;
    Name: string;
    _id: string;
    InProgress: boolean;
    Error: string;
}

function FacilityConfigurations(props: Props) {
    const [configuration, setConfiguration] = useState<FacilityConfigurationsTypes>({
        notepad: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.notepad || false,
        orders: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.orders || false,
        ai: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.ai || false,
        signage: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.signage || false,
        kiosk: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.kiosk || false,
        services: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.services || false,
        assets: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.assets || false,
        dining: props.featureConfigs && props.featureConfigs.configurations && props.featureConfigs.configurations.dining || false,
    });
    const [IsWebRTCEnabled, setIsWebRTCEnabled] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isWebRTCEnabledFacility, setIsWebRTCEnabledFaciltiy] = useState<Partial<enabledFacility>>({});

    useEffect(() => {
        fetchFacilityEnabled();
    }, []);

    useEffect(() => {
        props.getValues && props.getValues(configuration);
    }, [configuration]);
    
    const fetchFacilityEnabled = async () => {
        try {
            if(props.facilityId) {
                const facility = await fetchOneFeaturedFacility(props.facilityId);
                if (facility && facility.FacilityId) {
                    console.log("Facility", facility);
                    setIsWebRTCEnabledFaciltiy(facility);
                    setIsWebRTCEnabled(true);
                } else {
                    setIsWebRTCEnabled(false);
                }
            }
        } catch (error) {
            console.log("Error in fetching featured facility", error);
            toast.error("Error in fetching featured facility", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const handleUpsertFacilityConfig = async () => {
        try {
            setIsUpdating(true);
            if (props.facilityId) {
                if (!props.featureConfigs) { // indicating that this data is not created so create should be called 
                    await addFacilityConfigurations(configuration, props.facilityId);
                } else { // indecating that the data already exists so update should be called
                    if (!props.featureConfigs._id) throw new Error("Feature Configurations Id is not defined");
                    await updateFacilityConfigurations({ _id: props.featureConfigs._id, configurations: configuration, isWebRTCEnabled: IsWebRTCEnabled }, props.facilityId);
                }
                if (props.refresh) {
                    props.refresh();
                    fetchFacilityEnabled();
                }
            } else {
                throw new Error("Facility Id is not defined");
            }
        } catch (error) {
            console.error(error);
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsUpdating(false); 
          }
    };

    const handleCheckboxChange = (name) => {
       // when enabled/disabled check the dependency and enable/disable them
       //if orders is enabled then enable services and assets
       //if dining is enabled then enable services and assets
       //if service is enabled then enable assets
       //if assets is disabled then check services is enabled or not if enabled then toast error
         let updatedConfig = { ...configuration };
        if (name === "dining") {
            updatedConfig.dining = !configuration.dining;
            if (updatedConfig.dining && !configuration.services || !configuration.assets) {
                updatedConfig.services = true;
                updatedConfig.assets = true;
            }
        } else if (name === 'orders') {
            updatedConfig.orders = !configuration.orders;
            if (updatedConfig.orders && !configuration.services || !configuration.assets) {
                updatedConfig.services = true;
                updatedConfig.assets = true;
            }
        } else if (name === 'services') {
            updatedConfig.services = !configuration.services;
            if (!configuration.services && !updatedConfig.assets) {
                updatedConfig.assets = true;
                updatedConfig.services = true;
            }
            if (!updatedConfig.assets && configuration.services) {
                updatedConfig.assets = true;
                updatedConfig.services = true;
            }
            if (!updatedConfig.services && configuration.orders) {
                updatedConfig.services = true;
                updatedConfig.assets = true;
                sendToast("warn","Please disable orders before disabling services" );
            }
            if (!updatedConfig.services && configuration.dining) {
                updatedConfig.services = true;
                updatedConfig.assets = true;
                sendToast("warn","Please disable dining before disabling services" );
            }
        } else if (name === 'assets') {
            updatedConfig.assets = !configuration.assets;
            if (!updatedConfig.assets && configuration.services) {
                updatedConfig.assets = true;
                sendToast("warn","Please disable services before disabling assets" );
            }
        } else {
            updatedConfig[name] = !configuration[name];
        }
        setConfiguration(updatedConfig);
    };


    return (
        <Segment >
            <h4 style={{ textAlign: 'center' }}>Facility Configurations</h4>
            <div>
                <Form centered style={{ marginTop: "0px", marginLeft: "50px" }} >
                    <Form.Field>
                        {!isWebRTCEnabledFacility.InProgress  && 
                            <Checkbox
                                disabled={isWebRTCEnabledFacility.Error ? true : false}
                                label="WebRTC"
                                checked={IsWebRTCEnabled ? true : false}
                                onChange={(e, data) => {
                                    setIsWebRTCEnabled(data.checked || false);
                                }}
                            />
                        }
                        {isWebRTCEnabledFacility.InProgress && !isWebRTCEnabledFacility.Error && (
                            <Popup
                                content="WebRTC is being enabled/disabled for this facility, this will take few minutes to reflect, please refresh the page after few minutes to see the status"
                                position="top right"
                                on="hover"
                                trigger={
                                    <Loader
                                        active
                                        inline
                                        size="tiny"
                                    />
                                }
                            />
                        )}
                        {isWebRTCEnabledFacility.Error && <Message negative>There was an issue in WebRTC, please contact support</Message>}
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Notepad"
                            checked={configuration.notepad}
                            onChange={() => handleCheckboxChange('notepad')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Orders"
                            checked={configuration.orders}
                            onChange={() => handleCheckboxChange('orders')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Dining"
                            checked={configuration.dining}
                            onChange={() => handleCheckboxChange('dining')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="AI"
                            checked={configuration.ai}
                            onChange={() => handleCheckboxChange('ai')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Signage"
                            checked={configuration.signage}
                            onChange={() => handleCheckboxChange('signage')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Kiosk"
                            checked={configuration.kiosk}
                            onChange={() => handleCheckboxChange('kiosk')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Services"
                            checked={configuration.services}
                            onChange={() => handleCheckboxChange('services')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Assets"
                            checked={configuration.assets}
                            onChange={() => handleCheckboxChange('assets')}
                        />
                    </Form.Field>
                    {props.facilityId &&
                        <Form.Field style={{ display: "flex", justifyContent: 'center' }}>
                            <Button type="button" primary onClick={handleUpsertFacilityConfig} loading={isUpdating} disabled={isUpdating}>
                                Save Config
                            </Button>
                        </Form.Field>
                    }
                </Form>
            </div>
        </Segment>
    );
}

export default FacilityConfigurations;