import React from 'react'
import { Message } from 'semantic-ui-react'
import { FieldProps } from 'formik'
import EditableImage from '../../EditableImage'

interface CustomProps {
    placeholder: string
}

const GenericImageField: React.FC<any> = (props: FieldProps<any> & CustomProps) => {
    const errMsg = (
        <Message error>
            <p>{props.form.errors[props.field.name]}</p>
        </Message>
    )
    const hasError = props.form.errors[props.field.name] && props.form.touched[props.field.name] ? true : false
    return (
        <div className="editable-image-field">
            <EditableImage
                {...props}
                url={props.field.value}
                onSelected={(value: string | null) => props.form.setFieldValue(props.field.name, value)}
            />
            {hasError && errMsg}
        </div>
    )
}

export default GenericImageField
