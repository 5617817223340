import API from './API';

export async function uploadFile(DocId: string, files: File[], returnFileInfo?: boolean): Promise<any> {
    const filePromises = files.map(async (file) => {
        const { fileName, putUrl } = await getPreSignedFileS3Url(DocId, file, true);
        await API.putFile(putUrl, file);
        return fileName;
    });
    const uploadedFileNames = await Promise.all(filePromises);
    if (returnFileInfo) {
        return { fileNames: uploadedFileNames };
    }
    return undefined;
}

export async function getPreSignedFileS3Url(
    DocId: string,
    file: File,
    randomizeFilename = true,
): Promise<{ putUrl: string; fileName: string; }> {
    let name = file.name;
    if (randomizeFilename) {
        const nameParts = file.name.split('.');
        nameParts[nameParts.length - 2] = nameParts[nameParts.length - 2] + `_${Date.now()}`;
        name = nameParts.join('.');
    }
    const url = await API.lambdaPost('/docs/files/upload', {
        docId: DocId,
        Name: name,
    });
    return {
        putUrl: url as string,
        fileName: name,
    };
}
