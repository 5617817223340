import React, { useEffect, useState } from "react";
import { Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import './style.less';
import {
    pplOfTheMonths
} from '../../../src/services/PplOfTheMonth';
import { fetchAllActiveUsers } from "../../../src/services/Users";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { fetchAllAnnouncementAnalytics, fetchIntentAnalytics } from "../../services/Analytics";
import moment from "moment-timezone";
import SortableTable from "../ReportsMessage/SortedTable";
import PrintExportIcons from "../PrintExportIcons";
import { formatAndExportDataForPrint } from "../../util/jsonTocsvDownloader";
import { User } from "../../types";
import { fetchPaginatedNotepadData } from "../../services/Notepad";
import { fetchAllServiceInstance, getServiceInstancesTotCount } from "../../services/ServiceInstances";
import { fetchAllRequestInstances, getTotCountRequestInstances } from "../../services/RequestInstances";
import { ServicesType } from "../../types/ServicesTypes";
import { listAssets } from "../../services/Assets";
import { Asset } from "../../types/Asset";
import { fetchAllActiveServicesTypes } from "../../services/service";
import { prntyrLink } from "../../util/data";

const CommunityMetrics = (props) => {

    const defaultFilters = {
        pageLimit: 40,
        pageNo: 1,
        messageType: 'all',
        sortBy: "DateAdded:desc"
    };

    interface ExportDataItem {
        "Is mail in": string;
        "Apartments": string;
        "Text": string;
        "Location": string;
        "Date/Time": string;
        "Sent By": string;
    }

    const facilityTimezone = props.facilityTimeZone;

    const [filters, setFilters] = useState(defaultFilters);
    const [isLoading, setIsLoading] = useState(false);
    const [isAnnouncementLoading, setIsAnnouncementLoading] = useState(false);
    const [allAnnouncements, setAllAnnouncements] = useState<{
        Message: string;
        DateAdded: number;
        roomNames: string[];
    }[]>([]);
    const [communityMetricsData, setCommunityMetrcisData] = useState<any[]>([]);
    const [activeUsers, setActiveUsers] = useState<User[]>([]);
    const [selectedService, setSelectedService] = useState<string>("announcement");
    const [announcementCount, setAnnouncementCount] = useState(0);
    const [announcementPaginationCount, setAnnouncementPaginationCount] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);
    const [mailIsInCount, setMailIsInCount] = useState(0);
    const [communityMessagesCount, setCommunityMessagesCount] = useState(0);
    const [notepadCount, setNotepadCount] = useState(0);
    const [countLoading, setCountLoading] = useState(false);
    const [selectedItemCount, setSelectedItemCount] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState<any[]>([]);
    const [printLoading, setPrintLoading] = useState<boolean>(false);
    const [exportData, setExportData] = useState<ExportDataItem[]>([]);
    const [currentStartDate, setCurrentStartDate] = useState<Date>(props.startDate);
    const [currentEndDate, setCurrentEndDate] = useState<Date>(props.endDate);
    const [assetDict, setAssetDict] = useState<{ [key: string]: Asset }>({});

    useEffect(() => {
        setCurrentStartDate(props.startDate);
        setCurrentEndDate(props.endDate);
        if (props.startDate !== currentStartDate && props.endDate !== currentEndDate) {
            return;
        }
        (async () => {
            // Super admins are missing in getActiveUsers
            try {
                setIsLoading(true);
                setIsAnnouncementLoading(true);
                setCountLoading(true);
                if (selectedService === 'announcement') {
                    fetchIntentData('announcement');
                } else if (selectedService === 'notification') {
                    fetchIntentData('notification');
                } else if (selectedService === 'mailIsIn') {
                    fetchIntentData('mailIsIn');
                } else if (selectedService === 'communityMessages') {
                    fetchData('communityMessages');
                } else if (selectedService === 'notepad') {
                    fetchData('notepad');
                }
                await Promise.all([fetchCombinedAnnouncements(), getCount()]);

            } catch (error) {
                console.error("fetch data error", error);
                toast.warn(
                    error instanceof Error ? error.message : "Failed to fetch data",
                    {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                setIsLoading(false);
                setIsAnnouncementLoading(false);
            }
        })();
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        const itemCount = (selectedService === 'communityMessages') ? communityMessagesCount :
            (selectedService === 'notepad') ? notepadCount :
                (selectedService === 'announcement') ? announcementPaginationCount :
                    (selectedService === 'notification') ? notificationCount :
                        (selectedService === 'mailIsIn') ? mailIsInCount :
                            0;

        setSelectedItemCount(itemCount);

        const column = (selectedService === 'announcement' || selectedService === 'notification') ? columnsIntents :
            (selectedService === 'mailIsIn') ? columnsMailIsIn :
                (selectedService === 'communityMessages') ? columnsMessages :
                    (selectedService === 'notepad') ? columnsNotepad :
                        [];
        setSelectedColumn(column);
    }, [selectedService, announcementCount, notificationCount, mailIsInCount, communityMessagesCount, notepadCount]);

    useEffect(() => {
        setIsLoading(true);
        if (selectedService === 'announcement') {
            fetchIntentData('announcement');
        } else if (selectedService === 'notification') {
            fetchIntentData('notification');
        } else if (selectedService === 'mailIsIn') {
            fetchIntentData('mailIsIn');
        } else if (selectedService === 'communityMessages') {
            fetchData('communityMessages');
        } else if (selectedService === 'notepad') {
            fetchData('notepad');
        }
    }, [filters, selectedService]);

    useEffect(() => {
        formatExportData();
    }, [communityMetricsData]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const [allAssets = [], users = []] = await Promise.all([listAssets(), fetchAllActiveUsers()]);
                // Generate dictionaries
                const assetDictionary = {};
                setActiveUsers(users);
                allAssets.forEach((asset: Asset) => {
                    assetDictionary[String(asset._id)] = asset;
                });
                setAssetDict(assetDictionary);
            } catch (error) {
                console.error("fetch data error", error);
                toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);
    
    const getCount = async () => {
        setCountLoading(true);
        const fromDateString = moment(props.startDate).format('YYYY-MM-DD');
        const toDateString = moment(props.endDate).format('YYYY-MM-DD');
        const startDateTimeString = moment(props.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment(props.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const fromDateTimestamp = moment.tz(fromDateString, 'YYYY-MM-DD', facilityTimezone).startOf('day').valueOf();
        const toDateTimestamp = moment.tz(toDateString, 'YYYY-MM-DD', facilityTimezone).endOf('day').valueOf();
        const intentsDataReqBody = {
            option: "announcement",
            sortBy: "DateAdded:desc",
            pageLimit: filters.pageLimit,
            pageNo: filters.pageNo,
            fromDate: fromDateTimestamp,
            toDate: toDateTimestamp,
            fromDateString,
            toDateString
        };
        const requestAnnouncementPayload = {
            StartDateTime: startDateTimeString,
            EndDateTime: endDateTimeString,
            triggerArns: { $exists: true } // requests with announcements
        };
        const serviceAnnouncementPayload = {
            startDate: startDateTimeString,
            endDate: endDateTimeString,
            triggerArns: { $exists: true },
            'defaults?.autoAnnouncement': true,
        };
        const [intentsData, requestAnnouncementCount = 0, serviceAnnouncementCount = 0] = await Promise.all([
            fetchIntentAnalytics(intentsDataReqBody),
            getTotCountRequestInstances(requestAnnouncementPayload),
            getServiceInstancesTotCount(serviceAnnouncementPayload),
        ]);
        const filter = {
            startDate: {
                $gte: fromDateString,
                $lte: toDateString,
            },
        };
        setAnnouncementCount(intentsData.announcementCount + requestAnnouncementCount + serviceAnnouncementCount);
        setAnnouncementPaginationCount(intentsData.announcementCount + requestAnnouncementCount + serviceAnnouncementCount);
        setNotificationCount(intentsData.notificationCount);
        setMailIsInCount(intentsData.mailIsInCount);
        const { TotRecords: communityMessagesCount = 0 } = await pplOfTheMonths('affirmation', filter);
        const { TotRecords: notepadCount = 0 } = await fetchPaginatedNotepadData(fromDateString, toDateString, false);
        setCommunityMessagesCount(communityMessagesCount);
        setNotepadCount(notepadCount);
        setCountLoading(false);
    };

    const fetchCombinedAnnouncements = async () => {
        const fromDateString = moment(props.startDate).format('YYYY-MM-DD');
        const toDateString = moment(props.endDate).format('YYYY-MM-DD');
        const startDateTimeString = moment(props.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment(props.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const fromDateTimestamp = moment.tz(fromDateString, 'YYYY-MM-DD', facilityTimezone).startOf('day').valueOf();
        const toDateTimestamp = moment.tz(toDateString, 'YYYY-MM-DD', facilityTimezone).endOf('day').valueOf();
        const intentsDataReqBody = {
            option: 'announcement',
            sortBy: filters.sortBy,
            pageLimit: 100,
            pageNo: 1,
            fromDate: fromDateTimestamp,
            toDate: toDateTimestamp,
            fromDateString,
            toDateString,
        };
        const requestAnnouncementPayload = {
            StartDateTime: startDateTimeString,
            EndDateTime: endDateTimeString,
            triggerArns: { $exists: true } // requests with announcements
        };
        const serviceAnnouncementPayload = {
            startDate: startDateTimeString,
            endDate: endDateTimeString,
            triggerArns: { $exists: true },
            'defaults?.autoAnnouncement': true,
        };
        const [intentsData, requestAnnouncements, serviceInstanceAnnouncements] = await Promise.all([
            fetchAllAnnouncementAnalytics(intentsDataReqBody, filters.sortBy),
            fetchAllRequestInstances(requestAnnouncementPayload),
            fetchAllServiceInstance(serviceAnnouncementPayload),
        ]);
        const serviceIds = requestAnnouncements.map((item) => item.OriginalServiceId);
        const services = (serviceIds.length && (await fetchAllActiveServicesTypes({ Filter: { _id: { $in: serviceIds } } }))) || [];
        const serviceDict = {};
        services.forEach((service: ServicesType) => {
            serviceDict[service._id] = service;
        });
        const formattedServiceAnnouncements = serviceInstanceAnnouncements.map((item) => {
            const minutesBeforeAnnouncement = item && item.defaults && item.defaults.minutesBeforeAnnouncement;
            let DateAdded = moment.tz(item.startDate, facilityTimezone).valueOf();
            if (minutesBeforeAnnouncement) {
                DateAdded = DateAdded - minutesBeforeAnnouncement * 60 * 1000;
            }
            const dayInFacility = moment.tz(item.startDate, facilityTimezone).format('dddd');
            const dateInFacility = moment.tz(item.startDate, facilityTimezone).format('M/D/YY');
            const timeInFacility = moment.tz(item.startDate, facilityTimezone).format('h:mm A');
            const location = (item.Asset && assetDict[item.Asset] && assetDict[item.Asset].AssetName) || null;
            const message = `REMINDER: ${item && item.name || ""} ${
                item && item.shortDescription || ""
            } scheduled at ${timeInFacility} on ${dayInFacility}, ${dateInFacility}${
                location ? ` in ${location}.` : '.'
            }`;
            const target = item.defaults && item.defaults.alertTarget;
            return {
                Message: message,
                DateAdded: DateAdded as number,
                roomNames: target !== 'registered' ? ['All Rooms'] : ['Registered Rooms'],
            };
        });
        const formattedRequestAnnouncements = requestAnnouncements.map((item) => {
            const requestStartDateTimeString =
                item.ScheduledTime && item.ScheduledTime.startDate
                    ? moment(item.ScheduledTime.startDate).format('M/D/YY h:mm A')
                    : moment.tz(item.RequestedTime, facilityTimezone).format('M/D/YY h:mm A');
            const dayInFacility = moment(requestStartDateTimeString).format('dddd');
            const dateInFacility = moment(requestStartDateTimeString).format('M/D/YY');
            const timeInFacility = moment(requestStartDateTimeString).format('h:mm A');
            const correspondingService = serviceDict[item.OriginalServiceId];
            const location =
                (correspondingService &&
                    correspondingService.Asset &&
                    assetDict[correspondingService.Asset] &&
                    assetDict[correspondingService.Asset].AssetName) ||
                null;
            const message = `REMINDER: ${correspondingService && correspondingService.name || ""} ${correspondingService && correspondingService.shortDescription || ""} scheduled at ${timeInFacility} on ${dayInFacility}, ${dateInFacility}${
                location ? ` in ${location}.` : '.'
            }`;
            return {
                Message: message,
                DateAdded: item.RequestedTime as number,
                roomNames: [`${item.UnitName}`],
            };
        });
        const mergedData = [
            ...intentsData,
            ...formattedServiceAnnouncements,
            ...formattedRequestAnnouncements,
        ];
        const sortedData = mergedData.sort((a, b) => b.DateAdded - a.DateAdded);
        setAllAnnouncements(sortedData);
        const currentPageData = sortedData.slice(
            (filters.pageNo - 1) * filters.pageLimit,
            filters.pageNo * filters.pageLimit
        );
        setCommunityMetrcisData(currentPageData);
        setIsAnnouncementLoading(false);
    };

    const fetchIntentData = async (option) => {
        try {
            setSelectedService(option);
            const fromDateString = moment(props.startDate).format('YYYY-MM-DD');
            const toDateString = moment(props.endDate).format('YYYY-MM-DD');
            const fromDateTimestamp = moment.tz(fromDateString, 'YYYY-MM-DD', facilityTimezone).startOf('day').valueOf();
            const toDateTimestamp = moment.tz(toDateString, 'YYYY-MM-DD', facilityTimezone).endOf('day').valueOf();
            const intentsDataReqBody = {
                option: option,
                sortBy: filters.sortBy,
                pageLimit: filters.pageLimit,
                pageNo: filters.pageNo,
                fromDate: fromDateTimestamp,
                toDate: toDateTimestamp,
                fromDateString,
                toDateString
            };
            if (option === 'announcement') { // for announcements we plan to do frontend pagination since we have to fetch from 3 different collections
                const currentPageData = allAnnouncements.slice(
                    (filters.pageNo - 1) * filters.pageLimit,
                    filters.pageNo * filters.pageLimit
                );
                setCommunityMetrcisData(currentPageData);
            } else {
                const intentsData = await fetchIntentAnalytics(intentsDataReqBody);
                setCommunityMetrcisData(intentsData.intentsData);
            }
        } catch (error) {
            console.error("fetch data error", error);
            toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async (service) => {
        try {
            setCommunityMetrcisData([]);
            let selectedService = '';
            let fetchFunction;
            let functionParams;
            const startDate = moment.tz(props.startDate, facilityTimezone).format('YYYY-MM-DD');
            const endDate = moment.tz(props.endDate, facilityTimezone).format('YYYY-MM-DD');

            if (service === 'communityMessages') {
                selectedService = 'communityMessages';
                fetchFunction = pplOfTheMonths;
                const filter = {
                    startDate: {
                        $gte: startDate,
                        $lte: endDate,
                    },
                };
                const pageSort = {
                    page_no: filters.pageNo,
                    page_size: filters.pageLimit,
                    sort: [{ startDate: "asc" }]
                };
                functionParams = ['affirmation', filter, pageSort];
            } else if (service === 'notepad') {
                const pagingSorting = {
                    page_no: filters.pageNo,
                    page_size: filters.pageLimit,
                    sort: [{ dateTimeString: "asc" }]
                };
                selectedService = 'notepad';
                fetchFunction = fetchPaginatedNotepadData;
                functionParams = [startDate, endDate, true, "", pagingSorting];
            }

            const { Result: data = [] } = await fetchFunction(...functionParams);

            data.map((item) => {
                activeUsers.map((userItem) => {
                    if (item.AddedBy === userItem._id)
                        item.Username = userItem.Username;
                });
            });

            setCommunityMetrcisData(data);
            setSelectedService(selectedService);
        } catch (error) {
            console.error("fetch data error", error);
            toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const nextPage = () => {
        filters.pageNo * filters.pageLimit < selectedItemCount && setFilters({ ...filters, pageNo: filters.pageNo + 1 });
    };

    const previousPage = () => {
        filters.pageNo > 1 && setFilters({ ...filters, pageNo: filters.pageNo - 1 });
    };

    const columnsIntents = React.useMemo<any>(
        () => [
            {
                Header: 'Text',
                accessor: 'Message',
            },
            {
                Header: 'Apartments',
                accessor: 'roomNames',
                Cell: ({ row }) => {
                    const { roomNames } = row.original;
                    return (
                      <div style={{ height: roomNames && roomNames.length > 0 ? '10vh' : 'auto', overflow: 'auto'}}>
                        {roomNames && roomNames.length > 0 ? (
                          roomNames.map((roomName, index) => (
                            <div key={index}>{roomName}</div>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    );
                  },
            },
            {
                Header: 'Date/Time',
                id: 'dateAdded',
                accessor: row => {
                    const date = moment.tz(row.DateAdded, facilityTimezone).format('M/D/YY h:mm A');
                    return row.DateAdded && date;
                },
            },
        ],
        [communityMetricsData],
    );
    const columnsMailIsIn = React.useMemo<any>(
        () => [
            {
                Header: 'Date/Time',
                id: 'dateAdded',
                accessor: row => {
                    const date = format(row.DateAdded, 'M/D/YY h:mm A');
                    return row.DateAdded && date;
                },
            },
            {
                Header: 'Is mail in',
                id: 'isMailIn',
                accessor: row => {
                    let mailIsIn = 'No';
                    if (row.isMailIn) {
                        mailIsIn = 'Yes';
                    }
                    return mailIsIn;
                },
            },
        ],
        [communityMetricsData],
    );
    const columnsMessages = React.useMemo<any>(
        () => [

            {
                Header: 'Text',
                accessor: 'name',
            },
            {
                Header: 'Date/Time',
                id: 'dateAdded',
                accessor: row => {
                    const date = format(row.DateAdded, 'M/D/YY h:mm A');
                    return row.DateAdded && date;
                },
            },
            {
                Header: 'Sent By',
                accessor: 'Username',
            },
        ],
        [communityMetricsData],
    );
    const columnsNotepad = React.useMemo<any>(
        () => [

            {
                Header: 'Text',
                accessor: 'text',
            },
            {
                Header: 'Location',
                accessor: 'location'
            },
            {
                Header: 'Date/Time',
                id: 'dateAdded',
                accessor: row => {
                    const date = format(row.dateTimeString, 'M/D/YY h:mm A');
                    return row.dateTimeString && date;
                },
            },
            {
                Header: 'Sent By',
                accessor: 'Username',
            },
        ],
        [communityMetricsData],
    );

    const formatExportData = () => {
        let formattedData: any = [];
        if (selectedService === 'announcement') {
            formattedData = communityMetricsData.map((el) => {
                return {
                    "Text": el.Message,
                    "Apartments": el.roomNames,
                    "Date": moment.tz(el.DateAdded, facilityTimezone).format('M/D/YY'),
                    "Time": moment.tz(el.DateAdded, facilityTimezone).format('h:mm A'),
                };
            });
        } else if (selectedService === 'notification') {
            formattedData = communityMetricsData.map((el) => {
                return {
                    "Text": el.Message,
                    "Apartments": el.roomNames,
                    "Date": moment.tz(el.DateAdded, facilityTimezone).format('M/D/YY'),
                    "Time": moment.tz(el.DateAdded, facilityTimezone).format('h:mm A'),
                };
            });
        } else if (selectedService === 'mailIsIn') {
            formattedData = communityMetricsData.map((el) => {
                return {
                    "Date": moment.tz(el.DateAdded, facilityTimezone).format('M/D/YY'),
                    "Time": moment.tz(el.DateAdded, facilityTimezone).format('h:mm A'),
                    "Is mail in": el.isMailIn ? 'Yes' : 'No',
                };
            });
        } else if (selectedService === 'communityMessages') {
            formattedData = communityMetricsData.map((el) => {
                return {
                    "Text": el.name,
                    "Date": moment.tz(el.DateAdded, facilityTimezone).format('M/D/YY'),
                    "Time": moment.tz(el.DateAdded, facilityTimezone).format('h:mm A'),
                    "Sent By": el.Username,
                };
            });
        } else if (selectedService === 'notepad') {
            formattedData = communityMetricsData.map((el) => {
                return {
                    "Text": el.text,
                    "Location": el.location,
                    "Date": moment.tz(el.dateTimeString, facilityTimezone).format('M/D/YY'),
                    "Time": moment.tz(el.dateTimeString, facilityTimezone).format('h:mm A'),
                    "Sent By": el.Username,
                };
            });
        }
        setExportData(formattedData);
    };

    const onPrintClick = async () => {
        window.open(prntyrLink, '_blank');
        // let formattedData: any = [];
        // if (selectedService === 'announcement') {
        //     formattedData = communityMetricsData.map((el) => {
        //         return {
        //             Subject: el.Message,
        //             StartTime: new Date(el.DateAdded),
        //             StartDate: new Date(el.DateAdded),
        //         };
        //     });
        // } else if (selectedService === 'notification') {
        //     formattedData = communityMetricsData.map((el) => {
        //         return {
        //             Subject: el.Message,
        //             StartTime: new Date(el.DateAdded),
        //             StartDate: new Date(el.DateAdded),
        //         };
        //     });
        // } else if (selectedService === 'mailIsIn') {
        //     formattedData = communityMetricsData.map((el) => {
        //         return {
        //             Subject: el.isMailIn ? 'Yes' : 'No',
        //             StartTime: new Date(el.DateAdded),
        //             StartDate: new Date(el.DateAdded),
        //         };
        //     });
        // } else if (selectedService === 'communityMessages') {
        //     formattedData = communityMetricsData.map((el) => {
        //         return {
        //             Subject: el.name,
        //             StartTime: new Date(el.DateAdded),
        //             StartDate: new Date(el.DateAdded),
        //         };
        //     });
        // } else if (selectedService === 'notepad') {
        //     formattedData = communityMetricsData.map((el) => {
        //         return {
        //             Subject: el.text,
        //             StartTime: new Date(el.dateTimeString),
        //             StartDate: new Date(el.dateTimeString),
        //         };
        //     });
        // }

        // setPrintLoading(true);
        // await formatAndExportDataForPrint(formattedData, `reports_community_metrics`, facilityTimezone, "menu" /* menu will not add formatting */);
        // setPrintLoading(false);
    };

    return (
        <>
            <div>
                {countLoading ? (
                    <Loader active inline="centered" />
                ) : (
                    <Grid columns={5} stackable>
                        <Grid.Row className="count-row">
                            <Grid.Column className="count-column">
                                <Segment
                                    textAlign="center"
                                    className="count-item"
                                    style={{ border: selectedService === 'announcement' ? '1px solid #2185d0' : '' }}
                                    onClick={() => {
                                        setSelectedService('announcement');
                                        setFilters(defaultFilters);
                                    }}
                                >
                                    <p className="small-text">Announcements</p>
                                    <p>{announcementCount}</p>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column className="count-column">
                                <Segment
                                    textAlign="center"
                                    className="count-item"
                                    style={{ border: selectedService === 'notification' ? '1px solid #2185d0' : '' }}
                                    onClick={() => {
                                        setSelectedService('notification');
                                        setFilters(defaultFilters);
                                    }}
                                >
                                    <p className="small-text">Notifications</p>
                                    <p>{notificationCount}</p>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column className="count-column">
                                <Segment
                                    textAlign="center"
                                    className="count-item"
                                    style={{ border: selectedService === 'mailIsIn' ? '1px solid #2185d0' : '' }}
                                    onClick={() => {
                                        setSelectedService('mailIsIn');
                                        setFilters(defaultFilters);
                                    }}
                                >
                                    <p className="small-text">Mail Is In</p>
                                    <p>{mailIsInCount}</p>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column className="count-column">
                                <Segment
                                    textAlign="center"
                                    className="count-item"
                                    style={{ border: selectedService === 'communityMessages' ? '1px solid #2185d0' : '' }}
                                    onClick={() => {
                                        setSelectedService('communityMessages');
                                        setFilters(defaultFilters);
                                    }}
                                >
                                    <p className="small-text">Community News</p>
                                    <p>{communityMessagesCount}</p>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column className="count-column">
                                <Segment
                                    textAlign="center"
                                    className="count-item"
                                    style={{ border: selectedService === 'notepad' ? '1px solid #2185d0' : '' }}
                                    onClick={() => {
                                        setSelectedService('notepad');
                                        setFilters(defaultFilters);
                                    }}
                                >
                                    <p className="small-text">Notepad</p>
                                    <p>{notepadCount}</p>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
            <div className="table-containter">
                <Dimmer active={isLoading || isAnnouncementLoading} inverted>
                    <Loader active={isLoading || isAnnouncementLoading}>
                        Please wait as this might take a while to load based on the date range selected...
                    </Loader>
                </Dimmer>
                <div className="filter-block-new">
                    <div className="icon-block-table">
                        <PrintExportIcons
                            exportData={exportData}
                            exportFileName={`${selectedService}_community_metrics_report`}
                            onPrintClick={onPrintClick}
                            printLoading={printLoading}
                            communicationsPageStyles={true}
                        />
                    </div>
                </div>
                {!isAnnouncementLoading && selectedColumn.length && (
                    <div>
                        <SortableTable
                            columns={selectedColumn}
                            data={communityMetricsData}
                            nextPage={{ nextPage, disableNext: !(filters.pageNo * filters.pageLimit < selectedItemCount) }}
                            previousPage={{ previousPage, disablePrev: filters.pageNo === 1 }}
                            pageNumber={filters.pageNo}
                            loading={isAnnouncementLoading || isAnnouncementLoading}
                            setManualSortBy={() => {}}
                            columnSortData={{}}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CommunityMetrics;