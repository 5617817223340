import React, { useEffect } from 'react';
import { Dimmer, Icon, Loader, Modal, Pagination, Popup, Table, TableHeaderCell } from 'semantic-ui-react';
import { fetchDepartments } from '../../services/Departments';
import { fetchDepartmentStaffs, deleteUser } from '../../services/Users';
import { sendToast } from '../../util';
import { User } from '../../types';
import ConfirmationModal from '../DeleteConfirmationModal';
import DiningKitchenStaffForm from './DiningKitchenStaffForm';

interface KitchenStaffMembersProps {
    setKitchenAndDiningDeletionId: React.Dispatch<React.SetStateAction<string>>;
    kitchenAndDiningDeletionId: string;
}

const KitchenStaffMembers: React.FC<KitchenStaffMembersProps> = ({ setKitchenAndDiningDeletionId, kitchenAndDiningDeletionId }) => {
    const [kitchenStaffMembersLoading, setKitchenStaffMembersLoading] = React.useState<boolean>(false);
    const [kitchenStaffMembersDetails, setKitchenStaffMembersDetails] = React.useState<User[]>([]);
    const [displayKitchenMembers, setDisplayKitchenMembers] = React.useState<User[]>([]);
    const [kitchenStaffDeleteModal, setKitchenStaffDeleteModal] = React.useState<boolean>(false);
    const [kitchenStaffDeleteId, setKitchenStaffDeleteId] = React.useState<string | null>(null);
    const [page_no, setPage_no] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const pageSize = 10;
    const [openCreateForm, setOpenCreateForm] = React.useState(false);
    const [reloadFlag, setReloadFlag] = React.useState(false);
    const [selectedStaff, setSelectedStaff] = React.useState<User | null>(null);

    useEffect(() => {
        fetchKitchenStaffMembers();
    }, [reloadFlag]);

    useEffect(() => {
        paginateUsers();
    }, [page_no, kitchenStaffMembersDetails]);

    const paginateUsers = () => {
        const start = (page_no - 1) * pageSize;
        const end = start + pageSize;
        setDisplayKitchenMembers(kitchenStaffMembersDetails.slice(start, end));
    }

    useEffect(() => {
        // the deletion id is and the staff is present in the kitchen staff list, then remove it from the list
        if (kitchenStaffMembersDetails.length > 0 && kitchenStaffMembersDetails.find((staff) => staff._id === kitchenAndDiningDeletionId)) {
            removeDeletedStaff(kitchenAndDiningDeletionId);
        }
    }, [kitchenAndDiningDeletionId]);

    const fetchKitchenStaffMembers = async () => {
        try {
            setKitchenStaffMembersLoading(true);
            const departmentsArr = await fetchDepartments();
            const kitchenDepartment = departmentsArr.find((department) => department.Name === "Kitchen");
            const diningDepartment = departmentsArr.find((department) => department.Name === "Dining");
            if (!kitchenDepartment || !diningDepartment) {
                throw new Error("Kitchen or Dining Department not found.");
            }
            //If strictDepatment is true than it will only strictly check if all the departments are present.
            const filters = {
                DepartmentIds: [String(kitchenDepartment._id), String(diningDepartment._id)],
                StrictDepartments: true
            }
            const { users: allDepartmentStaffs } = await fetchDepartmentStaffs(filters);
            setTotalPages(Math.ceil(Number(allDepartmentStaffs.length) / pageSize));
            setKitchenStaffMembersDetails(allDepartmentStaffs);
            paginateUsers();
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error While fetching the Kitchen Staff Details.");
        } finally {
            setKitchenStaffMembersLoading(false);
        }
    }
    const handleCreateFormModalOpen = () => {
        setSelectedStaff(null);
        setOpenCreateForm(true);
    };
    const handleCloseForm = (reload = false) => {
        setSelectedStaff(null);
        setOpenCreateForm(false);
        if (reload) {
            setReloadFlag(!reloadFlag);
        }
    }

    const handleDeleteKitchenStaff = async (id: string) => {
        setKitchenStaffDeleteId(id);
        setKitchenStaffDeleteModal(true);
    }

    const closeKitchenStaffDeleteModal = () => {
        setKitchenStaffDeleteModal(false);
        setKitchenStaffDeleteId(null);
    }

    const removeDeletedStaff = (id: string) => {
        // if the person is only one whom we are deleting than move the page to previous page
        if (kitchenStaffMembersDetails.length === 1 && page_no > 1) {
            setPage_no(page_no - 1);
        }
        setKitchenStaffMembersDetails((prev) => prev.filter((staff) => staff._id !== id));
    }

    const deleteKitchenStaff = async () => {
        try {
            setKitchenStaffMembersLoading(true);
            setKitchenStaffDeleteModal(false);
            await deleteUser(String(kitchenStaffDeleteId));
            setKitchenAndDiningDeletionId(String(kitchenStaffDeleteId));
            sendToast("success", "Dining Staff deleted successfully.");
            // remove the deleted staff from the list
            removeDeletedStaff(String(kitchenStaffDeleteId));
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error While deleting the Dining Staff.");
        } finally {
            setKitchenStaffMembersLoading(false);
        }
    }

    const handleOpenEditModal = (id: string) => {
        const selectedStaffMember = kitchenStaffMembersDetails.find((staff) => String(staff._id) === String(id));
        selectedStaffMember && setSelectedStaff(selectedStaffMember);
        setOpenCreateForm(true);
    }

    return (
        <>
            <Dimmer active={kitchenStaffMembersLoading} inverted>
                <Loader active={kitchenStaffMembersLoading}>  </Loader>
            </Dimmer>
            <div className="restaurant-header">
                <h3>Station</h3>
                <span>
                    <Popup
                        trigger={<Icon name="plus" size="large" style={{ cursor: 'pointer' }} onClick={() => handleCreateFormModalOpen()} />}
                        content="Add Kitchen Staff"
                        position="top right"
                    />
                </span>
            </div>
            <Table celled striped >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>User ID</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Cell</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {displayKitchenMembers && displayKitchenMembers.length > 0 ?
                        displayKitchenMembers.map((kitchenStaff) => (
                            <Table.Row key={kitchenStaff._id} className='table-row'>
                                <Table.Cell><p className='clickable' onClick={() => handleOpenEditModal(kitchenStaff._id)}> {kitchenStaff.FirstName === "Station" ? "" : kitchenStaff.FirstName} {kitchenStaff.LastName}</p></Table.Cell> {/* Station is a default name when creating a staff from dining and we have to remove it and if the user is not creted from dining than show the full name.*/}
                                <Table.Cell>{kitchenStaff.Username}</Table.Cell>
                                <Table.Cell className='cell-width'>{kitchenStaff.Email || "-"}</Table.Cell>
                                <Table.Cell className="table-cell">
                                    <p>{kitchenStaff.Phone || kitchenStaff.Cell || "-"}</p>
                                    <Popup
                                        trigger={
                                            <Icon className="trash-icon" name='trash' size="large" onClick={() => handleDeleteKitchenStaff(kitchenStaff._id)} />
                                        }
                                        content='Delete Kitchen Staff'
                                        position='top right'
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))
                        :
                        <Table.Row>
                            <Table.Cell colSpan="3" style={{ textAlign: 'center' }}>
                                <p>No staff found for Kitchen Department.</p>
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
                {(totalPages > 1) && <Table.Footer >
                    <Table.Row>
                        <TableHeaderCell colSpan='4' >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Pagination
                                    boundaryRange={0}
                                    defaultActivePage={1}
                                    activePage={page_no}
                                    onPageChange={(e, { activePage }) => {
                                        setPage_no(Number(activePage));
                                    }}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={1}
                                    totalPages={totalPages}
                                />
                            </div>
                        </TableHeaderCell>
                    </Table.Row>
                </Table.Footer>}
            </Table>
            <ConfirmationModal
                open={kitchenStaffDeleteModal}
                onClose={closeKitchenStaffDeleteModal}
                onConfirm={deleteKitchenStaff}
                messageToDisplay="Are you sure you want to delete the Kitchen Staff?"
            />
            <Modal
                // size='small'
                onClose={() => setOpenCreateForm(false)}
                open={openCreateForm}
                style={{ width: '400px' }}
            >
                <DiningKitchenStaffForm source={"Kitchen"} user={selectedStaff} closeForm={handleCloseForm} />
            </Modal>
        </>
    );
};

export default KitchenStaffMembers;
