import React from 'react'
import './style.less'
import { User, UserLike } from '../../../../types'
import UsersList from '../../../../components/UsersList'
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface State {

}

interface Props extends RouteComponentProps {
    users: User[]
    error: string | null
    selectedId?: string
}

class StaffList extends React.Component<Props, State> {
    get userLikeArray(): UserLike[] {
        return this.props.users.map(u => {
            const details = u.Departments.map(d => d.Name).join(', ')
            return {
                _id: u._id,
                FirstName: u.FirstName,
                LastName: u.LastName,
                Details: details,
                Image: u.Icon,
                CommunityLiaison: u.CommunityLiaison
            }
        })
    }

    navigateToEdit(id: string) {
        return this.props.history.push(`/admin/user/${id}`)
    }

    render() {
        return (
            <div className="UsersList container-tabs">
                <UsersList users={this.userLikeArray} onClick={this.navigateToEdit.bind(this)} selectedId={this.props.selectedId} />
            </div>
        )
    }
}

export default withRouter(StaffList)
