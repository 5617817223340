import {
    RemoteVideoAction,
    SET_REMOTE_VIDEO
} from '../actions/remoteVideo';
import { RemoteVideoState } from '../types';

const initialState: RemoteVideoState = {
    remoteVideo: null
}

export function remoteVideoReducer(
    state = initialState,
    action: RemoteVideoAction,
): RemoteVideoState {
    console.log("got inside the reducer", { action })
    switch (action.type) {
        case SET_REMOTE_VIDEO:
            return {
                remoteVideo: action.remoteVideo
            }
        default:
            return state
    }
}
