import * as React from 'react';
import { Grid, Input } from 'semantic-ui-react';
import PieChart from './PieChart';
import { declineOptions, participationOptions, ParticipationOptionsStatsType, DeclineOptionsStatsType } from '../../util/data';

const ReportsAttendancePieChart = ({
    attendedData,
    declinedData,
    searchTerm,
    setSearchTerm,
}: {
    attendedData: ParticipationOptionsStatsType;
    declinedData: DeclineOptionsStatsType;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
}) => {
    const attendedLabels = participationOptions.map((option) => option.text);
    const declinedLabels = declineOptions.map((option) => option.text);

    const attendedValues = Object.values(attendedData);
    const declinedValues = Object.values(declinedData);

    const noAttendedData = Math.max(...attendedValues) === 0;
    const noDeclinedData = Math.max(...declinedValues) === 0;

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value = '' }: { value: string } = e.target;
        setSearchTerm(value);
    };

    const totalAttended = attendedValues.reduce((acc, curr) => acc + curr, 0);
    const totalDeclined = declinedValues.reduce((acc, curr) => acc + curr, 0);
    const attendedTitle = `Attended ${totalAttended}`;
    const declinedTitle = `Declined ${totalDeclined}`;
    const noAttendedDataMessage = noAttendedData
        ? {
              Header: 'No attendance data',
              Message: 'There is no attendance data for the selected date range.',
          }
        : false;
    const noDeclinedDataMessage = noDeclinedData
        ? {
              Header: 'No declined data',
              Message: 'There is no declined data for the selected date range.',
          }
        : false;

    return (
        <Grid.Column>
            <Grid.Row>
                <h4 style={{ marginTop: '1vh', marginLeft: '0.5vw' }}>Activity participation level</h4>
            </Grid.Row>
                <Grid.Row>
                    <Input
                        style={{ width: '95%', margin: '2vh 0.5vw 0 0.5vw' }}
                        icon="search"
                        placeholder={'Search by Activity Name'}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Grid.Row>
                <Grid.Row>
                    <PieChart
                        labels={attendedLabels}
                        values={attendedValues}
                        title={attendedTitle}
                        noData={noAttendedDataMessage}
                    />
                </Grid.Row>
                <Grid.Row>
                    <PieChart
                        labels={declinedLabels}
                        values={declinedValues}
                        title={declinedTitle}
                        noData={noDeclinedDataMessage}
                    />
                </Grid.Row>
        </Grid.Column>
    );
};

export default ReportsAttendancePieChart;
