import React, { useState } from 'react'
import { Dimmer, Loader, Button, Input, Card, Grid, Menu } from 'semantic-ui-react'
import { fetchDeviceDetails } from '../../../services/Devices'
import { toast } from 'react-toastify';

interface DeviceDetails {
  unitName: string | null;
  facilityName: string | null;
  deviceSerialNumber: string | null;
  unitId: string | null;
  unitFacilityId: string | null;
  facilityFriendlyName: string | null;
}

const Devices = () => {

  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [deviceSerialNumberSearch, setDeviceSerialNumberSearch] = useState("")
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails | null>(null)

  const updateSearchQuery = (value) => {
    setDeviceSerialNumberSearch(value)
  }

  const handleDeviceDetailsSearch = async () => {
    try {
      setIsFetching(true)
      const fetchedDeviceDetails = await fetchDeviceDetails(deviceSerialNumberSearch)
      setDeviceDetails(fetchedDeviceDetails)
      setIsFetching(false)
    } catch (error) {
      setDeviceDetails(null)
      setIsFetching(false)
      console.error("Error while fetching device details", error)
      toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    })
    }
  }

  return (
    <div>
      <Dimmer active={isFetching} inverted>
      </Dimmer>
      <div style={{ marginBottom: '20px' }}>
        <span style={{ marginBottom: '5px', display: 'block' }}>Device Details Search</span>
        <div style={{ display: 'flex' }}>
          <Input
            placeholder="Search Device by Serial Number"
            type="text"
            value={deviceSerialNumberSearch}
            style={{ marginRight: '5px' }}
            onChange={(_, { value }) => updateSearchQuery(value)}
          />
          <Button primary onClick={handleDeviceDetailsSearch} >
            Search
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        {
          deviceDetails &&
          <Grid>
            <Grid.Column>
              <Menu fluid vertical>
                <Menu.Item className='header'>Room name: {deviceDetails.unitName}</Menu.Item>
                <Menu.Item className='header'>Facility friendly name: {deviceDetails.facilityFriendlyName}</Menu.Item>
                <Menu.Item className='header'>Facility name: {deviceDetails.facilityName}</Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid>
        }
      </div>
    </div>
  )
}

export default Devices
