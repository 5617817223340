import React, { FC, useEffect, useState } from 'react';
import { UserProfile } from '../../../types';
import { Button, Card, Confirm, Dimmer, Grid, Icon, Input, Loader, Modal, Segment } from 'semantic-ui-react';
import GroupsModal from '../../../components/AssetsGroupsManagement/GroupsModal';
import { toast } from 'react-toastify';
import { deleteGroup, listPaginatedGroups } from '../../../services/Groups';
import { Group, Member } from '../../../types/Group';
import { fetchAllActiveUsers } from '../../../services/Users';
import { fetchAllActiveFacilityRegistrants } from '../../../services/Registrants';
import LoadMore from '../../../components/LoadMore';

interface Props {
    profile: UserProfile | null;
}

const Groups: FC<Props> = ({ profile }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [groupsList, setGroupsList] = useState<Group[]>([]);
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');
    const [deleteImageName, setDeleteImageName] = useState<string>('');
    const [openMembers, setOpenMembers] = useState<boolean>(false);
    const [currentMembers, setCurrentMembers] = useState<Partial<Member>[]>([]);
    const [membersList, setMembersList] = useState<Member[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMorePages, setHasMorePages] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [pagelimit, setPageLimit] = useState<number>(16);

    useEffect(() => {
        setLoading(true);
        const getStaffAndResidents = async () => {
            const staff = await fetchAllActiveUsers();
            staff.map((item) => {
                setMembersList((prevState) => [
                    ...prevState,
                    {
                        _id: item._id,
                        firstName: item.FirstName,
                        lastName: item.LastName,
                        staff: true,
                    },
                ]);
            });
            const residents = await fetchAllActiveFacilityRegistrants(profile ? profile.Facility : '', true);
            residents.map((item) => {
                setMembersList((prevState) => [
                    ...prevState,
                    {
                        _id: item._id,
                        firstName: item.FirstName,
                        lastName: item.LastName,
                        resident: true,
                    },
                ]);
            });
            await getAllGroups();
        };
        getStaffAndResidents();
    }, [page]);

    const handleOpenGroupsModal = (id: string) => () => {
        setEditId(id);
        setOpen(true);
    };
    const getAllGroups = async () => {
        setLoading(true);
        try {
            const fetchPage = searchQuery !== '' ? 1 : page;
            const data = await listPaginatedGroups({ pageSort: { page_no: fetchPage, page_size: pagelimit, sort: [{ DateAdded: 'asc' }] }, filters: searchQuery !== "" ? { Name: searchQuery } : {} });
            const hasMorePages = !(data.Result.length < pagelimit);
            setHasMorePages(hasMorePages);
            let sortedData = data.Result || [];
            // Sort alphabetically by AssetName if the array is not empty
            if (sortedData.length > 0) {
                sortedData = sortedData.sort((a, b) => a.Name.localeCompare(b.Name));
            }

            if (searchQuery === '') {
                if (page === 1) {
                    // For the first page, replace the entire grouplist with the sorted data
                    setGroupsList([...sortedData]);
                } else {
                    const uniqueData = sortedData.filter(newItem => !groupsList.some(existingItem => existingItem._id === newItem._id));
                    const updatedList = [...groupsList, ...uniqueData];
                    // Sort alphabetically if the array is not empty
                    if (updatedList.length > 0) {
                        setGroupsList(updatedList.sort((a, b) => a.Name.localeCompare(b.Name)));
                    } else {
                        setGroupsList(updatedList);
                    }
                }
            } else {
                // For search results, just set the sorted data
                setGroupsList([...sortedData]);
            }
        } catch (error) {
            toast.error(`Error while fetching groups ${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const closeConfirmDialog = () => {
        setConfirmDialog(false);
    };
    const deleteOneGroup = async () => {
        setLoading(true);
        setConfirmDialog(false);
        try {
            await deleteGroup(deleteId, deleteImageName);
            setGroupsList((prevGroupsList) => prevGroupsList.filter(group => group._id !== deleteId));
            toast.success('Group Deleted Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error in Deleting Group', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const handleOpenMembersModal = (group: Group) => {
        let residentArr: string[] = [];
        let staffArr: string[] = [];
        if (group.ResidentIds && group.ResidentIds.length > 0) {
            residentArr = group.ResidentIds;
        }
        if (group.StaffIds && group.StaffIds.length > 0) {
            staffArr = group.StaffIds;
        }
        const membersIdArr: string[] = [...residentArr, ...staffArr];
        let membersArr: Partial<Member>[] = [];
        membersIdArr.map((itemId) => {
            const memberObj = membersList.find((member) => member._id === itemId);
            if (memberObj) membersArr.push(memberObj);
        });
        setCurrentMembers(membersArr);
        setOpenMembers(true);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        // Trigger search when the search icon is clicked
        if (searchQuery === '') {
            setPageLimit(16);
        } else {
            setPageLimit(100);
        }
        setPage(1);
        getAllGroups();
    };

    return (
        <div>
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            <Button onClick={handleOpenGroupsModal('')}>Create Group</Button>
            <Input
                action={{ icon: 'search', onClick: handleSearchClick }}
                placeholder='Search group...'
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchClick();
                    }
                }}
            />
            <div className="groups-list-container">
                <Grid style={{ flexWrap: 'wrap', marginTop: '10px' }}>
                    {groupsList
                        ? groupsList.map((groupItem) => {
                            return (
                                <Grid.Column stretched key={groupItem._id} style={{ width: '25%' }}>
                                    <Card style={{ width: '100%' }}>
                                        <Card.Content>
                                            <Card.Header style={{ color: '#2987CD' }}>
                                                {groupItem.Name}
                                                <Icon
                                                    style={{ cursor: 'pointer', float: 'right' }}
                                                    name="edit outline"
                                                    onClick={handleOpenGroupsModal(
                                                        groupItem._id ? groupItem._id : '',
                                                    )}
                                                />
                                                <Icon
                                                    style={{ cursor: 'pointer', float: 'right', marginRight: '10px' }}
                                                    name="trash"
                                                    onClick={() => {
                                                        setDeleteId(groupItem._id ? groupItem._id : '');
                                                        setDeleteImageName(
                                                            groupItem.ImageName ? groupItem.ImageName : '',
                                                        );
                                                        setConfirmDialog(true);
                                                    }}
                                                    disabled={groupItem.CommunityGroup}
                                                />
                                            </Card.Header>
                                            <Card.Meta>{groupItem.GroupCategory}</Card.Meta>
                                            {groupItem.MinCap && <Card.Meta>Min Cap - {groupItem.MinCap}</Card.Meta>}
                                            {groupItem.MaxCap && <Card.Meta>Max Cap - {groupItem.MaxCap}</Card.Meta>}
                                            <Card.Meta>
                                                <Segment
                                                    style={{
                                                        width: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        margin: '10px 0px',
                                                    }}
                                                    onClick={() => handleOpenMembersModal(groupItem)}
                                                >
                                                    <span style={{ fontSize: '13px', color: 'black' }}>
                                                        Check Members
                                                    </span>
                                                </Segment>
                                            </Card.Meta>
                                            <Card.Meta>{groupItem.URL}</Card.Meta>
                                            <Card.Description>{groupItem.Description}</Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            );
                        })
                        : ''}
                </Grid>
                <LoadMore hasMore={hasMorePages} isLoading={loading} next={() => setPage((prev) => prev + 1)} />
                <Confirm
                    size="tiny"
                    open={confirmDialog}
                    content="Are you sure you want to delete this Group?"
                    onCancel={closeConfirmDialog}
                    onConfirm={deleteOneGroup}
                />
                <Modal size="mini" open={openMembers} onClose={() => setOpenMembers(false)}>
                    <Modal.Header>Members</Modal.Header>
                    <Modal.Content scrolling>
                        {currentMembers.length > 0
                            ? currentMembers.map((item) => {
                                return (
                                    <div key={item._id}>
                                        <p style={{ color: 'black', marginBottom: '5px' }}>
                                            {item.firstName} {item.lastName} - {item.staff ? 'Staff' : 'Resident'}
                                        </p>
                                    </div>
                                );
                            })
                            : 'No Members Available'}
                    </Modal.Content>
                </Modal>
            </div>
            {open === true && (
                <GroupsModal
                    open={open}
                    setOpen={setOpen}
                    editId={editId}
                    setEditId={setEditId}
                    membersList={membersList}
                    facilityId={profile ? profile.Facility : ''}
                    setLoading={setLoading}
                    updateGroupList={setGroupsList}
                    groupLists={groupsList}
                />
            )}
        </div>
    );
};

export default Groups;
