import API from './API'
import { Gadget } from '../types'

export async function getSmartGadget(a4hRoomId): Promise<Gadget[]> {
    const res = await API.lambdaPost('/smart-gadgets/get', { a4hRoomId })
    const gadgets = res as Gadget[];
    return gadgets;
}

export async function updateSmartGadget(a4hRoomId: string, updateValues: Partial<Gadget>): Promise<Partial<Gadget>> {
    const res = await API.lambdaPost('/smart-gadgets/update', { a4hRoomId, updateValues })
    const gadgets = res as Partial<Gadget>;
    return gadgets;
}