import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, List, Image, Modal } from 'semantic-ui-react'
import { Department } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchDepartments } from '../../../../services/Departments';
import CreateDeparment from "../Create"

const imagePlaceholder = `${process.env.PUBLIC_URL}/request_type_placeholder.png`

interface State {
    departments: Department[]
    error: string | null
    isFetching: boolean
    page: number
    count: number
    limit: number
    showModal: boolean
}

class DepartmentsList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            departments: [],
            error: null,
            isFetching: false,
            page: 0,
            count: 0,
            limit: 10000,
            showModal: false
        }
    }

    async loadData() {
        this.setState({
            isFetching: true,
            error: null,
        })

        try {
            const departments = await fetchDepartments()
            departments.sort((a, b) => a.Name.localeCompare(b.Name));
            this.setState({
                isFetching: false,
                departments,
                error: null,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Departments.'
            this.setState({
                isFetching: false,
                error: msg,
            })
        }
    }

    async componentDidMount() {
        await this.loadData()
    }

    navigateToCreate() {
        return this.props.history.push('/admin/forms/departments/create')
    }

    toggleModalHandler = () => {
        this.setState(prev => ({
            showModal: !prev.showModal
        }))
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/forms/department/${id}`)
    }

    get units() {
        return this.state.departments.map(d => (
            <List.Item key={d._id} onClick={() => this.goToEdit(d._id)}>
                <Image src={d.Icon || imagePlaceholder} avatar />
                <List.Content>
                    <List.Header as="a">{d.Name}</List.Header>
                    <List.Description as="a">{d.Code}</List.Description>
                </List.Content>
            </List.Item>
        ))
    }

    render() {
        return (
            <div className="DepartmentsList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.toggleModalHandler.bind(this)}
                />
                <List divided relaxed>
                    {this.units}
                </List>
                <Modal open={this.state.showModal} closeIcon closeOnEscape onClose={this.toggleModalHandler}>
                    <CreateDeparment onSubmitClick={() => {
                        this.loadData()
                        this.toggleModalHandler()
                    }} 
                    onCancelClick={() => {
                        this.toggleModalHandler()
                    }}
                     />
                </Modal>
            </div>
        )
    }
}

export default withRouter(DepartmentsList)
