import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Dimmer, Grid, Loader, Modal } from 'semantic-ui-react';
import ModalDocWidget from '../Service/Modals';
import { DocWidgetData } from '../../types/Service';
import { ListDocWidgetData } from '../../services/DocWidgets';
import { toast } from 'react-toastify';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    docId?: string; // If docId is passed then we are selecting a single doc
    setDocId?: React.Dispatch<React.SetStateAction<string>>;
    facilityId?: string;
    source?: string;
    multipleDocs?: string[]; // If multipleDocs is passed then we are selecting multiple docs
    setMultipleDocs?: React.Dispatch<React.SetStateAction<string[]>>;
}

const ListDocsModal: FC<Props> = ({ open, setOpen, docId, setDocId, facilityId, source, multipleDocs, setMultipleDocs }) => {
    const multipleDocsSelection = multipleDocs && setMultipleDocs ? true : false;
    const [createDocOpen, setCreateDocOpen] = useState<boolean>(false);
    const [selectedDocId, setSelectedDocId] = useState<string>('');
    const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
    const [docsList, setDocsList] = useState<DocWidgetData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [deletedDocCount, setDeletedDocCount] = useState<number>(0);
    const [data, setData] = useState<DocWidgetData>({
        Name: '',
        Access: [],
        Description: '',
        files: [],
    });

    useEffect(() => {
        if (multipleDocs) setSelectedDocs(multipleDocs);
        if (docId) setSelectedDocId(docId);
        getDocsData();
    }, []);

    const getDocsData = async () => {
        try {
            setLoading(true);
            const data = await ListDocWidgetData();
            setDocsList(data);
            const allDocIds = new Set(data.map(doc => doc._id));
            const selectedDocuments = multipleDocs || [docId];
            const deletedDocs = selectedDocuments.filter(id => !allDocIds.has(id));
            const deletedDocsCount = deletedDocs.length;
            if (multipleDocs) setSelectedDocs(multipleDocs.filter(id => !(deletedDocs.includes(id))));
            setDeletedDocCount(deletedDocsCount);
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error Listing docs', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const handleOpenCreateDoc = () => {
        setCreateDocOpen(true);
    };
    const handleSelectDoc = (id: string) => {
        if (multipleDocsSelection) { // If multiple docs selection is true then we use the array of selectedDocs else we use the selectedDocId
            if (selectedDocs.includes(id)) {
                setSelectedDocs(selectedDocs.filter(doc => doc !== id)); // Remove the doc from the array if it is selected again
            } else {
                setSelectedDocs([...selectedDocs, id]);
            }
        } else {
            if (id === selectedDocId) setSelectedDocId(''); // If the doc is already selected then we deselect it
            else setSelectedDocId(id);
        }
    };
    const handleDocsCancel = () => {
        setSelectedDocId('');
        setOpen(false);
    };
    const handleDocsSubmit = () => {
        if (multipleDocsSelection) { // If multiple docs selection is true then we use the array of selectedDocs else we use the selectedDocId
            if (selectedDocs.length > 0) {
                setMultipleDocs && setMultipleDocs(selectedDocs);
            }
        } else {
            if (selectedDocId) setDocId && setDocId(selectedDocId);
        }
        setOpen(false);
    };

    return (
        <div>
            <Modal size={'large'} open={open} onClose={handleDocsCancel}>
                <Dimmer inverted active={loading}>
                    <Loader active={loading} />
                </Dimmer>
                <Modal.Header>
                    <h2 style={{ textAlign: 'center' }}>Docs</h2>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Button onClick={handleOpenCreateDoc}>Create Doc</Button>
                        {deletedDocCount ? (
                            <p style={{ margin: 0, fontSize: '16px', color: 'orange' }}>
                                {deletedDocCount > 1 ? "Some of the selected Docs are" : "Selected Doc is"} deleted
                            </p>
                        ) : <></>}
                    </div>
                </Modal.Header>

                <Modal.Content style={{ maxHeight: '80vh' }} scrolling>
                    <Grid style={{ flexWrap: 'wrap' }}>
                        {docsList
                            ? docsList.map((doc) => {
                                  return (
                                      <Grid.Column stretched key={doc._id} style={{ width: '50%' }}>
                                          <Card
                                              style={{
                                                  cursor: 'pointer',
                                                  width: '100%',
                                                  backgroundColor: (multipleDocsSelection && doc._id && selectedDocs.includes(doc._id)) || (selectedDocId === doc._id) ? '#183466' : '',
                                              }}
                                              onClick={() => handleSelectDoc(doc._id || '')}
                                          >
                                              <Card.Content>
                                                  <Card.Header
                                                      style={{ color: (multipleDocsSelection && doc._id && selectedDocs.includes(doc._id)) || (selectedDocId === doc._id) ? 'white' : '' }}
                                                  >
                                                      {doc.Name}
                                                  </Card.Header>
                                                  <Card.Description
                                                      style={{ color: (multipleDocsSelection && doc._id && selectedDocs.includes(doc._id)) || (selectedDocId === doc._id) ? 'white' : '' }}
                                                  >
                                                      {doc.Description}
                                                  </Card.Description>
                                              </Card.Content>
                                          </Card>
                                      </Grid.Column>
                                  );
                              })
                            : ''}
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleDocsSubmit} color='blue'>Submit</Button>
                    <Button onClick={handleDocsCancel}>Cancel</Button>
                </Modal.Actions>
            </Modal>
            <ModalDocWidget
                open={createDocOpen}
                setOpen={setCreateDocOpen}
                data={data}
                setData={setData}
                isEdit={false}
                getDocsData={getDocsData}
                facilityId={facilityId}
                source={source || ""}
            />
        </div>
    );
};

export default ListDocsModal;
