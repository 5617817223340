import React from 'react'
import { Form, TextArea, Button, Message, Dropdown, Dimmer, Input } from 'semantic-ui-react';
import { PersonOfTheMonth, UserProfile } from '../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import AudioRecorder from '../../components/AudioRecorder'
import './style.less'
import DatePicker from 'react-datepicker'
import SignageContentImgCaptureAndDisplay from '../SignageContentImgCaptureAndDisplay'
import { ContentType } from '../../types/signage'
import moment from 'moment-timezone';
import { fetchOneFacility } from '../../services/Facilities';
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import ExcludeDatesField from '../ExcludeDatesField';

interface Props extends RouteComponentProps {
    isFetching: boolean
    isSaving: boolean
    editableActivity?: Partial<PersonOfTheMonth>
    handleSubmit: (potm) => any
    handleUpdate: (potm) => any
    edit?: boolean
    _id?: string
    sourcePage: string
    passAudioBlob?
    audioError?: string | null
    startDate?: string | null
    endDate?: string | null
    handleCancel?: () => any
    cancelEditActivity: (id?) => any
    copyItem?: any
    copyClickHandler?: (image: string | undefined) => void;
    profile: UserProfile | null
    modifyGroup?: boolean
    calendarType?: string[]
    allCalendarType?: {
        key: string;
        text: string;
        value: string;
    }[]
}

interface State {
    personOfTheMonth: Partial<PersonOfTheMonth>
    imageFile: File | null
    copyImageUrl: string | null
    signagePreviewImage: string | undefined
    repeatType?: string
    timezone?: string
    interval: number
    excludeDates: string[];
    datePickerMinDate: Date | null;
    datePickerMaxDate: Date | null;
    calendarType?: string[]
    allCalendarType?: {
        key: string;
        text: string;
        value: string;
    }[]
}

const modalTitles = {
    StaffOfTheMonth: 'Staff Spotlight',
    ResidentOfTheMonth: 'Resident Spotlight',
    DailyAffirmations: 'Community News',
    Birthdays: "Birthdays"
}
class POTMForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            personOfTheMonth: {
                name:
                    this.props.editableActivity && this.props.editableActivity.name
                        ? this.props.editableActivity.name
                        : this.props.copyItem
                        ? this.props.copyItem.name
                        : '',
                startDate: props.startDate ? props.startDate : '',
                endDate: props.endDate ? props.endDate : '',
                recurrence:
                    this.props.editableActivity && this.props.editableActivity.recurrence
                        ? this.props.editableActivity.recurrence
                        : undefined,
            },
            imageFile: null,
            copyImageUrl: null,
            signagePreviewImage: undefined,
            repeatType:
                this.props.editableActivity && this.props.editableActivity.recurrence
                    ? this.props.editableActivity.recurrence.split('FREQ=')[1].split(';')[0]
                    : 'OFF',
            timezone: 'America/New_York',
            interval:
                (this.props.editableActivity &&
                    this.props.modifyGroup &&
                    this.props.editableActivity.recurrence &&
                    this.props.editableActivity.recurrence.split('FREQ=')[1] &&
                    !isNaN(
                        Number(
                            this.props.editableActivity.recurrence
                                .split('FREQ=')[1]
                                .split(';')[0]
                        )
                    ) &&
                    Number(
                        this.props.editableActivity.recurrence
                            .split('FREQ=')[1]
                            .split(';')[0]
                    )) ||
                1,
            excludeDates: this.getExcludeDatesFromRecurrence(this.props),
            datePickerMinDate: this.getDatePickerMinDate(this.props),
            datePickerMaxDate: this.getDatePickerMaxDate(this.props),
            calendarType: [],
            allCalendarType: this.props.allCalendarType || []
        };
    }

    getExcludeDatesFromRecurrence(props) {
        if (props.editableActivity && props.modifyGroup && props.editableActivity.recurrence) {
            const recurrence = props.editableActivity.recurrence;
            const exDatesStringFromRecurrence = recurrence.split('EXDATE:')[1];
            const exDatesArrayFromRecurrence = exDatesStringFromRecurrence ? exDatesStringFromRecurrence.split(',').filter(x => x) : [];
            const formattedDatesArray = exDatesArrayFromRecurrence.map((dateString) => {
                return `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
            });
            return formattedDatesArray;
        }
        return [];
    }

    getDatePickerMinDate(props) {
        const startDate = props.editableActivity && props.editableActivity.startDate;
        const endDate = (props.editableActivity && props.editableActivity.recurrence && props.modifyGroup)
            ? moment(
                props.editableActivity.recurrence
                    .split('UNTIL=')[1]
                    .split('\n')[0]
                    .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
            )
                .set({
                    hour: moment(props.startDate).format('HH'),
                    minute: moment(props.startDate).format('mm'),
                    second: moment(props.startDate).format('ss'),
                })
                .format('YYYY-MM-DDTHH:mm:ss')
            : undefined;
        if (startDate && endDate) {
            return moment(startDate).add(1, 'day').toDate();
        }
        return null;
    }

    getDatePickerMaxDate(props) {
        const startDate = props.editableActivity && props.editableActivity.startDate;
        const endDate = (props.editableActivity && props.editableActivity.recurrence && props.modifyGroup)
            ? moment(
                props.editableActivity.recurrence
                    .split('UNTIL=')[1]
                    .split('\n')[0]
                    .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
            )
                .set({
                    hour: moment(props.startDate).format('HH'),
                    minute: moment(props.startDate).format('mm'),
                    second: moment(props.startDate).format('ss'),
                })
                .format('YYYY-MM-DDTHH:mm:ss')
            : undefined;
        if (startDate && endDate) {
            return moment(endDate).subtract(1, 'day').toDate();
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.personOfTheMonth.startDate !== prevState.personOfTheMonth.startDate ||
            this.state.personOfTheMonth.endDate !== prevState.personOfTheMonth.endDate
        ) {
            this.updateDatePickerRange();
        }
    }

    updateDatePickerRange() {
        const { startDate, endDate } = this.state.personOfTheMonth;

        if (startDate && endDate) {
            const datePickerMinDate = moment(startDate).add(1, 'day').toDate();
            const datePickerMaxDate = moment(endDate).subtract(1, 'day').toDate();

            this.setState({ datePickerMinDate, datePickerMaxDate }, () => {
                this.updateExcludeDates(datePickerMinDate, datePickerMaxDate);
            });
        }
    }

    updateExcludeDates(datePickerMinDate: Date, datePickerMaxDate: Date) {
        const updatedExDates = this.state.excludeDates.filter((date) => {
            return moment(date).isBetween(datePickerMinDate, datePickerMaxDate, 'day', '[]');
        });

        this.setState({ excludeDates: updatedExDates });
    }

    setPOTMName(name: string) {
        this.setState({
            personOfTheMonth: {
                ...this.state.personOfTheMonth,
                name,
            },
        });
    }

    padLeadingZero(num) {
        if (num < 10) {
            return String(num).padStart(2, '0');
        }
        return num;
    }

    setPOTMDate(date: Date, type: 'start' | 'end') {
        const dateString = `${date.getFullYear()}-${this.padLeadingZero(date.getMonth() + 1)}-${this.padLeadingZero(
            date.getDate(),
        )}`;

        console.log('date', date);
        console.log('dateString', typeof dateString, dateString);
        console.log(this.state.personOfTheMonth.recurrence);
        if (type === 'start') {
            this.setState(
                {
                    personOfTheMonth: {
                        ...this.state.personOfTheMonth,
                        startDate: dateString,
                    },
                },
                () => {
                    this.generateRecurrence();
                },
            );
        } else {
            this.setState(
                {
                    personOfTheMonth: {
                        ...this.state.personOfTheMonth,
                        endDate: dateString,
                    },
                },
                () => {
                    this.generateRecurrence();
                },
            );
        }
    }

    validForm() {
        return (
            this.state.personOfTheMonth.name &&
            this.state.personOfTheMonth.startDate &&
            this.state.personOfTheMonth.endDate &&
            this.state.personOfTheMonth.startDate <= this.state.personOfTheMonth.endDate &&
            this.state.personOfTheMonth && this.state.personOfTheMonth.calendarType && this.state.personOfTheMonth.calendarType.length > 0 
        );
    }

    generateRepeatOption() {
        const off = {
            key: 'Off',
            text: 'Repeat off',
            value: 'OFF',
        };
        const daily = {
            key: 'Daily',
            text: 'Repeat daily',
            value: 'DAILY',
        };
        const weekly = {
            key: 'Weekly',
            text: 'Repeat weekly',
            value: 'WEEKLY',
        };
        const monthly = {
            key: 'Monthly',
            text: 'Repeat monthly',
            value: 'MONTHLY',
        };
        return [off, daily, weekly, monthly];
    }

    generateRecurrence() {
        const formatDate = (dateString: string) => {
            const [year, month, day] = dateString.split('-');
            return `${year}${month}${day}`;
        };
        const dtstart =
            this.state.personOfTheMonth.startDate && this.state.personOfTheMonth.startDate.split('-').join('');
        const until = this.state.personOfTheMonth.endDate && this.state.personOfTheMonth.endDate.split('-').join('');
        const frequency = this.state.repeatType;
        const interval = this.state.interval;
        const excludeDatesString = this.state.excludeDates.length && this.state.excludeDates.map(formatDate).join(',');
        const recurrence =
            dtstart &&
            until &&
            frequency !== 'OFF' &&
            `DTSTART=${dtstart}\n` + `RRULE:FREQ=${frequency};INTERVAL=${interval};UNTIL=${until}${excludeDatesString ? `\nEXDATE:${excludeDatesString}` : ''}`;
        if (this.props.editableActivity) {
            this.setState({
                personOfTheMonth: {
                    ...this.props.editableActivity,
                    name: this.state.personOfTheMonth.name,
                    startDate: this.state.personOfTheMonth.startDate,
                    endDate: this.state.personOfTheMonth.endDate,
                    recurrence: recurrence ? recurrence : undefined,
                },
            });
        } else {
            this.setState({
                personOfTheMonth: {
                    ...this.state.personOfTheMonth,
                    recurrence: recurrence ? recurrence : undefined,
                },
            });
        }
    }

    async handleSubmit() {
        if (this.props.edit) {
            // const personOfTheMonth = this.state.personOfTheMonth
            // personOfTheMonth._id = this.props._id
            this.props.handleUpdate({
                ...this.state.personOfTheMonth,
                _id: this.props._id,
                imageFile: this.state.imageFile,
                copyImageUrl: this.state.copyImageUrl,
            });
        } else {
            this.props.handleSubmit({
                ...this.state.personOfTheMonth,
                imageFile: this.state.imageFile,
                copyImageUrl: this.state.copyImageUrl,
            });
        }
        this.setState({
            personOfTheMonth: {
                name: '',
                startDate: '',
                endDate: '',
                recurrence: undefined,
                calendarType: []
            },
            repeatType: 'OFF',
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.copyItem &&
            nextProps.copyItem !== prevState.copyItem &&
            nextProps.copyItem.name !== prevState.personOfTheMonth.name &&
            nextProps.startDate &&
            nextProps.endDate
        ) {
            return {
                personOfTheMonth: {
                    name: nextProps.copyItem.name,
                    audioId: nextProps.copyItem.audioId,
                    playableUrl: nextProps.copyItem.playableUrl,
                    startDate: nextProps.startDate,
                    endDate: nextProps.endDate,
                },
                copyItem: nextProps.copyItem,
            };
        }
        return prevState;
    }

    async componentDidMount() {
        if (this.props.editableActivity && this.props.editableActivity.calendarType && this.props.editableActivity.calendarType.length > 0) {
            const activityCalendarType = this.props.editableActivity.calendarType;
            this.setState({
                calendarType: activityCalendarType,
                personOfTheMonth: {
                    ...this.state.personOfTheMonth,
                    calendarType: activityCalendarType
                }
            });
        } else if (this.props.calendarType && !this.props.editableActivity) {
            const calendarType = this.props.calendarType || [];
            this.setState({
                calendarType: calendarType,
                personOfTheMonth: {
                    ...this.state.personOfTheMonth,
                    calendarType: calendarType
                }
            });
        }
        const facilityId = this.props.profile && this.props.profile.Facility;
        const facilityData: any = (facilityId && await fetchOneFacility(facilityId)) || {};
        const FacilityTimeZone = facilityData.FacilityTimeZone || 'America/New_York';
        this.setState({
            timezone: FacilityTimeZone,
        });
        this.generateRecurrence();
    }

    handleCancel(e) {
        e.preventDefault();
        if (this.props.edit) {
            this.props.cancelEditActivity(this.props._id);
        } else {
            this.props.cancelEditActivity();
            this.setState({
                personOfTheMonth: {
                    name: '',
                    startDate: '',
                    endDate: '',
                    recurrence: undefined,
                },
                repeatType: 'OFF',
            });
        }
    }

    handleFileChange(file: File) {
        this.setState({
            imageFile: file,
        });
    }

    handleCopyImage(url: string) {
        // supported file extensions
        const fileExtensions = ['.jpg', '.png'];
        const regex = new RegExp(`(${fileExtensions.join('|')})\\b(?!.*\\1)`);
        const match = url.match(regex);

        if (match && match.index) {
            // If a supported extension is found, truncate the URL at that point
            const truncatedUrl = url.substring(0, match.index + match[0].length);
            this.setState({
                copyImageUrl: truncatedUrl,
            });
        } else {
            // If no supported extension is found, use the original URL
            this.setState({
                copyImageUrl: url,
            });
        }
    }

    clearSignageContentState() {
        this.setState({
            copyImageUrl: null,
            imageFile: null,
        });
    }

    getAndStorePreviewSignageImg(img: string | undefined) {
        this.setState({
            signagePreviewImage: img,
        });
    }

    getContentType(): ContentType {
        if (this.props.sourcePage === 'StaffOfTheMonth') {
            return 'SOTM';
        } else if (this.props.sourcePage === 'ResidentOfTheMonth') {
            return 'ROTM';
        } else if (this.props.sourcePage === 'DailyAffirmations') {
            return 'communityMessages';
        } else {
            throw new Error('Content type is not provided');
        }
    }

    get intervalType() : string {
        const interval = this.state.interval;
        const correctGrammar = (value: string) => interval > 1 ? value + 's' : value;
        switch (this.state.repeatType) {
            case 'DAILY':
                return correctGrammar('Day')
            case 'WEEKLY':
                return correctGrammar('Week')
            case 'MONTHLY':
                return correctGrammar('Month')
            default:
                return '';
        }
    }

    renderSaveButton() {
        const btn = (
            <Button
                type="submit"
                primary
                loading={this.props.isSaving}
                disabled={this.props.isSaving || !this.validForm()}
            >
                Save
            </Button>
        );
        return btn;
    }

    render() {
        return (
            <Form loading={this.props.isFetching} autoComplete="false" onSubmit={this.handleSubmit.bind(this)}>
                <div className="audio-or-text">
                    {this.props.copyItem ? (
                        ''
                    ) : (
                        <h3 className="text-center">
                            {this.props.editableActivity ? 'Update' : 'Create'} your{' '}
                            {modalTitles[this.props.sourcePage]}
                        </h3>
                    )}
                    {this.props.copyItem ? (
                        <h3 className="text-center">Create your {modalTitles[this.props.sourcePage]}</h3>
                    ) : (
                        ''
                    )}
                    <div className="audio-or-text-header">
                        <span>Record your voice</span>
                    </div>
                    <div className="audio-recorder">
                        {this.props.passAudioBlob ? <AudioRecorder passAudioBlob={this.props.passAudioBlob} /> : <></>}
                        {this.props.editableActivity && this.props.editableActivity.playableUrl ? (
                            <div className="current-audio">
                                <p>To change your audio, press the Mic</p>
                                <p>
                                    <br />
                                    Current Audio
                                </p>
                                <audio controls src={this.props.editableActivity.playableUrl}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                                </audio>
                            </div>
                        ) : (
                            <></>
                        )}

                        {this.props.copyItem && this.props.copyItem.playableUrl ? (
                            <div className="current-audio">
                                <p>To change your audio, press the Mic</p>
                                <p>
                                    <br />
                                    Current Audio
                                </p>
                                <audio controls src={this.props.copyItem.playableUrl}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                                </audio>
                            </div>
                        ) : (
                            <></>
                        )}

                        <Form.Field required>
                            <div className='req-label-cont'>
                                <label className="required-label mt10">{this.props.sourcePage === "DailyAffirmations" ? "Community News" : "Name"}</label>
                            </div>
                            <TextArea
                                style={{ border: '1px solid #183466', marginTop: '12px' }}
                                value={this.state.personOfTheMonth.name || ''}
                                type="textarea"
                                placeholder="Enter and save your text here"
                                onChange={(e) => this.setPOTMName.call(this, e.currentTarget.value)}
                            />
                        </Form.Field>
                    </div>
                </div>

                {this.props.audioError && <Message negative>{this.props.audioError}</Message>}

                <Form.Field style={{ width: '300px', marginTop: '17px' }} required>
                    <div className='req-label-cont'>
                        <label className="required-label mb5"> Start date</label>
                    </div>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            style={{ border: '1px solid #183466' }}
                            placeholderText="Select Date"
                            dateFormat="MMMM d, Y"
                            selectsStart
                            selected={
                                this.state.personOfTheMonth.startDate &&
                                moment.tz(this.state.personOfTheMonth.startDate, this.state.timezone).toDate()
                            }
                            onChange={(date) => this.setPOTMDate(date, 'start')}
                            startDate={
                                this.state.personOfTheMonth.startDate &&
                                moment.tz(this.state.personOfTheMonth.startDate, this.state.timezone).toDate()
                            }
                            endDate={
                                this.state.personOfTheMonth.endDate &&
                                moment.tz(this.state.personOfTheMonth.endDate, this.state.timezone).toDate()
                            }
                        />
                    </div>
                </Form.Field>

                <Form.Field style={{ width: '300px' }} required>
                    <div className='req-label-cont'>
                        <label className="required-label mb5"> End date</label>
                    </div>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            style={{ border: '1px solid #183466' }}
                            placeholderText="Select End Date"
                            dateFormat="MMMM d, Y"
                            selectsEnd
                            selected={
                                this.state.personOfTheMonth.endDate &&
                                moment.tz(this.state.personOfTheMonth.endDate, this.state.timezone).toDate()
                            }
                            onChange={(date) => this.setPOTMDate(date, 'end')}
                            startDate={
                                this.state.personOfTheMonth.startDate &&
                                moment.tz(this.state.personOfTheMonth.startDate, this.state.timezone).toDate()
                            }
                            endDate={
                                this.state.personOfTheMonth.endDate &&
                                moment.tz(this.state.personOfTheMonth.endDate, this.state.timezone).toDate()
                            }
                            minDate={
                                this.state.personOfTheMonth.startDate &&
                                moment.tz(this.state.personOfTheMonth.startDate, this.state.timezone).toDate()
                            }
                        />
                    </div>
                </Form.Field>
                {
                    (this.props.allCalendarType && this.props.allCalendarType.length > 0 || this.props.editableActivity && this.props.editableActivity.calendarType) && (
                        <Form.Field style={{ width: '300px' }} required={true}>
                            <div className='req-label-cont'>
                                <label className="required-label mb5"> Calendar Type</label>
                            </div>
                            <Dropdown
                                placeholder="Select Room Type"
                                fluid
                                multiple
                                selection
                                options={this.props.allCalendarType}
                                value={this.state.calendarType}
                                onChange={(e, d) => {
                                    this.setState({
                                        calendarType: d.value as string[],
                                        personOfTheMonth: {
                                            ...this.state.personOfTheMonth,
                                            calendarType: d.value as string[]
                                        }
                                    });
                                }}
                            />
                        </Form.Field>
                    )
                }
                {this.props.sourcePage === 'DailyAffirmations' && (
                    <Form.Field style={{ width: '300px' }}>
                        <Dropdown
                            disabled={
                                this.props.editableActivity &&
                                !this.state.personOfTheMonth.recurrence &&
                                this.state.personOfTheMonth.startDate !== this.state.personOfTheMonth.endDate
                                    ? true
                                    : false
                            }
                            placeholder="Repeats"
                            defaultValue={this.state.repeatType}
                            fluid
                            selection
                            options={this.generateRepeatOption()}
                            onChange={(e, d) => {
                                this.setState(
                                    {
                                        repeatType: String(d.value),
                                    },
                                    () => {
                                        this.generateRecurrence();
                                    },
                                );
                                console.log(this.state.personOfTheMonth.recurrence);
                            }}
                        />
                    </Form.Field>
                )}
                {
                    this.props.sourcePage === 'DailyAffirmations' && 
                    this.state.repeatType && 
                    this.state.repeatType !== 'OFF' && 
                    (
                        <Form.Field inline>
                            <label>Once every</label>
                            <Input
                                type='number'
                                min={1}
                                value={this.state.interval}
                                onChange={(e, d) => {
                                    const interval = d.value;
                                    if (interval && Number(interval) > 0) {
                                        this.setState({
                                            interval: Number(interval),
                                        }, () => this.generateRecurrence());
                                    }
                                }}
                            >
                            </Input>
                            <label style={{marginLeft: '10px'}}>{this.intervalType}</label>
                        </Form.Field>
                    )  
                }
                {
                    this.props.sourcePage === 'DailyAffirmations' &&
                    this.state.repeatType &&
                    this.state.repeatType !== 'OFF' &&
                    this.state.personOfTheMonth.endDate &&
                    this.state.personOfTheMonth.startDate &&
                    (() => {
                        const startDate = new Date(this.state.personOfTheMonth.startDate);
                        const endDate = new Date(this.state.personOfTheMonth.endDate);
                        const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;

                        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime()) && (endDate.getTime() - startDate.getTime()) >= twoDaysInMilliseconds) {
                            return (
                                <ExcludeDatesField
                                    excludeDates={this.state.excludeDates}
                                    setExcludeDates={(excludeDates: string[]) => this.setState({ excludeDates })}
                                    datePickerMinDate={this.state.datePickerMinDate}
                                    datePickerMaxDate={this.state.datePickerMaxDate}
                                    generateRecurrence={() => {this.generateRecurrence()}}
                                />
                            );
                        }
                        return null;
                    })()
                }

                <div style={{ position: 'relative' }}>
                    <Dimmer active={(!this.props.modifyGroup && this.props.editableActivity && this.props.editableActivity.srcId && (this.props.editableActivity._id === this.props.editableActivity.srcId)) || false} inverted></Dimmer>
                    <Form.Field>
                        <SignageContentImgCaptureAndDisplay
                            handleFileChange={this.handleFileChange.bind(this)}
                            contentId={
                                this.props.editableActivity && this.props.editableActivity.signageImageId
                                    ? this.props.editableActivity.signageImageId
                                    : this.props._id
                            }
                            contentType={this.getContentType()}
                            galleryImageClickHandler={this.handleCopyImage.bind(this)}
                            clearSignageState={this.clearSignageContentState.bind(this)}
                            getFetchedPreviewImage={this.getAndStorePreviewSignageImg.bind(this)}
                            title={this.state.personOfTheMonth.name ? this.state.personOfTheMonth.name : ''}
                            subTitle={
                                this.props.sourcePage ?
                                    this.props.sourcePage === "DailyAffirmations" ? "Community News" :
                                        this.props.sourcePage === "ResidentOfTheMonth" ? "Resident Spotlight" :
                                            this.props.sourcePage === "StaffOfTheMonth" ? "staff of the month" : "" : ""
                            }
                        />
                    </Form.Field>
                </div>
                {this.renderSaveButton()}

                <Button primary disabled={this.props.isSaving} onClick={this.handleCancel.bind(this)}>
                    Cancel
                </Button>
                {this.props.copyClickHandler && (
                    <Button
                        type="button"
                        onClick={() => {
                            if (this.props.copyClickHandler) {
                                this.props.copyClickHandler(this.state.signagePreviewImage);
                            }
                        }}
                        className="copyButton"
                    >
                        Copy
                    </Button>
                )}
            </Form>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

export default withRouter(connect(mapStateToProps)(POTMForm));