import React from 'react';
import { Segment } from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { RequestInstance } from '../../types/RequestInstance';
import moment from 'moment';
import { formatDateWithTZ } from '../../util/timezone';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
interface Props {
    requests: Partial<RequestInstance>[];
    unassigned: boolean;
    handleClick: (id: string) => void;
    changeFilter: (startDate: string, endDate: string) => void;
}
interface EventData {
    id: string;
    title: string;
    start: string;
    end: string;
  }
function RequestCalenderView(props: Props) {

    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });

    const facilityTZ = profile && profile.FacilityTimeZone || "";

    const eventsFormater = (): EventData[] => {
        return props.requests.map((request) => {
            const formattedData = {
                id: request._id || "",
                title: request.Name || "",
                start: (request.ScheduledTime && request.ScheduledTime.startDate) || request.RequestedTime && formatDateWithTZ(request.RequestedTime, 'YYYY-MM-DDTHH:mm:ss', facilityTZ) || "",
                end: (request.ScheduledTime && request.ScheduledTime.endDate) || request.RequestedTime && formatDateWithTZ(request.RequestedTime, 'YYYY-MM-DDTHH:mm:ss', facilityTZ) || ""
            };
            return formattedData;
        });
    };
    const handleEventClick = (info) => {
        props.handleClick(info.event._def.publicId);
    };
    const handleMonthChange = (arg) => {
        const { start, end } = arg;
        const startDate = moment(start).format('YYYY-MM-DDTHH:mm:ss');
        const endDate = moment(end).format('YYYY-MM-DDTHH:mm:ss');
        props.changeFilter(startDate, endDate);
    };

    return (
        <div>
            <Segment>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    editable={false}
                    events={eventsFormater() || []}
                    datesSet={handleMonthChange}
                    customButtons={{}}
                    headerToolbar={{
                        right: 'prev,next',
                    }}
                    eventClick={handleEventClick}
                    displayEventTime={false}
                    showNonCurrentDates={false}
                />
            </Segment>
        </div>
    );
}

export default RequestCalenderView;