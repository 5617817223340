import React from 'react';
import './style.less';
import { Popup } from 'semantic-ui-react';

const Icon = ({
    imgSrc,
    type,
    height = 25,
    width = 25,
    click,
    mouseEnter,
    mouseLeave,
    disabled = false,
}) => {
    // const  capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    return disabled ? (
        <Popup
            content={`Coming soon`}
            trigger={
                <span>
                    <img src={imgSrc} height={height} width={width} />
                </span>
            }
        />
    ) : (
        <Popup
            content={`Toggle ${type} alert`}
            trigger={
                <img
                    src={imgSrc}
                    height={height}
                    width={width}
                    onClick={click}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                />
            }
        />
    );
};

export default Icon;
