import API from './API'

import { MenuType } from '../types'

export async function newMenu(menu: MenuType): Promise<any> {
    const response = await API.lambdaPost('/menu/add', menu)
    return response
}

export async function fetchMenuItems(page_no = 1, page_size = 10, type, filters = {}): Promise<any> {
    const params: any = {
        Filter: {...filters },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ timestamp: type === 'active' ? 'desc' : 'asc' }],
        },
    }

    if (type) {
        const date = new Date()
        if (type === 'active') {
            params.Filter.timestamp = {
                $gte: new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime(),
            }
        } else if (type === 'history') {
            params.Filter.timestamp = {
                $lt: new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime(),
            }
        }
    }

    const response = await API.lambdaPost('/menu/list', params)

    return response
}

export async function deleteMenu(id: string): Promise<void> {
    const response = await API.lambdaPost('/menu/delete', {
        id
    })
    return response
}

export async function deleteRecurringMenuGroup(data: object): Promise<void> {
    const response = await API.lambdaPost('/menu/delete', data);
    return response;
}

export async function updateMenu(menu: Partial<MenuType>): Promise<any> {
    const response = await API.lambdaPut('/menu/update', menu)
    return response
}

export async function updateMenuAttendance(menu: Partial<MenuType>): Promise<any> {
    const response = await API.lambdaPut('/menu/update', menu)
    return response
}

export async function createMenuAttendee(menuAttendeeObj) {
    return await API.lambdaPost('/menu/attendees/add', menuAttendeeObj)

}

export async function listMenuAttendee(id, isSvcInstanceId = false) {
    const params = {};
    if (isSvcInstanceId) {
        params['svcInstanceId'] = id;
    } else {
        params['menuId'] = id;
    }
    const response = await API.lambdaPost('/menu/attendees/list', params)
    return response
}


export async function updateMenuAttendee(id, status) {
    const params = {
        attendeeObjId: id,
        status
    }
    const response = await API.lambdaPut('/menu/attendees/update', params)
    return response
}

export async function updateMenuAttendeeDeclineReason(id, status, declineReason) {
    const params = {
        attendeeObjId: id,
        status,
        declineReason
    }
    const response = await API.lambdaPut('/menu/attendees/update', params)
    return response
}

export async function updateMenuAttendeeDeliveryReason(id, status, deliveryReason) {
    const params = {
        attendeeObjId: id,
        status,
        deliveryReason
    }
    const response = await API.lambdaPut('/menu/attendees/update', params)
    return response
}

export async function deleteMenuAttendee(id: string) {
    const response = await API.lambdaPut('/menu/attendees/delete', { _id: id });
    return response;
}
