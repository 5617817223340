import API from './API'

import { UniversityTraining } from '../types'

export async function newStandardTraining(universityTraining: UniversityTraining): Promise<void> {
    const response = await API.lambdaPost('/university/standard-trainings/add', universityTraining)
    return response
}

export async function getStandardTrainings(
    _id,
    page_no: string | number | undefined = 1,
    page_size = 5,
    filters: any = null,
): Promise<any> {
    if (_id == null || _id == undefined) {
        const params = {
            QueryOptions: {
                page_no,
                page_size,
                //sort: [{ topic: 'desc' }],
            },
        }
        if (filters) {
            params['Filter'] = { ...filters }
        }
        const response = await API.lambdaPost('/university/standard-trainings/list', params)
        return response
    } else {
        const queryStringParam = { _id }
        const response = await API.lambdaGet('/university/standard-trainings/list', queryStringParam)
        return response
    }
}

export async function deleteStandardTraining(_id): Promise<any> {
    const queryStringParam = { _id }
    const response = await API.lambdaDeleteById('/university/standard-trainings/delete', queryStringParam)
    return response
}

export async function updateStandardTraining(universityTraining: UniversityTraining): Promise<void> {
    const response = await API.lambdaPut('/university/standard-trainings/update', universityTraining)
    return response
}
