import { Notepad } from '../types/Notepad';
import { fetchAllPaginatedData } from '../util';
import API from './API'
export async function createNotepadEvent(notepadEntry: Partial<Notepad>) {
    const response = await API.lambdaPost('/notepad/add', notepadEntry)
    if (!response) throw new Error("Could not create a notepad entry")
    return response
}

export async function updateNotepadEvent(notepadEntry: Partial<Notepad>) {
    const { registrantNames = [], staffName = "",residentInfo = [], ...payloadWithoutNamesAndStaff } = notepadEntry;
    const response = await API.lambdaPost('/notepad/update', payloadWithoutNamesAndStaff);
    if (!response) throw new Error("Could not update the notepad entry");
    return response;
}

export async function fetchAllFacilityNotepadEvents(startDate: string, endDate: string, includedRegistrantAndStaffInfo: boolean = false, searchTerm?: string) {
    const params = {
        Filter: {
            startDate,
            endDate,
            includedRegistrantAndStaffInfo
        },
    };
    if (searchTerm) {
        params["searchTerm"] = searchTerm;
    }

    const residentCalendarApiCall = async (params: any) => {
        return await API.lambdaPost('/notepad/list', params);
    };
    const residentCalendarEvents = await fetchAllPaginatedData(residentCalendarApiCall, params);
    if (!residentCalendarEvents) throw new Error('Cannot fetch notepad entries');
    return residentCalendarEvents as Notepad[];
}

export async function fetchPaginatedNotepadData(startDate: string, endDate: string, includedRegistrantAndStaffInfo: boolean = false, searchTerm?: string, pageSort?: { page_no: string, page_size: string }) {
    const params = {
        Filter: {
            startDate,
            endDate,
            includedRegistrantAndStaffInfo, 
            searchTerm
        },
        pageSort
    };

    return await API.lambdaPost('/notepad/list', params);
}

export async function fetchAllResidentCalendarEventsFromResidentIds(registrantIds: string[], startDate: string, endDate: string, sort?: any[]) {
    const params = {
        Filter: {
            registrantIds,
            startDate,
            endDate,
        },
    };
    const sorting = sort && Array.isArray(sort) ? sort : undefined;
    // fetch paginated Resident Calendar Events
    const residentCalendarApiCall = async (params: any) => {
        return await API.lambdaPost('/notepad/list', params);
    };
    const residentCalendarEvents = await fetchAllPaginatedData(residentCalendarApiCall, params, sorting);
    if (!residentCalendarEvents) throw new Error('Cannot fetch notepad entries');
    return residentCalendarEvents as Partial<Notepad>[];

}
export async function deleteNotepadEvent(_id: string) {
    const response = await API.lambdaPost('/notepad/delete', {
        _id
    })
    if (!response) throw new Error("Could not delete the notepad entry")
    return response
}

export async function fetchNotepadEvent(registrantId: string | null, startDate: string, endDate: string, residentCalendarId: string = "") {
    const Filter = {
        registrantId,
        startDate,
        endDate,
    }
    if (residentCalendarId) {
        Filter["_id"] = residentCalendarId;
    }

    const response = await API.lambdaPost('/notepad/list', { Filter })
    if (!response || !response.Result) throw new Error("Cannot fetch notepad entries")

    return response.Result
}