import React from 'react'
import { Form, Message } from 'semantic-ui-react'
import { FieldProps } from 'formik'
import DataSourceDropdown from '../../DataSourceDropdown'
import DataSourceDropdownMultiple from '../../DataSourceDropdownMultiple'

interface CustomProps {
    required?: boolean
    data: any[]
    labelFields: string[]
    imageField?: string
    placeholder: string
    loading?: boolean
    disabled?: boolean
    valueField?: string
    multiple?: boolean
}

const GenericDataSourceDropdown: React.FC<any> = (props: FieldProps<any> & CustomProps) => {
    const setFieldValue = (selectedItems: any) => {
        const { valueField } = props
        if (!props.multiple) {
            const valueToSet = valueField ? selectedItems[valueField] : selectedItems
            props.form.setFieldValue(props.field.name, valueToSet)
        } else {
            const valueToSet = valueField ? selectedItems.map((s: any) => s[valueField]) : selectedItems
            props.form.setFieldValue(props.field.name, valueToSet)
        }
    }

    const getFieldValue = () => {
        if (!props.valueField) return props.field.value
        if (!props.multiple)
            return props.data.find(item => props.valueField && item[props.valueField] === props.field.value)
        return props.data.filter(item => props.valueField && item[props.valueField] === props.field.value)
    }

    const errMsg = (
        <Message error>
            <p>{props.form.errors[props.field.name]}</p>
        </Message>
    )
    const hasError = props.form.errors[props.field.name] && props.form.touched[props.field.name] ? true : false

    return (
        <Form.Field required={props.required || false} error={hasError}>
            <label>{props.placeholder}</label>
            {!props.multiple && (
                <DataSourceDropdown {...props} onSelectionChange={setFieldValue} selected={getFieldValue()} />
            )}
            {props.multiple && (
                <DataSourceDropdownMultiple {...props} onSelectionChange={setFieldValue} selected={getFieldValue()} />
            )}
            {hasError && errMsg}
        </Form.Field>
    )
}

export default GenericDataSourceDropdown
