import { Dispatch, useCallback, useEffect, useReducer, useState } from 'react'
import { listResidentDietery } from '../../services/Registrants';
import {DietaryState} from "../../types/Dietary"


export interface FetchDataAction {
    type: "SET_DATA",
    data: DietaryState
}

export interface HandleChange {
    type: "HANDLE_CHANGE",
    category: "goals" | "restrictions",
    key: string,
    value: boolean | number | string
}

export interface ResetData {
    type: "RESET_DATA",
    fetchedData: DietaryState
}

export const dieteryReducer = (state: DietaryState, action: FetchDataAction | HandleChange | ResetData) => {
    switch (action.type) {
        case 'HANDLE_CHANGE':
            return {
                ...state,
                [action.category]: {
                    ...state[action.category],
                    [action.key]: action.value
                }
            }
        case "SET_DATA":
            return {
                ...action.data
            }
        case "RESET_DATA":
            return {
                ...action.fetchedData
            }
        default:
            return state
    }
}
export const initialState: DietaryState = {
    goals: {
        vegetables: '',
        fruits: '',
        grains: '',
        dairy: '',
        proteins: '',
        oils: '',
        other: '',
    },
    restrictions: {
        lowSodium: false,
        diabetic: false,
        lowCarb: false,
        clearLiquid: false,
        pureed: false,
        mechanicalSoft: false,
        thickenedLiquid: false,
        nutAllergy: false,
        glutenIntolerance: false,
        lactoseIntolerance: false,
        vegan: false,
        vegetarian: false,
        flexeterian: false,
        pescaterian: false,
        lactoVegeterian: false,
        noPork: false,
        noMeat: false,
        noFish: false,
        noShellfish: false,
        kosher: false,
    }
};

const useDietary = (residentId: string): [DietaryState, Dispatch<FetchDataAction | HandleChange | ResetData>, boolean, () => void, Dispatch<React.SetStateAction<DietaryState>>, DietaryState] => {
    const [loading, setLoading] = useState(false)
    const [dieteryState, dispatch] = useReducer(dieteryReducer, initialState)
    const [fetchedData, setFetchedData] = useState(initialState)
    useEffect(() => {
        (async () => {
            setLoading(true)
            const res = await listResidentDietery(residentId)
            const dataNotFound = Object.keys(res).includes("dataNotFound")
            if (!dataNotFound) {
                dispatch({
                    type: "SET_DATA",
                    data: res
                })
                setFetchedData(res)
            }
            setLoading(false)
        })()
    }, [])
    // memoize resetData function using useCallBack as it not nessary to create the fucntion on every state change 
    const resetData = useCallback(() => {
        dispatch({
            type: "RESET_DATA",
            fetchedData
        })
    }, [fetchedData])
    return [dieteryState, dispatch, loading, resetData, setFetchedData, fetchedData]
}

export default useDietary