import React from 'react'
import UnitsForm, { UnitFormValues } from '../UnitsForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Unit } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchOneUnit, updateUnit, deleteUnit } from '../../../../services/Units';

interface State {
    isFetching: boolean
    unit: Unit | null
    isSaving: boolean
    error: string | null
    refreshUnitFormKey: number
}

interface RouteInfo {
    id: string
}

interface Props extends RouteComponentProps<RouteInfo> {
    refreshListFun?: () => void
}

class UnitsEdit extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            unit: null,
            isFetching: false,
            refreshUnitFormKey: 0
        }

        this.id = props.match.params.id
    }

    async fetchData(id?: string) {
        this.setState({ isFetching: true, error: null })
        try {
            const unit = await fetchOneUnit(id || this.id)
            this.setState({ isFetching: false, unit })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }
    async componentDidMount() {
       await this.fetchData()
    }

    async componentDidUpdate(prevProps: RouteComponentProps<RouteInfo>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.id = this.props.match.params.id
            await this.fetchData(this.props.match.params.id)
            this.setState(prev => {
                return {
                    refreshUnitFormKey: prev.refreshUnitFormKey + 1
                }
            })
        }
    }
    goBack() {
        this.props.history.push('/admin/forms');
    }

    async handleSave(data: UnitFormValues) {
        this.setState({ isSaving: true, error: null })
        const postData = {
            ...data,
            _id: this.id,
        }
        try {
            await updateUnit(postData)
            this.setState({ isSaving: false })
            this.goBack()
            if(this.props.refreshListFun) this.props.refreshListFun()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(unitId: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteUnit(unitId)
            this.setState({ isSaving: false })
            this.goBack()
            if(this.props.refreshListFun) this.props.refreshListFun()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditUnit">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.unit && (
                    <UnitsForm
                        title="Edit Room"
                        unit={this.state.unit}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        {...this.state}
                        key={this.state.refreshUnitFormKey}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(UnitsEdit)
