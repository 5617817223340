import React, { useState } from 'react'
import { Modal, ModalHeader,Button, Input } from 'semantic-ui-react'
import "./style.less"

interface Props {
  isModalOpen: boolean 
  closeModal: () => void
  updateNewPassword: (password: string) => Promise<void>
}
// Here do the state management

const SetNewPasswordModal: React.SFC<Props> = ({isModalOpen, closeModal, updateNewPassword}) => {
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  return (
    <Modal open={isModalOpen} size="small" onClose={() => {
      if (!isLoading) {
        closeModal()
      }
    }}>
      <ModalHeader>
      Set New Password

      </ModalHeader>
    <Modal.Content>
    <div className="set-new-password">
      <div className="member">
        <Input name="password" type="text" placeholder="New Password" onChange={(e) => {
          setPassword(e.target.value)
        }}/>
      </div>
      <div className="member" >
        <Button loading={isLoading} onClick={async e => {
          e.preventDefault();
          setLoading(true);
          await updateNewPassword(password)
          setLoading(false);
        }}>Set Password</Button>
      </div>
      </div>
    </Modal.Content>

    </Modal>
  )
}

export default SetNewPasswordModal;