import moment from 'moment-timezone'

export const localesData = [
    { key: 'none', text: 'NONE', value: 'none' },
    { key: 'ar-SA', text: 'Arabic (Saudi Arabia)', value: 'ar-SA' },
    { key: 'en-US', text: 'English (United States)', value: 'en-US' },
    { key: 'es-MX', text: 'Spanish (Mexico)', value: 'es-MX' },
    { key: 'es-ES', text: 'Spanish (Spain)', value: 'es-ES' },
    { key: 'de-DE', text: 'German (Germany)', value: 'de-DE' },
    { key: 'it-IT', text: 'Italian (Italy)', value: 'it-IT' },
    { key: 'en-IN', text: 'English (India)', value: 'en-IN' },
    { key: 'fr-CA', text: 'French (Canada)', value: 'fr-CA' },
    { key: 'fr-FR', text: 'French (France)', value: 'fr-FR' },
    { key: 'en-CA', text: 'English (Canada)', value: 'en-CA' },
    { key: 'ja-JP', text: 'Japanese (Japan)', value: 'ja-JP' },
    { key: 'pt-BR', text: 'Portuguese (Brazil)', value: 'pt-BR' },
    { key: 'hi-IN', text: 'Hindi (India)', value: 'hi-IN' },
    { key: 'es-US', text: 'Spanish (United States)', value: 'es-US' },
    { key: 'en-GB', text: 'English (United Kingdom)', value: 'en-GB' },
];


export const wakeWordsData = [
    { key: 'alexa', text: 'ALEXA', value: 'ALEXA' },
    { key: 'amazon', text: 'AMAZON', value: 'AMAZON' },
    { key: 'computer', text: 'COMPUTER', value: 'COMPUTER' },
    { key: 'echo', text: 'ECHO', value: 'ECHO' },
]

export const speechConfirmationData = [
    { key: 'tone', text: 'TONE', value: 'TONE' },
    { key: 'none', text: 'NONE', value: 'NONE' },
]

export const temperatureData = [
    { key: 'celsius', text: 'CELSIUS', value: 'CELSIUS' },
    { key: 'fahrenheit', text: 'FAHRENHEIT', value: 'FAHRENHEIT' },
]


export const distanceUnitData = [
    { key: 'metric', text: 'METRIC', value: 'METRIC' },
    { key: 'imperial', text: 'IMPERIAL', value: 'IMPERIAL' },
]

export const timezonesData = moment.tz.names().map((name) => {
    return {
        key: name,
        text: name.replace('_', ' '),
        value: name,
    };
});
