import { KioskQuestionsType } from "../types/KioskQuestions";
import API from "./API";

interface KioskQuestionsResponse {
    Result: KioskQuestionsType[];
    totalRecords: number;
}

export async function getKioskQuestions(Facility: string): Promise<KioskQuestionsResponse> {
    const params = {
        Facility
    };
    const response = await API.lambdaPost('/kiosk/questions/get', params);
    return response;
}

export async function createKioskQuestion(data: Partial<KioskQuestionsType>): Promise<string> {
    const response = await API.lambdaPost('/kiosk/questions/add', data);
    return response;
}

export async function updateKioskQuestion(data: KioskQuestionsType): Promise<boolean> {
    const response = await API.lambdaPost('/kiosk/questions/update', data);
    return response;
}