import React, { FC } from 'react';

interface Props {
    name: string;
    size?: string;
}

const IconList: FC<Props> = ({ name, size = "small" }) => {
    if (name === "Concierge") {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-headset"></i>
        );
    }
    if (name == "Activity") {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-calendar"></i>
        );
    }
    if (name == 'Menu') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-plate-utensils"></i>
        );
    }
    if (name === 'Maintenance') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-screwdriver-wrench"></i>
        );
    }
    if (name == 'Transport') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-sharp fa-light fa-bus-simple"></i>
        );
    }
    if (name == 'Care') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-note-medical"></i>
        );
    }
    if (name === "Valet") {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-car-side"></i>
        );
    }
    if (name == "Wellness") {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-hand-heart"></i>
        )
    }
    if (name == 'Housekeeping') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-dryer"></i>
        );
    }
    if (name == 'Salon') {
        return (
            <i style={{ fontSize: size === "small" ? '18px' : '24px' }} className="fa-sharp fa-light fa-scissors"></i>
        );
    }
    if (name == 'Technology') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-laptop-code"></i>
        );
    }
    if (name == 'Administrative') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-sharp fa-light fa-paperclip"></i>
        );
    }
    if (name == 'Facility') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-sharp fa-light fa-building"></i>
        );
    }
    if (name == 'Pets') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-dog-leashed"></i>
        );
    }
    if (name == "Fees") {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-circle-dollar"></i>
        );
    }
    if (name == 'Other') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-plus-large"></i>
        );
    }
    if (name == 'Room') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-person-shelter"></i>
        );
    }
    if (name == 'Guest') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-house-person-return"></i>
        );
    }
    if (name == 'High Touch') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-sharp fa-light fa-crown"></i>
        );
    }
    if (name == 'On-Campus') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-map-pin"></i>
        );
    }
    if (name == 'Off-Campus') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-bus-simple"></i>
        );
    }
    if (name == 'Solo Engagement') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-hand-fist"></i>
        );
    }
    if (name == 'Item Checkout') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-truck-ramp-box"></i>
        );
    }
    if (name == 'Events') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-sharp fa-light fa-calendar-circle-exclamation"></i>
        );
    }
    if (name == 'Creative Arts') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-user-music"></i>
        );
    }
    if (name == 'Lobby') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-person-dress-burst"></i>
        );
    }
    if (name == 'Reception Desk') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-display-code"></i>
        );
    }
    if (name == 'Front Desk') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-lamp-desk"></i>
        );
    }
    if (name == 'Security') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-shield-halved"></i>
        );
    }
    if (name == 'Mail/Packages') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-sharp fa-light fa-box"></i>
        );
    }
    if (name == 'Service Attendants') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-person-arrow-up-from-line"></i>
        );
    }
    if (name == 'Spa') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-spa"></i>
        );
    }
    if (name == 'Fitness') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-pickleball"></i>
        );
    }
    if (name == 'Aquatics') {
        return (
            <i style={{ fontSize: size === "small"? '18px' : '24px' }} className="fa-light fa-person-swimming"></i>
        );
    }

    if (name.length > 0) {
        return (
            <img height="38px" width={"40px"} style={{ filter: 'grayscale(100%) contrast(120%) ', position: "relative", left: "-7px", top: "-4px" }} src={`${process.env.PUBLIC_URL}${name}`} />
        );
    }

    return null; // or some other fallback JSX if needed
};

export default IconList;
