import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, List, Modal } from 'semantic-ui-react'
import { Unit } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchPaginatedUnits } from '../../../../services/Units'
import LoadMore from '../../../../components/LoadMore'
import CreateUnit from "../Create"
interface State {
    units: Unit[]
    error: string | null
    isFetching: boolean
    isPaginating: boolean
    page: number
    count: number
    limit: number
    hasMore: boolean
    showModal: boolean
}

class UnitsList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            units: [],
            error: null,
            isFetching: false,
            isPaginating: false,
            page: 1,
            count: 0,
            limit: 50,
            hasMore: true,
            showModal: false
        }
    }

    async loadMore() {
        try {
            if (this.state.page === 1) {
                this.setState({
                    isFetching: true,
                    error: null,
                })
            } else {
                this.setState({
                    isPaginating: true,
                    error: null,
                })
            }

            const units = await fetchPaginatedUnits(this.state.page, this.state.limit, undefined, true /* includeNonResidentialRooms */)
            console.log('units: ', units, units.length)
            const hasMore = !(units.length < this.state.limit)

            this.setState({
                isFetching: false,
                isPaginating: false,
                units: [...this.state.units, ...units],
                error: null,
                hasMore,
                page: this.state.page + 1,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Rooms.'
            this.setState({
                isFetching: false,
                isPaginating: false,
                error: msg,
            })
        }
    }

    async componentDidMount() {
        await this.loadMore()
    }

    toggleModalHandler = () => {
        this.setState(prev => ({
            showModal: !prev.showModal
        }))
    }

    navigateToCreate() {
        return this.props.history.push('/admin/forms/units/create')
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/forms/unit/${id}`)
    }

    get units() {
        return this.state.units.map(u => (
            <List.Item key={u._id} onClick={() => this.goToEdit(u._id)}>
                <List.Icon name="bed" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header as="a">Apartment {u.Name}</List.Header>
                    <List.Description as="a">{u.Code}</List.Description>
                </List.Content>
            </List.Item>
        ))
    }

    render() {
        return (
            <div className="UnitsList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.toggleModalHandler.bind(this)}
                />
                <List divided relaxed>
                    {this.units}
                </List>
                <LoadMore
                    next={() => {
                        this.loadMore()
                    }}
                    isLoading={this.state.isPaginating}
                    hasMore={this.state.hasMore}
                />
                <Modal open={this.state.showModal} closeIcon closeOnEscape onClose={this.toggleModalHandler}>
                    <CreateUnit onSubmitClick={() => {
                        this.setState({
                            page: 1, 
                            units: []
                        }, () => this.loadMore())
                        this.toggleModalHandler()
                    }} 
                    onCancelClick={() => {
                        this.toggleModalHandler()
                    }}
                    />
                </Modal>
            </div>
        )
    }
}

export default withRouter(UnitsList)
