import React, { FC, useEffect, useState } from 'react';
import { Segment, Loader, Message, Grid, Form, Dimmer } from 'semantic-ui-react';
import { fetchOneRegistrant } from '../../services/Registrants';
import { fetchStaffRoleUsers } from '../../services/Users';
import { Registrant, User } from '../../types';
import DataSourceDropdown from '../DataSourceDropdown';
import SegmentedInputBox from "../SegmentedInputBox"
interface Props {
    resident: Partial<Registrant>
}

const ResidentDetailView: FC<Props> = ({ resident }) => {
    const [residentDetails, setResidentDetails] = useState<Registrant | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [staffUsersData, setStaffUsersData] = useState<User[]>([])

    useEffect(() => {
        (async () => {
            try {
                if (resident._id) {
                    setLoading(true)
                    const [residentRes, staffRoleUsers]= await Promise.all([fetchOneRegistrant(resident._id), fetchStaffRoleUsers()])
                    setResidentDetails(residentRes)
                    setStaffUsersData(staffRoleUsers)
                    setLoading(false)
                } else {
                    throw new Error("resident id not provided")
                }
            } catch (error) {
                console.error(error)
                setError(error instanceof Error ? error.message : "Failed to fetch resident details")
                setLoading(false)

            }
        })()
    }, [])

    if (loading) {
        return <Dimmer active={true} inverted>
            <Loader active={true} />
        </Dimmer>
    }

    if (error && (!loading)) {
        return <Message error>{error ? error : "Could not find a resident with id"}</Message>
    }
    return <>
        {residentDetails ? <Grid columns={6} >
            <Grid.Column width={6}>
                <SegmentedInputBox
                    value={residentDetails.FirstName || ''}
                    required={true}
                    label="First name"
                    onChange={(e) => {}}
                    placeholder="First name"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.LastName || ''}
                    required={true}
                    label="Last name"
                    onChange={(e) => {}}
                    placeholder="Last name"
                    readonly={true}
                />
                <Segment size='small'>
                    <Form.Field required style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <label>Apartment</label>
                        <DataSourceDropdown
                            disabled={true}
                            selected={residentDetails.Unit || undefined}
                            placeholder="Apartment"
                            data={[{Name: residentDetails.Unit ? residentDetails.Unit.Name : "No Room" , _id: residentDetails.Unit ? residentDetails.Unit._id : ""}]}
                            labelFields={['Name']}
                            onSelectionChange={async (u) => {
                            }
                            }
                        />
                    </Form.Field>
                </Segment>
                <SegmentedInputBox
                    value={residentDetails.Email}
                    placeholder="Email"
                    type="email"
                    name="registeremail"
                    onChange={(e) => {}}
                    label="Email"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.Cell || ''}
                    placeholder="Cell"
                    onChange={(e) => {}}
                    label="Cell"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.Phone || ''}
                    placeholder="Phone"
                    onChange={(e) => {}}
                    label="Landline"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.ExternalRequestIntegrationDeviceId || ''}
                    placeholder="Device Id"
                    onChange={(value) => {}}
                    label="Device ID"
                    readonly={true}
                />
            </Grid.Column>
            <Grid.Column width={6}>
                <SegmentedInputBox
                    value={residentDetails.Notes || ''}
                    placeholder="Notes"
                    onChange={(e) => {}}
                    label="Notes"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.SpecialHandling || ''}
                    placeholder="About Me"
                    onChange={(e) => {}}
                    label="About Me"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.CarePlan || ''}
                    placeholder="Care Plan"
                    onChange={(e) => {}}
                    label="Care Plan"
                    readonly={true}
                />
                <Segment size='small'>
                    <Form.Field style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <label>Special Contact</label>
                        <DataSourceDropdown
                            selected={{_id: residentDetails.PrimaryCaregiver}}
                            placeholder="Primary Caregiver"
                            data={staffUsersData.map(user => ({_id: user._id, Name: `${user.FirstName} ${user.LastName}`}))}
                            labelFields={["Name"]}
                            onSelectionChange={(user) => {
                            }}
                            disabled={true}
                        />
                    </Form.Field>
                </Segment>
                <SegmentedInputBox
                    value={residentDetails.CarePlan || undefined}
                    placeholder="Special Handling"
                    onChange={(e) => {}}
                    label="Billing plan"
                    readonly={true}
                />
                <SegmentedInputBox
                    value={residentDetails.AmazonAccountID || ''}
                    placeholder="Amazon Account ID"
                    type="email"
                    name="amazonid"
                    onChange={(e) => {}}
                    label="Amazon Account"
                    readonly={true}
                />
                <Segment size='small'>
                    <Form.Field>
                        <label>Additional Device IDs</label>
                        <div style={{
                            border: '1px solid #dee2e6',
                            borderRadius: '0.28571429rem',
                            transition: 'box-shadow 0.1s ease, border-color 0.1s ease',
                            padding: "5px",
                            margin: "10px 0px",
                            width: "100%"
                        }}>
                            {residentDetails.AdditionalExtReqIntegrationDeviceIds && Array.isArray(residentDetails.AdditionalExtReqIntegrationDeviceIds) && residentDetails.AdditionalExtReqIntegrationDeviceIds.map((item, index) => (
                                <div key={index}>{item}</div>
                            ))}
                        </div>
                    </Form.Field>
                </Segment>
            </Grid.Column>
        </Grid> : <></>}
    </>;
};

export default ResidentDetailView