import React from 'react';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dimmer, Loader } from 'semantic-ui-react';

const UnscheduledEventList = ({ events, editResidentCalendarEntry, allowEdit, onSelection, selectedDay }) => {
    const localizer = momentLocalizer(moment);
    const eventArr = events.map((event) => {
        return {
            id: event._id,
            title: event.text,
            start: new Date(event.dateTimeString),
            end: new Date(event.endDateTimeString || event.dateTimeString),
        };
    });

    const CustomToolbar = (toolbar) => {
        const label = () => {
            return <h3 style={{ margin: '10px' }}>{moment(selectedDay).format('DD MMM YYYY')}</h3>;
        };

        return (
            <div className={Calendar['toolbar-container']}>
                <label className={Calendar['label-date']}>{label()}</label>
            </div>
        );
    };

    const handleSelectSlot = (data) => {
        const startTime = moment(data.start).format('HH:mm:ss');
        const endTime = moment(data.end).format('HH:mm:ss');
        const date = moment(selectedDay).format('YYYY-MM-DD');
        const dateTimeString = `${date}T${startTime}`;
        const endDateTimeString = `${date}T${endTime}`;
        onSelection({
            dateTimeString,
            endDateTimeString,
        });
    };

    const slotPropGetter = (date) => {
        const currentHour = moment(date).hours();
        const style = {
            backgroundColor: 'white',
        };

        if (currentHour >= 7 && currentHour < 19) {
            style.backgroundColor = '#7FB3D5CC';
        }

        return { style };
    };

    return (
        <>
            <Dimmer active={!allowEdit} inverted>
                <Loader active={!allowEdit} />
            </Dimmer>
            <Calendar
                events={eventArr}
                defaultView={'day'}
                formats={{
                    eventTimeRangeFormat: () => null, // this is required to remove default formatting of eventTimeRange
                    timeGutterFormat: 'hh:mm A',
                }}
                date={new Date(selectedDay)}
                defaultDate={new Date(selectedDay)}
                dayLayoutAlgorithm={'no-overlap'}
                localizer={localizer} // The localizer used for formatting dates and times according to the formats. https://jquense.github.io/react-big-calendar/examples/?path=/docs/props--localizer
                components={{
                    toolbar: CustomToolbar,
                }}
                slotPropGetter={slotPropGetter}
                onNavigate={() => { }} // https://jquense.github.io/react-big-calendar/examples/?path=/docs/props--on-navigate
                onSelectEvent={(event) => {
                    const currentEvent = events.find((e) => e._id === event.id);
                    editResidentCalendarEntry(currentEvent);
                }}
                onSelectSlot={(data) => handleSelectSlot(data)}
                selectable
                scrollToTime={moment(selectedDay).set({ h: 7, m: 0, s: 0 })}
            />
        </>
    );
};

export default UnscheduledEventList;
