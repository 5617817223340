import React from 'react'
import { Modal, Image, Button } from 'semantic-ui-react'

const PhotoModal = ({ imageUrl, onModalOpen, onModalClose, isPhotoModalOpen, showPreviousPhoto, showNextPhoto, disableModalPreviousButton, disableModalNextButton }) => {
    return (
        <Modal
            onClose={onModalClose}
            onOpen={onModalOpen}
            open={isPhotoModalOpen}
        >
            <Modal.Content image>
                <Image size='large' src={imageUrl}/>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    icon='chevron left'
                    onClick={showPreviousPhoto} disabled={disableModalPreviousButton}
                />
                <Button 
                    icon='chevron right'
                    onClick={showNextPhoto} disabled={disableModalNextButton}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default PhotoModal
