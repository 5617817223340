import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Dimmer, Loader } from 'semantic-ui-react'
import NotificationsForm from '../../pages/AdminPanel/Notifications/Form'
import { fetchAllRooms } from '../../services/Facilities'
import { sendAnnouncement } from '../../services/Notifications'
import { createRoomGroup, deleteRoomGroup, fetchRoomGroups } from '../../services/RoomGroups'

interface Props {
    selectedA4hRoomId?: string
    closeModal?: () => void
    selectedAnnouncees?: string[];
}

const NotificationsFormContainer: FC<Props> = ({ selectedA4hRoomId, closeModal, selectedAnnouncees }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [rooms, setRooms] = useState<any[]>([{ key: 'all', text: 'All apartments', value: 'all' }]) // todo later create/use a type for rooms
    const [roomGroups, setRoomGroups] = useState<any[]>([]) // todo later create/use a type for rooms
    const [error, setError] = useState({
        message: "",
        isError: false
    })

    function formatAndSortRooms(rooms) {
        const formattedRooms = rooms.map((obj: any) => {
            let text = obj.RoomName;
            if (obj.Resident && obj.Resident !== '') {
                text = obj.Resident;
            }
            return {
                key: `${obj.RoomId}`,
                text,
                value: `${obj.RoomId}`,
            };
        });

        const sortedRooms = formattedRooms.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRooms;
    }

    function formatAndSortRoomGroups(groups) {
        const formattedRoomGroups = groups.map((obj) => {
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.RoomIds]}`,
            };
        });

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        setRoomGroups(sortedRoomGroups)

        return sortedRoomGroups;
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const [rooms, roomGroups] = await Promise.all([fetchAllRooms(), fetchRoomGroups()]);

                const sortedRooms = formatAndSortRooms(rooms);
                const sortedRoomGroups = formatAndSortRoomGroups(roomGroups);
                const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                    .concat(sortedRoomGroups)
                    .concat(sortedRooms);
                setRooms(roomOptions)
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                setError({
                    isError: true,
                    message: e instanceof Error ? e.message : "Could not send a annocement"
                })
            }
        })()
    }, [])
    const sendToast = (type: "warn" | "success" = 'warn', message: string) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    useEffect(() => {
        if (error.isError) {
            sendToast("warn", error.message)
        }
    }, [error.isError]) // todo check if this leads to unnessary re-renders
    const deleteRoomGroupFun = async (id: string) => {
        await deleteRoomGroup(id);
    }

    const createRoomGroupFun = async (name: string, roomIds: string[]) => {
        await createRoomGroup(name, roomIds);
    }
    const handleSend = async (
        message: string,
        roomIds: string[],
        dismissalTimeOffsetMinutes: number,
        sendDeviceNotification?: boolean,
        triggerDateTimeString?: string,
        recurence?: string,
        mail?: boolean,
        sms?: boolean,
        staff?: boolean,
    ) => {
        if (roomIds[0] === 'all') {
            const allRooms = rooms.slice(1);
            roomIds = allRooms.map((room) => {
                return room.value;
            });
            roomIds = roomIds.filter((room) => {
                if (room.indexOf(',') === -1) return room;
            });
        }
        setIsLoading(true);
        try {
            await sendAnnouncement(
                message,
                roomIds,
                triggerDateTimeString,
                recurence,
                mail,
                sms,
                staff,
                dismissalTimeOffsetMinutes,
                true,  //passed true as senting notification should not be an option and should default to true
            );
            setIsLoading(false);
        } catch (e) {
            console.error(e);
            setError({
                message: e instanceof Error ? e.message : 'Could not send a annocement',
                isError: true,
            });
        }
        if (closeModal) closeModal();
    };

    return (
        <Dimmer.Dimmable as={'div'} dimmed={isLoading}>
            <Dimmer active={isLoading} inverted/>
            <NotificationsForm
                onSubmit={handleSend}
                isSaving={isLoading}
                roomOptions={rooms}
                roomGroups={roomGroups}
                deleteRoomGroup={deleteRoomGroupFun}
                createRoomGroup={createRoomGroupFun}
                disallowRoomControls={false}
                selectedAnnouncees={selectedAnnouncees}
                hideAnnounceeSelection={false}
                sourcepage="home"
                selectedRoomA4hId={selectedA4hRoomId}
            />
        </Dimmer.Dimmable>

    )
}

export default NotificationsFormContainer