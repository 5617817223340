import React from 'react'
import { UserProfile } from '../../../../types'
import { RouteComponentProps, withRouter } from 'react-router'
import { UniversityTraining } from '../../../../types/universitytraining'
import { updateTraining, getTraining, deleteTraining } from '../../../../services/UniversityTrainings'
import { Loader, Dimmer, Form, Button, Message, Confirm } from 'semantic-ui-react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import * as Yup from 'yup'
import { GenericTextField, GenericImageField, GenericDropdown } from '../../../../components/GenericFormFields'

interface State {
    selectedTraining: UniversityTraining
    isFetching: boolean
    hasSaveError: false
    error: null
    showConfirm: boolean
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

let _training: UniversityTraining = {
    _id: '',
    topic: '',
    lesson: '',
    image: '',
    active: false,
    url: '',
    date: '',
    AddedBy: '',
    UpdatedBy: '',
    edit: true,
}

export interface FormValues {
    image: string
    topic: string
    lesson: string
    active: boolean
    url: string
}

const ValidationSchema = Yup.object().shape({
    image: Yup.string(),
    topic: Yup.string().required('Topic is required'),
    lesson: Yup.string().required('Lesson is required'),
    active: Yup.boolean(),
    url: Yup.string(),
})

class EditUniversityTrainings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            selectedTraining: _training,
            isFetching: true,
            hasSaveError: false,
            error: null,
            showConfirm: false,
        }
    }

    async componentDidMount(): Promise<void> {
        const params = this.props.match.params as any
        try {
            const _id = params.id
            const res = await getTraining(_id)
            const training = (res.Result || []).find((x) => x._id === _id)
            this.setState({
                selectedTraining: training,
                isFetching: false,
            })
        } catch (e) {
            console.log(e)
        }
    }

    onChange = (e) => {
        const state = this.state.selectedTraining
        state[e.target.name] = e.target.value
        this.setState({ selectedTraining: state })
    }

    onSubmit = async (value) => {
        const training = { ...value }
        try {
            await updateTraining(training)
            this.props.history.push('/admin/university/trainings/list')
        } catch (e) {
            this.setState({
                error: e,
            })
        }
    }

    async delete(id) {
        try {
            await deleteTraining(id)
            this.props.history.push('/admin/university/trainings/list')
        } catch (error) {
            this.setState({
                error: error,
            })
        }
    }

    setShowConfirm(show) {
        this.setState({
            showConfirm: show,
        })
    }

    setImage(newImage: any) {
        this.setState({
            selectedTraining: {
                ...this.state.selectedTraining,
                image: newImage,
            },
        })
    }

    render() {
        const placeholderUrl = `${process.env.PUBLIC_URL}/university_training_placeholder.png`
        return (
            <div className="ui container list-all Create">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Confirm
                    open={this.state.showConfirm}
                    onCancel={() => this.setShowConfirm(false)}
                    onConfirm={() => this.delete(this.state.selectedTraining._id)}
                    header="Confirm Delete"
                    content="Are you sure you want to delete this item?"
                />
                {this.state.isFetching ? null : (
                    <div className="UsersForm">
                        <Formik
                            initialValues={this.state.selectedTraining as FormValues}
                            onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                                await this.onSubmit(values)
                                actions.setSubmitting(false)
                            }}
                            validationSchema={ValidationSchema}
                            render={({
                                values,
                                errors,
                                status,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <Form
                                    onSubmit={handleSubmit}
                                    loading={isSubmitting}
                                    error={Object.keys(errors).length > 0 || this.state.hasSaveError}
                                >
                                    <FormikField
                                        name="image"
                                        component={GenericImageField}
                                        placeholder={placeholderUrl}
                                        disabled={this.state.selectedTraining.Facility === 'standard'}
                                    />
                                    <FormikField
                                        required
                                        name="topic"
                                        component={GenericTextField}
                                        placeholder="Topic"
                                        disabled={this.state.selectedTraining.Facility === 'standard'}
                                    />
                                    <FormikField
                                        required
                                        name="lesson"
                                        component={GenericTextField}
                                        placeholder="Lesson"
                                        disabled={this.state.selectedTraining.Facility === 'standard'}
                                    />
                                    <FormikField
                                        name="active"
                                        component={GenericDropdown}
                                        placeholder="Status"
                                        options={[
                                            { key: 'True', text: 'Active', value: true },
                                            { key: 'False', text: 'Inactive', value: false },
                                        ]}
                                    ></FormikField>

                                    <FormikField
                                        name="url"
                                        component={GenericTextField}
                                        placeholder="URL"
                                        disabled={this.state.selectedTraining.Facility === 'standard'}
                                    />

                                    {this.state.hasSaveError && (
                                        <Message error>
                                            <Message.Header>Error saving User</Message.Header>
                                            <p>{this.state.error}</p>
                                        </Message>
                                    )}
                                    <Button type="submit" disabled={isSubmitting} primary>
                                        Submit
                                    </Button>
                                    <Button
                                        type="button"
                                        basic
                                        onClick={() => this.props.history.push('/admin/university/trainings/list')}
                                    >
                                        Cancel
                                    </Button>
                                    {this.state.selectedTraining.Facility === 'standard' ? (
                                        <div className="standard-university">
                                            This is a Standard University Training. Only Status can be udpated.
                                        </div>
                                    ) : (
                                        <Button
                                            type="button"
                                            basic
                                            color="red"
                                            onClick={() => this.setShowConfirm(true)}
                                        >
                                            Delete Training
                                        </Button>
                                    )}
                                </Form>
                            )}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(EditUniversityTrainings)
