import React, { MouseEvent, useEffect, useState } from 'react'
import { Formik, FormikActions, Field as FormikField, FieldArray } from 'formik'
import { Form, Button, Message, Confirm, Label, Popup, Dropdown, Grid, Checkbox } from 'semantic-ui-react'
import * as Yup from 'yup'

import { GenericTextField } from '../../../../components/GenericFormFields'

import './style.less'
import { AuthState, Unit } from '../../../../types'
import { listRoomTypes } from '../../../../services/RoomTypes';
import { sortByKey } from '../../../../util/sortData';
import { useSelector } from 'react-redux';
import { fetchOneFacility } from '../../../../services/Facilities';
import { sendToast } from '../../../../util';
import { fetchFacilityEnabledForExternalIntegration } from '../../../../services/ExternalIntegrationFacilities';

export interface UnitFormValues {
    Name: string
    Code: string
    Notes: string
    AlexaProfile: string
    RoomCategory?: string
    raspi_identifiers?: Array<{identifier: string}>
    FirstName?: string;
    LastName?: string;
    intrexRoomId?:  string;
    ExternalRequestIntegrationDeviceId?: string;
    NonResidentialRoom?: boolean;
}

export interface ResidentInfo {
    FirstName: string;
    LastName: string;
}

const ValidationSchema = Yup.object().shape({
    Name: Yup.string()
        .required('Room Name is required')
        .max(256, 'Choose a smaller room name')
        .matches(/^[a-zA-Z0-9\-_=#;:?@&]+$/, 'A room name can contain letters or numbers and the following special characters, with no spaces or periods: _ - = # ; : ? @ &'),
        
    Code: Yup.string(),
    Notes: Yup.string(),
    AlexaProfile: Yup.string(),
    RoomCategory: Yup.string(),
    raspi_identifiers: Yup.array().of(Yup.object().shape({
        identifier: Yup.string()
    })),
    FirstName: Yup.string(),
    LastName: Yup.string().when('FirstName', {
        is: (firstName) => !!firstName, // Check if FirstName is provided
        then: Yup.string().required('Last Name is required if First Name is provided')
    }),
    intrexRoomId: Yup.string(),
    ExternalRequestIntegrationDeviceId: Yup.string(),
    NonResidentialRoom: Yup.boolean()
})

interface Props {
    unit: Unit | null
    error: string | null
    title: string  
    onSave: (data: UnitFormValues, defaultResidentinfo: ResidentInfo ) => Promise<any>
    cancel: () => any
    onDelete?: (id: string) => Promise<any>
    isSaving?: boolean
    isCreation?: boolean
}

const UnitsForm: React.FC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    const getInitialValues = () => ({
        Name: (props.unit && props.unit.Name) || '',
        Code: (props.unit && props.unit.Code) || '',
        Notes: (props.unit && props.unit.Notes) || '',
        AlexaProfile: (props.unit && props.unit.AlexaProfile) || '',
        RoomCategory: (props.unit && props.unit.RoomCategory) || '',
        raspi_identifiers: (props.unit && props.unit.raspi_identifiers) || [],
        intrexRoomId: (props.unit && props.unit.intrexRoomId) || '',
        ExternalRequestIntegrationDeviceId: (props.unit && props.unit.ExternalRequestIntegrationDeviceId) || '',
    })

    const canDelete = () => {
        return props.onDelete && props.unit && props.unit._id
    }

    const [showConfirm, setShowConfirm] = useState(false)
    const [selectedRoomCategory, setSelectedRoomCategory] = useState<any>((props.unit && props.unit.RoomCategory) || '')
    const [options, setOptions] = useState<any>([]);
    const [roomCategoryLoading, setRoomCategoryLoading] = useState<boolean>(false);
    const [isFacilityEnabledForIntrex, setIsFacilityEnabledForIntrex] = useState<boolean>(false);
    const [isFacilityEnabledForExternalRequestIntegration, setIsFacilityEnabledForExternalRequestIntegration] = useState<boolean>(false);
    const [externalRequestIntegrationName, setExternalRequestIntegrationName] = useState<string>('');
    const [nonResidentialRoom, setNonResidentialRoom] = useState<boolean>((props.unit && props.unit.NonResidentialRoom) ? true : false);

    useEffect(() => {
        try {
            setRoomCategoryLoading(true);
            (async () => {
                const res = await listRoomTypes();
                await fetchExternalRequestIntegrationFacility();
                if (res && res.Result && res.Result.length > 0) {
                    const locationOptions = res.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                    setOptions(locationOptions);
                }
                if (!(props.unit && props.unit.RoomCategory) && (profile && profile.Facility)) {
                    const facility = await fetchOneFacility(profile.Facility);
                    if (facility && facility.RoomProfile) {
                        setSelectedRoomCategory(facility.RoomProfile);
                    }
                }
            })();
        } catch (e) {
            console.error(e);
            sendToast("error", "Fetching room categories failed");
        } finally {
            setRoomCategoryLoading(false);
        }

    }, [])

    const handleDropdownChange = (value) => {
            setSelectedRoomCategory(value);
    }

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.unit && props.unit._id) {
            props.onDelete(props.unit._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }

    const fetchExternalRequestIntegrationFacility = async () => {
        try {
            //if facility is enabled for external request integration then only show the intrex room id or external integration room ID field
            if (profile && profile.Facility) {
                const [intrexEnabled, palCareEnabled] = await Promise.all([
                    fetchFacilityEnabledForExternalIntegration({ facilityId: profile.Facility, integrationType: 'Intrex' }),
                    fetchFacilityEnabledForExternalIntegration({ facilityId: profile.Facility, integrationType: 'PalCare' })
                ]);
                if (intrexEnabled && palCareEnabled) {
                    throw new Error("Facility can't be enabled for both Intrex and PalCare at the same time");
                }
                if (intrexEnabled) {
                    setIsFacilityEnabledForIntrex(true);
                    setExternalRequestIntegrationName('Intrex');
                }
                if (palCareEnabled) {
                    setIsFacilityEnabledForExternalRequestIntegration(true);
                    setExternalRequestIntegrationName('PalCare');
                }
            }
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error fetching facility integration details");
        }
    }

    return (
        <div className="UnitsForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: UnitFormValues, actions: FormikActions<UnitFormValues>) => {
                    //code will remain unchanged once created
                    if (!values.Code) {
                        const code = values.Name.split(' ').join("_");
                        values.Code = code;
                    }
                    const alexaProfile = values.Name;
                    values.AlexaProfile = alexaProfile;
                    values.RoomCategory = selectedRoomCategory
                    values.NonResidentialRoom = nonResidentialRoom;
                    console.log("values: ", values) 
                    let defaultResidentinfo;
                    if (values.FirstName && values.LastName) {
                        defaultResidentinfo = {
                            FirstName: values.FirstName,
                            LastName: values.LastName
                        };
                        delete values.FirstName;
                        delete values.LastName;
                    }
                    await props.onSave(values, defaultResidentinfo)
                    actions.setSubmitting(false)
                }}
                validationSchema={ValidationSchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField required name="Name" component={GenericTextField} placeholder="Room Name" />
                        <Form.Field required name="RoomCategory" disabled={roomCategoryLoading}>
                            <label>{'Room category'}</label>
                            <Dropdown
                                placeholder={'Select room category'}
                                closeOnEscape
                                value={selectedRoomCategory}
                                clearable
                                search
                                scrolling
                                selection
                                options={sortByKey(options)}
                                onChange={(e, { value }) => handleDropdownChange(value)}
                                loading={roomCategoryLoading}
                            />
                        </Form.Field >
                        <FormikField name="Notes" component={GenericTextField} placeholder="Notes" />
                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving Room</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                            <Form.Field>
                                <label>
                                me2you Identifiers 
                                </label>
                            </Form.Field>
                        <FieldArray
                            name="raspi_identifiers"
                            render={({insert, remove, push}) => (
                                <div >
                                    <div>
                                    {values.raspi_identifiers && values.raspi_identifiers.length > 0 && values.raspi_identifiers.map((raspi_identifier, index) => (
                                        <div>
                                        <div key={index} className="container">
                                            <div className="item">
                                            <FormikField 
                                                name={`raspi_identifiers.${index}.identifier`}
                                                placeholder="Identifier"
                                                type="text"
                                                value={raspi_identifier.identifier}
                                                onChange={(e) => {
                                                    console.log("E",e.target.value);
                                                    e.target.value = e.target.value.replace(/\s/g, '');
                                                    handleChange(e)
                                                }}
                                            />
                                            </div>
                                            <div  className="cancel">
                                                <button
                                                    type="button"
                                                    className="secondary"
                                                    onClick={() => remove(index)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                       </div>

                                    </div>
                                    ))}
                                    </div>

                            <Popup 
                                content="White spaces are stripped automatically"
                                trigger={
                                    <Button
                                    className="add-id"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        push({identifier: ''})
                                    }}
                                >
                                    Add Identifier
                                </Button>   
                                }
                            />
                                </div>
                            )}
                        />
                        {
                            isFacilityEnabledForIntrex && <FormikField name="intrexRoomId" component={GenericTextField} placeholder="Intrex RoomId" />
                        }     
                        { (!isFacilityEnabledForIntrex && isFacilityEnabledForExternalRequestIntegration) && <FormikField name="ExternalRequestIntegrationDeviceId" component={GenericTextField} placeholder={`${externalRequestIntegrationName} room id`} />}
                        {
                            (isFacilityEnabledForIntrex || isFacilityEnabledForExternalRequestIntegration) &&
                            <div style={{ marginBottom: '15px' }}>
                                <Checkbox
                                    label="Non Residential room"
                                    onChange={(e, { checked }) => {
                                        if (props && props.unit && props.unit.NonResidentialRoom) {
                                            if (!checked) {
                                                return sendToast("warn", "Non Residential room can't be changed to Residential room. Please delete the room and recreate if need.");
                                            }
                                        }
                                        setNonResidentialRoom(checked || false);
                                    }
                                    }
                                    checked={nonResidentialRoom}
                                />
                            </div>
                        }
                            {props.isCreation && <Grid style={{marginTop: "20px" }}>
                            <div style={{ width: "100%" }}>
                                <h4>Default Resident Info</h4>
                                <hr/>
                            </div>
                                <Grid.Row style={{marginTop: "0px" }} columns={2}>
                                    <Grid.Column>
                                        <FormikField name="FirstName" component={GenericTextField} placeholder="First Name" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormikField name="LastName" component={GenericTextField} placeholder="Last Name" />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove Room
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default UnitsForm;
