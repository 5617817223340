import React, { FC, useEffect, useState } from 'react'
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import NotificationsForm from '../../pages/AdminPanel/DeviceNotifications/Form'
import { fetchResidentRooms } from '../../services/Facilities';
import {  sendAlexaDeviceNotification, sendPersistentVisualAlert } from '../../services/Notifications';
import { fetchRoomGroups } from '../../services/RoomGroups';
interface Props {
    selectedResidentA4hRoomId?: string
    closeModal?: () => void
}
const DeviceNotificationsFormContainer: FC<Props> = (props) => {
    const [rooms, setRooms] = useState([{ key: 'all', text: 'All apartments', value: 'all' }])
    const [isLoading, setIsLoading] = useState(false)
    const [roomGroups, setRoomGroups] = useState([])
    const [errorMsg, setErrorMsg] = useState('')

    function formatAndSortRooms(rooms) {
        const formattedRooms = rooms.map((obj) => {
            return {
                key: `${obj.RoomId}`,
                text: `${obj.Resident}`,
                value: `${obj.RoomId}`,
            };
        });

        const sortedRooms = formattedRooms.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRooms;
    }

    function formatAndSortRoomGroups(groups) {
        const formattedRoomGroups = groups.map((obj) => {
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.RoomIds]}`,
            };
        });

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        setRoomGroups(sortedRoomGroups)

        return sortedRoomGroups;
    }

    const refreshRoomOptions = async () => {
        setIsLoading(true)
        try {
            const [rooms, roomGroups] = await Promise.all([fetchResidentRooms(), fetchRoomGroups()]);

            const sortedRooms = formatAndSortRooms(rooms);
            const sortedRoomGroups = formatAndSortRoomGroups(roomGroups);

            const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                .concat(sortedRoomGroups)
                .concat(sortedRooms);
            setRooms(roomOptions)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            setErrorMsg(e instanceof Error ? e.message : "failed to refreshRoomOptions")
        }
    }

    const handleSend = async (subject, message, roomIds, startDate) => {
        if (roomIds[0] === 'all') {
            roomIds = rooms.slice(1);
            roomIds = roomIds.map((room) => {
                return room.value;
            });
            roomIds = roomIds.filter((room) => {
                if (room.indexOf(',') === -1) return room;
            });
        }
        setIsLoading(true)
        try {
            await sendAlexaDeviceNotification(message, subject, roomIds, startDate);

            try {
                await sendPersistentVisualAlert(message, roomIds, startDate);
            } catch (e) {
                console.error(e);
                const errMsg =
                    (e.message !== '' && typeof e.message === 'string' && e.message) ||
                    'persistent visual alert failed';

                setErrorMsg(errMsg)
            }
            setIsLoading(false)
            if(props.closeModal) props.closeModal()
        } catch (e) {
            console.error(e);
            setIsLoading(false)
            setErrorMsg(e instanceof Error ? e.message : "Failed to send notification")
        }
    }
    const deleteRoomGroup = async (id) => {
        await deleteRoomGroup(id);
        refreshRoomOptions();
    }

    const createRoomGroup = async (name, roomIds) => {
        await createRoomGroup(name, roomIds);
        refreshRoomOptions();
    }

    useEffect(() => {
        (async () => {
            await refreshRoomOptions()
        })()
    }, [])

    if (errorMsg && (!isLoading)) {
        return <Message error>{errorMsg}</Message>
    }
    return (
        <div>
            <Dimmer.Dimmable as={'div'} dimmed={isLoading}>
                <Dimmer active={isLoading} inverted/>
                <NotificationsForm
                    residentA4hRoomId={undefined}
                    onSubmit={handleSend}
                    isSaving={isLoading}
                    roomOptions={rooms}
                    roomGroups={roomGroups}
                    deleteRoomGroup={deleteRoomGroup}
                    createRoomGroup={createRoomGroup}
                    selectedRoomId={props.selectedResidentA4hRoomId}
                />
            </Dimmer.Dimmable>
        </div>
    )
}

export default DeviceNotificationsFormContainer