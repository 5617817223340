import React, { FC } from 'react';
import { Grid, Header, Message } from 'semantic-ui-react';
import { Analyze } from '../../types/Analyze';
import { Doughnut, Bar } from "react-chartjs-2";
import { declineOptions } from '../../util/data';
// todo post review: remove is summary chart from the whole file if isSummaryChart is not required.
interface Props {
  data: Analyze[]
  totFacilityEvents: number
  showChartForAttendance?: true
  isSummaryChart?: true
}

const AnalyzeCharts: FC<Props> = ({ data, totFacilityEvents, showChartForAttendance, isSummaryChart }) => {
  let attendedCount = 0;
  let observedCount = 0;
  let declinedCount = 0;
  let unscheduledCount = 0;
  let intendedToAttendedCount = 0;
  let deliveredCount = 0;

  const isAttendedDeclined = (event: Analyze) => {
    return declineOptions.some(option => option.value === event.attended);
  };

  const isAttendedDelivered = (event: Analyze) => {
    const deliveredOptions = ["Ill", "Preferred"];
    return deliveredOptions.some(option => option === event.attended);
  }

  for (const event of data) {
    if (event.attended === "Yes") {
      attendedCount++;
    } else if (event.attended === "Observed") {
      observedCount++;
    } else if (event.registered === "No" && event.attended === "Yes") {
      unscheduledCount++;
    } else if (isAttendedDeclined(event)) {
      declinedCount++;
    } else if (event.registered === "Yes") {
      intendedToAttendedCount++;
    } else if (isAttendedDelivered(event)) {
      deliveredCount++;
    }
  }

  const sum = attendedCount + observedCount + declinedCount + unscheduledCount + intendedToAttendedCount;
  const attendenceChartData = {
    labels: [
      `${isSummaryChart ? "Total resident events" : "Total Events"}`,
      "Observed",
      "Unscheduled",
      "Attended",
      "Declined",
      "Delivered"
    ],
    datasets: [
      {
        label: "Analyse dataset",
        data: [
          isSummaryChart ? sum : totFacilityEvents,
          observedCount,
          unscheduledCount,
          attendedCount,
          declinedCount,
          deliveredCount
        ],
        fill: false,
        borderColor: "white",
        tension: 0.1,
        backgroundColor: [
          "#2987CD",
          "#F0712C",
          "#B5CA31",
          "#2DB84B",
          "#CC2029",
          "#FFD700"
        ],
        borderWidth: 1,
      },
    ],
  };

  const potentialChartData = {
    labels: [
      `${isSummaryChart ? "Total resident events" : "Total Events"}`,
      "Intended To Attend"
    ],
    datasets: [{
      label: "Potential dataset",
      data: [
        isSummaryChart ? sum : totFacilityEvents,
        intendedToAttendedCount,
      ],
      fill: false,
      borderColor: "white",
      tension: 0.1,
      backgroundColor: [
        "#2987CD",
        "#1CB5AC",
      ]
    }]
  }

  const options = {
    responsive: true,
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            // below is shown on chart item hover 
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
          },
        },
      },
    },
  };

  if (showChartForAttendance) {
    return <Bar data={attendenceChartData} options={options} />
  }
  return (
      <Grid columns={2} verticalAlign="top" style={{ width: '100%' }}>
          <Grid.Column width={8}>
              <Header as={'h3'}>{`${isSummaryChart ? 'Weekly' : ''}`}Attendance Graph</Header>
              {data.length ? (
                  <Doughnut data={attendenceChartData} options={options} />
              ) : (
                  <Message>No graph data to display</Message>
              )}
          </Grid.Column>
          <Grid.Column width={8}>
              <Header as={'h3'}>{`${isSummaryChart ? 'Weekly' : ''}`} Potential Graph</Header>
              {data.length ? (
                  <Doughnut data={potentialChartData} options={options} />
              ) : (
                  <Message>No graph data to display</Message>
              )}
          </Grid.Column>
      </Grid>
  );
};

export default AnalyzeCharts;
