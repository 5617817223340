import React, { useState, useEffect } from 'react'
import { fetchRequests } from '../services/Basics'
import { Request } from '../types'
import { registerOnNotificationHandler } from '../services/PushNotifications'
import { BasicReportSummaryFilters } from '../types'

const REFRESH_REQUESTS_TIME_MS = 30000

export const useAdminPanelRequests = (
    filters: BasicReportSummaryFilters,
    sortBy: object[] | undefined,
    shouldBG = false,
    isReport = false,
    pageNo = 1,
    pageSize = 40,
    history,
    fetchAllData = false,
    facilityTimezone: string | null = null,
) => {
    const [requests, setRequests] = useState<Request[]>([])
    const [error, setError] = useState()
    const [pageCount, setPageCount] = useState(1)
    const [isFetching, setIsFetching] = useState(false)
    const [isPaginating, setIsPaginating] = useState(false)
    const [hasMorePages, setHasMorePages] = useState(true)
    const [totalDataLength, settotalDataLength] = useState<number>(0);

    const loadRequests = async (
        requestFilters: BasicReportSummaryFilters,
        requestPageNo: number,
        requestPageSize: number,
        requestSortBy: Object[] | undefined,
        isBG: boolean,
        isRequestPaginating: boolean,
    ) => {
        if (requestFilters.type === 'Question of the day') { // We are fetching QOTD separately in the parent component
            setRequests([]);
            setIsFetching(false);
            setIsPaginating(false);
            setPageCount(0);
            setHasMorePages(false);
            settotalDataLength(0);
            return;
        }

        if (!isBG && !isRequestPaginating) {
            setRequests([])
            setIsFetching(true)
        }

        if (isRequestPaginating) {
            setIsPaginating(true)
        }

        return fetchRequests(
            requestFilters.fromDate,
            requestFilters.toDate,
            requestFilters.resident === 'all' ? null : requestFilters.resident,
            requestFilters.food === 'all' ? null : requestFilters.food,
            requestFilters.type === 'all' ? null : requestFilters.type,
            requestFilters.disable === true ? true : null,
            requestPageNo,
            requestPageSize,
            requestSortBy,
            isReport,
            facilityTimezone,
        ).then(({ count, requests: freshRequests }) => {
            if (fetchAllData && (count > freshRequests.length)) {
                return fetchRequests(
                    requestFilters.fromDate,
                    requestFilters.toDate,
                    requestFilters.resident === 'all' ? null : requestFilters.resident,
                    requestFilters.food === 'all' ? null : requestFilters.food,
                    requestFilters.type === 'all' ? null : requestFilters.type,
                    requestFilters.disable === true ? true : null,
                    1,
                    count,
                    requestSortBy,
                    isReport,
                    facilityTimezone,
                ).then(({ requests: freshRequests }) => {
                    console.log("all data", count, requestPageSize * requestPageNo, count >= requestPageSize * requestPageNo);
                    setRequests(freshRequests);
                    setIsFetching(false);
                    setIsPaginating(false);
                    setPageCount(Math.ceil(count / requestPageSize));
                    setHasMorePages(count >= requestPageSize * requestPageNo);
                    settotalDataLength(count);
                });
            } else {
                console.log("paginated", count, requestPageSize * requestPageNo, count >= requestPageSize * requestPageNo);
                setRequests(freshRequests);
                setIsFetching(false);
                setIsPaginating(false);
                setPageCount(Math.ceil(count / requestPageSize));
                setHasMorePages(count >= requestPageSize * requestPageNo);
                settotalDataLength(count);
            }
        });
    }

    useEffect(() => {
        const requestPageSize = isReport ? pageSize : pageSize * pageNo
        const requestPageNo = isReport ? pageNo : 1

        loadRequests(filters, requestPageNo, requestPageSize, sortBy, false, pageNo !== 1)
    }, [filters, sortBy, isReport, pageSize, pageNo])

    useEffect(() => {
        if (shouldBG) {
            const bGFunction = () => {
                const requestPageSize = isReport ? pageSize : pageSize * pageNo
                const requestPageNo = isReport ? pageNo : 1

                loadRequests(filters, requestPageNo, requestPageSize, sortBy, true, false)
            }

            const unsubscribeHandler = registerOnNotificationHandler(bGFunction, history)
            const timerId = window.setInterval(bGFunction, REFRESH_REQUESTS_TIME_MS)

            return () => {
                if (timerId) window.clearInterval(timerId)
                if (unsubscribeHandler) unsubscribeHandler()
            }
        }
    }, [filters, sortBy, isReport, pageSize, pageNo, shouldBG])

    return {
        requests,
        error,
        isFetching,
        isPaginating,
        hasMorePages,
        pageCount,
        totalDataLength
    }
}
