import React, { FC, useState } from 'react';
import { Dimmer, Loader, Tab } from 'semantic-ui-react';
import CheckboxItems from '../ResidentProfile/CheckboxItems';
import { FetchDataAction, ToggleCheckboxAction } from '../ResidentProfile/useCheckbox';
interface Props {
    checkboxItems;
    dispatch: React.Dispatch<FetchDataAction | ToggleCheckboxAction>;
    loading: boolean;
}

const ProfilePanes: FC<Props> = ({checkboxItems, dispatch, loading }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [activeSubTab, setActiveSubTab] = useState({});
    const intellectualPanes = [
        {
            menuItem: 'Arts',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Intellectual"
                        subCategory='arts'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Technology',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Intellectual"
                        subCategory='tech'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Science',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Intellectual"
                        subCategory='science'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'World',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Intellectual"
                        subCategory='USAndWorld'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Training',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Intellectual"
                        subCategory='training'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ];
    const occupationalPanes = [
        {
            menuItem: 'Community Engagement',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Occupational"
                        subCategory='communityEngagement'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Intergenerational',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Occupational"
                        subCategory='intergenerational'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Art and Craft',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Occupational"
                        subCategory='artAndCraft'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ];
    const physicalPanes = [
        {
            menuItem: 'Dance',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Physical"
                        subCategory='dance'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Fitness',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Physical"
                        subCategory='fitness'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Games',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Physical"
                        subCategory='games'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Self-Care',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Physical"
                        subCategory='selfCare'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ];
    const socialPanes = [
        {
            menuItem: 'Community',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Social"
                        subCategory='community'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Clubs',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Social"
                        subCategory='clubs'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Outings',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Social"
                        subCategory='outings'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Games',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Social"
                        subCategory='games'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Physical Games',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Social"
                        subCategory='physicalGames'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ];
    const emotionalPanes = [
        {
            menuItem: 'Music',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Emotional"
                        subCategory='music'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Family',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Emotional"
                        subCategory='family'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Nurturing',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Emotional"
                        subCategory='nurturing'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Reminiscence',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Emotional"
                        subCategory='reminiscence'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Therapies',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        category="Emotional"
                        subCategory='therapies'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ];
    const spiritualPanes = [
        {
            menuItem: 'Spiritual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Spiritual"
                        subCategory='spiritual'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }
    ];
    const environmentalPanes = [
        {
            menuItem: 'Environmental',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Environmental"
                        subCategory='environmental'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }
    ];
    const virtualPanes = [
        {
            menuItem: 'Virtual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Virtual"
                        subCategory='virtual'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }
    ];
    const panes = [
        {
            menuItem: 'Intellectual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={intellectualPanes}
                        activeIndex={activeSubTab[0] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [0]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Occupational',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={occupationalPanes}
                        activeIndex={activeSubTab[1] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [1]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Physical',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={physicalPanes}
                        activeIndex={activeSubTab[2] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [2]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Social',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={socialPanes}
                        activeIndex={activeSubTab[3] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [3]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Emotional',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={emotionalPanes}
                        activeIndex={activeSubTab[4] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [4]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Spiritual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={spiritualPanes}
                        activeIndex={activeSubTab[5] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [5]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Environmental',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={environmentalPanes}
                        activeIndex={activeSubTab[6] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [6]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Virtual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={virtualPanes}
                        activeIndex={activeSubTab[7] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [7]: activeIndex
                                };
                            });
                        }}
                    />
                </Tab.Pane>
            ),
        }
    ];
    return (
        <Tab
            style={{ marginTop: "10px" }}
            panes={panes}
            activeIndex={activeTab}
            onTabChange={(e, { activeIndex }) => {
                if (activeIndex !== undefined) { // can't use !activeIndex as that will not work for 0th tab
                    setActiveTab(Number(activeIndex));
                }
            }}
        />
    );
};

export default ProfilePanes;