import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, Label, Menu, Popup } from 'semantic-ui-react';
import PrintExportIcons from '../PrintExportIcons';
import IconList from '../../pages/AdminPanel/Requests/CategoryIconList/IconList';
import "./style.less";
import { serviceSourceType } from '../../types/ServiceInstance';
import { getServiceCategories } from '../../services/service';
import { toast } from 'react-toastify';

interface Props {
    onPrintClick: () => void;
    exportData: {}[];
    printButtonLoading?: boolean;
    disable?: boolean;
    exportFileName: string;
    filterChangeHandler: (selectedFilters: string[]) => void;
    source: serviceSourceType;
}

const ServiceFilterPrintExportIcons: FC<Props> = ({ exportData, onPrintClick, exportFileName, filterChangeHandler, disable = false, printButtonLoading, source }) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        filterChangeHandler(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categories = await getServiceCategories({ sortAlphabetically: true });
                if (source === "DailyActivities") {
                    setCategoryOptions(categories.filter((category: string) => category !== 'Menu'));
                } else if (source === "Menu") {
                    setCategoryOptions(categories.filter((category: string) => category == 'Menu'));
                } else {
                    setCategoryOptions(categories);
                }
            } catch (error) {
                toast.warn(error instanceof Error ? error.message : "Failed to get categories", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        };
        fetchCategories();
    }, [source]);

    const itemsPerRow = 9; // Adjust the number of items per row as needed
    const totalItems = itemsPerRow;
    const startItemIndex = currentPage * totalItems;
    const endItemIndex = startItemIndex + totalItems;
  
    const visibleCategories = categoryOptions && categoryOptions.length > 0 && categoryOptions.slice(startItemIndex, endItemIndex);

    const handlePageChange = (direction: 'prev' | 'next') => {
        const totalPages = Math.ceil(categoryOptions.length / totalItems);
        if (direction === 'prev' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const filterButton = !selectedFilters.length ? <Button icon='filter' size='large' disabled={disable ? disable : false} style={{ background: "none", padding: 0, margin: 0 }} /> : <Button icon='filter' size='large' style={{ background: "none", padding: 0, margin: 0, position: "relative" }} >
        <Icon name='filter' style={{ position: "relative" }} >
            <span style={{
                position: "absolute",
                top: "-6px",
                right: "-6px",
                background: "#20c997",
                color: "white",
                borderRadius: "50%",
                width: "14px",
                height: "14px",
                textAlign: "center",
                fontSize: "7px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>{selectedFilters.length}</span>
        </Icon>
    </Button>;

    const isFilterSelected = (type: string) => {
        return selectedFilters.includes(type);
    };

    const handleFilter = (category: string) => {
        if (!isFilterSelected(category)) {
            setSelectedFilters([...selectedFilters, category]);
        } else {
            setSelectedFilters(selectedFilters.filter(filter => filter !== category));
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }} >
            {source && source !== "Menu" &&
                <Popup
                    trigger={filterButton}
                    content={
                        <div className='serviceFilter'>
                            <div className="menu-wrapper">
                                <div className="carousel-arrows-container">
                                    <Icon name="angle left" className="angle left" onClick={() => handlePageChange('prev')} />
                                    <Menu borderless>
                                        {visibleCategories && visibleCategories.length > 0 && visibleCategories.map((category) => (
                                            <Menu.Item className={`menu-item ${isFilterSelected(category) ? 'active' : ''}`} key={category} onClick={() => handleFilter(category)}>
                                                <div className='pop-up'>
                                                    <Label pointing='below'>{category}</Label>
                                                </div>
                                                <IconList name={category} />
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                    <Icon name="angle right" className="angle right" onClick={() => handlePageChange('next')} />
                                </div>
                            </div>
                        </div>
                    }
                    size='mini'
                    style={{ background: "#ced4da", maxWidth: "450px", width: "450px" }}
                    pinned
                    on="click"
                    basic
                />
            }
            <PrintExportIcons disablePrintButton={source === 'Notepad'} exportData={exportData} onPrintClick={onPrintClick} printLoading={printButtonLoading} exportFileName={exportFileName} />
        </div>
    );
};

export default ServiceFilterPrintExportIcons;
