import React, { FC } from 'react'
import { Form } from 'semantic-ui-react'
import PrintExportIcons from '../PrintExportIcons'
import { capitalizeFirstLetter } from '../MenuFormEditor/utils'
import DatePicker from 'react-datepicker'
import CustomDatePicker, { DateRange } from '../CustomDatePicker'

interface Props {
    residentName: string
    dateRange: DateRange
    setDateRange: React.Dispatch<React.SetStateAction<DateRange>>
}
const ResidentDatePickers: FC<Props> = ({ residentName, dateRange, setDateRange }) => {

    const handleDateChange = (dateRange: DateRange) => {
        setDateRange(dateRange)
    }

    const onExportClick = () => {
        // todo format the below data properly to have multiple rows instead of columns
        // return {
        //     ...analysisData,
        //     [`${capitalizeFirstLetter(selectedType)}TotalCount`]: totFacilityEvents,
        //     ...dateRange
        // }
    }
    return (
        <div className="">
            <div className="customDatePickerWidth">
                <CustomDatePicker dateRange={dateRange} setDateRange={handleDateChange} />
            </div>
        </div>
    )
}

export default ResidentDatePickers