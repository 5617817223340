import React, { FC, useEffect, useState } from 'react';
import { Segment, Loader, Message, Grid, Form, Dimmer, Label } from 'semantic-ui-react';
import { fetchOneUser } from '../../services/Users';
import { Department, User, UserRole } from '../../types';
import SegmentedInputBox from '../SegmentedInputBox';

interface Props {
    staff: Partial<User>;
}

const StaffFeedDetailView: FC<Props> = ({ staff }) => {
    const [staffDetails, setStaffDetails] = useState<User | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        (async () => {
            try {
                if (staff._id) {
                    setLoading(true);
                    const staffRes = await fetchOneUser(staff._id);
                    setStaffDetails(staffRes);
                    setLoading(false);
                } else {
                    throw new Error('staff id not provided');
                }
            } catch (error) {
                console.error(error);
                setError(error instanceof Error ? error.message : 'Failed to fetch staff details');
                setLoading(false);
            }
        })();
    }, []);

    if (loading) {
        return (
            <Dimmer active={true} inverted>
                <Loader active={true} />
            </Dimmer>
        );
    }

    const userDepartments = (staffDetails && Array.isArray(staffDetails.Departments) && staffDetails.Departments) || [];
    const userRoles = (staffDetails && Array.isArray(staffDetails.Roles) && staffDetails.Roles) || [];

    if (error && !loading) {
        return <Message error>{error ? error : 'Could not find a staff with id'}</Message>;
    }
    return (
        <>
            {staffDetails ? (
                <Grid columns={6}>
                    <Grid.Column width={6}>
                        <SegmentedInputBox
                            value={staffDetails.FirstName || ''}
                            required={true}
                            label="First name"
                            onChange={(e) => {}}
                            placeholder="First name"
                            readonly={true}
                        />
                        <SegmentedInputBox
                            value={
                                staffDetails.Cell && staffDetails.Phone
                                    ? `${staffDetails.Cell} / ${staffDetails.Phone}`
                                    : staffDetails.Cell || staffDetails.Phone || ''
                            }
                            placeholder="Contact"
                            onChange={(e) => {}}
                            label="Contact"
                            readonly={true}
                        />
                        <Segment size="small">
                            <Form.Field inline disabled={true}>
                                <label style={{ display: 'block' }}>Departments</label>
                                {userDepartments.map((department: Department) => (
                                    <Label style={{ margin: '1px' }}>{department.Name}</Label>
                                ))}
                            </Form.Field>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <SegmentedInputBox
                            value={staffDetails.LastName || ''}
                            required={true}
                            label="Last name"
                            onChange={(e) => {}}
                            placeholder="Last name"
                            readonly={true}
                        />
                        <SegmentedInputBox
                            value={staffDetails.Email}
                            placeholder="Email"
                            type="email"
                            name="registeremail"
                            onChange={(e) => {}}
                            label="Email"
                            readonly={true}
                        />
                        <Segment size="small">
                            <Form.Field inline disabled={true}>
                                <label style={{ display: 'block' }}>Roles</label>
                                {userRoles.map((role: UserRole) => (
                                    <Label style={{ margin: '1px' }}>{role.Name}</Label>
                                ))}
                            </Form.Field>
                        </Segment>
                    </Grid.Column>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};

export default StaffFeedDetailView;
