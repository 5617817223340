import * as jsonexport from 'jsonexport/dist'
import { saveAs } from 'file-saver'
import { fetchOneFacility } from '../services/Facilities'
import { uploadCSVFileToS3 } from "../services/PrintCalendar"
import moment from "moment-timezone"
import { toast } from 'react-toastify'

export const jsonTocsvDownloader = async (jsonData: {}[] | any, name: string, uploadFileToS3: boolean = false) => {

    const deepCopiedJsonData = JSON.parse(JSON.stringify(jsonData))
    if (deepCopiedJsonData.length < 1) return
    const facility = await fetchOneFacility(deepCopiedJsonData[0].Facility)
    const facilityName = facility.Name
    const facilityTimezone = facility.FacilityTimeZone || "America/New_York";
    let exportData: {}[] = [];
    deepCopiedJsonData.forEach(async (item) => {
        //convert timestamps to readable format
        if (item.DateAdded) item.DateAdded = new Date(item.DateAdded).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.timestamp) {
            const timeSlots = {
                breakfast: 8,
                lunch: 12,
                dinner: 20,
                snack: 18,
                alternative: 22,
            }
            const timestamp = new Date(item.timestamp);
            const facilityBasedTimeStamp = moment.tz(timestamp, facilityTimezone);
            if (item.category) {
                facilityBasedTimeStamp.hours(timeSlots[item.category]);
            }
            let formattedTimestamp = facilityBasedTimeStamp.format('YYYY-MM-DD:HH:mm A');

            if (uploadFileToS3) { // PrintCalendar case
                formattedTimestamp = facilityBasedTimeStamp.format('hh:mm A')
            }
            item.timestamp = formattedTimestamp;
        } else if(item.startDate) {
            const orgTimestamp = moment.tz(item.startDate, 'YYYY-MM-DDTHH:mm:ss');
            let formattedTimestamp = orgTimestamp.format('YYYY-MM-DD:HH:mm A');
            if (uploadFileToS3) { // PrintCalendar case
                formattedTimestamp = orgTimestamp.format('hh:mm A');
            }
            item.timestamp = formattedTimestamp;
        }
        if (item.LastUpdated) item.LastUpdated = new Date(item.LastUpdated).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.RequestedTime) item.RequestedTime = new Date(item.RequestedTime).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.endTimestamp) item.endTimestamp = new Date(item.endTimestamp).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.dob) item.dob = new Date(item.dob).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.dateTimeString) item.dateTimeString = new Date(item.dateTimeString).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.endDateTimeString) item.endDateTimeString = new Date(item.endDateTimeString).toLocaleString('en-US', { timeZone: facilityTimezone });
        if (item.startTime) {
            if (uploadFileToS3) { // PrintCalendar case
                const time = moment(item.StartTime).format("h:mm A")
                item.startTime = time
            } else {
                item.startTime = new Date(item.startTime).toLocaleString('en-US', { timeZone: facilityTimezone });
            }
        }
        let filteredSubject = item.text || item.name || item.Message || item.Name;
        if (item.text || item.name || item.Name) {
            filteredSubject = filteredSubject.replace(/<\/?[^>]+(>|$)/g, "") || filteredSubject.replace(/<\/?[^>]+(>|$)/g, "")
            filteredSubject = filteredSubject.trim()
            filteredSubject = filteredSubject.replace(/  +/g, ',  ')
        }

        let filteredItem;

        if (uploadFileToS3) { // only send Subject, StartDate, StartTime for print calendar
            const startDate = item.startDate || item.dateString || item.date || item.RequestedTime || item.dob || item.startTime || item.dateTimeString || "";

            const formattedStartDate = moment.tz(startDate, facilityTimezone).format('MM/DD/YYYY'); // printer only accepts MM/DD/YYYY format

            filteredItem = {
                Subject: filteredSubject,
                StartDate: formattedStartDate  ,
                StartTime: item.timestamp || item.startTime || item.dateTimeString || item.RequestedTime || ""
            };
        } else {
            filteredItem = {
                Subject: filteredSubject,
                StartDate: item.dateString || item.date || item.RequestedTime || item.startDate || item.dob || item.startTime || item.dateTimeString || "",
                StartTime: item.timestamp || item.startTime || item.dateTimeString || item.RequestedTime || "",
                EndDate: item.endDate || item.endDateTimeString || item.ClosedTime || "",
                EndTime: item.endTimestamp || item.ClosedTime || "",
                RequestedTime: item.RequestedTime,
                Facility: facilityName,
                DateAdded: item.DateAdded,
                id: item._id,

                ...(item.AllDay && { AllDay: item.AllDay }),
                // Description: "",
                // Location: "",
                ...(item.private && { Private: item.private }),
                ...(item.category && { Category: item.category }),
                ...(item.location && { location: item.location })
            }
        }
        exportData.push(filteredItem)
    })
   
    let file: any;
    if (uploadFileToS3) { // print calendar usecase
        exportData = normalizeDates(exportData)
    }
    jsonexport(exportData, (err, csv) => {
        console.log('csv', csv, typeof csv, err)
        file = new File([csv], `${name}.csv`, { type: 'text/csv;charset=utf-8' })
        if (!uploadFileToS3) {
            saveAs(file)
        }
    })

    if (uploadFileToS3) {
        const signedUrl = await uploadCSVFileToS3(file);
        const params = new URLSearchParams();
        params.set('file', signedUrl);
        window.open(`https://prntyr.speak2.live/?${params.toString()}`, '_blank');
    }

}

function normalizeDates(arr) {
    const customEvents = arr.map((el) => {
        if (el.EndDate && el.StartDate) {
            const start = moment(el.StartDate);
            const end = moment(el.EndDate);
            const days = end.diff(start, "days");
            return Array.from({ length: days + 1 }, (_, i) => { // {length + 1} runs the fucntion for the numbers of days 
                const formattedStartDate = start.clone().add(i, 'd').format('YYYY-MM-DD');
                return {
                    _id: `${el._id ? el._id : new Date().getTime()}`,
                    Subject: el.Subject,
                    StartDate: formattedStartDate,
                    StartTime: el.StartTime || "",
                    Facility: el.Facility,
                    DateAdded: el.DateAdded,
                    LastUpdated: el.LastUpdated || null,
                    UpdatedBy: el.UpdatedBy || null,
                    _version: el.Version || 1
                }
            })
        } else {
            return el
        }
    }).flat()
    return customEvents
}

export const jsonTocsvDownloaderV2 = (data: {}[], exportFileName: string, preventDataFormat: boolean = false) => {
    const ignoreItems = ["_id", "IsActive", "DateAdded", "AddedBy", "LastUpdated", "UpdatedBy", "_version", "Facility"]
    let headers: string[] | null = null
    if (!preventDataFormat) {
        const unquieHeaders = new Set()
        for (const obj of data) {
            for (const key in obj) {
                if (Array.isArray(obj[key])) { // check if key is an array
                    for (let i = 0; i < obj[key].length; i++) {
                        const nestedObj = obj[key][i];
                        for (const nestedKey in nestedObj) {
                            if (!ignoreItems.includes(nestedKey) && typeof nestedKey === "object") {
                                unquieHeaders.add(`${key}.${nestedKey}`);
                            }
                        }
                    }
                } else if (typeof obj[key] === "object") { // check if key is an object
                    for (const nestedKey in obj[key]) {
                        if (!ignoreItems.includes(nestedKey)) {
                            unquieHeaders.add(`${key}.${nestedKey}`);
                        }
                    }
                } else {
                    if (!ignoreItems.includes(key)) {
                        unquieHeaders.add(key);
                    }
                }
            }
        }
        // todo remove the below ts ignore ////
        // @ts-ignore
        headers = [...unquieHeaders]
    }
    const renamedHeaders = headers ? headers.map((header) => {
        if (header.includes(".")) {
            const dotformattedString = header.split(".").join(" - ");
            const formattedString = dotformattedString.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase());
            return formattedString
        } else {
            const formattedString = header.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase());
            return formattedString
        }
    }) : []

    const isObject = (val) => {
        return val != null && typeof val === "object" && Array.isArray(val) === false;
    };

    const filterIgnoreItems = (obj) => {
        if (!isObject(obj)) {
            return obj;
        }

        const filteredObj = Array.isArray(obj) ? [] : {};

        for (let key in obj) {
            if (ignoreItems.includes(key)) {
                continue;
            }

            const value = obj[key];
            filteredObj[key] = isObject(value) ? filterIgnoreItems(value) : value;
        }

        return filteredObj;
    };


    const filteredData = data.map((el) => {
        return filterIgnoreItems(el)
    })

    jsonexport(filteredData, { headers: headers, rename: renamedHeaders }, (err, csv) => {
        if (err) {
            toast.error("Failed to export data", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } else {
            const file = new File([csv], `${exportFileName}.csv`, { type: 'text/csv;charset=utf-8' });
            saveAs(file)
        }
    })
}

const getHourFromTimeStamp = (timestamp: Date) => { //// is this not used?
    const formatted = new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric" }).format(new Date(timestamp))
    return formatted
}

const formatStartDateInYYMMDD = (date: Date | number | string, FacilityTimeZone: string, formatString: string, applyTimeZone?: boolean) => {
    let formattedDate;
    if (applyTimeZone) {
        formattedDate = moment.tz(date, FacilityTimeZone).format(formatString)
    } else {
        formattedDate = moment(date).format(formatString)
    }
    ;
    return formattedDate
}

export const formatAndExportDataForPrint = async (data: { Subject: string, StartDate: Date | number | string, StartTime: Date | number | string }[], fileName: string, FacilityTimeZone: string, type: "activity" | "menu") => {
    const formatString = type === "activity" ? "YYYY-MM-DD hh:mm A" : "YYYY-MM-DD";
    const applyTimeZone = type === "menu" ? false : true; // if format is YYYY-MM-DD don't use timezone as its creating issues
    const formattedData = data.map((el) =>
    (
        {
            Subject: el.Subject,
            StartDate: `${formatStartDateInYYMMDD(el.StartDate, FacilityTimeZone, formatString , applyTimeZone)}`,
            StartTime: `${formatStartDateInYYMMDD(el.StartTime, FacilityTimeZone, formatString, applyTimeZone)}`,
        }
    )
    )
    let file: File | null = null;
    jsonexport(formattedData, (err, csv) => {
        if (err) throw new Error(err);
        const csvFile = new File([csv], `${fileName}.csv`, { type: 'text/csv;charset=utf-8' });
        file = csvFile
    });

    if (file) {
        const signedUrl = await uploadCSVFileToS3(file)
        const params = new URLSearchParams();
        params.set('file', signedUrl);
        window.open(`https://prntyr.speak2.live/?${params.toString()}`, '_blank');
    }
}

export const formatAndExportDataForPrintDateString = async (data: { Subject: string, StartDate: string, StartTime: string; }[], fileName: string) => {
    const formattedData = data.map((el) =>
    (
        {
            Subject: el.Subject,
            StartDate: moment(el.StartDate).format("YYYY-MM-DD"),
            StartTime: moment(el.StartDate).format("hh:mm A"),
        }
    )
    );
    let file: File | null = null;
    jsonexport(formattedData, (err, csv) => {
        if (err) throw new Error(err);
        const csvFile = new File([csv], `${fileName}.csv`, { type: 'text/csv;charset=utf-8' });
        file = csvFile;
    });
    if (file) {
        const signedUrl = await uploadCSVFileToS3(file);
        const params = new URLSearchParams();
        params.set('file', signedUrl);
        window.open(`https://prntyr.speak2.live/?${params.toString()}`, '_blank');
    }
};