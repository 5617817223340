import React, { useState, useEffect } from 'react';
import { Dropdown, Input, Form, Grid, DropdownItemProps } from 'semantic-ui-react';
import { CustomFieldsFormula } from '../../types/Service';
import { sortByKey } from '../../util/sortData';


interface FormulaComponentProps {
    dropdownValues: DropdownItemProps[];
    onFormulaChange: (formula: CustomFieldsFormula) => void;
    clearState: boolean
    setClearStateToFalse: () => void
}

const CustomFields: React.FC<FormulaComponentProps> = ({ dropdownValues, onFormulaChange, clearState, setClearStateToFalse }) => {
    const [formulaName, setFormulaName] = useState<string>('');
    const [operator, setOperator] = useState<string | undefined>(undefined);
    const [leftOperandName, setLeftOperandName] = useState<string>('');
    const [rightOperandName, setRightOperandName] = useState<string>('');

    const operators = [
        { key: 'add', text: 'Add', value: 'add' },
        { key: 'subtract', text: 'Subtract', value: 'subtract' },
        { key: 'multiply', text: 'Multiply', value: 'multiply' },
        { key: 'divide', text: 'Divide', value: 'divide' },
    ];

    useEffect(() => {
        onFormulaChange({
            formulaName,
            operator,
            leftOperandName,
            rightOperandName
        });
    }, [formulaName, operator, leftOperandName, rightOperandName]);

    useEffect(() => {
        if (clearState) {
            setFormulaName('');
            setOperator("someRandomValue"); // set to a random value to avoid dropdown still being shown
            setLeftOperandName('');
            setRightOperandName('');
            setClearStateToFalse();
        }
    }, [clearState])

    return (
        <Form>
            <Form.Field required>
                <label>Field Name</label>
                <Input
                    placeholder='Enter Field Name'
                    value={formulaName}
                    onChange={(e) => setFormulaName(e.target.value)}
                />
            </Form.Field>
            <Grid columns={3}>
                <Grid.Column>
                    <Form.Field required>
                        <label>Left Operand</label>
                        <Dropdown
                            placeholder='Select Left Operand'
                            fluid
                            selection
                            options={sortByKey(dropdownValues)}
                            value={leftOperandName}
                            onChange={(e, { value, options }) => {
                                setLeftOperandName(value as string);
                            }}
                        />
                    </Form.Field>
                </Grid.Column> 
                <Grid.Column>
                    <Form.Field required>
                        <label>Operation</label>
                        <Dropdown
                            placeholder='Select Operation'
                            fluid
                            selection
                            options={sortByKey(operators)}
                            value={operator}
                            onChange={(e, { value }) => { setOperator(value as string); }}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field required>
                        <label>Right Operand</label>
                        <Dropdown
                            placeholder='Select Right Operand'
                            fluid
                            selection 
                            options={sortByKey(dropdownValues)}
                            value={rightOperandName}
                            onChange={(e, { value, options }) => {
                                setRightOperandName(value as string);
                            }}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default CustomFields;
