import React from 'react'
import FilePicker from './FilePicker'
import { uploadFile } from '../../services/ImageUpload'
import { Promise } from 'bluebird'

interface Props {
    accept?: string[]
    folderName: string
    onUploaded: (fileUrl: string) => any
    onSelected?: (file: File) => any
    onUploadError?: (error: any) => any
    onUploadProgress?: (progress: number) => any, 
    multiple?:boolean
    postUpload?:(fileNames: string[]) => any | undefined
}

interface State {
    percentage: number
    isUploading: boolean
    finished: boolean
    error: string | null
}

class S3FileUpload extends React.Component<Props, State> {
    state: State = {
        percentage: 0,
        isUploading: false,
        finished: false,
        error: null,
    }

    handleSelect = async (files: File[]) => {
        this.setState({ isUploading: true, error: null, finished: false, percentage: 0 })
        // TODO: support multiple
        const file = files[0]
        if (this.props.onSelected) this.props.onSelected(file)
        if(this.props.multiple) {
            try {
                //upload to s3
                const resArr =  (await Promise.map(files, 
                    async (file) => 
                        await uploadFile(this.props.folderName, file, this.handleProgress),
                    { concurrency: 5 }
                ))
                console.log('resArr', resArr)

                //display uploaded images in photo grid
                const fileNames = await Promise.map(resArr, 
                    async (url) => {
                        const res = await this.props.onUploaded(url)
                        return res
                    }, 
                    { concurrency: 1 }
                )
                console.log('fileNames', fileNames)

                //update db
                if (this.props.postUpload) await this.props.postUpload(fileNames)
                this.setState({finished:true, isUploading:false})
            } catch (e) {
                console.log('in parent catch', this.state)
                this.setState({ finished: true, percentage: 0, isUploading: false, error: e.message  || "Upload failed"})
            }
        } else {
            // the single photo upload code below can be deleted because it is already taken care of my multiple photos upload
            try {
                const res = await uploadFile(this.props.folderName, file, this.handleProgress)
                this.setState({ finished: true, percentage: 100, isUploading: false })
                const fileName = this.props.onUploaded(res)
                // todo 
                if(this.props.postUpload) await this.props.postUpload(fileName)
            } catch (e) {
                this.setState({ finished: true, percentage: 0, isUploading: false, error: e.message })
                if (this.props.onUploadError) this.props.onUploadError(e)
            }
        }
    }

    handleProgress = (progress: number) => {
        this.setState({ percentage: progress })
        if (this.props.onUploadProgress) this.props.onUploadProgress(progress)
    }

    render() {
        return (
            <div className="S3FileUpload">
                <FilePicker onSelected={this.handleSelect} multiple={this.props.multiple ? this.props.multiple : false} {...this.state} accept={this.props.accept} />
            </div>
        )
    }
}

export default S3FileUpload
