import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Modal, Segment, Loader, Dimmer } from 'semantic-ui-react';
import { CustomAppInfoType } from '../../types';
import FilePicker from '../S3FileUpload/FilePicker';
import { uploadFile } from '../../services/ImageUpload';
import { toast } from 'react-toastify';
import { addCustomAppInfo, updateCustomAppInfo } from '../../services/Facilities';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
    customAppInfo?: CustomAppInfoType;
    facilityId: string;
}

const CustomAppInfoForm: React.FC<Props> = ({ facilityId, customAppInfo, history }) => {
    const [customAppInfoFormData, setCustomAppInfoFormData] = useState<CustomAppInfoType>({
        appCircle: {
            icon: '',
            label: '',
            link: '',
            androidLink: '',
        },
        appLinks: Array.from({ length: 5 }, () => ({
            label: '',
            link: '',
        })),
    });
    const [filePickerStatus, setFilePickerStatus] = useState({
        percentage: 0,
        isUploading: false,
        finished: false,
        error: null,
    });
    const [open, setOpen] = useState<boolean>(false);
    const [currIconFile, setCurrIconFile] = useState<File | null>(null);
    const [currIconUrl, setCurrIconUrl] = useState<string | null>(null);
    const [updatedIconFile, setUpdatedIconFile] = useState<File | null>(null);
    const [updatedIconUrl, setUpdatedIconUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // Update the form data when the customAppInfo prop changes
        if (!customAppInfo) return;
        setCurrIconUrl(customAppInfo.appCircle.icon);
        setUpdatedIconUrl(customAppInfo.appCircle.icon);
        setCustomAppInfoFormData({
            _id: customAppInfo._id,
            appCircle: {
                icon: customAppInfo.appCircle.icon,
                label: customAppInfo.appCircle.label,
                link: customAppInfo.appCircle.link,
                androidLink: customAppInfo.appCircle.androidLink,
            },
            appLinks: customAppInfo.appLinks,
        });
    }, [customAppInfo]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
        const { value } = e.target;
        setCustomAppInfoFormData((prevData) => ({
            ...prevData,
            appCircle: {
                ...prevData.appCircle,
                [name]: value,
            },
        }));
    };

    const handleAppLinkChange = (index: number, fieldName: string, value: string) => {
        setCustomAppInfoFormData((prevData) => ({
            ...prevData,
            appLinks: prevData.appLinks.map((link, i) => (i === index ? { ...link, [fieldName]: value } : link)),
        }));
    };

    const handleFileSelect = (files: File[]) => {
        const file = files[0];
        setUpdatedIconFile(file);

        const reader = new FileReader();
        reader.onload = () => {
            if (reader && reader.result) {
                setUpdatedIconUrl(String(reader.result));
            }
        };

        reader.readAsDataURL(file);
    };

    const uploadImageFile = async (id: string) => {
        const handleProgess = (number: number) => {
            setFilePickerStatus((prev) => ({ ...prev, percentage: number }));
        };
        if (updatedIconFile) {
            const url = await uploadFile(
                `facility-custom-app-circle/${facilityId}/${id}`,
                updatedIconFile,
                handleProgess,
                false,
                true,
            );
            return url;
        } else {
            throw new Error('Image file is not provided');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const data = customAppInfoFormData;
            if (data._id) {
                await updateCustomAppInfo(data, facilityId);
                if (updatedIconFile) {
                    const url = await uploadImageFile(data._id);
                    data.appCircle.icon = url;
                    await updateCustomAppInfo(data, facilityId);
                }
            } else {
                const id = await addCustomAppInfo(data, facilityId);
                setCustomAppInfoFormData((prevData) => ({ ...prevData, _id: id }));
                if (updatedIconFile) {
                    const url = await uploadImageFile(id);
                    data.appCircle.icon = url;
                    data._id = id;
                    await updateCustomAppInfo(data, facilityId);
                }
            }
            toast.success('Successfully saved custom app info', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (err) {
            console.error(err);
            toast.error(`Failed to save custom app info - ${err}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            history.push('/admin/facilities/list');
            setLoading(false);
        }
    };

    const handleCancel = () => {
        // Reset the form data to the initial state
        if (!customAppInfo) {
            setCustomAppInfoFormData({
                appCircle: {
                    icon: '',
                    label: '',
                    link: '',
                    androidLink: '',
                },
                appLinks: Array.from({ length: 5 }, () => ({
                    label: '',
                    link: '',
                })),
            });
            setCurrIconFile(null);
            setCurrIconUrl(null);
            setUpdatedIconFile(null);
            setUpdatedIconUrl(null);
        } else {
            setCurrIconUrl(customAppInfo.appCircle.icon);
            setUpdatedIconUrl(customAppInfo.appCircle.icon);
            setCurrIconFile(null);
            setUpdatedIconFile(null);
            setCustomAppInfoFormData({
                _id: customAppInfo._id,
                appCircle: {
                    icon: customAppInfo.appCircle.icon,
                    label: customAppInfo.appCircle.label,
                    link: customAppInfo.appCircle.link,
                    androidLink: customAppInfo.appCircle.androidLink,
                },
                appLinks: customAppInfo.appLinks,
            });
        }
    };
    const handleIconModalClose = () => {
        setUpdatedIconFile(currIconFile);
        setUpdatedIconUrl(currIconUrl);
        setOpen(false);
    };
    const handleIconModalOpen = () => {
        setOpen(true);
    };
    const handleSelectIcon = () => {
        setCurrIconUrl(updatedIconUrl);
        setCurrIconFile(updatedIconFile);
        setOpen(false);
        toast.info('Please make sure to click save button in order to upload the image.', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    };
    return (
        <div style={{ position: 'relative' }}>
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            <Form>
                <p>Custom App Circle</p>
                <Segment style={{ cursor: 'pointer', textAlign: 'center' }} onClick={handleIconModalOpen}>
                    Click here to view/upload icon
                </Segment>
                <Form.Field>
                    <label style={{ marginTop: '10px' }}>Label</label>
                    <input
                        placeholder="Label"
                        name="label"
                        value={customAppInfoFormData.appCircle.label}
                        onChange={(e) => handleInputChange(e, 'label')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Link</label>
                    <input
                        placeholder="Link"
                        name="link"
                        value={customAppInfoFormData.appCircle.link}
                        onChange={(e) => handleInputChange(e, 'link')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Android link</label>
                    <input
                        placeholder="Android link"
                        name="androidLink"
                        value={customAppInfoFormData.appCircle.androidLink}
                        onChange={(e) => handleInputChange(e, 'androidLink')}
                    />
                </Form.Field>
                <p style={{ marginTop: '30px' }}>Custom App Links Popup</p>
                {customAppInfoFormData.appLinks.map((link, index) => (
                    <Form.Group key={index} widths="equal">
                        <Form.Field>
                            <label>Label</label>
                            <input
                                placeholder="Label"
                                value={link.label}
                                onChange={(e) => handleAppLinkChange(index, 'label', e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Link</label>
                            <input
                                placeholder="Link"
                                value={link.link}
                                onChange={(e) => handleAppLinkChange(index, 'link', e.target.value)}
                            />
                        </Form.Field>
                    </Form.Group>
                ))}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="button" onClick={(e) => handleSubmit(e)}>
                        Save
                    </Button>
                    <Button type="button" onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            </Form>
            <Modal size={'small'} open={open} onClose={handleIconModalClose}>
                <Modal.Content>
                    <Segment padded basic>
                        <FilePicker
                            {...filePickerStatus}
                            onSelected={handleFileSelect}
                            multiple={false}
                            accept={['image/*']}
                        />
                    </Segment>
                    {updatedIconUrl ? (
                        <>
                            <img src={updatedIconUrl} alt="icon" style={{ width: '100%' }} />
                        </>
                    ) : (
                        <></>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={handleIconModalClose}>
                        Cancel
                    </Button>
                    <Button positive onClick={handleSelectIcon}>
                        Select
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default withRouter(CustomAppInfoForm);
