import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, List, Checkbox, Grid, Dropdown, Select } from 'semantic-ui-react'
import { Facility } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchAllActiveFacilities } from '../../../../services/Facilities'
import { toggleContentStatusEmail, updateSelectedCalendarTypes } from '../../../../services/ContentStatusEmail'
import { listRoomTypes } from '../../../../services/RoomTypes';
import { sortByKey } from '../../../../util/sortData';

interface State {
    facilities: Facility[]
    error: string | null
    isFetching: boolean
    page: number
    count: number
    limit: number
    toggleLoadingId: string;
    locationOptions: {
        key: string;
        text: string;
        value: string;
    }[];
    dropdownLoader;
}

class FacilitiesList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            facilities: [],
            error: null,
            isFetching: false,
            page: 0,
            count: 0,
            limit: 10000,
            toggleLoadingId: '',
            locationOptions: [],
            dropdownLoader: false,
        }
    }

    componentDidMount() {
        this.setState({
            isFetching: true,
        });
        this.fetchData();
        listRoomTypes().then((res) => {
            const locationOptions = res.Result && res.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
            this.setState({
                locationOptions
            });
        })
        this.setState({
            isFetching: false,
        });
    }

    async fetchData() {
        this.setState({
            error: null,
        })

        try {
            const facilities = await fetchAllActiveFacilities()
            this.setState({
                facilities,
                error: null,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Facilities.'
            this.setState({
                error: msg,
            })
        }
    }

    navigateToCreate() {
        return this.props.history.push('/admin/facilities/create')
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/facility/${id}`)
    }

    async handleContentStatusEmailToggle(facilityId: string, checked: boolean) {
        try{
            this.setState({
                toggleLoadingId: facilityId,
            });
            await toggleContentStatusEmail(facilityId, checked);
            await this.fetchData();
        }  catch (e) {
            const msg = e.message || 'Could not toggle Content Status Email.'
            this.setState({
                error: msg,
            })
        } finally {
            this.setState({
                toggleLoadingId: '',
            })
        }
    }

    async handleCalendarTypeUpdate(facilityId, calendarTypes: []) {
        try {
            this.setState({
                toggleLoadingId: facilityId,
            });
            await updateSelectedCalendarTypes(facilityId, calendarTypes);
            await this.fetchData();
        } catch (e) {
            const msg = e.message || 'Could not toggle Content Status Email.';
            this.setState({
                error: msg,
            });
        } finally {
            this.setState({
                toggleLoadingId: '',
            });
        }
    }

    get facilities() {
        return this.state.facilities.map((f, index) => (
            <List.Item key={f._id}>
                <List.Icon name="building outline" size="large" verticalAlign="middle" />
                <List.Content>
                    <Grid columns={2}>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column width={11} onClick={() => this.goToEdit(f._id)}>
                                <List.Header as="a">{f.Name}</List.Header>
                                <List.Description as="a">{f.URL}</List.Description>
                            </Grid.Column>
                            <Grid.Column width={5} textAlign="center">
                                {
                                    (index === 0) && <div style={{ marginBottom: '0.5em' }}>
                                        <b>Content Status Email</b>
                                    </div>
                                }
                                <Checkbox
                                    toggle
                                    checked={f.contentStatusEmail}
                                    disabled={this.state.toggleLoadingId === f._id}
                                    onClick={async (e, data) => {
                                        e.stopPropagation();
                                        await this.handleContentStatusEmailToggle(f._id, data.checked as boolean);
                                    }}
                                />
                                {
                                    f.contentStatusEmail && <div>
                                        <Dropdown
                                            selection
                                            multiple
                                            control={Select}
                                            placeholder="Select calendar types "
                                            options={sortByKey(this.state.locationOptions)}
                                            onChange={(e, { value }) => {
                                                console.log("value", value)
                                                if (value) {
                                                    this.handleCalendarTypeUpdate && this.handleCalendarTypeUpdate(f._id, value as []);
                                                }
                                            }}
                                            value={f.selectedCalendarTypes}
                                            loading={this.state.toggleLoadingId === f._id}
                                        />
                                    </div>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </List.Content>
            </List.Item>
        ));
    }

    render() {
        return (
            <div className="FacilitiesList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.navigateToCreate.bind(this)}
                />
                <List divided relaxed>
                    {this.facilities}
                </List>
            </div>
        )
    }
}

export default withRouter(FacilitiesList)
