import React from "react";
import { Header } from "semantic-ui-react";

const DisabledContentPage = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '-15em' }}>
            <Header as='h1' disabled>
                Orders disabled for this facility
            </Header>
        </div>
    );
};

export default DisabledContentPage;