import React, { useEffect, useContext, useState, useMemo } from 'react';
import { fetchMessageAnalytics } from '../../services/Analytics';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input, Button, Dimmer, Loader, Dropdown, Grid } from 'semantic-ui-react';
import './style.less';
import SortableTable from './SortedTable';
import * as jsonexport from 'jsonexport/dist';
import { saveAs } from 'file-saver';
import "./style.less"
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import moment from 'moment-timezone';

interface filters {
    toDate?: number;
    fromDate?: number;
    pageLimit: number;
    pageNo: number;
    messageType?: string;
    userQuery?: string;
    facility?: string
}

const MessagesTable = ({ startDate, endDate } : {startDate: Date, endDate: Date}) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    const facilityTZ = profile && profile.FacilityTimeZone;
    const defaultFilters = {
        pageLimit: 40,
        pageNo: 1,
        messageType: 'all',
    };
    const [messages, setMessages] = useState<any>([]);
    const [itemCount, setItemCount] = useState(0);
    const [itemReadCount, setItemReadCount] = useState(0);
    const [filters, setFilters] = useState<filters>(defaultFilters);
    const [loading, setLoading] = useState<boolean>(false);
    const [nameQuery, setNameQuery] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [dateFilters, setDateFilters] = useState<any>({});
    const [sortByStr, setSortByStr] = useState<any>([]);
    const [isDownloadingReport, setIsDownloadingReport] = useState(false);
    const [isDownloadingLastSentReport, setIsDownloadingLastSentReport] = useState(false);
    const [isExportingToCSV, setIsExportingToCSV] = useState(false);
    const [columnSortData, setColumnSortData] = useState({
        facilityName: {
            isSorted: false,
            isSortedDesc: false,
        },
        To: {
            isSorted: false,
            isSortedDesc: false,
        },
        residentName: {
            isSorted: false,
            isSortedDesc: false,
        },
        relativeName: {
            isSorted: false,
            isSortedDesc: false,
        },
        sentAt: {
            isSorted: true,
            isSortedDesc: true,
        },
        readAt: {
            isSorted: false,
            isSortedDesc: false,
        },
        type: {
            isSorted: false,
            isSortedDesc: false,
        },
        reply: {
            isSorted: false,
            isSortedDesc: false,
        },
    });

    const fetchMessages = async () => {
        setLoading(true);
        try {
            const messageData = await fetchMessageAnalytics({ ...filters, sortBy: sortByStr.length ? sortByStr : "sentAt:desc" });
            if (messageData) {
                const { messages = [], totalItemCount = 0, messageReadCount = 0 } = messageData;
                setItemCount(totalItemCount);
                setItemReadCount(messageReadCount)
                const formattedMessages = messages.map((message) => {
                    const sentAt = moment.tz(message.sentAt, facilityTZ).format('M/D/YY h:mm A');
                    const readAt = message.readAt && moment.tz(message.readAt, facilityTZ).format('M/D/YY h:mm A') || '-';
                    const reply = message.responseText ? "True" : "False";
                    return { ...message, sentAt, readAt, reply };
                });
                setMessages(formattedMessages);
                setErrorInfo('');
            } else {
                setErrorInfo('Something went wrong. Please try again');
            }
        } catch (error) {
            setErrorInfo('An error occurred: ' + (error instanceof Error ? error.message : 'Something went wrong'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (filters.fromDate && filters.toDate) {
            fetchMessages();
        }
    }, [filters]);

    useEffect(() => {
        if (startDate && endDate) {
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const startTimestamp = moment.tz(formattedStartDate, 'YYYY-MM-DD', facilityTZ).startOf('day').valueOf();
            const endTimestamp = moment.tz(formattedEndDate, 'YYYY-MM-DD', facilityTZ).endOf('day').valueOf();
            setFilters({ ...filters, fromDate: startTimestamp, toDate: endTimestamp });
        }
    }, [startDate, endDate]);

    const nextPage = () => {
        filters.pageNo * filters.pageLimit < itemCount && setFilters({ ...filters, pageNo: filters.pageNo + 1 });
    };
    const previousPage = () => {
        filters.pageNo > 1 && setFilters({ ...filters, pageNo: filters.pageNo - 1 });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Facility',
                accessor: 'facilityName',
            },
            {
                Header: 'To',
                accessor: 'To',
            },
            {
                Header: 'Resident Name',
                accessor: 'residentName',
            },
            {
                Header: 'Username',
                accessor: 'relativeName',
            },
            {
                Header: 'Sent At',
                accessor: 'sentAt',
            },
            {
                Header: 'Read At',
                accessor: 'readAt',
            },
            {
                Header: 'Message type',
                accessor: 'type',
            },
            {
                Header: 'Message Reply',
                accessor: 'reply',
            },
        ],
        [],
    );

    const data = useMemo(() => messages, [messages]);

    const handleFilterChange = (filters) => {
        setFilters(filters);
    };

    const handleNameQueryChange = () => {
        if (!nameQuery) {
            const { userQuery, ...otherOptionsOfFilter } = filters;
            handleFilterChange(otherOptionsOfFilter);
        } else {
            handleFilterChange({ ...filters, userQuery: nameQuery });
        }
    };

    const processSortBy = (columnSortData) => {
        const sortStringArray: Array<string> = [];

        for (const property in columnSortData) {
            if (columnSortData[property].isSorted) {
                sortStringArray.push(`${property}:${columnSortData[property].isSortedDesc ? 'desc' : 'asc'}`);
            }
        }

        const sortString = sortStringArray.join(',');
        setSortByStr(sortString);
    };

    const setManualSortBy = (columnId) => {
        if (!(columnId === 'type' || columnId === 'facilityName')) {
            const newColumnSortData = { ...columnSortData };
            if (columnSortData[columnId].isSorted) {
                newColumnSortData[columnId] = {
                    ...columnSortData[columnId],
                    isSorted: !columnSortData[columnId].isSortedDesc,
                    isSortedDesc: !columnSortData[columnId].isSortedDesc,
                };
            } else {
                newColumnSortData[columnId].isSorted = true;
            }
            setColumnSortData(newColumnSortData);
            processSortBy(newColumnSortData);
            setFilters({ ...filters, pageNo: 1 });
        }
    };

    return (
        <div className="messages-page">
            <div className='filters'>
                <div>
                    <div className="caption">Message Count</div>
                    <div className="message-count">{itemCount}</div>
                </div>
                <div>
                    <div className="caption">Messages Read</div>
                    <div className="message-count">{itemReadCount}</div>
                </div>
                <div>
                    <div className="caption">Messages Unread</div>
                    <div className="message-count">{itemCount - itemReadCount}</div>
                </div>
                <div>
                    <div className="caption">Messages Unread</div>
                    <div className="message-count">
                        {
                            `${(isNaN(((itemCount - itemReadCount) / itemCount) * 100) ?
                                0 :
                                (((itemCount - itemReadCount) / itemCount) * 100).toFixed(2))
                            }%`
                        }
                    </div>
                </div>
                <div>
                    <div className="caption">Message Type</div>
                    <Dropdown
                        fluid
                        selection
                        options={[
                            { value: 'all', text: 'All' },
                            { value: 'text', text: 'Text' },
                            { value: 'audio', text: 'Audio' },
                            { value: 'image', text: 'Image' },
                            { value: 'video', text: 'Video' },
                        ]}
                        defaultValue={filters.messageType}
                        onChange={(event, data) => {
                            handleFilterChange({ ...filters, messageType: data.value });
                        }}
                    />
                </div>
                <div>
                        <div className="caption">Username</div>
                        <div style={{ display: 'flex' }}>
                        <Input
                            value={nameQuery}
                            onChange={(event, data) => {
                                setNameQuery(data.value);
                            }}
                        />
                        <Button className="tiny primary ml-4" style={{height:'35px'}}  onClick={handleNameQueryChange}>
                            Search
                        </Button>
                        </div>
                    </div>
            </div>

            {errorInfo && <div className="error-info">{errorInfo}</div>}

            <div className="table-container">
                <Dimmer active={loading} inverted>
                    <Loader active={loading}></Loader>
                </Dimmer>
                {!loading && messages && (
                    <SortableTable
                        columns={columns}
                        data={data}
                        nextPage={{ nextPage, disableNext: !(filters.pageNo * filters.pageLimit < itemCount) }}
                        previousPage={{ previousPage, disablePrev: filters.pageNo === 1 }}
                        pageNumber={filters.pageNo}
                        loading={loading}
                        setManualSortBy={setManualSortBy}
                        columnSortData={columnSortData}
                        height={'40vh'}
                    />
                )}
            </div>
        </div>
    );
};

export default MessagesTable;
