import { TURNBillingFacilityInfo } from "../types/WebRTC";
import API from "./API";

export const getTURNCreds = async (UserId: string) => {
    try {
        const res = await API.lambdaGet('/webrtc/turn/creds', { UserId });
        return res;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const RUNTwilioScript = async () => {
    try {
        const res = await API.lambdaGet('/twilio/script');
        return res;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getBillingInfo = async (): Promise<TURNBillingFacilityInfo[]> => {
    try {
        const res = await API.lambdaGet('/webrtc/turn/facilities/billing');
        return res;
    } catch (error) {
        console.error(error);
        throw error;
    }
}