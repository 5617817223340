import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../actions/auth'
import './style.css'
import { AppState } from '../../reducers'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import { askForPermissioToReceiveNotifications } from '../../services/PushNotifications';
import FederatedUserDetailsForm from '../../components/FederatedUserDetailsForm';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [login, setLogin] = useState(false)
    const dispatch = useDispatch();

    // Mapping state from redux store
    const {
        isAuthenticated,
        cacheLoginFailed, 
        federatedAuthUser, 
        federatedUserExistsInDB, 
    } = useSelector((state: AppState) => state.authReducer);

    useEffect(() => {
        if (cacheLoginFailed && !federatedAuthUser) { // if federatedAuthUser is true, then we are redirect the user to fill in the form instead of calling the login action
            console.log("trying to reach hosted ui")
            handleLogIn()
            setLogin(true)
        }
    
        if (!cacheLoginFailed) {
            dispatch(actions.logInFromCache());
            if (!cacheLoginFailed)
                askForPermissioToReceiveNotifications();
        }

    }, [cacheLoginFailed, dispatch, login])

    const handleLogIn = async () => {
        await dispatch(actions.logIn(username, password));
        await askForPermissioToReceiveNotifications();
    };

    const from = props.location.state && props.location.state.from
        ? props.location.state.from + props.location.search
        : '/' + props.location.search;
    const renderLoginForm = () => {
        return (
            <div className="Login">
                <Dimmer active={!cacheLoginFailed} inverted>
                    <Loader size='medium' active={!cacheLoginFailed} content={`Processing `} />
                </Dimmer>
                <div className="ask-jillian-login">
                    <a href="https://www.askjillian.app/">
                        <img src={`${process.env.PUBLIC_URL}/ask_jillian.png`} />
                    </a>
                </div>
                <div className="loginBox">
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="" className="logo" />
                </div>
            </div>
        );
    };

    if (isAuthenticated) {
        return <Redirect to={from} />
    }

    if(federatedAuthUser && !federatedUserExistsInDB) {
        return <FederatedUserDetailsForm />
    }
    return renderLoginForm()
};

export default withRouter(Login);
