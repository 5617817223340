import React from 'react'
import { Item, Button } from 'semantic-ui-react'
import './style.less'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { sendToast } from '../../util';

interface Props extends RouteComponentProps {
    id: string | undefined
    text: string
    time: any
    sourcePage: string
    deleteListItem: (id) => any
    editListItem: (id) => any
    copyListItem?: (id) => any
    playableUrl?: string
    hideActionButtons?: boolean
    itemClickHandler?: (id) => void
    svcMenuInstance?: boolean
    svcActivityInstance?: boolean
    selectedDate?: Date
}

const getPOTMDisplayDateString = (dateString: string) => {
    const dateAttrs = dateString.split('-')
    const year = dateAttrs[0]
    const date = Number(dateAttrs[2])
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    const month = months[Number(dateAttrs[1]) - 1]
    return `${date} ${month}, ${year}`
}

const formatTime = (time, sourcePage) => {
    if (sourcePage === 'DailyActivities') {
        return `${new Date(time).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
        })} 
        ${new Date(time).toLocaleTimeString('en-US')}`
    }
    if (sourcePage === 'Menu') {
        return `${new Date(time).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
        })}`
    }
    if (['StaffOfTheMonth', 'ResidentOfTheMonth', 'DailyAffirmations'].includes(sourcePage))
        return `${getPOTMDisplayDateString(time[0])} - ${getPOTMDisplayDateString(time[1])}`
    if (sourcePage === "Birthdays" || sourcePage === "residentCalendar") {
        return time
    }
}


const ListItemElement: React.FC<Props> = (props) => {
    const { history } = props;
    const handleEditRedirect = (id) => {
        const selectedTab = history.location.search;
        const selectedDate = moment(props.selectedDate).format('YYYY-MM-DD');
        if (props.sourcePage === "Menu") {
            if (props.svcMenuInstance) {
                //We will block all the Service instance in the menu if the dining is turned off and the list will only be shown when the dining is disabled so we are blocking the service instance here.
                sendToast("error", "Dining is not enabled for the facility. Please contact the facility administrator.")
            } else {
                props.history.push(`/admin/menu/${id}/false`, { selectedTab, selectedDate });
            }
        } else if (props.sourcePage === "DailyActivities") {
            if (props.svcActivityInstance) {
                props.history.push(`/admin/dailyactivities/serviceinstance/${id}`, { selectedTab, selectedDate });
            } else {
                props.history.push(`/admin/dailyactivities/${id}/false`, { selectedTab, selectedDate });
            }
        }
    }
    return ['StaffOfTheMonth', 'ResidentOfTheMonth', 'DailyAffirmations', 'Birthdays'].includes(props.sourcePage) ? (
        <Item className="ListItem">
            <Item.Content verticalAlign="middle">
                <Item.Description as="div">
                    {<div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
                </Item.Description>

                {props.playableUrl && (
                    <Item.Description>
                        <audio controls src={props.playableUrl}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                    </Item.Description>
                )}

                <Item.Meta className="list-item-time">{formatTime(props.time, props.sourcePage)}</Item.Meta>
                <Item.Meta className="meta_options_column"></Item.Meta>

                <Item.Meta onClick={() => {props.editListItem(props.id)}} className="meta_options_column">
                    <Button className='editBtn'>{'Edit'}</Button>
                </Item.Meta>
                <Item.Meta className="meta_options_column" onClick={() => props.deleteListItem(props.id)}>
                    <Button className='deleteBtn'>{'Delete'}</Button>
                </Item.Meta>
                <Item.Meta
                    className="meta_options_column"
                    onClick={async () => {
                        if (props.copyListItem) {
                            await props.copyListItem(props.id)
                        }
                    }}
                >
                    <Button className='copyBtn'>{'Copy'}</Button>
                </Item.Meta>
            </Item.Content>
        </Item>
    ) : (
        <Item className="ListItem" onClick = {() => {if(props.itemClickHandler)props.itemClickHandler(props.id)}}>
            <Item.Content verticalAlign="middle">
                <Item.Description as="div">
                    {<div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
                </Item.Description>

                {props.playableUrl && (
                    <Item.Description>
                        <audio controls src={props.playableUrl}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                    </Item.Description>
                )}
                <Item.Meta className="list-item-time">{formatTime(props.time, props.sourcePage)}</Item.Meta>
                {!props.hideActionButtons ? <>
                        <Item.Meta onClick={() => handleEditRedirect(props.id)} className="meta_options_column">
                        <Button className='editBtn'>{'Edit'}</Button>
                    </Item.Meta>

                    <Item.Meta className="meta_options_column" onClick={() => props.deleteListItem(props.sourcePage === "DailyActivities" ? {id:props.id} : props.id)}>
                        <Button className='deleteBtn'>{'Delete'}</Button>
                    </Item.Meta>
                    <Item.Meta
                        className="meta_options_column"
                        onClick={() => {
                            if (props.copyListItem) {
                                props.copyListItem(props.id)
                            }
                        }}
                    >
                        <Button className='copyBtn'>{'Copy'}</Button>
                    </Item.Meta>
                </> : <></>}


            </Item.Content>
        </Item>
    )
}

export default withRouter(ListItemElement)
