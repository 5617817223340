import React, { FC, useEffect, useState } from 'react'
import { Button, Dimmer, Grid, Header, Loader } from 'semantic-ui-react'
import { upsertResidentDietery } from '../../services/Registrants'
import PrintExportIcons from '../PrintExportIcons'
import GoalsInputBoxes from './GoalsInputBoxes'
import RestrictionsCheckBoxes from './RestrictionsCheckBoxes'
import useDietary from './useDietary'
import { toast } from 'react-toastify'
import DieteryForm from '../DieteryForm'

interface Props {
    residentId: string
    residentName: string
    inactiveResident?: boolean
}
const ResidentDietery: FC<Props> = ({ residentId, residentName, inactiveResident = false }) => {
    const [dieteryState, dispatch, loading, resetData, setFetchedDataForReducer, fetchedData] = useDietary(residentId)
    const [isLoading, setIsLoading] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(true)
    const onPrintClick = () => { }

    useEffect(() => {
        if (!loading) {
            const keys = ['goals', 'restrictions'];
            const isChanged = keys.some(key => {
                const subKeys = Object.keys(dieteryState[key])
                return subKeys.some(subKey => fetchedData[key][subKey] !== dieteryState[key][subKey])
            })
            if (isChanged) {
                setDisableSaveButton(false)
            } else {
                setDisableSaveButton(true)
            }
        }
    }, [dieteryState, fetchedData])

    const saveBtnClickHandler = async () => {
        try {
            setIsLoading(true)
            await upsertResidentDietery(dieteryState, residentId)
            setFetchedDataForReducer(dieteryState) // reset fetched data in reducer with state instead of fetching
        } catch (error) {
            console.error({ error })
            toast.warn("Failed to update resident deitery", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const cancelBtnClickHandler = () => {
        resetData()
    }

    const getExportData = () => {
        return [{ Name: residentName, ...fetchedData }]
    }
    return (
        <div style={{ position: "relative" }}>
            <Dimmer inverted active={isLoading || loading}>
                <Loader active={isLoading || loading} />
            </Dimmer>
            <div style={{ display: "flex", gap: "4px", margin: "10px 0px" }}>
                <Button color='blue' loading={isLoading || loading} disabled={disableSaveButton || inactiveResident} onClick={saveBtnClickHandler}>Save Resident</Button>
                <Button color='grey' basic onClick={cancelBtnClickHandler}>Cancel</Button>
                <PrintExportIcons exportData={getExportData()} onPrintClick={onPrintClick} disablePrintButton={true} disableExportButton={isLoading} exportFileName={`${residentName}-dietary`} />
            </div>
             <DieteryForm dieteryState={dieteryState} dispatch={dispatch} readonly={inactiveResident}/>
        </div>
    )
}

export default ResidentDietery