 //Adds text area in a segment that appears like a card.
import React, { useState } from 'react';
import { Form, TextArea, Segment } from 'semantic-ui-react';

interface LightTextAreaProps {
    label: string;
    name?: string; /* Key */
    placeholder?: string;
    value?: any;
    onChange?: (value: string, name?: string) => void;
    error?: boolean;
    required?: boolean;
    readonly?: boolean;
}

const SegmentedTextArea: React.FC<LightTextAreaProps> = ({ label, value, onChange, required, readonly=false, name }) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <Segment size='small'>
            <Form.Field inline required={required} disabled={readonly}>
                <label style={{ display: "block" }}>{label}</label>
                <TextArea
                    value={value}
                    onChange={(e, data) => {
                        const { value = "" } = data;
                        if (onChange) onChange(String(value), name)
                    }}
                    onBlur={() => setIsFocused(false)}
                    onClick={() => setIsFocused((prev) => !prev)}
                    style={{
                        border: isFocused ? '1px solid #85bb65' : '1px solid #dee2e6',
                        borderRadius: '0.28571429rem',
                        transition: 'box-shadow 0.1s ease, border-color 0.1s ease',
                        padding: "5px",
                        margin: "10px 0px",
                        width: "100%"
                    }}
                    transparent={true}
                    disabled={readonly}
                    name={name}
                />
            </Form.Field>
        </Segment>
    );
};

export default SegmentedTextArea;
