import React from 'react'
import DepartmentsForm, { FormValues } from '../DepartmentsForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Department } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchOneDepartment, updateDepartment, deleteDepartment } from '../../../../services/Departments'

interface State {
    isFetching: boolean
    department: Department | null
    isSaving: boolean
    error: string | null
    refreshDepartmentFormKey: number
}

interface RouteInfo {
    id: string
}

interface Props extends RouteComponentProps<RouteInfo> {
    refreshListFun?: () => void
}

class DepartmentsEdit extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            department: null,
            isFetching: false,
            refreshDepartmentFormKey: 0
        }

        this.id = props.match.params.id
    }

    async fetchData(id?: string) { 
        this.setState({ isFetching: true, error: null })
        try {
            const department = await fetchOneDepartment(id || this.id)
            this.setState({ isFetching: false, department })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }
    async componentDidMount() {
        await this.fetchData()
    }

    async componentDidUpdate(prevProps: RouteComponentProps<RouteInfo>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.id = this.props.match.params.id
            await this.fetchData(this.props.match.params.id)
            this.setState(prev => {
                return {
                    refreshDepartmentFormKey: prev.refreshDepartmentFormKey + 1
                }
            })
        }
    }

    goBack() {
        this.props.history.push('/admin/forms');
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        const postData = {
            ...data,
            _id: this.id,
        }
        try {
            await updateDepartment(postData)
            this.setState({ isSaving: false })
            this.goBack()
            if(this.props.refreshListFun) this.props.refreshListFun()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(id: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteDepartment(id)
            this.setState({ isSaving: false })
            this.goBack()
            if(this.props.refreshListFun) this.props.refreshListFun()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditDepartment">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.department && (
                    <DepartmentsForm
                        title="Edit Department"
                        department={this.state.department}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        {...this.state}
                        key={this.state.refreshDepartmentFormKey}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(DepartmentsEdit)
