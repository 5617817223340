import React from 'react'
import { Item } from 'semantic-ui-react'
import './style.less'
import { AuthState, OrderIdColorDict, Request } from '../../types'
import RequestItem from '../RequestItem'
import { useSelector } from 'react-redux'
import momentTZLib from 'moment-timezone';
import { RequestInstance } from '../../types/RequestInstance';

interface Props {
    requests: Request[] | RequestInstance[]
    unassigned: boolean
    handleClick: (id: string, req: Request) => any
    refresh: () => void
    powerAndFacilityUsers?: boolean;
    sortBy?: "desc" | "asc" | string;
    orderIdColorDict?: OrderIdColorDict;
    isCompactView?: boolean;
}

const RequestsList: React.FC<Props> = props => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const facilityTimeZone = profile ? profile.FacilityTimeZone : null;
    if (props.sortBy) {
        props.requests.sort((a, b) => { // we are following reverse logic. Basically if asc is sent in props we sort it based according to descending order and vice-versa. The reason we do this is because backend is faulty and sends the data in descending order when it should be in ascending order.
            const dateA = facilityTimeZone ? momentTZLib.tz(a.RequestedTime, facilityTimeZone) : momentTZLib(a.RequestedTime);
            const dateB = facilityTimeZone ? momentTZLib.tz(b.RequestedTime, facilityTimeZone) : momentTZLib(b.RequestedTime);

            if (props.sortBy === "asc") {
                if (dateB.isBefore(dateA)) {
                    return -1; // if b is in the past, put a first
                } else if (dateA.isBefore(dateB)) {
                    return 1; // if a is in the past, put b first
                } else {
                    return 0;
                }
            } else {
                if (dateA.isBefore(dateB)) {
                    return -1; // if a is in the past, put a first
                } else if (dateB.isBefore(dateA)) {
                    return 1; // if b is in the past, put b first
                } else {
                    return 0;
                }
            }
        });

    } else {
        props.requests.sort(function (a, b) {
            const dateA = facilityTimeZone ? momentTZLib.tz(a.RequestedTime, facilityTimeZone) : momentTZLib(a.RequestedTime);
            const dateB = facilityTimeZone ? momentTZLib.tz(b.RequestedTime, facilityTimeZone) : momentTZLib(b.RequestedTime);

            if (dateB.isBefore(dateA)) {
                return -1; // if b is in the past, put a first
            } else if (dateA.isBefore(dateB)) {
                return 1; // if a is in the past, put b first
            } else {
                return 0;
            }
        });
    }

    function groupRequests(requests: Request[] | RequestInstance[]): Request[] {
        const groupedRequests: Request[] = [];

        requests.forEach((currentRequest) => {
            let foundSimilar = false;

            // Loop through grouped requests to find a match
            for (const groupedRequest of groupedRequests) {
                if (
                    currentRequest.RequestChannel === "ExternalDevice" &&
                    groupedRequest.RequestChannel === "ExternalDevice" &&
                    groupedRequest.Registrant_FirstName === currentRequest.Registrant_FirstName &&
                    groupedRequest.Registrant_LastName === currentRequest.Registrant_LastName &&
                    groupedRequest.Status === "Open"
                ) {
                    // If similar request is found, group
                    if (!groupedRequest.similarRequestIds || !Array.isArray(groupedRequest.similarRequestIds) || groupedRequest.similarRequestIds.length === 0) {
                        groupedRequest.similarRequestIds = [groupedRequest._id];
                        groupedRequest.requestCount = 1;
                    }
                    groupedRequest.similarRequestIds.push(currentRequest._id);
                    groupedRequest.requestCount! += 1; // ! is used to tell typescript that this value will never be null
                    foundSimilar = true;
                    break;
                }
            }

            // If no similar request was found, add it as a new grouped request
            if (!foundSimilar) {
                groupedRequests.push({ ...currentRequest });
            }
        });

        return groupedRequests;
    }

    const groupedRequests = groupRequests(props.requests);

    const requestItems = groupedRequests
        .map(req => (
            <RequestItem
                powerAndFacilityUsers={props.powerAndFacilityUsers}
                key={req._id}
                id={req._id}
                requestName={req.Name || 'Unknown'}
                requestTime={req.RequestedTime}
                residentName={req.Registrant_FirstName + ' ' + req.Registrant_LastName}
                escalatedIndicator={(req.EscalatedTo && props.unassigned) || false}
                avatarUrl={req.Registrant_Image}
                room={req.Unit ? req.Unit_Name : 'Unknown Room'}
                onClick={() => props.handleClick(req._id, req)}
                category={req.RequestType_Icon || "Other"}
                request={{ ...req }}
                refresh={props.refresh}
                orderId={req.OrderId}
                orderIdColorDict={props.orderIdColorDict || {}}
                isCompactView={props.isCompactView}
            />
        ));

    return (
        <Item.Group style={{ maxHeight: props.powerAndFacilityUsers ?  '90vh' : '', width: '100%', margin: props.isCompactView ? '0' : ''}} className='request-list' divided>
            {requestItems}
        </Item.Group>
    )
}

export default RequestsList
