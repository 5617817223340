import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Confirm, Dimmer, Grid, Icon, Input, Loader, Modal, Segment } from 'semantic-ui-react';
import AssetsModal from '../../../components/AssetsGroupsManagement/AssetsModal';
import { Asset, Availability } from '../../../types/Asset';
import { deleteAsset, listPaginatedAssets } from '../../../services/Assets';
import { toast } from 'react-toastify';
import { UserProfile } from '../../../types';
import LoadMore from '../../../components/LoadMore';
import { fetchServicesByFilter } from '../../../services/service';
import { sendToast } from '../../../util';

interface Props {
    profile: UserProfile | null;
}

const Assets: FC<Props> = ({ profile }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [assetList, setAssetsList] = useState<Asset[]>([]);
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');
    const [deleteImageName, setDeleteImageName] = useState<string>('');
    const [openAvailability, setOpenAvailability] = useState<boolean>(false);
    const [currentAvailability, setCurrentAvailability] = useState<Availability[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMorePages, setHasMorePages] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [pagelimit, setPageLimit] = useState<number>(12);
    const [assetConfirmationMessage, setAssetConfirmationMessage] = useState<string>('');
    const [openServiceDeleteModal, setOpenServiceDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        getAllAssets();
    }, [page]);

    const handleOpenAssetModal = (id: string) => () => {
        setEditId(id);
        setOpen(true);
    };
    const closeConfirmDialog = () => {
        setConfirmDialog(false);
        setDeleteId("");
    };
    const closeServiceDeleteModal = () => {
        setOpenServiceDeleteModal(false);
        setDeleteId("");
    }
    const deleteOneAsset = async () => {
        setLoading(true);
        setConfirmDialog(false);
        try {
            await deleteAsset(deleteId, deleteImageName);
            setAssetsList((prevAssetsList) => prevAssetsList.filter(asset => asset._id !== deleteId));
            toast.success('Asset Deleted Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error in Deleting Asset', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const getAllAssets = async () => {
        setLoading(true);
        try {
            const fetchPage = searchQuery !== '' ? 1 : page;
            const data = await listPaginatedAssets({ pageSort: { page_no: fetchPage, page_size: pagelimit, sort: [{ DateAdded: 'asc' }] }, filters: searchQuery !== "" ? { AssetName: searchQuery } : {} });
            const hasMorePages = !(data.Result.length < pagelimit);
            setHasMorePages(hasMorePages);
            let sortedData = data.Result || [];
            // Sort alphabetically by AssetName if the array is not empty
            if (sortedData.length > 0) {
                sortedData = sortedData.sort((a, b) => a.AssetName.localeCompare(b.AssetName));
            }

            if (searchQuery === '') {
                if (page === 1) {
                    // For the first page, replace the entire assetsLists with the sorted data
                    setAssetsList([...sortedData]);
                } else {
                    const uniqueData = sortedData.filter(newItem => !assetList.some(existingItem => existingItem._id === newItem._id));
                    const updatedList = [...assetList, ...uniqueData];
                    // Sort alphabetically if the array is not empty
                    if (updatedList.length > 0) {
                        setAssetsList(updatedList.sort((a, b) => a.AssetName.localeCompare(b.AssetName)));
                    } else {
                        setAssetsList(updatedList);
                    }
                }
            } else {
                // For search results, just set the sorted data
                setAssetsList([...sortedData]);
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : 'Error in Listing Assets', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        // Trigger search when the search icon is clicked
        if (searchQuery === '') {
            setPageLimit(12);
        } else {
            setPageLimit(100);
        }
        setPage(1);
        getAllAssets();
    };

    const fetchAllServiceRelatedToAsset = async (assetId: string) => {
        try {
            // this function will fetch all services which are using this asset if there are any services using this asset then it will show a confirmation message else show the 
            // delete confirmation dialog
            if (profile && profile.Facility) {
                const servicesUsingAsset = await fetchServicesByFilter(profile.Facility, { Asset: assetId });
                if (servicesUsingAsset && servicesUsingAsset.Result && servicesUsingAsset.Result.length > 0) {
                    const serviceNames = servicesUsingAsset.Result.map(service => service.name).join(', ');
                    const contentMessage = `You can’t delete this asset as it’s attached to the following services: "${serviceNames}” 
                        If you still wish to proceed with the deletion, you must first delete the service, and then you can delete the asset.`;
                    setAssetConfirmationMessage(contentMessage);
                    setOpenServiceDeleteModal(true);
                } else {
                    setConfirmDialog(true);
                }
            }
        } catch (error) {
            console.log("Error in fetching services using asset", error);
            sendToast("warn", error instanceof Error ? error.message : 'Error in fetching services using asset');
        }
    }
    

    return (
        <div>
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            <Button onClick={handleOpenAssetModal('')}>Create Asset</Button>
            <Input
                action={{ icon: 'search', onClick: handleSearchClick }}
                placeholder='Search...'
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchClick();
                    }
                }}
            />
            <div className="asset-list-container">
                <Grid style={{ flexWrap: 'wrap', marginTop: '10px' }}>
                    {assetList
                        ? assetList.map((asset, index) => {
                              return (
                                  <Grid.Column stretched key={index} style={{ width: '25%' }}>
                                      <Card style={{ width: '100%' }}>
                                          <Card.Content>
                                              <Card.Header style={{ color: '#2987CD' }}>
                                                  {asset.AssetName}
                                                  <Icon
                                                      style={{ cursor: 'pointer', float: 'right' }}
                                                      name="edit outline"
                                                      onClick={handleOpenAssetModal(asset._id ? asset._id : '')}
                                                  />                                                
                                                  {deleteId === asset._id ? (
                                                      <Icon loading name="spinner" style={{ float: 'right', marginRight: '10px' }} />
                                                  ) : (
                                                      <Icon
                                                          style={{ cursor: 'pointer', float: 'right', marginRight: '10px' }}
                                                          name="trash"
                                                          onClick={async () => {
                                                              setDeleteId(asset._id ? asset._id : '');
                                                              setDeleteImageName(asset.ImageName ? asset.ImageName : '');
                                                              await fetchAllServiceRelatedToAsset(asset._id);
                                                          }}
                                                      />
                                                  )}
                                              </Card.Header>
                                              <Card.Meta>{asset.AssetType}</Card.Meta>
                                              <Card.Meta>
                                                  {asset.MinCap} - {asset.MaxCap}
                                              </Card.Meta>
                                              <Card.Meta>
                                                  <Segment
                                                      style={{
                                                          width: '50%',
                                                          display: 'flex',
                                                          justifyContent: 'space-around',
                                                          alignItems: 'center',
                                                          cursor: 'pointer',
                                                          margin: '10px 0px',
                                                      }}
                                                      onClick={() => {
                                                          setCurrentAvailability(asset.Availability);
                                                          setOpenAvailability(true);
                                                      }}
                                                  >
                                                      <img
                                                          src="../assets-groups-management/calendar.png"
                                                          alt="Image Input"
                                                      />
                                                      <span style={{ fontSize: '13px', color: 'black' }}>
                                                          Availability
                                                      </span>
                                                  </Segment>
                                              </Card.Meta>
                                              <Card.Meta>{asset.URL}</Card.Meta>
                                              <Card.Description>{asset.Description}</Card.Description>
                                          </Card.Content>
                                      </Card>
                                  </Grid.Column>
                              );
                          })
                        : ''}
                </Grid>
                <LoadMore hasMore={hasMorePages} isLoading={loading} next={() => setPage((prev) => prev + 1)} />
                <Confirm
                    size="tiny"
                    open={confirmDialog}                    
                    content={"Are you sure you want to delete this Asset?"}
                    onCancel={closeConfirmDialog}
                    onConfirm={deleteOneAsset}
                />
                 <Confirm // this is for deleting the asset which is being used by services
                    size="tiny"
                    style={{fontSize: '16px', color:"red"}}
                    open={openServiceDeleteModal}
                    content={assetConfirmationMessage}
                    onCancel={closeServiceDeleteModal}
                    onConfirm={closeServiceDeleteModal}
                />
                <Modal size="mini" open={openAvailability} onClose={() => setOpenAvailability(false)}>
                    <Modal.Header>Availability</Modal.Header>
                    <Modal.Content>
                        {currentAvailability.length > 0
                            ? currentAvailability.map((item) => {
                                  return (
                                      <div key={item.DayOfWeek}>
                                          <h5 style={{ color: 'black', marginBottom: '5px' }}>{item.DayOfWeek}</h5>
                                          <div style={{ marginBottom: '10px' }}>
                                              {item.StartTime} - {item.EndTime}
                                          </div>
                                      </div>
                                  );
                              })
                            : 'No Time Slots Available'}
                    </Modal.Content>
                </Modal>
            </div>
            {open === true && (
                <AssetsModal
                    open={open}
                    setOpen={setOpen}
                    editId={editId}
                    setEditId={setEditId}
                    setLoading={setLoading}
                    facilityId={profile ? profile.Facility : ''}
                    updateAssetsLists={setAssetsList}
                    assetsLists={assetList}
                />
            )}
        </div>
    );
};

export default Assets;
