import React, { FC } from 'react'
import { Checkbox, CheckboxProps} from 'semantic-ui-react'
import { CategoryType, SubCategoryType, ToggleCheckboxAction } from './useCheckbox'

interface Props {
    category: CategoryType
    subCategory?: SubCategoryType
    checkboxItems
    dispatch: React.Dispatch<ToggleCheckboxAction>
    readonly?: boolean
}
interface CheckboxItem {
    label: string;
    name: string;
    checked: boolean;
}
const CheckboxItems: FC<Props> = ({ category, subCategory = "", dispatch, checkboxItems, readonly = false }) => {
    const handleCheckboxChange: ((event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void) = async (e, { name, checked }) => {
        if (name) {
            dispatch({
                type: 'TOGGLE_CHECKBOX',
                category: category,
                subCategory: subCategory,
                checkboxName: name
            })
        } else {
            throw new Error("name is required for checkbox items")
        }
    };
    function formatString(str: string) {
        const specialCases = {
            "tv": "TV",
            "nonFatMilk": "NonFat Milk",
            "milk": "1% Milk"
        }

        const specialCaseResult = specialCases[str.toLowerCase()];
        if (specialCaseResult) {
            return specialCaseResult
        } else {
            // /(?=[A-Z])/) regex for uppercase lettter
            // /^\w/ regex for first letter
            return str.split(/(?=[A-Z])/).join(" ").replace(/^\w/, (c) => c.toUpperCase());
        }
    }
    const selecteCategoryCheckBoxItems = subCategory && checkboxItems && Object.keys(checkboxItems).length ? checkboxItems[category][subCategory] : checkboxItems[category];
    let selecteCategoryCheckBoxItemsArr: any = [];
    if (selecteCategoryCheckBoxItems) {
        selecteCategoryCheckBoxItemsArr = Object.entries(selecteCategoryCheckBoxItems).map(([key, value]) => {
            const labelString = formatString(key)
            return {
                label: labelString,
                name: key,
                checked: value
            }
        })
    }

    return (
        <div className="" style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {selecteCategoryCheckBoxItemsArr.map((el, i) => {
                return <Checkbox
                    disabled={readonly}
                    key={i}
                    label={el.label}
                    name={el.name}
                    checked={el.checked}
                    onChange={handleCheckboxChange}
                />
            })}
        </div>
    )
}

export default CheckboxItems