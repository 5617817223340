import React, { useEffect, useState } from 'react'
import { Header } from 'semantic-ui-react';
import MessagesTable from './MessageTable';
import InvitesTable from './InvitesTable';


const ReportsMessage = ({ startDate, endDate }) => {
  return (
    <div>
      <Header as='h2' style={{ marginTop: '10px', marginLeft: '22px', marginBottom: '10px' }} textAlign='left'>Connections</Header>
      <InvitesTable startDate={startDate} endDate={endDate} />
      <Header as='h2' style={{ marginTop: '10px', marginLeft: '22px', marginBottom: '10px' }} textAlign='left'>Messages</Header>
      <MessagesTable startDate={startDate} endDate={endDate} />
    </div>
  );
}

export default ReportsMessage