import React from 'react';
import DieteryForm from '../DieteryForm';
import { FetchDataAction, HandleChange, ResetData } from './reducerData';
import { DietaryState } from '../../types/Dietary';

interface Props {
    serviceDieteryState: DietaryState;
    dieteryDispatch: React.Dispatch<FetchDataAction | HandleChange | ResetData>;
}

const ServiceMealRestrictions: React.FC<Props> = ({ serviceDieteryState, dieteryDispatch }) => {
    return (
        <DieteryForm dieteryState={serviceDieteryState} dispatch={dieteryDispatch} readonly={false} />
    ); 
};

export default ServiceMealRestrictions;