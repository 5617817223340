import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Grid, Segment } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import './style.less';

interface DateRange {
    start?: Date | undefined;
    end?: Date | undefined;
}

interface DateRangePickerProps {
    dateRange?: DateRange;
    setDateRange?: (dateRange: DateRange) => void;
    closeShowdateAndTimePickerModal: () => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ dateRange, setDateRange, closeShowdateAndTimePickerModal }) => {
    const [startDate, setStartDate] = useState<Date | undefined>(dateRange && dateRange.start);
    const [endDate, setEndDate] = useState<Date | undefined>(dateRange && dateRange.end);

    useEffect(() => {
        if (dateRange) {
            setStartDate(dateRange.start);
            setEndDate(dateRange.end);
        }
    }, [dateRange]);

    const handleApply = () => {
        if (setDateRange) {
            setDateRange({ start: startDate, end: endDate });
            closeShowdateAndTimePickerModal()
        }
    };

    return (
        <Segment className="date-picker-container">
            <Grid columns={2} divided>
                <Grid.Row centered>
                    <Grid.Column width={8} >
                        <div className='center-align'>
                            <h3>Start Date & Time</h3>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date || undefined)}
                                showTimeSelect
                                inline
                                dateFormat="yyyy/MM/dd h:mm aa"
                                timeCaption="Time"
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8} >
                        <div className='center-align'>
                            <h3>End Date & Time</h3>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date || undefined)}
                                showTimeSelect
                                inline
                                dateFormat="yyyy/MM/dd h:mm aa"
                                timeCaption="Time"
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="apply-button-container">
                <Button primary className="apply-button" onClick={handleApply}>Apply</Button>
            </div>
        </Segment>
    );
};

export default DateRangePicker;
