import { fetchAllPaginatedData } from '../util';
import API from './API';

export const fetchFilteredDeviceConnectivityLogsItems = async (
    FacilityId: string,
    startDate: string,
    endDate: string,
) => {
    try {
        const params = {
            startDate,
            endDate,
            FacilityId,
        };
        // fetch paginated device connectivity logs
        const deviceConectivityLogsApiCall = async (params: any) => {
            return await API.lambdaPost('/deviceConnectivityLogs/list', params);
        };
        const deviceConectivityLogs = await fetchAllPaginatedData(deviceConectivityLogsApiCall, params);
        return deviceConectivityLogs;
    } catch (error) {
        throw error;
    }
};