import React, { FC, useEffect, useState } from 'react';
import { Dimmer, Grid, Loader, Modal, Segment } from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import { useSelector } from 'react-redux';
import { listCards } from './../../services/CheckinCards';
import './style.less';
import { AuthState } from '../../types/auth.d';
import moment from "moment-timezone";
import { Registrant } from '../../types';
import { RRule } from 'rrule';
import ReportsCheckInTable from './ReportsCheckInTable';
import { fetchAllPaginatedResidents } from '../../services/Registrants';
import { sendToast } from '../../util';

interface Props {
    startDate: Date;
    endDate?: Date;
    facilityTimeZone: string;
}

interface CheckInMainComponentProps {
    calendarCountEvents: { date: string, title: string }[];
    startDate: string;
    setModalOpen: (open: boolean) => void;
    getCheckInModalData: (info: any, isCheckin: boolean) => void;
}

type filter = {
    startTimestamp: number,
    endTimestamp: number,
    resident: string,
}

type checkInTable = {
    dateTimeString: string,
    registrantName: string,
    registrantId: string,
    status: string
    registrantFirstName: string
    registrantLastName: string
    roomName?: string
}[]

const CheckInMainComponent: FC<CheckInMainComponentProps> = ({ calendarCountEvents, setModalOpen, getCheckInModalData, startDate }) => {
    return (
        <Segment>
            <FullCalendar
                key={startDate} // this will force the calendar to re-render when startDate changes
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                initialDate={startDate} // set the initial month to the startDate's month
                editable={false}
                events={calendarCountEvents}
                customButtons={{}}
                headerToolbar={{
                    right: 'prev,next',
                }}
                eventContent={(info: any) => {
                    const [checkedInCount, notCheckedInCount] = info.event._def.title.split('|');
                    return (
                        <div
                            style={{
                                backgroundColor: 'white',
                                padding: '10px 0px',
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    getCheckInModalData(info, true);
                                }}
                                style={{ color: '#007212', fontSize: '23px' }}
                            >
                                {checkedInCount}
                            </div>
                            <div
                                style={{
                                    color: 'black',
                                    fontSize: '27px',
                                    marginTop: '-3px',
                                }}
                            >
                                |
                            </div>
                            <div
                                style={{ color: '#FF0000', fontSize: '23px' }}
                                onClick={() => {
                                    setModalOpen(true);
                                    getCheckInModalData(info, false);
                                }}
                            >
                                {notCheckedInCount}
                            </div>
                        </div>
                    );
                }}
                displayEventTime={false}
                showNonCurrentDates={false}
            />
        </Segment>
    );
};

const ReportsCheckIn: FC<Props> = ({ startDate, endDate, facilityTimeZone }) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile
    })
    const [isModalFetching, setIsModalFetching] = useState<boolean>(false);
    const [residentsFetchLoading, setResidentsFetchLoading] = useState<boolean>(false);
    const [checkInLoading, setCheckInLoading] = useState(false);
    const [residents, setResidents] = useState<Registrant[]>([]);
    const [calendarCountEvents, setCalendarCountEvents] = useState<{ date: string, title: string }[]>([]);
    const [checkinTableItems, setCheckinTableItems] = useState<checkInTable>([]);
    const [modalCheckinTableItems, setModalCheckinTableItems] = useState<checkInTable>([]);
    const [open, setOpen] = useState(false);
    const [residentDict, setResidentDict] = useState<Record<string, { FirstName: string; LastName: string; roomName: string; }>>({});

    useEffect(() => {
        (async () => {
            try {
                    setResidentsFetchLoading(true);
                    const allResidents = await fetchAllPaginatedResidents();
                    const tempResidentDict = {};
                    allResidents.forEach((resident: Registrant) => {
                        tempResidentDict[String(resident._id)] = {
                            FirstName: resident.FirstName,
                            LastName: resident.LastName,
                            roomName: resident.Unit && resident.Unit.Name,
                        };
                    });
                    setResidentDict(tempResidentDict);
                    setResidents(allResidents || []);
            } catch (error) {
                sendToast("error", "Something went wrong while fetching residents. Please try again later.");
            } finally {
                setResidentsFetchLoading(false);
            }
        })();
    }, [])

    useEffect(() => {
        if (residents.length > 0) {
            (async () => {
                await fetchCheckInfo();
            })();
        };
    }, [startDate, endDate, residents]);

    const fetchCheckInfo = async () => {
        try {
            setCheckInLoading(true);
            if (!startDate || !endDate) return;
            const formattedStartDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
            const startDateTimestamp = moment.tz(formattedStartDate, facilityTimeZone).valueOf();
            const formattedEndDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');
            const endDateTimestamp = moment.tz(formattedEndDate, facilityTimeZone).valueOf();
            if(startDateTimestamp >= endDateTimestamp) {
                sendToast("warn", "Start date time should be less than end date time");
                setCheckInLoading(false);
                return;
            }
            const { items: checkInList } = await getCheckinList({
                startTimestamp: startDateTimestamp,
                endTimestamp: endDateTimestamp,
                resident: "all",
            });
            const formattedCalendarInfo = {};
            const formattedCheckinInfo = {};
            const checkInTableItems: checkInTable = [];
            const frequency = RRule.DAILY;
            // Create an RRule instance
            const rule = new RRule({
                freq: frequency,
                dtstart: startDate,
                until: endDate,
            });
            const generatedDates = rule.all();
            generatedDates.forEach((generatedDate) => {
                // since start end date range is set in the browser, we need not consider facility timezone here
                const generatedDateString =
                    moment(new Date()).startOf('day').valueOf() >= moment(generatedDate).startOf('day').valueOf()
                        ? moment(generatedDate).format('YYYY-MM-DD')
                        : undefined;
                if (generatedDateString) formattedCalendarInfo[generatedDateString] = 0;
            });
            checkInList.forEach((checkInRecord) => {
                const startOfDay = moment
                    .tz(checkInRecord.timestamp, facilityTimeZone)
                    .startOf('day')
                    .format('YYYY-MM-DD');
                if (checkInRecord.column === 'checked_in') {
                    const date = moment.tz(startOfDay, facilityTimeZone).format('YYYY-MM-DD');
                    formattedCalendarInfo[date] = formattedCalendarInfo[date] ? formattedCalendarInfo[date] + 1 : 1;
                    formattedCheckinInfo[date]
                        ? formattedCheckinInfo[date].push(checkInRecord)
                        : (formattedCheckinInfo[date] = [checkInRecord]);
                }
                // Existing code to populate checkInTableItems
                const dateTimeString: string = moment
                    .tz(checkInRecord.timestamp, facilityTimeZone).format('M/D/YY h:mm A');
                const residentData = residentDict[String(checkInRecord.registrant._id)];
                const registrantName: string = (residentData && residentData.FirstName && residentData.LastName && `${residentData.FirstName} ${residentData.LastName}`) || "-";
                const status = checkInRecord.column === 'checked_in' ? 'Yes' : 'No';
                const registrantId = String(checkInRecord.registrant._id);
                const registrantFirstName = residentData && residentData.FirstName || "-";
                const registrantLastName = residentData && residentData.LastName || "-";
                const roomName = residentData && residentData.roomName || "-";
                checkInTableItems.push({ dateTimeString,  registrantName, status, registrantId,registrantFirstName, registrantLastName, roomName });
            });


            const calendarEvents = Object.keys(formattedCalendarInfo).map((key) => {
                const checkedInCount = formattedCalendarInfo[key] || 0;
                const notCheckedInCount = residents.length && residents.length > checkedInCount ? residents.length - checkedInCount : 0;
                return {
                    date: key,
                    title: `${checkedInCount} | ${notCheckedInCount}`
                };
            });
            setCalendarCountEvents(calendarEvents);
            setCheckinTableItems(checkInTableItems);
        } catch (err) {
            sendToast("error", err instanceof Error ? err.message : "Something went wrong while fetching check in data. Please try again later.");
        } finally {
            setCheckInLoading(false);
        }
    };

    const getCheckinList = async (filters: filter) => {
        const facilityId = profile && profile.Facility;
        const checkinList = await listCards(facilityId, filters)

        return {
            items: checkinList
        };
    }
    const getCheckInModalData = async (info: any, isCheckin: boolean) => {
        setIsModalFetching(true);
        const selectedDate = moment(info.event.start).format('YYYY-MM-DD');
        const filter = {
            startTimestamp: moment.tz(selectedDate, facilityTimeZone).startOf('day').valueOf(),
            endTimestamp: moment.tz(selectedDate, facilityTimeZone).endOf('day').valueOf(),
            resident: 'all',
        };
        if(filter.startTimestamp >= filter.endTimestamp) {
            sendToast("warn", "Start date time should be less than end date time");
            setIsModalFetching(false);
            return;
        }
        const { items: checkinList } = await getCheckinList(filter);
        const checkInTableItems: checkInTable = [];
        if (!isCheckin) {
                residents.forEach((resident) => {
                    const checkInItem = checkinList.find(checkInItem => String(checkInItem.registrant._id) === String(resident._id));
                    if (checkInItem && checkInItem.column === 'checked_in') { // if resident is checked in then skip that resident
                        return;
                    }
                    else {
                        const residentData = residentDict[String(resident._id)] || { FirstName: "-", LastName: "-"};
                        checkInTableItems.push({
                            dateTimeString: moment(info.event.start).format('M/D/YY h:mm A'),
                            registrantName: `${residentData.FirstName} ${residentData.LastName}`,
                            registrantId: String(resident._id),
                            status: 'No',
                            registrantFirstName: residentData.FirstName || "-",
                            registrantLastName: residentData.LastName || "-"
                        });
                    }
                })
        } else {
            checkinList.forEach((checkInRecord) => {
                if (checkInRecord.column !== 'checked_in') return;
                const residentData = residentDict[String(checkInRecord.registrant._id)] || { FirstName: "-", LastName: "-"};
                const dateTimeString: string = moment
                    .tz(checkInRecord.timestamp, facilityTimeZone).format('M/D/YY h:mm A');
                const registrantName: string = `${residentData.FirstName} ${residentData.LastName}`;
                const status = 'Yes';
                const registrantId = String(checkInRecord.registrant._id);
                checkInTableItems.push({ dateTimeString, registrantName, status, registrantId, registrantFirstName: residentData.FirstName, registrantLastName: residentData.LastName });
            });
        }
        setModalCheckinTableItems(checkInTableItems);
        setIsModalFetching(false);
    };

    return (
        <div className="wrapperParent">
        <Dimmer active={residentsFetchLoading || checkInLoading} inverted>
            <Loader active={residentsFetchLoading || checkInLoading} />
        </Dimmer>
            <Grid>
                <Grid.Column width={9}>
                    <CheckInMainComponent
                        getCheckInModalData={getCheckInModalData}
                        calendarCountEvents={calendarCountEvents}
                        startDate={moment(startDate).format('YYYY-MM-DD')}
                        setModalOpen={setOpen}
                    />
                </Grid.Column>
                <Grid.Column width={7}>
                    <Segment style={{ height: '80%'}}>
                        <ReportsCheckInTable
                            tableItems={checkinTableItems}
                            tableHeading={'Resident check in list'}
                            height={'93%'} // matches with calendar's height
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
            <Modal
                onClose={() => {
                    setOpen(false);
                }}
                open={open}
            >
                <Modal.Content>
                    <Dimmer active={isModalFetching} inverted>
                        <Loader active={isModalFetching} />
                    </Dimmer>
                    <ReportsCheckInTable
                        tableItems={modalCheckinTableItems}
                        tableHeading={''} // We don't have heading for modal table hence empty string
                        height={'600px'}
                    />
                </Modal.Content>
            </Modal>
        </div>
    );

}

export default ReportsCheckIn;
