import { FeaturedFacility } from '../types';
import API from './API';

export async function getWebRTCFeaturedFacilities(): Promise<{Result: FeaturedFacility[]}> {
    try {
        const res = await API.lambdaPost('/webrtc/enabled-facilities/get', {});
        return res;
    } catch (error) {
        console.error('Error getting WebRTC featured facilities:', error);
        throw error;
    }
}

export async function addWebRTCFeaturedFacility(facilityId: string): Promise<any> {
    try {
        const res = await API.lambdaPost('/webrtc/enabled-facilities/insert', { facilityId });
        return res;
    } catch (error) {
        console.error('Error adding WebRTC featured facility:', error);
        throw error;
    }
}

export async function removeWebRTCFeaturedFacility(facilityId: string): Promise<any> {
    try {
        const res = await API.lambdaPost('/webrtc/enabled-facilities/delete', { facilityId });
        return res;
    } catch (error) {
        console.error('Error removing WebRTC featured facility:', error);
        throw error;
    }
}
