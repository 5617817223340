import React, { FC, useEffect, useState } from 'react'
import { Dimmer, FormTextArea, Header, Loader, Message } from 'semantic-ui-react';
import { listResidentAnalyzeData } from '../../services/Analyze'
import { Bar } from "react-chartjs-2"
import "./style.less"
import moment from 'moment-timezone'
import CustomDatePicker from '../CustomDatePicker';
import { DateRange } from "../CustomDatePicker"
import { capitalizeFirstLetter } from '../MenuFormEditor/utils'
import { startOfWeek, endOfWeek, addDays, addWeeks, isBefore, format, startOfDay, endOfDay } from 'date-fns';
import { declineOptions } from '../../util/data';
import { Analyze } from '../../types/Analyze';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
interface Props {
    residentId?: string
    selectedType: "activity" | "menu" | "summary"
    customTitle?: string
}
interface WeekData {
    weekLabel: string;
    totalEvents: number;
    observedCount: number;
    attendedCount: number;
    declinedCount: number;
    unscheduledCount: number;
    intendedToAttendedCount: number;
    deliveredCount: number;
}

interface ChartData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
        borderColor: string;
        borderWidth: number;
        stack: string;
    }[];
}
const AttendanceChart: FC<Props> = ({ residentId, selectedType, customTitle }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [monthlyData, setMonthlyData] = useState<ChartData | null>(null);
    const [dateRange, setDateRange] = useState<DateRange>({
        start: undefined,
        end: undefined
    })
    const [isFirstRender, setIsFirstRender] = useState(true);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    function isSameOrBefore(startDate: Date, endDate: Date) {
        return isBefore(startDate, endDate) || startDate.getTime() === endDate.getTime()
    }

    const generateFourWeeks = (weekDatesArr: { start: string, end: string, weekLabel: string }[]) => {
        let startDate = dateRange.start ? new Date(dateRange.start) : new Date();
        const endDate = dateRange.end ? new Date(dateRange.end) : addWeeks(new Date(startDate), 4);
        let loopCount = 0
        const isEndDateNotSelected = !dateRange.end;
        while (isBefore(startDate, endDate)) {
            const endOfWeekDate = endOfWeek(startDate);
            const startDateCopy = new Date(startDate)
            const start = format(startDateCopy, 'YYYY-MM-DD')
            weekDatesArr.push({
                start,
                end: isSameOrBefore(endOfWeekDate, endDate) ? format(endOfWeekDate, 'YYYY-MM-DD') : format(endDate, 'YYYY-MM-DD'),
                weekLabel: `${format(start, 'DD-MM')} - ${format(endOfWeekDate, 'DD-MM')}`,
            });
            startDate = addWeeks(startOfWeek(startDate), 1);
            loopCount++
            if(isEndDateNotSelected && loopCount >= 4) {
                break;
            }
        }
        return weekDatesArr;
    }

    const isAttendedDeclined = (event: Analyze) => {
        return declineOptions.some(option => option.value === event.attended);
    }

    const isAttendedDelivered = (event: Analyze) => {
        const deliveredOptions = ["Ill", "Preferred"];
        return deliveredOptions.some(option => option === event.attended);
    }

    const fetchData = async () => {
        try {
            if (profile && profile.Facility && profile.FacilityTimeZone) {
                const emptyWweekDatesArr: { start: string, end: string, weekLabel: string; }[] = [];
                const weekDatesArr = generateFourWeeks(emptyWweekDatesArr);

                if (!isFirstRender) {
                    setIsLoading(true);
                    const promises = weekDatesArr.map((date, i) => {
                        const startDateTimeString =  selectedType === "summary" ? moment(date.start).format("YYYY-MM-DD") : moment.tz(date.start,profile.FacilityTimeZone).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
                        const endDateTimeString = selectedType === "summary" ? moment(date.end) : moment.tz(date.end,profile.FacilityTimeZone).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
                        return listResidentAnalyzeData(selectedType, residentId, startDateTimeString, endDateTimeString);
                    });
                    const res = await Promise.all(promises);
                    let attendacneDataArr: WeekData[] = [];
                    res.forEach((weekData, i) => {
                        let weekDataItem: WeekData = {
                            weekLabel: weekDatesArr[i].weekLabel,
                            totalEvents: weekData.totFacilityEvents,
                            observedCount: 0,
                            attendedCount: 0,
                            declinedCount: 0,
                            unscheduledCount: 0,
                            intendedToAttendedCount: 0,
                            deliveredCount: 0,
                        };
                        weekData.data.forEach((event) => {
                            if (event.attended === "Yes") {
                                weekDataItem.attendedCount++;
                            } else if (event.attended === "Observed") {
                                weekDataItem.observedCount++;
                            } else if (event.registered === "No" && event.attended === "Yes") {
                                weekDataItem.unscheduledCount++;
                            } else if (isAttendedDeclined(event)) {
                                weekDataItem.declinedCount++;
                            } else if (event.registered === "Yes") {
                                weekDataItem.intendedToAttendedCount++;
                            } else if (isAttendedDelivered(event)) {
                                weekDataItem.deliveredCount++;
                            }
                        });
                        attendacneDataArr.push(weekDataItem);
                    });
                    const chartData = {
                        labels: attendacneDataArr.map(data => data.weekLabel),
                        datasets: [
                            {
                                label: 'Observed',
                                data: attendacneDataArr.map(data => data.observedCount),
                                backgroundColor: '#F0712C',
                                borderColor: '#F0712C',
                                borderWidth: 1,
                                stack: 'total',
                            },
                            {
                                label: 'Attended',
                                data: attendacneDataArr.map(data => data.attendedCount),
                                backgroundColor: '#2DB84B',
                                borderColor: '#2DB84B',
                                borderWidth: 1,
                                stack: 'total',
                            },
                            {
                                label: 'Declined',
                                data: attendacneDataArr.map(data => data.declinedCount),
                                backgroundColor: '#CC2029',
                                borderColor: '#CC2029',
                                borderWidth: 1,
                                stack: 'total',
                            },
                            {
                                label: 'Unscheduled',
                                data: attendacneDataArr.map(data => data.unscheduledCount),
                                backgroundColor: '#B5CA31',
                                borderColor: '#B5CA31',
                                borderWidth: 1,
                                stack: 'total',
                            },
                            {
                                label: 'Intended To Attend',
                                data: attendacneDataArr.map(data => data.intendedToAttendedCount),
                                backgroundColor: '#1CB5AC',
                                borderColor: '#1CB5AC',
                                borderWidth: 1,
                                stack: 'total',
                            },
                            {
                                label: 'Delivered',
                                data: attendacneDataArr.map(data => data.deliveredCount),
                                backgroundColor: '#F0C808',
                                borderColor: '#F0C808',
                                borderWidth: 1,
                                stack: 'total',
                            },
                            {
                                label: 'Total Events',
                                data: attendacneDataArr.map(data => data.totalEvents),
                                backgroundColor: '#2987CD',
                                borderColor: '#2987CD',
                                borderWidth: 1,
                                stack: 'total',
                            },
                        ],
                    };
                    setMonthlyData(chartData);

                } else {
                    const weekDatesArrCopy: { start: string, end: string, weekLabel: string; }[] = JSON.parse(JSON.stringify(weekDatesArr));
                    const firstWeek = weekDatesArrCopy[0];
                    const lastWeek = weekDatesArrCopy[3];
                    const start = new Date(firstWeek.start);
                    const end = new Date(lastWeek.end);
                    setDateRange({
                        start,
                        end
                    });
                    setIsFirstRender(false);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dateRange, selectedType]);

    const options = {
        legend: {
            display: true,
            position: 'bottom',
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Number of Activities',
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Week',
                    },
                },
            ],
        },
    };

    const handleDateChange = (dates: DateRange) => {
        setDateRange(dates)
    }
    return (
        <div style={{ position: 'relative' }}>
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading} />
            </Dimmer>
            <Header as={'h3'} textAlign="center">{`Weekly ${
                customTitle ? capitalizeFirstLetter(customTitle) : capitalizeFirstLetter(selectedType)
            } Summary`}</Header>
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                <CustomDatePicker dateRange={dateRange} setDateRange={handleDateChange} />
            </div>
            {monthlyData ? <Bar data={monthlyData} options={options} /> : <Message>No data to display.</Message>}
        </div>
    );
}

export default AttendanceChart