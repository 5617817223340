import { RouteComponentProps, withRouter } from "react-router-dom";
import ServiceTypesForm from "../../../../components/ServiceTypesForm";
import React, { useEffect, useState } from "react";
import { Service } from "../../../../types/Service";
import { fetchOneUniversalService } from "../../../../services/service";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { sendToast, templateService } from "../../../../util";
interface ServicesProps extends RouteComponentProps<RouteInfo> {
    source?: string;
}
interface RouteInfo {
    id?: string;
}
const UpdateTemplateServiceForm: React.FC<ServicesProps> = ({ history, match }) => {
    const [selectedService, setSelectedService] = useState<Service | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { id } = match.params;
    useEffect(() => {
        if (id) {
            fetchOneServiceTypeDetails(id);
        }
    }, [id]);

    const fetchOneServiceTypeDetails = async (id: string) => {
        try {
            setIsFetching(true);
            const responseData = await fetchOneUniversalService(id);
            // Combine data from different service types into a single object
            const combinedData = {
                ...responseData
            };
            // Update the state with the combined object
            setSelectedService(combinedData);
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            sendToast("error", "Error in fetching service template details");
        }
    };

    return (
        <div>
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching} size="small">
                    Loading
                </Loader>
            </Dimmer>
            <Button
                size="tiny"
                basic
                onClick={() => history.push(`/admin/services/list?tab=${selectedService && selectedService.CorporateId ? "corporate" : "universal"}`)}>
                {selectedService && selectedService.CorporateId ? "Back to corporate templates" : "Back to universal templates"}
            </Button>
            {selectedService ? <ServiceTypesForm service={selectedService} source={templateService} /> : null}
        </div>
    );
};

export default withRouter(UpdateTemplateServiceForm);