import React, {FC, useEffect, useState} from 'react'
import { 
    Grid,
    Image,
    Dimmer,
    Loader,
    Button,
    Icon
} from 'semantic-ui-react'
import PhotoModal from './PhotoModal'
import { deleteMyImage } from '../../services/Signage';
import { sendToast } from '../../util';
import { ContentType } from '../../types/signage';

interface Props {
    imageUrls: string[]
    onImageClick?: (imageUrl: string) => void
    refreshGalleryImage?: () => void;
    activeContentType?: ContentType | null;
}

const PhotoGrid: FC<Props> = ({ imageUrls, onImageClick, refreshGalleryImage, activeContentType }) => {

    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false)
    const [imageLoadedStatus, setImageLoadedStatus] = useState<boolean>(true)
    const [photoModalImageUrl, setPhotoModalImageUrl] = useState<any | undefined>()
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number | undefined>()
    const [deletingImageUrl, setDeletingImageUrl] = useState<string | null>(null); // New state to track the deleting image

    // Change this constant to change the number of columns in the photo grid
    const maxColumnsConst = 4
    
    const getPhotoRows = (photoUrls, maxCols) => {
        let photoRows: any[] = []
        let row: any[] = []
        const quotient = Math.floor(photoUrls.length / maxCols)
        for (let rowCtr = 0; rowCtr < quotient; ++rowCtr) {
            row = []
            for (let colCtr = 0; colCtr < maxCols; ++colCtr) {
                row.push(photoUrls[rowCtr * maxCols + colCtr])
            }
            photoRows.push(row)
        }

        const remainder = photoUrls.length % maxCols
        row = []
        for (let ctr = remainder; ctr > 0; --ctr) {
            row.push(photoUrls[photoUrls.length - ctr])
        }
        photoRows.push(row)

        return photoRows
    }

    const showPreviousPhoto = () => {
        const localCurrentPhotoIndex = getCurrentPhotoIndex(imageUrls, photoModalImageUrl)
        if (localCurrentPhotoIndex !== 0) {
            setPhotoModalImageUrl(imageUrls[localCurrentPhotoIndex - 1])
            setCurrentPhotoIndex(localCurrentPhotoIndex - 1)
        }
    }

    const showNextPhoto = () => {
        const localCurrentPhotoIndex = getCurrentPhotoIndex(imageUrls, photoModalImageUrl)
        if (localCurrentPhotoIndex !== (imageUrls.length - 1)) {
            setPhotoModalImageUrl(imageUrls[localCurrentPhotoIndex + 1])
            setCurrentPhotoIndex(localCurrentPhotoIndex + 1)
        }
    }

    const getCurrentPhotoIndex = (imgUrls, currentUrl) => {
        return imgUrls.findIndex(url => {
            return url === currentUrl
        })
    }

    const handleDeleteClick = async (imageUrl: string) => {
        try {
            setDeletingImageUrl(imageUrl);
            // Find the index of the '?' character, which marks the start of the query parameters
            const questionMarkIndex = imageUrl.indexOf('?');
            // If there's a '?' in the URL, return the substring up to that index
            const trimmedUrl = questionMarkIndex !== -1 ? imageUrl.substring(0, questionMarkIndex) : imageUrl;
            await deleteMyImage(trimmedUrl, activeContentType)
            if (refreshGalleryImage) {
                refreshGalleryImage();
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : "Error in deleting image, please try again later");
        } finally {
            setDeletingImageUrl(null); // Reset the deleting image after the operation
        }
    }

    // Specify which content types can display the delete button
    const canDelete = activeContentType && ["myUploads", "ROTM", "SOTM", "menus", "activities", "communityMessages"].includes(activeContentType);

    if (imageUrls && imageUrls.length) {
        return (
            <>
                <Grid>
                <Dimmer active={imageLoadedStatus} inverted>
                    <Loader active={imageLoadedStatus} />
                </Dimmer>
                    {getPhotoRows(imageUrls, maxColumnsConst).map((row, rowIndex) => {
                        return (
                            <Grid.Row columns={maxColumnsConst} key={rowIndex}>
                                {row.map(imageUrl => {
                                    return (
                                        <Grid.Column 
                                            key={imageUrl} 
                                            style={{ position: 'relative' }}
                                        >
                                            {canDelete && (
                                                <Button
                                                    icon
                                                    size='mini'
                                                    circular
                                                    color='red'
                                                    disabled={deletingImageUrl === imageUrl}
                                                    loading={deletingImageUrl === imageUrl}
                                                    style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}
                                                    onClick={() => handleDeleteClick(imageUrl)}
                                                >
                                                    <Icon name='trash' />
                                                </Button>
                                            )}
                                            <Image 
                                                rounded 
                                                src={imageUrl} 
                                                onLoad={() => setImageLoadedStatus(false)}
                                                onClick={e => {
                                                    if (onImageClick) {
                                                        onImageClick(e.target.src)
                                                        return
                                                    }
                                                    setPhotoModalImageUrl(e.target.src)
                                                    setCurrentPhotoIndex(getCurrentPhotoIndex(imageUrls, e.target.src))
                                                    setIsPhotoModalOpen(true)
                                                }} 
                                            />
                                        </Grid.Column>
                                    )
                                })}
                            </Grid.Row>
                        )
                    })}
                </Grid>
                <PhotoModal
                    imageUrl={photoModalImageUrl}
                    onModalOpen={() => {
                        setIsPhotoModalOpen(true)
                    }}
                    onModalClose={() => {
                        setIsPhotoModalOpen(false)
                    }}
                    isPhotoModalOpen={isPhotoModalOpen}
                    showPreviousPhoto={showPreviousPhoto}
                    showNextPhoto={showNextPhoto}
                    disableModalPreviousButton={currentPhotoIndex === 0}
                    disableModalNextButton={currentPhotoIndex === (imageUrls.length - 1)}
                />
            </>
        )
    }
    return (
        <p style={{color: 'gray'}}>No photos yet</p>
    )
}

export default PhotoGrid
