import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { UserProfile } from '../../types';
import { thisExpression } from '@babel/types';

interface ProtectedRouteProps extends RouteProps {
    condition: boolean
    redirect: string
    profile?: UserProfile | null
}

const parentFrom = (props: any): string => {
    return (props.location.state && props.location.state.from) || props.location.pathname
}

const parentQueryParams = (props: any) => {
    return props.location.search || '';
}

class ProtectedRoute extends Route<ProtectedRouteProps> {
    public render() {
        let redirectPath: string = ''
        if (!this.props.condition) {
            redirectPath = this.props.redirect
        }

        if (redirectPath) {
            const renderComponent = (props: any) => (
                <Redirect to={{ pathname: redirectPath, state: { from: parentFrom(props) }, search: parentQueryParams(props) }} />
            )
            return <Route {...this.props} component={renderComponent} render={undefined} />
        } else {
            return <Route {...this.props} />
        }
    }
}

export default ProtectedRoute
