import React from 'react'
import DepartmentsForm, { FormValues } from '../DepartmentsForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { createDepartment } from '../../../../services/Departments'

interface State {
    isSaving: boolean
    error: string | null
}
interface Props extends RouteComponentProps {
    onSubmitClick?: () => void
    onCancelClick?: () => void
}

class UnitsCreate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
        }
    }

    goBack() {
        if (this.props.onCancelClick) {
            this.props.onCancelClick()
        } else {
            this.props.history.goBack()
        }
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            await createDepartment(data)
            this.setState({ isSaving: false })
            this.props.onSubmitClick && this.props.onSubmitClick()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <DepartmentsForm
                cancel={this.goBack.bind(this)}
                title="Create Department"
                department={null}
                onSave={this.handleSave.bind(this)}
                {...this.state}
            />
        )
    }
}

export default withRouter(UnitsCreate)
