import React from 'react'
import { Form, Icon, Button } from 'semantic-ui-react'
import { CurrentUser } from '../../types'
import { updatePassword } from '../../services/Authentication'

interface Props {
    onSuccess: () => any
    onError: (e: string) => any
    currentUser: CurrentUser | null
}

interface State {
    changingPassword: boolean
    passwordChangeSuccess: boolean
    passwordChangeError: string | null
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

class ChangePassword extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            changingPassword: false,
            passwordChangeError: null,
            passwordChangeSuccess: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }
    }

    handleChange(property: keyof State, value: string | boolean | null) {
        this.setState({
            [property]: value,
        } as Pick<State, keyof State>)
    }

    get validOldPassword(): boolean {
        if (this.state.oldPassword === '') return true
        return this.state.oldPassword.length > 6
    }

    get validNewPassword(): boolean {
        if (this.state.newPassword === '') return true
        return this.state.newPassword.length > 6
    }

    get validConfirm(): boolean {
        if (this.state.confirmPassword === '') return true
        return this.state.newPassword === this.state.confirmPassword
    }

    get validForm(): boolean {
        return (
            this.state.oldPassword.length > 6 &&
            this.state.newPassword.length > 6 &&
            this.state.newPassword.length > 6 &&
            this.validNewPassword &&
            this.validConfirm
        )
    }

    async handleSubmit() {
        if (!this.props.currentUser) return
        this.setState({
            changingPassword: true,
            passwordChangeError: null,
            passwordChangeSuccess: false,
        })
        try {
            await updatePassword(this.props.currentUser, this.state.oldPassword, this.state.newPassword)
            this.setState({
                changingPassword: false,
                passwordChangeError: null,
                passwordChangeSuccess: true,
            })
            this.props.onSuccess()
        } catch (e) {
            console.log(e)
            this.setState({
                changingPassword: false,
                passwordChangeError: e.message,
                passwordChangeSuccess: false,
            })
            this.props.onError(e.message)
        }
    }

    render() {
        const { oldPassword, newPassword, confirmPassword } = this.state
        return (
            <div className="change-password-form">
                <Form.Field className="change-password-form" error={this.state.passwordChangeError ? true : false}>
                    <Form.Input
                        fluid
                        type="password"
                        className="input"
                        iconPosition="left"
                        placeholder="Old Password"
                        error={!this.validOldPassword}
                        value={oldPassword}
                        onChange={e => this.handleChange('oldPassword', e.currentTarget.value)}
                    >
                        <Icon name="lock" />
                        <input />
                    </Form.Input>
                    <Form.Input
                        fluid
                        type="password"
                        className="input"
                        iconPosition="left"
                        placeholder="New Password"
                        error={!this.validNewPassword}
                        value={newPassword}
                        onChange={e => this.handleChange('newPassword', e.currentTarget.value)}
                    >
                        <Icon name="lock" />
                        <input />
                    </Form.Input>
                    <Form.Input
                        fluid
                        className="input"
                        iconPosition="left"
                        placeholder="Confirm Password"
                        error={!this.validConfirm}
                        type="password"
                        value={confirmPassword}
                        onChange={e => this.handleChange('confirmPassword', e.currentTarget.value)}
                    >
                        <Icon name="lock" />
                        <input />
                    </Form.Input>
                    <Button
                        floated="right"
                        primary
                        basic
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.validForm || this.state.changingPassword}
                        loading={this.state.changingPassword}
                    >
                        Update Password
                    </Button>
                </Form.Field>
            </div>
        )
    }
}

export default ChangePassword
