import React, { useEffect, useState } from "react";
import Board, { moveCard } from "@asseinfo/react-kanban"
import "@asseinfo/react-kanban/dist/styles.css";
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react';
import { toast } from "react-toastify";
import { updateActivityAttendee, createActivityAttendee, updateActivityAttendeeDeclineReason, updateActivityAttendeeParticipation, deleteActivityAttendee } from '../../../services/DailyActivities';

// Use your own styles to override the default styles
import "./style.less";
import { declineOptions, participationOptions } from "../../../util/data";
import { sendToast, sortKanbanList } from "../../../util";
import { createSvcAttendee, deleteSvcAttendee, reorderWaitingListAttendee, updateSvcAttendee } from "../../../services/ServiceInstances";

function ControlledBoard(props) {
  // You need to control the state yourself.
  const [controlledBoard, setBoard] = useState(props.board);
  const [selectedSortingOption, setSelectedSortingOption] = useState('');
  const [sortLoading, setSortLoading] = useState(false);

  useEffect(() => {
    setBoard(props.board);
    props.loader(false); //only set loader to false when the board is set
  }, [props.board])
  
  const sortingOptions = [
      { key: 'FirstName', text: 'First Name', value: 'FirstName' },
      { key: 'LastName', text: 'Last Name', value: 'LastName' },
      { key: 'RoomName', text: 'Room Name', value: 'RoomName' },
  ];
  async function handleCardMove(_card, source, destination) {

    const currentBoard = controlledBoard;
    try {     
      if (props.isWaitingListMovementEnabled === true && (source.fromColumnId === 4 && destination.toColumnId !== 4 && props.svcInstanceId || props.isWaitingListMovementEnabled && destination.toColumnId === 4)) {
        sendToast("warn", "Disable waiting list automation to move cards from waiting list column");
        return;
      }

      props.loader(true);
      setSelectedSortingOption(''); // Reset sorting option
      delete _card.declineReason;
      delete _card.participation;
      if (source.fromColumnId !== destination.toColumnId && destination.toColumnId !== 0) {
        // only allow service activity to be moved to waiting list column
        if (destination.toColumnId === 4 && props.activityId) {
          sendToast("warn", "Waiting list movement is disabled");
          return;
        }
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
        let res;
        let status;
        if (source.fromColumnId === 0 || !_card.status) {
          if (destination.toColumnId === 1) status = 'intend_attend';
          if (destination.toColumnId === 2) {
            _card.participation = 'active';
            status = 'attended';
          }
          if (destination.toColumnId === 3) {
            _card.declineReason = 'Not Interested';
            status = 'removed';
          }

          if (destination.toColumnId === 4) {
            status = 'waiting';
          }

          delete _card._id;
          delete _card.id;

          _card.status = status;
          _card.Facility = props.facilityId;
          _card.activityId = props.activityId;

          if (props.activityId) {
            if (_card.status === 'intend_attend') {
              props.addNewAnnouncee(_card.title);
            }
            res = await createActivityAttendee({
              Facility: props.facilityId,
              activityId: props.activityId,
              registrantId: _card.registrantId,
              registrant: { FirstName: _card.registrant.FirstName, LastName: _card.registrant.LastName },
              status: status
            });
          } else if (props.svcInstanceId) {
            res = await createSvcAttendee({
              Facility: props.facilityId,
              svcInstanceId: props.svcInstanceId,
              registrantId: _card.registrantId,
              registrant: { FirstName: _card.registrant.FirstName, LastName: _card.registrant.LastName },
              status: status,
              svcType: 'activity', 
              thisAndFollowingDate: props.thisAndFollowingDate
            });
          }
          _card.id = res;
          _card._id = res;
        }
        else {
          if (destination.toColumnId === 1) {
            if (props.activityId) {
              props.addNewAnnouncee(_card.title);
              res = await updateActivityAttendee(_card.id, 'intend_attend', props.activityId);
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'intend_attend', svcInstanceId: props.svcInstanceId, svcType: 'activity', thisAndFollowingDate: props.thisAndFollowingDate });
            }
            _card.status = 'intend_attend';
          } else if (destination.toColumnId === 2) {
            _card.status = 'attended';
            _card.participation = 'active';
            if (props.activityId) {
              props.removeAnnouncee(_card.title);
              res = await updateActivityAttendee(_card.id, 'attended', props.activityId);
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'attended', svcInstanceId: props.svcInstanceId, svcType: 'activity', participation: _card.participation, thisAndFollowingDate: props.thisAndFollowingDate });
            }
          } else if (destination.toColumnId === 3) {
            _card.status = 'removed';
            _card.declineReason = 'Not Interested';
            if (props.activityId) {
              props.removeAnnouncee(_card.title);
              res = await updateActivityAttendee(_card.id, 'removed', props.activityId);
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'removed', svcInstanceId: props.svcInstanceId, svcType: 'activity', declineReason: _card.declineReason, thisAndFollowingDate: props.thisAndFollowingDate });
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'waiting', svcInstanceId: props.svcInstanceId, svcType: 'activity', thisAndFollowingDate: props.thisAndFollowingDate });
            }
          } else if (destination.toColumnId === 4) {
            if (props.svcInstanceId) {
              _card.status = 'waiting';
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'waiting', svcInstanceId: props.svcInstanceId, svcType: 'activity', thisAndFollowingDate: props.thisAndFollowingDate });
            } else {
              sendToast("warn", "Waiting list movement is disabled");
              return;
            }
          }
        }
        if (res && res.message) {
          sendToast("success", res.message);
        }
      }
      if (source.fromColumnId !== destination.toColumnId && destination.toColumnId === 0) {
        // This block is invoked when a card is moved back to the initial column (columnId 0) from a different column.
        // This usually signifies that the user recognizes a mistake in the card's previous placement and wants to undo that action.
        // In response, we not only move the card back to the initial column in the UI but also remove its corresponding entry from the database.
        // This is done to ensure data integrity and to revert the state back to what it was before the 'mistaken' move.
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
        let response;
        if (props.activityId) {
          props.removeAnnouncee(_card.title);
          response = await deleteActivityAttendee(_card.id, props.activityId);
        } else if (props.svcInstanceId) {
          response = await deleteSvcAttendee({ id: _card.id, svcType: 'activity', svcInstanceId: props.svcInstanceId, thisAndFollowingDate: props.thisAndFollowingDate });
        }
        delete _card.Facility;
        delete _card.activityId;
        delete _card.status;
        delete _card._id;
        delete _card.id;
        _card.key = 'temp' + _card.registrantId;
        _card.id = 'temp' + _card.registrantId;
        if (response && response.message) {
          sendToast("success", response.message);
        }
      }
      // This block is invoked when a card is moved within the same column.
      if (source.fromColumnId === 4 && destination.toColumnId === 4) {
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
        let response;
        if(props.svcInstanceId) {
          //we are adding 1 to the position because the position is 0 based
          const toPosition = Number(destination.toPosition) + 1;
          const fromPosition = Number(source.fromPosition) + 1;
          response = await reorderWaitingListAttendee({ attendeeObjId:String(_card._id),svcInstanceId: String(props.svcInstanceId), fromPosition, toPosition, thisAndFollowingDate: props.thisAndFollowingDate });
        } else {
          sendToast("warn", "Waiting list movement is disabled");
          return;
        }
        if (response && response.message) {
          sendToast("success", response.message);
        }
      }
      if (props.refreshData) {
        await props.refreshData();
      }
    } catch (error) {
      setBoard(currentBoard);
      const errorMessage = error.message ? error.message : 'An error occurred. Unable to move the card.';
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      props.loader(false);
    }
  }
  

  const RenderCard = (card) => {
      const [dropDownloading, setDropDownloading] = useState(false); 
      const isParticipationType  = participationOptions.map(item => item.value).some(item => item === card.participation);
      const isDeclineType  = declineOptions.map(item => item.value).some(item => item === card.declineReason);
      
      return (
          <div className="react-kanban-card">
              <div className="react-kanban-column-header">
                  <div className="react-kanban-card__title">{card.title}
                  {card.queueNumber && (
                    <span style={{ float: 'right', color: 'grey' }}>Queue: {card.queuePosition}</span>
                  )}
                  </div>
              </div>
              <div className="react-kanban-card__roomName">
                  {card.roomName && (
                      <p>
                          <span style={{ color: 'grey' }}>Room Name: </span>
                          {card.roomName}
                      </p>
                  )}
                  {isParticipationType || isDeclineType ? (
                      <>
                          {card.roomName && <hr style={{ border: '1px solid #F0F0F0' }} />}
                          <Dropdown
                              placeholder={isDeclineType ? 'Reason for decline' : 'Participation'}
                              fluid
                              selection
                              loading={dropDownloading}
                              options={isDeclineType ? declineOptions : participationOptions}
                              defaultValue={card.declineReason || card.participation || ''}
                              onChange={async (e, data) => {
                                  try {
                                      setDropDownloading(true);
                                      if (isDeclineType) {
                                        if (props.activityId) {
                                          await updateActivityAttendeeDeclineReason(card.id, card.status, data.value, props.activityId);
                                        } else if (props.svcInstanceId) {
                                          await updateSvcAttendee({ attendeeObjId: card.id, status: card.status, svcInstanceId: props.svcInstanceId, svcType: 'activity', declineReason: data.value, thisAndFollowingDate: props.thisAndFollowingDate });
                                        }
                                      } else {
                                        if (props.activityId) {
                                          await updateActivityAttendeeParticipation(card.id, card.status, data.value, props.activityId);
                                        } else if (props.svcInstanceId) {
                                          await updateSvcAttendee({ attendeeObjId: card.id, status: card.status, svcInstanceId: props.svcInstanceId, svcType: 'activity', participation: data.value, thisAndFollowingDate: props.thisAndFollowingDate });
                                        }
                                      }
                                      setDropDownloading(false);
                                  } catch (error) {
                                      setDropDownloading(false);
                                      toast.error('Could not update', {
                                          position: 'bottom-center',
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                      });
                                  }
                              }}
                          />
                      </>
                  ) : (
                      <></>
                  )}
              </div>
          </div>
      );
  };
  const handleSortingDropdownChange = (value) => {
      setSortLoading(true);
      setSelectedSortingOption(value);
      sortKanbanList(value, controlledBoard);
      setSortLoading(false);
  };
  
  return (
      <>
          <Dimmer active={sortLoading} inverted>
              <Loader active={sortLoading} />
          </Dimmer>
          <Dropdown
              style={{ marginLeft: '10px', marginTop: '10px' }}
              placeholder={'Sort By'}
              closeOnEscape
              value={selectedSortingOption}
              selection
              scrolling
              options={sortingOptions}
              onChange={(e, { value = "" }) => handleSortingDropdownChange(value)}
          />
          <Board onCardDragEnd={handleCardMove} disableColumnDrag style={{position: 'relative'}} renderCard={RenderCard}>
              {controlledBoard}
          </Board>
      </>
  );
}

function KanbanBoards(props) {
  const [loading, setLoading] = useState(false);
  const handleSetLoading = (value) => {
    setLoading(value);
  }
  props.board.columns.forEach((item) => {
    //if status is waiting then sort by the key queuePosition else by the key LastName
    item.cards = item.cards.sort((a, b) => {
      if (a.status === 'waiting' && b.status === 'waiting') {
        return a.queuePosition - b.queuePosition;
      } else {
        const aLastName = a.registrant && a.registrant.LastName ? a.registrant.LastName : '';
        const bLastName = b.registrant && b.registrant.LastName ? b.registrant.LastName : '';
        return aLastName < bLastName ? -1 : aLastName > bLastName ? 1 : 0;
      }
    });
  });

  return (
      <>
        <Dimmer active={loading} inverted>
          <Loader active={loading} />
        </Dimmer>
        <ControlledBoard 
          board={props.board}
          facilityId={props.facilityId}
          activityId={props.activityId} 
          svcInstanceId={props.svcInstanceId}
          addNewAnnouncee={props.addNewAnnouncee} 
          removeAnnouncee={props.removeAnnouncee}
          loader={handleSetLoading}
          refreshData={props.refreshData}
          thisAndFollowingDate={props.thisAndFollowingDate}
          isWaitingListMovementEnabled={props.isWaitingListMovementEnabled}
        />
      </>
  );
}

export default KanbanBoards