export default {
    development: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://yek6gf0kb3.execute-api.us-east-1.amazonaws.com/dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    developmentDevDb: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://rpc9cyltgg.execute-api.us-east-1.amazonaws.com/development-database',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    shashDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://pr4uip6bmk.execute-api.us-east-1.amazonaws.com/shash-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    sharikhDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://x5k03bnl2k.execute-api.us-east-1.amazonaws.com/sharikh-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },     
    },
    sachinDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://hb7s8sp4z2.execute-api.us-east-1.amazonaws.com/sachin-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },        
    },
    ikigaiDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://pd149ww5ik.execute-api.us-east-1.amazonaws.com/ikigai-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },        
    },
    tharunDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://f2nqbjk3a0.execute-api.us-east-1.amazonaws.com/tharun-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    amanDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://2v92oqyu10.execute-api.us-east-1.amazonaws.com/aman-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    rishiDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ', // speak2_dev_pool id
        appClientId: '30lffkfdm5quqf06vjho9vjs5s', // Azure_SSO app client id
        // appClientId: '4m5fn7a5p0gk2phnl5t2u3cmub',
        lambdaEndpoint: 'https://6hznlv1xs5.execute-api.us-east-1.amazonaws.com/rishi-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    althafDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://83nbgafa44.execute-api.us-east-1.amazonaws.com/althaf-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevAlthaf: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://tx2uomau51.execute-api.us-east-1.amazonaws.com/dev-db-althaf',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevTharun: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://apnbu4prbh.execute-api.us-east-1.amazonaws.com/dev-db-tharun',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevAman: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://pso9rlnn1d.execute-api.us-east-1.amazonaws.com/dev-db-aman',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    speak2familyDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://ay76u3raz6.execute-api.us-east-1.amazonaws.com/speak2family-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    alexaSpeak2Live: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://atqxeqncag.execute-api.us-east-1.amazonaws.com/alexa-speak2-live',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        }
    },
    production: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://isuqd3h5r8.execute-api.us-east-1.amazonaws.com/prod',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    test: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://0ihzow8ng7.execute-api.us-east-1.amazonaws.com/api',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevRishi: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://bo52q0153l.execute-api.us-east-1.amazonaws.com/dev-db-rishi',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevSharikh: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://vpsibhnl20.execute-api.us-east-1.amazonaws.com/dev-db-sharikh',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevTharun2: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://rvl41k22zd.execute-api.us-east-1.amazonaws.com/dev-db-tharun2',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        }
    },
    adharshDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://zjexw6h4af.execute-api.us-east-1.amazonaws.com/adharsh-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevAdharsh: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://jmmstvt034.execute-api.us-east-1.amazonaws.com/dev-db-adharsh',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    rishabhDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://dvhhxoohj2.execute-api.us-east-1.amazonaws.com/rishabh-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevRishabh: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://y6glnvbive.execute-api.us-east-1.amazonaws.com/dev-db-rishabh',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    gokulDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://ob1f7yxn94.execute-api.us-east-1.amazonaws.com/gokul-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    dbDevGokul: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://z09sn8sco8.execute-api.us-east-1.amazonaws.com/dev-db-gokul',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    alexaSpeak2Staging: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://31pzkyu2ej.execute-api.us-east-1.amazonaws.com/speak2-staging',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        }
    },
    jamesDev: {
        identityPoolId: 'us-east-1:405944a4-62bb-4e6c-8efc-821013486aab',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '30lffkfdm5quqf06vjho9vjs5s',
        lambdaEndpoint: 'https://fuguljthek.execute-api.us-east-1.amazonaws.com/james-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        }
    },
}