import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

import './style.less'
import { Identifiable } from '../../types'

type ImplicitIdentifiable = any & Identifiable

interface Props {
    data: ImplicitIdentifiable[]
    labelFields: string[]
    imageField?: string
    placeholder: string
    loading?: boolean
    disabled?: boolean
    onSelectionChange?: (u: ImplicitIdentifiable[]) => void
    selected?: Identifiable[]
}

interface State {
    selected: Identifiable[]
}

class DataSourceDropdown extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            selected: [],
        }
    }

    joinLabelFields(item: any) {
        return this.props.labelFields.map(label => item[label]).join(' ')
    }

    getFilteredDataOptions() {
        return this.props.data.map(u => {
            const ret: any = {
                key: u._id,
                text: this.joinLabelFields(u),
                value: u._id,
            }
            if (this.props.imageField)
                ret.image = {
                    avatar: true,
                    src: u[this.props.imageField] || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`,
                }
            return ret
        }).sort((a, b) => a.text.localeCompare(b.text));
    }

    get selectedValue() {
        let selectedIds: string[] = []
        if (this.state.selected.length) {
            selectedIds = this.state.selected.map(i => i._id)
        } else if (this.props.selected) {
            selectedIds = this.props.selected.map(i => i._id)
        }

        return selectedIds
    }

    handleSelect(_: any, data: any) {
        if (!this.props.onSelectionChange) return
        const selectedIds: string[] = data.value
        const selected = this.props.data.filter(item => selectedIds.includes(item._id))
        this.setState({
            selected,
        })
        this.props.onSelectionChange(selected)
    }

    render() {
        return (
            <Dropdown
                className="DataSourceDropdown"
                value={this.selectedValue}
                loading={this.props.loading}
                disabled={this.props.loading || this.props.disabled}
                selection
                placeholder={this.props.placeholder}
                options={this.getFilteredDataOptions()}
                search
                multiple
                onChange={this.handleSelect.bind(this)}
            />
        )
    }
}

export default DataSourceDropdown
