import React, { FunctionComponent, useEffect, useState } from 'react';
import { Item, Button, Icon, Grid, Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import InboundSync from '../../pages/AdminPanel/Registrants/RegistrantEditor/InboundSync';
import { AuthState, RegistrantRelative } from '../../types';

import { deleteA4HContact, deleteRelativeContact, togglewebrctForRelative } from "../../services/Registrants"
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { fetchOneFeaturedFacility } from '../../services/Facilities';
interface Props {
    name: string;
    cell: string;
    imageUrl?: string;
    onEditClick: () => void;
    relaitve: RegistrantRelative
    listType: string
    reload: () => Promise<void>
    onSync: (contactId: any) => Promise<void>
    residentId: string
    onDeleteSuccess?: (relativeId: string) => void
    handleRPBox?: (registrantRelative: RegistrantRelative) => void
}

const AddressBookItem: FunctionComponent<Props> = ({ name, cell, imageUrl, onEditClick, ...props }) => {

    const { RelativeId: relativeId } = props.relaitve;
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [supportedFacility, setSupportedFacility] = useState<string>('');
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });

    useEffect(() => {
        const fetchFeaturedFacility = async () => {
            if (profile && profile.Facility) {
                try {
                    const { FacilityId = "" } = await fetchOneFeaturedFacility(profile.Facility);
                    setSupportedFacility(FacilityId);
                } catch (error) {
                    console.log(error instanceof Error ? error.message : 'Failed to fetch Featured Facility'); //since only featured facilty have webrtc feature no toast is shown
                }
            }
        };
        fetchFeaturedFacility();
    }, []);

    const onDeleteClick = async () => {
        try {
            setIsDeleteLoading(true)
            const isAppEnteredTab = props.listType === "relatives"
            if (isAppEnteredTab) {
                await deleteRelativeContact({ RegistrantId: props.residentId, RelativeId: relativeId })
            } else {
                await deleteA4HContact(props.residentId, relativeId)
            }
            if (props.onDeleteSuccess) props.onDeleteSuccess(relativeId)
        } catch (error) {
            console.error("onDeleteClick", error)
            toast.warn(error instanceof Error ? error.message : "Failed to delete data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } finally {
            setIsDeleteLoading(false)
            if(props.handleRPBox) props.handleRPBox(props.relaitve)
            await props.reload()

        }
    }
    
    const handleToggleChange = async (relaitveId: string, checked: boolean) => {
        try {
            if (profile && profile.Facility) {
                setIsFetching(true);
                await togglewebrctForRelative(relaitveId, checked, profile.Facility);
                props.reload();
                setIsFetching(false);
            }
        } catch (error) {
            setIsFetching(false);
            console.log("error in addressbook webrtc toogle", error);
            toast.error(error instanceof Error ? error.message : "Failed change webrtc toggle", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    return (
        // position relative is required for edit icon to be positioned properly.
        <Item style={{ position: "relative" }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Item.Image circular={true} size='tiny' src={imageUrl ? imageUrl : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Dimmer active={isFetching} inverted>
                            <Loader size='mini'>Loading</Loader>
                        </Dimmer>
                        <Item.Content>
                            {/* textOverflow: "ellipsis" is used when text is to long */}
                            <Item.Header as='a' style={{ whiteSpace: "pre-line", cursor: "default" }}>{name.split(" ").join("\n")}</Item.Header>
                            {props.listType === "room" ? <></>: <Item.Meta>Cell: {cell}</Item.Meta>}
                            {props.relaitve.contacts && props.relaitve.contacts.reciprocals && props.listType !== "room" ?
                                <>
                                    <Item.Extra>
                                        Inbound: 
                                        <span>
                                            {props.relaitve.contacts.reciprocals === "ENABLED" ? <Icon name='check' /> : <Icon name='close' />}
                                        </span>
                                        {props.relaitve.contacts.reciprocals !== "ENABLED" ?
                                            <InboundSync
                                                onSync={props.onSync}
                                                reload={props.reload}
                                                listType={props.listType}
                                                relative={props.relaitve}
                                            /> :
                                            <></>
                                        }

                                    </Item.Extra>
                                </> :
                                <></>
                            }
                            {
                                props.listType !== "room" ?
                                    <Item.Extra>
                                        Announce:
                                        <span>
                                            {props.relaitve.announcementAlerts ? <Icon name='check' /> : <Icon name='close' />}
                                        </span>
                                    </Item.Extra> :
                                    <> </>
                            }
                            {
                                (props.listType === "relatives" && supportedFacility != "") ?
                                    <Item.Extra>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: '10px' }}>Webrtc enable:</span>
                                            <Checkbox toggle checked={props.relaitve.WebRTCEnabled} onChange={(e, data) => {
                                            handleToggleChange(props.relaitve.RelativeId, data.checked as boolean);
                                        }}/>
                                        </div>
                                    </Item.Extra>
                                : <></>
                            }

                        </Item.Content>
                    </Grid.Column>
                </Grid.Row>
                <Button type="button" icon onClick={onEditClick} size='tiny' style={{ position: 'absolute', top: '0px', right: '0px', background: 'none', padding: '2px' }} color="blue">
                    <Icon name='edit' color='blue' />
                </Button>
                <Button type="button" icon onClick={onDeleteClick} size='tiny' style={{ position: 'absolute', top: '0px', right: '20px', background: 'none', padding: '2px' }} color="red">
                    <Icon name='delete' color='red' loading={isDeleteLoading} />
                </Button>
            </Grid>
        </Item>
    );
};

export default AddressBookItem;
