import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  messageToDisplay: string;
}

const ConfirmationModal = ({ open, onClose, onConfirm, messageToDisplay }: Props) => (
  <Modal open={open} onClose={onClose} size='small'>
    <Header icon='archive' content='Confirm Deletion' />
    <Modal.Content>
      <p>{messageToDisplay}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>
        <Icon name='remove' /> Cancel
      </Button>
      <Button color='red' onClick={onConfirm}>
        <Icon name='checkmark' /> Delete
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ConfirmationModal;