import * as React from 'react';
import { Grid, Modal } from 'semantic-ui-react';
import CustomTable from '../CustomTable';
import moment from 'moment-timezone';
import { DailyActivity } from '../../types';
import { declineOptions, participationOptions } from '../../util/data';

const participationOptionsPreviewDict = participationOptions.reduce((acc, curr) => {
    acc[curr.value] = curr.text;
    return acc;
}, {});

const declineOptionsPreviewDict = declineOptions.reduce((acc, curr) => {
    acc[curr.value] = curr.text;
    return acc;
}, {});

type overviewTableDataType = {
    activitiesCount: number;
    registeredCount: number;
    attendedCount: number;
    declinedCount: number;
};

type overviewTableDetailViewDataType = {
    registeredActivities: {
        activity: {
            text: string;
        };
        DateAdded: string;
        registrant: {
            FirstName: string;
            LastName: string;
        };
    }[];
    attendedActivities: {
        activity: {
            text: string;
        };
        DateAdded: string;
        registrant: {
            FirstName: string;
            LastName: string;
        };
        participation: string;
    }[];
    declinedActivities: {
        activity: {
            text: string;
        };
        DateAdded: string;
        registrant: {
            FirstName: string;
            LastName: string;
        };
        declineReason: string;
    }[];
};


const ReportsAttendanceOverviewTable = ({
    overviewTableData,
    overviewTableDetailViewData,
    activities,
    facilityTimezone,
}: {
    overviewTableData: overviewTableDataType;
    overviewTableDetailViewData: overviewTableDetailViewDataType;
    activities: DailyActivity[];
    facilityTimezone: string;
}) => {

    const [detailView, setDetailView] = React.useState(false);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentTableHeaders, setCurrentTableHeaders] = React.useState([]);
    const [currentTableKeys, setCurrentTableKeys] = React.useState([]);

    const { activitiesCount = 0, registeredCount = 0, attendedCount = 0, declinedCount = 0 } = overviewTableData;
    const tableItems = {
        Activities: activitiesCount,
        Registered: registeredCount,
        Attended: attendedCount,
        Declined: declinedCount,
    };
    const formattedActivities = activities.map((activity: DailyActivity) => {
        const activityDate = activity.timestamp
            ? moment.tz(activity.timestamp, facilityTimezone).format('MM/DD/YYYY hh:mm A')
            : moment(activity.dateTimeString).format('MM/DD/YYYY hh:mm A');
        return {
            activityName: activity.text,
            dayTime: activityDate,
        };
    });
    const formattedRegistered = overviewTableDetailViewData.registeredActivities.map((registeredAttendeeEntry) => {
        const { FirstName, LastName } = registeredAttendeeEntry.registrant;
        return {
            activityName: registeredAttendeeEntry.activity.text,
            dayTime: moment.tz(registeredAttendeeEntry.DateAdded, facilityTimezone).format('MM/DD/YYYY hh:mm A'),
            resident: FirstName && LastName ? FirstName + ' ' + LastName : FirstName || LastName || '',
        };
    });
    const formattedAttended = overviewTableDetailViewData.attendedActivities.map((attendedAttendeeEntry) => {
        const { FirstName, LastName } = attendedAttendeeEntry.registrant;
        return {
            activityName: attendedAttendeeEntry.activity.text,
            dayTime: moment.tz(attendedAttendeeEntry.DateAdded, facilityTimezone).format('MM/DD/YYYY hh:mm A'),
            resident: FirstName && LastName ? FirstName + ' ' + LastName : FirstName || LastName || '',
            status: participationOptionsPreviewDict[attendedAttendeeEntry.participation] || attendedAttendeeEntry.participation,
        };
    });
    const formattedDeclined = overviewTableDetailViewData.declinedActivities.map((declinedAttendeeEntry) => {
        const { FirstName, LastName } = declinedAttendeeEntry.registrant;
        return {
            activityName: declinedAttendeeEntry.activity.text,
            dayTime: moment.tz(declinedAttendeeEntry.DateAdded, facilityTimezone).format('MM/DD/YYYY hh:mm A'),
            resident: FirstName && LastName ? FirstName + ' ' + LastName : FirstName || LastName || '',
            status: declineOptionsPreviewDict[declinedAttendeeEntry.declineReason] || declinedAttendeeEntry.declineReason,
        };
    });
    const detailViewItems = {
        Activities: formattedActivities,
        Registered: formattedRegistered,
        Attended: formattedAttended,
        Declined: formattedDeclined,
    };
    const tableHeaders = {
        Activities: ['Activity', 'Day/Time'],
        Registered: ['Activity', 'Day/Time', 'Resident'],
        Attended: ['Activity', 'Day/Time', 'Resident', 'Status'],
        Declined: ['Activity', 'Day/Time', 'Resident', 'Status'],
    };
    const tableKeys = {
        Activities: ['activityName', 'dayTime'],
        Registered: ['activityName', 'dayTime', 'resident'],
        Attended: ['activityName', 'dayTime', 'resident', 'status'],
        Declined: ['activityName', 'dayTime', 'resident', 'status'],
    };

    const handleClick = (type: string) => {
        setCurrentTableData(detailViewItems[type]);
        setCurrentTableHeaders(tableHeaders[type]);
        setCurrentTableKeys(tableKeys[type]);
        setDetailView(true);
    };
    return (
        <>
            <Grid style={{ marginBottom: '15px' }}>
                <h4 style={{ marginTop: '2.5vh', marginLeft: '0' }}>Attendance per time period</h4>
                <Grid.Row columns={4} style={{ margin: '0 10px 0 10px' }}>
                    {Object.keys(tableItems).map((key, index) => {
                        return (
                            <Grid.Column key={index}>
                                <Grid.Row>
                                    <h4 style={{ display: 'flex', justifyContent: 'space-evenly' }}>{key}</h4>
                                </Grid.Row>
                                <Grid.Row>
                                    <h3
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            marginTop: '3vh',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleClick(key)}
                                    >
                                        {tableItems[key]}
                                    </h3>
                                </Grid.Row>
                            </Grid.Column>
                        );
                    })}
                </Grid.Row>
            </Grid>
            <Modal open={detailView} onClose={() => setDetailView(false)} closeOnDocumentClick>
                <Modal.Header>Attendance per time period</Modal.Header>
                <Modal.Content>
                    <CustomTable
                        data={currentTableData}
                        loading={false} // Set to false as data is not being fetch currently
                        headers={currentTableHeaders}
                        rowKeys={currentTableKeys}
                        facilityTimezone="" // not required currently
                        formatString="" // not required currently
                        allowSearch={true}
                        searchkey={["activityName"]}
                        searchBoxPlaceholder="Search by activity name..."
                    />
                </Modal.Content>
            </Modal>
        </>
    );
};

export default ReportsAttendanceOverviewTable;
