import React, { FC, useEffect, useRef, useState } from 'react'
import { Dimmer, Image, Loader, Message, Tab, TabProps, Segment } from 'semantic-ui-react'
import { listSignageImageFromS3, listSignageImagesForContentTypeFromS3, getSignageImageFromS3 } from '../../services/ImageUpload'
import { ContentType } from '../../types/signage'
import FilePicker from '../S3FileUpload/FilePicker'
import PhotoGrid from '../PhotoGrid'
import LoadMore from '../LoadMore'
import styles from "../../pages/AdminPanel/Signage/Background.module.css";
import { toast } from 'react-toastify';

interface filePickerStatus {
    percentage: number
    isUploading: boolean
    finished: boolean
    error: string | null
}

interface Props {
    handleFileChange: (file: File) => void
    contentType?: ContentType
    contentId?: string
    galleryImageClickHandler: (copyUrl: string) => void
    clearSignageState: () => void
    getFetchedPreviewImage?: (img: string) => void
    title: string
    subTitle: string
    defaultImage?: string
}

const SignageContentImgCaptureAndDisplay: FC<Props> = (props) => {
    const [filePickerStatus, setFilePickerStatus] = useState<filePickerStatus>({
        error: null,
        finished: false,
        isUploading: false,
        percentage: 0
    }) // filePickerStatus values are required params for FilePicker , The setFilePickerStatus functon is  not being used in the current implementation because the image is only uploaded when the save button is clicked, not when a file is selected.
    const [previewImageSrc, setPreviewImageSrc] = useState<string | null>("")
    const [signageImageSrc, setSignageImageSrc] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>("")
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
    const [galleryImages, setGalleryImages] = useState<string[]>([])
    const [nextContinuationToken, setNextContinuationToken] = useState<string| null>(null)
    const [imageETags, setImageEtags] = useState<string[]>([])
    const [previewTime, setPreviewTime] = useState("")
    const [imageSignedUrl, setImageSignedUrl] = useState<string>("");
    const [loadingSignageImage, setLoadingSignageImage] = useState<boolean>(false);
    const [previewImgSize, setPreviewImgSize] = useState({
        Height: 0,
        Width: 0
    })
    const previewImgRef = useRef<any>({
        current: {
            clientHeight: 0,
            clientWidth: 0
        }
    });

    useEffect(() => {
        (async function () {
            if (props.contentId && props.contentType) {
                setIsLoading(true)
                const res = await listSignageImageFromS3(props.contentType, props.contentId)
                setIsLoading(false)
                if (!res || (res.filesFound === false)) {
                    if(props.defaultImage){
                        setPreviewImageSrc(props.defaultImage);
                    }
                    return
                }
                if (props.getFetchedPreviewImage) {
                    // todo fetch the image prefix programmatically
                    props.getFetchedPreviewImage(`https://speak2-signage.s3.amazonaws.com/${res}`);
                }
                setSignageImageSrc(res)
            }
        })()
        const minutesWithPadedZero =
            String(new Date().getMinutes()).length === 1
                ? '0' + String(new Date().getMinutes())
                : new Date().getMinutes();
        setPreviewTime(`${new Date().getHours()}:${minutesWithPadedZero}`);
    }, [])

    useEffect(() => {
        if (activeTabIndex === 1) { // gallery tab is active
            setGalleryImages([]); // remove all the existing elements to avoid duplicates
            (async function () {
                setIsLoading(true)
                if (props.contentType) {
                    const perPage = 20
                    const res = await listSignageImagesForContentTypeFromS3(props.contentType, perPage, nextContinuationToken)
                    setGalleryImages(res.images)
                    setNextContinuationToken(res.nextContinuationToken)
                    if (res.etags) {
                        setImageEtags((prev) => [...prev, , ...res.etags])
                    }
                    setIsLoading(false)
                }
            })()
        }
    }, [activeTabIndex])

    useEffect(() => {
        if (signageImageSrc) {
            // signageImageSrc eg -> activities/620fc722e1c6bc0009f6ac47/64e443104a30a06f51571ad2/externalApiImage_compressed%3C100kb.jpg
            const fetchSignageImage = async () => {
                try {
                    setLoadingSignageImage(true);
                    const [contentType = "", _, contentId = ""] = signageImageSrc.split("/");
                    const res = await getSignageImageFromS3(contentType as ContentType, contentId);
                    const { signedUrl = "", fileFound = false } = res;
                    if (fileFound) {
                        setImageSignedUrl(signedUrl);
                    }
                } catch (error) {
                    console.error("Error fetching signage image:", error);
                    toast.warn(error instanceof Error ? error.message : "Failed to fetch signage image", {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } finally {
                    setLoadingSignageImage(false);
                }
            };
            fetchSignageImage();
        }
    }, [signageImageSrc])

    const formatFileName = (fileName: string) => {  // Escape s3 unfriendly characters
       // but preserve file extension (.jpg, .png)
        const fileNameArray = fileName.split(".")
        const ext = fileNameArray.pop()
        const rawFileName = fileNameArray.join("")
        const URLSafeFileName = rawFileName.replace(/[^a-zA-Z0-9]/g, '') //strip url unsafe elements
        return (URLSafeFileName + '.' + ext) // file name + extension
    }

    const handleFilePick = async (files: File[]) => {
        setErrorMsg("")
        const file = files[0]

        // const hundredKB = 100000;
        // if (file && file.size && (file.size > hundredKB)) {
        //     setErrorMsg("File size should not exceed 100 KB")
        //     return
        // }

        const fileExtension = file.name.split('.').pop();

        if (fileExtension !== 'jpg' && fileExtension !== 'png') {
            setErrorMsg('Only JPG and PNG files are supported');
            return;
        }

        const formattedFileName = formatFileName(file.name)

        const fileBlob = new Blob([file], { type: file.type })

        const modifiedFile = new File([fileBlob], formattedFileName, { type: file.type })

        const reader = new FileReader()

        reader.onload = () => {
            if (reader.result) {
                const result = reader.result as string;
                setPreviewImageSrc(result)
                props.handleFileChange(modifiedFile)
            }
        }

        reader.readAsDataURL(file)
    }
    const handleTabChange = async function (_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        if (data.activeIndex !== undefined) {
            setActiveTabIndex(+data.activeIndex) // + converts the value to a number
        }
    }

    const imageClickHandler = (signageImageUrl: string) => {
        // clearning all the previous tab data when clicked on gallery image to handle edge case where both the copy and upload might be true while Submitting
        props.clearSignageState()
        setActiveTabIndex(0) // 0 is preview tab
        setPreviewImageSrc(signageImageUrl)
        props.galleryImageClickHandler(signageImageUrl)
        //is there any redundancy in setPreviewImageSrc and props.galleryImageClickHandler's functions? Can we drop setPreviewImageSrc and use props.... itself?
    }
    const bottomleftFontSize = (previewImgSize.Height * 2) / 21;
    const topleftFontSize = bottomleftFontSize * 1.5;
    const bottomleftsubFontSize = bottomleftFontSize / 2;
    const topleftPosition = (bottomleftFontSize * 1.5 / 3) + (bottomleftFontSize * 1.5 / 3 * 300 / 570);
    const bottomleftPosition = (bottomleftFontSize) + (bottomleftFontSize * 300 / 570);
    const bottomleftsubPosition = (bottomleftFontSize / 2.5) + (bottomleftFontSize / 2.5 * 1 / 570);
    const letterSpacing = (bottomleftFontSize*0.05)
    const panes = [
        {
            menuItem: 'Preview',
            render: () => (
                <>
                    <FilePicker
                        {...filePickerStatus}
                        onSelected={handleFilePick}
                        multiple={false}
                        accept={["image/*"]}
                    />

                    {((signageImageSrc || previewImageSrc) && !isLoading) &&
                        <Segment padded>
                            {
                                <div className={styles.container}>
                                    <img onLoad={() =>
                                        setPreviewImgSize({ Height: previewImgRef.current.clientHeight, Width: previewImgRef.current.clientWidth })}
                                        className={styles.image}
                                        ref={previewImgRef}
                                        src={
                                            loadingSignageImage ? undefined :
                                                previewImageSrc ? previewImageSrc :
                                                    imageSignedUrl ? imageSignedUrl :
                                                        signageImageSrc ? `https://speak2-signage.s3.amazonaws.com/${signageImageSrc}` :
                                                            undefined
                                        }
                                    />
                                    <div
                                        className={styles.topleft}
                                        style={{
                                            fontSize: `${topleftFontSize}px`,
                                            top: `${topleftPosition}px`
                                        }}>
                                        {previewTime}
                                    </div>
                                    <div
                                        className={styles.bottomleft}
                                        style={{ fontWeight: 'lighter', letterSpacing: `${letterSpacing}px`, fontSize: `${bottomleftFontSize}px`, bottom: `${bottomleftPosition}px` }}>
                                        {props.title.substring(0, 25) || "Title"}
                                    </div>
                                    <div
                                        className={styles.bottomleftsub}
                                        style={{ fontSize: `${bottomleftsubFontSize}px`, bottom: `${bottomleftsubPosition}px` }}>
                                        {props.subTitle.substring(0, 60) || "Subtitle"}
                                    </div>
                                </div>
                            }
                        </Segment>
                    }
                </>
            ),
        },
        {
            menuItem: 'Gallery',
            render: () => (
                <>
                    <PhotoGrid imageUrls={galleryImages} onImageClick={imageClickHandler} />

                        <LoadMore hasMore={!!nextContinuationToken} isLoading={isLoading} next={async () => {
                            setIsLoading(true)
                            if (props.contentType) {
                                const perPage = 20
                                const res = await listSignageImagesForContentTypeFromS3(props.contentType, perPage, nextContinuationToken, imageETags)
                                setGalleryImages(prev => [...prev, ...res.images])
                                setNextContinuationToken(res.nextContinuationToken)
                                setImageEtags((prev) => [...prev, , ...res.etags])
                                setIsLoading(false)
                            }
                        }} />
                </>
            ),
        },
    ]

    return (
        <div>
            {isLoading &&
                <Dimmer active={isLoading} inverted>
                    <Loader active={isLoading} size="big" />
                </Dimmer>
            }
            {errorMsg && <Message negative>{errorMsg}</Message>}
            <Tab
                activeIndex={activeTabIndex}
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                onTabChange={handleTabChange}
            />
        </div>
    )
}

export default SignageContentImgCaptureAndDisplay