import React from 'react'
import { Form, Message, Dropdown } from 'semantic-ui-react'
import { FieldProps } from 'formik'

interface DropdownOption {
    key: number | string
    text: string
    value: any
}

interface CustomProps {
    required?: boolean
    placeholder: string
    data: any[]
    options: DropdownOption[]
    search?: boolean
}

const GenericDropdown: React.FC<any> = (props: FieldProps<any> & CustomProps) => {
    const errMsg = (
        <Message error>
            <p>{props.form.errors[props.field.name]}</p>
        </Message>
    )
    const hasError = props.form.errors[props.field.name] && props.form.touched[props.field.name] ? true : false

    return (
        <Form.Field required={props.required || false} error={hasError}>
            <label>{props.placeholder}</label>
            <Dropdown
                selection
                {...props}
                onChange={(e, { value }) => props.form.setFieldValue(props.field.name, value)}
                value={props.field.value}
            />
            {hasError && errMsg}
        </Form.Field>
    )
}

export default GenericDropdown
