import React, { FC, useState } from 'react'
import { Checkbox, Dimmer, Image, List, Loader } from 'semantic-ui-react'
import { changeApproveStatus } from '../../../../services/Speak2Broadcaster'
import "./style.less"

interface Props {
    id: string
    name: string
    isApprove: boolean
}

const RelativeItem: FC<Props> = ({ name, isApprove, id }) => {
    const [approveVal, setApproveVal] = useState(isApprove || false)
    const [loading, setLoading] = useState(false)

    const checkBoxChangeHandler = async (e, data) => {
        setLoading(true)
        await changeApproveStatus(id, data.checked)
        setApproveVal(data.checked)
        setLoading(false)
    }

    return (
        <>
            <Dimmer active={loading} inverted>
                <Loader size='medium'>Loading...</Loader>
            </Dimmer>
            <List.Item>
                <List.Content>
                    <List.Header>{name}</List.Header>
                </List.Content>
                <List.Description className='mt-4'>
                    <Checkbox toggle label="Auto Approval" checked={approveVal} onChange={checkBoxChangeHandler} />
                </List.Description>
            </List.Item>
        </>
    )
}

export default RelativeItem