import React, { useState, useRef } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import audioRecorder, { Recorder } from './services/AudioRecorder';

interface Props {
    passAudioBlob: (audioBlob: Blob | undefined) => void;
}

const ServiceAudioRecorder: React.FC<Props> = (props: Props) => {
    const [recording, setRecording] = useState<boolean>(false);
    const [audioExists, setAudioExists] = useState<boolean>(false);
    const [recorder, setRecorder] = useState<Recorder | null>(null);
    const audioPlayer = useRef<HTMLAudioElement | null>(null);

    const beginRecording = async () => {
        try {
            const tempRecorder = await audioRecorder();
            setRecorder(tempRecorder);
            tempRecorder.record();
            setRecording(true);
        } catch (e) {
            console.error(e);
        }
    };

    const stopRecording = async () => {
        const blob = await recorder.stop();
        props.passAudioBlob(blob);
        setAudioExists(true);
        setRecording(false);
    };

    const clearMessageInput = () => {
        props.passAudioBlob(undefined);
        setAudioExists(false);
    };

    return (
        <>
            <div className="recording-state-image">
                {recording ? (
                    <div className="recording-button" onClick={stopRecording} />
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <Icon name='microphone' style={{ fontSize : "40px"}} onClick={beginRecording} />
                    </div>
                )}
            </div>
            {recording && (
                <div className="recording-audio">
                    <span className="recording-button"></span>
                    <p>Recording Audio</p>
                </div>
            )}
            {!recording && audioExists && (
                <div className="audio-exists">
                    <Button
                        className="small"
                        onClick={(e) => {
                            e.preventDefault();
                            if (!audioPlayer.current) {
                                return;
                            }
                            audioPlayer.current.src = recorder.play();
                            audioPlayer.current.play();
                        }}
                    >
                        <Icon name="play" />
                        Play
                    </Button>
                    <Button className="small" onClick={clearMessageInput}>
                        Cancel
                    </Button>
                </div>
            )}
            <div style={{ visibility: 'hidden', height: 0 }}>
                <audio ref={audioPlayer}></audio>
            </div>
        </>
    );
};

export default ServiceAudioRecorder;