import React, { FC, useState } from 'react';
import { Dimmer, Loader, Tab } from 'semantic-ui-react';
import CheckboxItems from '../ResidentProfile/CheckboxItems';
import { FetchDataAction, ToggleCheckboxAction } from '../ResidentProfile/useCheckbox';
interface Props {
    checkboxItems;
    dispatch: React.Dispatch<FetchDataAction | ToggleCheckboxAction>;
    loading: boolean;
}

const DietaryPanes: FC<Props> = ({ checkboxItems, dispatch, loading }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const dietaryPanes = [
        {
            menuItem: 'Dairy',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Dairy"
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Protein',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Protein"
                        checkboxItems={checkboxItems} 
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Vegetables',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Vegetables"
                        checkboxItems={checkboxItems} 
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Fruits',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Fruits"
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Plant Proteins',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="PlantProteins"
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Grains and Starches',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="GrainsAndStarches" 
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Other',
            render: () => (
                <Tab.Pane attached={false}>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <CheckboxItems
                        category="Other"
                        checkboxItems={checkboxItems} 
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ];
    return (
        <Tab
            style={{ marginTop: "10px" }}
            panes={dietaryPanes}
            activeIndex={activeTab}
            onTabChange={(e, { activeIndex }) => {
                if (activeIndex !== undefined) { // can't use !activeIndex as that will not work for 0th tab
                    setActiveTab(Number(activeIndex));
                }
            }}
        />
    );
};

export default DietaryPanes;