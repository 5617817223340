const kanbanInitData = {
    columns: [
        {
            key: 0,
            id: 0,
            title: 'Residents',
            cards: []
        },
        {
            key: 1,
            id: 1,
            title: 'Registered',
            cards: []
        },
        {
            key: 2,
            id: 2,
            title: 'Attended',
            cards: []
        },
        {
            key: 3,
            id: 3,
            title: 'Declined',
            cards: []
        }, 
        {
            key: 4,
            id: 4,
            title: 'Waiting List',
            cards: []
        }
    ]
}

export default kanbanInitData
