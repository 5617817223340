import React, { useEffect, useState } from 'react'
import { Grid, Image, List, Loader, Message, Modal } from 'semantic-ui-react'
import { fetchStaffUsers } from '../../services/Users'
import { User } from '../../types'
import LoadMore from '../LoadMore'
import { Link } from "react-router-dom"
import StaffDetailView from './StaffDetailView'
const StaffList = () => {
    const [staffList, setStaffList] = useState<User[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [recievedRecords, setRecievedRecords] = useState(0)
    const [totRecords, setTotRecords] = useState(0)
    const [timerId, setTimerId] = useState<any>(null)
    const [showStaffDetails, setShowStaffDetails] = useState<{ openModal: boolean, staff: User | null }>({ openModal: false, staff: null })
    const [error, setError] = useState({
        message: "",
        isError: false
    })

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const staff = await fetchStaffUsers(pageNo, "")
                setStaffList((prev) => ([...prev, ...staff.users]))
                setRecievedRecords(Number(staff.recievedResponse))
                setTotRecords(Number(staff.TotRecords))
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                setError({
                    isError: true,
                    message: e instanceof Error ? e.message : "Failed to fetch staff data"
                })
            }
        })()
    }, [pageNo])

    useEffect(() => {
        if (timerId) clearInterval(timerId);
        const intervalId = window.setInterval(async () => {
            const pageSize = pageNo * 50;
            const staff = await fetchStaffUsers(1, "", pageSize);
            setStaffList(staff.users);
        }, 10000);
        setTimerId(intervalId);
        return () => clearInterval(intervalId);
    }, [pageNo])

    const staffClickHandler = (staffUser: User) => {
        console.log({ staffUser })
        setShowStaffDetails({
            openModal: true,
            staff: staffUser
        })
    }

    const modalCloseHandler = () => {
        setShowStaffDetails({
            openModal: false, 
            staff: null
        })
    }

    const listView = <List relaxed>
        <Grid columns={5} relaxed>
            {staffList.map((el) => {
                return <Grid.Column key={el._id} stretched width={4}>
                    <List.Item style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                        <Image avatar src={el.Icon ? el.Icon : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                        <List.Content verticalAlign='middle'>
                            <List.Header style={{ color: (el.onBreak || (!el.isLoggedIn && !el.isWebAppLoggedIn)) ? "#EB1919" : "#24A129", fontWeight: "bold", textDecoration: "underline", fontSize: "12px", cursor: "pointer" }} onClick={() => {
                                staffClickHandler(el)
                            }}>
                                {el.FirstName} {el.LastName}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                </Grid.Column>
            })}
        </Grid>
        <LoadMore
            isLoading={isLoading}
            next={() => {
                setPageNo((prev) => prev + 1) // component is re-rerendered when pageNo is changed as pageNo is a useEffect dependency
            }}
            hasMore={recievedRecords < totRecords }
        />
    </List>

    const errorView = <Message error>{error.message}</Message>

    if (isLoading) {
        return <Loader active={isLoading} />
    }
    return (
        <>
            <Modal open={showStaffDetails.openModal} onClose={modalCloseHandler} closeIcon={true} closeOnDocumentClick >
                {
                    showStaffDetails.staff ?
                        <>
                            <Modal.Header>Staff Details</Modal.Header>
                            <Modal.Content>
                                <StaffDetailView staff={showStaffDetails.staff} />
                            </Modal.Content>
                        </> :
                        <>
                        </>
                }
            </Modal>
            {error.isError ? errorView : listView}
        </>
    )
}

export default StaffList