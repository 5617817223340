import API from './API'

import { PersonOfTheMonth } from '../types'

export async function newPersonOfTheMonth(
    type: 'staff' | 'resident' | 'affirmation',
    personOfTheMonth: PersonOfTheMonth,
): Promise<string> {
    let subPath = ''
    if (type === 'staff') {
        subPath = 'staff-of-the-month'
    } else if (type === 'resident') {
        subPath = 'resident-of-the-month'
    } else if (type === 'affirmation') {
        subPath = 'daily-affirmations'
    }

    console.log('potm', personOfTheMonth, type)

    const response = await API.lambdaPost(`/${subPath}/add`, personOfTheMonth)
    console.log('create response', response)

    if (!response) {
        // return Promise.reject('Create failed. Please ensure that the dates aren\'t overlapping');
        throw new Error("Create failed. Please ensure that the dates aren't overlapping")
    }
    return response
}

export async function updatePersonOfTheMonth(
    type: 'staff' | 'resident' | 'affirmation',
    personOfTheMonth: PersonOfTheMonth,
): Promise<number> {
    let subPath = ''
    if (type === 'staff') {
        subPath = 'staff-of-the-month'
    } else if (type === 'resident') {
        subPath = 'resident-of-the-month'
    } else if (type === 'affirmation') {
        subPath = 'daily-affirmations'
    }

    const response = await API.lambdaPut(`/${subPath}/update`, personOfTheMonth)
    console.log('create response', response)

    if (!response) {
        // return Promise.reject('Create failed. Please ensure that the dates aren\'t overlapping');
        throw new Error("Create failed. Please ensure that the dates aren't overlapping")
    }
    return response
}

export async function pplOfTheMonths(type: 'staff' | 'resident' | 'affirmation', filters = {}, pageSort?: { [key: string]: any; }): Promise<any> {
    let subPath = ''
    if (type === 'staff') {
        subPath = 'staff-of-the-month'
    } else if (type === 'resident') {
        subPath = 'resident-of-the-month'
    } else if (type === 'affirmation') {
        subPath = 'daily-affirmations'
    }
    const params = {
        Filter: { ...filters },
    }

    if (pageSort) {
        params['QueryOptions'] = pageSort
    }

    console.log('subPath', subPath)

    const response = await API.lambdaPost(`/${subPath}/list`, params)
    return response
}

export async function deletePersonOfTheMonth(data, type: 'staff' | 'resident' | 'affirmation'): Promise<any> {
    let subPath = ''
    if (type === 'staff') {
        subPath = 'staff-of-the-month'
    } else if (type === 'resident') {
        subPath = 'resident-of-the-month'
    } else if (type === 'affirmation') {
        subPath = 'daily-affirmations'
    }
    const { _id, deleteGroup = undefined, srcId = undefined } = data;
    const response = await API.lambdaPost(`/${subPath}/delete`, {
        _id,
        deleteGroup,
        srcId
    })
    return response
}

export async function getUploadUrl(feature, ext = 'wav') {
    try {
        const apiPath = '/files/audio'
        const params = { feature, ext }
        const response = await API.lambdaGet(apiPath, params)

        if (!response.signedUrl || !response.audioId) return false

        return response
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function uploadToSignedUrl(blob, url) {
    const params = {
        method: 'PUT',
        body: blob,
    }

    return fetch(url, params)
}

async function checkIfDestPlayableExists(audioFileName, feature) {
    try {
        return await API.lambdaGet('/files/checkIfDestPlayableExists', { audioFileName, feature })
    } catch (e) {
        throw e
    }
}

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function checkDestPlayableWithTimeDelay(destAudioId, type, delayMs) {
    await timeout(delayMs)
    return await checkIfDestPlayableExists(destAudioId, type)
}

// export async function updateActivity(dailyActivity: DailyActivity): Promise<void> {
//     const response = await API.lambdaPut('/dailyactivities/update', dailyActivity);
//     return response;
// }
