import API from './API'
import Promise from 'bluebird';

export async function getEndpoints(a4hRoomId: string): Promise<any> {
    return await API.lambdaPost('/endpoints/list', {
        a4hRoomId
    });
}

export async function assertEndpointAddress({ rooms, address, city, state, postalCode, timezoneName }): Promise<any> {
    return await API.lambdaPost('/endpoints/assertAddr', {
        rooms,
        address,
        city,
        state,
        postalCode,
        ...(timezoneName && { timezoneName })
    });
}

export async function assertEndpointTimezone({endpointId , timezoneName}) :Promise<any> {
    return await API.lambdaPost('/endpoints/assertTimezone', {
        endpointId,
        timezoneName
    });
}

export async function listFacilityResidentsEndpoints() :Promise<any> {
    return await API.lambdaGet('/endpoints/listResidentEndpoints')
}

export async function assertWifi(rooms, ssid, psk='') :Promise<any> {
    return await API.lambdaPost('/endpoints/assertWifi', {
        rooms,
        ssid,
        psk
    })
}

export async function getWifiPairingStatus(endpointId, operationId, alexaHospitalityStatusId = null): Promise<any> {
    return await API.lambdaPost('/endpoints/getWifiPairingStatus', { endpointId, operationId, alexaHospitalityStatusId });
}

export async function customAPIThrottler(asyncFunc, data, delayMs = 1000) {
    const maxRetries = 5;
    let res,
        retries = 0;
    do {
        try {
            res = await asyncFunc(data);
        } catch (error) {
            console.log('Error =>', error);
            res = error instanceof Error ? error.message : error;
        }
        if (res && ((res.data && res.data.error && res.data.error === 429) || (res.status === 429))) {
            retries = retries + 1;
            await Promise.delay(delayMs);
        }
    } while (res && ((res.data && res.data.error && res.data.error === 429) || (res.status === 429)) && retries <= maxRetries);
    return res;
};
