import React, { useEffect } from 'react'
import { Form, Icon, Label } from "semantic-ui-react";
import DatePicker from 'react-datepicker';
import moment from "moment";

const ExcludeDatesField = ({ excludeDates, setExcludeDates, datePickerMinDate, datePickerMaxDate, generateRecurrence = () => {} }) => {

    useEffect(() => {
        generateRecurrence();
    }, [excludeDates]);

    const handleDateChange = (date: Date | null) => {
        const dateString = date && moment(date).format('YYYY-MM-DD');
        if (date && !excludeDates.some(excludeDate => excludeDate === dateString)) {
            setExcludeDates([...excludeDates, dateString]);
        }
    };

    const removeExDate = (dateToRemove: string) => {
        const updatedExDates = excludeDates.filter(date => date !== dateToRemove);
        setExcludeDates(updatedExDates);
    };

    return (
        <Form.Field style={{ width: '300px' }}>
            <label>Exclude Dates</label>
            <DatePicker
                onChange={handleDateChange}
                placeholderText="Click to select a date"
                minDate={datePickerMinDate}
                maxDate={datePickerMaxDate}
            />
            {excludeDates.length > 0 && (
                <div>
                    {excludeDates.map((date, index) => (
                        <Label key={index} style={{ marginLeft: '0px' }} >
                            {date}
                            <Icon name='delete' onClick={() => removeExDate(date)} />
                        </Label>
                    ))}
                </div>
            )}
        </Form.Field>
    );

};

export default ExcludeDatesField;