import React, { useState } from 'react';
import { AuthState, Notification } from '../../../types';
import { Comment, Button, Grid } from 'semantic-ui-react';
import { distanceInWordsToNow } from 'date-fns';
import './style.less';
import { rrulestr } from 'rrule';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

interface Props {
    userName: string | null;
    imageUrl: string | null;
    notification: Notification;
    refreshNotifications: Function;
    editNotification?: (id: string) => void;
    deleteClickHandler?: (id: string) => void;
}

const NotificationItem: React.FC<Props> = (props: Props) => {
    const imageUrl = props.imageUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`;
    const recurrence = props && props.notification && props.notification.Recurrence;
    const rrule =
        recurrence &&
        rrulestr(recurrence)
            .all()
            .filter((date) => Date.parse(date.toISOString()) > Date.now()).length;
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const isScheduledEvent = (recurrenceString) => {
        let dtstart = recurrenceString.split("\n");
        dtstart = dtstart.length > 1 ? dtstart[0].split('DTSTART:')[1] : false;
        const until = recurrenceString.split("UNTIL=")[1];
        return dtstart === until
    }

    let announcementSendTime = '';
    const facilityTimezone = profile && profile.Facility && profile.FacilityTimeZone || '';
    
    if (facilityTimezone) {
        const timeStamp = props.notification.triggerTimestampISO ? props.notification.triggerTimestampISO : props.notification.DateAdded;
        const date = moment.tz(timeStamp, facilityTimezone).format('MM/DD/YYYY');
        const time = moment.tz(timeStamp, facilityTimezone).format('hh:mm A');
        if (props.notification.triggerTimestampISO) {
            moment.tz(props.notification.triggerTimestampISO, facilityTimezone).format('hh:mm A');
            moment.tz(props.notification.triggerTimestampISO, facilityTimezone).format('MM/DD/YYYY');
            if (new Date(props.notification.triggerTimestampISO) < new Date()) {
                announcementSendTime = `Announcement sent at ${date} ${time}`;
            } else {
                announcementSendTime = `Announcement will be sent at ${date} ${time}`;
            }
        } else {
            moment.tz(props.notification.DateAdded, facilityTimezone).format('hh:mm A');
            moment.tz(props.notification.DateAdded, facilityTimezone).format('MM/DD/YYYY');
            announcementSendTime = `Announcement sent at ${date} ${time}`;
        }
    }

    const announcementCreatedTimeMessage = (dateAdded:number) => {
        const time = moment.tz(dateAdded, facilityTimezone).format('hh:mm A');
        const date = moment.tz(dateAdded, facilityTimezone).format('MM/DD/YYYY');
        return `Created at ${date} ${time}`
    }

    return (
        <Comment className="NotificationItem Comment">
            <Comment.Avatar src={imageUrl} />
            <Comment.Content>
                {props.userName && <Comment.Author>{props.userName}</Comment.Author>}
                {!props.userName && <Comment.Author>Unknown User</Comment.Author>}
                <Comment.Metadata>
                    <div>{announcementCreatedTimeMessage(props.notification.DateAdded)}</div>
                </Comment.Metadata>
                <br />
                <Comment.Metadata>
                    <div>{announcementSendTime}</div>
                </Comment.Metadata>
                <br />
                {props.notification.Recurrence &&
                    props.notification.Recurrence.length &&
                    typeof props.notification.Recurrence === typeof 'string' && (
                        <Comment.Text>
                            {isScheduledEvent(props.notification.Recurrence) ? `Repeats off` : `Repeats ${props.notification.Recurrence.split('FREQ=')[1].split(';')[0]}`}
                        </Comment.Text>
                    )}
                <Comment.Text>
                    <b>{props.notification.Message}</b>
                </Comment.Text>
                <Comment.Metadata>
                    {props.notification.ResidentNames && props.notification.ResidentNames.map((name) => `${name}, `)}
                </Comment.Metadata>
                <br />
                <>
                    {
                        // if announcement is scheduled and its in future or if its a repeating announcement
                        (props.notification &&
                            props.notification.triggerTimestampISO &&
                            props.notification.triggerTimestampISO > new Date().toISOString()) ||
                        (props.notification && props.notification.Recurrence && rrule) ? (
                            <div className="mt-3">
                                <Button
                                    basic
                                    size="mini"
                                    color="blue"
                                    onClick={() => {
                                        if (props.editNotification) {
                                            props.editNotification(props.notification._id);
                                        }
                                    }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    basic
                                    size="mini"
                                    color="red"
                                    onClick={() => {
                                        if (props.deleteClickHandler) {
                                            props.deleteClickHandler(props.notification._id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                </>
            </Comment.Content>
        </Comment>
    );
};

export default NotificationItem;