import React, { FC, useEffect, useState } from 'react';
import { useAdminPanelRequests as useBasicRequests } from '../../hooks/basicRequestPanel';
import { toast } from 'react-toastify';
import { BasicReportSummaryFilters, Registrant } from '../../types';
import ContentRequestFilters from './ContentRequestFilters';
import moment from 'moment-timezone';
import CustomTable from '../CustomTable';
import PrintExportIcons from '../PrintExportIcons';
import { fetchRegistrants } from '../../services/Registrants';
import { fetchRequests } from '../../services/Basics';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { prntyrLink } from '../../util/data';
import { findKeyByValue, sendToast } from '../../util';
import { listAllPaginatedResponsesForQuestionsOfTheDayForMultipleResidents } from '../../services/QuestionOfTheDay';
import { capitalizeFirstLetter } from '../../pages/AdminPanel/Menu/utils';
import { QuestionOfTheDay } from '../../types/questionOfTheDay';
import './style.less';
interface Props {
    startDate: any; // any is used else it breaks for a reusable component BasicReportsSummary
    endDate: any;
    history?: any;
    facilityTimeZone: string;
    attendancePage?: boolean;
}

interface WithRequestedTime {
    RequestedTime: string | Date | number;
}

const ReportContentRequests: FC<Props> = ({
    startDate,
    endDate,
    history,
    facilityTimeZone,
    attendancePage = false,
}) => {
    const [basicRequestFilters, setBasicRequestFilters] = useState<BasicReportSummaryFilters>({
        fromDate: startDate,
        toDate: endDate,
        resident: 'all',
        food: 'all',
        type: attendancePage ? 'Daily Activities Attendance' : 'all',
        disable: false,
        reload: 0,
    });

    const [facilityRegistrants, setFacilityRegistrants] = useState<Registrant[]>([]);
    const [pageNo, setPageNo] = useState(1);
    const [registrantIdRoomNameDict, setRegistrantIdRoomNameDict] = useState({});
    const [responsesToQuestionOfTheDay, setResponsesToQuestionOfTheDay] = useState<QuestionOfTheDay[]>([]);
    const [qotdLoading, setQotdLoading] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState([{ RequestedTime: 'asc' }]); // This needs to be a state obj as it is used in the useBasicRequests hook
    const PAGE_SIZE = 20;
    const {
        requests: basicRequests,
        isFetching: basicIsFetching,
        isPaginating: basicIsPaginating,
        totalDataLength: basicRequestsDataLength,
    } = useBasicRequests(basicRequestFilters, sortBy, false, true, 1, PAGE_SIZE, history, true, facilityTimeZone);

    
    const requestTypeToDetailsDictionary = { // mapping taken from google sheet
        "Affirmations": "Affirmations",
        "Ate": "Ate",
        "Awake": "Awake",
        "Birthdays": "Birthdays",
        "Calendar": "Calendar",
        "Check in": "Check in",
        "checkin_exercise": "Exercise",
        "checkin_film": "Film",
        "checkin_game": "Game",
        "checkin_meal": "Meal",
        "checkin_meds": "Meds",
        "checkin_sleep": "Sleep",
        "checkin_stretch": "Strech",
        "checkin_tv": "TV",
        "checkin_wake": "Awake",
        "checkin_walked": "Walk",
        "checkin_water": "Water",
        "checkin_yoga": "Yoga",
        "Close Activity": "Activity closed",
        "CustomYesIntent": "Check in",
        "Daily Activities Attendance": "Daily Activities Attendance",
        "Daily Checkin": "Check in",
        "DailyActivities": "Daily Activities Attendance",
        "DailyActivitiesAttendance": "Daily Activities Attendance",
        "Exercise": "Exercise",
        "Feeling bad": "Feeling bad",
        "Game": "Game",
        "Heard Messages": "Listened to messages",
        "ImOk": "I'm okay",
        "List Activity Attendees": "List Activity Attendees",
        "Mail": "Mail",
        "Meds": "Meds",
        "Menu": "Menu",
        "Message": "Messages",
        "Movie": "Movie",
        "Question of the day": "Question of the day",
        "Read Activities": "Read Activities",
        "Resident Calendar": "Resident Calendar",
        "ResidentOfTheMonth": "Resident spotlight",
        "Sent Message": "Sent a message",
        "Sleep": "Sleep",
        "Speak2 support": "Speak2 support",
        "Staff Visit": "Staff visit",
        "Staff_Visit": "Staff visit",
        "StaffOfTheMonth": "Staff spotlight",
        "Start Activity": "Start activity",
        "Stretch": "Strech",
        "TV": "TV",
        "Unscheduled Event": "Unscheduled Event",
        "Unscheduled Interaction": "Unscheduled Event",
        "Video": "Video",
        "Walk": "Walk",
        "Water": "Water",
        "wellness_bad": "Feeling bad",
        "Yoga": "Yoga"
    }
    const tableHeaders = attendancePage ? ['FirstName', 'LastName', 'Apartment', 'Activity', 'Activity Time', 'Requested Time', 'Type', "Request Id"] : ['FirstName', 'LastName', 'Apartment', 'Requested Time', 'Type', 'Detail', "Request Id", 'Question', 'Answer'];

    const tableKeys = attendancePage ? ['Registrant_FirstName', 'Registrant_LastName', 'Apartment', 'Activity', 'ActivityTime', 'RequestedTime', 'Type', 'RequestId'] : ['Registrant_FirstName', 'Registrant_LastName', 'Apartment', 'RequestedTime', 'Type', 'Detail', 'RequestId', 'Question', 'Answer'];

    useEffect(() => {
        (async () => {
            const initialRegistrantIdRoomNameDict = {};
            const registrants = (await fetchRegistrants()) || [];
            registrants && setFacilityRegistrants(registrants as Registrant[]);
            registrants &&
                registrants.forEach(
                    (registrant: Registrant) =>
                        (initialRegistrantIdRoomNameDict[registrant._id] =
                            (registrant.Unit && registrant.Unit.Name) || '-'),
                );
            setRegistrantIdRoomNameDict(initialRegistrantIdRoomNameDict);
        })();
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            setBasicRequestFilters({
                ...basicRequestFilters,
                fromDate: startDate,
                toDate: endDate,
            });
            setPageNo(1);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            fetchQOTDResponses();
            setPageNo(1);
        }
    }, [registrantIdRoomNameDict, basicRequestFilters]);

    const fetchQOTDResponses = async () => { // This function separately fetches Question of the day responses as the requirement also needed to show answers and other data for it and all that info is not present in the basic request feed item which is created
        const residentIds = Object.keys(registrantIdRoomNameDict);
        if(residentIds.length === 0) {
            return;
        }
        try {
            setQotdLoading(true);
            if (basicRequestFilters.type === 'Question of the day' || basicRequestFilters.type === 'all') {
                // Extract raw date from the date object and utilize it to generate the start and end timestamps
                const startDateString = moment(startDate).format('YYYY-MM-DD');
                const endDateString = moment(endDate).format('YYYY-MM-DD');
                const startTimeStamp = moment.tz(startDateString, facilityTimeZone).startOf('day').valueOf();
                const endTimeStamp = moment.tz(endDateString, facilityTimeZone).endOf('day').valueOf();
                let residentIds: string[] = [];
                if (basicRequestFilters.resident === 'all') {
                    residentIds = Object.keys(registrantIdRoomNameDict);
                } else {
                    residentIds = [basicRequestFilters.resident];
                }
                const resp = await listAllPaginatedResponsesForQuestionsOfTheDayForMultipleResidents(residentIds, startTimeStamp, endTimeStamp);
                const generalizedResponses = resp.map((item) => {
                    const resident = facilityRegistrants.find((registrant) => String(registrant._id) === item.residentId);
                    return {
                        ...item,
                        Registrant_FirstName: resident && resident.FirstName || '-',
                        Registrant_LastName: resident && resident.LastName || '-',
                        Registrant: item.residentId,
                        RequestedTime: item.DateAdded,
                        Type: 'Question of the day',
                        _id: item._id
                    };
                });
                setResponsesToQuestionOfTheDay(generalizedResponses);
            } else {
                setResponsesToQuestionOfTheDay([]);
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Could not fetch questions of the day - please try after sometime');
        } finally {
            setQotdLoading(false);
        }
    }

    const transformToTableData = (basicRequests, facilityTimeZone, registrantIdRoomNameDict) => {
    
        if(facilityTimeZone && registrantIdRoomNameDict)
            return basicRequests.map((requestItem) => {
                if (attendancePage && !requestItem.customText) { // Filter basic requests that arent from attendance collection - all items from attendance collection will have custom text
                    return null
                }
                const activityText =
                    requestItem.customText && requestItem.customText.includes('Activity:')
                        ? (() => {
                            const startIndex = requestItem.customText.indexOf('Activity:') + 10; // Add 10 to exclude "Activity:"
                            const endIndex = requestItem.customText.indexOf('at', startIndex) - 1; // Subtract 1 to exclude the trailing space before "at"
                            const extractedText = requestItem.customText.substring(startIndex, endIndex);
                            return extractedText;
                        })()
                        : '-';

                const activityTime = requestItem.activityTime
                    ? moment.tz(requestItem.activityTime, facilityTimeZone).format('M/D/YY h:mm A')
                    : '-';
                const roomName = requestItem.Unit_Name || registrantIdRoomNameDict[requestItem.Registrant] || '-';
                let customDetails;
                if (requestItem.Type === 'Menu') { // details will display whatever resident asked for specifically like Lunch, Dinner etc.
                    const detailsObj =
                        (requestItem.Details && typeof requestItem.Details === 'string') ? JSON.parse(requestItem.Details) : requestItem.Details;

                    // Now, you can access the 'food' property
                    customDetails = detailsObj && detailsObj.food && detailsObj.food.split(':')[1];
                    if (customDetails) {
                        customDetails = capitalizeFirstLetter(customDetails);
                    }
                }
                return {
                    Registrant_FirstName: requestItem.Registrant_FirstName || '-',
                    Registrant_LastName: requestItem.Registrant_LastName || '-',
                    Apartment: roomName || '-',
                    RequestedTime: requestItem.RequestedTime
                        ? moment.tz(requestItem.RequestedTime, facilityTimeZone).format('M/D/YY h:mm A')
                        : '-',
                    Type: findKeyByValue(requestItem.Type) || requestItem.Type || '-',
                    Detail: customDetails || requestTypeToDetailsDictionary[requestItem.Type] || '-',
                    RequestId: requestItem._id || '-',
                    Question: requestItem.Type === 'Question of the day' ? requestItem.question : '-',
                    Answer: requestItem.Type === 'Question of the day' ? requestItem.response : '-',
                    Activity: activityText,
                    ActivityTime: activityTime,
                };
            }).filter((item) => item);
     }

    const handlePrintClick = () => {
        window.open(prntyrLink, '_blank');
    };

    const combinedRequests = [...basicRequests, ...responsesToQuestionOfTheDay];
    
    const sortedRequests = [...combinedRequests].sort((a, b) => {
        const timeA = new Date((a as WithRequestedTime).RequestedTime).getTime();
        const timeB = new Date((b as WithRequestedTime).RequestedTime).getTime();
        return timeB - timeA;
    });
    const tableData = transformToTableData(sortedRequests, facilityTimeZone, registrantIdRoomNameDict);
    const exportData = tableData.map((item) => {
        if(attendancePage) {
            //remove Date time question answer column and separate date and time
            return {
                'FirstName': item.Registrant_FirstName,
                'LastName': item.Registrant_LastName,
                'Apartment': item.Apartment,
                'Activity': item.Activity,
                'Activity Date': moment(item.ActivityTime).format('M/D/YY'),
                'Activity Time': moment(item.ActivityTime).format('h:mm A'),
                'Requested Date': moment(item.RequestedTime).format('M/D/YY'),
                'Requested Time': moment(item.RequestedTime).format('h:mm A'),
                'Type': item.Type,
                'Request Id': item.RequestId,
            };
        } else {
            return {
                'FirstName': item.Registrant_FirstName,
                'LastName': item.Registrant_LastName,
                'Apartment': item.Apartment,
                'Requested Date': moment(item.RequestedTime).format('M/D/YY'),
                'Requested Time': moment(item.RequestedTime).format('h:mm A'),
                'Type': item.Type,
                'Detail': item.Detail,
                'Request Id': item.RequestId,
                'Question': item.Question,
                'Answer': item.Answer
            };
        }
    });

    return (
        <><Dimmer active={basicIsFetching || basicIsPaginating || qotdLoading} inverted>
            <Loader active={basicIsFetching || basicIsPaginating || qotdLoading}/>
        </Dimmer><div className="wrapperParent">
                {!attendancePage &&
                    <div className='flexBox'>
                        <div className='flexBoxCentered'>
                            <ContentRequestFilters
                                filters={[basicRequestFilters]}
                                handleFilterChangeBasic={(updatedFilters) => {
                                    setBasicRequestFilters(updatedFilters);
                                } }
                                registrants={facilityRegistrants} />
                            <Segment style={{ padding: '5px 10px', marginLeft: '20px' }}>
                                <p style={{ marginBottom: '0px' }}>Count</p>
                                <strong>{tableData.length || 0}</strong>
                            </Segment>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <PrintExportIcons
                                exportData={exportData}
                                exportFileName="reports_Content_requests"
                                onPrintClick={handlePrintClick} />
                        </div>
                    </div>}
                {attendancePage && <div style={{ float: 'right' }}>
                    <PrintExportIcons
                        exportData={exportData}
                        exportFileName="reports_Content_requests"
                        onPrintClick={handlePrintClick}
                        disablePrintButton={attendancePage ? true : false} />
                </div>}
                <div style={{ marginTop: '5px' }}>
                    <CustomTable
                        allowSearch={false}
                        data={tableData}
                        headers={tableHeaders}
                        rowKeys={tableKeys}
                        totalDataLength={basicRequestsDataLength}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                        allowPrint={false}
                        allowExport={false}
                        loading={basicIsFetching || basicIsPaginating}
                        facilityTimezone={''}
                        formatString="D/M/Y H:M A"
                        setHeight={attendancePage ? '40vh' : '100vh'}
                        overflowX={attendancePage ? 'hidden' : 'auto'}
                        refreshCurrPage={true}
                        filter={[]} // needed to pass else the child componen't useEffect will get triggered unnecessarily when the table is rendered
                        />                      
                </div>
            </div></>
    );
};

export default ReportContentRequests;