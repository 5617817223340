import React, { useEffect, useState } from 'react'

import {
    Modal,
    Form,
    Dimmer,
    Loader,
    ModalHeader,
    ModalActions,
    Progress,
    Button,
    Input,
    Checkbox
} from 'semantic-ui-react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'
import { createAddressBookContact, enableInboundCalling } from '../.././../../services/Registrants'


interface Result {
    success: boolean
    data: {
        message: string
    }
}
const AddContactModal = ({ isModalOpen, close, residentId, reload }) => {
    const [contact, setContact] = useState('')
    const [error, setError] = useState('')
    const [usernameError, setUsernameError] = useState('')

    const [makingCalls, setMakingCalls] = useState(false)
    const [makingCallsPercent, setMakingCallsPercent] = useState(0)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)

    const [isLandline, setIsLandline] = useState(false)
    const [noAlexaEnabled, setNoAlexaEnabled] = useState(false)
    const [isAnnouncementAlert, setIsAnnouncementAlert] =  useState(false);
    const [isDisabled, setIsDisabled] =  useState(false);
    const [isValidName, setIsValidName] =  useState(false);
    const [isValidContact, setIsValidContact] =  useState(false);



    // const toggleRedirect = useContext(RedirectContext)

    const wait = async (seconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, seconds)
        })
    }

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    const enableInbound = async (residentId, contactId) => {
        // call enable inbound calling, until we get 200 / 201
        // wait for exponential
        // do this for 60
        console.log('Calling enable', residentId, contactId)

        let result: Result | null = null
        for (let i = 0; i < 8; i++) {
            const waitSeconds = 1000 * 3 ** i

            try {
                // @ts-ignore
                result = await enableInboundCalling(residentId, contactId)
                console.log('result:', result)
                setMakingCallsPercent(100)

                return result
            } catch (err) {
                console.log('error:', err)
                await wait(waitSeconds)
                setMakingCallsPercent(((1 + i) / 8) * 100)
            }
        }

        // setMakingCallsPercent(100);

        return null
    }
    const navigate = (to: string) => {
        // toggleRedirect(to)
        return
    }

    const toggleCurrentSelectionHandler = (triggerValue) => {
        switch (triggerValue) {
            case 'noAlexaApp':
                setNoAlexaEnabled(!noAlexaEnabled)
                setIsLandline(false)
                break
            case 'landline':
                setIsLandline(!isLandline)
                setNoAlexaEnabled(false)
                break
        }
    }

    useEffect(() => {
       const bool = !(contact.length >= 11 && contact.length < 16 && typeof contact === 'string' && name.length && typeof name === typeof 'string')
       setIsDisabled(bool)
    }, [contact, name])

    const validateName = () => {
        setIsValidName(!(name.length && typeof name === typeof 'string'))
    }

    const validateContact = () => {
        setIsValidContact(!(contact.length >= 11 && contact.length < 16 && typeof contact === 'string'))
    }

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        /*
            The above regex pattern checks for the following conditions:
                1) ^[^\s@]+: Starts with one or more characters that are not whitespace or '@'.
                2) @: Contains the '@' symbol.
                3) [^\s@]+: Followed by one or more characters that are not whitespace or '@'.
                4) \.: Contains a dot '.'.
                5) [^\s@]+$: Ends with one or more characters that are not whitespace or '@'.
        */
        return emailRegex.test(email);
    }

    const submitContact = async () => {
        setMakingCalls(true)
        setMakingCallsPercent(0)
        // validate email
        if (email && !validateEmail(email)) {
            sendToast('warn', 'Please enter a valid email address');
            setMakingCalls(false);
            return;
        }
        try {
            const addressBookRes = await createAddressBookContact(residentId, name, contact, !isLandline, email, isAnnouncementAlert, isLandline);
            setMakingCallsPercent(10)
            const {contactId}  = addressBookRes;
            if (noAlexaEnabled || isLandline) {
                setMakingCallsPercent(100);
                sendToast('success', 'Contact saved successfully.');
                setMakingCalls(false);
                close();
                await reload();
            } else {
                await wait(30 * 1000)
                const result = await enableInbound(residentId, contactId)
                if (result) {
                    setMakingCalls(false)
                    sendToast(
                        `${result && result.success ? 'success' : 'warn'}`,
                        `${(result && result.data && result.data.message) || (result.success ? `Successfully added contact!` : 'Something went wrong')}`,
                    )
                    close()
                    await reload()
                    // navigate(`/message-recipients/${residentId}/address-bookcontacts/${contactId}`)
                } else {
                    setMakingCalls(false)
                
                    sendToast('warn', 'Failed to enable inbound calling, try after sometime')
                    // navigate(`/message-recipients/${residentId}/address-bookcontacts/${contactId}`)
                    close()
                    await reload()
                }
            }   
        } catch (err) {
            console.error(err);
            sendToast('warn', 'Failed to create contact, try after sometime')
            setMakingCalls(false)
            close()
            await reload()
        }
    }
    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                if (!makingCalls) {
                    close()
                }
            }}
            size={'tiny'}
            className="invite-modal-root"
            closeIcon
        >
            <ModalHeader>
                <h5>Please enter the phone number to enabling inbound and outbound calling for this resident.</h5>

            </ModalHeader>

            <Form error>
                <Modal.Content className="fnf-invite-modal-content">
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>

                    <div className="name-section">
                        <Form.Field
                                control={Input}
                              error={isValidName && {
                                content: 'Name field is required',
                                pointing: 'below',
                              }}
                              value={name}
                              onChange={(e) => {
                                  setName(e.target.value)
                              }}
                              className="firstName"
                              required
                              name="FirstName"
                              onBlur={() => validateName()}
                              placeholder="First Name"
                        />

                    </div>
                    <div className="name-section">
                        <Input
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            required
                            className="phone"
                            name="email"
                            placeholder="Email"
                        ></Input>
                    </div>
                    <div className="name-section">
                        <Form.Field
                            defaultCountry="US"
                            className="phone"
                            placeholder="Contact number"
                            onBlur={() => validateContact()}
                            onChange={(e) => {
                                setUsernameError('');
                                if(e) setContact(e);

                            }}
                            value={contact}
                            control={PhoneInput}
                            error={isValidContact && {
                                content: 'phone number field is required',
                                pointing: 'below',
                            }}
                        />
                    </div>
                    <div className="my-4 m-2" style={{}}>
                        <Form.Field>
                            <Checkbox
                                label="Landline"
                                name="radioGroup"
                                value={'landline'}
                                checked={isLandline}
                                onClick={() => toggleCurrentSelectionHandler('landline')}
                            />
                            <p style={{ color :"black"}}>Is this number a landline? If you aren't sure, leave this blank</p>
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                            label="Announcements"
                            value={'announcement'}
                            checked={isAnnouncementAlert}
                            onClick={() => setIsAnnouncementAlert(isAnnouncementAlert => !isAnnouncementAlert)}
                            />
                            <p style={{ color :"black"}}>Enable announcement alerts</p>
                        </Form.Field>
                    </div>

                    {makingCalls && (
                        <div>
                            <Progress
                                percent={makingCallsPercent}
                                size="tiny"
                                autoSuccess
                                color="blue"
                                className="inbound-progress"
                                style={{ width: '30%', marginBottom: '0px' }}
                            />
                            <div className="inbound-progress-text">
                                {(!noAlexaEnabled && !isLandline)? ("We are saving this contact and enabling outbound and inbound calls(audio and video).\nPlease be patient while we work some security magic behind the scenes, this could take\nup to two minutes.") :(<></>)}
                            </div>
                        </div>
                    )}
                </Modal.Content>

                <ModalActions className="inviteBtn">
                    <Button
                        className="ui primary small button "
                        loading={makingCalls}
                        disabled={isDisabled}
                        onClick={async (e) => {
                            e.preventDefault()
                            await submitContact()
                        }}
                    >
                        Add Contact
                    </Button>
                </ModalActions>
            </Form>
        </Modal>
    )
}

export default AddContactModal
