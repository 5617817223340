import React, { useState, useEffect } from 'react'
import './style.less'
import { withRouter } from 'react-router'
import { Grid, Header, Icon, Segment } from 'semantic-ui-react'
import RequestTextWithFilter from '../../../components/RequestsTextList'
import StaffList from '../../../components/StaffList'
import NotificationsFormContainer from '../../../components/NotificationFormContainer'
import ResidentsLiveCall from '../../../components/ResidentsLiveCall'
import { useSelector } from 'react-redux'
import { AuthState } from '../../../types'
import { fetchOneFeaturedFacility } from '../../../services/Facilities';
import CreateRequestModal from '../../../components/Requests/CreateRequestModal';
import { toast } from 'react-toastify';
import { isFacilityTabEnabled } from '../../../services/Permissions';

const Home = (props) => {
    const [refreshReqListWithKey, setRefreshReqListWithKey] = useState(0)
    const [openLiveCallModal, setOpenLiveCallModal] = useState(false)
    const [supportedFacility, setSupportedFacility] = useState<string>('');
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });

    const toggleExpandModal = () => {
        setOpenLiveCallModal((prev) => !prev)
    }

    useEffect(()=>{
        const fetchFeaturedFacility = async () => {
            if (profile && profile.Facility) {
                try {
                    const { FacilityId = "" } = await fetchOneFeaturedFacility(profile.Facility);
                    if (FacilityId) {
                        setSupportedFacility(FacilityId);
                    } else {
                        console.error("Webrtc is not supported for this facility"); //since only featured facilty have webrtc feature no toast is shown
                    }
                } catch (error) {
                   console.error(error);
                }
            }
        };
        fetchFeaturedFacility();
    },[])

    return (
        <div style={{ width: '100%', maxWidth: '100%' }}>
            <Grid columns={2}>
                <Grid.Column computer={6} tablet={16} mobile={16}>
                    {/* DO NOT REMOVE KEY : IT IS USED TO REFRESH THE LIST BY CHANGING THE KEY */}
                    <RequestTextWithFilter key={refreshReqListWithKey} />
                </Grid.Column>
                <Grid.Column computer={10} tablet={16} mobile={16}>
                    <Grid columns={isFacilityTabEnabled(profile, 'orders') ? 2 : 1}>
                        <Grid.Column computer={isFacilityTabEnabled(profile, 'orders') ? 8 : 16} tablet={16} mobile={16}>
                            <Segment raised padded style={{ background: '#F9F9F9' }}>
                                <NotificationsFormContainer />
                            </Segment>
                        </Grid.Column>
                        {isFacilityTabEnabled(profile, 'orders') && (
                            <Grid.Column computer={8} tablet={16} mobile={16}>
                                <Segment
                                    raised
                                    padded
                                    style={{ marginRight: '10px', background: '#F9F9F9', height: '100%' }}
                                >
                                    <CreateRequestModal
                                        facilityId={profile && profile.Facility || ''}
                                        smallIcons={true}
                                        refresh={() => setRefreshReqListWithKey((prev) => prev + 1)}
                                    />
                                </Segment>
                            </Grid.Column>
                        )}
                    </Grid>
                    {profile && supportedFacility ? <Grid.Column style={{ margin: "20px 0px" }}>
                        <Segment raised style={{ marginRight: "10px", background: `url(${process.env.PUBLIC_URL}/webRTC-background.svg)`, backgroundSize: "cover", position: "relative" }}>
                            <div style={{ position: "relative" }}>
                                <Header as={"h3"} style={{ color: "white" }}>Live Call</Header>
                                <ResidentsLiveCall fullScreen={openLiveCallModal} onModalClose={toggleExpandModal} />
                            </div>
                            <Icon
                                name="expand"
                                size='small'
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    cursor: "pointer",
                                }}
                                onClick={toggleExpandModal}
                            />
                        </Segment>
                    </Grid.Column> : <></>}
                    <Grid>
                        <Grid.Row columns={5}>
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                                <Segment raised style={{ marginRight: '10px', background: '#F9F9F9' }}>
                                    <Header as={'h3'}>Staff</Header>
                                    <StaffList />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default withRouter(Home)
