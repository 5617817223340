import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm, Checkbox } from 'semantic-ui-react'
import * as Yup from 'yup'

import { GenericTextField, GenericDataSourceDropdown } from '../../../../components/GenericFormFields'

import './style.less'
import { Contact, Registrant } from '../../../../types'

export interface FormValues {
    FirstName: string
    LastName: string
    Email1: string
    Email2: string
    Phone: string
    Cell: string
    SpecialHandling: string | null
    Notes: string | null
    PreferredCommunication: string | null
    Registrant: Registrant | null
    NotifyFamily?: boolean
}

const ValidationSchema = Yup.object().shape({
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('First Name is required'),
    Email1: Yup.string()
        .email()
        .required('At least 1 email is required'),
    Email2: Yup.string().email(),
    Phone: Yup.string(),
    Cell: Yup.string(),
    SpecialHandling: Yup.string(),
    PreferredCommunication: Yup.string(),
    Notes: Yup.string(),
    NotifyFamily: Yup.boolean(),
    Registrant: Yup.object()
        .nullable()
        .required('You must assign a Resident to this Contact'),
})

interface Props {
    contact: Contact | null
    error: string | null
    title: string
    registrants: Registrant[]
    onSave: (data: FormValues) => Promise<any>
    cancel: () => any
    onDelete?: (id: string) => Promise<any>
    isSaving?: boolean
}

const ContactsForm: React.SFC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        FirstName: (props.contact && props.contact.FirstName) || '',
        LastName: (props.contact && props.contact.LastName) || '',
        Email1: (props.contact && props.contact.Email1) || '',
        Email2: (props.contact && props.contact.Email2) || '',
        Phone: (props.contact && props.contact.Phone) || '',
        Cell: (props.contact && props.contact.Cell) || '',
        SpecialHandling: (props.contact && props.contact.SpecialHandling) || '',
        PreferredCommunication: (props.contact && props.contact.PreferredCommunication) || '',
        Notes: (props.contact && props.contact.Notes) || '',
        Registrant: (props.contact && props.contact.Registrant) || null,
        NotifyFamily: (props.contact && props.contact.NotifyFamily) || false,
    })

    const canDelete = () => {
        return props.onDelete && props.contact && props.contact._id
    }

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.contact && props.contact._id) {
            props.onDelete(props.contact._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }

    return (
        <div className="ContactsForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                    await props.onSave(values)
                    actions.setSubmitting(false)
                }}
                validationSchema={ValidationSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField required name="FirstName" component={GenericTextField} placeholder="First Name" />
                        <FormikField required name="LastName" component={GenericTextField} placeholder="Last Name" />
                        <FormikField
                            required
                            name="Email1"
                            component={GenericTextField}
                            placeholder="Email 1"
                            type="email"
                        />
                        <FormikField name="Email2" component={GenericTextField} placeholder="Email 2" type="email" />
                        <FormikField name="Phone" component={GenericTextField} placeholder="Phone" />
                        <FormikField name="Cell" component={GenericTextField} placeholder="Cell" />
                        <FormikField
                            name="SpecialHandling"
                            component={GenericTextField}
                            placeholder="Special Handling"
                        />
                        <FormikField
                            name="PreferredCommunication"
                            component={GenericTextField}
                            placeholder="Preferred Communication"
                        />
                        <FormikField name="Notes" component={GenericTextField} placeholder="Notes" />
                        <FormikField
                            data={props.registrants}
                            required
                            name="Registrant"
                            component={GenericDataSourceDropdown}
                            placeholder="Registrant"
                            labelFields={['FirstName', 'LastName']}
                            imageField="Image"
                        />

                        <div className="field enable-escalation">
                            <Checkbox
                                name="NotifyFamily"
                                label="Notify Family for this request?"
                                checked={values.NotifyFamily}
                                onChange={() => {
                                    setFieldValue('NotifyFamily', !values.NotifyFamily)
                                }}
                                toggle
                            />
                        </div>

                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving Contact</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove Contact
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default ContactsForm
