import React, { useState, useEffect } from 'react';
import { Table, Pagination, Loader, Dimmer } from 'semantic-ui-react';
import { fetchCommunityLiaisons } from '../../../services/Users';
import { Facility, User } from '../../../types';
import { fetchAllActiveFacilities } from '../../../services/Facilities';
import 'semantic-ui-css/semantic.min.css';
import { fetchAllPaginatedData, sendToast } from '../../../util';
import PrintExportIcons from '../../../components/PrintExportIcons';

const CommunityLiaisons = (props) => {
	const [page_no, setPage_no] = useState(1);
	const [communityLiaisons, setCommunityLiaisons] = useState<User[]>([]);
	const [facilitiesMap, setFacilitiesMap] = useState<Map<string, Facility>>(new Map());
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [sortField, setSortField] = useState('FirstName');
	const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');

	const page_size = 10;

	useEffect(() => {
		const fetchFacilities = async () => {
			try {
				const facilities = await fetchAllActiveFacilities();
				const facilitiesMap = new Map(facilities.map(facility => [String(facility._id), facility]));
				setFacilitiesMap(facilitiesMap);
			} catch (err) {
				sendToast("error", "Error fetching facilities");
			}
		};

		fetchFacilities();
	}, []);

	useEffect(() => {
		const fetchCommunityLiaisonsData = async () => {
			try {
				setIsLoading(true);
				const sort = { [sortField]: sortDirection === 'ascending' ? 'desc' : 'asc' }; // Flip sort order due to backend logic issue
				const response = await fetchCommunityLiaisons({ pagingSorting: { page_no, page_size, sort } });
				const commLiaisons = response.Result;
				const totalRecords = response.TotRecords || 0;
				const liaisonsWithFacilityDetails = commLiaisons.map(liaison => {
					const facilityDetails = facilitiesMap.get(String(liaison.Facility));
					return {
						...liaison,
						facilityDetails
					};
				});
				setCommunityLiaisons(liaisonsWithFacilityDetails);
				setTotalPages(Math.ceil(totalRecords / page_size));
			} catch (error) {
				sendToast("error", "Error fetching community liaisons");
			} finally {
				setIsLoading(false);
			}
		};

		fetchCommunityLiaisonsData();
	}, [page_no, page_size, sortField, sortDirection, facilitiesMap]);

	const handlePaginationChange = (e, data) => {
		setPage_no(data.activePage);
	};

	const handleSort = (field) => {
		const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
		setSortField(field);
		setSortDirection(direction);
	};

	const fetchExportData = async () => {
		try {
			let exportPageSize = 100;
			const sort = { FirstName: 'desc' };
			const commLiaisons = await fetchAllPaginatedData(fetchCommunityLiaisons, {}, sort, exportPageSize);
			const liaisonsWithFacilityDetails = commLiaisons.map(liaison => {
				const facilityDetails = facilitiesMap.get(String(liaison.Facility));
				return {
					Community: facilityDetails ? facilityDetails.FriendlyName || facilityDetails.Name : "-",
					FirstName: liaison.FirstName,
					LastName: liaison.LastName,
					Cell: String(liaison.Cell || liaison.Phone || '-'),
					Email: liaison.Email || '-'
				};
			});
			return liaisonsWithFacilityDetails;
		} catch (err) {
			sendToast("error", "Error exporting data");
		}
	}

	return (
		<div>
			<Dimmer active={isLoading} inverted>
				<Loader active={isLoading} />
			</Dimmer>
			<h1>Community Liaisons</h1>

			<div style={{paddingRight: '3em', paddingBottom: '2em', marginTop: '-2.5em'}}>
				<PrintExportIcons
					exportAll={true}
					disablePrintButton={true}
					exportFileName={`Community_Liaisons`}
					communicationsPageStyles={true}
					fetchAll={fetchExportData}
				/>
			</div>
			
			<Table celled sortable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Community</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortField === 'FirstName' ? sortDirection : undefined}
							onClick={() => handleSort('FirstName')}
						>
							First Name
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortField === 'LastName' ? sortDirection : undefined}
							onClick={() => handleSort('LastName')}
						>
							Last Name
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortField === 'Cell' ? sortDirection : undefined}
							onClick={() => handleSort('Cell')}
						>
							Cell
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortField === 'Email' ? sortDirection : undefined}
							onClick={() => handleSort('Email')}
						>
							Email
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{communityLiaisons.map((liaison) => (
						<Table.Row key={liaison._id}>
							<Table.Cell>{
								liaison.facilityDetails ?
									liaison.facilityDetails.FriendlyName || liaison.facilityDetails.Name :
									"-"}
							</Table.Cell>
							<Table.Cell>{liaison.FirstName}</Table.Cell>
							<Table.Cell>{liaison.LastName}</Table.Cell>
							<Table.Cell>{liaison.Cell || liaison.Phone || '-'}</Table.Cell>
							<Table.Cell>{liaison.Email || '-'}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>

			<div style={{ textAlign: 'center' }}>
				<Pagination
					activePage={page_no}
					onPageChange={handlePaginationChange}
					totalPages={totalPages}
					siblingRange={1}
					boundaryRange={1}
				/>
			</div>
		</div>
	);
};

export default CommunityLiaisons;
