//RENAME FILE AND ALL CONNECTED IMPORTS TO ANNOUNCEMENTS INSTEAD OF NOTIFICATIONS
import React from 'react';
import { Notification, User, UserProfile } from '../../../types';

import './style.less';
import {
    fetchFilteredNotifications,
    sendAnnouncement,
    sendAlexaDeviceNotification,
    editFutureAnnouncement,
    deleteAnnouncement,
    deleteDeviceNotification,
} from '../../../services/Notifications';
import { fetchAllActiveUsers } from '../../../services/Users';
import { fetchAllRooms } from '../../../services/Facilities';
import { fetchRoomGroups } from '../../../services/RoomGroups';
import {
    Dimmer,
    Loader,
    Comment,
    Header,
    Message,
    Tab,
    TabProps,
    TransitionablePortal,
    Modal,
    Button,
    Segment,
} from 'semantic-ui-react';
import NotificationItem from './NotificationItem';
import { AppState } from '../../../reducers';
import { connect } from 'react-redux';
import { deleteRoomGroup, createRoomGroup } from '../../../services/RoomGroups';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import UpdateNotificationsForm from './UpdateForm';
import NotificationsForm from './Form';
import { toast } from 'react-toastify';
import rrulePlugin from '@fullcalendar/rrule';
import { rrulestr } from 'rrule';
import { jsonTocsvDownloader } from '../../../util/jsonTocsvDownloader';
import moment from 'moment-timezone';
interface State {
    selectedDateInCalendar: any
    notifications: Notification[]
    users: User[]
    isFetching: boolean
    error: string | null
    isSaving: boolean
    rooms: object[],
    roomsToNotify: number
    notifQueue: number
    roomGroups: object[]
    openEditForm: boolean
    selectedAnnouncement?: Notification
    showDeleteConfirmModal: boolean
    showRecurringConfirmModal: boolean
    showDetailViewModal: boolean
    openCreateForm: boolean
    selectedDate?: number
    deleteLoader: boolean
    printBtnSpinner: boolean
    FacilityTimeZone: string | null
    selectedRange: { startTimeStampISO: string, endTimeStampISO: string }
    fetchedRooms: any[]
    isFetchingCalendar: boolean
}

interface Props {
    profile: UserProfile | null;
    selectedAnnouncement?: undefined;
    disallowRoomControls?: boolean;
    selectedAnnouncees?: string[];
    hidePastNotifications?: boolean;
    hideAnnounceeSelection?: boolean;
}

class Notifications extends React.Component<Props, State> {
    state: State = {
        notifications: [],
        users: [],
        isFetching: false,
        error: null,
        isSaving: false,
        rooms: [{ key: 'all', text: 'All apartments', value: 'all' }],
        roomsToNotify: 0,
        notifQueue: 0,
        roomGroups: [],
        selectedDateInCalendar: undefined,
        openEditForm: false,
        selectedAnnouncement: undefined,
        showDeleteConfirmModal: false,
        showRecurringConfirmModal: false,
        showDetailViewModal: false,
        openCreateForm: false,
        selectedDate: undefined,
        deleteLoader: false,
        printBtnSpinner: false,
        FacilityTimeZone: this.props.profile && this.props.profile.FacilityTimeZone || null,
        selectedRange: { startTimeStampISO: new Date().toISOString(), endTimeStampISO: new Date().toISOString() },
        fetchedRooms: [],
        isFetchingCalendar: false,
    };

    formatAndSortRooms(rooms) {
        const formattedRooms = rooms.map((obj: any) => {
            let text = obj.RoomName;
            if (obj.Resident && obj.Resident !== '') {
                text = obj.Resident;
            }
            return {
                key: `${obj.RoomId}`,
                text,
                value: `${obj.RoomId}`,
            };
        });

        const sortedRooms = formattedRooms.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRooms;
    }

    formatAndSortRoomGroups(groups) {
        const formattedRoomGroups = groups.map((obj) => {
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.RoomIds]}`,
            };
        });

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        this.setState({ roomGroups: sortedRoomGroups });

        return sortedRoomGroups;
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null });
        try {
            const [users, rooms, roomGroups] = await Promise.all([
                fetchAllActiveUsers(),
                fetchAllRooms(),
                fetchRoomGroups(),
            ]);
            console.log({users})

            const sortedRooms = this.formatAndSortRooms(rooms);
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);

            const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                .concat(sortedRoomGroups)
                .concat(sortedRooms);
            this.setState({isFetching: false, users, rooms: roomOptions, fetchedRooms: rooms});
        } catch (e) {
            this.setState({ error: e.message });
        }
        this.setState({ isFetching: false, isFetchingCalendar: false})
    }

    async refreshNotifications(startTimeStampISO = '', endTimeStampISO = '', providedRooms = null) {
        this.setState({ isFetchingCalendar: true });
        const rooms = providedRooms || this.state.fetchedRooms || await fetchAllRooms(); 
        const notificationsFilter = {
            startTimestampISO: startTimeStampISO || this.state.selectedRange.startTimeStampISO,
            endTimestampISO: endTimeStampISO || this.state.selectedRange.endTimeStampISO,
        }
        const notifications = await fetchFilteredNotifications(notificationsFilter);
        notifications.forEach((n) => {
            const roomNameRoomIdMap = rooms.reduce((acc, room) => {
                acc[room.RoomId] = room.Resident;
                return acc;
            }, {});
            const RoomNames = n.RoomIds.map((roomId) => roomNameRoomIdMap[roomId]).filter((x) => x);
            Object.assign(n, { RoomNames });
        });
        this.setState({ notifications, isFetchingCalendar: false, isFetching: false });
    }

    async refreshRoomOptions() {
        this.setState({ isFetching: true, error: null });
        try {
            const [rooms, roomGroups] = await Promise.all([fetchAllRooms(), fetchRoomGroups()]);

            const sortedRooms = this.formatAndSortRooms(rooms);
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);

            const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                .concat(sortedRoomGroups)
                .concat(sortedRooms);
            this.setState({ isFetching: false, rooms: roomOptions, fetchedRooms: rooms });
        } catch (e) {
            this.setState({ isFetching: false, error: e.message });
        }
    }

    async sendDelayedDeviceNotification(message, subject, roomId, delayMs: number, triggerTimestampISO) {
        /* this function was used to delay notification based on message length to avoid load on server
         *  its currently commented for unclear use.
         */

        // const timeoutId = setTimeout(async () => {
        function timeout(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
        await timeout(delayMs);
        try {
            const res = await sendAlexaDeviceNotification(message, subject, roomId, triggerTimestampISO);
            return res;
            // clearTimeout(timeoutId);
        } catch (e) {
            console.error(e);
            this.setState({ isSaving: false, error: 'Device notification failed' });
        }
        // }, delayMs);
    }

    async handleSend(message, roomIds, dismissalTimeOffsetMinutes, sendDeviceNotification, triggerDateTimeString, recurrence, mail, sms, staff) {
        if (roomIds[0] === 'all') {
            roomIds = this.state.rooms.slice(1);
            roomIds = roomIds.map((room) => {
                return room.value;
            });
            roomIds = roomIds.filter((room) => {
                if (room.indexOf(',') === -1) return room;
            });
        }
        this.setState({ isSaving: true, error: null, roomsToNotify: roomIds.length });
        try {
            //notify 1 room at a time to escape lambda timeout
            // for (let i = 0; i < roomIds.length; i++) {
            // this.setState({ notifQueue: i });

            // if (sendDeviceNotification) {
            //     //     const delayMs = parseInt(String(message.length * 1000 / 3));
            //     const delayMs = 0; // set to 0 to avoid unwanted delay, will be replaced later
            //     [notificationId] = await Promise.all([
            //         this.sendDelayedDeviceNotification(message, '', roomIds, delayMs, triggerTimestampISO),
            //     ]);
            // }
            await sendAnnouncement(message, roomIds, triggerDateTimeString, recurrence, mail, sms, staff, dismissalTimeOffsetMinutes, true);  // sent notification by default so passing true

            // console.log("notificationId", notificationId);

            // if (dismissalTime && !recurrence) {
            // try {
            // this.setState({ error: null });
            // await sendPersistentVisualAlert(message, [roomIds[i]], dismissalTime, triggerTimestampISO);
            // } catch (e) {
            //     console.error(e);
            //     this.setState({ isSaving: false, error: 'Persistent alert failed' });
            // }
            // }
            // }
            this.setState({ isSaving: false, openCreateForm: false });
            await this.refreshNotifications();
        } catch (e) {
            console.error(e);
            this.setState({ isSaving: false, error: e.message });
        }
    }

    async handleEdit(data) {
        this.setState({
            showDetailViewModal: false,
        });
        if (data.roomIds[0] === 'all') {
            data.roomIds = this.state.rooms.slice(1);
            data.roomIds = data.roomIds.map((room) => {
                return room.value;
            });
            data.roomIds = data.roomIds.filter((room) => {
                if (room.indexOf(',') === -1) return room;
            });
        }
        this.setState({ isSaving: true, error: null, roomsToNotify: data.roomIds.length, showDetailViewModal: false });
        try {
            // if notification is checked (update notification)
            // if(this.state.selectedAnnouncement && this.state.selectedAnnouncement.notificationId && data.sendDeviceNotification){
            //     await editDeviceNotification({
            //         ...data,
            //         _id: this.state.selectedAnnouncement.notificationId
            //     });
            // }

            // console.log("notificationId", this.state.selectedAnnouncement!.notificationId)
            // delete notification if its unchecked
            // if(this.state.selectedAnnouncement && this.state.selectedAnnouncement.notificationId && data.sendDeviceNotification === false) {
            //     await deleteDeviceNotification({
            //         _id: this.state.selectedAnnouncement.notificationId
            //     })
            //     data.notificationId = null;
            // }

            // if it was unchecked and now its checked
            // if(data.sendDeviceNotification && (this.state.selectedAnnouncement && !(this.state.selectedAnnouncement.notificationId))){
            //     const delayMs = parseInt(String(data.message.length * 1000 / 3));
            //     let [notificationId] = await Promise.all([this.sendDelayedDeviceNotification(data.message, data.roomIds, delayMs, data.triggerTimestampISO)]);
            //     console.log("notificationId", notificationId);
            //     data.notificationId = notificationId;
            // }

            await editFutureAnnouncement(data);
            this.setState({ isSaving: false, showDetailViewModal: false });
            await this.refreshNotifications();
        } catch (e) {
            console.error(e);
            this.setState({ isSaving: false, error: e.message });
            toast.error(e.message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    findNotificationUser(notification: Notification): User | UserProfile | null {
        const res = this.state.users.find((u) => u._id === notification.AddedBy) || null;
        if (!res && this.props.profile) {
            return this.props.profile._id === notification.AddedBy ? this.props.profile : null;
        }
        return res;
    }

    async deleteRoomGroup(id) {
        await deleteRoomGroup(id);
        this.refreshRoomOptions();
    }

    async createRoomGroup(name, roomIds) {
        await createRoomGroup(name, roomIds);
        this.refreshRoomOptions();
    }

    async handleMonthChange(payload) {
        // fetch annoucement for the month
        let facilityTimeZone = this.state.FacilityTimeZone;
        if (!this.state.FacilityTimeZone) {
            if (!this.props.profile || !this.props.profile.FacilityTimeZone) {
                return;
            } else {
                facilityTimeZone = this.props.profile.FacilityTimeZone;
            }
        }
        const startTimestampISO = moment.tz(payload.start, facilityTimeZone).startOf('day').toISOString()
        const endTimestampISO = moment.tz(payload.end, facilityTimeZone).endOf('day').toISOString()
        this.setState({ selectedRange: { startTimeStampISO: startTimestampISO, endTimeStampISO: endTimestampISO }});
        await this.refreshNotifications(startTimestampISO, endTimestampISO);
    }

    handleDateClick(payload) {
        const todayDateStr = moment().startOf('day');
        const selectedDateMoment = moment(payload.dateStr);
        if (selectedDateMoment.isSameOrAfter(todayDateStr)) {
            const selectedDate = selectedDateMoment.hour(9).minute(0).second(0).toDate();
            this.setState({
                openCreateForm: true,
                selectedDate,
            });
        } else {
            toast.warn("Can't create announcement in the past", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    handleModalClose() {
        this.setState({
            ...this.state,
            openEditForm: false,
        });
    }

    handleEventClick(payload) {
        const selectedAnnouncement = this.state.notifications.filter(
            (event) => event._id === payload.event._def.publicId,
        )[0];
        this.setState({
            ...this.state,
            selectedDateInCalendar: payload.event._instance.range.start,
            showDetailViewModal: true,
            selectedAnnouncement: selectedAnnouncement,
            error: '',
        });
    }

    editNotification(id) {
        const selectedAnnouncement = this.state.notifications.filter((event) => event._id === id)[0];
        if (
            (selectedAnnouncement.triggerTimestampISO &&
                selectedAnnouncement.triggerTimestampISO > new Date().toISOString()) ||
            (selectedAnnouncement && selectedAnnouncement.Recurrence)
        ) {
            this.setState({
                ...this.state,
                openEditForm: true,
                selectedAnnouncement: selectedAnnouncement,
                error: '',
            });
        } else {
            toast.warn('Selected announcement has been triggered already', {
                position: 'bottom-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
            });
        }
    }

    closeModalHandler() {
        this.setState({
            openEditForm: false,
        });
    }

    closeCreateModalHandler() {
        this.setState({
            openCreateForm: false,
        });
    }

    closeDetailViewModalHandler() {
        this.setState({
            showDetailViewModal: false,
        });
    }

    panes0 = [
        {
            menuItem: 'Calendar',
            render: () => {
                return (
                    <div className="menu-calendar-view menu-calendar">
                        <Dimmer.Dimmable dimmed={this.state.isFetchingCalendar}>
                            <Dimmer active={this.state.isFetchingCalendar} inverted>
                                <Loader active={this.state.isFetchingCalendar} />
                            </Dimmer>
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin, rrulePlugin]}
                                initialView="dayGridMonth"
                                headerToolbar={{
                                    right: 'today prev,next',
                                }}
                                customButtons={{
                                    //commented the print functionality for now as it is not required for now but we might need it in future.
                                    // print: {
                                    //     text: this.state.printBtnSpinner ? 'Loading...' : 'Print Calendar',
                                    //     click: async (e) => {
                                    //         e.preventDefault();
                                    //         if (this.state.printBtnSpinner) {
                                    //             toast.warn("Task in progress, please wait until it's completed.", {
                                    //                 position: 'bottom-center',
                                    //                 autoClose: 5000,
                                    //                 hideProgressBar: false,
                                    //                 closeOnClick: true,
                                    //                 pauseOnHover: true,
                                    //             });
                                    //             return;
                                    //         }
                                    //         this.setState({ printBtnSpinner: true });
                                    //         const recurrenceEvents = this.state.notifications
                                    //             .filter((event) => event.Recurrence)
                                    //             .filter((x) => x)
                                    //             .map((event) => {
                                    //                 const rule = event.Recurrence && event.Recurrence;
                                    //                 const dates = rule && rrulestr(rule).all();
                                    //                 if (dates && dates.length) {
                                    //                     const futureDates = dates.map((date) => {
                                    //                         return {
                                    //                             ...event,
                                    //                             dateAdded: date.getTime(),
                                    //                             startTime: date.getTime(),
                                    //                         };
                                    //                     });
                                    //                     return futureDates;
                                    //                 }
                                    //             });
                                    //         const nonRecurringEvents = this.state.notifications.map((event) => {
                                    //             const startTime =
                                    //                 event.triggerTimestampISO && new Date(event.triggerTimestampISO);
                                    //             return {
                                    //                 ...event,
                                    //                 ...(startTime ? { startTime } : { startTime: event.DateAdded }),
                                    //             };
                                    //         });
                                    //         const printCalendarPayload = [
                                    //             ...recurrenceEvents.flat(),
                                    //             ...nonRecurringEvents,
                                    //         ];
                                    //         await jsonTocsvDownloader(printCalendarPayload, 'Announcement', true);
                                    //         this.setState({ printBtnSpinner: false });
                                    //     },
                                    // },
                                }}
                                events={[
                                    ...this.state.notifications.map((event) => {
                                        const start = event.triggerTimestampISO || event.DateAdded;
                                        const pattern = /exdate/i;
                                        let recurrence;
                                        /* below code handles full calendar bug https://github.com/fullcalendar/fullcalendar/issues/6105
                                            by removing exdate from recurrence
                                        */
                                        if (event.Recurrence && pattern.test(event.Recurrence.split('\n')[1])) {
                                            recurrence = event.Recurrence.split('\n');
                                            recurrence.splice(1, 1);
                                            recurrence = recurrence.join('\n');
                                        } else {
                                            recurrence = event.Recurrence;
                                        }
                                        // console.log({event})
                                        return event && event.Recurrence
                                            ? {
                                                  title: event.Message,
                                                  start: new Date(start),
                                                  end: new Date(event.EndDate || ''),
                                                  id: event._id,
                                                  rrule: recurrence,
                                                  exdate: pattern.test(event.Recurrence.split('\n')[1])
                                                      ? event.Recurrence.split('\n')[1].split(':')[1].split(',')
                                                      : [],
                                              }
                                            : {
                                                  title: event.Message,
                                                  start: new Date(start),
                                                  end: new Date(event.EndDate || ''),
                                                  id: event._id,
                                              };
                                    }),
                                ]}
                                datesSet={this.handleMonthChange.bind(this)}
                                dateClick={this.handleDateClick.bind(this)}
                                eventClick={this.handleEventClick.bind(this)}
                            />
                        </Dimmer.Dimmable>
                    </div>
                );
            },
        },
        {
            menuItem: 'List',
            render: () => {
                const sortedAnnouncement = this.state.notifications.sort((a, b) => b.DateAdded - a.DateAdded);
                const notificationItems = sortedAnnouncement.map((n) => {
                    const notificationUser = this.findNotificationUser(n);
                    const userName = notificationUser
                        ? `${notificationUser.FirstName} ${notificationUser.LastName}`
                        : null;
                    const imageUrl = notificationUser ? notificationUser.Icon : null;

                    return (
                        <NotificationItem
                            key={n._id}
                            userName={userName}
                            imageUrl={imageUrl}
                            notification={n}
                            refreshNotifications={this.refreshNotifications.bind(this)}
                            editNotification={this.editNotification.bind(this)}
                            deleteClickHandler={this.deleteClickHandler.bind(this)}
                        />
                    );
                });
                return (
                    <>
                        {this.state.users.length ? <Comment.Group> {notificationItems}</Comment.Group> : <Dimmer active inverted><Loader active /></Dimmer>}
                    </>
                );
            },
        },
    ];

    async deleteClickHandler(_id: string) {
        const selectedAnnouncement = this.state.notifications.filter((e) => {
            return e._id === _id;
        })[0];

        if (selectedAnnouncement.Recurrence) {
            const rule = selectedAnnouncement.Recurrence;
            if (rrulestr(rule).all().length > 1) {
                this.setState({
                    showRecurringConfirmModal: true,
                });
            } else {
                try {
                    await deleteAnnouncement(_id);
                    this.setState({
                        showDetailViewModal: false,
                    });
                    toast.success('Successfully deleted announcement', {
                        position: 'bottom-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                    });
                    await this.refreshNotifications();
                } catch (error) {
                    toast.error(
                        error instanceof Error && error.message
                            ? error.message
                            : 'Something went wrong while deleting announcement',
                        {
                            position: 'bottom-center',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                        },
                    );
                }
            }
        } else if (selectedAnnouncement.notificationId) {
            this.setState({
                showDeleteConfirmModal: true,
                selectedAnnouncement,
            });
        } else {
            await deleteAnnouncement(_id);
            this.setState({
                showDetailViewModal: false,
            });
            await this.refreshNotifications();
        }
    }

    async deleteAssoicatedNotificationHandler() {
        const selectedAnnouncement = this.state.selectedAnnouncement;
        const notificationId = selectedAnnouncement && selectedAnnouncement.notificationId;

        if (selectedAnnouncement && notificationId) {
            try {
                this.setState({
                    deleteLoader: true,
                });
                await deleteAnnouncement(selectedAnnouncement._id);
                await deleteDeviceNotification(notificationId);

                await this.refreshNotifications();
                this.setState({
                    showDeleteConfirmModal: false,
                    showRecurringConfirmModal: false,
                    showDetailViewModal: false,
                    isFetching: false,
                    deleteLoader: false,
                });
                toast.success('Successfully deleted announcement and assiociated notification', {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
            } catch (error) {
                console.error(error);
                toast.warn(
                    error instanceof Error && error.message
                        ? error.message
                        : 'Something went wrong while deleting announcement',
                    {
                        position: 'bottom-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                    },
                );
            }
        }
    }

    async exdateHandler() {
        const selectedAnnouncement = this.state.selectedAnnouncement;
        const recurrence = selectedAnnouncement && selectedAnnouncement.Recurrence;

        if (selectedAnnouncement && recurrence) {
            try {
                this.setState({
                    deleteLoader: true,
                });
                const pattern = /exdate/i;
                let newRecurrence;
                if (pattern.test(recurrence.split('\n')[1])) {
                    // push to existing exdates
                    const [_, ...exdates] = recurrence.split('\n')[1].split(':');
                    const date = this.state.selectedDateInCalendar.toISOString().split('T')[0].split('-').join('');
                    exdates.push(date);
                    newRecurrence = recurrence.split('\n');
                    newRecurrence[1] = `EXDATE:${exdates}`;
                    newRecurrence = newRecurrence.join('\n');
                } else {
                    // add exdate if doesn't exist
                    const date = this.state.selectedDateInCalendar.toISOString().split('T')[0].split('-').join('');
                    newRecurrence = recurrence.split('\n');
                    newRecurrence[2] = newRecurrence[1];
                    newRecurrence[1] = `EXDATE:${date}`;
                    newRecurrence = newRecurrence.join('\n');
                }

                let facilityTimeZone = this.state.FacilityTimeZone;
                if (!this.state.FacilityTimeZone) {
                    if (!this.props.profile || !this.props.profile.FacilityTimeZone) {
                        toast.error('Facility time zone not found', {
                            position: 'bottom-center',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                        });
                        return;
                    } else {
                        facilityTimeZone = this.props.profile.FacilityTimeZone;
                    }
                }
                const payload = {
                    _id: selectedAnnouncement._id,
                    message: selectedAnnouncement.Message,
                    roomIds: selectedAnnouncement.RoomIds,
                    triggerDateTimeString: moment.tz(selectedAnnouncement.triggerTimestampISO, facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
                    notificationId: undefined,
                    recurrence: newRecurrence,
                };
                await editFutureAnnouncement(payload);
                await this.refreshNotifications();
                this.setState({
                    showRecurringConfirmModal: false,
                    showDetailViewModal: false,
                    isFetching: false,
                    deleteLoader: false,
                });
                toast.success('Successfully deleted announcement', {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
            } catch (error) {
                console.error('oh year =========', error);
                toast.warn(
                    error instanceof Error && error.message
                        ? error.message
                        : 'Something went wrong while deleting announcement',
                    {
                        position: 'bottom-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                    },
                );
            }
        }
    }

    async deleteAnnoucementHandler() {
        this.setState({
            isFetching: true,
        });
        const selectedAnnouncementId = this.state.selectedAnnouncement && this.state.selectedAnnouncement._id;
        if (selectedAnnouncementId) {
            try {
                await deleteAnnouncement(selectedAnnouncementId);
                toast.success('Successfully deleted announcement', {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
                this.setState({
                    showDeleteConfirmModal: false,
                    isFetching: false,
                    showRecurringConfirmModal: false,
                    showDetailViewModal: false,
                    deleteLoader: false,
                });
                await this.refreshNotifications();
            } catch (error) {
                console.error(error);
                toast.warn(
                    error instanceof Error && error.message
                        ? error.message
                        : 'Something went wrong while deleting announcement',
                    {
                        position: 'bottom-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                    },
                );
                this.setState({
                    showDeleteConfirmModal: false,
                    isFetching: false,
                });
            }
        }
    }

    closeConfirmModalHandler() {
        this.setState({
            showDeleteConfirmModal: false,
            showRecurringConfirmModal: false,
        });
    }

    render() {
        // console.log("log state var", this.state)
        return (
            <div className="Notifications">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <TransitionablePortal
                    onClose={this.closeDetailViewModalHandler.bind(this)}
                    open={this.state.showDetailViewModal}
                    transition={{ animation: 'fade down', duration: 500 }}
                    closeOnDocumentClick={false}
                >
                    <Modal
                        open={this.state.showDetailViewModal}
                        size="tiny"
                        className="edit-item-form"
                        onClose={this.closeDetailViewModalHandler.bind(this)}
                    >
                        {this.state.selectedAnnouncement && (
                            <Comment.Group>
                                <NotificationItem
                                    userName={`${
                                        this.state.selectedAnnouncement.creatorInfo
                                            ? `${this.state.selectedAnnouncement.creatorInfo.firstName} ${this.state.selectedAnnouncement.creatorInfo.lastName}`
                                            : 'Unknown user'
                                    }`}
                                    imageUrl={
                                        this.state.selectedAnnouncement.creatorInfo
                                            ? this.state.selectedAnnouncement.creatorInfo.icon
                                            : ''
                                    }
                                    notification={this.state.selectedAnnouncement}
                                    editNotification={this.editNotification.bind(this)}
                                    refreshNotifications={this.refreshNotifications.bind(this)}
                                    deleteClickHandler={this.deleteClickHandler.bind(this)}
                                />
                            </Comment.Group>
                        )}
                    </Modal>
                </TransitionablePortal>
                <TransitionablePortal
                    open={this.state.openEditForm}
                    transition={{ animation: 'fade down', duration: 500 }}
                    closeOnDocumentClick={false}
                >
                    <Modal
                        open={this.state.openEditForm}
                        size="tiny"
                        className="edit-item-form"
                        onClose={this.closeModalHandler.bind(this)}
                    >
                        <UpdateNotificationsForm
                            onSubmit={this.handleEdit.bind(this)}
                            isSaving={this.state.isSaving}
                            roomOptions={this.state.rooms}
                            roomGroups={this.state.roomGroups}
                            deleteRoomGroup={this.deleteRoomGroup.bind(this)}
                            createRoomGroup={this.createRoomGroup.bind(this)}
                            disallowRoomControls={this.props.disallowRoomControls}
                            selectedAnnouncees={this.props.selectedAnnouncees}
                            hideAnnounceeSelection={this.props.hideAnnounceeSelection}
                            selectedAnnouncement={this.state.selectedAnnouncement}
                            handleModalClose={this.handleModalClose.bind(this)}
                            refreshNotifications={this.refreshNotifications.bind(this)}
                            facilityTimeZone = {(this.state.FacilityTimeZone || (this.props.profile && this.props.profile.FacilityTimeZone)) as string}
                        />
                    </Modal>
                </TransitionablePortal>
                <div>
                {/* <div style={{ width : "100%", display : "flex", justifyContent: "center" }} */}
                    <Segment raised padded style={{ background: "#F9F9F9", width: "35rem" }} >
                        <NotificationsForm //rename to announcement form
                            onSubmit={this.handleSend.bind(this)}
                            isSaving={this.state.isSaving}
                            roomOptions={this.state.rooms}
                            roomGroups={this.state.roomGroups}
                            deleteRoomGroup={this.deleteRoomGroup.bind(this)}
                            createRoomGroup={this.createRoomGroup.bind(this)}
                            disallowRoomControls={this.props.disallowRoomControls}
                            selectedAnnouncees={this.props.selectedAnnouncees}
                            hideAnnounceeSelection={this.props.hideAnnounceeSelection}
                            sourcepage={'home'}
                        />
                    </Segment>
                </div>
                {this.state.error && <Message negative>{this.state.error}</Message>}

                {this.props.hidePastNotifications ? (
                    <Comment.Group>
                        {/* {this.state.isSaving && (
                            <Header as="h4">
                                Notifying room {this.state.notifQueue + 1} of {this.state.roomsToNotify}...
                            </Header>
                        )} */}
                    </Comment.Group>
                ) : (
                    <div>
                        {/* {this.state.isSaving && (
                            <Header as="h4">
                                Notifying room {this.state.notifQueue + 1} of {this.state.roomsToNotify}...
                            </Header>
                        )} */}
                        {this.state.notifications.length >= 0 ? (
                            <Tab className="menu-tab" menu={{ secondary: true, pointing: true }} panes={this.panes0} />
                        ) : null}
                    </div>
                )}
                <Modal open={this.state.showDeleteConfirmModal}>
                    <Modal.Header>
                        There is a notification associated with this announcement. Would you like to delete that as
                        well?
                    </Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.deleteLoader}
                            basic
                            color="red"
                            onClick={this.deleteAssoicatedNotificationHandler.bind(this)}
                        >
                            Yes
                        </Button>
                        <Button
                            loading={this.state.deleteLoader}
                            basic
                            color="blue"
                            onClick={this.deleteAnnoucementHandler.bind(this)}
                        >
                            No
                        </Button>
                        <Button basic color="grey" onClick={this.closeConfirmModalHandler.bind(this)}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                {
                    <Modal open={this.state.showRecurringConfirmModal}>
                        <Modal.Header>Delete recurring event</Modal.Header>
                        <Modal.Actions>
                            <Button
                                loading={this.state.deleteLoader}
                                basic
                                color="blue"
                                onClick={this.exdateHandler.bind(this)}
                            >
                                This event
                            </Button>
                            <Button
                                loading={this.state.deleteLoader}
                                basic
                                color="red"
                                onClick={this.deleteAnnoucementHandler.bind(this)}
                            >
                                All events
                            </Button>
                            <Button basic color="grey" onClick={this.closeConfirmModalHandler.bind(this)}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
                <TransitionablePortal
                    open={this.state.openCreateForm}
                    transition={{ animation: 'fade down', duration: 500 }}
                    closeOnDocumentClick={false}
                >
                    <Modal
                        open={this.state.openCreateForm}
                        onClose={this.closeCreateModalHandler.bind(this)}
                        style={{ width: '35rem' }}
                    >
                        <div style={{ padding: '20px' }}>
                            <NotificationsForm //rename to announcement form
                                onSubmit={this.handleSend.bind(this)}
                                isSaving={this.state.isSaving}
                                roomOptions={this.state.rooms}
                                roomGroups={this.state.roomGroups}
                                deleteRoomGroup={this.deleteRoomGroup.bind(this)}
                                createRoomGroup={this.createRoomGroup.bind(this)}
                                disallowRoomControls={this.props.disallowRoomControls}
                                selectedAnnouncees={this.props.selectedAnnouncees}
                                hideAnnounceeSelection={this.props.hideAnnounceeSelection}
                                selectedDate={this.state.selectedDate}
                                sourcepage={'home'}
                            />
                        </div>
                    </Modal>
                </TransitionablePortal>
            </div>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    };
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
