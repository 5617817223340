import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm } from 'semantic-ui-react'
import * as Yup from 'yup'

import { GenericTextField, GenericImageField } from '../../../../components/GenericFormFields'

import './style.less'
import { Department } from '../../../../types'

export interface FormValues {
    Name: string
    Code: string
    Icon: string
    Notes: string
    Email: string
    Phone: string
}

const ValidationSchema = Yup.object().shape({
    Name: Yup.string().required('Department Name is required'),
    Code: Yup.string().required('You must specify a unique code for this department.'),
    Icon: Yup.string(),
    Notes: Yup.string(),
    Email: Yup.string().email(),
    Phone: Yup.string(),
})

const imagePlaceholder = `${process.env.PUBLIC_URL}/request_type_placeholder.png`

interface Props {
    department: Department | null
    error: string | null
    title: string
    onSave: (data: FormValues) => Promise<any>
    cancel: () => any
    onDelete?: (id: string) => Promise<any>
    isSaving?: boolean
}

const DepartmentsForm: React.SFC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Name: (props.department && props.department.Name) || '',
        Code: (props.department && props.department.Code) || '',
        Icon: (props.department && props.department.Icon) || '',
        Notes: (props.department && props.department.Notes) || '',
        Email: (props.department && props.department.Email) || '',
        Phone: (props.department && props.department.Phone) || '',
    })

    const canDelete = () => {
        return props.onDelete && props.department && props.department._id
    }

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.department && props.department._id) {
            props.onDelete(props.department._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }

    return (
        <div className="DepartmentsForm">
            <h1>{props.title}</h1>
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                    await props.onSave(values)
                    actions.setSubmitting(false)
                }}
                validationSchema={ValidationSchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField name="Icon" component={GenericImageField} placeholder={imagePlaceholder} />
                        <FormikField required name="Name" component={GenericTextField} placeholder="Department Name" />
                        <FormikField required name="Code" component={GenericTextField} placeholder="Department Code" />
                        <FormikField name="Notes" component={GenericTextField} placeholder="Notes" />
                        <FormikField name="Email" component={GenericTextField} placeholder="Email" type="email" />
                        <FormikField name="Phone" component={GenericTextField} placeholder="Phone" />

                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving Department</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove Department
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default DepartmentsForm
