import React, {useEffect, useState} from 'react'
import { Image, Button, Icon, Input } from 'semantic-ui-react'
import { getSignedActivityImageUrls } from '../../services/ImageUpload'
import { updateActivity } from '../../services/DailyActivities'
import S3FileUpload from '../../components/S3FileUpload'
import PhotoGrid from '../../components/PhotoGrid'

const ImageUploader = (props) => {
    if (!props.activity._id)
        throw new Error('Missing activity id')
//     // {/* <EditableImage /> */}
//     const [imageFile, setMessageImage] = useState(undefined);
//     const [loading, setLoading] = useState<boolean>(false)

//     useEffect(() => console.log(imageFile),[imageFile])

//     const fileChange = async (event) => {
//         console.log('entreerd')
//         const file = event.target.files[0];
//         const fileType = file && file.type.split('/')[0];
//         if (fileType === 'image') {
//             setMessageImage(file);
//         } else 
//             setMessageImage(undefined)
//     };

//     const handleUpload = (file) => {
//         setLoading(true)
//         console.log('hu', file)
//         setMessageImage(undefined)
//         setLoading(false)
        

//     }

//     return <>
//         <Input
//             type="file"
//             accept="image/*"
//             multiple={false}
//             onChange={fileChange}
//         />
//         <Button 
//             color='blue' 
//             icon='check' 
//             // floated='right' 
//             size='large'
//             disabled={loading || !imageFile}
//             loading={loading}
//             onClick={()=>handleUpload(imageFile)}
//             style={{marginLeft: '10px'}}
//         />
//         {/* <Button 
//             color='red' 
//             icon='x' 
//             // floated='right' 
//             size='large'
//             disabled={loading || !imageFile}
//             // loading={loading}
//             onClick={()=>setMessageImage(undefined)}
//             style={{marginLeft: '10px'}}
//         /> */}

//     </>

    const [imageUrls, setImageUrls] = useState<string[]>([])
    const handleFilePick = async (url) => {
        console.log('url', url)
        if (url) {
            const newFilename = url.split('/').pop()
            console.log('New image filename: ', newFilename)
            const queryId = props.activity.originalId?props.activity.originalId:props.activity._id
            const newSignedImageUrl = await getSignedActivityImageUrls(queryId, [newFilename])
            let newImageUrls = [...imageUrls]
            newImageUrls = newImageUrls.concat(newSignedImageUrl)
            setImageUrls(newImageUrls)
            return newFilename
        }
    }

    const loadImages = async () => {
        let allImages = []
        if (props.activity.imageFilenames && props.activity.imageFilenames.length !== 0) {
            const queryId = props.activity.originalId?props.activity.originalId:props.activity._id
            const signedImgUrls = await getSignedActivityImageUrls(queryId, props.activity.imageFilenames)
            // const signedImgUrls = await getSignedActivityImageUrls(props.activity._id, ['Screenshot from 2021-05-31 22-47-11_1646324952783.png']) //hardcoded
            //@ts-ignore
            allImages = [...imageUrls, ...allImages, ...signedImgUrls]
        }
        if (props.activity.externalImages && props.activity.externalImages.length !== 0) {
            //@ts-ignore
            allImages = [...imageUrls, ...allImages, ...props.activity.externalImages]
        }
        setImageUrls(allImages)
    }

    const updateActivityImageFilenames = async (filenames) => {
        try {
            const newActivity = JSON.parse(JSON.stringify(props.activity))
            if (!newActivity.imageFilenames) {
                newActivity.imageFilenames = []
            }
            const newImageFileNames = newActivity.imageFilenames.concat(filenames)
            newActivity.imageFilenames = newImageFileNames
            const updateActivityResponse = await updateActivity(newActivity)
            props.updateActivityImageFilenames(newImageFileNames)
        } catch (e) {
            console.error('Error updating image URLs: ', e)
        }
    }

    useEffect(()=>{
        loadImages()
    },[])
    const queryId = props.activity.originalId?props.activity.originalId:props.activity._id
    return <>
    <S3FileUpload 
        folderName={`activities/${queryId}/images`}
        onUploaded={(url) => handleFilePick(url)}
        accept={['image/*']}
        multiple={true}
        postUpload={async (filenames) => await updateActivityImageFilenames(filenames)
        }
        // onSelected={async (file) => {
        //     const filenames = [...imageFilenames]
        //     filenames.push(file.name)
        //     setImageFilenames(filenames)
        // }}
    />
    <PhotoGrid imageUrls={imageUrls} />
    </>

}

export default ImageUploader

