import React, { FC, useState } from 'react';
import { Button, Checkbox, Form, Grid, Item } from 'semantic-ui-react';
import SegmentedInputBox from '../../../../components/SegmentedInputBox';
import { RegistrantRelative } from '../../../../types';
import InboundSync from './InboundSync';
import {
    fetchPaginatedRegistrantRelatives,
    fetchPaginatedRegistrantA4HContacts,
    enableInboundCalling,
    deleteRelativeContact,
    updateAnnouncementAlert,
    updateA4HEmail,
    updateA4HContactNumber,
    updateA4HContactName
} from '../../../../services/Registrants';
import { toast } from 'react-toastify';

interface Props {
    name: string;
    contact: string;
    role: string;
    announcementsEnabled: boolean;
    image?: string;
    relationship: string;
    manager: boolean;
    contacts?: {
        contact: {
            name: string;
            phoneNumbers: [{
                number: string;
            }];
        };
        contactId: string;
        reciprocals: string;
    };
    listType: string;
    relative: RegistrantRelative;
    reload: () => Promise<void>;
    onSync: (contactId: any) => Promise<void>;
    registrantId: string;
    onUpdateSuccess?: () => Promise<any>;
    setModalOpenState: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactDetailView: FC<Props> = (props) => {
    const defaultImage = `${process.env.PUBLIC_URL}/avatar_placeholder.svg`;
    const emailValidationRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    const [isLoading, setIsLoading] = useState(false);
    const isEmail = emailValidationRegex.test(props.contact);
    const isRelativesDetailView = props.listType === "relatives";
    const isStaffRelative = props.listType === "staff"
    console.log({relative: props.relative})
    const [formValues, setFormValues] = useState<Partial<RegistrantRelative>>({
        announcementAlerts: props.relative.announcementAlerts,
        Contact: props.relative.Contact,
        email: props.relative.email,
        manager: props.relative.manager,
        RelativeName: props.listType === "relatives" && props.relative.contacts ? props.relative.contacts.contact.name : props.relative.RelativeName,
        contacts: props.relative.contacts, 
        isLandline: props.relative.isLandline
    });

    const [initialValues, setInitialValues] = useState<Partial<RegistrantRelative>>({
        announcementAlerts: props.relative.announcementAlerts,
        Contact: props.relative.Contact,
        email: props.relative.email,
        manager: props.relative.manager,
        RelativeName: props.listType === "relatives" && props.relative.contacts ? props.relative.contacts.contact.name : props.relative.RelativeName,
        _id: props.relative._id
    });

    const handleChange = (value: string, name?: string): void => {
        if (name) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                [name]: value
            }));
        }
    };
    const handleFormSubmit = async () => {
        try {
            setIsLoading(true);
            const { Contact, email, announcementAlerts, RelativeName } = formValues;

            if (email !== initialValues.email) {
                // validate email
                if (email && emailValidationRegex.test(email) === false) {
                    throw new Error("Please enter a valid email address");
                }
                const { success = false } = await updateA4HEmail(props.registrantId, props.relative.RelativeId, email, props.listType);

                if (success) {
                    setInitialValues(prev => ({
                        ...prev,
                        email
                    }));
                }
            }

            if (Contact !== initialValues.Contact) {
                const { success = false } = await updateA4HContactNumber(props.registrantId, props.relative.RelativeId, Contact, props.listType);
                if (success) {
                    setInitialValues(prev => ({
                        ...prev,
                        Contact
                    }));
                }
            }

            if (RelativeName !== initialValues.RelativeName) {
                const { success = false } = await updateA4HContactName(props.registrantId, props.relative.RelativeId, RelativeName, props.listType);
                if (success) {
                    setInitialValues(prev => ({
                        ...prev,
                        RelativeName
                    }));
                }
            }

            if (announcementAlerts !== initialValues.announcementAlerts) {
                const { success = false } = await updateAnnouncementAlert({ status: announcementAlerts, _id: props.relative._id, type: props.listType });
                if (success) {
                    setInitialValues(prev => ({
                        ...prev,
                        announcementAlerts
                    }));
                }
            }
        } catch (error) {
            console.error("handleFormSubmit", error);
            toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);
            if (props.onUpdateSuccess) {
                props.onUpdateSuccess();
            }
            props.setModalOpenState(false);
        }

    };

    const renderNameField = () => {
        if (isRelativesDetailView) {
            return <>
                <SegmentedInputBox label='Name' value={props.name} readonly={true} />
                <SegmentedInputBox label='AddressBook Name' name="RelativeName" value={formValues.RelativeName} onChange={handleChange} />
            </>;
        } else {
            return <SegmentedInputBox label='Name' name="RelativeName" value={formValues.RelativeName} onChange={handleChange} />;
        }
    };
    return (
        <Item>
            <Grid columns={2}>
                <Grid.Column width={4}>
                    <Item.Image src={props.image ? props.image : defaultImage} size='small' circular />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Item.Content verticalAlign='top'>
                        <Form loading={isLoading}>
                            {renderNameField()}
                            {isStaffRelative ? <SegmentedInputBox label="Landline" readonly={true} value={formValues.isLandline !== undefined || null
                                ? (formValues.isLandline === true ? "Yes" : "No")
                                : "-"} /> : <></>}
                            <SegmentedInputBox label={"Email"} name="email" value={formValues.email} onChange={handleChange} />
                            {/* todo add validation for below logic */}
                            <SegmentedInputBox label={"Cell"} name="Contact" value={formValues.Contact} onChange={handleChange} />
                            {/* todo confirm with shashank what the value for relationship is */}
                            {/* <SegmentedInputBox label={"Relationship"} value={"figure out"} readonly={true} /> */}
                            <SegmentedInputBox label={"Role"} value={formValues.manager ? "Manager" : "Relative"} readonly={true} />
                            {props.contacts && props.contacts.reciprocals && props.listType !== "room" ?
                                <>
                                    <Item.Extra>
                                        Inbound Calling:
                                        <a>
                                            <b> {props.contacts.reciprocals} </b>
                                        </a>
                                        {props.contacts.reciprocals !== "ENABLED" ?
                                            <span style={{ cursor: "pointer" }}>
                                                <InboundSync
                                                    onSync={props.onSync}
                                                    reload={props.reload}
                                                    listType={props.listType}
                                                    relative={props.relative}
                                                />
                                            </span> :
                                            <></>
                                        }
                                        <br/>
                                        <Checkbox style={{marginTop: '5px'}} label="Announcements" onChange={(e, { checked }) => {
                                            setFormValues(prevFormValues => ({
                                                ...prevFormValues,
                                                announcementAlerts: checked
                                            }));
                                        }} checked={formValues.announcementAlerts} />
                                    </Item.Extra>
                                </> :
                                <></>
                            }
                            <Form.Button style={{ marginTop: "10px" }} color='blue' basic onClick={handleFormSubmit}>Submit</Form.Button>
                        </Form>
                    </Item.Content>
                </Grid.Column>
            </Grid>
        </Item>
    );
};

export default ContactDetailView;