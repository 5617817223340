import API from './API';

export async function listQueueStatus({ filters = {}, pageSort = { page_size: 20, page_no: 1, sort: [{ DateAdded: 'asc' }] } }) {
    try {
        const params = {
            pagingSorting: pageSort,
            Filter: filters,
        };
        const response = await API.lambdaPost('/alexa-hospitality-queue-status/list', params);
        return response;
    }
    catch (error) {
        throw new Error(`Something went wrong in fetching queue status ${error}`);
    }
}