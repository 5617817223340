import React from 'react'
import { Image, Modal, Button, Icon, SemanticSIZES } from 'semantic-ui-react'

import './style.less'
import S3FileUpload from '../S3FileUpload'
import { sendToast } from '../../util';
import { throwCorrespondingExternalIntegrationError } from '../../util/externalintegrations';

interface Props {
    url?: string | null
    onSelected?: (newImageUrl: string | null) => any
    placeholder: string
    folderName?: string
    disabled?: boolean
    size?: SemanticSIZES
    isExternalResident?: boolean
    externalIntegrationSource?: string
    isAlisResident?: boolean
}

interface State {
    imageUrl?: string | null
    modalOpen: boolean
    uploaded: boolean
    disabled: boolean
    externalResidentWarning?: string
}

class EditableImage extends React.Component<Props, State> {
    folderName: string
    constructor(props: Props) {
        super(props)
        this.folderName = props.folderName || 'user-images'
        this.state = {
            imageUrl: this.props.url,
            modalOpen: false,
            uploaded: false,
            disabled: typeof this.props.disabled === 'boolean' ? this.props.disabled : false,
            externalResidentWarning: throwCorrespondingExternalIntegrationError({
                ...(this.props.isExternalResident && this.props.externalIntegrationSource && { isExternalResident: true, externalIntegrationSource: this.props.externalIntegrationSource }),
                ...(this.props.isAlisResident && { isAlisResident: true }),
                action: 'update image for'
            })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps !== this.props) {
            this.setState({
                externalResidentWarning: throwCorrespondingExternalIntegrationError({
                    ...(this.props.isExternalResident && this.props.externalIntegrationSource && { isExternalResident: true, externalIntegrationSource: this.props.externalIntegrationSource }),
                    ...(this.props.isAlisResident && { isAlisResident: true }),
                    action: 'update image for'
                })
            });
        }
    }

    handleOpen() {
        if (this.props.isExternalResident || this.props.isAlisResident) {
            sendToast('warn', this.state.externalResidentWarning || '');
            return;
        }
        if (!this.state.disabled) {
            this.setState({
                modalOpen: true,
            })
        }
    }

    handleCancel() {
        this.setState({
            modalOpen: false,
            imageUrl: this.props.url,
        })
    }

    handleConfirm() {
        this.setState({
            modalOpen: false,
        })
        if (this.props.onSelected) this.props.onSelected(this.state.imageUrl || null)
    }

    handleChange(newUrl: string) {
        this.setState({
            imageUrl: newUrl,
            uploaded: true,
        })
    }

    renderModal() {
        return (
            <Modal open={this.state.modalOpen} onClose={this.handleCancel.bind(this)} size="small">
                <Modal.Header>Select a Photo</Modal.Header>
                <Modal.Content>
                    {/* TODO: Maybe add preview here */}
                    <S3FileUpload
                        folderName={this.folderName}
                        onUploaded={(url) => this.handleChange(url)}
                        accept={['image/*']}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={this.handleCancel.bind(this)}>
                        <Icon name="remove" /> Cancel
                    </Button>
                    <Button primary basic onClick={this.handleConfirm.bind(this)} disabled={!this.state.uploaded}>
                        <Icon name="checkmark" /> Confirm
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        const src = this.state.imageUrl || this.props.url || this.props.placeholder
        return (
            <div className="editable-image-container">
                {this.renderModal()}
                <Image
                    className="editable-image"
                    src={src}
                    onError={() => this.setState({ imageUrl: this.props.placeholder })}
                    avatar
                    size={this.props.size || "small"}
                    onClick={this.handleOpen.bind(this)}
                />
            </div>
        )
    }
}

export default EditableImage
