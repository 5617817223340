import React, { Fragment } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Loader, Dimmer, Pagination, PaginationProps, Button, Input, Dropdown } from 'semantic-ui-react'
import { UniversityTraining, UserProfile } from '../../types'
import { getTraining } from '../../services/UniversityTrainings'
import { getStandardTrainings } from '../../services/StandardUniversityTrainings'

interface State {
    training: UniversityTraining[]
    isFetching: boolean
    activePageNumber: string | number | undefined
    totalItems: number
    filters: any
    type: string | null
}

interface Props extends RouteComponentProps {
    profile?: UserProfile | null
    type?: string | null
}

class TrainingList extends React.Component<Props, State> {
    paginationPerPageNumber = 30

    constructor(props: Props) {
        super(props)
        console.log('props', props)
        this.state = {
            training: [],
            isFetching: true,
            activePageNumber: 1,
            totalItems: 0,
            filters: {
                active: 'all',
                topicQuery: null,
            },
            type: props.type || null,
        }
        if (props.type === 'standard') {
            this.state.filters.type = 'standard'
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            this.loadTraining()
        } catch (e) {
            console.log(e)
        }
    }

    async changePage(e, data: PaginationProps) {
        this.loadTraining(data.activePage)
    }

    gotToTraining(id): void {
        if (this.state.type === 'standard') {
            this.props.history.push(`/admin/university/standard-trainings/${id}`)
        } else {
            this.props.history.push(`/admin/university/trainings/${id}`)
        }
    }

    redirectToAdd() {
        if (this.state.type === 'standard') {
            this.props.history.push(`/admin/university/standard-trainings/create`)
        } else {
            this.props.history.push(`/admin/university/trainings/create`)
        }
    }

    handleFilterChange(value) {
        this.loadTraining(null, value)
    }

    async loadTraining(pageNumber?, newFilters?) {
        this.setState({
            isFetching: true,
        })

        let activePageNumber = pageNumber || this.state.activePageNumber
        let filters = { ...this.state.filters }

        if (newFilters) {
            filters = { ...filters, ...newFilters }
            activePageNumber = 1
        }

        const updatedFilters = { ...filters }
        if (updatedFilters.active === 'all') {
            delete updatedFilters.active
        }
        if (updatedFilters.active == 'true') {
            updatedFilters.active = true
        }
        if (updatedFilters.active == 'false') {
            updatedFilters.active = false
        }

        delete updatedFilters.topicQuery
        if (filters.topicQuery) {
            updatedFilters.topic = { $regex: `.*${filters.topicQuery}.*`, $options: 'gi' }
        }

        let res
        console.log(this.state.type, 'type')
        if (this.state.type === 'standard') {
            res = await getStandardTrainings(null, activePageNumber, this.paginationPerPageNumber, updatedFilters)
        } else {
            res = await getTraining(null, activePageNumber, this.paginationPerPageNumber, updatedFilters)
        }

        if (filters.active == true) {
            filters.active = 'true'
        }
        if (filters.active == false) {
            filters.active = 'false'
        }

        const totalItems = res.TotRecords

        this.setState({
            activePageNumber,
            training: res.Result,
            isFetching: false,
            totalItems,
            filters,
        })
    }

    handleTopicQueryChange = (value) => {
        this.setState((state) => {
            return {
                filters: { ...state.filters, topicQuery: value },
            }
        })
    }

    render() {
        return (
            <Fragment>
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <div className="ui container list-all">
                    <div className="panel-body">
                        <div className="panel-body__top">
                            <Button
                                content="Create New"
                                icon="add"
                                labelPosition="left"
                                primary
                                onClick={() => this.redirectToAdd()}
                            />
                            <div className="search-container">
                                <div className="caption">Topic</div>
                                <div>
                                    <Input
                                        value={this.state.filters.topicQuery}
                                        onChange={(event, data) => {
                                            this.handleTopicQueryChange(data.value)
                                        }}
                                    />
                                    <Button
                                        className="tiny primary ml-4"
                                        onClick={() => this.loadTraining(null, { ...this.state.filters })}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                            <div className="status-dropdown-container">
                                <div>
                                    <div className="caption">Status</div>
                                    <Dropdown
                                        className="status-dropdown"
                                        value = {this.state.filters.active}
                                        onChange={(e, data) => {
                                            this.handleFilterChange({ ...this.state.filters, active: data.value })
                                        }}
                                        options={[
                                            { key: 'all', value: 'all', text: 'All' },
                                            { key: 'true', value: 'true', text: 'Active' },
                                            { key: 'false', value: 'false', text: 'Inactive' },
                                        ]}
                                        style={{width: "auto"}}
                                    >
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="total-item-count">
                                <div className="caption">Trainings Count</div>
                                <div className="item-count">{this.state.totalItems}</div>
                            </div>
                        </div>
                        <table className="ui celled table">
                            <thead>
                                <tr>
                                    <th>Topic</th>
                                    <th>Lesson</th>
                                    <th>Image</th>
                                    <th>Status</th>
                                    <th>URL</th>
                                    <th>Standard</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.training.length
                                    ? this.state.training.map((training) => (
                                          <tr
                                              onClick={() => this.gotToTraining(training._id)}
                                              style={{ cursor: 'pointer' }}
                                          >
                                              <td>{training.topic}</td>
                                              <td>{training.lesson}</td>
                                              <td>
                                                  <img
                                                      className="training-img small"
                                                      src={
                                                          training.image ||
                                                          `${process.env.PUBLIC_URL}/university_training_placeholder.png`
                                                      }
                                                      style={{ maxHeight: '50px' }}
                                                      alt=""
                                                  />
                                              </td>
                                              <td>{training.active == true ? 'Active' : 'Inactive'}</td>
                                              <td>{training.url}</td>
                                              <td>{training.Facility === 'standard' ? 'True' : 'False'}</td>
                                          </tr>
                                      ))
                                    : ''}
                            </tbody>
                        </table>
                        {this.state.totalItems > this.paginationPerPageNumber ? (
                            <div className="pagination-holder-training">
                                <Pagination
                                    activePage={this.state.activePageNumber}
                                    totalPages={Math.ceil(this.state.totalItems / this.paginationPerPageNumber)}
                                    onPageChange={this.changePage.bind(this)}
                                    siblingRange={1}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(TrainingList)
