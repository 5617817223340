import { useState, useEffect } from 'react'
import { fetchFeedItems } from '../services/Feed';
import { FeedItem } from '../types'
import moment from 'moment-timezone';
// import { ReportSummaryFilters } from '../types' // we might have to create a new one for this - based on the filters usage in future

export const useAdminPanelFeedItems = (
    filters: any,
    sortBy: object[] | undefined,
    pageNo = 1,
    pageSize = 40,
    facilityTimezone: string
) => {
    const [feedItems, setFeedItems] = useState<FeedItem[]>([])
    const [error, setError] = useState()
    const [pageCount, setPageCount] = useState(1)
    const [totalDataLength, settotalDataLength] = useState<number>(0)
    const [isFetching, setIsFetching] = useState(false)
    const [isPaginating, setIsPaginating] = useState(false)
    const [hasMorePages, setHasMorePages] = useState(true)

    const loadFeedItems = async (
        feedItemFilters: any,
        feedItemPageNo: number,
        feedItemPageSize: number,
        feedItemSortBy: Object[] | undefined,
        isFeedItemPaginating: boolean,
        facilityTimeZone: string = facilityTimezone
    ) => {
        if (!isFeedItemPaginating) {
            setFeedItems([])
            setIsFetching(true)
        }
        if (isFeedItemPaginating) {
            setIsPaginating(true)
        }
        const startDateUnix = moment.tz(feedItemFilters.fromDate, facilityTimeZone).startOf('day').valueOf();
        const endDateUnix = moment.tz(feedItemFilters.toDate, facilityTimeZone).endOf('day').valueOf();
        return fetchFeedItems(
            {
                ...feedItemFilters,
                fromDate: startDateUnix,
                toDate: endDateUnix,
            },
            {
                page_no: feedItemPageNo,
                page_size: feedItemPageSize,
                sorting: feedItemSortBy,
            }
        ).then(({ count, feedItems: freshFeedItems }) => {
            setFeedItems(freshFeedItems || [])
            setIsFetching(false)
            setIsPaginating(false)
            setPageCount(Math.ceil(count / feedItemPageSize))
            setHasMorePages(count >= feedItemPageSize * feedItemPageNo)
            settotalDataLength(count);
        })
    }

    useEffect(() => {
        const feedItemPageSize = pageSize * pageNo
        const feedItemPageNo = 1

        loadFeedItems(filters, feedItemPageNo, feedItemPageSize, sortBy, pageNo !== 1, facilityTimezone)
    }, [filters, sortBy, pageSize, pageNo])

    return {
        feedItems,
        error,
        isFetching,
        isPaginating,
        hasMorePages,
        pageCount,
        totalDataLength
    }
}
