import React from 'react';
import { Modal } from 'semantic-ui-react';
import ModalComponentsDocWidget from './ModalComponentsDocWidget';
import { DocWidgetData } from '../../../types/Service';
interface ModalDocWidgetProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    data: DocWidgetData;
    setData: React.Dispatch<React.SetStateAction<DocWidgetData>>;
    isEdit: boolean;
    setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
    getDocsData: () => void;
    facilityId?: string;
    source?: string;
}

function ModalDocWidget({ open, setOpen, data, setData, isEdit, setIsEdit, getDocsData, facilityId, source }: ModalDocWidgetProps) {
    return (
        <Modal open={open} style={{ width: '30vw' }}>
            <Modal.Content>
                <Modal.Description>
                    <ModalComponentsDocWidget
                        data={data}
                        setData={setData}
                        onCancel={() => {
                            setOpen(false);
                            setIsEdit && setIsEdit(false);
                        }}
                        isEdit={isEdit}
                        getDocsData={getDocsData}
                        facilityId={facilityId}
                        source={source}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}

export default ModalDocWidget;
