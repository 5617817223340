import React from 'react'
import FacilitiesForm, { FacilityFormValues } from '../FacilitiesForm'
import { fetchOneFacility, updateFacility, deleteFacility, fetchAllActiveFacilities } from '../../../../services/Facilities'
import { withRouter, RouteComponentProps } from 'react-router'
import { Facility } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchA4hFacilities } from '../../../../services/Facilities'


interface State {
    isFetching: boolean
    facility: Facility | null
    isSaving: boolean
    error: string | null
    facilityOptions: object[]
}

interface RouteInfo {
    id: string
}

class FacilitiesCreate extends React.Component<RouteComponentProps<RouteInfo>, State> {
    id: string
    constructor(props: RouteComponentProps<RouteInfo>) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            facility: null,
            isFetching: false,
            facilityOptions: []
        }

        this.id = props.match.params.id
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const facility = await fetchOneFacility(this.id);

            //handle dropdown
            const a4hFacilities = await fetchA4hFacilities();
            const facilities = await fetchAllActiveFacilities();
    
            /*
                Foreach a4h facility, if the ProfileName doesn't match with any of the facilities' AlexaProfile, push a4h facility to a new unmappedFacilities array
            */
            let unmappedFacilities :any[]= [];
    
            a4hFacilities.forEach((a4hFacility) => {
                if (!facilities.some(facility => facility.AlexaProfile === a4hFacility.Name)) {
                    unmappedFacilities.push(a4hFacility);                 
                }
            });
    
            const facilityOptions = unmappedFacilities.map((f) =>
                ({
                    key: f.id,
                    text: f.Name,
                    value: f.Name
                })
            );

            //allow current facility in edit form
            facilityOptions.push(({
                key: facility.a4hPropertyId ? facility.a4hPropertyId : unmappedFacilities.length,
                value: facility.Name,
                text: facility.Name
            }));

            this.setState({ isFetching: false, facility, facilityOptions });
        } catch (e) {
            console.error(e);
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FacilityFormValues) {
        this.setState({ isSaving: true, error: null })
        const postData = {
            ...data,
            _id: this.id,
        }
        try {
            await updateFacility(postData)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(facilityId: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteFacility(facilityId)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    async refreshFacilityDetails() {
        this.setState({ isFetching: true, error: null })
        try {
            const facility = await fetchOneFacility(this.id)
            this.setState({ isFetching: false, facility })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    render() {
        return (
            <div className="EditFacility">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.facility && (
                    <FacilitiesForm
                        title="Edit Facility"
                        facility={this.state.facility}
                        facilityOptions={this.state.facilityOptions}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        refresh={this.refreshFacilityDetails.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(FacilitiesCreate)
