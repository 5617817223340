import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { fetchFilteredDeviceConnectivityLogsItems } from '../../services/DeviceConnectivityLogs';
import CustomTable from '../CustomTable';
import { Input } from 'semantic-ui-react';
import {  DeviceStatusLog } from '../../types/DeviceStatusLog';
import moment from 'moment-timezone';
import { sendToast } from '../../util';

interface Props {
    startDate: Date | undefined;
    endDate: Date | undefined;
    facilityTimeZone: string
}

interface DeviceConnectivityLogs extends DeviceStatusLog {
    Unit_Name: string;
}

const ReportsDeviceStatusMonitor: FC<Props> = ({ endDate, startDate, facilityTimeZone }) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [deviceConnectivityLogs, setDeviceConnectivityLogs] = React.useState<DeviceConnectivityLogs[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');

    const fetchData = async () => {
        try {
            if (startDate && endDate && profile && profile.Facility) {
                setIsLoading(true);

                const momentStartDate = moment(startDate).format('YYYY-MM-DD');
                const momentEndDate = moment(endDate).format('YYYY-MM-DD');

                const formattedStartDate = moment.tz(momentStartDate, 'YYYY-MM-DD', facilityTimeZone).startOf('day').toDate();
                const formattedEndDate = moment.tz(momentEndDate, 'YYYY-MM-DD', facilityTimeZone).endOf('day').toDate();

                const deviceConnectivityLogsItems =
                    (await fetchFilteredDeviceConnectivityLogsItems(profile.Facility, formattedStartDate, formattedEndDate)) || [];

                // Flatten the DeviceStatusLogs and include Unit_Name
                const flattenedArray = deviceConnectivityLogsItems.flatMap(item => {
                    return item.DeviceStatusLogs.map(log => ({
                        ...log,
                        Unit_Name: item.Unit_Name,
                    }));
                });
    
                // Sort the array by TimeCaptured
                const sortedArray = flattenedArray.sort((a, b) => {
                    const dateA = new Date(a.TimeCaptured);
                    const dateB = new Date(b.TimeCaptured);
                    return dateB.getTime() - dateA.getTime();
                });
    
                setDeviceConnectivityLogs(sortedArray);
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : "Failed to fetch data")
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [endDate, startDate])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredLogs = deviceConnectivityLogs.filter((log) =>
        log.Unit_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        log.DSN.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <Input
                icon="search"
                placeholder="Search Unit or DSN"
                value={searchQuery}
                onChange={handleSearchChange}
            />

            <CustomTable
                // @ts-ignore
                data={filteredLogs}
                headers={['Apartment Name', 'Status', 'Time Captured', 'DSN', 'Device Name', 'Resident Full Name']}
                rowKeys={['Unit_Name', 'Status', 'TimeCaptured', 'DSN', 'DeviceFriendlyName', 'Resident_FullName']}
                facilityTimezone={facilityTimeZone}
                formatString='M/D/YY h:mm A'
                formatKeys={['TimeCaptured']}
                loading={isLoading}
                overflowX='hidden'
            />
        </div>
    )
}

export default ReportsDeviceStatusMonitor