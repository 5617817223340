import React from 'react';
import { useDrag } from 'react-dnd';
import { List, Label } from 'semantic-ui-react';
import { ServicesType } from '../../types/ServicesTypes';

interface Props {
    service: ServicesType;
    searchQuery: string;
}

const DragZone = ({ service, searchQuery }: Props) => {
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'DRAGGABLE_ITEM', id: service._id, serviceObj: service },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    return (
        <div ref={drag} style={{ marginBottom: "10px" }}>
            <List.Item
                style={{
                    fontWeight:
                        searchQuery &&
                            service.name.toLowerCase().includes(searchQuery.toLowerCase())
                            ? 'bold'
                            : 'normal',
                    cursor: 'pointer'
                }}
            >
                <Label>{service.name}</Label>
            </List.Item>
        </div>
    );
};

export default DragZone;
