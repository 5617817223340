import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { distanceInWordsToNow, format } from 'date-fns'

import { Image, Button, Icon, Dimmer, Loader, Message } from 'semantic-ui-react'
import {
    fetchOneRequest,
    closeRequest,
    setRequestSentiment,
    assignRequest,
    closeAllRequests,
    assignAllRequests,
} from '../../../../services/Requests'
import { Request, User, UserProfile } from '../../../../types'

import './style.less'
import AssignButton from '../../../../components/AssignButton'
import { fetchAllStaffUsers } from '../../../../services/Users'
import IconList from '../CategoryIconList/IconList';
import { RequestInstance } from '../../../../types/RequestInstance';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { AppState } from '../../../../reducers'
import { connect } from 'react-redux'
import { distanceInWordsToNowWithTimezone, formatDateWithTZ } from '../../../../util/timezone'
import CreateRequestModal from '../../../../components/Requests/CreateRequestModal';
import StandaloneDetailedForm from '../../../../components/Requests/StandaloneDetailedForm';

interface RouteInfo {
    id: string
}
interface Props extends RouteComponentProps<RouteInfo> {
    profile?: UserProfile | null
}

interface State {
    isFetching: boolean
    isFetchingUsers: boolean
    hasError: boolean
    error: string | null
    request: RequestInstance | null
    users: User[]
}

class SingleRequest extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.id = props.match.params.id
        this.state = {
            isFetching: false,
            isFetchingUsers: false,
            hasError: false,
            error: null,
            request: null,
            users: [],
        }
        this.handleClose = this.handleClose.bind(this)
    }
    load() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        fetchOneRequest(this.id).then(res => {
            this.setState({
                isFetching: false,
                request: res,
                hasError: res ? false : true,
                error: res ? null : `Could not not find request ${this.id}`,
            })
            this.fetchUsers(res && res.Department)
        })

    }
    componentDidMount() {
        this.load()
    }

    goToRequestsDashboard() {
        this.props.history.push('/admin/requests')
    }

    formatAcceptedTime(time) {
        if (!time) return undefined;

        const facilityTimezone = this.props.profile && this.props.profile.FacilityTimeZone || "";

        const formatString = moment(new Date(time)).year() > moment().year()
            ? 'MMM DD, YYYY, hh:mm A'
            : 'MMM DD, hh:mm A';

        return formatDateWithTZ(time, formatString, facilityTimezone);
    }


    formatSvcRequestTime = (time: number | Date | undefined) => {
        // collects time in timestamp and returns a string in the format "at 12:00 PM on Mar 19, 2021"
        if (!time) return "";
        const facilityTimezone = this.props.profile && this.props.profile.FacilityTimeZone || "";
        if (!facilityTimezone) {
            return "";
        }
        const formatString = 'h:mm A on MMM D, YYYY';
        const formattedTime = 'at ' + momentTimezone.tz(time, facilityTimezone).format(formatString);
        return formattedTime;
    }

    fetchUsers(departmentId: string | null) {
        this.setState({
            isFetchingUsers: true,
            hasError: false,
            error: null,
        })
        fetchAllStaffUsers().then(res => {
            this.setState({
                isFetchingUsers: false,
                users: res.filter(user => {
                    if (!departmentId) return true
                    const departmentIds = user.Departments && user.Departments.map(e => e._id)
                    return departmentIds.includes(departmentId)
                }),
            })
        })
    }

    async handleAssign(user: User | null) {
        if (!user) return
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })

        if (!(this.props.location.state && this.props.location.state.similarRequestIds)) {
            try {
                const newRequest = await assignRequest(this.id, user._id)

                this.setState({
                    isFetching: false,
                    hasError: false,
                    error: null,
                    request: newRequest,
                })
                this.goToRequestsDashboard()
            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                })
            }
        }
        if (this.props.location.state && this.props.location.state.similarRequestIds) {
            try {
                const allIds = this.props.location.state.similarRequestIds
                await assignAllRequests(allIds, user._id)
                this.goToRequestsDashboard()
            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                })
            }
        }
    }

    async handleClose(requestId = this.id) {

        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        if (!(this.props.location.state && this.props.location.state.similarRequestIds)) {
            try {
                console.log('closing request === ', this)
                const newRequest = await closeRequest(requestId)
                this.setState({
                    request: newRequest,
                    isFetching: false,
                    hasError: false,
                })
                this.goToRequestsDashboard()

            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                })
            }
        }

        if (this.props.location.state && this.props.location.state.similarRequestIds) {
            try {
                const ids = this.props.location.state.similarRequestIds
                await closeAllRequests(ids)
                this.goToRequestsDashboard()
            } catch (error) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: error instanceof Error && error.message ? error.message : "Could not close all requests",
                })
            }
        }
    }
    async setSentiment(sentiment: number) {
        if (!this.state.request) return
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
            request: {
                ...this.state.request,
                Sentiment: sentiment,
            },
        })
        try {
            const newRequest = await setRequestSentiment(this.id, sentiment)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    renderFaces(request: RequestInstance) {
        const isAccepted = request.Status === 'Accepted'
        const isClosed = request.Status === 'Closed'
        const faces = [
            <Icon
                key={1}
                className="face"
                onClick={() => this.setSentiment(1)}
                name="frown outline"
                color="red"
                size={request.Sentiment ? (request.Sentiment === 1 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={2}
                className="face"
                onClick={() => this.setSentiment(2)}
                name="frown outline"
                color="orange"
                size={request.Sentiment ? (request.Sentiment === 2 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={3}
                className="face"
                onClick={() => this.setSentiment(3)}
                name="meh outline"
                color="yellow"
                size={request.Sentiment ? (request.Sentiment === 3 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={4}
                className="face"
                onClick={() => this.setSentiment(4)}
                name="smile outline"
                color="olive"
                size={request.Sentiment ? (request.Sentiment === 4 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={5}
                className="face"
                onClick={() => this.setSentiment(5)}
                name="smile outline"
                color="green"
                size={request.Sentiment ? (request.Sentiment === 5 ? 'huge' : 'big') : 'big'}
            />,
        ]
        const faceButtons = <div className="faces">{faces}</div>
        if (isAccepted && !request.Sentiment) return faceButtons
        if ((isAccepted || isClosed) && request.Sentiment) return faceButtons
        return null
    }

    renderRequest(request: RequestInstance) {
        const isOpen = request.Status === 'Open'
        const isClosed = request.Status === 'Closed'
        const isAccepted = request.Status === 'Accepted'

        
        const closeBtn = (disable: boolean) => {
            return (
                <Button
                    basic
                    color='red'
                    disabled={isClosed || this.state.isFetching || disable}
                    loading={this.state.isFetching}
                    icon="play"
                    content={(this.props.location.state && this.props.location.state.similarRequestIds) ? 'Close All' : 'Close'}
                    id="actionButton"
                    onClick={() => {
                        this.handleClose();
                    }}
                />
            );
        }
        
        const avatarUrl = request.Registrant_Image || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`
        const reqTypeIcon = request.Department_Icon || `${process.env.PUBLIC_URL}/request_type_placeholder.png`

        // if (this.props.location.state) {
        //     const sortedTime = this.props.location.state.sort((a, b) => {
        //         return parseFloat(a.reqTime) - parseFloat(b.reqTime)
        //     })
        // }

        const generateReqTimeDiffString = (request) => {
            const isScheduled = request.ScheduledTime && request.ScheduledTime.startDate
            const facilityTZ = this.props.profile && this.props.profile.FacilityTimeZone || "";

            if(isScheduled) {
                return `Scheduled ${distanceInWordsToNowWithTimezone(request.ScheduledTime.startDate, facilityTZ)}`
            } else {
                return `Created ${distanceInWordsToNowWithTimezone(request.RequestedTime, facilityTZ)}`
            }
        }
        
        const renderServiceRequestDetails = (request: RequestInstance) => {
            return (
                <>
                    <div className="header" style={{ flexDirection: 'column' }}>
                        <CreateRequestModal
                            facilityId={this.props.profile && this.props.profile.Facility || ""}
                            request={request}
                            refresh={this.goToRequestsDashboard.bind(this)}
                            cancelClickHandler={this.goToRequestsDashboard.bind(this)}
                            parentLoading={this.state.isFetching}
                            staffUsers={this.state.users}
                            loadingStaffUsers={this.state.isFetchingUsers || this.state.isFetching}
                            handleAssignSubmit={this.handleAssign.bind(this)}
                            locationStateAssignButton={this.props.location.state}
                            handleOnClose={this.handleClose.bind(this)}
                        />
                    </div>
                </>
            );
        };

        const renderStandaloneRequestDetails = (request: RequestInstance) => {
            // Standalone request details
            return (
                <>
                    <StandaloneDetailedForm
                        request={request}
                        reqTypeIcon={reqTypeIcon}
                        avatarUrl={avatarUrl}
                        formatSvcRequestTime={this.formatSvcRequestTime}
                        users={this.state.users}
                        userLoading={this.state.isFetchingUsers || this.state.isFetching}
                        onAssignSelection={this.handleAssign.bind(this)}
                        locationState={(this.props.location.state && this.props.location.state.similarRequestIds)}
                        isAssignBtnDisabled={!isOpen && !isAccepted}
                        closeBtn={closeBtn}
                        renderFaces={this.renderFaces.bind(this)}
                    />
                </>
            );
        };

        return (
            <div className="request-view">
                {(request.OriginalServiceId || request.OriginalServiceInstanceId)
                    ? renderServiceRequestDetails(request)
                    : renderStandaloneRequestDetails(request)}
                {(request.OriginalServiceId || request.OriginalServiceInstanceId) && this.renderFaces(request)}
                {(this.state.hasError && <div className="error">{this.state.error}</div>) || null}
            </div>
        )
    }

    renderLoadingOrError() {
        const { isFetching, hasError, error } = this.state
        if (isFetching) {
            return (
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching} />
                </Dimmer>
            )
        }

        if (hasError && error) {
            return <div className="error">{error}</div>
        }
    }

    render() {
        const { request } = this.state
        return (
            <div className="SingleRequestAdmin">
                {request ? this.renderRequest(request) : this.renderLoadingOrError()}
            </div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

export default withRouter(connect(mapStateToProps)(SingleRequest));
