import React from 'react'

import './style.less'
import { Icon } from 'semantic-ui-react'

interface Props {
    accept: string[]
    multiple?: boolean
    dragging?: boolean
    percentage: number
    isUploading: boolean
    finished: boolean
    error: string | null
    handleDragIn: (e: DragEvent) => any
    handleDragOut: (e: DragEvent) => any
    handleDrag: (e: DragEvent) => any
    handleDrop: (e: DragEvent) => any
    handleFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => any
}

class FileUploadPresentation extends React.Component<Props, {}> {
    fileInputEl: HTMLInputElement | null = null
    dropEl: HTMLDivElement | null = null

    handleClick = () => {
        if (this.props.isUploading) return
        if (this.fileInputEl) {
            this.fileInputEl.click()
        }
    }

    componentDidMount() {
        if (this.dropEl) {
            this.dropEl.addEventListener('dragenter', this.props.handleDragIn)
            this.dropEl.addEventListener('dragleave', this.props.handleDragOut)
            this.dropEl.addEventListener('dragover', this.props.handleDrag)
            this.dropEl.addEventListener('drop', this.props.handleDrop)
        }
    }

    componentWillUnmount() {
        if (this.dropEl) {
            this.dropEl.removeEventListener('dragenter', this.props.handleDragIn)
            this.dropEl.removeEventListener('dragleave', this.props.handleDragOut)
            this.dropEl.removeEventListener('dragover', this.props.handleDrag)
            this.dropEl.removeEventListener('drop', this.props.handleDrop)
        }
    }

    render() {
        return (
            <div className="FileUploadPresentation" onClick={this.handleClick} ref={drop => (this.dropEl = drop)}>
                <input
                    ref={input => (this.fileInputEl = input)}
                    type="file"
                    className="file-input"
                    onChange={this.props.handleFileInputChange}
                    accept={this.props.accept.join(',')}
                    multiple={this.props.multiple}
                />
                {this.props.dragging && <div className="overlay" />}
                <div className="icon-container">
                    <Icon name="cloud upload" size="huge" />
                    {!this.props.isUploading && (
                        <div className="text">Select a file from your computer or drag one here.</div>
                    )}
                    {this.props.isUploading && !this.props.finished && (
                        <div className="text">{this.props.percentage}%.</div>
                    )}
                    {this.props.finished && !this.props.error && (
                        <div className="text" style={{color:'green'}}>File/s uploaded successfully.</div>
                    )}
                    {this.props.finished && this.props.error && (
                        <div className="text" style={{color:'red'}}>{this.props.error}</div>
                    )}
                </div>
            </div>
        )
    }
}

export default FileUploadPresentation
