export const GetSlotTime = (daySlot: string) => {
    switch (daySlot) {
        case "breakfast":
            return { startHour: 7, endHour: 10 };
        case "lunch":
            return { startHour: 11, endHour: 14 };
        case "brunch":
            return { startHour: 7, endHour: 14 };
        case "snack":
            return { startHour: 0, endHour: 24 };
        case "dinner":
            return { startHour: 16, endHour: 19 };
        default:
            return { startHour: 0, endHour: 24 };
    }
};

