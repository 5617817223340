const checkinKanbanInitData = {
    columns: [
        {
            key: 0,
            id: 0,
            title: 'Not Checked In',
            name_string: 'not_checked_in',
            cards: []
        },
        {
            key: 1,
            id: 1,
            title: 'Checked In',
            name_string: 'checked_in',
            cards: []
        },
        {
            key: 2,
            id: 2,
            title: 'Other',
            name_string: 'other',
            cards: []
        }
    ]
}

export default checkinKanbanInitData
