import React, { useEffect, useState } from 'react';
import { Dimmer, Icon, Loader, Popup, Table } from 'semantic-ui-react';
import { Restaurant, TableSeat } from '../../types/Dining';
import { deleteAsset, listTableSeatConfiguration } from '../../services/Assets';
import { toast } from 'react-toastify';
import CreateTableModal from './CreateTableModal';
import ConfirmationModal from '../DeleteConfirmationModal';
import { sendToast } from '../../util';

interface Props {
    restaurant: Restaurant;
    setRefreshPage: React.Dispatch<React.SetStateAction<boolean>>;
}
const TableSeatView = (props: Props) => {
    const [tables, setTables] = useState<TableSeat[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openCreateTableModal, setOpenCreateTableModal] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');
    const [maxCapOfSelectedTable, setMaxCapOfSelectedTable] = useState<number>(0);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);
    const maxCapOfTables = tables && tables.reduce((acc, table) => acc + table.MaxCap, 0);
    const restaurantMaxCap = props.restaurant && props.restaurant.MaxCap;
    
    useEffect(() => {
        fetchTables();
    }, [props.restaurant, refreshTable]);

    const fetchTables = async () => {
        try {
            setLoading(true);
            const filters = {
                RestaurantId: String(props.restaurant._id),
                Facility: props.restaurant.Facility || ""
            };  
            const response = await listTableSeatConfiguration(filters);
            setTables(response);
        } catch (error) {
            toast.error(`${error instanceof Error ? error.message : `Failed to fetch Table Seat's`}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const closeCreateTableModal = () => {
        setOpenCreateTableModal(false);
    };
    const handleOpenTableModal = (id?: string, maxCap?: number) => {
        setEditId(id || '');
        setMaxCapOfSelectedTable(maxCap || 0);
        setOpenCreateTableModal(true);
    };

    const handleDeleteTableSeat = (id: string) => {
        setDeleteId(id);
        setDeleteModal(true);
    };

    const cancelDelete = () => {
        setDeleteId("");
        setDeleteModal(false);
    };

    const confirmDelete = async () => {
        try {
            setLoading(true);
            setDeleteModal(false);
           const response =  await deleteAsset(deleteId);
            if (!response) {
                throw new Error('Failed to delete table. Please try again later');
            }
            sendToast('success', 'Table deleted successfully.')
            props.setRefreshPage(prev => !prev);
            refreshTable && setRefreshTable(prev => !prev);
        } catch (error) {
            toast.error(`${error instanceof Error ? error.message : 'Failed to delete restaurant'}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
        finally {
            setDeleteId("");
            setLoading(false);
        }
    };

    return (
        <div>
            <Dimmer active={loading} inverted>
                <Loader size="small">Loading</Loader>
            </Dimmer>
            <div style={{ position: 'relative' }}>
                <h4>{props.restaurant.AssetName}</h4>
                <p>{props.restaurant.Description}</p>
                <Popup 
                trigger={
                    <Icon className="icon-placement" name="plus" size="large" style={{ cursor: "pointer" }} onClick={() => handleOpenTableModal()} />
                }
                content='Add Table'
                position='top right'
                />
            </div>
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Table-Seat</Table.HeaderCell>
                        <Table.HeaderCell>Max Cap</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tables && tables.length ? (
                        tables.map((table, index) => (
                            <Table.Row className="table-row" key={index}>
                                <Table.Cell style={{ cursor: "pointer" }}>
                                    <p className="clickable" onClick={() => table && handleOpenTableModal(table._id, table.MaxCap)} > {table.AssetName}</p>
                                </Table.Cell>
                                <Table.Cell>{table.MaxCap}</Table.Cell>
                                <Table.Cell className='table-cell' >{table.Description}
                                    <Popup
                                    trigger={
                                        <Icon className="trash-icon" name='trash' size="large" onClick={() => handleDeleteTableSeat(table._id)} />
                                    }
                                    content='Delete Table'
                                    position='top right'
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))
                    ) : (
                        <Table.Row>
                            <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                                <p>No table-seat configuration found</p>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            {openCreateTableModal && (
                <CreateTableModal
                    openCreateTableModal={openCreateTableModal}
                    closeCreateTableModal={closeCreateTableModal}
                    restaurantId={props && props.restaurant && props.restaurant._id}
                    editId={editId}
                    facilityId={props && props.restaurant && props.restaurant.Facility || ""}
                    tableMaxCap={maxCapOfTables}
                    restaurantMaxCap={restaurantMaxCap}
                    setRefreshPage={props.setRefreshPage}
                    setRefreshTable={setRefreshTable}
                    maxCapOfSelectedTable={maxCapOfSelectedTable}
                />
            )}
            <ConfirmationModal open={deleteModal} onClose={cancelDelete} onConfirm={confirmDelete} messageToDisplay={"Are you sure you want to delete the selected table-seat configuration."} />
        </div>
    );
};

export default TableSeatView;