import React, { useEffect } from 'react';
import { Dimmer, Icon, Loader, Modal, Pagination, Popup, Table, TableHeaderCell } from 'semantic-ui-react';
import { User } from '../../types';
import { deleteUser, fetchDepartmentStaffs } from '../../services/Users';
import { sendToast } from '../../util';
import { fetchDepartments } from '../../services/Departments';
import ConfirmationModal from '../DeleteConfirmationModal';
import DiningKitchenStaffForm from './DiningKitchenStaffForm';

interface DiningStaffMembersProps {
    setKitchenAndDiningDeletionId: React.Dispatch<React.SetStateAction<string>>;
    kitchenAndDiningDeletionId: string;
}

const DiningStaffMembers: React.FC<DiningStaffMembersProps> = ({ setKitchenAndDiningDeletionId, kitchenAndDiningDeletionId }) => {
    const [diningStaffMembersLoading, setDiningStaffMembersLoading] = React.useState<boolean>(false);
    const [diningStaffMembersDetails, setDiningStaffMembersDetails] = React.useState<User[]>([]);
    const [displayDiningMembers, setDisplayDiningMembers] = React.useState<User[]>([]);
    const [diningStaffDeleteModal, setDiningStaffDeleteModal] = React.useState<boolean>(false);
    const [diningStaffDeleteId, setDiningStaffDeleteId] = React.useState<string | null>(null);
    const [selectedStaff, setSelectedStaff] = React.useState<User | null>(null);
    const [page_no, setPage_no] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const pageSize = 10;
    const [openCreateForm, setOpenCreateForm] = React.useState(false);
    const [reloadFlag, setReloadFlag] = React.useState(false);

    useEffect(() => {
        fetchDiningStaffMembers();
    }, [reloadFlag]);

    useEffect(() => {
        paginateUsers();
    }, [page_no, diningStaffMembersDetails]);

    const paginateUsers = () => {
        const start = (page_no - 1) * pageSize;
        const end = start + pageSize;
        setDisplayDiningMembers(diningStaffMembersDetails.slice(start, end));
    }

    useEffect(() => {
        // the deletion id is and the staff is present in the kitchen staff list, then remove it from the list
        if (diningStaffMembersDetails.length > 0 && diningStaffMembersDetails.find((staff) => staff._id === kitchenAndDiningDeletionId)) {
            removeDeletedStaff(kitchenAndDiningDeletionId);
        }
    }, [kitchenAndDiningDeletionId]);


    const removeDeletedStaff = (id) => {
        // if the person is only one whom we are deleting than move the page to previous page
        if (diningStaffMembersDetails.length === 1 && page_no > 1) {
            setPage_no(page_no - 1);
        }
        setDiningStaffMembersDetails((prev) => prev.filter((staff) => staff._id !== id));
    }

    const fetchDiningStaffMembers = async () => {
        try {
            setDiningStaffMembersLoading(true);
            const departmentsArr = await fetchDepartments();
            const diningDepartment = departmentsArr.find((department) => department.Name === "Dining");
            const kitchenDepartment = departmentsArr.find((department) => department.Name === "Kitchen");
            if (!diningDepartment) {
                throw new Error("Dining Department not found.");
            }
            const filters = {
                DepartmentIds: [String(diningDepartment._id)],
                ExcludeDepartmentIds: [kitchenDepartment && String(kitchenDepartment._id)]
            }
            const pageSort = { page_size: pageSize, page_no: page_no, sort: [{ DateAdded: 'asc' }] };
            const { users: allStaffMembers } = await fetchDepartmentStaffs(filters);
            setTotalPages(Math.ceil(Number(allStaffMembers.length) / pageSize));
            setDiningStaffMembersDetails(allStaffMembers);
            paginateUsers();
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error While fetching the Dining Staff Details.");
        } finally {
            setDiningStaffMembersLoading(false);
        }
    };

    const handleCreateFormModalOpen = () => {
        setSelectedStaff(null);
        setOpenCreateForm(true);
    };

    const handleDeleteDiningStaff = async (id: string) => {
        setDiningStaffDeleteId(id);
        setDiningStaffDeleteModal(true);
    }

    const closeDiningStaffDeleteModal = () => {
        setDiningStaffDeleteModal(false);
        setDiningStaffDeleteId(null);
    }

    const handleCloseForm = (reload = false) => {
        setSelectedStaff(null);
        setOpenCreateForm(false);
        if (reload) {
            setReloadFlag(!reloadFlag);
        }
    }

    const deleteDiningStaff = async () => {
        try {
            setDiningStaffMembersLoading(true);
            setDiningStaffDeleteModal(false);
            await deleteUser(String(diningStaffDeleteId));
            setKitchenAndDiningDeletionId(String(diningStaffDeleteId));
            sendToast("success", "Dining Staff deleted successfully.");
            // remove the deleted staff from the list
            removeDeletedStaff(String(diningStaffDeleteId));
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error While deleting the Dining Staff.");
        } finally {
            setDiningStaffMembersLoading(false);
        }
    }

    const handleOpenEditModal = (id: string) => {
        const selectedStaffMember = diningStaffMembersDetails.find((staff) => String(staff._id) === String(id));
        selectedStaffMember && setSelectedStaff(selectedStaffMember);
        setOpenCreateForm(true);
    }

    return (
        <>
            <Dimmer active={diningStaffMembersLoading} inverted>
                <Loader active={diningStaffMembersLoading}>  </Loader>
            </Dimmer>
            <div className="restaurant-header">
                <h3>Staff</h3>
                <span>
                    <Popup
                        trigger={<Icon name="plus" size="large" style={{ cursor: 'pointer' }} onClick={() => handleCreateFormModalOpen()} />}
                        content="Add Dining Staff"
                        position="top right"
                    />
                </span>
            </div>
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>User ID</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Cell</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {displayDiningMembers && displayDiningMembers.length > 0 ?
                        displayDiningMembers.map((diningStaff) => (
                            <Table.Row key={diningStaff._id} className='table-row'>
                                <Table.Cell><p className='clickable' onClick={() => handleOpenEditModal(diningStaff._id)}> {diningStaff.FirstName === "Station" ? "" : diningStaff.FirstName}  {diningStaff.LastName}</p></Table.Cell>
                                <Table.Cell>{diningStaff.Username}</Table.Cell>
                                <Table.Cell className='cell-width'> <p>{diningStaff.Email || "-"}
                                </p> </Table.Cell>
                                <Table.Cell className="table-cell">
                                    <p>{diningStaff.Phone || diningStaff.Cell || "-"}</p>
                                    <Popup
                                        trigger={
                                            <Icon className="trash-icon" name='trash' size="large" onClick={() => handleDeleteDiningStaff(diningStaff._id)} />
                                        }
                                        content='Delete Dining Staff'
                                        position='top right'
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))
                        :
                        <Table.Row>
                            <Table.Cell colSpan="3" style={{ textAlign: 'center' }}>
                                <p>No staff found for Dining Department.</p>
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
                {(totalPages > 1) && <Table.Footer >
                    <Table.Row>
                        <TableHeaderCell colSpan='4' >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Pagination
                                    boundaryRange={0}
                                    defaultActivePage={1}
                                    activePage={page_no}
                                    onPageChange={(e, { activePage }) => {
                                        setPage_no(Number(activePage));
                                    }}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={1}
                                    totalPages={totalPages}
                                />
                            </div>
                        </TableHeaderCell>
                    </Table.Row>
                </Table.Footer>}
            </Table>
            <ConfirmationModal
                open={diningStaffDeleteModal}
                onClose={closeDiningStaffDeleteModal}
                onConfirm={deleteDiningStaff}
                messageToDisplay="Are you sure you want to delete the Dining Staff?"
            />
            <Modal
                style={{ width: '400px' }}
                onClose={() => setOpenCreateForm(false)}
                open={openCreateForm}
            >
                <DiningKitchenStaffForm source={"Dining"} user={selectedStaff} closeForm={handleCloseForm} />
            </Modal>
        </>
    );
};

export default DiningStaffMembers;
