import React, { useEffect, useState } from 'react';
import { Confirm, Button, Icon, Image, Input } from 'semantic-ui-react';
import {
    fetchFacilityResidentCalendarEvents,
    fetchRegistrants,
    updateResidentCalendarEvent,
} from '../../services/Registrants';
import { fetchAllFacilityNotepadEvents, updateNotepadEvent } from "../../services/Notepad"
import moment from 'moment';
import { fetchAllActiveServicesTypes } from '../../services/service';
import CustomTable from '../CustomTable';
import { Service } from '../../types/Service';
import { AuthState, Registrant, RelatedService, ResidentCalendar, User } from '../../types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { syncNotepadItemsToServiceInstances } from '../../services/ServiceInstances';
import CustomDatePicker from '../CustomDatePicker';
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader';
import { fetchOneFacility } from '../../services/Facilities';
import ServiceFilterPrintExportIcons from '../FilterPrintExportIcons/serviceTypes';
import ResidentCalendarForm from '../ResidentCalendar/ResidentCalendarForm';
import { fetchAllStaffUsers } from '../../services/Users';
import { fetchResidentGroups } from '../../services/RoomGroups';
import { Notepad } from '../../types/Notepad';
import './style.less';
interface ModifiedResidentCalendarEvent {
    _id: string;
    EditIcon: React.ReactNode;
    Resident: string;
    Date: string;
    Time: string;
    Length: string;
    Staff: string;
    Location: string;
    Event: string;
    Service?: string[];
    SyncStatus?: string;
    StatusIcon?: React.ReactNode;
    Status?: string;
    Category?: string[];
    isKioskEvent?: boolean;
}

interface ExportType {
    Resident: string;
    Date: string;
    Time: string;
    Length: string;
    Staff: string;
    Location: string;
    Event: string;
    Service?: string;
    Status?: string;
}

const NotepadServices = () => {
    const [startDate, setStartDate] = useState<Date | undefined>(moment().startOf('day').toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().endOf('day').toDate());
    const [residentCalendarEvents, setResidentCalendarEvents] = useState<ResidentCalendar[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [tableData, setTableData] = useState<ModifiedResidentCalendarEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [servicesDropdownOptions, setServicesDropdownOptions] = useState<
        {
            key: string;
            text: string;
            value: string;
            tags?: string;
        }[]
    >([]);
    const [printBtnLoader, setPrintBtnLoader] = useState<boolean>(false);
    const [facilityTimeZone, setFacilityTimeZone] = useState<string>('America/New_York');
    const [filter, setFilter] = useState<string[]>([]);
    const [refreshCurrPage, setRefreshCurrPage] = useState<boolean>(false);
    const [residentCalendarData, setResidentCalendarData] = useState<Partial<ResidentCalendar>>({});
    const [residentOptions, setResidentOptions] = useState<{ key: string; text: string; value: string; }[]>([
        { key: 'all', text: 'All residents', value: 'all' },
    ]);
    const [staffOptions, setStaffOptions] = useState<{ key: string; text: string; value: string; }[]>([]);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [duration, setDuration] = useState<number>(0);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [dropdownLoading, setDropdownLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [disableEditAndDelete, setDisableEditAndDelete] = useState<boolean>(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const isServiceEnabled = profile && profile.FacilityConfigurations && profile.FacilityConfigurations.services ? true : false;
    const pageSource = "Notepad"

    useEffect(() => {
        if (startDate && endDate) {
            setRefreshCurrPage(true);
            fetchData();
        }
    }, [startDate, endDate]);

    useEffect(() => {
        (async () => {
            try {
                setPrintBtnLoader(true);
                setDropdownLoading(true);
                const { FacilityTimeZone = '', featureConfigs } = await fetchOneFacility((profile && profile.Facility) || '');
                const [staffDetails, residentsWithRooms, roomGroups] = await Promise.all([
                    fetchAllStaffUsers(),
                    fetchRegistrants(),
                    fetchResidentGroups(),
                ]);
                const formatedStaffOptions = formatAndSortStaffs(staffDetails);
                const formattedResidentOptions = formatAndSortResidents(residentsWithRooms);
                const sortedRoomGroups = formatAndSortRoomGroups(roomGroups);
                const residentOptions = [{ key: 'all', text: 'All residents', value: 'all' }]
                    .concat(sortedRoomGroups)
                    .concat(formattedResidentOptions);
                setResidentOptions(residentOptions);
                setStaffOptions(formatedStaffOptions);
                setFacilityTimeZone(FacilityTimeZone);
            } catch (error) {
                console.error('Error fetching dropdown data', error);
                toast.warn('Something went wrong in fetching dropdown data', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } finally {
                setDropdownLoading(false);
                setPrintBtnLoader(false);
            }
        })();
    }, []);

    useEffect(() => {
        filterEvents(residentCalendarEvents);
    }, [filter]);

    const tableHeaders = ['', 'Resident', 'Date', 'Time', 'Length', 'Staff', 'Location', 'Event'];// Empty header is for edit icons as header name is not required

    const formatAndSortStaffs = (staff: User[]) => {
        const formattedStaffs = staff.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.FirstName}`,
                value: `${obj._id}`,
            };
        });

        const sortedStaffs = formattedStaffs.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedStaffs;
    };
    const formatAndSortResidents = (residents: Registrant[]) => {
        const formattedResidents = residents.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.FirstName}`,
                value: `${obj._id}`,
            };
        });

        const sortedResidents = formattedResidents.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedResidents;
    };
    const formatAndSortRoomGroups = (groups: any) => {
        const formattedRoomGroups = groups
            .map((obj) => {
                if (obj.ResidentIds && obj.ResidentIds.length <= 1) return; // don't show groups which does not have atleast 2 ResidentIds (To take care of wierd issues with residentGroups)
                return {
                    key: `${obj._id}`,
                    text: `${obj.Name} group`,
                    value: `${[obj.ResidentIds]}`,
                };
            })
            .filter((obj) => obj);

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRoomGroups;
    };
    const filterEvents = (events: ResidentCalendar[]) => {
        setRefreshCurrPage(true);
        if (filter.length === 0) {
            const modifiedEvents = modifyResidentCalendarEvents(events);
            setTableData(modifiedEvents);
        } else {
            const filteredData = events.filter((item) => {
                return (
                    item.relatedServices &&
                    item.relatedServices.length > 0 &&
                    item.relatedServices.some((service) => {
                        return filter.includes(service.serviceCategory);
                    })
                );
            });
            const modifiedEvents = modifyResidentCalendarEvents(filteredData);
            setTableData(modifiedEvents);
        }
    };
    const sortData = (data: ResidentCalendar[]) => {
        const sortedData = data.sort((a, b) => {
            const aDate = moment(a.dateTimeString);
            const bDate = moment(b.dateTimeString);
            return bDate.diff(aDate);
        });
        return sortedData;
    };
    const showWarningToast = (message: string) => {
        toast.warn(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    };
    const fetchData = async () => {
        try {
            setLoading(true);
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const Filter = { Facility: profile && profile.Facility, active: true }
            const [residentCalendarEvents, services] = await Promise.all([
                fetchAllFacilityNotepadEvents(
                    formattedStartDate,
                    formattedEndDate,
                    true,
                    searchTerm && searchTerm,
                ),
                isServiceEnabled ? fetchAllActiveServicesTypes({ Filter }) : Promise.resolve([]),
            ]);
            const sortedResidentCalendarEvents = sortData(residentCalendarEvents);
            setResidentCalendarEvents(sortedResidentCalendarEvents);
            setServices(services);
            if (filter.length > 0) {
                filterEvents(residentCalendarEvents);
            } else {
                const modifiedEvents = modifyResidentCalendarEvents(residentCalendarEvents);
                setTableData(modifiedEvents);
            }
            const servicesDropdownOptions = services.map((service: Service) => {
                const serviceTags = service.defaults && service.defaults.tags ? service.defaults.tags : "";
                return { text: service.name, value: service._id, tags: serviceTags };
            });
            setServicesDropdownOptions(servicesDropdownOptions);
        } catch (error) {
            console.error('Error fetching resident calendar events', error);
            toast.warn(error instanceof Error ? error.message : 'Failed to fetch resident calendar events', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const modifyResidentCalendarEvents = (
        residentCalendarEvents: ResidentCalendar[],
        exportDataFormat: boolean = false, // setting default value to false for backward compatibility
    ): ModifiedResidentCalendarEvent[] => {
        const formatEventForResident = (
            event: ResidentCalendar,
            residentName: string = "N/A",
        ): ModifiedResidentCalendarEvent => {
            const startDate = moment(event.dateTimeString);
            const endDate = moment(event.endDateTimeString);
            const formattedDate = startDate.format('MM-DD-YYYY');
            const formattedTime = startDate.format('hh:mm A');
            const duration = moment.duration(endDate.diff(startDate)).humanize();
            const showGreenIcon = event.syncTimestamp && event.syncStatus === 'merged';
            const showYellowIcon =
                event.relatedServices && event.relatedServices.length > 0 &&
                event.syncStatus === 'matched';
            const statusIconLogic = showGreenIcon ? (
                <div className="singleIconsWrapper">
                    <Icon name="check" color="green" />
                </div>
            ) : showYellowIcon ? (
                <div className="singleIconsWrapper">
                    <Icon name="wait" color="yellow" />
                    {event.relatedServices && event.relatedServices.length > 0 && (
                        <Image
                            onClick={async () => {
                                console.log({ event })
                                await syncIconHeaderClickHandler(event);
                            }}
                            style={{ cursor: 'pointer' }}
                            src={`${process.env.PUBLIC_URL}/customTableIcons/sync.svg`}
                        />
                    )}
                </div>
            ) : (
                <div className="singleIconsWrapper">
                    <Icon name="x" color="red" />{' '}
                    {event.relatedServices && event.relatedServices.length > 0 && (
                        <Image
                            onClick={async () => {
                                console.log({ event })
                                await syncIconHeaderClickHandler(event);
                            }}
                            style={{ cursor: 'pointer' }}
                            src={`${process.env.PUBLIC_URL}/customTableIcons/sync.svg`}
                        />
                    )}
                </div>
            );
            const statusTextLogic = showGreenIcon ? 'merged' : showYellowIcon ? 'matched, not merged' : 'not matched';
            const statusObject = exportDataFormat ? { Status: statusTextLogic } : { StatusIcon: statusIconLogic };
            const editIcon = (
                <div className="singleIconsWrapper">
                    <Image
                        onClick={() => eventClickHandler(event)}
                        style={{ cursor: 'pointer', width: '15px' }}
                        src={`${process.env.PUBLIC_URL}/assets-groups-management/edit.png`}
                    />
                </div>
            );
            const serviceIds = event.relatedServices && event.relatedServices.length > 0 ? event.relatedServices.map((service) => { return service.serviceId; }) : [];
            const serviceCategories = event.relatedServices && event.relatedServices.length > 0 ? event.relatedServices.map((service) => { return service.serviceCategory; }) : [];

            return {
                _id: event._id,
                EditIcon: editIcon,
                Resident: residentName,
                Date: formattedDate,
                Time: formattedTime,
                Length: duration,
                Staff: event.staffName || 'N/A',
                Location: event.location,
                Event: event.text,
                Service: serviceIds,
                Category: serviceCategories,
                SyncStatus: event.syncStatus || '',
                isKioskEvent: event.isKioskEvent || false,
                ...statusObject,
            };
        };

        // Iterate through the residentCalendarEvents and flatten the array by registrantIds
        const modifiedEvents: ModifiedResidentCalendarEvent[] = [];
        residentCalendarEvents.forEach(event => {
            if (event.registrantIds && event.residentInfo && event.residentInfo.length > 0) {
                event.residentInfo.forEach(resident => {
                    modifiedEvents.push(formatEventForResident(event, resident.fullName));
                });
            } else {
                // Handle events with no associated resident
                modifiedEvents.push(formatEventForResident(event));
            }
        });
        return modifiedEvents;
    };

    const handleDropdownChange = async (value: string | number | string[], notepadId: string) => {
        console.log({ notepadId })
        await confirmDropdownChange(notepadId, value)
    };
    const confirmDropdownChange = async (selectedNotepadId, serviceIds) => {
        try {
            setLoading(true);
            if (serviceIds && serviceIds.length > 0) {
                let servicesObj: Service[] = [];
                serviceIds.map(async (serviceId) => {
                    const matchingService = services.find((service) => service._id === serviceId);
                    if (matchingService) {
                        servicesObj.push(matchingService);
                    }
                });
                console.log({selectedNotepadId})
                const notepadObj = residentCalendarEvents.find((notepad) => notepad._id === selectedNotepadId);
                console.log({notepadObj, servicesObj})
                if (servicesObj && notepadObj) {
                    const updatedNotepadItem = {
                        _id: notepadObj._id,
                        text: notepadObj.text,
                        dateTimeString: notepadObj.dateTimeString,
                        registrantIds: notepadObj.registrantIds,
                        staffId: notepadObj.staffId,
                        location: notepadObj.location,
                        endDateTimeString: notepadObj.endDateTimeString,
                        relatedServices: servicesObj && servicesObj.length > 0 ?
                            servicesObj.map((service) => {
                                return {
                                    serviceId: service._id,
                                    serviceName: service.name,
                                    serviceCategory: service.category,
                                } as RelatedService;
                            })
                            : [] as RelatedService[],
                        syncStatus: '',
                        syncTimestamp: null,
                    };
                    await updateNotepadItem(updatedNotepadItem);
                }
            } else {
                // if the serviceId is empty then we need to nullify the service from the notepad event
                const notepadObj = residentCalendarEvents.find((notepad) => notepad._id === selectedNotepadId);
                if (notepadObj) {
                    const updatedNotepadItem = {
                        _id: notepadObj._id,
                        text: notepadObj.text,
                        dateTimeString: notepadObj.dateTimeString,
                        registrantIds: notepadObj.registrantIds,
                        staffId: notepadObj.staffId,
                        location: notepadObj.location,
                        endDateTimeString: notepadObj.endDateTimeString,
                        relatedServices: [] as RelatedService[],
                        syncStatus: '',
                        syncTimestamp: null,
                    };
                    await updateNotepadItem(updatedNotepadItem);
                }
            }
            setRefreshCurrPage(false);
        } catch (error) {
            console.error('Error in dropdown change', error);
            toast.warn('Something went wrong in dropdown change', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            await fetchData();
            setLoading(false);
        }
    };

    const gamepadIconHeaderClick = async () => {
        try {
            setLoading(true);
            await Promise.all(
                residentCalendarEvents.map(async (notepadItem) => {
                    if (notepadItem.syncStatus !== 'merged' && !notepadItem.isKioskEvent) {
                        let matchingServices: Service[] = [];

                        const specialCharacters = /\s+|,+|;+|:+|-+|\band\b|\bor\b/i;


                        const splitedEvent = notepadItem.text.split(specialCharacters).filter(Boolean);

                        const numbersArray: number[] = [];
                        const stringArray: string[] = [];
                        splitedEvent.forEach((word: any) => {
                            if (isNaN(word)) {
                                stringArray.push(word);
                            } else {
                                numbersArray.push(word);
                            }
                        });

                        if (numbersArray.length > 0) {
                            const splittedUniqueNumbers = numbersArray
                                .map(number => number.toString()) // Convert each number to a string
                                .join('')                         // Join all numbers into a single string
                                .split('')                        // Split the string into an array of single characters
                                .map(digit => parseInt(digit))    // Convert each character back to a number
                                .filter(digit => !isNaN(digit));  // Filter out any NaN values that occur from non-numeric characters

                            const uniqueDigits = Array.from(new Set(splittedUniqueNumbers));
                            const numbersMatchedServices = handleNumberMatching(uniqueDigits);
                            matchingServices.push(...numbersMatchedServices);
                        }

                        if (stringArray.length > 0) {
                            const wordsMatchedServices = handleWordMatching(notepadItem.text);
                            matchingServices.push(...wordsMatchedServices);
                        }
                        if (matchingServices && matchingServices.length > 0) {
                            const updatedNotepadItem = {
                                _id: notepadItem._id,
                                text: notepadItem.text,
                                dateTimeString: notepadItem.dateTimeString,
                                registrantIds: notepadItem.registrantIds,
                                staffId: notepadItem.staffId,
                                location: notepadItem.location,
                                endDateTimeString: notepadItem.endDateTimeString,
                                relatedServices: matchingServices.map((service) => {
                                    return {
                                        serviceId: service && service._id,
                                        serviceName: service && service.name,
                                        serviceCategory: service && service.category,
                                    } as RelatedService;
                                }),
                                syncStatus: 'matched',
                                syncTimestamp: null,
                            };
                            await updateNotepadItem(updatedNotepadItem);
                        }
                    }
                }),
            );
            setRefreshCurrPage(false);
        } catch (error) {
            console.error('Error in matching events', error);
            toast.warn('Something went wrong in matching events', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            await fetchData();
            setLoading(false);
        }
    };

    const syncIconHeaderClickHandler = async (singleSyncItem: any) => {
        try {
            setLoading(true);
            let notepadItemsToSync: ResidentCalendar[] = [];
            if (singleSyncItem && singleSyncItem._id) {
                const isMatchedAndNotSynced =
                    (!singleSyncItem.syncStatus ||
                        singleSyncItem.syncStatus === 'matched' ||
                        singleSyncItem.syncStatus === '') &&
                    !singleSyncItem.syncTimestamp &&
                    singleSyncItem.relatedServices && singleSyncItem.relatedServices.length > 0;
                const isKioskEvent = singleSyncItem.isKioskEvent;
                if (isMatchedAndNotSynced && !isKioskEvent) notepadItemsToSync.push(singleSyncItem);
            } else {
                notepadItemsToSync = residentCalendarEvents.filter((notepadItem) => {
                    const isMatchedAndNotSynced =
                        (!notepadItem.syncStatus ||
                            notepadItem.syncStatus === 'matched' ||
                            notepadItem.syncStatus === '') &&
                        !notepadItem.syncTimestamp &&
                        notepadItem.relatedServices && notepadItem.relatedServices.length > 0;
                    const isKioskEvent = notepadItem.isKioskEvent;
                    return isMatchedAndNotSynced && !isKioskEvent;
                });
            }
            if (notepadItemsToSync.length) {
                const backendDataFormat = notepadItemsToSync.map((notepadItem) => {
                    const staffId = notepadItem.staffId ? notepadItem.staffId : '';
                    return {
                        notepadId: notepadItem._id,
                        startDate: notepadItem.dateTimeString,
                        endDate: notepadItem.endDateTimeString
                            ? notepadItem.endDateTimeString
                            : notepadItem.dateTimeString,
                        relatedServices: notepadItem.relatedServices as RelatedService[],
                        text: notepadItem.text,
                        staffId: staffId,
                    };
                });
                await syncNotepadItemsToServiceInstances(backendDataFormat);
                setRefreshCurrPage(false);
                await fetchData();
            } else {
                toast.success('No matching items to sync, please check', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            toast.warn('failed to sync items', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const tableHeaderClickHandler = async (header: string) => {
        if (header === 'Game Pad Icon') {
            gamepadIconHeaderClick();
        }

        if (header === 'Sync Icon') {
            await syncIconHeaderClickHandler(null)
        }
    };

    const updateNotepadItem = async (notepadItem: Partial<Notepad>) => {
        try {
            await updateNotepadEvent(notepadItem)
        } catch (error) {
            console.error('Error updating notepad event', error);
            toast.warn('Something went wrong in updating event', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const handleNumberMatching = (numbers: number[]) => {
        function generateCombinations(numbers) {
            let results: Number[] = [];
            function combine(currentCombo, remainingNumbers) {
                if (currentCombo) {
                    results.push(parseInt(currentCombo));
                }
                for (let i = 0; i < remainingNumbers.length; i++) {
                    combine(currentCombo + remainingNumbers[i], remainingNumbers.slice(i + 1));
                }
            }
            combine("", numbers);
            return results;
        }

        const combinations = generateCombinations(numbers);
        const specialCharacters = /\s+|,+|;+|:+|-+/;
        const stringNumbersArray = combinations.map(String);
        const filteredServices = services.filter(service => {
            const serviceWords = service.name.split(specialCharacters);
            const tags = service.defaults && service.defaults.tags ? service.defaults.tags : "";
            const tagWords = tags.split(specialCharacters);

            const serviceWordSet = new Set(serviceWords.map(word => word.toLowerCase()));
            const tagWordSet = new Set(tagWords.map(word => word.toLowerCase()));

            const matchedWords = stringNumbersArray.filter(word => {
                return serviceWordSet.has(word) || tagWordSet.has(word);
            });

            return matchedWords.length > 0;
        });
        return filteredServices;
    }

    const handleWordMatching = (event) => {
        const specialCharacters = /\s+|,+|;+|:+|-+|\band\b|\bor\b/i;
        const splitedEvent = event.split(specialCharacters);

        const filteredServices = services.filter(service => {
            const serviceWords = service.name.split(specialCharacters);
            const tags = service.defaults && service.defaults.tags ? service.defaults.tags : "";
            const tagWords = tags.split(specialCharacters);
            const serviceWordSet = new Set(serviceWords.map(word => word.toLowerCase()).filter(Boolean));
            const tagWordSet = new Set(tagWords.map(word => word.toLowerCase()).filter(Boolean));
            const matchedWords = splitedEvent.filter(word => {
                return serviceWordSet.has(word.toLowerCase()) || tagWordSet.has(word.toLowerCase());
            });
            return matchedWords.length > 0;
        });
        return filteredServices;
    }


    const formatDataForExport = () => {
        const modifiedEvents: ExportType[] = [];

        residentCalendarEvents.forEach((event) => {
            const startDate = moment(event.dateTimeString);
            const endDate = moment(event.endDateTimeString);
            const formattedDate = startDate.format('MM-DD-YYYY');
            const formattedTime = startDate.format('hh:mm A');
            const duration = moment.duration(endDate.diff(startDate)).humanize();

            const createEventObject = (residentName) => ({
                Resident: residentName,
                Date: formattedDate,
                Time: formattedTime,
                Length: duration,
                Staff: event.staffName || 'N/A',
                Location: event.location,
                Event: event.text,
                ...(isServiceEnabled ? {
                    Service: event.relatedServices && event.relatedServices.length > 0 ?
                        event.relatedServices.map(service => service.serviceName).join(", ")
                        : "",
                    Status: event.syncStatus || ''
                } : {})
            });

            if (event && event.residentInfo && event.residentInfo.length > 0) {
                event.residentInfo.forEach((resident) => {
                    modifiedEvents.push(createEventObject(resident.fullName));
                });
            } else {
                // Handle events with no associated resident
                modifiedEvents.push(createEventObject('N/A'));
            }
        });

        return modifiedEvents;
    };

    const printClickHandler = async () => {
        setPrintBtnLoader(true);
        const deepCopyData = JSON.parse(JSON.stringify(residentCalendarEvents)); // Create a deep copy of the data array
        if (deepCopyData.length === 0) {
            toast.warn('There is no data to print', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } else {
            const formattedData = deepCopyData.map((item: ResidentCalendar) => {
                return {
                    Subject: item.text || '',
                    StartDate: item.dateTimeString,
                    StartTime: item.dateTimeString,
                };
            });
            await formatAndExportDataForPrint(formattedData, 'notepad', facilityTimeZone, 'activity');
        }
        setPrintBtnLoader(false);
    };
    const filterChangeHandler = (filterItems: string[]) => {
        setFilter(filterItems);
    };
    const setResdientCalendarDataField = (key: keyof ResidentCalendar, value: any) => {
        if (key === 'registrantIds') {
            if (Array.isArray(value)) {
                const handleGroups = value.map((residentId) => {
                    if (residentId.indexOf(',') !== -1) {
                        return residentId.split(',');
                    }
                    return residentId;
                });
                const rooms = handleGroups.flat();
                setResidentCalendarData({
                    ...residentCalendarData,
                    [key]: rooms,
                });
            }
        } else if (key === 'dateTimeString') {
            const endDateTimeString = moment(value).add(duration, 'm').format('YYYY-MM-DDTHH:mm:ss');
            setResidentCalendarData({
                ...residentCalendarData,
                [key]: value,
                endDateTimeString,
            });
        } else if (key === 'staffId') {
            setResidentCalendarData({
                ...residentCalendarData,
                [key]: value,
            });
        } else {
            setResidentCalendarData({
                ...residentCalendarData,
                [key]: value,
            });
        }
    };
    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };
    const eventClickHandler = async (event: ResidentCalendar) => {
        if (!event) showWarningToast('There is an issue with this entry !');
        if (event.syncStatus === 'merged') setDisableEditAndDelete(true);
        else setDisableEditAndDelete(false);
        const data = Object.assign({}, event);
        const durationInMinutes = data.endDateTimeString
            ? Math.floor((Date.parse(data.endDateTimeString) - Date.parse(data.dateTimeString)) / 1000 / 60)
            : 0;
        setIsEdit(true);
        setResidentCalendarData(data);
        setDuration(durationInMinutes);
        setShowForm(true);
    };

    return (
        <>
            <ResidentCalendarForm
                setLoader={(value: boolean) => setLoading(value)}
                dropdownLoading={dropdownLoading}
                showModal={showForm}
                closeForm={() => setShowForm(false)}
                isEdit={isEdit}
                residentCalendarData={residentCalendarData}
                setResdientCalendarDataField={(...values) => setResdientCalendarDataField(...values)}
                displayError={(message) => (message.length > 0 ? showWarningToast(message) : '')}
                refresh={fetchData}
                resetResidentCalendarData={() => setResidentCalendarData({})}
                residentOptions={residentOptions.filter((option) => option.value !== 'all')}
                staffOptions={staffOptions}
                duration={duration}
                setDuration={(value: number) => setDuration(value)}
                disableEditAndDelete={disableEditAndDelete}
                pageSource={pageSource}
            />
            <div>
                <div style={{ marginTop: '30px' }}>
                    <CustomDatePicker
                        dateRange={{
                            end: endDate,
                            start: startDate,
                        }}
                        setDateRange={(dateRange) => {
                            setStartDate(dateRange.start);
                            setEndDate(dateRange.end);
                        }}
                        useNewStyles={true}
                    />
                </div>
                <div style={{ display: 'flex', gap: '5px', marginTop: '10px' }}>
                    <Image
                        onClick={() => {
                            setIsEdit(false);
                            setShowForm(!showForm);
                        }}
                        style={{ cursor: 'pointer' }}
                        src={`${process.env.PUBLIC_URL}/notepadFiltericons/plus.svg`}
                    />
                    <Input
                        placeholder="Search Event"
                        type="text"
                        value={searchTerm}
                        size="small"
                        action={
                            <Button
                                className="searchBtn"
                                icon="search"
                                onClick={() => {
                                    setRefreshCurrPage(true);
                                    fetchData();
                                }}
                                size="small"
                            />
                        }
                        onChange={(e) => handleSearchChange(e)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setRefreshCurrPage(true);
                                fetchData();
                            }
                        }}
                    />
                    <ServiceFilterPrintExportIcons
                        printButtonLoading={printBtnLoader}
                        onPrintClick={printClickHandler}
                        exportData={formatDataForExport()}
                        filterChangeHandler={filterChangeHandler}
                        exportFileName="speak2-notepad"
                        source='Notepad'
                    />
                </div>
                <div style={{ marginTop: '30px' }}>
                    <CustomTable
                        data={tableData}
                        headers={tableHeaders}
                        rowKeys={[
                            'EditIcon',
                            'Resident',
                            'Date',
                            'Time',
                            'Length',
                            'Staff',
                            'Location',
                            'Event',
                            'Service',
                            'StatusIcon',
                        ].filter((keys) => {
                            if (!isServiceEnabled) {
                                return !['Service', 'StatusIcon'].includes(keys);
                            } else {
                                return keys;
                            }
                        })}
                        setHeight='800px'
                        facilityTimezone="America/New_York"
                        formatString="YYYY-MM-DD HH:mm:ss"
                        dropdownKeys={['Service']}
                        dropdownOptions={{ Service: servicesDropdownOptions }}
                        onDropdownChange={handleDropdownChange}
                        headerClickHandler={(header) => tableHeaderClickHandler(header)}
                        iconHeaders={isServiceEnabled ? ['Game Pad Icon', 'Sync Icon'] : []}
                        iconsMap={{
                            'Game Pad Icon': '/customTableIcons/gamepad.svg',
                            'Sync Icon': '/customTableIcons/sync.svg',
                        }}
                        itemsPerPage={30}
                        loading={loading}
                        filter={filter}
                        refreshCurrPage={refreshCurrPage}
                        multiSelectDropdownKeys={["Service"]}
                    />
                </div>
            </div>
        </>
    );
};

export default NotepadServices;
