import React, { useState, useEffect } from 'react'

import { Form, Modal, ModalHeader, Dimmer, Loader, Input, ModalActions, Progress, Button } from 'semantic-ui-react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// import ContactInput from '../../components/ContactInput'
import { parsePhoneNumber } from 'react-phone-number-input'

import { toast } from 'react-toastify'

import { updateA4HContactName } from '../../../../services/Registrants'

const UpdateA4HContactName = ({ isModalOpen, residentId, contactId, type, reload, inputName, close }) => {
    const [error, setError] = useState('')

    const [makingCalls, setMakingCalls] = useState(false)
    const [makingCallsPercent, setMakingCallsPercent] = useState(0)

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log(residentId, contactId, type, inputName)
        setName(inputName)
    }, [residentId, contactId, type, inputName])

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    const handleSubmit = async () => {
        try {
            console.log('name', name)
            setMakingCalls(true)
            setMakingCallsPercent(0)

            await updateA4HContactName(residentId, contactId, name, type)
            sendToast('success', 'Contact Name Updated')
            await reload()
            setMakingCallsPercent(100)
            setMakingCalls(false)
            close()
        } catch (er) {
            console.log('error', er)
            sendToast('warn', 'Something went wrong')
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                if (!makingCalls) {
                    close()
                }
            }}
            size={'tiny'}
            className="invite-modal-root"
            closeIcon
        >
            <ModalHeader>
                <h5>Enter new Name to be reflected in Resident's AddressBook</h5>
            </ModalHeader>

            <Form error>
                <Modal.Content className="fnf-invite-modal-content">
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>

                    <div className="name-section">
                        <Input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            className="firstName"
                            required
                            name="FirstName"
                            placeholder="First Name"
                        ></Input>
                    </div>

                    {/* {makingCalls && (
                        <div>
                            <Progress
                                percent={makingCallsPercent}
                                size="tiny"
                                autoSuccess
                                color="blue"
                                className="inbound-progress"
                                style={{ width: '30%', marginBottom: '0px' }}
                            />
                            <div>We are saving this contact. This may take some time.</div>
                        </div>
                    )} */}
                </Modal.Content>
                <ModalActions className="inviteBtn">
                    <Button
                        className="ui primary small button"
                        loading={makingCalls}
                        onClick={async (e) => {
                            e.preventDefault()
                            await handleSubmit()
                        }}
                    >
                        Save
                    </Button>
                </ModalActions>
            </Form>
        </Modal>
    )
}

export default UpdateA4HContactName
