import React, { useEffect, useState } from 'react';
import { Checkbox, Dimmer, Grid, Header, List, Loader, Message, Popup } from 'semantic-ui-react';
import { addWebRTCFeaturedFacility, getWebRTCFeaturedFacilities, removeWebRTCFeaturedFacility } from '../../../services/WebRTCFeaturedFacilities';
import { fetchAllActiveFacilities } from '../../../services/Facilities';
import { toast } from 'react-toastify';
import { FeaturedFacility, Facility } from '../../../types';

const Devices = () => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [webRTCEnabledFacilities, setWebRTCEnabledFacilities] = useState<FeaturedFacility[]>([]);
    const [facilities, setFacilities] = useState<Facility[]>([]);

    useEffect(() => {
        fetchWebRTCFeaturedFacilities()
    }, []);

    const fetchWebRTCFeaturedFacilities = async () => {
        try {
            setIsFetching(true);
            const facilities = await fetchAllActiveFacilities();
            setFacilities(facilities);
            const facilityDict = {};
            facilities.forEach((facility) => {
                facilityDict[facility._id] = facility;
            });
            const webRTCEnabledFacilities = await getWebRTCFeaturedFacilities();
            const formattedWebRTCEnabledFacilities = webRTCEnabledFacilities.Result.map((featuredFacility: FeaturedFacility) => {
                const correspondingFacilityObj = facilityDict[featuredFacility.FacilityId as string];
                return {
                    ...correspondingFacilityObj,
                    InProgress: featuredFacility.InProgress,
                    Error: featuredFacility.Error,
                };
            });
            setWebRTCEnabledFacilities(formattedWebRTCEnabledFacilities);
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching WebRTC featured facilities:', error);
            toast.warn(
                error instanceof Error ? error.message : "Failed to fetch facility data",
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
            setIsFetching(false);
        }
    };

    const handleToggleChange = async (facilityId: string, checked: boolean) => {
        try {
            setIsFetching(true);
            if (checked) {
                await addWebRTCFeaturedFacility(facilityId);
            } else {
                await removeWebRTCFeaturedFacility(facilityId);
            }
            await fetchWebRTCFeaturedFacilities();
            setIsFetching(false);
        } catch (error) {
            console.error('Error handling toggle change:', error);
            toast.warn(
                error instanceof Error ? error.message : "Failed to update webRTC data",
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
            setIsFetching(false);
        }
    };
    const displayWebRTCEnabledFacilities = () => {
        if (webRTCEnabledFacilities.length > 0) {
            return (
                <div>
                    {webRTCEnabledFacilities.map((featuredFacility) => {
                        return (
                            <List divided relaxed>
                                <List.Item key={String(featuredFacility._id)} style={{ display: 'flex', alignItems: 'center' }}>
                                    <List.Icon name="building outline" size="large" verticalAlign="middle" />
                                    <List.Content floated="left">
                                        <List.Header>{featuredFacility.FriendlyName? `${featuredFacility.Name} (${featuredFacility.FriendlyName})` : featuredFacility.Name}</List.Header>
                                    </List.Content>
                                    <List.Content>
                                        {!featuredFacility.InProgress && !featuredFacility.Error && (
                                            <Checkbox
                                                toggle
                                                label=""
                                                checked={true} // since this is a list of enabled facilities, the toggle should always be checked
                                                onChange={(e, data) => {
                                                    handleToggleChange(String(featuredFacility._id), data.checked as boolean);
                                                }}
                                            />
                                        )}
                                        {featuredFacility.InProgress && !featuredFacility.Error && (
                                            <Popup
                                                content="WebRTC is being enabled/disabled for this facility, this will take few minutes to reflect, please refresh the page after few minutes to see the status"
                                                position="top right"
                                                on="hover"
                                                trigger={
                                                    <Loader
                                                        active
                                                        inline
                                                        size="tiny"
                                                    />
                                                }
                                            />
                                        )}
                                        {featuredFacility.Error && <Message negative>There was an issue, please contact support</Message>}
                                    </List.Content>
                                </List.Item>
                                <hr />
                            </List>
                        );
                    })}
                </div>
            );
        }
    };

    const displayRemainingFacilities = () => {
        const webRTCFacilityIds = webRTCEnabledFacilities.map(facility => String(facility._id));
        const getRemainingFacilities = facilities.filter(facility => !webRTCFacilityIds.includes(facility._id));
        if (getRemainingFacilities.length > 0) {
            return (
                <div>
                    {getRemainingFacilities.map((facility) => {
                        return (
                            <List divided relaxed>
                                <List.Item key={facility._id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <List.Icon name="building outline" size="large" verticalAlign="middle" />
                                    <List.Content>
                                        <List.Header>{facility.FriendlyName ? `${facility.Name} (${facility.FriendlyName})` : facility.Name}</List.Header>
                                    </List.Content>
                                    <Checkbox
                                        toggle
                                        label=""
                                        checked={false} // since they are disabled, the toggle should always be unchecked
                                        onChange={(e, data) => {
                                            handleToggleChange(facility._id, data.checked as boolean);
                                        }}
                                    />
                                </List.Item>
                                <hr />
                            </List>
                        );
                    })}
                </div>
            );
        }
    };

    return (
        <div>
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching}></Loader>
            </Dimmer>
            <Grid>
                <Grid.Column width={8} style={{padding: '20px'}}>
                    <Header>Disabled facilities</Header>
                    {
                        displayRemainingFacilities()
                    }
                </Grid.Column>
                <Grid.Column width={8} style={{ padding: '20px' }}>
                    <Header>Enabled facilities</Header>
                    {
                        displayWebRTCEnabledFacilities()
                    }
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default Devices;