import React, { Dispatch, FC } from 'react'
import { Form, Input } from 'semantic-ui-react'
import {  FetchDataAction, HandleChange } from './useDietary'
import { DietaryState } from '../../types/Dietary'

interface Props {
    dietaryStateItems: DietaryState
    disptach: Dispatch<FetchDataAction | HandleChange>
    readonly?: boolean
}

const GoalsInputBoxes: FC<Props> = ({ dietaryStateItems, disptach, readonly = false }) => {
    const goals = dietaryStateItems.goals
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        disptach({
            type: "HANDLE_CHANGE",
            category: "goals",
            value: e.target.value,
            key: key
        })
    }
    function formatString(str: string) {
        // /(?=[A-Z])/) regex for uppercase lettter
        // /^\w/ regex for first letter
        return str.split(/(?=[A-Z])/).join(" ").replace(/^\w/, (c) => c.toUpperCase());
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {Object.entries(goals).map(([key, value]) => (
                <Form.Field key={key}>
                    <label>{formatString(key)}</label>
                    <Input
                        disabled={readonly}
                        value={value}
                        placeholder={`enter ${key}`}
                        onChange={(e) => handleChange(e, key)}
                    />
                </Form.Field>
            ))}
        </div>
    )
}

export default GoalsInputBoxes