import React from 'react';
import moment from 'moment';
import { List } from 'semantic-ui-react';
import './style.less';
import { ResidentCalendar } from '../../types';

interface CalendarProps {
    events: ResidentCalendar[];
    currentDay: Date;
    setShowResidentCalendarModal: (show: boolean, isEdit: boolean, dateRange?: { start: string, end: string; }, event?: ResidentCalendar) => void;
}

const ListCalendar: React.FC<CalendarProps> = ({ events, currentDay, setShowResidentCalendarModal }) => {

    // Create an array of 24 hours in AM/PM format.
    const formattedCurrentDay = moment(currentDay).format('MMM DD, YYYY');
    const hours = Array.from({ length: 24 }, (_, i) =>
        moment({ hour: i }).format('hh A')
    );

    const handleHourClick = (event: ResidentCalendar | null, hour: string) => {
        if (!event) {
            // If there are no events for this hour, open the modal for creating a new event.
            const startTime = moment(hour, 'hh A').format('HH:mm:ss');
            const endTime = moment(hour, 'hh A').add(1, 'hour').format('HH:mm:ss');
            const date = moment(currentDay).format('YYYY-MM-DD');
            const dateTimeString = `${date}T${startTime}`;
            const endDateTimeString = `${date}T${endTime}`;
            setShowResidentCalendarModal(true, false, { start: dateTimeString, end: endDateTimeString });
        } else if (event) {
            setShowResidentCalendarModal(true, true, { start: event.dateTimeString, end: event.endDateTimeString }, event);
        }
    };

    return (
        <div className="list-calendar">
            <h3 className="current-day">{formattedCurrentDay}</h3>
            <List>
                {hours.map((hour) => {
                    // Filter events for this hour.
                    const eventsThisHour = events.filter(
                        (e) => moment(e.dateTimeString, 'YYYY-MM-DDTHH:mm:ss').format('hh A') === hour
                    );
                    return (
                        <>
                            <List.Item key={hour} className="hour-item" onClick={() => handleHourClick(null, hour)}>
                                <List.Content >
                                    <span className="hour">{hour}&nbsp; - &nbsp;</span>
                                    {eventsThisHour.map((e, i) => (
                                        <span
                                            key={i}
                                            className="event event-hover"  // Include the event-hover class
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleHourClick(e, hour);
                                            }}
                                        >
                                            {e.text}
                                            {i < eventsThisHour.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </List.Content>
                            </List.Item>
                        </>
                    );
                })}
            </List>
        </div>
    );
};

export default ListCalendar;
