import API from './API';

export async function fetchFacilityEnabledForExternalIntegration({ facilityId, integrationType }: { facilityId: string, integrationType: string; }) {
    try {       
        const response = await API.lambdaPost('/externalIntegrationFacilities/get', { facilityId, integrationType });
        return response;
    } catch (error) {
        console.log("Error in fetchFacilityEnabledForExternalIntegration", error);
        throw error;
    }
}

export async function enableIntrexIntegration({speak2FacilityId, intrexCommunityId}: {speak2FacilityId: string, intrexCommunityId: string}) {
    try {
        const response = await API.lambdaPost('/facilities/intrex/enable', {speak2FacilityId, intrexCommunityId});
        return response;
    } catch (error) {
        console.log("Error in enableIntrexIntegration", error);
        throw error;
    }
}

export async function disableIntrexIntegration({speak2FacilityId}: {speak2FacilityId: string}) {
    try {
        const response = await API.lambdaPost('/facilities/intrex/disable', {speak2FacilityId});
        return response;
    } catch (error) {
        console.log("Error in disableIntrexIntegration", error);
        throw error;
    }
}

export async function updateCommunityId({ _id, intrexCommunityId }: { _id: string, intrexCommunityId: string; }) {
    try {
        const response = await API.lambdaPost('/facilities/intrex/update', { _id, intrexCommunityId });
        return response;
    } catch (error) {
        console.log("Error in updateCommunityId", error);
        throw error;
    }
}