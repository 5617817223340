export type RemoteVideoResponse = any

export const SET_REMOTE_VIDEO = 'remoteVideo/SET_REMOTE_VIDEO';
export type SET_REMOTE_VIDEO = typeof SET_REMOTE_VIDEO;

export interface SetRemoteVideo {
    type: SET_REMOTE_VIDEO;
    remoteVideo: RemoteVideoResponse;
}

export type RemoteVideoAction = SetRemoteVideo


export function setRemoteVideo(remoteVideo: RemoteVideoResponse): SetRemoteVideo {
    console.log("setRemoteVideo", { remoteVideo})
    return {
        type: SET_REMOTE_VIDEO,
        remoteVideo,
    }
}
