
export const sortByKey = (array, key = "text") => {   //key is given the default value of "text" as this function is mostly used in Dropdowns
  // Sort the rest of the array based on the specified key
  array.sort((a, b) => {
    const textA = a[key].toUpperCase();
    const textB = b[key].toUpperCase();
    return textA.localeCompare(textB);
  });

  // Check if the array contains an object with "value" equal to "all"
  const allIndex = array.findIndex(item => item.value == "all");

  // If "all" is found, move it to the beginning of the array
  if (allIndex !== -1) {
    const allItem = array.splice(allIndex, 1)[0];
    array.unshift(allItem);
  }
  return array;

};