import API from "./API"

export async function uploadCSVFileToS3(csvFile: File) {
    console.log({ csvFile, type: csvFile.type })
    try {
        const { preSignedUrl, facilityId } = await API.lambdaPost('/printcalendar/getPreSignedUrl', {})
        await API.putFile(preSignedUrl, csvFile)
        const signedUrl = await API.lambdaGet("/printcalendar/getSignedUrl", { Facility: facilityId })
        return signedUrl
    } catch (error) {
        console.error(error)
        throw new Error(`${error}`)
    }
}