import React, { FC, useEffect, useState } from 'react';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import { getSmartGadget } from "../../services/SmartGadgets";
import { AuthState, Gadget } from '../../types';
import ResidentAlexaForm from './ResidentAlexaForm';
import CustomTable from '../CustomTable';
import { useSelector } from 'react-redux';
import { sendToast } from '../../util';
interface Props {
    a4hRoomId: string;
}
const ResidentAlexa: FC<Props> = ({ a4hRoomId }) => {
    const [alexaDevices, setAlexaDevices] = useState<Gadget[] | undefined>(undefined);
    const [nonAlexaDevices, setNonAlexaDevices] = useState<Gadget[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const gadgets = await getSmartGadget(a4hRoomId);
                if (gadgets) {
                    const alexaDevices = gadgets.filter(gadget => gadget.isAlexaDevice);
                    const nonAlexaDevices = gadgets.filter(gadget => !gadget.isAlexaDevice);
                    setAlexaDevices(alexaDevices || undefined);
                    setNonAlexaDevices(nonAlexaDevices || undefined);
                } else {
                    setAlexaDevices(undefined);
                    setNonAlexaDevices(undefined);
                }
            } catch (error) {
                sendToast("warn", "Error fetching smart gadgets")
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div>
            <Dimmer inverted active={isLoading}>
                <Loader active={isLoading} />
            </Dimmer>
            {alexaDevices && alexaDevices.length ?
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {
                        alexaDevices.map((el, i) => {
                            return <ResidentAlexaForm device={el} key={i} />;
                        })
                    }
                </div>
                : !isLoading ?
                    <Message negative>
                        <Message.Header>
                            No Alexa device is associated with registrant
                        </Message.Header>
                    </Message>
                    :
                    <></>
            }
            {nonAlexaDevices && nonAlexaDevices.length ?
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: '40px' }}>
                    <h3>Other smart devices</h3>
                    <CustomTable
                        data={nonAlexaDevices}
                        headers={[
                            'Friendly Name',
                            'Manufacturer',
                            'Model',
                            'Mac Address',
                            'Status'
                        ]}
                        rowKeys={[
                            'friendlyName',
                            'manufacturer',
                            'model',
                            'macAddress',
                            'status'
                        ]}
                        facilityTimezone={profile && profile.FacilityTimeZone || ""}
                        formatString='MM/DD/YYYY HH:mm:ss'
                    />
                </div> : <></>
            }
        </div>
    );
};

export default ResidentAlexa;