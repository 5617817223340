import React, { useEffect, useState } from 'react';
import ServicesTypesForm from '../../../../components/ServiceTypesForm';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { cookbook } from '../../../../util';
import { fetchOneUniversalService } from '../../../../services/service';
import { Service } from '../../../../types/Service';
interface ServicesProps extends RouteComponentProps {
    source?: string;
}

const Services: React.FC<ServicesProps> = ({ history, source = "", location }) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [syncData, setSyncData] = useState<Service | null>(null);
    const [templateId, setTemplateId] = useState<string | null>(null);
    useEffect(() => {
        const templateId = (location.state && location.state.templateId);
        if (templateId) {
            fetchUniversalServiceInfo(templateId);
        }
    }, [location.state]);

    const fetchUniversalServiceInfo = async (id: string) => {
        try {
            setIsFetching(true);
            const { _id, ...responseData } = await fetchOneUniversalService(id);
            setSyncData({...responseData, active : true});
            setTemplateId(id);
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            console.error('error in fetching serviceTypes details', error);
        }
    };
    return (
        <>
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching} size="small">
                    Loading
                </Loader>
            </Dimmer>
            <Button
                size="tiny"
                basic
                onClick={() => history.push(source === cookbook ? '/admin/cookbook' : '/admin/services/list')}>
                {source === cookbook ? 'Back To Cook Book' : 'Back to Services'}
            </Button>
            {(syncData && templateId) ? <ServicesTypesForm source={source} service={syncData} templateId={templateId} /> : <ServicesTypesForm source={source} />}
        </>
    );
};

export default withRouter(Services);
