import React, { useState, useEffect } from 'react'

import { Form, Modal, ModalHeader, Dimmer, Loader, Input, ModalActions, Progress, Button } from 'semantic-ui-react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// import ContactInput from '../../components/ContactInput'
import { parsePhoneNumber } from 'react-phone-number-input'

import { toast } from 'react-toastify'

import { updateA4HContactNumber, enableInboundCalling } from '../../../../services/Registrants'

const UpdateA4HContactNumber = ({ isModalOpen, residentId, contactId, type, reload, inputNumber, close }) => {
    const [error, setError] = useState('')

    const [makingCalls, setMakingCalls] = useState(false)
    const [makingCallsPercent, setMakingCallsPercent] = useState(0)

    const [number, setNumber] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log(residentId, contactId, type, inputNumber)
        setNumber(inputNumber)
    }, [residentId, contactId, type, inputNumber])

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }
    const wait = async (seconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, seconds)
        })
    }

    const enableInbound = async (residentId, contactId) => {
        // call enable inbound calling, until we get 200 / 201
        // wait for exponential
        // do this for 60
        console.log('Calling enable', residentId, contactId)

        let result = null
        for (let i = 0; i < 8; i++) {
            const waitSeconds = 1000 * 3 ** i

            try {
                // @ts-ignore
                result = await enableInboundCalling(residentId, contactId)
                console.log('result:', result)

                // @ts-ignore
                if (!(result && result.success)) {
                    console.log('error:', result)
                    await wait(waitSeconds)
                    setMakingCallsPercent(((1 + i) / 8) * 100)
                    continue
                }
                setMakingCallsPercent(100)
                return true
            } catch (err) {
                console.log('error:', err)
                await wait(waitSeconds)
                setMakingCallsPercent(((1 + i) / 8) * 100)
            }
        }

        // setMakingCallsPercent(100);

        return null
    }

    const handleSubmit = async () => {
        console.log('number', number)
        setMakingCalls(true)
        setMakingCallsPercent(0)

        try {
            const { contactId: a4hContactId } = await updateA4HContactNumber(residentId, contactId, number, type)

            await wait(30 * 1000)
            const result = await enableInbound(residentId, a4hContactId)

            if (result) {
                // Good toast

                sendToast('success', 'Contact Name Updated')
                await reload()
                setMakingCallsPercent(100)
                setMakingCalls(false)
                close()

                // navigate(`/message-recipients/${residentId}/address-book/contacts/${contactId}`)
            } else {
                setMakingCalls(false)

                sendToast('warn', 'Contact Saved, but failed to enable the inbound calling')
                // navigate(`/message-recipients/${residentId}/address-book/contacts/${contactId}`)
            }
            setMakingCalls(false)
        } catch (er) {
            console.log('error', er)
            sendToast('warn', 'Something went wrong')
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                if (!makingCalls) {
                    close()
                }
            }}
            size={'tiny'}
            className="invite-modal-root"
            closeIcon
        >
            <ModalHeader>
                <h5>Enter new Name to be reflected in Resident's AddressBook</h5>
            </ModalHeader>

            <Form error>
                <Modal.Content className="fnf-invite-modal-content">
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>

                    <div className="name-section">
                        <PhoneInput
                            defaultCountry="US"
                            className="phone"
                            value={number}
                            onChange={(e) => {
                                console.log(e);
                                if(e) setNumber(e);
                            }}
                        />
                    </div>

                    {makingCalls && (
                        <div>
                            <Progress
                                percent={makingCallsPercent}
                                size="tiny"
                                autoSuccess
                                color="blue"
                                className="inbound-progress"
                                style={{ width: '30%', marginBottom: '0px' }}
                            />
                            <div className="inbound-progress-text">
                                We are saving this contact and enabling outbound and inbound calls(audio and video).
                                Please be patient while we work some security magic behind the scenes, this could take
                                up to two minutes.
                            </div>
                        </div>
                    )}
                </Modal.Content>
                <ModalActions className="inviteBtn">
                    <Button
                        className="ui primary small button"
                        loading={makingCalls}
                        onClick={async (e) => {
                            e.preventDefault()
                            await handleSubmit()
                        }}
                    >
                        Save
                    </Button>
                </ModalActions>
            </Form>
        </Modal>
    )
}

export default UpdateA4HContactNumber
