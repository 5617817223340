import React from 'react';
import { useDrop } from 'react-dnd';
import { Grid, List } from 'semantic-ui-react';
import { DraggedItem } from '../../types/ServicesTypes';
import { ServicesType } from '../../types/ServicesTypes';
import { sendToast } from '../../util';
import { GetSlotTime } from '../../util/menu';
interface DropZoneProps {
    perDayData: { [key: string]: ServicesType[]; };
    daySlot: string;
    handleMenuSelect: (id: string) => void;
    handleSlotSelect: (mealCategory: string) => void;
    calendarDate: Date;
    handleMenuDrop: (droppedService: ServicesType, info: any, daySlot: string) => void;
}
interface HandleSlotDropProps {
    item: DraggedItem;
    daySlot: string;
    handleMenuDrop: (droppedService: ServicesType, info: any, daySlot: string) => void;
    calendarDate: Date;
}

const DropZone: React.FC<DropZoneProps> = ({ perDayData, daySlot, handleMenuSelect, handleSlotSelect, calendarDate, handleMenuDrop }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'DRAGGABLE_ITEM',
        drop: (item: DraggedItem) => {
            handleSlotDrop({ item, daySlot, handleMenuDrop, calendarDate });
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    const handleOpenEditForm = (e: React.MouseEvent, id: string) => {
        e.stopPropagation();
        handleMenuSelect(id);
    };
    const handleOpenCreateModal = () => {
        handleSlotSelect(daySlot);
    };
    if (daySlot === "Any") {  //there is not slot space for Any in Menu view But it is in the drop down for meal types when creating a cook book 
        return null;
    }

    const handleSlotDrop = async ({ item, daySlot, handleMenuDrop, calendarDate }: HandleSlotDropProps) => {
        try {
            const slotTime = GetSlotTime(daySlot.toLowerCase());
            const StartDate = new Date(calendarDate).setHours(slotTime.startHour, 0, 0, 0);
            const endDate = new Date(calendarDate).setHours(slotTime.endHour, 0, 0, 0);
            const info = {
                updatedStartDate : StartDate,
                updatedEndDate : endDate,
            };
            handleMenuDrop(item.serviceObj, info, daySlot);
        } catch (error) {
            sendToast('error', `Failed to add meal to the slot ${daySlot}`);
        }
    };

    return (
        <div ref={drop}>
            <Grid
                style={{
                    border: '0.5px solid #B6B6B6',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0px',
                    backgroundColor: isOver ? '#f0f0f0' : 'transparent',
                }}
                onClick={handleOpenCreateModal}
            >
                <Grid.Column width={4} style={{ padding: '20px' }}>
                    <p>{daySlot}</p>
                </Grid.Column>
                <Grid.Column width={12}>
                    {perDayData[daySlot.toLowerCase()] && perDayData[daySlot.toLowerCase()].length && (
                        <List bulleted>
                            {perDayData[daySlot.toLowerCase()].map((item, idx) => (
                                <List.Item key={idx}>
                                    <u onClick={(e) => handleOpenEditForm(e, item._id)} style={{ cursor: "pointer" }}>{item.name}</u>
                                </List.Item>
                            ))}
                        </List>
                    )}
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default DropZone;