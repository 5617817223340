import { useEffect, useReducer, useState } from "react";
import { listDefaultResidentInterests, listResidentInterests } from '../../services/Registrants';
import { Service } from "../../types/Service";
import { getInitialServiceTypesState } from "../../services/service";

export type CategoryType = "Intellectual" | "Occupational" | "Physical" | "Social" | "Emotional" | "Spiritual" | "Environmental" | "Virtual" | "Dairy" | "Protein" | "Vegetables" |
    "Fruits" | "PlantProteins" | "GrainsAndStarches" | "Other";
export type SubCategoryType = "arts" | "tech" | "science" | "USAndWorld" | "challenge" | "media" | "communityEngagement" | "intergenerational" | "artAndCraft" | "dance" | "fitness" | "games" | "selfCare" | "community" | "clubs" | "outings" | "games" | "physicalGames" | "music" | "family" | "nurturing" | "reminiscence" | "therapies" | "spiritual" | "environmental" | "virtual" | "dairy" | "training"

export interface ToggleCheckboxAction {
    type: 'TOGGLE_CHECKBOX',
    category: string,
    subCategory: string,
    checkboxName: string
}

export interface FetchDataAction {
    type: 'SET_DATA',
    data
}

export interface ResetState {
    type: "RESET_STATE" | "RESET_SERVICE_STATE"
}

export const checkboxReducer = (state, action: ToggleCheckboxAction | FetchDataAction | ResetState) => {
    switch (action.type) {
        case 'TOGGLE_CHECKBOX':
            if (action.subCategory) {
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        [action.subCategory]: {
                            ...state[action.category][action.subCategory],
                            [action.checkboxName]: !state[action.category][action.subCategory][action.checkboxName]
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        [action.checkboxName]: !state[action.category][action.checkboxName]
                    }
                };
            }
        case "SET_DATA":
            return {
                ...action.data
            }
        case "RESET_STATE":
            return state
        case "RESET_SERVICE_STATE":
            return state
        default:
            return state
    }
}


const useCheckbox = (residentId: string, forServiceType?: boolean, service?: Partial<Service>): [any, React.Dispatch<FetchDataAction | ToggleCheckboxAction | ResetState>, boolean] => {
    const [initialState, setInitialState] = useState({});
    const [initialServiceTypesState, setInitialServiceTypesState] = useState({});
    
    useEffect(() => {
        (async () => {
            const initialResidentInterests = await listDefaultResidentInterests();
            const initialServiceTypes = await getInitialServiceTypesState();
            setInitialState(initialResidentInterests);
            setInitialServiceTypesState(initialServiceTypes);
        })();
    }, []); 
    
    const [checkboxItems, dispatch] = useReducer(checkboxReducer, forServiceType ? initialServiceTypesState : initialState);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!forServiceType) {
            (async () => {
                setLoading(true);
                const res = await listResidentInterests(residentId);
                setLoading(false);
                    dispatch({
                        type: 'SET_DATA',
                        data: res,
                    });
            })();
        }
    }, []);

    useEffect(() => {
        if (service) {
            dispatch({
                type: 'SET_DATA',
                data: {
                    ...service.Wellness,
                    ...service.Dietary,
                },
            });
        } else if(forServiceType) {
            dispatch({
                type: 'SET_DATA',
                data: initialServiceTypesState,
            });
        }
    }, [service, initialState, initialServiceTypesState]);

    return [checkboxItems, dispatch, loading];
}

export default useCheckbox;