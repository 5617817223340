import {
    AuthAction,
    LOG_IN,
    LOG_OUT,
    SET_FETCHING,
    SET_USER,
    SET_ERROR,
    SET_PROFILE,
    SET_CACHE_LOGIN_ERROR,
    SET_PASSWORD_CHANGE_REQUIRED,
    SET_PASSWORD_CHANGE_ERROR,
    SET_LOADING,
    SET_FEDERATED_USER_DETAILS,
} from '../actions/auth'
import { AuthState } from '../types'

const initialState: AuthState = {
    isAuthenticated: false,
    passwordChangeRequired: false,
    accessToken: null,
    refreshToken: null,
    currentUser: null,
    profile: null,
    fetching: false,
    hasError: false,
    passwordChangeError: false,
    errorCode: null,
    cacheLoginFailed: false,
    isLoading: false,
    federatedAuthUser: false,
    federatedUserExistsInDB: false,
    federatedUserEmail: null,
    federatedUserExternalId: null,
    federatedUsername: null,
    federatedUserOfficeLocation: null,
}

export function authReducer(state = initialState, action: AuthAction): AuthState {
    switch (action.type) {
        case LOG_IN:
            return state
        case LOG_OUT:
            return {
                ...state,
                isAuthenticated: false,
                currentUser: null,
                profile: null,
                passwordChangeRequired: false,
            }
        case SET_PASSWORD_CHANGE_REQUIRED:
                return {
                    ...state,
                    passwordChangeRequired: action.required,
                }
        case SET_FETCHING:
            return {
                ...state,
                fetching: action.isFetching,
            }
        case SET_USER:
            return {
                ...state,
                // accessToken: action.user.userSession.accessToken.jwtToken,
                // refreshToken: action.user.userSession.refreshToken.token,
                currentUser: action.user,
            }
        case SET_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                hasError: action.hasError,
                errorCode: action.errorCode,
            }
        case SET_PASSWORD_CHANGE_ERROR:
                return {
                    ...state,
                    isAuthenticated: false,
                    passwordChangeError: action.hasError,
                    errorCode: action.errorCode,
                }
        case SET_PROFILE:
            return {
                ...state,
                isAuthenticated: true,
                profile: action.profile,
            }
        case SET_CACHE_LOGIN_ERROR:
            return {
                ...state,
                cacheLoginFailed: true,
            }
        case SET_LOADING:
            return {
                ...state, 
                isLoading: action.isLoading
            }
        case SET_FEDERATED_USER_DETAILS:
                return {
                    ...state, 
                    federatedAuthUser: action.federatedAuthUser,
                    federatedUserExistsInDB: action.federatedUserExistsInDB,
                    federatedUserEmail: action.federatedUserEmail,
                    federatedUserExternalId: action.federatedUserExternalId,
                    federatedUsername: action.federatedUsername, 
                    federatedUserOfficeLocation: action.federatedUserOfficeLocation,
                }
        default:
            return state
    }
}
