import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Segment, Button } from 'semantic-ui-react';
import { externalIntegrationList } from '../../types';
import { sendToast } from '../../util';
import { disableIntrexIntegration, enableIntrexIntegration, fetchFacilityEnabledForExternalIntegration, updateCommunityId } from '../../services/ExternalIntegrationFacilities';

interface Props {
    facilityId?: string;
}

function ExternalIntegrationConfiguration(props: Props) {
    const [isFacilityEnabledForExternalInteration, setIsFacilityEnabledForExternalInteration] = useState<externalIntegrationList>({
        intrex: {
            Speak2FacilityId: '',
            IntrexCommunityId: '',
        }
    });
    const [communityId, setCommunityId] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        fetchExternalIntegrationAlreadyEnabled();
    }, []);

    const fetchExternalIntegrationAlreadyEnabled = async () => {
        try {
            if (props.facilityId) {
                const isFacilityEnabledForIntrex = await fetchFacilityEnabledForExternalIntegration({ facilityId: props.facilityId, integrationType: "Intrex" });
                if (isFacilityEnabledForIntrex && Object.keys(isFacilityEnabledForIntrex).length > 0) {
                    setIsFacilityEnabledForExternalInteration((prevState) => ({
                        ...prevState,
                        intrex: {
                            ...prevState.intrex,
                            Speak2FacilityId: props.facilityId || '', 
                            IntrexCommunityId: isFacilityEnabledForIntrex.IntrexCommunityId || '', 
                        },
                    }));
                    setCommunityId(isFacilityEnabledForIntrex.IntrexCommunityId || '');
                }
            }
        } catch (error) {
            console.log('Error fetching external integration:', error);
            sendToast('error', 'Failed to fetch external integration. Please try again.');
        }
    };
    

    const handleSave = async () => {
        try {
            if (props.facilityId) {
                setIsSaving(true);
                if (isFacilityEnabledForExternalInteration.intrex.Speak2FacilityId) {         
                    const interxAlreadyEnabled = await fetchFacilityEnabledForExternalIntegration({ facilityId: props.facilityId, integrationType: "Intrex" });
                    if (interxAlreadyEnabled && Object.keys(interxAlreadyEnabled).length > 0 && interxAlreadyEnabled.IntrexCommunityId !== communityId) {
                        await updateCommunityId({ _id: String(interxAlreadyEnabled._id), intrexCommunityId: communityId });
                        sendToast('success', 'Intrex community ID updated successfully.');
                        return;
                    }        
                    await enableIntrexIntegration({ speak2FacilityId: props.facilityId, intrexCommunityId: communityId });
                    sendToast('success', 'Intrex integration enabled successfully.');
                } else {
                    // Disable Intrex
                    await disableIntrexIntegration({ speak2FacilityId: props.facilityId });
                    sendToast('success', 'Intrex integration disabled successfully.');
                }
            } else {
                sendToast('error', 'facilityId not found, Please refresh the page and try again.');
            }
        } catch (error) {
            console.error(`Error ${isFacilityEnabledForExternalInteration.intrex.Speak2FacilityId ? 'enabling' : 'disabling'} Intrex:`, error);
            sendToast('error', `Failed to ${isFacilityEnabledForExternalInteration.intrex.Speak2FacilityId ? 'enable' : 'disable'} Intrex integration. ${error instanceof Error ? error.message : ''}`);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCheckboxChange = (checked: boolean) => {
        console.log("checked", checked);
        if (checked) {
            setIsFacilityEnabledForExternalInteration((prevState) => ({
                ...prevState,
                intrex: {
                    ...prevState.intrex,
                    Speak2FacilityId: props.facilityId || '', // Set Speak2FacilityId when enabled
                    IntrexCommunityId: communityId, // Ensure the community ID is maintained
                },
            }));
        } else {
            // Clear the values when unchecked
            setIsFacilityEnabledForExternalInteration((prevState) => ({
                ...prevState,
                intrex: {
                    ...prevState.intrex,
                    Speak2FacilityId: '', // Clear when disabled
                    IntrexCommunityId: '', // Clear when disabled
                },
            }));
        }
    };

    return (
        <Segment>
            <h4 style={{ textAlign: 'center' }}>External Integration Configurations</h4>
            <div>
                <Form centered style={{ marginTop: "0px", marginLeft: "50px" }}>
                    {/* Intrex Checkbox */}
                    <Form.Field>
                        <Checkbox
                            label="Intrex"
                            checked={isFacilityEnabledForExternalInteration.intrex.Speak2FacilityId ? true : false}
                            onChange={(e, data) => handleCheckboxChange(data.checked || false)}
                        />
                    </Form.Field>

                    {/* Conditionally show communityId input if Intrex is enabled */}
                    {isFacilityEnabledForExternalInteration.intrex.Speak2FacilityId && (
                        <Form.Field>
                            <label>Intrex Community ID</label>
                            <input
                                type="text"
                                value={communityId}
                                onChange={(e) => setCommunityId(e.target.value)}
                            />
                        </Form.Field>
                    )}

                    {/* Save button */}
                    <Button type="button" primary onClick={handleSave} loading={isSaving}>
                        Save
                    </Button>
                </Form>
            </div>
        </Segment>
    );
}

export default ExternalIntegrationConfiguration;
