import React, { useState, useEffect } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { ServicesType } from '../../types/ServicesTypes';
import DropZone from './DropZone';
import moment from 'moment';
interface PerDayDetailsProps {
  date: Date;
  name: string;
  categoriesData: ServicesType[];
  perDayLists: string[];
  handleMenuSelect: (id: string) => void;
  handleSlotSelect: (mealCategory: string) => void;
  handleMenuDrop: (droppedService: ServicesType, info: any, daySlot: string) => void;
}

const PerDayDetails: React.FC<PerDayDetailsProps> = ({
  date,
  name,
  categoriesData,
  perDayLists,
  handleMenuSelect,
  handleSlotSelect,
  handleMenuDrop,
}) => {
  const [perDayData, setPerDayData] = useState<{ [key: string]: ServicesType[]; }>({});
  useEffect(() => {
    const perDayDataDict: { [key: string]: ServicesType[]; } = {};  // this will be a dictionary with meal category as key and list of services as value
    categoriesData.forEach((item) => {
      if (item.svcMenuInstance && item.menuCategory) { // this is a service instance 
        if (item.menuCategory === "alternative") {
          item.category = "all day";
        }
        if (!perDayDataDict[item.menuCategory]) {
          perDayDataDict[item.menuCategory] = [];
        }
        perDayDataDict[item.menuCategory].push(item);
      } else if (item.category) { // this is a standalone menu 
        if (item.category === "alternative") {
          item.category = "all day";
        }
        if (!perDayDataDict[item.category]) {
          perDayDataDict[item.category] = [];
        }
        perDayDataDict[item.category].push(item);
      }
    });
    setPerDayData(perDayDataDict);
  }, [categoriesData, perDayLists]);
  const formattedDate = moment(date).format('D MMMM'); // it will look like 19 May
  return (
    <Container style={{ border: '0.5px solid #B6B6B6', borderRadius: "5px", marginBottom: '20px', width: '90%' }}>
      <Header as="h3" style={{ margin: "20px", color: "#B6B6B6" }}>
        {formattedDate} {name && `- ${name}`}
      </Header>
      {perDayLists.length > 0 && perDayLists.map((daySlot, index) => (
        <DropZone
          perDayData={perDayData}
          daySlot={daySlot}
          key={index}
          handleMenuSelect={handleMenuSelect}
          handleSlotSelect={handleSlotSelect}
          calendarDate={date}
          handleMenuDrop={handleMenuDrop}
        />
      ))}
    </Container>
  );
};

export default PerDayDetails;
