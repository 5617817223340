import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Message } from 'semantic-ui-react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the plugin with Chart.js
Chart.plugins.register(ChartDataLabels);

interface PieChartProps {
    labels: string[];
    values: number[];
    title: string;
    noData?: boolean | { Header: string; Message: string };
}

const PieChart = ({ labels, values, title, noData = false }: PieChartProps) => {
    const colors = [
        'rgba(255, 128, 66, 1)',
        'rgba(255, 187, 40, 1)',
        'rgba(0, 196, 159, 1)',
        'rgba(0, 136, 254, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 0, 0, 1)',
    ];

    const hoverColors = [
        'rgba(255, 128, 66, 0.8)',
        'rgba(255, 187, 40, 0.8)',
        'rgba(0, 196, 159, 0.8)',
        'rgba(0, 136, 254, 0.8)',
        'rgba(153, 102, 255, 0.8)',
        'rgba(255, 0, 0, 0.8)',
    ];

    const data = {
        labels: labels,
        datasets: [
            {
                data: values,
                backgroundColor: colors.slice(0, values.length),
                hoverBackgroundColor: hoverColors.slice(0, values.length),
            },
        ],
    };

    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                font: {
                    size: 14, // Increase the font size here
                    weight: 'bold', // Optional: make the text bold
                    family: 'Arial' // Optional: specify a font family
                  },
                formatter: (value, context) => {
                    const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                    if (value === 0) return null;
                    const percentage = ((value / total) * 100).toFixed(1);
                    return `${percentage}%`;
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 0,
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.round((currentValue / total) * 100);
                    return `${data.labels[tooltipItem.index]}: ${percentage}%`;
                },
            },
        },
    };

    return (
        <div>
            <div style={{ textAlign: 'left' }}>
                <h4 style={{ margin: '3vh 0.5vw 2vh 0.5vw' }}>{title}</h4>
            </div>
            {noData ? (
                <Message
                    style={{ margin: '0 0.5vw 0 0.5vw' }}
                    header={(typeof noData === 'object' && noData.Header) || 'No stats for the selected time period'}
                    content={
                        (typeof noData === 'object' && noData.Message) ||
                        'There is no data for the selected time period.'
                    }
                />
            ) : (
                <div style={{ margin: '1vh 0.5vw 1vh 0.5vw', height: '24vh' }}>
                    <Pie data={data} options={options} />
                </div>
            )}
        </div>
    );
};

export default PieChart;
