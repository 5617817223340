import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import * as Auth from '../services/Authentication'
import { CurrentUser, UserProfile } from '../types'

export const LOG_IN = 'auth/LOG_IN'
export type LOG_IN = typeof LOG_IN

export const LOG_IN_FROM_CACHE = 'auth/LOG_IN_FROM_CACHE'
export type LOG_IN_FROM_CACHE = typeof LOG_IN_FROM_CACHE

export const SET_PASSWORD_CHANGE_REQUIRED = 'auth/SET_PASSWORD_CHANGE_REQUIRED'
export type SET_PASSWORD_CHANGE_REQUIRED = typeof SET_PASSWORD_CHANGE_REQUIRED

export const SET_FETCHING = 'auth/SET_FETCHING'
export type SET_FETCHING = typeof SET_FETCHING
export const SET_LOADING = 'auth/SET_LOADING'
export type SET_LOADING = typeof SET_LOADING

export const SET_USER = 'auth/SET_USER'
export type SET_USER = typeof SET_USER

export const SET_PROFILE = 'auth/SET_PROFILE'
export type SET_PROFILE = typeof SET_PROFILE

export const SET_FEDERATED_USER_DETAILS = 'auth/SET_FEDERATED_USER_DETAILS'
export type SET_FEDERATED_USER_DETAILS = typeof SET_FEDERATED_USER_DETAILS

export const SET_ERROR = 'auth/SET_ERROR'
export type SET_ERROR = typeof SET_ERROR

export const SET_CACHE_LOGIN_ERROR = 'auth/SET_CACHE_LOGIN_ERROR'
export type SET_CACHE_LOGIN_ERROR = typeof SET_CACHE_LOGIN_ERROR

export const SET_PASSWORD_CHANGE_ERROR = 'auth/SET_PASSWORD_CHANGE_ERROR'
export type SET_PASSWORD_CHANGE_ERROR = typeof SET_PASSWORD_CHANGE_ERROR

export const LOG_OUT = 'auth/LOG_OUT'
export type LOG_OUT = typeof LOG_OUT

export interface LogIn {
    type: LOG_IN
}

export interface LoginFromCache {
    type: LOG_IN_FROM_CACHE
}

export interface SetPasswordChangeRequired {
    type: SET_PASSWORD_CHANGE_REQUIRED
    required: boolean
}

export interface SetFetching {
    type: SET_FETCHING
    isFetching: boolean
}
export interface SetLoading {
    type: SET_LOADING;
    isLoading: boolean;
}

export interface SetUser {
    type: SET_USER
    user: CurrentUser
}

export interface SetProfile {
    type: SET_PROFILE
    profile: UserProfile
}

export interface SetFederatedUserDetails {
    type: SET_FEDERATED_USER_DETAILS;
    federatedAuthUser: boolean;
    federatedUserExistsInDB: boolean;
    federatedUserEmail: string;
    federatedUserExternalId: string;
    federatedUsername: string;
    federatedUserOfficeLocation: string;
}

export interface SetError {
    type: SET_ERROR
    errorCode: string | null
    hasError: boolean
}

export interface SetCacheLoginError {
    type: SET_CACHE_LOGIN_ERROR
}

export interface SetPasswordChangeError {
    type: SET_PASSWORD_CHANGE_ERROR
    hasError: boolean
    errorCode: string | null
}

export interface LogOut {
    type: LOG_OUT
}

export type AuthAction = LogIn | LoginFromCache | SetPasswordChangeRequired | LogOut | SetFetching | SetUser | SetProfile | SetError | SetCacheLoginError | SetPasswordChangeError | SetLoading | SetFederatedUserDetails

// Action Creators

export function setPasswordChangeRequired(required: boolean): SetPasswordChangeRequired {
    return {
        type: SET_PASSWORD_CHANGE_REQUIRED,
        required,
    }
}

export function setFetching(isFetching: boolean): SetFetching {
    return {
        type: SET_FETCHING,
        isFetching,
    }
}

export function setLoading(isLoading: boolean): SetLoading {
    return {
        type: SET_LOADING,
        isLoading
    };
}

export function setUser(user: CurrentUser): SetUser {
    return {
        type: SET_USER,
        user,
    }
}

export function setProfile(profile: UserProfile): SetProfile {
    return {
        type: SET_PROFILE,
        profile,
    }
}

export function setFederatedUserDetails(federatedAuthUser: boolean, federatedUserExistsInDB: boolean, federatedUserEmail: string, federatedUserExternalId: string, federatedUsername: string, federatedUserOfficeLocation: string): SetFederatedUserDetails {
    return {
        type: SET_FEDERATED_USER_DETAILS,
        federatedAuthUser,
        federatedUserExistsInDB,
        federatedUserEmail,
        federatedUserExternalId,
        federatedUsername,
        federatedUserOfficeLocation
    };
}

export function setCacheLoginError(): SetCacheLoginError {
    return {
        type: SET_CACHE_LOGIN_ERROR,
    }
}

export function setError(hasError: boolean, errorCode: string | null): SetError {
    return {
        type: SET_ERROR,
        hasError,
        errorCode
    }
}

export function setPasswordChangeError(hasError: boolean, errorCode: string | null): SetPasswordChangeError {
    return {
        type: SET_PASSWORD_CHANGE_ERROR,
        hasError,
        errorCode,
    }
}

export function logIn(username: string, password: string): ThunkAction<Promise<void>, {}, {}, AuthAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(setFetching(true))
        dispatch(setError(false, null))
        try {
            const { user, profile, passwordChangeRequired } = await Auth.logIn(username, password)
            dispatch(setUser(user))
            if (passwordChangeRequired) {
                dispatch(setPasswordChangeRequired(true))
            } else {
                if (!profile) throw new Error('Could not fetch profile')
                dispatch(setProfile(profile))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode))
        }
        dispatch(setFetching(false))
    }
}

export function logInFromCache(): ThunkAction<Promise<void>, {}, {}, AuthAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(setFetching(true))
        dispatch(setError(false, null))
        try {
            // destructure federatedUserExistsInDB from Auth.logInFromCache 
            const { user = {}, profile = {}, federatedAuthUser = false, federatedUserExistsInDB = false, federatedUserEmail = null, federatedUserExternalId = null, federatedUsername = null, federatedUserOfficeLocation = null } = await Auth.logInFromCache();
            dispatch(setFederatedUserDetails(federatedAuthUser, federatedUserExistsInDB, federatedUserEmail, federatedUserExternalId, federatedUsername, federatedUserOfficeLocation));
            // return if its false
            if (federatedAuthUser && !federatedUserExistsInDB) {
                return; // don't set profile if federated user doesn't exist in DB
            };
            if (!profile) throw new Error('Could not fetch profile.');
            dispatch(setUser(user));
            dispatch(setProfile(profile));
        } catch (e) {
            dispatch(setCacheLoginError());
        }
        dispatch(setFetching(false))
    }
}

export function logOutAction(): LogOut {
    return {
        type: LOG_OUT
    }
}

export function logOut(profile: UserProfile | null): ThunkAction<Promise<void>, {}, {}, AuthAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoading(true))
            await Auth.logOut(profile)
            dispatch(setLoading(false))
            dispatch(logOutAction())
        } catch (e) {
            console.log('Logout error:')
            console.log(e)
        }

    }
}

export function changePassword(currentUser: CurrentUser, newPassword: string): ThunkAction<Promise<void>, {}, {}, AuthAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(setFetching(true))
        dispatch(setPasswordChangeError(false, null))
        try {
            const { user, profile } = await Auth.forcedChangePassword(currentUser, newPassword)
            if (!profile) throw new Error('Could not fetch profile.')
            dispatch(setUser(user))
            dispatch(setProfile(profile))
        } catch (e) {
            console.error(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setPasswordChangeError(true, errorCode))
        }
        dispatch(setFetching(false))
    }
}
