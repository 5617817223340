import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router';
import audioRecorder from './services/AudioRecorder';


interface Props extends RouteComponentProps {
    passAudioBlob: (audioBlob)=>any
}

interface State {
    recording: boolean;
    audioExists: boolean;
    recorder;
    audioBlob;
}

interface AudioRecorder {
    audioPlayer;
}

class AudioRecorder extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.audioPlayer = React.createRef();
        props.passAudioBlob(undefined);
        this.state = {
            recording: false,
            audioBlob: undefined,
            audioExists: false,
            recorder: false
        }
    };

    async beginRecording() {
        try {
            let tempRecorder = await audioRecorder();
            this.setState({
                recorder: tempRecorder
            });

            tempRecorder.record(); //test state

            this.setState({
                recording: true,
            });
        } catch(e) {
            console.log(e);
            console.error(e);
        }

    };

    async stopRecording() {
        const blob = await this.state.recorder.stop();
    
        this.props.passAudioBlob(blob);
        this.setState({
            audioBlob: blob,
            audioExists: true,
            recording: false,
        });
         
    };
    
    clearMessageInput () {
        this.props.passAudioBlob(undefined);
        this.setState({
            audioBlob: undefined,
            audioExists: false
        });
    };

    render () {
        return (
            <>
            <div className="recording-state-image">
                {this.state.recording ? (
                    <div className="recording-button" onClick={this.stopRecording.bind(this)} />
                ) : (
                    <div style={{textAlign:'center'}}>
                        <div>
                            <img className="audio-arrow" src={`${process.env.PUBLIC_URL}/arrow.svg`} />
                            <img className="mic" src={`${process.env.PUBLIC_URL}/microphone.svg`} onClick={this.beginRecording.bind(this)} />
                        </div>

                        <p>
                            *Please note that audio files may take longer to submit than text, please be patient :)
                        </p>
                    </div>
                )}
            </div>
            {this.state.recording && (
                <div className="recording-audio">
                    <span className="recording-button"></span>
                    <p>Recording Audio</p>
                </div>
            )}
            {!this.state.recording && this.state.audioExists && (
                <div className="audio-exists">
                    <Button
                        className="small"
                        onClick={(e) => {
                            e.preventDefault();
                            if (!this.audioPlayer || !this.audioPlayer.current) {
                                return;
                            }
                            this.audioPlayer.current.src = this.state.recorder.play();
                            this.audioPlayer.current.play();
                        }}
                    >
                        <Icon name="play" />
                        Play
                    </Button>
                    <Button className="small" onClick={this.clearMessageInput.bind(this)}>
                        Cancel
                    </Button>
                </div>
            )}
            <div style={{ visibility: 'hidden', height: 0 }}>
                <audio ref={this.audioPlayer}></audio>
            </div>
            </>
        );
    }
}

export default withRouter(AudioRecorder);