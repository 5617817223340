import * as MediaRecorder from 'audio-recorder-polyfill';
let audio;

const audioRecorder = async (): Promise<ReturnType<typeof audioRecorder>> => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks: any[] = [];

    mediaRecorder.addEventListener('dataavailable', e => {
        audioChunks.push(e.data);
    });

    const record = () => mediaRecorder.start();

    const stop = () => {
        const p = new Promise(resolve => {
            mediaRecorder.addEventListener('stop', () => {
                const audioBlob = new Blob(audioChunks, {
                    type: 'audio/wav',
                });
                // audio = new Audio(audioUrl);
                audio = audioBlob;
                console.log(audio);
                return resolve(audioBlob);
            });
        });
        mediaRecorder.stop();

        return p;
    };
    const getAudio = () => {
        console.log('a', audio);
        return audio;
    };

    const play = () => {
        // console.log(audio);
        // if (audio) audio.play();

        return URL.createObjectURL(audio);
    };

    return { record, stop, play, getAudio };
};

export default audioRecorder;

export type Recorder = typeof audioRecorder; 