import React, { Dispatch, FC } from 'react'
import { Checkbox } from 'semantic-ui-react'
import {  FetchDataAction, HandleChange } from './useDietary'
import { DietaryState } from '../../types/Dietary'

interface Props {
    dietaryStateItems: DietaryState
    disptach: Dispatch<FetchDataAction | HandleChange>
    readonly?: boolean
}
const RestrictionsCheckBoxes: FC<Props> = ({ dietaryStateItems, disptach, readonly = false }) => {
    const restrictions = dietaryStateItems.restrictions
    const handleChange = (checked: boolean, key: string) => {
        disptach({
            type: "HANDLE_CHANGE",
            category: "restrictions",
            value: checked,
            key: key
        })
    }
    function formatString(str: string) {
        // /(?=[A-Z])/) regex for uppercase lettter
        // /^\w/ regex for first letter
        return str.split(/(?=[A-Z])/).join(" ").replace(/^\w/, (c) => c.toUpperCase());
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {Object.entries(restrictions).map(([key, value]) => {
                return <Checkbox label={formatString(key)} checked={value} disabled={readonly} onChange={(e, { checked }) => {
                    if (checked !== undefined) {
                        handleChange(checked, key)
                    }
                }} />
            })}
        </div>
    )
}

export default RestrictionsCheckBoxes