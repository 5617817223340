import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, Dimmer, Loader, Table } from 'semantic-ui-react';
import { listRoomTypes } from '../../services/RoomTypes';
import { fetchOneFacility, updateFacility } from '../../services/Facilities';
import { useSelector } from 'react-redux';
import { AuthState, Facility } from '../../types';

export interface LocationType {
    name: string;
    default: boolean;
    _id: string;
}

const LocationTable = () => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const facilityId = profile && profile.Facility;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<LocationType[]>([]);

    async function fetchData() {
        try {
            setIsLoading(true);
            const facilityData = (facilityId && await fetchOneFacility(facilityId)) || {} as Facility;
            if (!facilityData) throw new Error("No facility data");
            const res = await listRoomTypes();
            if (res && res.Result && res.Result.length) {
                setData(() => {
                    return res.Result.map(el => {
                        return {
                            "name": el.Name,
                            "default": el._id === facilityData.defaultCalendarType ? true : false,
                            "_id": el._id
                        };
                    });
                });
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to failed location data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = async (_id: string) => {
        try {
            setIsLoading(true);
            if (!facilityId) throw new Error("No facility id");
            await updateFacility({ _id: facilityId, defaultCalendarType: _id });
            await fetchData();
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to update location data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return <Table style={{ position: "relative" }}>
        <Dimmer active={isLoading} inverted>
            <Loader active={isLoading} />
        </Dimmer>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Default</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {data && data.length ? <>
                {data.map(el => {
                    return <Table.Row>
                        <Table.Cell>
                            <span>{el.name}</span>
                        </Table.Cell>
                        <Table.Cell>
                            <Checkbox checked={el.default} onChange={(e, { checked }) => {
                                handleChange(el._id);
                            }} />
                        </Table.Cell>
                    </Table.Row>;
                })}
            </> : <></>}
        </Table.Body>
    </Table>;
};

export default LocationTable;