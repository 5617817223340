import React, { FC, useState } from 'react'
import { Button, Icon, Image, Label, Popup } from 'semantic-ui-react'
import PrintExportIcons from '../PrintExportIcons'

interface Props {
    onPrintClick: () => void
    exportData: {}[]
    onMenuFilterClick: (selectedFilters: string[]) => void
    onCalendarFilterClick: (selectedFilters: string[]) => void
    onMailFilterClick: (selectedFilters: string[]) => void
    onAnnouncementFilterClick: (selectedFilters: string[]) => void
    onCheckinFilterClick: (selectedFilters: string[]) => void
    onRequestsFilterClick: (selectedFilters: string[]) => void
    onBirthdaysFilterClick: (selectedFilters: string[]) => void
    onPalsFilterClick: (selectedFilters: string[]) => void
    onCarFilterClick: (selectedFilters: string[]) => void
    onKitchenFilterClick: (selectedFilters: string[]) => void
    onMailIsInFilterClick: (selectedFilters: string[]) => void
    onMaintenanceFilterClick: (selectedFilters: string[]) => void
    onSOTMFilterClick: (selectedFilters: string[]) => void
    onROTMFilterClick: (selectedFilters: string[]) => void
    onSMSFilterClick: (selectedFilters: string[]) => void
    onHouseKeeingFilterClick: (selectedFilters: string[]) => void
    onUnschduledEventFilterClick: (selectedFilters: string[]) => void
    onDeviceStatusFilterClick: (selectedFilters: string[]) => void
    printButtonLoading?: boolean
    disable?: boolean
    exportFileName: string
}
const FilterPrintExportIcons: FC<Props> = ({ exportData, onPrintClick, exportFileName , ...props }) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])
    const filterButton = !selectedFilters.length ? <Button icon='filter' size='large' disabled={props.disable ? props.disable : false} style={{ background: "none", padding: 0, margin: 0 }} /> : <Button icon='filter' size='large' style={{ background: "none", padding: 0, margin: 0, position: "relative" }} >
        <Icon name='filter' style={{ position: "relative" }} >
            <span style={{
                position: "absolute",
                top: "-6px",
                right: "-6px",
                background: "#20c997",
                color: "white",
                borderRadius: "50%",
                width: "14px",
                height: "14px",
                textAlign: "center",
                fontSize: "7px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>{selectedFilters.length}</span>
        </Icon>
    </Button>

    const isFilterSelected = (type: string) => {
        return selectedFilters.includes(type)
    }
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Popup
                trigger={filterButton}
                content={
                    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("menu") ? "menu" : "menu-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onMenuFilterClick
                                    if (isFilterSelected("menu")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "menu"))
                                        filterItems = selectedFilters.filter((f) => f !== "menu")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "menu"])
                                        filterItems = [...selectedFilters, "menu"]
                                    }
                                    // the below is to get data as state is async i am not getting data directly from state
                                    props.onMenuFilterClick(filterItems)
                                }}
                                    size="mini"
                                    style={{ height: "20px", cursor: "pointer" }}
                                />
                            }
                            content="Menu"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("calendar") ? "calendar" : "calendar-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onCalendarFilterClick
                                    if (isFilterSelected("calendar")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "calendar"))
                                        filterItems = selectedFilters.filter((f) => f !== "calendar")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "calendar"])
                                        filterItems = [...selectedFilters, "calendar"]
                                    }
                                    props.onCalendarFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Calendar"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("mail") ? "mail" : "mail-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onMailFilterClick
                                    if (isFilterSelected("mail")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "mail"))
                                        filterItems = selectedFilters.filter((f) => f !== "mail")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "mail"])
                                        filterItems = [...selectedFilters, "mail"]
                                    }
                                    props.onMailFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Community News"
                            size='tiny'
                            position='top center'
                            pinned
                        />
                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("checkin") ? "checkin" : "checkin-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onCheckinFilterClick
                                    if (isFilterSelected("checkin")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "checkin"))
                                        filterItems = selectedFilters.filter((f) => f !== "checkin")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "checkin"])
                                        filterItems = [...selectedFilters, "checkin"]
                                    }
                                    props.onCheckinFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Check-in"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("requests") ? "requests" : "requests-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onCheckinFilterClick
                                    if (isFilterSelected("requests")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "requests"))
                                        filterItems = selectedFilters.filter((f) => f !== "requests")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "requests"])
                                        filterItems = [...selectedFilters, "requests"]
                                    }
                                    props.onRequestsFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} disabled />
                            }
                            content="Requests"
                            size='tiny'
                            position='top center'
                            pinned
                        />


                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("birthdays") ? "birthdays" : "birthdays-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onBirthdaysFilterClick
                                    if (isFilterSelected("birthdays")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "birthdays"))
                                        filterItems = selectedFilters.filter((f) => f !== "birthdays")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "birthdays"])
                                        filterItems = [...selectedFilters, "birthdays"]
                                    }
                                    props.onBirthdaysFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Birthdays"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("sotm") ? "SOTM" : "SOTM-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onSOTMClick
                                    if (isFilterSelected("sotm")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "sotm"))
                                        filterItems = selectedFilters.filter((f) => f !== "sotm")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "sotm"])
                                        filterItems = [...selectedFilters, "sotm"]
                                    }
                                    props.onSOTMFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Staff Spotlight"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("rotm") ? "pals" : "pals-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onPalsFilterClick
                                    if (isFilterSelected("rotm")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "rotm"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "rotm"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "rotm"])
                                        filterItems = [...selectedFilters, "rotm"]
                                    }
                                    props.onPalsFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Resident Spotlight"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={

                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("car") ? "car" : "car-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onCarFilterClick
                                    if (isFilterSelected("car")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "car"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "car"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "car"])
                                        filterItems = [...selectedFilters, "car"]
                                    }
                                    props.onCarFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Transport"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={

                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("event") ? "event" : "event-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onEventClick
                                    if (isFilterSelected("event")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "event"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "event"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "event"])
                                        filterItems = [...selectedFilters, "event"]
                                    }
                                    props.onUnschduledEventFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Notepad"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={

                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("kitchen") ? "kitchen" : "kitchen-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onKitchenFilterClick
                                    if (isFilterSelected("kitchen")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "kitchen"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "kitchen"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "kitchen"])
                                        filterItems = [...selectedFilters, "kitchen"]
                                    }
                                    props.onKitchenFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />
                            }
                            content="Kitchen"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("sms") ? "sms" : "sms-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onBirthdaysFilterClick
                                    if (isFilterSelected("sms")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "sms"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "sms"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "sms"])
                                        filterItems = [...selectedFilters, "sms"]
                                    }
                                    props.onSMSFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />

                            }
                            content="Text"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("maintenance") ? "maintenance" : "maintenance-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onMaintenanceFilterClick
                                    if (isFilterSelected("maintenance")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "maintenance"))
                                        filterItems = selectedFilters.filter(filter => filter !== "maintenance")
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "maintenance"])
                                        filterItems = [...selectedFilters, "maintenance"]
                                    }
                                    props.onMaintenanceFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />

                            }
                            content="Maintenance"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("mail_is_in") ? "fancyMail" : "fancyMail-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onMailIsInFilterClick
                                    if (isFilterSelected("mail_is_in")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "mail_is_in"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "mail_is_in"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "mail_is_in"])
                                        filterItems = [...selectedFilters, "mail_is_in"]
                                    }
                                    props.onMailIsInFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />

                            }
                            content="Mail is in "
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("housekeeping") ? "housekeeping" : "housekeeping-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onHouseKeeingFilterClick
                                    if (isFilterSelected("housekeeping")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "housekeeping"))
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "housekeeping"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "housekeeping"])
                                        filterItems = [...selectedFilters, "housekeeping"]
                                    }
                                    props.onMailIsInFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />

                            }
                            content="House Keeping"
                            size='tiny'
                            position='top center'
                            pinned
                        />

                        <Popup
                            trigger={
                                <Image src={`${process.env.PUBLIC_URL}/sidebar-icons/${isFilterSelected("device_status") ? "router-sharp-blue" : "router-sharp-white"}.svg`} onClick={() => {
                                    let filterItems: string[] = [] // to avoid state value not present in props.onDeviceStatusFilterClick
                                    if (isFilterSelected("device_status")) {
                                        setSelectedFilters(prev => prev.filter(filter => filter !== "device_status"))
                                    } else {
                                        setSelectedFilters((prev) => [...prev, "device_status"])
                                        filterItems = [...selectedFilters, "device_status"]
                                    }
                                    props.onDeviceStatusFilterClick(filterItems)
                                }} size="mini" style={{ height: "20px", cursor: "pointer" }} />

                            }
                            content="Device Status"
                            size='tiny'
                            position='top center'
                            pinned
                        />
                    </div>
                }
                size='large'
                style={{ background: "#ced4da", maxWidth: "450px", width: "450px" }}
                pinned
                on="click"
                basic
            />
            <PrintExportIcons exportData={exportData} onPrintClick={onPrintClick} printLoading={props.printButtonLoading} exportFileName={exportFileName} />
        </div>
    )
}

export default FilterPrintExportIcons
