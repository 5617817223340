import API from './API';

export async function sendSmsInvite(contactInfo) {
    const response = await API.lambdaPost('/smsinvite/send', contactInfo);
    return response;
}

export async function sendEmailInvite(contactInfo) {
    const response = await API.lambdaPost('/emailinvite/send', contactInfo);
    return response;
}

export async function deleteSmsInvite (registrantId) {
    const response = await API.lambdaPost('/smsinvite/delete', {RegistrantId: registrantId})
    return response
}
