import React, { useState, useEffect } from 'react'
import { Form, Dropdown, Message, List, Button, Loader, Dimmer } from 'semantic-ui-react'
import { assertWifi, getWifiPairingStatus } from '../../../../services/A4hEndpoints'
import { fetchAllRooms } from '../../../../services/Facilities'
import { sortByKey } from '../../../../util/sortData';
import { sendToast } from '../../../../util'

const WifiStatusItem = props => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')

    const refreshWifiStatus = async (endpointId, operationId) => {
        setLoading(true)
        const res = await getWifiPairingStatus(endpointId, operationId)
        if (res && res.status)
            setStatus(res.status)
        setLoading(false)
    }

    return (
        <List.Item>
            {props.resident} - {status.replace('_', ' ') || props.status.replace('_', ' ')}
            <Button
                basic
                circular
                size='mini'
                loading={loading}
                icon='refresh'
                style={{ marginLeft: '5px' }}
                onClick={() => refreshWifiStatus(props.endpointId, props.operationId)}
            />
        </List.Item>
    )
}

const WifiAssertion = () => {
    const [rooms, setRooms] = useState([])
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState<any>([])
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [wifiName, setWifiName] = useState('')
    const [psk, setPsk] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [listItems, setListItems] = useState([])
    const [fetching, setFetching] = useState(true)
    const [progress, setProgress] = useState(0)

    const fetchAndSetDropdownOptions = async () => {
        setFetching(true)
        const facilityRooms = await fetchAllRooms()
        setProgress(0);
        setRooms(facilityRooms);
        const formattedRooms = facilityRooms.map(room => {
            return {
                text: (room.Resident && room.RoomName && `${room.Resident} - ${room.RoomName}`) || room.Resident || room.RoomName,
                key: room.RoomId,
                value: room.RoomId
            }
        })
        setOptions(formattedRooms)
        setFetching(false)
    }

    const clearForm = () => {
        setWifiName('')
        setPsk('')
        setSelectedOptions([])
    }

    const handleSubmit = async (ssid: string, psk: string = '', a4hRoomIds: string[]) => {
        if (psk && psk.length < 8) {
            setErrMsg('Password must be at least 8 characters long')
            return
        }
        if (!ssid || !a4hRoomIds.length) {
            setErrMsg('Form incomplete')
            return
        }
        setErrMsg('')
        setSubmitting(true)
        const filteredRooms = rooms.filter((r: any) => a4hRoomIds.includes(r.RoomId))
        const formattedRooms = filteredRooms.map((room: any) => {
            return {
                RoomId:  room.RoomId,
                Resident: room.Resident,
                RoomName: room.RoomName,
            }
        })
        const res = await assertWifi(formattedRooms, ssid, psk);
        sendToast('success', 'WiFi assertion initiated, Please refresh the page after a few minutes to check the assertion status.')
        setListItems(res)
        clearForm()
        setSubmitting(false)
    }

    useEffect(() => {
        fetchAndSetDropdownOptions()
    }, [])

    const getResident = (endpointId, allRooms) => {
        const { Resident } = allRooms.find(r => r.EndpointIds.includes(endpointId))
        return Resident
    }

    const listWifiResponse = (items) => {
        return (
            <List divided relaxed>
                {items.map(item => (
                    <WifiStatusItem
                        key={item.operationId}
                        endpointId={item.endpointId}
                        operationId={item.operationId}
                        status={item.status}
                        resident={getResident(item.endpointId, rooms)}
                    />
                ))}
            </List>
        )
    }

    return <>
        <Dimmer active={fetching} inverted>
            <Loader active={fetching} > {progress}% </Loader>
        </Dimmer>
        <Form widths='equal'>
            <Form.Input label='WiFi name' required value={wifiName} onChange={(e, { value }) => setWifiName(value)} />
            <Form.Input label='WiFi password' value={psk} onChange={(e, { value }) => setPsk(value)} />
            <Form.Field required>
                <label>Resident rooms</label>
                <Dropdown
                    placeholder='Select rooms'
                    closeOnEscape 
                    value={selectedOptions}
                    multiple
                    clearable
                    search
                    scrolling
                    selection
                    options={sortByKey(options)}
                    onChange={(e, { value }) => setSelectedOptions(value)}
                />
            </Form.Field>
            <Form.Button basic color='blue' loading={submitting} onClick={() => handleSubmit(wifiName, psk, selectedOptions)}>
                Assert Wifi
            </Form.Button>
        </Form>
        {errMsg && <Message negative>{errMsg}</Message>}

        {listItems.length ? <>
            <h2>Status</h2>
            {listWifiResponse(listItems)}
        </>
            :
            <></>
        }
    </>
}

export default WifiAssertion
