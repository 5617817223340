import React, { useEffect, useState } from 'react';
import { Accordion, List, Menu, Input, Dimmer, Loader, Label, Icon, Button, Popup, Dropdown, Grid } from 'semantic-ui-react';
import { fetchAllActiveServicesTypes, getServiceCategories } from '../../services/service';
import { ServicesType } from '../../types/ServicesTypes';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { serviceSourceType } from '../../types/ServiceInstance';
import DragZone from './DragZone';
import { mealTypes } from '../../util';
type AccordionItem = {
  heading: string;
  services: ServicesType[];
};

interface ChildProps {
  reloadFlag: boolean;
  source: serviceSourceType;
  selectedRestaurant?: string | null;
  showSvcInstanceModal?: (formattedStartDate?: string, formattedEndDate?: string) => void;
}

type Tab = "all" | "community" | "restaurant";

const ServicesAccordion = ({ reloadFlag, source, selectedRestaurant, showSvcInstanceModal }: ChildProps) => {
  const [activeTab, setActiveTab] = useState<Tab>('community');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [services, setServices] = useState<ServicesType[]>([]);
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page_no, setPage_no] = useState<number>(1);
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const dropdownOptions = [{ key: 'all', text: 'All meal types', value: 'all' }].concat(mealTypes.map((mealType) => ({
    key: mealType,
    text: mealType,
    value: mealType,
  })));
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getServiceCategories({ sortAlphabetically: true });
        if (source === "DailyActivities") {
          setCategoryOptions(categories.filter((category: string) => category !== 'Menu'));
        } else if (source === "Menu") {
          setCategoryOptions(categories.filter((category: string) => category == 'Menu'));
        } else {
          setCategoryOptions(categories);
        }
      } catch (error) {
        toast.warn(error instanceof Error ? error.message : "Failed to get categories", {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    };
    fetchCategories();
  }, [source]);

  const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => authReducer.profile);
  const fetchServices = async () => {
    setLoading(true);
    try {
      if (profile && profile.Facility) {
        let category: any;
        if (source === "DailyActivities") {
          category = { $ne: "Menu" };
        } else if (source === "Menu") {
          category = "Menu";
        }
        const Filter = { Facility: profile.Facility, active: true, category: category };
        if (activeTab === 'restaurant') {
          Filter["Asset"] = selectedRestaurant;
        }
        const allservices = await fetchAllActiveServicesTypes({ Filter });
        setServices(allservices);
      }
    } catch (error) {
      toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [profile, page_no, reloadFlag, activeTab, selectedRestaurant]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      if (!searchQuery) {
        await fetchServices();
        setActiveIndexes([]);
        return;
      }
      let category;
      if (source === "Menu") {
        category = "Menu";
      } else if (source === "DailyActivities") {
        category = { $ne: "Menu" };
      }
      // Fetch all services initially
      const Filter = { Facility: profile && profile.Facility, active: true, category: category };
      if (activeTab === 'restaurant') {
        Filter["Asset"] = selectedRestaurant;
      }
      const allServices = await fetchAllActiveServicesTypes({ Filter });
      // Filter the services based on the search query
      const searchResults = allServices.filter((service) =>
        service.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // Update the services state with the search results
      setServices(searchResults);
      if (searchResults && searchResults.length) {
        // Find indexes of categories that have matching services
        const indexes = categoryOptions.reduce((acc, category, index) => {
          if (searchResults.some((service) => service.category === category)) {
            acc.push(index);
          }
          return acc;
        }, [] as number[]);
        setActiveIndexes(indexes);
        setClearSearch(true);
      } else {
        // If no search results, reset the active indexes
        setActiveIndexes([]);
      }
    } catch (error) {
      toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const accordionItems: AccordionItem[] = categoryOptions.map((category) => ({
    heading: category,
    services: services.filter((service) => service.category === category),
  }));
  // filter out categories which have no services
  const filteredAccordionItems = accordionItems.filter((item) => item.services && item.services.length > 0);
  const panels = filteredAccordionItems.map((item) => ({
    key: item.heading,
    title: { content: <span style={{ color: '#2EA2F8' }}>{item.heading}</span> },
    content: {
      content: (
        <List>
          {item.services.length ? item.services.map((service, idx) => (
            <List.Item
              key={idx}
              style={{
                fontWeight:
                  searchQuery &&
                    service.name.toLowerCase().includes(searchQuery.toLowerCase())
                    ? 'bold'
                    : 'normal',
                cursor: 'pointer'
              }}
              className='drag-able-event'
              data-service={JSON.stringify(service)}
            >
              <Label>{service.name}</Label>
            </List.Item>
          ))
            : <p style={{ fontSize: "0.85rem", marginLeft: "10px" }}>No items </p>}
        </List>
      ),
    },
  }));

  const handleClear = async () => {
    setSearchQuery('');
    await fetchServices();
    setClearSearch(false);
    setActiveIndexes([]);
    return;
  };
  const filterServices = (services: ServicesType[]) => {
    let filteredServices = services;
    if (selectedFilter !== "all") { //Filter by all means no filter
      const filter = selectedFilter.toLowerCase();
      filteredServices = services.filter((service) => {
        // If service has no mealTypes and selectedFilter is "any", consider it a match
        if (!service.mealTypes && filter === "any") {
          return true;
        }
        // Filter based on mealTypes matching the selectedFilter
        return service.mealTypes && Array.isArray(service.mealTypes) && service.mealTypes.some((mealType) => mealType.toLowerCase() === filter);
      });
    }
    return filteredServices;
  };

  const listServices = () => {
    const filteredServices = filterServices(services);
    return (<List>
      {filteredServices.length ? (
        filteredServices.map((service, idx) => (
          <DragZone service={service} key={idx} searchQuery={searchQuery} />
        ))
      ) : (
        <p className='accordion-message' >{selectedRestaurant ? `No Cookbook present for the ${selectedFilter && selectedFilter !== "all" ? selectedFilter : 'restaurant'}  ` : "No restaurant has been selected"}</p>
      )}
    </List>);
  };

  const filterButton = <div onClick={() => setPopupOpen(!popupOpen)}>
    {selectedFilter !== "all" && `Filter by : ${selectedFilter}`}
    <Button icon='filter' size='large' style={{ background: "none", padding: 0, margin: 0 }} />
  </div>;

  const filterPopup = () => {
    return (
      <Popup
        trigger={filterButton}
        content={
          <Dropdown
            placeholder='Filter by meal type'
            fluid
            selection
            value={selectedFilter}
            options={dropdownOptions}
            closeOnChange
            onChange={(e, data) => {
              setSelectedFilter(data.value as string);
              setPopupOpen(false);
            }
            }
          />
        }
        size='mini'
        style={{ background: "#ced4da", maxWidth: "200px", width: "200px" }}
        on="click"
        basic
        open={popupOpen}
        position='top left'
        onClose={() => setPopupOpen(false)}
      />
    );
  };
  useEffect(() => {
    setSelectedFilter('all');
  }, [activeTab, selectedRestaurant]);

  const renderContent = () => {
    switch (activeTab) {
      case 'community':
        return (
          <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
            <div style={{ overflowY: 'auto', padding: '7px' }}>
              {
                source === "Menu" ? <>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {filterPopup()}
                  </div>
                  {listServices()}
                </> : <Accordion
                  styled
                  exclusive={!activeIndexes.length}
                  panels={panels}
                  activeIndex={activeIndexes.length ? activeIndexes : undefined}
                />
              }
            </div>
          </div>
        );
      case 'restaurant':
        return (
          <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
            <div style={{ overflowY: 'auto', padding: '7px' }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {filterPopup()}
              </div>
              {listServices()}
            </div>
          </div>
        );
      default:
        return (
          <div>
            <h3>Coming Soon</h3>
          </div>
        );
    }
  };

  return (
    <div>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Grid>
        <Grid.Column width={source === "Menu" ? 15 : 16}>
          <Input
            fluid
            icon={
              <Icon
                name={clearSearch ? 'times' : 'search'}
                link
                onClick={clearSearch ? handleClear : handleSearch}
              />
            }
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => {
              setClearSearch(false);
              setSearchQuery(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Grid.Column>
        <Grid.Column width={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
          {source === "Menu" && <Popup
            trigger={<Icon name='plus' size="large" onClick={(e) => {
              e.preventDefault();
              if (showSvcInstanceModal) {
                showSvcInstanceModal();
              }
            }} style={{ cursor: "pointer" }} />}
            content='Create a service instance'
            size='tiny'
            type="button"
            position="top right"
          />}
        </Grid.Column>
      </Grid>
      <Menu tabular>
        <Menu.Item
          name={source === "Menu" ? "Cookbook" : "My community"}
          active={activeTab === 'community'}
          onClick={() => setActiveTab('community')}
        />
        {
          source === "Menu" && <Menu.Item
            name="restaurant"
            active={activeTab === 'restaurant'}
            onClick={() => setActiveTab('restaurant')}
          />
        }
      </Menu>
      {renderContent()}
    </div>
  );
};

export default ServicesAccordion;
