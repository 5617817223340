import { Auth } from 'aws-amplify'
import { CurrentUser, UserProfile } from '../types'
import API from './API'
import { toast } from 'react-toastify'

function parseUserResponse(response: any): CurrentUser {
    if (!response.username) throw new Error('No username')
    const user: CurrentUser = {
        username: response.username,
        awsUserObject: response,
    }
    return user
}

interface LoginResponse {
    user: CurrentUser
    profile: UserProfile | null
    passwordChangeRequired: boolean
}

export async function logIn(username: string, password: string): Promise<any> {
    await Auth.federatedSignIn() // redirect to cognito hosted UI
}

export async function logOut(profile: UserProfile | null): Promise<any> {
    if (profile) {
        await API.lambdaPut('/users/updateLoginStatus', { userId: profile._id, isWebAppLoggedIn: false });
    }
    await Auth.signOut();

    return null;
}

export async function logInFromCache() {
    // Initialize constants and variables
    const maxRetries = 5;
    const retryInterval = 1000; // milliseconds
    let retryCount = 0;
    let federatedAuthUser = false;
    let federatedUserEmail = null;
    let federatedUserExternalId = null;
    let federatedUsername = null;
    let federatedUserOfficeLocation = null;
    let response: any = null;

    // Attempt to retrieve the current session with retries
    while (retryCount < maxRetries) {
        try {
            await Auth.currentSession();
            console.log('Successfully retrieved current session');
            break; // Exit loop on success
        } catch (error) {
            console.error(`Retry ${retryCount}: Current session retrieval failed`, error);
            await new Promise((resolve) => setTimeout(resolve, retryInterval));
            retryCount++;
        }
    }
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        // Check for Federated user
        federatedAuthUser = cognitoUser && cognitoUser.signInUserSession && cognitoUser.signInUserSession.idToken && cognitoUser.signInUserSession.idToken.payload && cognitoUser.signInUserSession.idToken.payload.identities &&  cognitoUser.signInUserSession.idToken.payload.identities.length > 0;
        const user = parseUserResponse(cognitoUser);
        if (federatedAuthUser) {
            federatedUserEmail = user.awsUserObject.signInUserSession.idToken.payload.email;
            federatedUserExternalId = user.awsUserObject.signInUserSession.idToken.payload.sub;
            federatedUsername = user.awsUserObject.username;
            federatedUserOfficeLocation = user.awsUserObject.signInUserSession.idToken.payload["custom:officelocation"] // get the office location from cognito
            if(!federatedUserOfficeLocation) {
                throw new Error("Office location is not set for this user. Please contact support.");
            }
        }
        const profile = await API.lambdaGet('/users/profile/get', { username: user.username, isActive: 1 }); // check for IsActive: 1 as well 
        // Handling profile specifics for super admin
        if (profile.Facilities && Array.isArray(profile.Facilities) && !profile.Facility) {
            await logOut(profile);
            response = { user, profile: null, passwordChangeRequired: false };
        } else {
                // don't wait for this to complete to save time
                API.lambdaPut('/users/updateLoginStatus', { userId: profile._id, isWebAppLoggedIn: true })
                    .catch((error) => {
                        // suppress error
                        console.error("updateLoginStatus error", error);
                        toast.warn("User Status update failed. Please contact support team.", {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    });
            // Successful response construction
            response = {
                user,
                profile,
                federatedAuthUser,
                federatedUserExistsInDB: true,
                federatedUserEmail,
                federatedUserExternalId,
                federatedUsername,
                federatedUserOfficeLocation,
            };
        }
    } catch (error) {
        console.error("Error in retrieving authenticated user details", error);
        if (federatedAuthUser) {
            response = { 
                federatedAuthUser, 
                federatedUserExistsInDB: false, 
                federatedUserEmail,
                federatedUserExternalId,
                federatedUsername, 
                federatedUserOfficeLocation
            };
        }
    } finally {
        return response
    }

}


export async function forcedChangePassword(currentUser: CurrentUser, newPassword: string): Promise<LoginResponse> {
    const user = currentUser.awsUserObject
    if (!user) throw new Error('Could not get authenticated user')
    await Auth.completeNewPassword(user, newPassword, {})
    const newUser = await Auth.currentAuthenticatedUser()
    const parsed = parseUserResponse(newUser)
    const profile = await API.lambdaGet('/users/profile/get')
    return {
        user: parsed,
        profile,
        passwordChangeRequired: false,
    }
}

export async function updatePassword(
    currentUser: CurrentUser,
    oldPassword: string,
    newPassword: string,
): Promise<boolean> {
    const user = currentUser.awsUserObject
    if (!user) throw new Error('Could not get authenticated user')
    await Auth.changePassword(user, oldPassword, newPassword)
    return true
}